import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/user?${queryString}`

    return fetch(target)
  },
  async get(userId) {
    return fetch(`/user/${userId}`)
  },
  async create(user) {
    return fetch('/user', {
      method: 'POST',
      body: JSON.stringify(user),
    })
  },
  async update(user) {
    return fetch(`/user/${user.user_id}`, {
      method: 'PUT',
      body: JSON.stringify(user),
    })
  },
  async delete(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))

    await fetch(`/user?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },
  async getCategorizedInstances() {
    return fetch('/all-categorized-instances')
  },
  async getRoles() {
    return fetch('/user/roles')
  },
  async resendInvitation(email) {
    return fetch(`/user/${email}/resend`, {
      method: 'POST',
    })
  },
}
