import Slider from 'rc-slider'
import { range } from 'lodash'
import { useRef, useEffect, useState } from 'react'

import 'rc-slider/assets/index.css'
import './style.styl'

const SliderInput = ({ onChange, value, min, max, disabled, marks }) => {
  const sliderRef = useRef()

  const [data, setData] = useState(value || 0)

  useEffect(() => {
    setData(parseInt(value, 10) || 0)
  }, [value])

  function handleChange(numb) {
    setData(numb)
    onChange && onChange(numb)
  }

  // get width of slider to handle gradent background
  useEffect(() => {
    const resizeHandle = () =>
      sliderRef.current.style.setProperty(
        '--slide-width',
        sliderRef.current.offsetWidth + 'px'
      )

    if (sliderRef.current) {
      resizeHandle()
      window.addEventListener('resize', resizeHandle)
    }

    return () => {
      window.removeEventListener('resize', resizeHandle)
    }
  }, [])

  return (
    <div ref={sliderRef} className="slider-input">
      <Slider
        disabled={disabled}
        onChange={handleChange}
        value={data}
        min={min}
        max={max}
        marks={marks}
      />
      <div className="slider-input__indicators">
        {range(11).map((_, index) => (
          <div key={index} className="slider-input__indicators-item"></div>
        ))}
      </div>
    </div>
  )
}

export default SliderInput
