import { Popover } from 'antd'
import { observer } from 'mobx-react-lite'

import ClipboardMenu from './ClipboardMenu'
import Icon from '../../../../components/Icon'
import ContentLanguageCopySection from './ContentLanguageCopySection'

import t from '../../../../utils/translate'

import EditorStore from '../../../../stores/PageEditor/EditorStore'

const ComponentContextMenu = ({
  onMenuHide,
  isComponentAvailable,
  isComponentDeprecated,
  field,
  visible,
}) => {
  const isActive = EditorStore.getActiveField(field)
  const isClipboardNotEmpty = EditorStore.clipboard.length > 0

  return (
    <div onClick={onMenuHide} className="page-editor__field-menu-action">
      {isComponentAvailable && (
        <div
          onClick={() => EditorStore.editElement(field)}
          className="page-editor__field-menu-item"
        >
          <Icon width="20px" height="15px" symbol="pencil" />
          <span>{t('Edit')}</span>
        </div>
      )}

      {isComponentAvailable && !isComponentDeprecated && (
        <div
          onClick={() => EditorStore.copyToClipboard(field, true)}
          className="page-editor__field-menu-item"
        >
          <Icon width="20px" height="15px" symbol="clipboard" />
          <span>{t('copy_verb')}</span>
        </div>
      )}

      {isComponentAvailable && isClipboardNotEmpty && (
        <Popover
          visible={visible === false ? false : undefined}
          content={<ClipboardMenu field={field} onMenuHide={onMenuHide} />}
          placement="rightTop"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="page-editor__field-menu-item"
          >
            <Icon width="20px" height="15px" symbol="paste" />
            <span>{t('Paste')}</span>
          </div>
        </Popover>
      )}

      {isComponentAvailable && !isComponentDeprecated && (
        <div
          onClick={() => EditorStore.duplicateElement(field)}
          className="page-editor__field-menu-item"
        >
          <Icon width="20px" height="15px" symbol="copy" />
          <span>{t('Duplicate')}</span>
        </div>
      )}

      <div
        onClick={() => EditorStore.setActiveFromId(field.id, !isActive)}
        className="page-editor__field-menu-item"
      >
        <Icon
          width="20px"
          height="15px"
          symbol={isActive ? 'toggle-off' : 'toggle-on'}
        />
        <span>{t(isActive ? 'Deactivate' : 'Activate')}</span>
      </div>

      {isComponentAvailable && (
        <div
          onClick={() => {
            EditorStore.editElement(field)
            EditorStore.showSettings()
          }}
          className="page-editor__field-menu-item"
        >
          <Icon width="20px" height="15px" symbol="clock" />
          <span>{t('Playout')}</span>
        </div>
      )}

      <div
        onClick={() => EditorStore.deleteElement(field.id)}
        className="page-editor__field-menu-item page-editor__field-menu-item--delete"
      >
        <Icon width="20px" height="15px" symbol="trash" />
        <span>{t('Delete')}</span>
      </div>

      <ContentLanguageCopySection
        element={field}
        type="component"
        visible={visible}
        onMenuHide={onMenuHide}
      />
    </div>
  )
}

export default observer(ComponentContextMenu)
