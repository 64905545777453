import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useCallback } from 'react'

import Spinner from '../../../components/Spinner'

import ContentWidgetStore from '../../../stores/Apps/ContentWidgetStore'
import { EditorStore } from '../../../stores'
import { toJS } from 'mobx'
import debounce from '../../../utils/debounce'

const ContentWidget = ({ slug }) => {
  const iframeRef = useRef()

  const pageContent = toJS(EditorStore.pageToEdit)
  const selectedLanguage = EditorStore.contentLanguage

  useEffect(() => {
    ContentWidgetStore.fetchContentWidgetUrl(slug)
  }, [slug])

  useEffect(() => {
    debouncedSendPageInformation(iframeRef.current, slug)
    // eslint-disable-next-line
  }, [pageContent, slug, selectedLanguage])

  const handleMessageReceived = (event) => {
    ContentWidgetStore.handleReceiveMessageEvent(event, slug)
  }
  useEffect(() => {
    window.addEventListener('message', handleMessageReceived)

    return () => {
      window.removeEventListener('message', handleMessageReceived)
    }
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSendPageInformation = useCallback(
    debounce(ContentWidgetStore.sendPageInformation, 500),
    []
  )

  const handleIframeLoad = () => {
    debouncedSendPageInformation(iframeRef.current, slug)
  }

  const widgetInformation = ContentWidgetStore.getMetaInformation(slug)

  if (!widgetInformation.iframeURL) return <Spinner />

  return (
    <iframe
      ref={iframeRef}
      src={widgetInformation.finalURL}
      onLoad={handleIframeLoad}
      allow="clipboard-read; clipboard-write"
    />
  )
}

export default observer(ContentWidget)
