import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'

class CategoryStore {
  aggregations = []
  aggregationsById = {}
  categories = []
  categoriesById = {}
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = { filterCategory: 'all' }
  params = null
  state = 'pending' // "pending", "done" or "error"
  updating = false
  modalDetail = {
    aggregationSorting: {},
    aggregations: [],
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  alignSortingToLatestAggregations() {
    const _aggregationSorting =
      Array.isArray(this.modalDetail.aggregationSorting) ||
      !Object.keys(this.modalDetail.aggregationSorting)?.length
        ? null
        : this.modalDetail.aggregationSorting

    let _maxPosition = _aggregationSorting
      ? Math.max(
          ...Object.keys(_aggregationSorting).map((k) => _aggregationSorting[k])
        )
      : 0

    let mergedAggregationSorting = {}

    // Sort this.aggregations by position to make sure append in ordering
    const _aggregations = this.aggregations.sort((a, b) =>
      a.position - b.position > 0 ? 1 : -1
    )

    if (_maxPosition > 0) {
      mergedAggregationSorting = _aggregations.reduce((d, item) => {
        const existPosition = _aggregationSorting[item.key]
        if (existPosition) {
          return { ...d, [item.key]: existPosition }
        } else {
          _maxPosition++
          return { ...d, [item.key]: _maxPosition }
        }
      }, {})
    } else {
      mergedAggregationSorting = _aggregations.reduce((d, item, index) => {
        return { ...d, [item.key]: index + 1 }
      }, {})
    }

    this.modalDetail.aggregationSorting = mergedAggregationSorting
  }

  alignFiltersToLatestAggregations() {
    const _aggregations = this.modalDetail.aggregations
    const mergedAggregations = this.aggregations.reduce((d, item) => {
      if (_aggregations.includes(item.id)) {
        return [...d, item.id]
      } else {
        return d
      }
    }, [])
    this.modalDetail.aggregations = mergedAggregations
  }

  resetSortingfromCategory() {
    const currentCategory = this.categoriesById[this.modalDetail.id]
    this.modalDetail.aggregationSorting =
      currentCategory.aggregationSorting || {}
  }

  resetFiltersfromCategory() {
    const currentCategory = this.categoriesById[this.modalDetail.id]
    this.modalDetail.aggregations = currentCategory.aggregations || []
  }

  loadSortingByFilters() {
    const customFilters = this.modalDetail.overrideAggregations
    const customSorting = this.modalDetail.overrideAggregationSorting

    // Sort this.aggregations by position to make sure append in ordering
    const _aggregations = this.aggregations
      .sort((a, b) => (a.position - b.position > 0 ? 1 : -1))
      .map((aggr, index) => {
        return {
          ...aggr,
          position: index,
        }
      })

    if (customFilters && customSorting) {
      this.modalDetail.aggregationSorting =
        this.modalDetail.aggregations.reduce((d, currentId) => {
          const found = _aggregations.find((i) => i.id === currentId)
          if (found) {
            return { ...d, [found.key]: found.position }
          }
          return d
        }, {})
    }
  }

  onFiltersOrSortingRadioChange(changeKey) {
    const customFilters = this.modalDetail.overrideAggregations
    const customSorting = this.modalDetail.overrideAggregationSorting

    if (customFilters && customSorting) {
      if (changeKey === 'overrideAggregations') {
        this.resetFiltersfromCategory()
      }
      this.resetSortingfromCategory()
      this.loadSortingByFilters()
      return
    }

    if (!customFilters && !customSorting) {
      this.modalDetail.aggregations = []
      this.modalDetail.aggregationSorting = {}
      return
    }

    if (!customFilters && customSorting) {
      this.modalDetail.aggregations = []
      this.resetSortingfromCategory()
      this.alignSortingToLatestAggregations()
      return
    }

    if (customFilters && !customSorting) {
      if (changeKey === 'overrideAggregations') {
        this.resetFiltersfromCategory()
      }
      this.modalDetail.aggregationSorting = {}
      return
    }
  }

  setModalDetail(value, key = '') {
    this.modalDetail = !key
      ? value
      : {
          ...this.modalDetail,
          [key]: value,
        }

    if (
      key === 'overrideAggregations' ||
      key === 'overrideAggregationSorting'
    ) {
      this.onFiltersOrSortingRadioChange(key)
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchCategories()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchCategories()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchCategories()
  }

  setParams(params) {
    this.params = params
    this.fetchCategories()
  }

  getById(id) {
    return this.categories.find((s) => s.id == id)
  }

  *fetchCategories() {
    this.state = 'pending'
    try {
      const { data, total } = yield api.categories.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
        params: this.params,
      })

      this.categories = data
      this.categories.forEach((item) => (this.categoriesById[item.id] = item))
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the category listing.')
      this.state = 'error'
    }
  }

  *updateOrCreate(stream) {
    this.updating = true
    try {
      if (stream.id) {
        yield api.categories.update(stream)
      } else {
        yield api.categories.create(stream)
      }
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    } finally {
      this.updating = false
    }
    this.fetchCategories()
  }

  *delete(id) {
    try {
      yield api.categories.delete(id)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchCategories()
  }

  *sync() {
    this.state = 'pending'
    try {
      yield api.categories.sync()
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchCategories()
  }

  *fetchAggregation() {
    try {
      const { data } = yield api.aggregation.getAll({
        pagination: { pageSize: 1000 },
      })
      this.aggregations = data
      data.forEach((item) => (this.aggregationsById[item.id] = item))
    } catch (error) {
      toast.error('Something went wrong loading the aggregations listing.')
      this.state = 'error'
    }
  }
}

export default new CategoryStore()
