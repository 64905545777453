import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import { debounce } from 'lodash'

import api from '../../api'
import t from '../../utils/translate'
import { delay } from '../../utils'
import { Recommendation } from '../../models'
import UIStore from '../UIStore'
import ABTestStore from '../ABTestStore'

class RecommendationStore {
  recommendations = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = {}
  state = 'pending' // "pending", "done" or "error"
  previewState = '' // "pending", "done" or "error"
  availableTypes = [
    'pictureSimilarity',
    'textSimilarity',
    'boughtTogether',
    'behaviourHistoryCartBased',
    'behaviourHistoryUserBased',
    'none',
  ]
  searchList = []
  searchListByIds = {}
  recommendationPreviewList = []
  recoDetail = {}
  isDirty = false
  isFetchingReco = false
  previewProductsCount = 0
  previewPage = 0
  MAX_PRODUCTS_IN_PREVIEW = 20
  productIds = []
  debounceFetchRecommendationPreviewList = debounce((request) => {
    return this.fetchRecommendationPreviewList(request)
  }, 300)
  previewLanguage = UIStore.enterpriseConfiguration.defaultLanguage

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchRecommendations()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchRecommendations()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchRecommendations()
  }

  getById(id) {
    return this.recommendations.find((s) => s.id == id)
  }

  get typeLabels() {
    return {
      pictureSimilarity: t('Picture Similarity'),
      textSimilarity: t('Text Similarity'),
      boughtTogether: t('Bought together (Association rules)'),
      behaviourHistoryCartBased: t('Behavior history (Cart based)'),
      behaviourHistoryUserBased: t('Behavior history (User based)'),
      none: t('None'),
    }
  }

  get availableTypeOptions() {
    return this.availableTypes.map((item) => ({
      value: item,
      label: this.typeLabels[item],
    }))
  }

  *fetchRecommendations(initial) {
    this.state = 'pending'
    if (initial) {
      this.filter = {}
    }
    try {
      const { data, total } = yield api.recommendations.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.recommendations = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the recommendation listing.')
      this.state = 'error'
    }
  }

  *updateOrCreate() {
    this.state = 'pending'
    try {
      if (this.recoDetail.id) {
        yield api.recommendations.update(this.recoDetail)
        toast.success('Recommendation updated!')
        this.state = 'done'
        this.isDirty = false
      } else {
        const { data } = yield api.recommendations.create(this.recoDetail)
        toast.success('Recommendation created!')
        this.state = 'done'
        this.isDirty = false
        return data.id
      }
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *delete(ids) {
    this.state = 'pending'
    try {
      yield api.recommendations.delete(ids)
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchRecommendations()
  }

  *fetchAvailableTypes(shopId) {
    try {
      const { data } = yield api.recommendations.getAvailableTypes(shopId)
      this.availableTypes = data
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *fetchSearchList(request) {
    this.previewState = 'pending'

    const { searchPhrase, shopId, language } = request
    if (searchPhrase === '') return
    try {
      const data = yield api.common.fetchSearchResultPreview({
        searchPhrase,
        shopId,
        language,
        fields: ['id', 'title', 'active', 'picture_url_main', 'ean'],
        count: 200,
      })

      this.searchList = data?.product?.items || []
      this.searchList.forEach((item) => (this.searchListByIds[item.id] = item))
    } catch (error) {
      this.previewState = 'error'
      toast.error('Something went wrong...')
    } finally {
      this.previewState = 'done'
    }
  }

  *fetchRecommendationPreviewList(request) {
    this.previewState = 'pending'

    const {
      currentLanguage,
      shopId,
      productId,
      recommendation,
      page,
      changedVariable,
      group,
    } = request

    if (productId) {
      this.productIds = productId
    }

    if (
      !this.productIds?.length &&
      recommendation.recommendationType !== 'none'
    ) {
      this.recommendationPreviewList = []
      this.previewProductsCount = 0
      this.previewState = 'done'
      this.previewPage = 0
      return
    }

    let updatedPreviewPageIndex = page || 0

    if (changedVariable === 'productId') {
      this.productIds = productId
      updatedPreviewPageIndex = 0
    }

    try {
      const data = yield api.recommendations.getRecommendationList({
        ...recommendation,
        constraints: {
          'query.language': currentLanguage,
          'query.shop_id': shopId,
          'query.group': group,
        },
        fields: ['id', 'title', 'active', 'picture_url_main', 'ean'],
        count: this.MAX_PRODUCTS_IN_PREVIEW,
        productId: this.productIds,
        isActive: true,
        recommendationId: undefined,
      })
      this.recommendationPreviewList = data?.data?.items || []
      this.previewPage = updatedPreviewPageIndex
      this.previewProductsCount = 0
    } catch (error) {
      console.log(error)
      this.previewState = 'error'
    } finally {
      this.previewState = 'done'
    }
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  *fetchRecoDetail(id) {
    this.isFetchingReco = true
    this.recoDetail = {}
    this.productIds = []
    this.recommendationPreviewList = []

    if (id === 'new' || ABTestStore.showMetaconfigView) {
      yield delay(100)
      this.recoDetail = new Recommendation()
      this.isFetchingReco = false
      return
    }

    try {
      const { data } = yield api.recommendations.get(id)
      if (data.filter?.length > 0) {
        data.filter = data.filter.map((item) => ({ ...item, uuid: uuidv4() }))
      }
      if (data.boosting?.length > 0) {
        data.boosting = data.boosting.map((item) => ({
          ...item,
          uuid: uuidv4(),
        }))
      }
      this.recoDetail = data
      this.isFetchingReco = false
    } catch (error) {
      this.isFetchingReco = false
      this.state = 'error'
      toast.error('Something went wrong fetching recommendation detail...')
    }
  }

  setRecoDetail(data, newData) {
    Object.keys(newData).forEach((key) => (data[key] = newData[key]))
    this.isDirty = true
    this.debounceFetchRecommendationPreviewList({
      currentLanguage: this.previewLanguage,
      shopId: UIStore.shopId,
      recommendation: data,
      productId: this.productIds,
    })
  }

  onChangeField(data, key, value) {
    data[key] = value
    this.isDirty = true
    this.debounceFetchRecommendationPreviewList({
      currentLanguage: this.previewLanguage,
      shopId: UIStore.shopId,
      recommendation: this.currentRecoDetail,
      productId: this.productIds,
    })
  }

  openFilterModal(filter, type) {
    if (filter) {
      this.editingFilter = filter
      this.isCreateNewFilter = false
    } else {
      this.editingFilter = {}
      this.isCreateNewFilter = true
    }

    this.filterMoldalType = type

    this.filterModalVisible = true
  }

  closeFilterModal() {
    this.filterModalVisible = false
  }

  saveFilter(filter, type) {
    const list = this.currentRecoDetail[type] || []

    const position = list.findIndex((item) => item.uuid === filter.uuid)

    if (position !== -1) {
      list[position] = filter
    } else {
      list.push({ ...filter, uuid: uuidv4() })
    }

    this.isDirty = true
    this.debounceFetchRecommendationPreviewList({
      currentLanguage: this.previewLanguage,
      shopId: UIStore.shopId,
      recommendation: this.currentRecoDetail,
      productId: this.productIds,
    })
  }

  setEditingFilter(filter) {
    this.editingFilter = filter
  }

  removeFilter(uuid, type) {
    this.currentRecoDetail[type] = (this.currentRecoDetail[type] || []).filter(
      (item) => item.uuid !== uuid
    )
    this.isDirty = true

    this.debounceFetchRecommendationPreviewList({
      currentLanguage: this.previewLanguage,
      shopId: UIStore.shopId,
      recommendation: this.currentRecoDetail,
      productId: this.productIds,
    })
  }

  get maxPreviewPage() {
    return Math.ceil(this.previewProductsCount / this.MAX_PRODUCTS_IN_PREVIEW)
  }

  applyPreset(id) {
    const newReco = new Recommendation()
    newReco.name = this.currentRecoDetail.name
    newReco.recommendationId = this.currentRecoDetail.recommendationId

    switch (id) {
      case 'sameyear':
        newReco.recommendationType = 'boughtTogether'
        newReco.filter = [
          {
            field: 'insert',
            operator: 'sameYear',
            type: 'date',
            compareWith: 'inputProduct',
            value: 'insert',
          },
        ]
        break

      case 'accessories':
        newReco.recommendationType = 'boughtTogether'
        newReco.filter = [
          {
            field: 'price',
            operator: 'lta',
            type: 'double',
            compareWith: 'inputProduct',
            value: 'price',
          },
        ]
        break

      case 'justin':
        newReco.recommendationType = 'boughtTogether'
        newReco.filter = [
          {
            field: 'soldamount',
            operator: 'lt',
            type: 'long',
            compareWith: 'inputProduct',
            value: 'soldamount',
          },
        ]
        newReco.boosting = [
          {
            field: 'soldamount',
            operator: 'useValue',
            type: 'long',
            boostingStrength: 'med',
          },
        ]
        break

      case 'alsobought':
        newReco.recommendationType = 'behaviourHistoryCartBased'
        newReco.boostWithSimilarAttributes = true
        break

      default:
        break
    }
    Object.keys(newReco).forEach(
      (key) => (this.currentRecoDetail[key] = newReco[key])
    )
    this.isDirty = true
    this.debounceFetchRecommendationPreviewList({
      currentLanguage: this.previewLanguage,
      shopId: UIStore.shopId,
      recommendation: this.currentRecoDetail,
      productId: this.productIds,
    })
  }

  get currentRecoDetail() {
    if (ABTestStore.showMetaconfigView) {
      return (
        ABTestStore.recommendationVariantsConfig.configs.find(
          (config) => config.variation_name === ABTestStore.selectedVariant
        )?.settings || {}
      )
    }

    return this.recoDetail
  }

  fetchPreviewByRecommendation() {
    const reco =
      ABTestStore.recommendationVariantsConfig.configs.find(
        (config) => config.variation_name === ABTestStore.selectedVariant
      ) || {}

    this.debounceFetchRecommendationPreviewList({
      currentLanguage: this.previewLanguage,
      shopId: UIStore.shopId,
      recommendation: reco,
      productId: this.productIds,
    })
  }

  onChangePreviewLanguage(language, fetchPreview = true) {
    this.previewLanguage = language
    if (fetchPreview) {
      this.fetchRecommendationPreviewList({
        currentLanguage: this.previewLanguage,
        shopId: UIStore.shopId,
        recommendation: this.recoDetail,
        productId: this.productIds,
      })
    }
  }
}

export default new RecommendationStore()
