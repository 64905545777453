import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import Text from '../../components/Text'

import ChangedField from '../../components/ChangedField'
import ResourceTable from '../../components/ResourceTable'
import { Column, ArrowAction } from '../../components/Table'

import SmartBundleStore from '../../stores/SmartBundleStore'
import ActionLayerStore from '../../stores/ActionLayerStore'

import t from '../../utils/translate'
import createRedirectPath from '../../utils/createRedirectPath'
import { routes } from '../../routing'

import './bundle.styl'
import ActiveField from '../../components/ActiveField'

function StreamList() {
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState([])
  const { requireImport, setRequireImport, setFilter } = SmartBundleStore

  useEffect(() => {
    SmartBundleStore.fetchBundles()
    showRequireImportWarning()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    showRequireImportWarning()
    // eslint-disable-next-line
  }, [requireImport])

  const showRequireImportWarning = () => {
    if (requireImport) {
      // This action should run at the end of the thread. otherwise i will got close from edit process.
      setTimeout(() => {
        ActionLayerStore.openActionLayer({
          header: t('Full Import required'),
          content: t(
            'The changes of your bundles require a full import of your data, please trigger the full import after you added all changes to your bundles.'
          ),
          saveTitle: 'OK',
          onSave: () => {
            setRequireImport(false)
          },
        })
      }, 500)
    }
  }

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    await SmartBundleStore.delete(selectedRows)

    if (SmartBundleStore.state !== 'error') {
      setSelectedRows([])
      setRequireImport(true)
      return true
    }

    return false
  }

  const bulkActions = [
    {
      title: t('Delete'),
      onExecute: () => showDeleteWarning(),
    },
  ]

  return (
    <div className="content-wrapper">
      <ResourceTable
        title={t('Smart Bundles')}
        store={SmartBundleStore}
        resourceName="bundles"
        filterParams="filter"
        filters={[
          {
            type: 'search',
            title: t('Search'),
            name: 'q',
            onChange: setFilter,
          },
        ]}
        headerAction={{
          type: 'create',
          action: () => history.push(createRedirectPath(routes.bundleNew.path)),
        }}
        loading={SmartBundleStore.state === 'pending'}
        bulkActions={bulkActions}
        rowSelection={{
          selectedRows,
          onSelectionChange: (newRows) => setSelectedRows(newRows),
        }}
        onRowClick={(record) =>
          history.push(createRedirectPath(`/bundle/${record.id}`))
        }
        emptyText={t(
          'Create a Smart Bundle to let your customers choose multiple products in a row\n' +
            'that belong together or to create more revenue through cross- and upselling.'
        )}
        createAction={() =>
          history.push(createRedirectPath(routes.bundleNew.path))
        }
        createActionText={t('Create Smart Bundle')}
      >
        <Column
          width={70}
          title={t('Active')}
          dataIndex="active"
          align="center"
          render={(value) => <ActiveField active={value} />}
        />
        <Column
          title={t('Title (ID)')}
          dataIndex="internal_title"
          sorter={true}
          customSortName="internalTitle"
          className="mk-table-cell--bold"
          render={(_, record) => (
            <Text weight="bold" element="div">
              {record.internal_title}{' '}
              <span className="bundle__list-id">({record.id})</span>
            </Text>
          )}
        />
        <Column
          title={t('Last changed')}
          dataIndex="changed"
          sorter={true}
          align="right"
          width={200}
          render={(_, record) => <ChangedField {...record} />}
        />
        <Column width={50} render={() => <ArrowAction />} />
      </ResourceTable>
    </div>
  )
}

export default observer(StreamList)
