import api from '../../api'

async function checkMakairaURL(url, id = '', type = '') {
  if (url === '' || url === undefined) {
    return true
  }

  const res = await api.common.checkURLExists({ id, type, url })

  return res.success
}

export default checkMakairaURL
