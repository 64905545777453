export default function normalizeTitleField(doc) {
  return {
    ...doc,
    title:
      doc.raw_title ??
      doc.manufacturer_title ??
      doc.category_title ??
      doc.title ??
      doc.words?.join(', '),
  }
}
