import { observer } from 'mobx-react-lite'
import Icon from '../../Icon'
import Select from '../../Select'
import { CategoryStore, FilterSectionStore } from '../../../stores'

function CategoryField() {
  const { categories } = CategoryStore

  const { editingFilter, setEditingFilter } = FilterSectionStore

  if (editingFilter.field !== 'category.catid') {
    return null
  }

  return (
    <>
      <Icon width="20px" height="20px" symbol="arrow-down" />
      <Select
        style={{ minWidth: '11.5rem' }}
        flexible
        dropdownMatchSelectWidth={false}
        options={categories.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
        value={editingFilter.value}
        onChange={(value) =>
          setEditingFilter({
            ...editingFilter,
            operator: 'inList',
            value,
          })
        }
      />
    </>
  )
}

export default observer(CategoryField)
