import { observer } from 'mobx-react-lite'

import PageTitleComponent from '../../components/PageTitle'
import t from '../../utils/translate'
import { routes } from '../../routing'

const PageTitle = () => {
  return (
    <PageTitleComponent
      className="search-configuration__page-title"
      tabData={[
        {
          route: routes.userList,
          title: t('Users'),
        },
        {
          route: routes.rolesList,
          title: t('Roles'),
        },
      ]}
    />
  )
}

export default observer(PageTitle)
