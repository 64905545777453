import { t } from '../../../utils'
import moment from 'moment'
import { UIStore } from '../../../stores'

export const levelOptions = [
  { value: undefined, label: t('All') },
  { value: 6, label: t('INFO') },
  { value: 3, label: t('ERROR') },
  { value: 2, label: t('CRITICAL') },
]

export function getGroupOptions(scheduleStatus) {
  moment.locale(UIStore.currentLanguage)
  const runningOptions = makeRunningOption(scheduleStatus)
  const passOptions = makePassOption(scheduleStatus)
  const scheduleOptions = [
    {
      label: '',
      children: [{ value: undefined, label: t('All importers') }],
    },
  ]
  if (runningOptions) {
    scheduleOptions.push(runningOptions)
  }
  if (passOptions) {
    scheduleOptions.push(passOptions)
  }

  return scheduleOptions
}

function makeRunningOption(scheduleStatus) {
  const makeLabel = (data, title) => {
    const type = data.type === 'active' ? t('live') : t('rebuild')
    let languages = data.languages.length === 0 ? ['en', 'de'] : data.languages
    return `${title} (${type} - ${languages.join(', ')})`
  }

  const options = scheduleStatus
    .filter((item) => !!item.current)
    .map((item) => ({
      value: item.current.name,
      label: makeLabel(item.current, item.title),
    }))

  if (!options.length) {
    return
  }

  return {
    label: t('running'),
    children: options,
  }
}

function makePassOption(scheduleStatus) {
  const options = [
    ...scheduleStatus
      .reduce((result, item) => {
        const items = item.history.map((o) => ({
          ...o,
          type: item.type,
          title: item.title,
        }))
        return [...result, ...items]
      }, [])
      .sort((a, b) => {
        return moment(a.creationTimestamp).isBefore(b.creationTimestamp)
          ? 1
          : -1
      })
      .map((item) => ({
        value: item.name,
        label: `${item.title} (${t(item.type)} - ${moment(
          item.creationTimestamp
        )
          .local()
          .fromNow()})`,
      }))
      .slice(0, 10),
  ]

  if (!options.length) {
    return
  }

  return {
    label: t('ended'),
    children: options,
  }
}
