import t from '../../utils/translate'

export const baseFeatures = [
  {
    name: t('Page Editor'),
    key: 'pageeditor',
    section: 'Marketing',
  },
  {
    name: t('A/B Tests'),
    key: 'abtesting',
    section: 'Marketing',
  },
  {
    name: t('Search'),
    key: 'search',
    section: 'Marketing',
  },
  {
    name: t('Categories'),
    key: 'categories',
    section: 'Marketing',
  },
  {
    name: t('Personalization'),
    key: 'personalization',
    section: 'Marketing',
  },
  {
    name: t('Recommendations'),
    key: 'recommendations',
    section: 'Marketing',
  },
  {
    name: t('Banners'),
    key: 'banner',
    section: 'Marketing',
  },
  {
    name: t('Advertising Spaces'),
    key: 'placement',
    section: 'Marketing',
  },
  {
    name: t('Redirects'),
    key: 'redirects',
    section: 'Marketing',
  },
  {
    name: t('menu_bundle'),
    key: 'smartbundle', //
    section: 'Marketing',
  },
  {
    name: t('Importer'),
    key: 'importer',
    section: 'Data',
  },
  {
    name: t('Index'),
    key: 'indices',
    section: 'Data',
  },
  {
    name: t('menu_expert_data_inspector'),
    key: 'datainspector',
    section: 'Data',
  },
  {
    name: t('Feeds'),
    key: 'productfeeds',
    section: 'Data',
  },
  {
    name: t('Streams'),
    key: 'streams',
    section: 'Data',
  },
  {
    name: t('Category Mapping'),
    key: 'categorymapping', // TODO: check again BE key
    section: 'Data',
  },
  {
    name: t('Roles'),
    key: 'rbac',
    section: 'Administration',
  },
  {
    name: t('Storefront Settings'),
    key: 'storefront',
    section: 'Administration',
  },
  {
    name: t('Audit Logs'),
    key: 'auditlog',
    section: 'Administration',
  },
  {
    name: t('Component Editor'),
    key: 'component',
    section: 'Administration',
  },
  {
    name: t('Settings'),
    key: 'settings',
    section: 'Administration',
  },
  {
    name: t('API Keys'),
    key: 'secret',
    section: 'Administration',
  },
  {
    name: t('Users'),
    key: 'user',
    section: 'Administration',
  },
]

export default baseFeatures
