import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Form, Formik } from 'formik'
import FieldStore from '../../stores/FieldStore'
import SmartBundleStore from '../../stores/SmartBundleStore'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import FieldConditions from '../../components/FieldConditions'
import t from '../../utils/translate'
import Text from '../../components/Text'

function EditModal() {
  const { isEditModalVisible, slotToEdit } = SmartBundleStore

  useEffect(() => {
    FieldStore.fetchFields()
  }, [])

  const { streamFields } = FieldStore

  const handleSubmit = (values) => {
    SmartBundleStore.updateSlot(values)
  }

  const slotPosition = SmartBundleStore.getSlotPosition(slotToEdit)

  return (
    <Modal
      className="bundle-modal"
      visible={isEditModalVisible}
      header={t('Products for Slot')(slotPosition)}
      footer={[
        <Button
          key="delete"
          level={1}
          variant="secondary"
          className="bundle-modal__group-delete"
          onClick={() => SmartBundleStore.deleteSlot(slotToEdit)}
        >
          {t('Delete')}
        </Button>,
        <Button
          key="cancel"
          level={1}
          variant="secondary"
          onClick={() => SmartBundleStore.closeEditModal()}
        >
          {t('Cancel')}
        </Button>,
        <Button
          key="submit"
          level={1}
          variant="primary"
          type="submit"
          form="editModal"
        >
          {t('Submit')}
        </Button>,
      ]}
      onClose={() => SmartBundleStore.closeEditModal()}
      width={800}
      centered={true}
    >
      <Formik
        key={slotToEdit.id}
        initialValues={slotToEdit}
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          const { values } = formikProps

          return (
            <Form id="editModal">
              <Text element="p">
                {t('Choose the filter criteria for the products of Slot')(
                  slotPosition
                )}
              </Text>
              <FieldConditions
                name="base_stream.and"
                title={t('All conditions must be met:')}
                conditions={values.base_stream.and}
                fields={streamFields}
                formikProps={formikProps}
              />

              <FieldConditions
                name="base_stream.or"
                title={t('At least one condition must be met:')}
                conditions={values.base_stream.or}
                fields={streamFields}
                formikProps={formikProps}
              />
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default observer(EditModal)
