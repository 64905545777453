import { useEffect } from 'react'
import { Prompt } from 'react-router'
import t from '../translate'

// Custom messages not supported everywhere
// A window's onbeforeunload property no longer supports a custom string.
// https://developers.google.com/web/updates/2016/04/chrome-51-deprecations?hl=en#remove_custom_messages_in_onbeforeunload_dialogs

function handleUnload(e) {
  e.preventDefault()
  const message = t(
    'There are unsaved changes in Makaira. Are you sure you want to leave this page?'
  )
  const event = e || window.event
  event.returnValue = message //Gecko + IE

  return message
}

export default function useLeaveConfirm(isDirty) {
  useEffect(() => {
    if (!isDirty) return

    window.addEventListener('beforeunload', handleUnload)

    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [isDirty])

  return (
    <Prompt
      when={isDirty}
      message={(_location, action) => {
        if (action === 'POP') {
          return t('You have unsaved changes. Are you sure you want to leave?')
        }
      }}
    />
  )
}
