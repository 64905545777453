import { useFormikContext } from 'formik'
import { useEffect } from 'react'

const UpdateStoreDirtyState = ({ store }) => {
  const { dirty } = useFormikContext()
  useEffect(() => {
    if (store.setDirty) {
      store.setDirty(dirty)
    }
  }, [dirty, store])

  return null
}

export default UpdateStoreDirtyState
