export const SOURCES_TYPE = {
  OXID: 'oxid',
  SHOPWARE: 'shopware',
  NDJSON: 'ndjson',
  PERSISTENCE_LAYER: 'persistence-layer',
  OTHER: 'default',
}

export const TARGET_TYPE = {
  makaira: 'Makaira Index',
  'persistence-layer': 'Persistence Layer',
}
