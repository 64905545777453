import React, { Component } from 'react'
import classnames from 'classnames'

import Button from '../../../components/Button'
import Icon from '../../../components/Icon'

import MenuEditorNode from '../MenuEditorNode'

import './MenuEditorNodeWrapper.styl'

function isDescendant(older, younger) {
  return (
    !!older.children &&
    typeof older.children !== 'function' &&
    older.children.some(
      (child) => child === younger || isDescendant(child, younger)
    )
  )
}

/**
 * Theme-Wrapper for a Tree Node of the react-sortable-tree package.
 * This is adaptation of the original node theme of react-sortable-tree.
 *
 * You can find the original file in the package-repo:
 * https://github.com/frontend-collective/react-sortable-tree/blob/master/src/node-renderer-default.js
 */
class MenuEditorNodeWrapper extends Component {
  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      title,
      subtitle,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      className,
      didDrop,
      treeHelper,
      parentNode,
      rowDirection,
      treeId,
      isOver,
      ...otherProps
    } = this.props

    let handle
    if (canDrag) {
      // Show the handle used to initiate a drag-and-drop
      handle = connectDragSource(
        <div
          className={classnames(
            'menu-editor-node__move-handle',
            node.expanded &&
              node.children.length > 0 &&
              'menu-editor-node__move-handle--bottom-border'
          )}
        >
          <Icon width={24} height={17} symbol="bars" />
        </div>,
        {
          dropEffect: 'copy',
        }
      )
    }

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node)
    const isLandingPadActive = !didDrop && isDragging

    return (
      <div style={{ height: '100%' }} {...otherProps}>
        {toggleChildrenVisibility &&
          node.children &&
          (node.children.length > 0 || typeof node.children === 'function') && (
            <div>
              {node.expanded && !isDragging && (
                <div
                  style={{ width: scaffoldBlockPxWidth }}
                  className={classnames('menu-editor-node__line-children')}
                />
              )}
            </div>
          )}

        <div className={classnames('menu-editor-node__row-wrapper')}>
          <div className="menu-editor-node__add-bar">
            <Button
              onClick={() => treeHelper.addChild(parentNode?.uuid, node.uuid)}
              variant="icon-only"
              icon="plus"
            />
          </div>
          {/* Set the row preview to be used during drag and drop */}
          {connectDragPreview(
            <div
              className={classnames(
                'menu-editor-node__row',
                isLandingPadActive && 'menu-editor-node__row-landing-pad',
                className
              )}
              style={{
                opacity: isDraggedDescendant ? 0.5 : 1,
              }}
            >
              {node.children && node.children.length > 0 && (
                <Button
                  level={1}
                  variant="reduced"
                  icon={node.expanded ? 'angles-up' : 'angles-down'}
                  aria-label={node.expanded ? 'Collapse' : 'Expand'}
                  className="menu-editor-node__collapse-button"
                  onClick={() =>
                    toggleChildrenVisibility({
                      node,
                      path,
                      treeIndex,
                    })
                  }
                />
              )}
              {handle}

              <div className={classnames('menu-editor-node__row-content')}>
                <MenuEditorNode {...treeHelper} node={node} />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

MenuEditorNodeWrapper.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  className: '',
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  subtitle: null,
}

export default MenuEditorNodeWrapper
