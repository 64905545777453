import { observer } from 'mobx-react-lite'
import { isNumber } from 'lodash'
import Modal from '../../Modal'
import { t } from '../../../utils'
import Button from '../../Button'
import { FieldStore, FilterSectionStore } from '../../../stores'
import ChooseField from './ChooseField'
import ChooseRule from './ChooseRule'
import { useMemo } from 'react'
import { SUPPORTED_FIELD_TYPES } from '../constants'
import { getOperator } from '../utils'
import { toJS } from 'mobx'

function FilterModal({ type, visible, onClose, onChange }) {
  const { editingFilter, mode, appendListFilter } = FilterSectionStore

  const allFields = FieldStore.fields

  let fieldOptions = useMemo(() => {
    return allFields.filter((el) => SUPPORTED_FIELD_TYPES.includes(el.type))
  }, [allFields])

  if (!visible) return <></>

  const { comparison } = getOperator({
    type: editingFilter.type,
    operator: editingFilter.operator,
  })

  const canCreate =
    editingFilter.value ||
    (!editingFilter.value && editingFilter.operator && !comparison) ||
    (isNumber(editingFilter.value) && comparison === 'number')

  const getHeader = () => {
    if (type === 'filter') {
      if (mode === 'create') {
        return t('Create a filter rule')
      } else {
        return (
          t('Edit the filter rule for') +
          ' ' +
          FieldStore.fieldsByIds[editingFilter.field].title
        )
      }
    }
    if (type === 'boosting') {
      if (mode === 'create') {
        return t('Create a boosting rule')
      } else {
        return (
          t('Edit the boosting rule for') +
          ' ' +
          FieldStore.fieldsByIds[editingFilter.field].title
        )
      }
    }
  }

  return (
    <Modal
      visible={visible}
      header={getHeader()}
      onClose={onClose}
      width={1000}
      mask
      maskClosable
      className="filter-section__filter-modal"
      footer={[
        <Button onClick={onClose} key="cancel">
          {t('Cancel')}
        </Button>,
        <Button
          onClick={() => {
            const list = appendListFilter(editingFilter, type)
            onClose && onClose()
            onChange && onChange(toJS(list))
          }}
          disabled={!canCreate}
          variant="primary"
          key="create"
        >
          {t(mode === 'create' ? 'Create' : 'Update')}
        </Button>,
      ]}
    >
      <div className="filter-section__filter-modal__content">
        {mode === 'create' && <ChooseField fieldOptions={fieldOptions} />}

        <ChooseRule
          type={type}
          canCreate={canCreate}
          fieldOptions={fieldOptions}
          mode={mode}
        />
      </div>
    </Modal>
  )
}

export default observer(FilterModal)
