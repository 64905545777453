import { observer } from 'mobx-react-lite'
import Text from '../../../components/Text'
import t from '../../../utils/translate'
import Icon from '../../../components/Icon'
import SmartBundleStore from '../../../stores/SmartBundleStore'

import './previewBundle.styl'
import classNames from 'classnames'

const PreviewBundle = () => {
  const { bundleToEdit } = SmartBundleStore
  const slots = bundleToEdit.slots || []

  return (
    <div className="preview-bundle">
      <div className="preview-bundle__section">
        <Text
          size="bravo"
          weight="light"
          className="preview-bundle__title"
          element="h1"
          variant="book"
        >
          {t('You can edit your bundle navigation’s content in this section.')}
        </Text>
        <div className="bundle-tab">
          {slots.map((slot) => {
            const slotPosition = SmartBundleStore.getSlotPosition(slot)

            return (
              <div
                key={slot.id}
                onClick={() => SmartBundleStore.setSelectedSlot(slot.id)}
                className={classNames('bundle-tab__item', {
                  'bundle-tab__item--active':
                    slot.id === SmartBundleStore.selectedSlotIdToEdit,
                })}
              >
                <div className="bundle-tab__image-placeholder">
                  <Icon symbol="image" className="bundle-tab__image" />
                </div>
                <div className="bundle-tab__title-placeholder" />
                <div className="bundle-tab__button-placeholder" />
                <Text
                  size="bravo"
                  element="p"
                  className="bundle-tab__group"
                  weight="medium"
                >{`${t('Slot')} ${slotPosition}`}</Text>
              </div>
            )
          })}

          <div className="bundle-tab__guide bundle-tab__guide--image">
            {t('Placeholder Image')}
          </div>
          <div className="bundle-tab__guide bundle-tab__guide--title">
            {t('Title')}
          </div>
          <div className="bundle-tab__guide bundle-tab__guide--button">
            {t('Buttontext')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(PreviewBundle)
