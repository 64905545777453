import Avatar from '../../../components/Avatar'
import ChangedField from '../../../components/ChangedField'

const LastUpdate = ({ data }) => {
  return (
    <div className="feeds__last-update">
      <Avatar email={data.user} />
      <ChangedField user={data.user} changed={data.changed} />
    </div>
  )
}

export default LastUpdate
