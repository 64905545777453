import { toJS } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import SortableTree, {
  toggleExpandedForAll,
} from '@makaira/react-sortable-tree'

import PageTitle from '../../components/PageTitle'
import MenuEditorNodeWrapper from './theme/MenuEditorNodeWrapper'
import ContentWrapper from '../../components/ContentWrapper'
import LoadingScreen from '../../components/LoadingScreen'

import UIStore from '../../stores/UIStore'
import MenuStore from '../../stores/MenuStore'
import ActionLayerStore from '../../stores/ActionLayerStore'

import './menuEditor.styl'
import '@makaira/react-sortable-tree/style.css'
import { t, useLeaveConfirm } from '../../utils'
import formatLanguageKey from '../../utils/formatLanguageKey'
// import Switch from '../../components/Switch'
import Select from '../../components/Select'
import Button from '../../components/Button'
import PrefillModal from './PrefillModal'
import SettingModal from './SettingModal'

const HEIGHTS = {
  padding: 20,
  row: 42,
  errorText: 21.5,
  default: 50,
}

function MenuEditor() {
  const [expandedAll, setExpandedAll] = useState(false)
  const [isDragging, setDragging] = useState(false)

  useEffect(() => {
    MenuStore.fetchMenu()
    UIStore.fetchDocumentTypes()
  }, [])

  const { sortedLanguages, languages } = UIStore
  const {
    menuData = {},
    isDirty,
    setDirty,
    closePrefillModal,
    closeSettingModal,
  } = MenuStore
  const { menu = [] } = menuData

  useLeaveConfirm(isDirty)

  useEffect(() => {
    if (isDirty && !isDragging) {
      ActionLayerStore.openActionLayer({
        onSave: MenuStore.saveMenu,
        onClose: MenuStore.resetMenu,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty, isDragging])

  useEffect(() => {
    return () => {
      setDirty(false)
      closePrefillModal()
      closeSettingModal()
    }
    // eslint-disable-next-line
  }, [])

  function addChild(menu, parentId, nodeId) {
    function createNewNode() {
      const newId = uuidv4()

      return {
        uuid: newId,
        key: newId, // we need this key field for compatibility with ant-design <Tree>
        text: languages.reduce(
          (acc, lang) => ({
            ...acc,
            [lang]: '',
          }),
          {}
        ),
        link: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
        isRedirectOnly: false,
        css: '',
        esId: '',
        children: [],
        isEditing: true,
        isNewNode: true,
      }
    }

    if (!parentId) {
      const newNode = createNewNode()

      if (nodeId) {
        const nodeIndex = menu.findIndex((node) => node.uuid === nodeId)
        const newMenu = [...menu]
        newMenu.splice(nodeIndex + 1, 0, newNode)
        return newMenu
      }

      return [...menu, newNode]
    }

    function addById(entry) {
      if (entry.uuid === parentId) {
        const newNode = createNewNode()
        // expand parent node
        entry.expanded = true
        if (nodeId) {
          const nodeIndex = entry.children.findIndex(
            (node) => node.uuid === nodeId
          )
          entry.children.splice(nodeIndex + 1, 0, newNode)
        } else {
          entry.children = [...entry.children, newNode]
        }
      } else {
        entry.children = addChild(entry.children, parentId)
      }

      return entry
    }

    return menu.map(addById)
  }

  function removeNode(menu, id) {
    function removeById(entry) {
      if (entry.uuid === id) {
        return null
      }

      entry.children = removeNode(entry.children, id)

      return entry
    }

    return menu.map(removeById).filter(Boolean)
  }

  function updateNode(menu, id, value) {
    function updateById(entry) {
      if (entry.uuid === id) {
        return value
      }

      entry.children = updateNode(entry.children, id, value)

      return entry
    }

    return menu.map(updateById)
  }

  const treeHelper = {
    addChild: (parentId, nodeId) => {
      const updated = addChild(toJS(MenuStore.menuData.menu), parentId, nodeId)

      MenuStore.updateMenu(updated)
    },
    removeNode: (id) => {
      const updated = removeNode(toJS(MenuStore.menuData.menu), id)

      MenuStore.updateMenu(updated)
    },
    updateNode: (id, value, setDirty) => {
      const updated = updateNode(toJS(MenuStore.menuData.menu), id, value)

      MenuStore.updateMenu(updated, setDirty)
    },
    toggleCollapsedForAll: (expanded) => {
      const updated = toggleExpandedForAll({
        treeData: toJS(MenuStore.menuData.menu),
        expanded,
      })
      MenuStore.updateMenu(updated, false)
    },
  }

  const handleCollaseAll = () => {
    treeHelper.toggleCollapsedForAll(!expandedAll)
    setExpandedAll(!expandedAll)
  }

  const getTotalNodeErrors = (node) => {
    if (!node.errors) return 0
    return Object.values(node.errors).filter((invalid) => !!invalid).length
  }

  if (MenuStore.state === 'pending') return <LoadingScreen />

  return (
    <>
      <PageTitle
        prefix={t('You are editing')}
        lastChanged={{
          timestamp: menuData.changed?.date,
          timezone: menuData.changed?.timezone,
          user: menuData.user,
        }}
      >
        {t('Menu')}
      </PageTitle>

      <ContentWrapper className="menu-editor">
        <div className="menu-editor__wrapper">
          <div className="menu-editor__header">
            {/* <div className="menu-editor__switch-wrapper">
              <Switch />
              <div className="switch-input__label">{t('Bulk Edit Mode')}</div>
            </div> */}
            <Button
              variant="tertiary"
              icon={expandedAll ? 'angles-up' : 'angles-down'}
              iconPosition="left"
              onClick={handleCollaseAll}
            >
              {t(expandedAll ? 'Collapse all' : 'Expand all')}
            </Button>
            {menu.length > 1 && (
              <Button
                variant="tertiary"
                icon="plus"
                iconPosition="left"
                onClick={() => treeHelper.addChild()}
              >
                {t('Add menu entry')}
              </Button>
            )}
            <div className="switch-input__label">
              {t('Show titles and urls in')}
            </div>
            <Select
              value={MenuStore.contentLanguage}
              options={sortedLanguages.map((lang) => ({
                label: formatLanguageKey(lang),
                value: lang,
              }))}
              onChange={(lang) => MenuStore.setContentLanguage(lang)}
            />
          </div>
          <SortableTree
            treeData={menu}
            isVirtualized={false}
            theme={{
              nodeContentRenderer: (props) => (
                <MenuEditorNodeWrapper {...props} treeHelper={treeHelper} />
              ),
              rowHeight: (data) =>
                data.node.isEditing
                  ? HEIGHTS.padding +
                    HEIGHTS.row * languages.length +
                    HEIGHTS.errorText * getTotalNodeErrors(data.node)
                  : HEIGHTS.default,
            }}
            onChange={(updated) => MenuStore.updateMenu(updated, false)}
            onMoveNode={({ treeData }) => MenuStore.updateMenu(treeData)}
            onDragStateChanged={({ isDragging }) => setDragging(isDragging)}
          />
          <Button
            variant="tertiary"
            icon="plus"
            iconPosition="left"
            className="menu-editor__add-button"
            onClick={() => treeHelper.addChild()}
          >
            {t('Add menu entry')}
          </Button>
        </div>
      </ContentWrapper>
      <PrefillModal updateNode={treeHelper.updateNode} />
      <SettingModal updateNode={treeHelper.updateNode} />
    </>
  )
}

export default observer(MenuEditor)
