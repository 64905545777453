import { useState, useCallback, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'

import TextInput from '../TextInput'
import Text from '../Text'
import Result from './Result'
import { MenuStore, UIStore } from '../../stores'
import { debounce, flattenDocument, normalizeTitleField, t } from '../../utils'
import api from '../../api'
import './menuEditorSearch.styl'

function SearchPreview(props) {
  const inputRef = useRef()

  const [searchResult, setSearchResult] = useState({})
  const [isFetching, setFetching] = useState(false)
  const [searchPhrase, setSearchPhrase] = useState('')

  useEffect(() => {
    if (props.focus) {
      inputRef?.current?.focus({
        cursor: 'start',
      })
    }
  }, [inputRef, props.focus])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateSearchResult = useCallback(
    debounce(updateSearchResult, 500),
    [props.type, props.limit, props.fields]
  )

  async function updateSearchResult(newSearchPhrase) {
    setSearchPhrase(newSearchPhrase)

    if (!newSearchPhrase) {
      setSearchResult({})
      return
    }

    setFetching(true)

    try {
      const result = await api.common.fetchSearchResultPreview({
        searchPhrase: newSearchPhrase,
        shopId: UIStore.shopId,
        datatypes: UIStore.documentTypes
          .map((type) => type.id)
          .filter((typeId) => typeId != 'searchredirect'),
        language: MenuStore.contentLanguage,
        count: props.limit,
        fields: props.fields,
      })

      setSearchResult(result)

      if (props.onDataFetched) {
        let data = []
        Object.entries(result).forEach(([type, resultData]) => {
          const { items = [] } = resultData
          if (props.type && props.type === type) {
            data = items
          }
        })
        return props.onDataFetched(data)
      }
    } catch (error) {
      console.error(error)
      toast.error('Could not fetch search result.')
    } finally {
      setFetching(false)
    }
  }

  const isEmptyResult = Object.values(searchResult).every(
    (value) => !value.items || value.items.length === 0
  )

  return (
    <>
      <div className="search-preview__inputs">
        <TextInput
          ref={inputRef}
          rounded
          icon="magnifying-glass"
          label={
            <Text size="bravo" className="search-preview__label">
              {props.searchLabel ||
                t('Where would you like your entry to link to?')}
            </Text>
          }
          loading={isFetching}
          onChange={(event) => debouncedUpdateSearchResult(event.target.value)}
          description={props.extraDescription}
        />
      </div>

      <div className="search-preview__result-wrapper">
        {isEmptyResult && (
          <Text className="search-preview__no-result">
            {searchPhrase
              ? t('No data')
              : t('Type a search word above to get your first results shown.')}
          </Text>
        )}
        {!isEmptyResult && props.resultHeader}
        {!isEmptyResult &&
          Object.entries(searchResult).map(([type, result]) => {
            const { items = [] } = result

            if (props.type && props.type !== type) return null

            if (props.renderItem) {
              return items
                .map(flattenDocument)
                .map(normalizeTitleField)
                .map((item, index) =>
                  props.renderItem({
                    item,
                    type,
                    onAssignment: props.onAssignment,
                    showEAN: props.showEAN,
                    showImage: props.showImage,
                    position: index + 1,
                  })
                )
            }

            return (
              <Result
                key={type}
                type={type}
                items={items.map(flattenDocument).map(normalizeTitleField)}
                onAssignment={props.handleAssignment}
                showEAN={props.showEAN}
                showImage={props.showImage}
                showURL={props.showURL}
              />
            )
          })}
      </div>
    </>
  )
}

export default observer(SearchPreview)
