import { observer } from 'mobx-react-lite'
import React from 'react'
import Select from '../../components/Select'
import Switch from '../../components/Switch'
import Text from '../../components/Text'
import { MonitoringStore, UIStore } from '../../stores'
import { formatLanguageKey, t } from '../../utils'

const Filter = () => {
  return (
    <>
      <Text weight="bold" size="echo">
        {t('Most frequently used search terms')}
      </Text>
      <div className="search-monitoring__filter-row">
        <Select
          value={MonitoringStore.filter.since}
          translateLabel
          options={[
            { label: 'Last 24h', value: '1d' },
            { label: 'Last week', value: '1w' },
            { label: 'Last 4 weeks', value: '1M' },
            { label: 'Last 6 months', value: '6M' },
            { label: 'Last 9 months', value: '9M' },
            { label: 'Last 12 months', value: '1y' },
          ]}
          flexible
          dropdownMatchSelectWidth={false}
          onChange={(val) =>
            MonitoringStore.setFilter({
              ...MonitoringStore.filter,
              since: val,
            })
          }
        />
        <Select
          value={MonitoringStore.filter.language}
          translateLabel
          options={UIStore.languages.map((language) => ({
            value: language,
            label: formatLanguageKey(language),
          }))}
          flexible
          dropdownMatchSelectWidth={false}
          onChange={(val) =>
            MonitoringStore.setFilter({
              ...MonitoringStore.filter,
              language: val,
            })
          }
        />
        <Switch
          value={MonitoringStore.filter.includeBotTraffic}
          onChange={(val) =>
            MonitoringStore.setFilter({
              ...MonitoringStore.filter,
              includeBotTraffic: val ? 1 : 0,
            })
          }
          type="horizontal"
          title={t('Include bot traffic')}
        />
      </div>
    </>
  )
}

export default observer(Filter)
