import t from '../../utils/translate'

const mergeFiltersWithDefaultFilters = (filters, onChange = () => {}) => {
  const combinedFilters = [
    {
      type: 'datepicker',
      title: t('Between…'),
      placeholder: t('Select Timestamp'),
      name: 'after',
      showTime: true,
      dataFormat: (date) => (date ? date.toISOString() : ''),
      onChange: onChange,
      position: 9,
    },
    {
      type: 'datepicker',
      title: t('…and'),
      placeholder: t('Select Timestamp'),
      name: 'before',
      showTime: true,
      dataFormat: (date) => (date ? date.toISOString() : ''),
      onChange: onChange,
      position: 10,
    },
  ]

  filters.forEach((filter) => {
    const filterWithCorrectListener = { ...filter }

    filterWithCorrectListener.onChange = onChange

    combinedFilters.push(filterWithCorrectListener)
  })

  return combinedFilters.sort((filter1, filter2) => {
    const pos1 = filter1.position ?? 0
    const pos2 = filter2.position ?? 0

    return pos1 - pos2
  })
}

export default mergeFiltersWithDefaultFilters
