import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Tooltip } from 'antd'
import Icon from '../../components/Icon'
import ResourceTable from '../../components/ResourceTable'
import { ArrowAction, Column } from '../../components/Table'
import Text from '../../components/Text'
import { ActionLayerStore, ComponentStore } from '../../stores'
import { createRedirectPath, t } from '../../utils'
import UsageModal from './UsageModal'

import './style.styl'

const List = () => {
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState([])
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    ComponentStore.fetchComponents()
    // eslint-disable-next-line
  }, [])

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    await ComponentStore.delete(selectedRows)

    if (ComponentStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const bulkActions = [
    {
      title: t('Delete'),
      onExecute: () => showDeleteWarning(),
    },
  ]

  const onChangeStateFilter = (filter) => {
    const newFilter = { ...filter }
    delete newFilter.active
    delete newFilter.deprecated
    switch (filter?.state) {
      case 'active':
        newFilter.active = 1
        break
      case 'notActive':
        newFilter.active = 0
        break
      case 'deprecated':
        newFilter.deprecated = 1
        break
      case 'notDeprecated':
        newFilter.deprecated = 0
        break
      default:
        break
    }
    ComponentStore.setFilter(newFilter)
  }

  return (
    <div className="content-wrapper">
      <ResourceTable
        noStriped
        title={t('Components')}
        store={ComponentStore}
        resourceName="components"
        filterParams="filter"
        filters={[
          {
            type: 'single-select',
            title: t('State'),
            name: 'state',
            onChange: onChangeStateFilter,
            defaultValue: ComponentStore.filter.state,
            options: [
              {
                label: t('Show all'),
                value: undefined,
              },
              {
                label: t('Active'),
                value: 'active',
              },
              {
                label: t('Not active'),
                value: 'notActive',
              },
              {
                label: t('Deprecated'),
                value: 'deprecated',
              },
              {
                label: t('Not deprecated'),
                value: 'notDeprecated',
              },
            ],
          },
          {
            type: 'search',
            title: t('Search'),
            name: 'q',
            defaultValue: ComponentStore.filter.q,
            onChange: ComponentStore.setFilter,
          },
        ]}
        headerAction={{
          type: 'create',
          action: () =>
            history.push(createRedirectPath('/component-editor/new')),
        }}
        loading={ComponentStore.isFetchingList}
        bulkActions={bulkActions}
        rowSelection={{
          selectedRows,
          onSelectionChange: (newRows) => setSelectedRows(newRows),
        }}
        onRowClick={(record) =>
          history.push(createRedirectPath(`/component-editor/${record.id}`))
        }
        emptyText={t(
          'Recommendations provides the shop users with recommended products'
        )}
        createAction={() =>
          history.push(createRedirectPath('/component-editor/new'))
        }
        createActionText={t('Create Recommendation')}
      >
        <Column
          title={t('Name (ID)')}
          className="mk-table-cell--bold"
          width={400}
          render={(_, record) => (
            <Text weight="bold" element="div">
              {record.name}{' '}
              <span className="bundle__list-id">({record.id})</span>
            </Text>
          )}
        />
        <Column
          title={t('Active')}
          dataIndex="active"
          width={60}
          align="center"
          render={(active) => (
            <Text>
              <Icon
                className="component-editor__icon-column"
                width="15px"
                height="15px"
                symbol={active ? 'circle-check__solid' : 'circle'}
              />
            </Text>
          )}
        />
        <Column
          title={t('Deprecated')}
          dataIndex="deprecated"
          width={60}
          align="center"
          render={(deprecated) => (
            <Text>
              <Icon
                className="component-editor__icon-column"
                width="15px"
                height="15px"
                symbol={deprecated ? 'ban__solid' : 'circle'}
              />
            </Text>
          )}
        />
        <Column
          title={t('Used in ...')}
          render={(_, record) => {
            if (!record.used) return null
            return (
              <Text
                onClick={(e) => {
                  e.stopPropagation()
                  ComponentStore.usage.setComponentData(record)
                  setModalVisible(true)
                }}
                weight="bold"
                className="component-editor__used-in"
              >
                <Icon
                  symbol="circle-info__regular"
                  width="18px"
                  height="18px"
                />
              </Text>
            )
          }}
        />
        <Column
          width={50}
          render={(_, record) => (
            <CopyToClipboard
              text={JSON.stringify(record.sections)}
              onCopy={() => toast.success(t('Copied') + '!')}
            >
              <Tooltip title={t('Copy Component Structure to Clipboard')}>
                <Icon
                  className="mk-table__arrow-action"
                  symbol="copy"
                  width={18}
                  height={18}
                  onClick={(e) => e.stopPropagation()}
                />
              </Tooltip>
            </CopyToClipboard>
          )}
        />
        <Column width={50} render={() => <ArrowAction />} />
      </ResourceTable>
      <UsageModal
        modalVisible={modalVisible}
        closeModal={() => {
          setModalVisible(false)
          setTimeout(() => ComponentStore.usage.reset(), 400)
        }}
      />
    </div>
  )
}

export default observer(List)
