import classNames from 'classnames'
import React from 'react'
import Button from '../Button'
import Icon from '../Icon'

import './style.styl'

const TrafficDistribution = (props) => {
  const {
    onChange = () => {},
    max = 100,
    min = 0,
    step = 0.1,
    variant = 'a',
    locked,
    onToggleLock = () => {},
    value,
  } = props

  function handleChangeUp() {
    if (props.disabled) return

    const newValue = parseInt(value, 10) + parseInt(step, 10)
    if (max !== undefined && newValue > max) return

    onChange(newValue)
  }

  function handleChangeDown() {
    if (props.disabled) return

    const newValue = parseInt(value, 10) - parseInt(step, 10)
    if (min !== undefined && newValue < min) return

    onChange(newValue)
  }

  const handleInputChange = (e) => {
    let newValue = e.target.value
    if (isNaN(newValue)) {
      newValue = 0
    }
    if (max !== undefined && newValue > max) {
      newValue = max
    }
    if (min !== undefined && newValue < min) {
      newValue = min
    }
    onChange(newValue)
  }

  return (
    <div className="traffic-distribution">
      <div className="traffic-distribution__variant">
        {variant.toUpperCase()}
      </div>
      <div className="traffic-distribution__controls">
        <button
          disabled={locked}
          className="traffic-distribution__button traffic-distribution__button--decrease"
          onClick={handleChangeDown}
        >
          <Icon className="traffic-distribution__button-icon" symbol="minus" />
        </button>
        <div className="traffic-distribution__input-wrapper">
          <input
            className="traffic-distribution__input"
            name="traffic-distribution"
            type="number"
            max={max}
            min={min}
            step={step}
            value={value}
            onChange={handleInputChange}
            disabled={locked}
            onFocus={(event) => event.target.select()}
          />
          <label>%</label>
        </div>

        <button
          disabled={locked}
          className="traffic-distribution__button traffic-distribution__button--increase"
          onClick={handleChangeUp}
        >
          <Icon className="traffic-distribution__button-icon" symbol="plus" />
        </button>
      </div>
      <Button
        className={classNames('traffic-distribution__lock-btn', {
          'traffic-distribution__lock-btn--unlocked': !locked,
        })}
        onClick={onToggleLock}
        variant="reduced"
        icon={locked ? 'lock-solid' : 'lock-open-solid'}
        level={0}
      />
    </div>
  )
}

export default TrafficDistribution
