import { useState, Fragment } from 'react'
import { Modal, Popover } from 'antd'
import Icon from '../Icon'
import Text from '../Text'
import checkeredBoard from '../../assets/svgs/checkered-board-1.svg'
import classNames from 'classnames'
import { prepareImageUrl } from '../../utils'

export default function Preview(props) {
  const [isModalVisible, setModalVisible] = useState(false)
  const {
    uploadFile,
    uploadCloudinary,
    fileType,
    showDetail,
    size,
    storageConfiguration,
  } = props
  const { name, url, thumbUrl, error } = prepareImageUrl({
    source: props.src,
    ...storageConfiguration,
  })

  const nameWithoutExt = name?.split('.')
  const ext = nameWithoutExt.pop()

  function showPreview(event) {
    if (fileType !== 'image') return

    event.preventDefault()

    setModalVisible(true)
  }

  const renderFilePreviewActions = (
    <div className="file-preview__actions">
      <div className="file-preview__actions-row">
        {props.onRemove && (
          <div className="file-upload__label" onClick={props.onRemove}>
            <Icon symbol="trash-alt" width="2.1rem" />
          </div>
        )}

        <a
          className={classNames('file-upload__label', {
            'file-upload__label--center-item':
              !uploadFile && !uploadCloudinary && !props.onRemove,
          })}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={showPreview}
        >
          <Icon symbol="eye__solid" />
        </a>
        {uploadFile}
        {uploadCloudinary}
      </div>
    </div>
  )

  const FilePreviewWrapper = size === 'small' ? Popover : Fragment
  const filePreviewWrapperProps = {}
  if (size === 'small') {
    filePreviewWrapperProps.content = renderFilePreviewActions
    filePreviewWrapperProps.overlayClassName = 'file-upload__popover'
  }

  return (
    <>
      <FilePreviewWrapper {...filePreviewWrapperProps}>
        <div
          className={classNames('file-preview', `file-preview--${size}`, {
            'file-preview--error': error,
          })}
        >
          {size !== 'small' && renderFilePreviewActions}
          <div
            style={{
              backgroundImage:
                fileType === 'image' &&
                !error &&
                `url("${thumbUrl}"), url("${checkeredBoard}")`,
            }}
            className="file-upload__img-wrapper"
          >
            {fileType === 'video' && !error && <Icon symbol="video" />}
            {fileType !== 'video' && fileType !== 'image' && !error && (
              <Icon symbol="file" />
            )}
            {error && <Icon symbol="circle-exclamation" />}
          </div>
        </div>
      </FilePreviewWrapper>

      {showDetail && (
        <div className="file-upload__detail">
          <Text
            size="bravo"
            weight="bold"
            className="file-upload__detail-name"
            title={nameWithoutExt.join('.') || ''}
          >
            {nameWithoutExt.join('.')}
          </Text>
          <Text variant="book" className="file-upload__detail-ext">
            {ext.toUpperCase()}
          </Text>
        </div>
      )}

      <Modal
        visible={isModalVisible}
        title={name}
        footer={null}
        onCancel={() => setModalVisible(false)}
        width={800}
      >
        <img style={{ width: '100%' }} src={url} />
      </Modal>
    </>
  )
}
