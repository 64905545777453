import { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import get from 'lodash/get'
import set from 'lodash/set'
import isEqual from 'lodash/isEqual'
import { toJS } from 'mobx'

import { UIStore, MenuStore } from '../../stores'
import Button from '../../components/Button'
import TextInput from '../../components/TextInput'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import { t } from '../../utils'
import LanguageContent from './LanguageContent'

const EditNode = ({ node, setOpenEdit, updateNode }) => {
  const { languages } = UIStore

  const firstInputRef = useRef(null)

  const nodeToJS = toJS(node)

  const [nodeInputs, setNodeInputs] = useState({
    text: nodeToJS.text,
    link: nodeToJS.link,
  })

  useEffect(() => {
    if (node.isNewNode) {
      firstInputRef?.current?.focus({
        cursor: 'start',
      })
    }
    // eslint-disable-next-line
  }, [])

  const onChangeInput = (keyArr, value) => {
    const newNodeInputs = { ...nodeInputs }
    set(newNodeInputs, keyArr, value)
    setNodeInputs(newNodeInputs)

    if (keyArr[0] === 'link') {
      checkValid(keyArr[1], value)
    }
  }

  const checkValid = (language, value = '') => {
    let invalid = true

    if (!value || value.startsWith('/') || /^(https?):\/\//.test(value)) {
      invalid = false
    }
    if ((node.errors && node.errors[language] !== invalid) || !node.errors) {
      updateNode(
        node.uuid,
        { ...node, errors: { ...node.errors, [language]: invalid } },
        false
      )
    }
    return invalid
  }

  const onSaveNode = () => {
    if (nodeInputs.link) {
      const errors = {}
      Object.keys(nodeInputs.link).forEach(
        (language) =>
          (errors[language] = checkValid(language, nodeInputs.link[language]))
      )
      if (Object.values(errors).includes(true)) {
        return
      }
    }

    const setIsDirty = !isEqual(
      { text: nodeInputs.text, link: nodeInputs.link },
      { text: node.text, link: node.link }
    )
    updateNode(
      node.uuid,
      { ...nodeToJS, ...nodeInputs, isEditing: false },
      setIsDirty
    )
  }

  const handleOverride = () => {
    let updated = { ...toJS(node) } // we don't want to change the observable
    updated['esId'] = ''
    setNodeInputs(updated)
    updateNode(node.uuid, updated)
  }

  const onBlurNewNode = (isNewNode) => {
    if (!isNewNode) return
    let updated = { ...toJS(node) } // we don't want to change the observable
    updated['isNewNode'] = false
    updateNode(node.uuid, updated)
  }

  const iconProps = (currentNode) => ({
    symbol: 'link__solid',
    onClick: () => MenuStore.openPrefillModal(currentNode),
    tooltip: t('Prefill menu entry'),
  })

  return (
    <div className="edit-node">
      <div
        className={classNames('edit-node__inputs-wrapper', {
          'edit-node__inputs-wrapper--one-row': languages.length === 1,
        })}
      >
        {languages.map((language, index) => (
          <div key={language} className="edit-node__inputs-row">
            {node.esId && (
              <LanguageContent
                text={get(node, ['text', language], '')}
                link={get(node, ['link', language], '')}
                language={language}
              />
            )}
            {!node.esId && (
              <>
                <div className="edit-node__input">
                  <Text variant="book">{language.toUpperCase()}</Text>
                  <TextInput
                    ref={index === 0 ? firstInputRef : undefined}
                    value={get(nodeInputs, ['text', language], '')}
                    icon={iconProps(node)}
                    onChange={(e) =>
                      onChangeInput(['text', language], e.target.value)
                    }
                    placeholder={index === 0 ? 'Homepage' : null}
                    onBlur={() => onBlurNewNode(node.isNewNode)}
                  />
                </div>
                <div className="edit-node__input">
                  <Icon symbol="globe" width={20} height={16} />
                  <TextInput
                    value={get(nodeInputs, ['link', language], '')}
                    icon={iconProps(node)}
                    onChange={(e) =>
                      onChangeInput(['link', language], e.target.value)
                    }
                    error={
                      node.errors &&
                      node.errors[language] &&
                      t('Begin URL with "/" or https:// for external links')
                    }
                    placeholder={index === 0 ? '/' : null}
                  />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      <div className="edit-node__actions">
        <Button
          level={1}
          onClick={() => MenuStore.openSettingModal(node)}
          variant="tertiary"
          icon="gear"
          tooltip={t('Settings')}
        />
        {node.esId && (
          <Button level={1} onClick={handleOverride}>
            {t('Override manually')}
          </Button>
        )}
        {!node.esId && (
          <Button
            level={1}
            variant="primary"
            icon="check"
            onClick={onSaveNode}
            tooltip={t('Apply')}
          />
        )}
        <Button
          level={1}
          variant="tertiary"
          icon="times"
          onClick={() => setOpenEdit(false)}
          tooltip={t('Cancel')}
        />
      </div>
    </div>
  )
}

export default observer(EditNode)
