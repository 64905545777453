import { useState } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'

import Icon from '../../../components/Icon'
import Spinner from '../../../components/Spinner'

import t from '../../../utils/translate'
import getOnlyDomain from '../../../stores/PageEditor/utils/getOnlyDomain'

import PageEditorPreviewStore from '../../../stores/PageEditor/PageEditorPreviewStore'

const URLInput = ({ activeTargetURL = '', onClickReload = () => {} }) => {
  const [urlInputHovered, setURLInputHovered] = useState(false)
  const [urlInputFocused, setURLInputFocused] = useState(false)
  const [customInput, setCustomInput] = useState(
    PageEditorPreviewStore.customPreviewTargetURL ?? ''
  )

  const isHoverTextShown = () => {
    if (!urlInputHovered) return false

    return !PageEditorPreviewStore.customPreviewTargetURL
  }

  const handleOnBlur = () => {
    // Only update store if the user really changed something. Otherwise, we would trigger a useless reload of the IFrame.
    if (customInput !== activeTargetURL)
      PageEditorPreviewStore.setCustomPreviewTargetURL(customInput)
    setURLInputFocused(false)
    setCustomInput(PageEditorPreviewStore.customPreviewTargetURL)
  }

  const handleRemoveCustomClick = () => {
    setURLInputFocused(false)
    PageEditorPreviewStore.setCustomPreviewTargetURL('')
    setCustomInput('')
  }

  const getValue = () => {
    if (isHoverTextShown() || urlInputFocused) return customInput

    if (PageEditorPreviewStore.customPreviewTargetURL) return activeTargetURL

    return getOnlyDomain(activeTargetURL)
  }

  return (
    <div
      className={classnames('editor-preview__url-input', {
        'editor-preview__url-input--hovered': urlInputHovered,
        'editor-preview__url-input--custom':
          PageEditorPreviewStore.customPreviewTargetURL,
      })}
    >
      {PageEditorPreviewStore.previewLoading && <Spinner size="small" />}
      <input
        value={getValue()}
        placeholder={t('You can enter a private url for development')}
        onChange={(event) => setCustomInput(event.target.value)}
        onFocus={() => setURLInputFocused(true)}
        onBlur={handleOnBlur}
        onKeyDown={(event) => {
          if (event.key === 'Enter') event.currentTarget.blur()
        }}
        onMouseEnter={() => setURLInputHovered(true)}
        onMouseLeave={() => setURLInputHovered(false)}
      />
      {PageEditorPreviewStore.customPreviewTargetURL && (
        <button
          onClick={handleRemoveCustomClick}
          className="editor-preview__remove-custom-url"
        >
          <Icon symbol={'circle-xmark-solid'} />
        </button>
      )}

      <button onClick={onClickReload} className="editor-preview__reload">
        <Icon symbol={'arrow-rotate-right'} />
      </button>
    </div>
  )
}

export default observer(URLInput)
