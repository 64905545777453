/**
 *
 * @param text
 * @param fileName
 */
const downloadCSV = ({ text, fileName }) => {
  const anchor = document.createElement('a')
  anchor.download = fileName
  if (window.URL.createObjectURL) {
    const blobObject = new Blob([text], { type: 'text/csv' })
    anchor.href = window.URL.createObjectURL(blobObject)
  } else {
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
  }

  if (document.createEvent) {
    const event = new MouseEvent('click', { bubbles: true, cancelable: true })
    anchor.dispatchEvent(event)
  } else {
    anchor.click()
  }
}

export default downloadCSV
