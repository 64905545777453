import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/productfeed?${queryString}`

    return fetch(target, { stage: true })
  },

  async get(id) {
    const target = `/productfeed/${id}`

    return fetch(target, { stage: true })
  },

  async create(feed) {
    return fetch('/productfeed', {
      method: 'POST',
      body: JSON.stringify(feed),
    })
  },

  async update(feed) {
    await fetch(`/productfeed/${feed.id}`, {
      method: 'PUT',
      body: JSON.stringify(feed),
    })
  },

  async delete(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))

    await fetch(`/productfeed?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },

  async copy(payload) {
    await fetch(`/productfeed/copy`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
  },

  async duplicate(ids) {
    await fetch(`/productfeed/duplicate`, {
      method: 'POST',
      body: JSON.stringify({
        ids,
      }),
    })
  },

  async getFields() {
    return fetch(`/productfeed/fields`, {
      method: 'GET',
    })
  },

  async getFeedPreview(feed, language, productIds) {
    return fetch(`/feeds/${language}/preview`, {
      method: 'POST',
      body: JSON.stringify({ productIds, config: feed }),
    })
  },

  async validateFields(feed, language) {
    return fetch(`/feeds/${language}/validate-field`, {
      method: 'POST',
      body: JSON.stringify({ config: feed }),
    })
  },
}
