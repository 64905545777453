import { observer } from 'mobx-react-lite'
import Text from '../../../components/Text'
import Icon from '../../../components/Icon'
import Button from '../../../components/Button'
import t from '../../../utils/translate'
import classNames from 'classnames'

const RENDER = {
  compatible: {
    icon: 'triangle-person-digging',
    heading: 'No preview available',
    description:
      "Your storefront does not support the preview feature yet. But don't worry you can fix that!",
  },
  break: {
    icon: 'burger-soda',
    heading: 'Your preview is having a break',
    description:
      'Open a Landing page, Category or other in the Makaira Page Editor, to see a preview here again. Attention: The preview is only connected with the tab it was opened in originally!',
  },
}

const NotCompatible = ({ type, isStorefrontPreview }) => {
  const renderData = RENDER[type]
  return (
    <div
      className={classNames('editor-preview__not-compatible', {
        'editor-preview__not-compatible--popup': isStorefrontPreview,
      })}
    >
      <Icon
        symbol={renderData.icon}
        className="editor-preview__not-compatible-icon"
      />
      <Text
        size="echo"
        weight="bold"
        className="editor-preview__not-compatible-title"
      >
        {t(renderData.heading)}
      </Text>
      <Text
        size="delta"
        weight="book"
        className="editor-preview__not-compatible-description"
      >
        {t(renderData.description)}
      </Text>
      {type === 'compatible' && (
        <a
          href="https://docs.makaira.io/books/storefront/page/upgrade-guide-storefront-preview-mode"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon="chevron-right">{t('Open documentation')}</Button>
        </a>
      )}
    </div>
  )
}

export default observer(NotCompatible)
