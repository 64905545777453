/**
 * This func used to clean up all Personas / PublishConfig when
 * some configs already deleted and no available anymore.
 * @param {*} availableConfigs List of Config return from BE
 * @param {*} currentConfig current publish config for component in Page data
 * @returns
 */
export default function cleanUpPublishConfig(
  availableConfigs = [],
  currentConfig = {}
) {
  const availableIdentiers = availableConfigs.map((config) => config.identifier)
  const removingIdentifiers = Object.keys(currentConfig).filter(
    (identifier) =>
      identifier !== 'All' && !availableIdentiers.includes(identifier)
  )
  for (let identifier of removingIdentifiers) {
    delete currentConfig[identifier]
  }

  return currentConfig
}
