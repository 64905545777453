import Text from '../../components/Text'
import { t } from '../../utils'

const TitleField = (item, row) => {
  return (
    <div title={item} className="ab-testing__title-field">
      <Text>{item || t('no title yet')}</Text>
      <Text className="title-id">({row.id})</Text>
    </div>
  )
}

export default TitleField
