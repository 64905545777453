import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/aggregation?${queryString}`

    return fetch(target)
  },

  async get(id) {
    return fetch(`/aggregation/${id}`)
  },

  async create(filter) {
    return fetch('/aggregation', {
      method: 'POST',
      body: JSON.stringify(filter),
    })
  },

  async update(filter) {
    return fetch(`/aggregation/${filter.id}`, {
      method: 'PUT',
      body: JSON.stringify(filter),
    })
  },
  async delete(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))

    await fetch(`/aggregation?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },

  async getValues(filter) {
    return fetch(`/aggregation/values`, {
      method: 'POST',
      body: JSON.stringify(filter),
    })
  },

  async getFields() {
    return fetch(`/aggregation/fields?exclude_type=datatype`)
  },
}
