import { observer } from 'mobx-react-lite'
import moment from 'moment'
import AutoComplete from '../../AutoComplete'
import DatePicker, { RangeDatePicker } from '../../DatePicker'
import Icon from '../../Icon'
import NumberInput from '../../NumberInput'
import TextInput from '../../TextInput'
import { FilterSectionStore } from '../../../stores'
import { getOperator } from '../utils'
import InputDayField from './InputDayField'
import RangeNumberField from './RangeNumberField'
import { NONE_COMPARISON_OPERATORS } from '../constants'

const FIELDS = {
  text: TextInput,
  number: NumberInput,
  rangeNumber: RangeNumberField,
  datepicker: DatePicker,
  withDays: InputDayField,
  dateRangePicker: RangeDatePicker,
}

function ValueField({ fieldOptions, type }) {
  const { editingFilter, setEditingFilter, config } = FilterSectionStore

  const hasInputProduct = config[type]?.hasInputProduct
  const shouldShowInputProduct =
    editingFilter.compareWith === 'inputProduct' && hasInputProduct
  const shouldShowStaticValue =
    editingFilter.compareWith === 'staticValue' || !hasInputProduct

  if (hasInputProduct && !editingFilter.compareWith) {
    return null
  }

  if (
    NONE_COMPARISON_OPERATORS.indexOf(editingFilter.operator) > -1 ||
    !editingFilter.operator
  ) {
    return <></>
  }

  const { comparison } = getOperator({
    type: editingFilter.type,
    operator: editingFilter.operator,
  })

  const filteredFieldOptions = fieldOptions.filter(
    (item) => item.type === editingFilter.type
  )

  const FieldComponent = FIELDS[comparison]

  const onChangeValueField = (value, dateString) => {
    if (['datepicker', 'dateRangePicker'].includes(comparison)) {
      value = comparison === 'datepicker' ? [dateString] : dateString
    }
    if (comparison === 'text') {
      value = value.target.value
    }

    setEditingFilter({
      ...editingFilter,
      value: value?.target?.value || value,
    })
  }

  const handleDataValueField = (data) => {
    if (comparison === 'datepicker') {
      data = data && data[0] ? moment(data[0]) : null
    }

    if (comparison === 'dateRangePicker') {
      data = data && data.length ? data.map((date) => moment(date)) : null
    }

    return data
  }

  const isFloat = ['float', 'double'].includes(editingFilter.type)

  const numberProps = {}
  numberProps.isFloat = isFloat
  numberProps.step = isFloat ? 0.1 : 1

  return (
    <>
      <Icon width="20px" height="20px" symbol="arrow-down" />
      {shouldShowInputProduct && (
        <AutoComplete
          style={{ width: '265px' }}
          fullWidth
          itemKey="label"
          defaultOptions={filteredFieldOptions.map((field) => ({
            label: field.title,
            value: field.id,
          }))}
          value={editingFilter.value}
          onSelect={(value) =>
            setEditingFilter({
              ...editingFilter,
              value,
            })
          }
        />
      )}
      {shouldShowStaticValue && FieldComponent && (
        <FieldComponent
          onChange={onChangeValueField}
          value={handleDataValueField(editingFilter.value)}
          autoAdjustWidth
          {...numberProps}
        />
      )}
    </>
  )
}

export default observer(ValueField)
