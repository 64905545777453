import classnames from 'classnames'
import Icon from '../Icon'
import MakairaGravatar from '../MakairaGravatar'

import './avatar.styl'

const Avatar = ({ email = '', icon }) => (
  <div className={classnames('avatar', { ['avatar--icon']: icon })}>
    <div className="avatar__inner">
      <MakairaGravatar email={email} />
      {icon && <Icon symbol={icon} />}
    </div>
  </div>
)

export default Avatar
