import { icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

// go to https://fontawesome.com/search to find icon
// rule for the key of icon is <name-of-icon>__<style>

export default {
  bell__solid: icon({ name: 'bell', style: 'solid' }),
  check__bold: icon({ name: 'check', style: 'solid' }),
  check__regular: icon({ name: 'check', style: 'regular' }),
  circle__solid: icon({ name: 'circle', style: 'solid' }),
  'triangle-exclamation__solid': icon({
    name: 'triangle-exclamation',
    style: 'solid',
  }),
  'arrow-up-right-from-square__solid': icon({
    name: 'arrow-up-right-from-square',
    style: 'solid',
  }),
  'ellipsis-vertical__solid': icon({
    name: 'ellipsis-vertical',
    style: 'solid',
  }),
  clock__solid: icon({ name: 'clock', style: 'solid' }),
  language__solid: icon({ name: 'language', style: 'solid' }),
  question__solid: icon({ name: 'circle-question', style: 'solid' }),
  link__solid: icon({ name: 'link', style: 'solid' }),
  eye__solid: icon({ name: 'eye', style: 'solid' }),
  'circle-info__solid': icon({ name: 'circle-info', style: 'solid' }),
  'circle-check__solid': icon({ name: 'circle-check', style: 'solid' }),
  rocket__regular: icon({ name: 'rocket', style: 'regular' }),
  check__solid: icon({ name: 'check', style: 'solid' }),
  user__solid: icon({ name: 'user', style: 'solid' }),
  'toggle-large-on__regular': icon({
    name: 'toggle-large-on',
    style: 'regular',
  }),
  'folder-gear__regular': icon({ name: 'folder-gear', style: 'regular' }),
  search__regular: icon({ name: 'search', style: 'regular' }),
  'sort-amount-down__regular': icon({
    name: 'sort-amount-down',
    style: 'regular',
  }),
  'flag-checkered__solid': icon({ name: 'flag-checkered', style: 'solid' }),
  'circle-xmark__solid': icon({ name: 'circle-xmark', style: 'solid' }),
  'chart-column__light': icon({ name: 'chart-column', style: 'light' }),
  'circle-info__regular': icon({ name: 'circle-info', style: 'regular' }),
  'angle-right__solid': icon({ name: 'angle-right', style: 'solid' }),
  skull__solid: icon({ name: 'skull', style: 'solid' }),
  folder__regular: icon({ name: 'folder', style: 'regular' }),
  'arrow-right__regular': icon({ name: 'arrow-right', style: 'regular' }),
  ban__solid: icon({ name: 'ban', style: 'solid' }),
}
