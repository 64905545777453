import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'

import Text from '../../components/Text'
import Button from '../../components/Button'
import TextInput from '../../components/TextInput'
import Table, { Column } from '../../components/Table'
import ConfirmModal from '../../components/Modal/ConfirmModal'

import { t } from '../../utils'
import { ConfigurationStore, UIStore } from '../../stores'

import RequestModal from './RequestModal'
import api from '../../api'

function UrlSettings() {
  const [urls, setUrls] = useState([])
  const [editingRows, setEditingRows] = useState([])
  const [showRequestDialog, setShowRequestDialog] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({
    visible: false,
    previewUrl: null,
    updatingRowIndex: -1,
  })
  const [requestAction, setRequestAction] = useState('new')
  const { configuration } = ConfigurationStore
  const { currentInstance, enterpriseConfiguration } = UIStore

  useEffect(() => {
    setUrls([
      {
        instance: currentInstance,
        url: enterpriseConfiguration?.clientBaseurl,
        previewUrl: configuration?.previewUrl,
      },
    ])
  }, [currentInstance, enterpriseConfiguration, configuration])

  const onEditingRow = (rowIndex) => {
    setEditingRows([...editingRows, rowIndex])
  }

  const onBeforeUpdatePreviewUrl = (rowIndex) => {
    const input = document.querySelector(
      `input[name="urls.${rowIndex}.previewUrl"]`
    )
    if (input) {
      setConfirmDialog({
        visible: true,
        previewUrl: input.value,
        updatingRowIndex: rowIndex,
      })
    }
  }

  const onConfirmUpdatePreviewUrl = async () => {
    try {
      await ConfigurationStore.updatePreviewURL(confirmDialog.previewUrl)
    } catch (error) {
      toast.error('Could not update previewUrl!')
      console.error(error)
    } finally {
      onCloseEditPreviewUrl(confirmDialog.updatingRowIndex)
      setConfirmDialog({
        visible: false,
        previewUrl: null,
        updatingRowIndex: -1,
      })
    }
  }

  const onRejectUpdatePreviewUrl = () => {
    onCloseEditPreviewUrl(confirmDialog.updatingRowIndex)
    setConfirmDialog({
      visible: false,
      previewUrl: null,
      updatingRowIndex: -1,
    })
  }

  const onCloseEditPreviewUrl = (rowIndex) => {
    setEditingRows(editingRows.filter((index) => index !== rowIndex))
  }

  const onShowRequestDialog = (show) => {
    setShowRequestDialog(show)
  }

  const onSubmitRequestDialog = async (action, requestBody) => {
    try {
      await api.common.requestIntance({ ...requestBody, type: action })
      toast.success(t('Request successfully'))
    } catch (error) {
      toast.error('Something went wrong...')
    }
  }

  return (
    <div className="general-settings__urls">
      <div className="section-title">
        <Text size="echo" weight="bold">
          {t('Instance')}: {currentInstance}
        </Text>
        <Button
          icon="plus"
          iconPosition="left"
          onClick={() => {
            setRequestAction('new')
            onShowRequestDialog(true)
          }}
        >
          {t('Add an instance')}
        </Button>
      </div>
      <div className="general-settings__edit-instance-wrapper">
        <Text size="charlie" weight="bold">
          {t('Change or delete this instance')}
        </Text>
        <Text>
          {t('Click below to start the process for any major edits.')}
        </Text>
        <div>
          <Button
            iconPosition="left"
            icon="triangle-exclamation__solid"
            variant="primary-blocker"
            onClick={() => {
              setRequestAction('delete')
              onShowRequestDialog(true)
            }}
          >
            {t('Yes, I want to start the process')}
          </Button>
        </div>
      </div>
      <div>
        <Table rowKey="instance" data={urls}>
          <Column
            title={t('Instance name')}
            render={(instance) => <Text>{instance}</Text>}
            dataIndex="instance"
          />
          <Column
            title={t('Base URL (used for Feeds, Sitemaps)')}
            render={(url) => <Text>{url}</Text>}
            dataIndex="url"
          />
          <Column
            title={t('Preview')}
            render={(previewUrl, record, rowIndex) => {
              if (editingRows.includes(rowIndex)) {
                return (
                  <div className="editable-cell">
                    <TextInput
                      name={`urls.${rowIndex}.previewUrl`}
                      defaultValue={previewUrl}
                    />
                    <Button
                      icon="check"
                      onClick={() => onBeforeUpdatePreviewUrl(rowIndex)}
                    />
                    <Button
                      icon="close"
                      onClick={() => onCloseEditPreviewUrl(rowIndex)}
                    />
                  </div>
                )
              }

              return (
                <div className="editable-cell">
                  <Text>{previewUrl}</Text>
                  <Button
                    icon="pen"
                    onClick={() => onEditingRow(rowIndex)}
                    className="editable-cell__action"
                  />
                </div>
              )
            }}
            dataIndex="previewUrl"
          />
        </Table>
      </div>
      <RequestModal
        key={requestAction}
        requestAction={requestAction}
        visible={showRequestDialog}
        onClose={() => onShowRequestDialog(false)}
        onSave={onSubmitRequestDialog}
      />

      <ConfirmModal
        message={t(
          'CAUTION! This setting is global, every author will be affected by changes of the URL. Do you really want to update the setting?'
        )}
        visible={confirmDialog.visible}
        onClose={onRejectUpdatePreviewUrl}
        onSubmit={onConfirmUpdatePreviewUrl}
        mask
      />
    </div>
  )
}

export default observer(UrlSettings)
