export const PRESETS = [
  {
    name: 'Interesting products in the same year',
    desc: 'The recommendation is based on products that are bought together based on the purchase date.',
    id: 'sameyear',
  },
  {
    name: 'Accessories',
    desc: 'The recommendation is based on products that are bought together and the recommended products are in a lower price range.',
    id: 'accessories',
  },
  {
    name: 'New products',
    desc: 'The recommendation is based on products that are bought together and the number of sold items.',
    id: 'justin',
  },
  {
    name: 'Other users also bought',
    desc: 'Shows products that were added to the cart by other users.',
    id: 'alsobought',
  },
]

export const CATEGORY_CATID = {
  id: 'category.catid',
  title: 'Category (category.catid)',
  type: 'keyword',
}
