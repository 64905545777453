import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { Children, isValidElement, cloneElement, useState } from 'react'
import Table, { Column } from '../Table'
import ResourceTableTitle from './ResourceTableTitle'
import ResourceTableFilter from './ResourceTableFilter'
import Footer from './Footer'
import HeaderColumn from './HeaderColumn'
import Checkbox from '../Checkbox'
import Text from '../Text'

function ResourceTable(props) {
  const [isCheckedAll, setCheckedAll] = useState(null)
  const [isShowCheckboxColumn, setShowCheckboxColumn] = useState(false)
  const {
    title,
    store,
    resourceName,
    children,
    scroll,
    rowKey,
    loading,
    data,
    bulkActions = [],
    rowSelection = {},
    onRowClick = () => {},
    emptyText,
    noResultsText,
    expandable = false,
    expandedRowChange,
    expandedRows,
    heading,
  } = props
  const {
    selectedRows = [],
    checkDisable = () => false,
    onSelectionChange = () => {},
    checkShow = () => true,
  } = rowSelection

  const dataSource = data || toJS(store[resourceName])

  const { state, pagination, setPagination, setSorter, sorter, filter } = store

  function onChange(...parameters) {
    const [pagination, , sorter, extra] = parameters
    const { action } = extra

    switch (action) {
      case 'paginate':
        (props.setPagination || setPagination)(pagination)
        break
      case 'sort':
        setSorter(sorter)
        break
      default:
        console.error('Invalid action provided')
    }
  }

  function applyHeaderToChildren() {
    return Children.map(children, (child) => {
      if (isValidElement(child)) {
        let childProps = {
          title: (
            <HeaderColumn
              {...child.props}
              currentSorter={sorter}
              onSort={(field, order) => setSorter({ field, order })}
            />
          ),
        }

        if (child?.props?.dataIndex) {
          childProps.render = (val) => <Text>{val}</Text>
        }

        childProps = {
          ...childProps,
          ...child.props,
          title: childProps.title,
        }

        return cloneElement(child, childProps)
      }
    })
  }

  function handleCheckboxClick(id) {
    let updatedCheckedElements = [...selectedRows]

    if (updatedCheckedElements.includes(id)) {
      updatedCheckedElements.splice(updatedCheckedElements.indexOf(id), 1)
    } else {
      updatedCheckedElements.push(id)
    }

    setCheckedAll(updatedCheckedElements.length === dataSource.length)
    onSelectionChange(updatedCheckedElements)
  }

  function toggleSelectAll(checked) {
    setCheckedAll(checked)

    if (!checked) {
      onSelectionChange([])
    } else {
      const items = dataSource.reduce((result, item) => {
        if (checkShow(item) && !checkDisable(item)) {
          result.push(getId(item))
        }
        return result
      }, [])
      onSelectionChange(items)
    }
  }

  function getId(record) {
    if (rowKey) {
      return record[rowKey]
    }

    return record.id
  }

  let expandableProps = {
    expandedRowKeys: dataSource.map((item) => getId(item)),
  }
  if (expandable) {
    expandableProps = {
      expandRowByClick: true,
      expandedRowKeys: expandedRows,
    }

    if (expandedRowChange) {
      expandableProps.onExpandedRowsChange = expandedRowChange
    }
  }

  const finalEmptyText = noResultsText ?? emptyText

  return (
    <div className="resource-table__wrapper">
      <ResourceTableTitle title={title} {...props} />

      <ResourceTableFilter {...props} />
      {heading && <Text>{heading}</Text>}
      <Table
        rowKey={(record) => getId(record)}
        data={dataSource}
        onChange={onChange}
        loading={loading !== undefined ? loading : state === 'pending'}
        scroll={scroll}
        rowClassName={props.rowClassName || 'resource-table__row'}
        onRow={(record, index) => ({
          onClick: () => onRowClick(record, index),
        })}
        expandable={expandableProps}
        filter={filter}
        emptyText={!(loading || state === 'pending') ? finalEmptyText : ''}
        noStriped={props.noStriped}
      >
        {bulkActions.length > 0 && isShowCheckboxColumn && (
          <Column
            className="resource-table__checkbox"
            render={(_, record) =>
              checkShow(record) && (
                <div data-cy="select-record">
                  <Checkbox
                    disabled={checkDisable(record)}
                    checked={selectedRows.includes(getId(record))}
                    onChange={() => handleCheckboxClick(getId(record))}
                  />
                </div>
              )
            }
          />
        )}
        {applyHeaderToChildren()}
      </Table>

      <Footer
        toggleSelectAll={toggleSelectAll}
        isCheckedAll={isCheckedAll}
        pagination={props.pagination || pagination}
        bulkActions={bulkActions}
        setPagination={props.setPagination || setPagination}
        checkedElements={selectedRows}
        isShowCheckbox={isShowCheckboxColumn}
        onShowCheckbox={setShowCheckboxColumn}
        dataSource={dataSource}
        {...props}
      />
    </div>
  )
}

export default observer(ResourceTable)
