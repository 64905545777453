export const AVAILABLE_FIELD_TYPES = [
  { label: 'Text', value: 'text', icon: 'text' },
  { label: 'Rich Text', value: 'richtext', icon: 'text' },
  { label: 'Number', value: 'number', icon: '1' },
  { label: 'File-Upload', value: 'file', icon: 'upload' },
  { label: 'Checkbox', value: 'checkbox', icon: 'square-check' },
  { label: 'Auswahl', value: 'select', icon: 'ballot' },
  { label: 'Multi-Select', value: 'multiselect', icon: 'ballot' },
  { label: 'Object', value: 'object', icon: 'clone' },
  { label: 'Array', value: 'array', icon: 'layer-group' },
  { label: 'Document-Types', value: 'documents', icon: 'file' },
  { label: 'Stream', value: 'stream', icon: 'bars-staggered' },
  { label: 'Color Picker', value: 'colorpicker', icon: 'palette' },
  { label: 'Hotspot Image', value: 'hotspot', icon: 'image' },
  { label: 'Date', value: 'date', icon: 'calendar-days' },
]

export const SECTION_TYPE = {
  label: 'Section',
  value: 'section',
  icon: 'folder',
}

export const CONDITIONAL_SECTION_TYPE = {
  label: 'Conditional Section',
  value: 'conditional-section',
  icon: 'option',
}

export const FIELDS_SUPPORT_APP = ['text', 'richtext', 'file']
