import operators from './operators/config'
import TextOperator from './operators/TextOperator'
import RangeOperator from './operators/RangeOperator'
import DateOperator from './operators/DateOperator'

const components = {
  string: TextOperator,
  text: TextOperator,
  keyword: TextOperator,
  nested: TextOperator,
  // TODO: create InputNumber component and refactor RangeOperator
  long: RangeOperator,
  integer: RangeOperator,
  double: RangeOperator,
  float: RangeOperator,
  // TODO: create Datepicker component and refactor DateOperator
  date: DateOperator,
}

export default function OperatorComponent(props) {
  const {
    name,
    condition: { operator, type, value },
    formikProps,
  } = props

  // get additional configration for current operator
  const operatorConfig = operators[type]?.find((o) => o.value == operator)

  // get Component to render for the current field type
  const Component = components[type]

  if (!Component) return null

  return (
    <Component
      isVisible={!!operator}
      config={operatorConfig}
      name={name}
      value={value}
      formikProps={formikProps}
    />
  )
}
