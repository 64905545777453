import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import Text from '../../components/Text'
import PublicFieldStore from '../../stores/PublicFieldStore'
import { Column } from '../../components/Table'
import Select from '../../components/Select'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Pagination from '../../components/Pagination'
import TextInput from '../../components/TextInput'
import { toJS } from 'mobx'
import { t } from '../../utils'
import DropdownButton from '../../components/DropdownButton'
import ResourceTable from '../../components/ResourceTable'

import './publicFields.styl'

const PublicFields = () => {
  const availableFieldsUpdated = PublicFieldStore.availableFieldsUpdated

  useEffect(() => {
    PublicFieldStore.fetchPublicFields(false)
    PublicFieldStore.fetchAvailableFields()

    return () => {
      PublicFieldStore.discard()
    }
  }, [])

  const onAddField = (_, { value, children }) => {
    PublicFieldStore.setAddOrEditFields({
      id: 'new',
      fieldId: value,
      fieldName: children,
      onDetailPage: false,
      onListingPage: false,
      onLandingPage: false,
    })
  }

  const onUpdateField = (row, oldRow = null) => {
    PublicFieldStore.setAddOrEditFields(row, oldRow)
  }

  const onDeleteField = (id, fieldId) => {
    if (id === 'new') {
      PublicFieldStore.deleteAddOrEditFields(fieldId)
    } else {
      PublicFieldStore.delete(id)
    }
  }

  const onFilterField = (e) => {
    PublicFieldStore.setFilter({ q: e.target.value })
  }

  const pagination = toJS(PublicFieldStore.pagination)

  return (
    <div className="settings__public-fields">
      <Text
        variant="book"
        element="p"
        className="settings__public-fields__desc"
      >
        {t('Assign the fields from the source system to the respective views')}
      </Text>
      <div className="settings__public-fields__content">
        <div className="settings__public-fields__headers">
          <Text size="delta" weight="bold" element="p" className="fields">
            {t('Choose public fields')}
            <TextInput
              value={PublicFieldStore.filter.q}
              rounded
              icon="magnifying-glass"
              placeholder={''}
              onChange={onFilterField}
              className="settings__public-fields__search"
            />
          </Text>

          <Text size="delta" weight="bold" element="p" className="displayAt">
            {t('Make fields available')}
          </Text>
        </div>
        <ResourceTable
          store={PublicFieldStore}
          resourceName="fields"
          loading={PublicFieldStore.state === 'pending'}
          customFooterRender={() => null}
          rowKey={(data) => data.id + data.fieldId}
        >
          <Column
            sorter
            title={t('Fields')}
            dataIndex="fieldName"
            render={(v, row) => {
              if (row.id === 'add_new') {
                return (
                  <Select
                    showSearch
                    placeholder={t('Add another field')}
                    options={availableFieldsUpdated?.map((f) => ({
                      label: f.title,
                      value: f.id,
                    }))}
                    value={null}
                    labelInValue={true}
                    onChange={onAddField}
                    optionFilterProp="children"
                  />
                )
              }

              if (row.id === 'new') {
                return (
                  <Select
                    optionFilterProp="children"
                    value={v}
                    showSearch
                    options={availableFieldsUpdated?.map((f) => ({
                      label: f.title,
                      value: f.id,
                    }))}
                    onChange={(_, { value, children }) => {
                      onUpdateField(
                        {
                          id: 'new',
                          fieldId: value,
                          fieldName: children,
                          onDetailPage: false,
                          onListingPage: false,
                          onLandingPage: false,
                        },
                        row
                      )
                    }}
                  />
                )
              }

              return <TextInput value={v} disabled />
            }}
          />
          <Column
            dataIndex="id"
            width={100}
            render={(id, row) =>
              id !== 'add_new' && (
                <DropdownButton
                  actionIcon="trash"
                  menu={(hide) => (
                    <div className="settings__delete-confirm">
                      <Text size="charlie">
                        {t('Delete public field now?')}
                      </Text>
                      <div>
                        <Button onClick={hide}>{t('Cancel')}</Button>
                        <Button
                          onClick={() => {
                            onDeleteField(id, row.fieldId)
                            hide()
                          }}
                          variant="primary"
                        >
                          {t('Ok')}
                        </Button>
                      </div>
                    </div>
                  )}
                  buttonLevel={0}
                  destroyTooltipOnHide
                  iconOnly
                />
              )
            }
          />
          <Column
            sorter
            title={t('Product List Page (PLP)')}
            dataIndex="onListingPage"
            width={166}
            render={(v, row) =>
              row.id !== 'add_new' && (
                <Checkbox
                  onChange={(e) =>
                    onUpdateField({ ...row, onListingPage: e.target.checked })
                  }
                  checked={v}
                />
              )
            }
          />
          <Column
            sorter
            title={t('Product Detail Page (PDP)')}
            dataIndex="onDetailPage"
            width={180}
            render={(v, row) =>
              row.id !== 'add_new' && (
                <Checkbox
                  onChange={(e) =>
                    onUpdateField({ ...row, onDetailPage: e.target.checked })
                  }
                  checked={v}
                />
              )
            }
          />
          <Column
            sorter
            title={t('Landing Page (LP)')}
            dataIndex="onLandingPage"
            width={166}
            render={(v, row) =>
              row.id !== 'add_new' && (
                <Checkbox
                  onChange={(e) =>
                    onUpdateField({ ...row, onLandingPage: e.target.checked })
                  }
                  checked={v}
                />
              )
            }
          />
        </ResourceTable>
      </div>
      <Pagination
        initialCurrentPage={pagination.current}
        currentPage={pagination.current}
        maxPage={Math.ceil(pagination.total / pagination.pageSize)}
        onPageSwitch={(page) => {
          PublicFieldStore.setPagination({
            ...pagination,
            current: page,
          })
        }}
      />
    </div>
  )
}

export default observer(PublicFields)
