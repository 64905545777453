import React, { useRef } from 'react'
import { Sketch } from '@uiw/react-color'

import { Popover } from 'antd'

import './colorPicker.styl'

const ColorPicker = (props) => {
  const containerRef = useRef()
  const { onChange = () => {} } = props
  const colorValue = props.value
    ? props.value
    : {
        hex: '#ffffff',
        hsla: 'hsla(0, 0%, 100%, 1)',
        rgba: 'rgba(255, 255, 255, 1)',
      }

  const rgbLabel = ['R', 'G', 'B', 'A']
  const hex = colorValue.hex

  function handleColorChange(color) {
    const { hsla, rgba, hex } = formatColors(color)
    onChange({ hsla, rgba, hex })
  }

  function formatColors(color) {
    const { hex } = color
    const { h, s, l, a } = color.hsla
    const { r, g, b } = color.rgb

    return {
      hsla: `hsla(${h}, ${s}%, ${l}%, ${a})`,
      rgba: `rgba(${r}, ${g}, ${b}, ${a})`,
      hex,
    }
  }

  function getRGBA() {
    const { rgba = '' } = colorValue
    return rgba
      ?.substring(5, rgba.length - 1)
      .replace(/ /g, '')
      .split(',')
  }

  const backgroundColor = colorValue.rgba

  return (
    <div className="color-picker__wrapper" ref={containerRef}>
      <div className="color-picker__component">
        <Popover
          placement="topLeft"
          getPopupContainer={() => containerRef.current}
          content={
            <Sketch
              color={hex}
              onChange={handleColorChange}
              presetColors={[]}
            />
          }
          trigger="click"
          overlayClassName="color-picker__popover"
        >
          <div className="color-picker__component-preview">
            <div
              className="color-picker__component-preview-overlay"
              style={{ backgroundColor: backgroundColor }}
            />
          </div>
        </Popover>

        <div className="color-picker__component-label">
          {rgbLabel.map((el, index) => {
            return (
              <div key={index} className="color-picker__component-label-value">
                <label>{el}</label>
                <span>{getRGBA()[index]}</span>
              </div>
            )
          })}
        </div>
      </div>
      {props.description && (
        <div className="color-picker__message">{props.description}</div>
      )}
    </div>
  )
}
export default ColorPicker
