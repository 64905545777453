import UIStore from '../../stores/UIStore'
import processCloudinaryResponse from '../processCloudinaryResponse'

export default async function uploadFileToCloudinary(file) {
  const { enterpriseConfiguration } = UIStore
  const {
    cloudinaryApiUrl: baseUrl,
    cloudinaryCloudName: cloudName,
    cloudinaryApiKey,
    cloudinaryTimestamp,
    cloudinaryUploadSignature,
    cloudinaryPreset,
  } = enterpriseConfiguration

  const url = `${baseUrl}/${cloudName}/upload`

  const data = new FormData()
  data.append('file', file)
  data.append('api_key', cloudinaryApiKey)
  data.append('timestamp', cloudinaryTimestamp)
  data.append('upload_preset', cloudinaryPreset)
  data.append('signature', cloudinaryUploadSignature)

  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({ Accept: 'multipart/form-data' }),
    body: data,
  })

  const responseBody = await response.json()

  return processCloudinaryResponse(responseBody)
}
