import Select from '../../../components/Select'
import TextInput from '../../../components/TextInput'
import { UIStore } from '../../../stores'
import t from '../../../utils/translate'
import LimitLanguage from '../components/LimitLanguage'
import { SOURCES_TYPE, TARGET_TYPE } from '../constants'

const DefaultImportPanel = ({ formik }) => {
  return (
    <>
      <div className="importer-config__source-config">
        <TextInput
          onWhiteGround
          style={{ width: 405, marginRight: 32 }}
          label={t('Source-URL')}
          placeholder={t('Enter URL…')}
          value={formik.values.sourceUrl}
          onChange={(event) => {
            formik.setFieldValue('sourceUrl', event.target.value)
          }}
          error={formik.errors.sourceUrl}
        />
        <Select
          title={t('Importer target')}
          value={formik.values.targetType}
          translateLabel
          options={[
            { label: TARGET_TYPE['makaira'], value: 'makaira' },
            {
              label: TARGET_TYPE['persistence-layer'],
              value: 'persistence-layer',
            },
          ]}
          onChange={(value) => {
            formik.setFieldValue('targetType', value)
          }}
        />
        <div className="importer-config__source-config-auth">
          <TextInput
            onWhiteGround
            style={{ width: 206, marginRight: 16 }}
            label={t('Basic Auth User')}
            placeholder={t('Enter User…')}
            description={t('Leave empty if no Basic Auth is needed.')}
            value={formik.values.sourceAuthUser}
            onChange={(event) => {
              formik.setFieldValue('sourceAuthUser', event.target.value)
            }}
          />
          <TextInput
            onWhiteGround
            style={{ width: 206, marginRight: 32 }}
            label={t('Basic Auth Password')}
            placeholder={t('Enter password…')}
            value={formik.values.sourceAuthPassword}
            onChange={(event) => {
              formik.setFieldValue('sourceAuthPassword', event.target.value)
            }}
          />
          {/* The Secret is not needed for NDJSON */}
          {formik.values.sourceType !== SOURCES_TYPE.NDJSON && (
            <TextInput
              onWhiteGround
              style={{ width: 206 }}
              label={t('Secret')}
              value={formik.values.sourceSecret}
              onChange={(event) => {
                formik.setFieldValue('sourceSecret', event.target.value)
              }}
            />
          )}
        </div>
      </div>
      <LimitLanguage
        languageList={UIStore.availableLanguages}
        formikProps={formik}
      />
    </>
  )
}

export default DefaultImportPanel
