import { useState } from 'react'

import './hotspotImage.styl'
import ImageWithSpots from './ImageWithSpots'
import SpotList from './SpotList'
import FileUpload from '../FileUpload'
import { t } from '../../utils'

export default function HotSpotImage(props) {
  const {
    label,
    value = {},
    properties,
    readOnly,
    onChange,
    id,
    selectLanguage,
  } = props
  const [preview, setPreview] = useState(value?.image || null)

  const handleUpload = (val) => {
    setPreview(val)
    onChange({
      ...value,
      image: val,
    })
  }

  const handleRemovePreview = () => {
    setPreview(null)
    onChange({ image: null, spots: [] })
  }

  const updateOrAddSpot = ({ spot, dragIndex }) => {
    if (readOnly) return
    const _spots = [...(value?.spots ? value?.spots : [])]

    if (typeof dragIndex === 'number') {
      _spots[dragIndex] = {
        ..._spots[dragIndex],
        ...spot,
      }
    } else {
      _spots.push({
        ...spot,
      })
    }

    onChange({ ...value, spots: _spots })
  }

  const handleRemoveSpot = (index) => {
    const _spots = [...value.spots]
    _spots.splice(index, 1)
    onChange({ ...value, spots: _spots })
  }

  return (
    <>
      {label && <div className="hotspot__label">{label}</div>}
      <div className="hotspot__wrapper">
        {!preview ? (
          <FileUpload
            onChange={handleUpload}
            size="large"
            level={1}
            description={t(
              'Upload an image and start adding hotspots by clicking on the picture.'
            )}
          />
        ) : (
          <ImageWithSpots
            spots={value?.spots}
            preview={preview}
            updateOrAddSpot={updateOrAddSpot}
            onRemovePreview={handleRemovePreview}
            readOnly={readOnly}
          />
        )}

        <SpotList
          properties={properties}
          spots={value?.spots}
          onRemoveSpot={handleRemoveSpot}
          readOnly={readOnly}
          parentId={id}
          selectLanguage={selectLanguage}
        />
      </div>
    </>
  )
}
