import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import LoadingScreen from '../../components/LoadingScreen'

import ABTestStore from '../../stores/ABTestStore'
import { t } from '../../utils'
import ABTestingPageTitle from './ABTestingPageTitle'
import ResourceTableFilter from '../../components/ResourceTable/ResourceTableFilter'

import './style.styl'
import Timeline from './Timeline'

const TimelineWrapper = (props) => {
  const timelineRef = useRef()
  const [timelineWidth, setTimelineWidth] = useState(-1)

  useEffect(() => {
    updateTimelineWidth()

    window.addEventListener('resize', updateTimelineWidth)

    return () => {
      window.removeEventListener('resize', updateTimelineWidth)
    }
  }, [])

  function updateTimelineWidth() {
    setTimelineWidth(timelineRef.current.clientWidth)
  }

  return (
    <div
      ref={timelineRef}
      style={{
        width: '100%',
      }}
    >
      <Timeline width={timelineWidth} {...props} />
    </div>
  )
}

const TimelineView = () => {
  const timelineTests = ABTestStore.timeline
  const {
    _start = moment().subtract(2, 'years').toISOString(),
    _end = moment().add(90, 'days').toISOString(),
  } = ABTestStore.filter || {}

  const [activeRangeStartDate, setActiveRangeStartDate] = useState(
    moment().subtract(60, 'days')
  )

  const [activeRangeDays, setActiveRangeDays] = useState(120)

  useEffect(() => {
    ABTestStore.fetchTimelineTest(true)
  }, [])

  const filters = [
    {
      type: 'datepicker',
      title: t('Display timeline from…'),
      placeholder: t('Select Timestamp'),
      name: '_start',
      dataFormat: (date) => (date ? date.toISOString() : ''),
      onChange: ABTestStore.setFilter,
      value: moment(ABTestStore?.filter?._start || ''),
      allowClear: false,
      disabledDate: (current) => current.isSameOrAfter(moment(_end)),
    },
    {
      type: 'datepicker',
      title: t('…until'),
      placeholder: t('Select Timestamp'),
      name: '_end',
      dataFormat: (date) => (date ? date.toISOString() : ''),
      onChange: ABTestStore.setFilter,
      value: moment(ABTestStore?.filter?._end || ''),
      allowClear: false,
      disabledDate: (current) => current.isSameOrBefore(moment(_start)),
    },
  ]

  const timelineTestsWithoutDraft = timelineTests.filter(
    (test) => test.status !== 'draft'
  )

  return (
    <>
      <ABTestingPageTitle />
      {!ABTestStore.filter?._end && <LoadingScreen />}
      {ABTestStore.filter?._end && (
        <div className="content-wrapper ab-testing">
          <ResourceTableFilter
            filters={filters}
            store={ABTestStore}
            filterParams="filter"
          />
          <TimelineWrapper
            startDate={_start}
            endDate={_end}
            items={timelineTestsWithoutDraft}
            activeRangeStartDate={activeRangeStartDate}
            setActiveRangeStartDate={setActiveRangeStartDate}
            activeRangeDays={activeRangeDays}
            setActiveRangeDays={setActiveRangeDays}
            type="year"
            variant="overview"
          />
          <TimelineWrapper
            startDate={activeRangeStartDate.toISOString()}
            endDate={activeRangeStartDate
              .clone()
              .add(activeRangeDays, 'day')
              .toISOString()}
            items={timelineTestsWithoutDraft}
            type="month"
            variant="detail"
          />
        </div>
      )}
    </>
  )
}

export default observer(TimelineView)
