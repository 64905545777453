import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'

import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import { UIStore } from '../../../stores'
import { getInstance, t } from '../../../utils'

function URLField({ data }) {
  const clickHandler = (e) => {
    e.stopPropagation()
  }

  const getFeedLink = (language) => {
    return `${window.location.origin}/feeds/${language}/${
      data?.name
    }?instance=${getInstance()}${data?.token ? '&token=' + data?.token : ''}`
  }

  const title = (
    <div className={'feeds__url-tooltip'} onClick={clickHandler}>
      {UIStore.languages.map((language) => (
        <div key={language} className={'feeds__url-tooltip-field'}>
          <a
            href={getFeedLink(language)}
            target="_blank"
            rel="noreferrer"
            className={'feeds__url-tooltip-field'}
          >
            {language.toUpperCase()}: {getFeedLink(language)}
          </a>
          <CopyToClipboard
            text={getFeedLink(language)}
            onCopy={() => toast.success(t('Link copied!'))}
          >
            <button>
              <Icon symbol="clipboard" width={13} height={13} />
            </button>
          </CopyToClipboard>
        </div>
      ))}
    </div>
  )

  return (
    <Tooltip
      title={title}
      overlayInnerStyle={{ padding: 0 }}
      overlayStyle={{ maxWidth: 'unset' }}
    >
      <Text>
        <Icon symbol="globe" width={15} height={15} />
      </Text>
    </Tooltip>
  )
}

export default observer(URLField)
