import { useState } from 'react'
import { createPortal } from 'react-dom'
import { observer } from 'mobx-react-lite'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import SortableItem, { Item } from './SortableItem'
import { EditorStore } from '../../../stores'

const DnDElements = () => {
  const [activeElement, setActiveElement] = useState(null)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const { updateElements, elements } = EditorStore

  const handleDragStart = (e) => {
    setActiveElement(elements.find((element) => element.id === e.active.id))
  }

  const handleDragEnd = (e) => {
    const { active, over } = e

    if (active.id !== over.id) {
      let elementsMoved = [...elements]

      const oldIndex = elementsMoved.findIndex(
        (element) => element.id === active.id
      )
      const newIndex = elementsMoved.findIndex(
        (element) => element.id === over.id
      )

      elementsMoved = arrayMove(elementsMoved, oldIndex, newIndex)
      updateElements(elementsMoved)
    }

    setActiveElement(null)
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
      collisionDetection={closestCenter}
    >
      <SortableContext items={elements} strategy={verticalListSortingStrategy}>
        {elements?.map((element) => (
          <SortableItem
            hidden={activeElement && element.id === activeElement.id}
            key={element.id}
            field={element}
          />
        ))}
      </SortableContext>
      {createPortal(
        <DragOverlay dropAnimation={null}>
          {activeElement ? (
            <Item key={activeElement.id} field={activeElement} isOverlay />
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  )
}

export default observer(DnDElements)
