import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import SmartBundleStore from '../../stores/SmartBundleStore'
import { createRedirectPath, t } from '../../utils'

export const calculateArrowPosition = (e) => {
  const element = e ? e.currentTarget : document.getElementById('config-button')
  if (!element) return

  const rect = element.getBoundingClientRect()
  const offsetLeft = rect.left + window.scrollX

  document.body.style.setProperty(
    '--arrow-position-left',
    offsetLeft + 6 + 'px'
  )
}

export default function Actions(props) {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { id } = useParams()
  const { onClickSetting, onClickApply, isShowConfiguration } = props

  const handleSave = async () => {
    setLoading(true)
    const { id } = await SmartBundleStore.updateOrCreate()
    setLoading(false)

    history.push(createRedirectPath(`/bundle/${id}`))
  }

  const handleClickSetting = (e) => {
    calculateArrowPosition(e)
    onClickSetting()
  }

  if (!isShowConfiguration) {
    return (
      <Button
        id="config-button"
        variant="tertiary"
        icon="sliders-up"
        onClick={handleClickSetting}
      >
        {t('Settings')}
      </Button>
    )
  }

  if (isShowConfiguration) {
    return (
      <Button
        id="config-button"
        onClick={onClickApply}
        variant="tertiary"
        icon="times"
      />
    )
  }

  if (!id) return null

  return (
    <Button
      id="config-button"
      variant="primary"
      onClick={handleSave}
      loading={loading}
      icon="check"
    >
      {t('Save')}
    </Button>
  )
}
