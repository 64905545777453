import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/reco?${queryString}`

    return fetch(target)
  },

  async get(id) {
    return fetch(`/reco/${id}`)
  },

  async create(recommendation) {
    return fetch('/reco', {
      method: 'POST',
      body: JSON.stringify(recommendation),
    })
  },

  async update(recommendation) {
    await fetch(`/reco/${recommendation.id}`, {
      method: 'PUT',
      body: JSON.stringify(recommendation),
    })
  },

  async delete(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))

    await fetch(`/reco?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },

  async getAvailableTypes(shopid) {
    return fetch(`/reco/available-types/${shopid}`, { method: 'GET' })
  },

  async getRecommendationList(body) {
    return fetch(`/recommendation/public`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
  },
}
