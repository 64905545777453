import { Popover } from 'antd'
import { useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import PageContextMenu from './PageContextMenu'
import Button from '../../../../components/Button'
import ComponentContextMenu from './ComponentContextMenu'

import EditorStore from '../../../../stores/PageEditor/EditorStore'

const ContextMenu = ({
  type = 'page',
  isComponentAvailable = true,
  field,
  isComponentDeprecated = false,
}) => {
  const [visible, setVisible] = useState(false)

  const handleMenuHide = () => {
    setVisible(false)
  }

  const MenuComponent = type === 'page' ? PageContextMenu : ComponentContextMenu

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={classNames('page-editor__field-actions', {
        'page-editor__field-actions--visible': visible,
        'page-editor__field-actions--page': type === 'page',
      })}
    >
      <Popover
        visible={visible}
        onVisibleChange={(visible) => setVisible(visible)}
        content={
          <MenuComponent
            field={field}
            visible={visible}
            onMenuHide={handleMenuHide}
            isComponentAvailable={isComponentAvailable}
            isComponentDeprecated={isComponentDeprecated}
          />
        }
        placement="bottomLeft"
      >
        <Button
          onMouseEnter={() => EditorStore.updateClipboard()}
          icon="ellipsis-vertical__solid"
          variant="tertiary"
        />
      </Popover>
    </div>
  )
}

export default observer(ContextMenu)
