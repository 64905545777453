import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ICONS from './iconList'
import './icon.styl'

//note: go to iconList.js to define new icon if it not available yet
// if it is a custom icon, go to assets/svgs/makaira-icons.svg to add icon

export default function Icon(props) {
  const {
    symbol,
    className,
    width,
    height,
    style = {},
    size = '10x',
    makairaIcon = false,
    ...rest
  } = props
  const classes = classNames(className, 'icon', `icon--${symbol}`)

  return (
    <span style={{ ...style, width, height }} className={classes} {...rest}>
      {!makairaIcon && (
        <FontAwesomeIcon
          icon={ICONS[symbol] || `fa-light fa-${symbol}`}
          size={size}
          fixedWidth
        />
      )}
      {makairaIcon && (
        <svg role="img" width={width} height={height}>
          <use xlinkHref={`#${symbol}`} />
        </svg>
      )}
    </span>
  )
}
