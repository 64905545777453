import { observer } from 'mobx-react-lite'

import TextInput from '../../../../components/TextInput'
import Select from '../../../../components/Select'
import Icon from '../../../../components/Icon'
import Text from '../../../../components/Text'
import Textarea from '../../../../components/Textarea'

import { ComponentStore } from '../../../../stores'
import { t } from '../../../../utils'

import { AppIntegration } from '../AppIntegration'
import FormSection from './FormSection'
import {
  FieldArray,
  FieldDocument,
  FieldPrimitive,
  FieldSelect,
} from '../fields'
import { AVAILABLE_FIELD_TYPES, FIELDS_SUPPORT_APP } from '../../constants'
import Switch from '../../../../components/Switch'
import FieldDate from '../fields/FieldDate'

const fieldComponents = {
  text: FieldPrimitive,
  number: FieldPrimitive,
  richtext: FieldPrimitive,
  checkbox: FieldPrimitive,
  date: FieldDate,
  select: FieldSelect,
  multiselect: FieldSelect,
  array: FieldArray,
  documents: FieldDocument,
}

function FieldSettings({ settings = {} }) {
  const { id, type, label, description, uuid, required } = settings
  const Component = fieldComponents[type]

  const onChangeProperties = (properties) => {
    for (let key of Object.keys(properties)) {
      ComponentStore.updateField(
        ComponentStore.activeFieldId,
        key,
        properties[key]
      )
    }
  }

  const isSupportApp = FIELDS_SUPPORT_APP.includes(type)
  return (
    <>
      <FormSection title={t('Identifier')}>
        <TextInput
          className="field-configuration__text-input"
          type="text"
          name="name"
          label={t('Name')}
          value={label}
          onChange={(event) => {
            onChangeProperties({ label: event.target.value })
          }}
          key={`name_${uuid}`}
          disabled={!ComponentStore.activeFieldId}
        />
        <TextInput
          className="field-configuration__text-input"
          type="text"
          name="id"
          label={t('Id')}
          value={id}
          onChange={(event) => {
            onChangeProperties({ id: event.target.value })
          }}
          key={`id_${uuid}`}
          disabled={!ComponentStore.activeFieldId}
        />
      </FormSection>

      <FormSection title={t('Form element')}>
        <Switch
          checked={required}
          type="horizontal"
          title={t('Mandatory field')}
          onChange={(checked) => {
            onChangeProperties({
              required: checked,
            })
          }}
        />
        <Select
          disabled
          key={`type_${uuid}`}
          title={t('Type')}
          value={type}
          options={AVAILABLE_FIELD_TYPES.map((option) => {
            return {
              value: option.value,
              label: (
                <Text>
                  <Icon
                    symbol={option.icon}
                    width="12px"
                    height="12px"
                    style={{ marginRight: '8px' }}
                  />
                  {t(option.label)}
                </Text>
              ),
            }
          })}
        />
        {Component && (
          <Component
            key={`component_${uuid}`}
            fieldSettings={settings}
            onChange={onChangeProperties}
          />
        )}
        {isSupportApp && (
          <AppIntegration
            key={`app_integration_${uuid}`}
            onChange={(apps) => {
              onChangeProperties({
                apps,
              })
            }}
            value={settings.apps || []}
          />
        )}
      </FormSection>
      <FormSection title={t('Instructions')}>
        <Textarea
          title={t('Description')}
          value={description}
          rows={3}
          onChange={(description) => {
            onChangeProperties({ description })
          }}
          key={`description_${uuid}`}
          disabled={!ComponentStore.activeFieldId}
        />
      </FormSection>
    </>
  )
}

export default observer(FieldSettings)
