import React from 'react'
import downloadCSV from '../../utils/downloadCSV'
import t from '../../utils/translate'
import { Tooltip } from 'antd'
import { fetchUtil } from '../../utils/fetchUtil'
import Icon from '../Icon'

const DownloadAnalyticsButton = ({ record, type = 'advertising_space' }) => {
  const downloadFile = (data, trackingType) => {
    const text = data.data

    const fileName = `${type}_${trackingType}_${record.name
      .toLowerCase()
      .replaceAll(' ', '_')
      .replaceAll('-', '_')}_analytics.csv`

    downloadCSV({ text, fileName })
  }

  const handleDownloadClick = () => {
    const filesDownloads = []
    if (record.viewTrackingId) {
      filesDownloads.push(
        fetchUtil(`/statistics/goals/${record.viewTrackingId}/data`)
      )
    }
    if (record.clickTrackingId) {
      filesDownloads.push(
        fetchUtil(`/statistics/goals/${record.clickTrackingId}/data`)
      )
    }
    if (filesDownloads.length) {
      Promise.all(filesDownloads).then(([dataView, dataClick]) => {
        if (dataView) {
          downloadFile(dataView, 'view')
        }
        if (dataClick) {
          downloadFile(dataClick, 'click')
        }
      })
    }
  }

  return (
    <Tooltip title={t('downloadAnalyticsTooltip')}>
      <Icon
        className="mk-table__arrow-action"
        symbol="chart-column__light"
        width={18}
        height={18}
        onClick={handleDownloadClick}
      />
    </Tooltip>
  )
}

export default DownloadAnalyticsButton
