import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import ResourceTable from '../../components/ResourceTable'
import { Column } from '../../components/Table'
import ChangedField from '../../components/ChangedField'
import Text from '../../components/Text'
import Icon from '../../components/Icon'
import AuditStore from '../../stores/AuditStore'
import t from '../../utils/translate'

import './auditLogs.styl'
import Button from '../../components/Button'
import DiffModal from './DiffModal'
import JsonTruncate from './JsonTruncate'

function AuditLogDetail() {
  const { id } = useParams()

  useEffect(() => {
    AuditStore.getById(id)
    return () => {
      AuditStore.reset()
    }
  }, [id])

  const statusIconProps = (status) => {
    switch (status) {
      case 'remove':
        return {
          className: 'mk-table-cell__icon mk-table-cell__icon--pink',
          symbol: 'trash',
        }

      case 'insert':
        return {
          className: 'mk-table-cell__icon mk-table-cell__icon--green',
          symbol: 'plus',
        }

      case 'update':
        return {
          className: 'mk-table-cell__icon',
          symbol: 'pencil',
        }
      default:
        return {
          className: 'mk-table-cell__icon',
        }
    }
  }

  const handleRenderData = (record, { parent, child, subTitle }) => {
    if (record.isSubTitle) {
      return subTitle
    }
    if (record.isChild) {
      return child
    }
    return parent
  }

  return (
    <div className="content-wrapper audit-log-details">
      <ResourceTable
        title={`${t('Audit Log for')} ${id}`}
        store={AuditStore}
        resourceName="auditDetail"
        loading={AuditStore.state === 'pending'}
      >
        <Column
          render={(record) => {
            const iconProps = statusIconProps(record.action)
            return handleRenderData(record, {
              parent: (
                <div className={iconProps.className}>
                  <Icon height={12} symbol={iconProps.symbol} />
                </div>
              ),
            })
          }}
        />
        <Column
          title={t('Type of change')}
          render={(record) =>
            handleRenderData(record, {
              parent: <Text>{t(record.action)}</Text>,
              child: <Text>{record.keyChange}</Text>,
              subTitle: (
                <Text className="audit-log-details__sub-title" variant="book">
                  {t('Attribute')}
                </Text>
              ),
            })
          }
        />
        <Column
          title={t('Log ID')}
          className="mk-table-cell--bold"
          onCell={(record) => {
            const props = {}

            if (record.isChild || record.isSubTitle) {
              props.colSpan = 3
            }

            return props
          }}
          render={(record) =>
            handleRenderData(record, {
              parent: <Text weight="bold">{id + '#' + record.id}</Text>,
              child: (
                <div className="audit-log-details__diff">
                  <div>
                    <Text
                      weight="bold"
                      className={classNames(
                        'audit-log-details__value audit-log-details__value--old',
                        {
                          'audit-log-details__value--null':
                            AuditStore.transformData(record.old) === 'null',
                        }
                      )}
                    >
                      <JsonTruncate
                        line={3}
                        element="span"
                        truncateText=""
                        text={AuditStore.transformData(record.old)}
                        textTruncateChild={`... ${t('Show complete value')}`}
                      />
                    </Text>
                  </div>
                  <div>
                    <Text
                      weight="bold"
                      className={classNames(
                        'audit-log-details__value audit-log-details__value--new',
                        {
                          'audit-log-details__value--null':
                            AuditStore.transformData(record.new) === 'null',
                        }
                      )}
                    >
                      <JsonTruncate
                        line={3}
                        element="span"
                        truncateText=""
                        text={AuditStore.transformData(record.new)}
                        textTruncateChild={`... ${t('Show complete value')}`}
                      />
                    </Text>
                  </div>
                </div>
              ),
              subTitle: (
                <div className="audit-log-details__diff">
                  <div>
                    <Text
                      className="audit-log-details__sub-title"
                      variant="book"
                    >
                      {t('Old value')}
                    </Text>
                  </div>
                  <div>
                    <Text
                      className="audit-log-details__sub-title"
                      variant="book"
                    >
                      {t('New value')}
                    </Text>
                  </div>
                </div>
              ),
            })
          }
        />

        <Column
          title={t('Object ID')}
          dataIndex="objectId"
          onCell={(record) => {
            const props = {}

            if (record.isChild || record.isSubTitle) {
              props.colSpan = 0
            }

            return props
          }}
        />
        <Column
          title={t('By account')}
          dataIndex="user"
          onCell={(record) => {
            const props = {}

            if (record.isChild || record.isSubTitle) {
              props.colSpan = 0
            }

            return props
          }}
        />
        <Column
          title={t('Date of edit')}
          width={170}
          render={(record) =>
            handleRenderData(record, {
              parent: <ChangedField changed={{ date: record.changedAt }} />,
              child: (
                <Button
                  onClick={() =>
                    AuditStore.openDiffModal(true, {
                      old: record.old,
                      new: record.new,
                    })
                  }
                >
                  {t('View Changes')}
                </Button>
              ),
            })
          }
        />
      </ResourceTable>
      <DiffModal />
    </div>
  )
}

export default observer(AuditLogDetail)
