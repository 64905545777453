import { t } from '../../utils'
import Text from '../Text'

export default function Heading(props) {
  const { type = '', allTypes = [] } = props

  const text = allTypes.find((item) => type.includes(item.id))?.title

  return (
    <Text size="bravo" className="search-preview__type-title">
      {t(text)}
    </Text>
  )
}
