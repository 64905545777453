export const statusWithErrors = {
  trackingIdSet: true,
  machineLearningGroupingFields: ['manufacturer_title', 'maincategory'],
  haveOrderCompletedCalls: {
    result: true,
  },
  haveDetailPageCalls: {
    result: true,
  },
  checkEansOnMatomoExistsInMakaira: {
    result: true,
  },
  checkEnoughElapsedData: {
    result: true,
  },
  haveAbandonedCartCalls: {
    result: true,
  },
  machineLearningLastSuccessfulCalc: {
    result: true,
  },
  checkMachineLearningReadinessFlag: {
    result: true,
  },
  checkTermAggregationOnGroupingFields: {
    result: true,
    message:
      "More than 80% of the documents contain the value '' in the field 'manufacturer_title'",
  },
  allPassed: {
    result: true,
  },
}

export const USERS = [
  {
    name: 'Berlin - MacOS - Safari',
    'oi.user.agent':
      'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_4) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.1 Safari/605.1.15',
    'oi.user.ip': '95.91.209.0',
  },
  {
    name: 'Oberstdorf - Windows - Chrome',
    'oi.user.agent':
      'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/66.0.3359.139 Safari/537.36',
    'oi.user.ip': '95.90.199.0',
  },
  {
    name: 'Wien - Android - Chrome',
    'oi.user.agent':
      'Mozilla/5.0 (Linux; Android 6.0.1; SM-G532G Build/MMB29T) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.83 Mobile Safari/537.36',
    'oi.user.ip': '77.119.129.0',
  },
]
