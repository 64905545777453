import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import get from 'lodash/get'

import { t } from '../../utils'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import TextInput from '../../components/TextInput'
import Text from '../../components/Text'
import MenuStore from '../../stores/MenuStore'
import Switch from '../../components/Switch'

function SettingModal({ updateNode }) {
  const { settingModalVisible, closeSettingModal, modalNode, contentLanguage } =
    MenuStore

  const [settings, setSettings] = useState({
    css: modalNode.css,
    isRedirectOnly: modalNode.isRedirectOnly,
  })

  useEffect(() => {
    setSettings({
      css: modalNode.css,
      isRedirectOnly: modalNode.isRedirectOnly,
    })

    return () => {
      setSettings({})
    }
  }, [settingModalVisible, modalNode])

  if (!settingModalVisible) return null

  const onSaveSettings = () => {
    let updated = { ...toJS(modalNode) }
    updated['isRedirectOnly'] = settings.isRedirectOnly
    updated['css'] = settings.css

    updateNode(modalNode.uuid, updated)
    closeSettingModal()
  }

  return (
    <Modal
      visible={settingModalVisible}
      onClose={closeSettingModal}
      header={`${t('Settings for')} "${get(modalNode, [
        'text',
        contentLanguage,
      ])}"`}
      mask
      maskClosable
      footer={[
        <Button
          key="cancel"
          variant="secondary"
          level={1}
          icon="xmark"
          onClick={closeSettingModal}
        >
          {t('Cancel')}
        </Button>,
        <Button
          key="save"
          variant="secondary-blocker"
          level={1}
          icon="chevron-right"
          onClick={onSaveSettings}
        >
          {t('Apply')}
        </Button>,
      ]}
    >
      <TextInput
        value={settings.css}
        onChange={(e) =>
          setSettings((settings) => ({
            ...settings,
            css: e.target.value,
          }))
        }
        label={t('CSS-Classes for this menu entry')}
        description={t(
          'This classes are inserted as an attribute of the <a>-tag. Multiple classes separated with space'
        )}
      />
      <div className="menu-editor__switch-wrapper">
        <Switch
          title={<Text className="text-input__label">{t('Visibility')}</Text>}
          checked={settings.isRedirectOnly}
          onChange={(checked) =>
            setSettings((settings) => ({
              ...settings,
              isRedirectOnly: checked,
            }))
          }
        />
        <div className="switch-input__label">
          {t("Only show in menu and don't use for breadcrumbs")}
        </div>
      </div>
    </Modal>
  )
}

export default observer(SettingModal)
