import { observer } from 'mobx-react-lite'
import React from 'react'
import Text from '../../components/Text'
import { FieldStore } from '../../stores'

const FieldTitle = ({ id }) => {
  return <Text>{FieldStore.fieldsByIds[id]?.title}</Text>
}

export default observer(FieldTitle)
