import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import SmartBundleStore from '../../stores/SmartBundleStore'
import UIStore from '../../stores/UIStore'
import { t } from '../../utils'
import classNames from 'classnames'
import Text from '../../components/Text'
import Button from '../../components/Button'

const ConnectionButton = ({ connection, id }) => {
  const [lineWidth, setLineWidth] = useState(0)

  const language = UIStore.currentLanguage

  useEffect(() => {
    window.setTimeout(() => {
      const btns = document.getElementsByClassName(
        [connection.first_slot.id, connection.second_slot.id].join('-')
      )

      if (btns.length !== 2) return 0

      const rect = Array.from(btns)[0].getBoundingClientRect()
      const rect2 = Array.from(btns)[1].getBoundingClientRect()

      setLineWidth(Math.abs(rect2.left - rect.left) - rect2.width)
    }, 100)
  }, [connection, language])

  const position = SmartBundleStore.getSlotPosition({ id })
  const firstSlotPosition = SmartBundleStore.getSlotPosition(
    connection.first_slot
  )
  const secondSlotPosition = SmartBundleStore.getSlotPosition(
    connection.second_slot
  )
  const showLine =
    position <= firstSlotPosition && position <= secondSlotPosition

  const handleHover = (e) => {
    const btns = document.getElementsByClassName(e.currentTarget.className)
    Array.from(btns).forEach((btn) => btn.classList.toggle('hover'))
  }

  const getDisplayValue = (connection) => {
    let displayValue = t('Slot') + ' '

    if (connection.first_slot.id == id) {
      displayValue += SmartBundleStore.getSlotPosition(connection.second_slot)
    } else {
      displayValue += SmartBundleStore.getSlotPosition(connection.first_slot)
    }

    return displayValue
  }

  return (
    <div
      className={classNames(
        'bundle__active-connection',
        [connection.first_slot.id, connection.second_slot.id].join('-')
      )}
      onClick={() => SmartBundleStore.openConnectionModal(connection)}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      <Text>{getDisplayValue(connection)}</Text>
      {showLine && (
        <div
          style={{ width: lineWidth + 'px' }}
          className="bundle__active-connection-line"
        >
          <Button
            className="bundle__connection-edit-button"
            variant="tertiary"
            icon="pencil"
          />
        </div>
      )}
    </div>
  )
}

export default observer(ConnectionButton)
