import moment from 'moment'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import Text from '../../components/Text'
import Badge from '../../components/Badge'
import ResourceTable from '../../components/ResourceTable'
import { ArrowAction, Column } from '../../components/Table'
import { ActionLayerStore, UIStore } from '../../stores'

import TitleField from './TitleField'
import ABTestingPageTitle from './ABTestingPageTitle'

import { getBadgeProps } from './utils'
import { createRedirectPath, t, usePathName } from '../../utils'

import ABTestStore from '../../stores/ABTestStore'

import './style.styl'
import { getRedirectPath } from '../../components/ABMetaInformation/utils'

const ABTestingList = () => {
  const pathWithoutInstance = usePathName()
  const type = pathWithoutInstance.split('/ab-testing/')[1]
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    ABTestStore.fetchSupportedMetrics()
    ABTestStore.fetchTests(type, true)
    // eslint-disable-next-line
  }, [])

  const handleDelete = async () => {
    await ABTestStore.delete(selectedRows)

    if (ABTestStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const bulkActions = [
    {
      title: t('Delete'),
      onExecute: () => showDeleteWarning(),
    },
  ]

  const checkDisable = (row) => row.status === 'running'

  const filters = useMemo(() => {
    let data = [
      {
        type: 'single-select',
        title: t('Status'),
        placeholder: t('Select Type'),
        name: '_status',
        onChange: ABTestStore.setFilter,
        options: [
          { label: t('All'), value: '' },
          { label: t('running_ab_status'), value: 'running' },
          { label: t('scheduled_ab_status'), value: 'scheduled' },
          { label: t('draft_ab_status'), value: 'draft' },
        ],
        value: ABTestStore.filter?._status,
      },
      {
        type: 'search',
        title: t('Including'),
        name: '_q',
        onChange: ABTestStore.setFilter,
        defaultValue: ABTestStore.filter?._q,
      },
      {
        type: 'datepicker',
        title: t('Start on or after…'),
        placeholder: t('Select Timestamp'),
        name: '_start_after',
        dataFormat: (date) => (date ? date.toISOString() : ''),
        onChange: ABTestStore.setFilter,
      },
    ]

    if (type === 'done') {
      data.shift()
      data.push({
        type: 'datepicker',
        title: t('End on or before…'),
        placeholder: t('Select Timestamp'),
        name: '_stop_before',
        dataFormat: (date) => (date ? date.toISOString() : ''),
        onChange: ABTestStore.setFilter,
      })
    }

    return data
    // UIStore.currentLanguage is required here so that when the user
    // changes the selected langauge the filter labels get updated.
    // eslint-disable-next-line
  }, [type, UIStore.currentLanguage])

  const handleClickRow = (row) => {
    // we just handle for frontend, ml case for now
    // TODO: update AB testing for all other cases
    if (
      ![
        'frontend',
        'personalization',
        'ranking_mix',
        'recommendation',
      ].includes(row.case)
    )
      return
    const newSearchParams = new URLSearchParams({
      'ab-test': row.id,
    })

    if (
      ['ranking_mix', 'personalization', 'recommendation'].includes(row.case)
    ) {
      newSearchParams.set('ab-variant', 'A')
    }

    const newURL = `${createRedirectPath(
      getRedirectPath(row.case)
    )}?${newSearchParams.toString()}`

    history.push(newURL)
  }

  return (
    <>
      <ABTestingPageTitle />
      <div className="content-wrapper ab-testing">
        <ResourceTable
          title={t(type === 'upcoming' ? 'Upcoming' : 'Archive')}
          store={ABTestStore}
          resourceName={type}
          loading={ABTestStore.state === 'pending'}
          headerAction={{
            type: 'create',
            action: () => history.push(createRedirectPath('/ab-testing/new')),
            children: t('Add test'),
            iconPosition: 'left',
          }}
          filters={filters}
          bulkActions={bulkActions}
          rowSelection={{
            checkDisable,
            selectedRows,
            onSelectionChange: (newRows) => setSelectedRows(newRows),
          }}
          onRowClick={handleClickRow}
          noResultsText={t('No A/B-Test could be found.')}
          emptyText={t(
            'Create an A/B-Test to test on the one hand configurations of Makaira in a data-driven manner\nand on the other hand to play out different versions in the frontend.'
          )}
          createActionText={t('Add test')}
          createAction={() =>
            history.push(createRedirectPath('/ab-testing/new'))
          }
          hideEmptyComponentWhenEmpty={ABTestStore.isFiltered}
        >
          <Column
            title={t('Status')}
            dataIndex="status"
            render={(status) => (
              <Badge
                {...getBadgeProps(status)}
                text={t(`${status}_ab_status`)}
              />
            )}
          />
          <Column
            title={t('Test Name') + ' (ID)'}
            dataIndex="title"
            render={TitleField}
            className="mk-table-cell__column-name"
          />
          <Column title={t('Type')} dataIndex="type" />
          <Column
            title={t('Start')}
            dataIndex="startDate"
            render={(data) => (
              <Text>{data ? moment(data.date).format('DD.MM.YYYY') : '-'}</Text>
            )}
          />
          <Column title={t('Duration')} dataIndex="duration" />
          <Column title={t('Segments')} dataIndex="segments" />
          <Column
            title={t('Maximum change for…')}
            dataIndex="maximum_change"
            options={ABTestStore.supportedMetrics}
            selectedValue={ABTestStore.selectedMetrics}
            onChangeSelect={ABTestStore.selectMetric}
            render={(data) => (
              <Text>
                {get(data, ABTestStore.selectedMetric, [])
                  .map(
                    (variation) =>
                      `Variation ${variation.variation_name}: ${variation.detected_effect}%`
                  )
                  .join(', ') || '-'}
              </Text>
            )}
          />
          <Column width={50} render={ArrowAction} />
        </ResourceTable>
      </div>
    </>
  )
}

export default observer(ABTestingList)
