import routes from '../../routing/routes'

export const getApps = () => {
  return [
    {
      name: 'Page Editor',
      icon: 'file-powerpoint',
      route: routes.pageEditor,
      doc: '/page-editor',
      section: 'Marketing',
      description:
        'The Page Editor is the heart for creating content in Makaira. The editor enables you to easily create landing pages, enrich entities like categories or manufacturers with content or create content snippets that could be reused on certain pages.',
    },
    {
      name: 'Menu Editor',
      icon: 'bars-staggered',
      route: routes.menuEditor,
      doc: '/menu-editor',
      section: 'Marketing',
      description:
        'Using the Menu Editor the main menu structure of the storefront can be defined. Menu Editor section also allows us setting up sub menues or sub contents under particular menu.',
    },
    {
      name: 'A/B-Testing',
      icon: 'vials',
      route: routes.abTestingUpcomingList,
      section: 'Marketing',
      doc: '/ab-experiments',
      description:
        'The "A/B Experiments" module makes it possible on the one hand to test configurations of Makaira in a data-driven manner and on the other hand to play out different versions in the frontend. For example, different buyboxes or different page structures can be tested against each other.',
    },
    {
      name: 'Search',
      icon: 'magnifying-glass',
      route: routes.searchConfiguration,
      doc: '/search',
      section: 'Marketing',
      description:
        'The search is the heart of Makaira and offers a variety of configuration for search functionality.',
    },
    {
      name: 'Categories',
      icon: 'folder-tree',
      route: routes.categories,
      doc: '/categories',
      section: 'Marketing',
      description:
        'This app will list all categories that were imported by Makaira from your shop system. You have here the option to assign custom streams to them and adjust displayed filters.',
    },
    {
      name: 'Ranking',
      icon: 'abacus',
      route: routes.rankingMix,
      doc: '/ranking-mix',
      section: 'Marketing',
      description:
        'The Ranking Mix setting allows you to influence (boost) the order of category listing and search results based on additional parameters.',
    },
    {
      name: 'Personalization',
      icon: 'users',
      route: routes.personalization,
      doc: '/machine-learning',
      section: 'Marketing',
      description: `Machine learning is the study of user's behaviour under various circumstances which helps in presenting suitable findings and content to the users in the future. Let's start with an example: Let's assume that tablet users in Bavaria regularly buy leather pants in the afternoon more often than iPhone users in Berlin during the same period. The machine learning now learns that tablet users from Bavaria are much more likely to search for leather pants than our Berlin customers, for example. If the tracking data of a whole year is available, it is also decided here whether it is a seasonal business and leather pants should only be displayed further in front in certain periods or whether the behavior is to be observed throughout the year. If leather pants in Bavaria are no longer in fashion for some reason, this will of course also be detected by machine learning and the search engine will be adjusted accordingly. Such trend changes do not happen from one day to the next, of course, and so the machine learning will slowly adapt here.`,
    },
    {
      name: 'Recommendations',
      icon: 'comment-plus',
      route: routes.recommendations,
      doc: '/recommendations',
      section: 'Marketing',
      description:
        'Recommendations provides the shop users with recommended products e.g. on a product detail page. You can also create cart based or top seller recommendations. The possibilities are endless!',
    },
    {
      name: 'Advertising Banners',
      icon: 'megaphone',
      route: routes.bannerList,
      requireFeature: 'promotion',
      doc: '/advertising-banner',
      roles: ['owner', 'admin', 'shopmanager', 'contentcreator'],
      section: 'Marketing',
      description:
        'The "Advertising banner" module allows you to configure images of advertisement to be displayed on list pages in addition to the regular results.',
    },
    {
      name: 'Advertising Spaces',
      icon: 'megaphone',
      route: routes.advertisingSpaces,
      requireFeature: 'promotion',
      doc: '/advertising-space',
      roles: ['owner', 'admin', 'shopmanager'],
      section: 'Marketing',
      description:
        'Advertising space is intended to advertise or prioritize products among relevant search results. With the help of advertising space, Makaira offers the possibility of placing targeted products, manufacturers and categories on selected positions of the search results and/or categories with the "Advertising Places" module.',
    },
    {
      name: 'Redirects',
      icon: 'diamond-turn-right',
      route: routes.redirects,
      doc: '/redirects',
      section: 'Marketing',
      description:
        'The Redirect module let you efficiently manage website redirections, seamlessly directing incoming traffic from specific URLs to designated target destinations',
    },
    {
      name: 'Smart Bundles',
      icon: 'box-open-full',
      route: routes.bundleList,
      doc: '/smart-bundles',
      section: 'Marketing',
      description:
        'Smart Bundles allows you to create bundles of related products based on various criteria. Through the bundles you increase the shopping cart and reduce returns, because only matching products can be added to the cart. In this way, matching outfits, rooms, equipment, etc. can be created according to the products.',
    },
    {
      name: 'Index & Importer',
      icon: 'arrows-rotate',
      route: routes.indexOverview,
      doc: '/index-configuration',
      section: 'Data',
      description:
        'The "Index and Importer" application gives you access to the core of Makaira: constantly updated product data. You can get insides about currently active and archived indices, about configured importers, or about the current status of the import process. You can also specify details of the import process like sources, scheduled index rebuilds or notification options for possible occurring errors.',
    },
    {
      name: 'Data Inspector',
      icon: 'file-magnifying-glass',
      route: routes.dataInspector,
      doc: '/data-inspector',
      roles: ['owner', 'admin', 'shopmanager'],
      section: 'Data',
      description:
        'The Data Inspector allows you to perform searches directly in the Makaira API as well as display the products from a stream. The returned results can be used as a preview or as a test whether, for example, your modifier works as desired.',
    },
    {
      name: 'Product Feeds',
      icon: 'file-export',
      route: routes.feeds,
      doc: '/feed-engine',
      section: 'Data',
      description:
        'Feeds of relevant articles are needed for external sales portals (e.g. Google, Facebook etc). You can generate such feeds easily and quickly in Makaira with the help of feed engine.',
    },
    {
      name: 'Streams',
      icon: 'rss',
      route: routes.ProductStreamList,
      doc: '/product-streams',
      section: 'Data',
      description:
        'Streams allow the creation of filtered views on data. A common use case for streams is, for example, the provision of dynamic categories as part of promotional offers where a discount is given on selected brands, colors, sizes, etc.',
    },
    {
      name: 'Category Mapping',
      icon: 'folders',
      route: routes.categoryMapping,
      section: 'Data',
      permission: [
        'categories.read',
        'abtesting.read',
        'personalization.read',
        'recommendations.read',
        'streams.read',
      ],
    },
    {
      name: 'User Management',
      icon: 'user',
      route: routes.userList,
      section: 'Administration',
    },
    {
      name: 'Storefront',
      icon: 'browser',
      route: routes.storefrontSettings,
      section: 'Administration',
    },
    {
      name: 'Audit Logs',
      icon: 'file-user',
      route: routes.auditList,
      section: 'Administration',
    },
    {
      name: 'Component Editor',
      icon: 'toolbox',
      route: routes.componentEditorList,
      section: 'Administration',
      doc: '/components-editor',
      description:
        'The component editor offers the possibility to create your own placeable components in addition to the standard components, in order to insert and fill them in the Page Editor.',
    },
    {
      name: 'Statistic',
      icon: 'chart-simple',
      route: routes.statistic,
      section: 'Administration',
    },
    {
      name: 'Settings',
      icon: 'gear',
      route: routes.settings,
      section: 'Administration',
    },
    {
      name: 'API Keys',
      icon: 'key-skeleton',
      route: routes.secretList,
      section: 'Administration',
    },
  ]
}
