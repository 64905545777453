import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/redirects?${queryString}`

    return fetch(target)
  },

  async create(redirect) {
    return fetch('/redirects', {
      method: 'POST',
      body: JSON.stringify(redirect),
    })
  },

  async update(redirect) {
    await fetch(`/redirects/${redirect.id}`, {
      method: 'PUT',
      body: JSON.stringify(redirect),
    })
  },

  async delete(id) {
    await fetch(`/redirects/${id}`, { method: 'DELETE' })
  },

  async bulkCreateCSV(file) {
    const data = new FormData()
    data.append('file', file)

    return fetch('/redirects/bulk', {
      method: 'POST',
      body: data,
    })
  },

  async fetchCSVData() {
    return fetch(`/redirects-csv`)
  },
}
