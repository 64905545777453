import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import t from '../../utils/translate'

function defaultEditingExperiment() {
  try {
    const experiment = sessionStorage.getItem('experiment') || false
    return experiment ? JSON.parse(experiment) : {}
  } catch (e) {
    console.error(e.message)
    return {}
  }
}

class ExperimentStore {
  experiments = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = null
  state = 'pending' // "pending", "done" or "error"
  experimentResult = {}
  running = {}
  editingExperiment = defaultEditingExperiment()

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setEditingExperiment(data) {
    try {
      sessionStorage.setItem('experiment', JSON.stringify(data))
      this.editingExperiment = data
    } catch (e) {
      console.error(e.message)
    }
  }

  removeEditingExperiment() {
    sessionStorage.removeItem('experiment')
    this.editingExperiment = {}
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchExperiments()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchExperiments()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchExperiments()
  }

  getById(id) {
    return this.experiments.find((s) => s.id == id)
  }

  *fetchExperiments() {
    this.state = 'pending'
    try {
      const { data, total } = yield api.experiments.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.experiments = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the experiment listing.')
      this.state = 'error'
    }
  }

  *updateOrCreate(stream) {
    try {
      if (stream.id) {
        yield api.experiments.update(stream)
      } else {
        yield api.experiments.create(stream)
      }
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
    this.fetchExperiments()
  }

  *delete(id) {
    try {
      yield api.experiments.delete(id)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchExperiments()
  }

  *start(id) {
    try {
      yield api.experiments.start(id)
      toast.success(t('Experiment started!'))
    } catch (error) {
      this.state = 'error'
      toast.error('Failed to start experiment.')
    }

    this.fetchExperiments()
    this.fetchExperimentRunning()
  }

  *stop(id) {
    try {
      yield api.experiments.stop(id)
    } catch (error) {
      this.state = 'error'
      toast.error('Failed to stop experiment.')
    }

    this.fetchExperiments()
    this.fetchExperimentRunning()
  }

  *fetchResults(id) {
    try {
      this.state = 'pending'

      const { data } = yield api.experiments.results(id)
      this.experimentResult = data

      this.state = 'done'
    } catch (error) {
      this.state = 'error'
      toast.error(t('Could not load experiment results.'))
    }
  }

  *fetchExperimentRunning() {
    try {
      const { data } = yield api.experiments.running()

      this.running = data
      this.state = 'done'
      return data
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong loading the experiment running.')
    }
  }
}

export default new ExperimentStore()
