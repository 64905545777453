import { useDraggable } from '@dnd-kit/core'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import { t } from '../../../utils'

export const DragableSection = ({ section, id, onRemove }) => {
  const newField = {
    uuid: section.uuid,
    type: 'section',
  }

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: section.uuid,
    data: {
      type: 'section',
      field: newField,
      sectionId: id,
    },
  })

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={classNames('component-editor__section', {
        'component-editor__element--dragging': isDragging,
      })}
    >
      <div className="component-editor__section-inner">
        <Icon width="12px" height="12px" symbol="folder" />
        <Tooltip title={t('Delete section')}>
          <div
            onClick={onRemove}
            className="component-editor__remove-saved-section"
          >
            <Icon width="12px" height="12px" symbol="trash" />
          </div>
        </Tooltip>

        <Text>{t(section.name || 'Saved section')}</Text>
      </div>
    </div>
  )
}
