import german from './locales/de'
import english from './locales/en'

export default {
  de: {
    ...german,
  },
  en: {
    ...english,
  },
}
