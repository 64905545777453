import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import AuthStore from './stores/AuthStore'
import UIStore from './stores/UIStore'
import { ActionLayerStore } from './stores'

import { AppRouter } from './routing'
import ActionLayer from './components/ActionLayer'
import LoadingScreen from './components/LoadingScreen'
import AccessError from './components/AccessError'

import getInstance from './utils/getInstance'
import updateInstance from './utils/updateInstance'
import checkValidInstance from './utils/checkValidInstance'

import './reset.styl'
import './fonts.css' // I don't know why this won't work with a stylus file ¯\_(ツ)_/¯
import './app.styl'
import 'antd/dist/antd.less'
import 'react-toastify/dist/ReactToastify.css'

import { ReactComponent as MAKAIRAIcons } from './assets/svgs/makaira-icons.svg'

// // TODO: Theme and style handling?
function App() {
  const history = useHistory()
  const search = history.location.search
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    async function validateUser() {
      // Force redirect to https on local dev environment to have working Auth0
      if (
        window.location.hostname === 'makaira.vm' &&
        location.protocol !== 'https:'
      ) {
        location.replace(
          `https:${location.href.substring(location.protocol.length)}`
        )
      }

      await AuthStore.initialize()

      const isAuthenticated = await AuthStore.isAuthenticated()

      if (!isAuthenticated) {
        sessionStorage.setItem('lastEntry', window.location.pathname)

        await AuthStore.login()
      } else {
        updateInstance()

        await UIStore.fetchInstances()
        UIStore.setInstance(getInstance())
        checkValidInstance(history)

        if (UIStore.instanceFetchStatusCode === 200) {
          await UIStore.initialize()
          await AuthStore.fetchRole()
          await AuthStore.fetchPermissions()
        }

        setLoading(false)
      }
    }

    validateUser()
  }, [history])

  useEffect(() => {
    const query = new URLSearchParams(search)

    if (query.get('popup') && !UIStore.isStorefrontPreview) {
      UIStore.setStorefrontPreview(true)
    }
  }, [search])

  if (AuthStore.role === 'missing') return <AccessError status={403} />

  if (AuthStore.state == 'error') return <h1>something went wrong...</h1>

  if (isLoading) return <LoadingScreen />

  if (UIStore.instanceFetchStatusCode !== 200)
    return <AccessError status={UIStore.instanceFetchStatusCode} />

  return (
    <>
      <MAKAIRAIcons />

      <ActionLayer />

      <AppRouter />

      <ToastContainer
        style={ActionLayerStore.open ? { top: 60 } : {}}
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
      />
    </>
  )
}

export default observer(App)
