import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useEffect, useState } from 'react'

import Button from '../Button'

import './animationButton.styl'

/**
 * Wrapper for the Button that displays an animation for the button.
 */
const AnimationButton = (props) => {
  const {
    animationDuration = 7000,
    animationVariant = 'primary',
    animationChildren,
    animationRunningOverride,
    variant,
    children,
    className,
    ...rest
  } = props

  const [isAnimationRunning, setIsAnimationRunning] = useState(true)
  const [activeVariant, setActiveVariant] = useState(animationVariant)

  useEffect(() => {
    if (animationDuration === -1 || animationRunningOverride !== undefined)
      return

    const timer = setTimeout(() => {
      setIsAnimationRunning(false)
      setActiveVariant(variant)
    }, animationDuration)

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (animationRunningOverride === undefined) return
    setIsAnimationRunning(animationRunningOverride)

    if (animationRunningOverride) {
      setActiveVariant(animationVariant)
    } else {
      setActiveVariant(variant)
    }
    // eslint-disable-next-line
  }, [animationRunningOverride])

  /**
   * Return the children components based on the current animation state.
   * When the property animationChildren is undefined the default children
   * will be rendered all the time.
   * If it is unequal to undefined during the animation the animationChildren will be
   * rendered as button children.
   *
   * @returns {JSX.Element|undefined}
   */
  const getChildren = () => {
    if (!isAnimationRunning) return children

    if (animationChildren === undefined) return children

    return animationChildren
  }

  return (
    <Button
      className={classnames(
        className,
        `animation-button--${animationVariant}`,
        {
          'animation-button--running': isAnimationRunning,
        }
      )}
      variant={activeVariant}
      {...rest}
    >
      {getChildren()}
    </Button>
  )
}

AnimationButton.propTypes = {
  /**
   * How long the animation state is display after the mount
   */
  animationDuration: PropTypes.number,
  /**
   * Variant of the animation.
   */
  animationVariant: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * Custom children of the button that are display during the animation
   */
  animationChildren: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Overrides internal state. When unequal to undefined will be used for running state
   */
  animationRunningOverride: PropTypes.bool,
  /**
   * Variant after the animation is finished
   */
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'reduced',
    'tertiary',
    'primary-blocker',
    'secondary-blocker',
  ]),
  /**
   * ClassNames that are always assigned to the button
   */
  className: PropTypes.string,
  /**
   * All other properties will be passed to button component
   */
  rest: PropTypes.any,
}

export default AnimationButton
