import { useEffect, useState } from 'react'
import get from 'lodash/get'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { intersection } from 'lodash'

import logoMakaira from '../../assets/images/logoMakaira.svg'
import Button from '../Button'
import Icon from '../Icon'
import Menu from '../Menu'
import dividerImg from '../../assets/images/navbar-divider.svg'
import dividerActiveImg from '../../assets/images/navbar-divider-active.svg'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { routes } from '../../routing'
import createRedirectPath from '../../utils/createRedirectPath'
import t from '../../utils/translate'
import AuthStore from '../../stores/AuthStore'
import UIStore from '../../stores/UIStore'
import { basePath } from '../../utils/constants'
import Text from '../Text'
import Avatar from '../Avatar'
import NotificationsPopup from './NotificationsPopup'
import NotificationStore from '../../stores/NotificationStore'
import EditorStore from '../../stores/PageEditor/EditorStore'
import PrivateElement from '../PrivateElement'
import { AppStore } from '../../stores'
import DashboardStore from '../../stores/DashboardStore'
import TextInput from '../TextInput'

import './pageHeader.styl'

const imgVariables = {
  '--divider-img': `url(${dividerImg})`,
  '--divider-active-img': `url(${dividerActiveImg})`,
}

function PageHeader(props) {
  const history = useHistory()
  const routeMatch = useRouteMatch()
  const { location } = props
  const { user } = AuthStore
  const { currentLanguage, currentInstance, instances } = UIStore
  const [openKeys, setOpenKeys] = useState([])
  const [search, setSearch] = useState('')
  const [isScrolled, setIsScrolled] = useState(false)
  const currentRoute =
    Object.values(routes).find(
      (route) => routeMatch.path === '/:instance' + route.path
    ) || {}

  const notifications = NotificationStore.unreadNotifications
  const urgentNotifications = NotificationStore.urgentNotifications
  const haveUrgentNotifications = urgentNotifications.length > 0

  useEffect(() => {
    NotificationStore.fetchUnreadNotifications()

    // Poll messages if tab in focus every 10 seconds
    const timer = setInterval(async () => {
      if (!document.hidden) {
        await NotificationStore.fetchUnreadNotifications()
        // directly show notification popover if have urgent notification
        if (NotificationStore.urgentNotifications.length > 0) {
          // re-fetch notifications if we currently in notifications list page
          if (currentRoute.path === '/notifications') {
            NotificationStore.fetchNotifications(true)
          }

          setOpenKeys(['Notification'])
          // Hide popover after 10 seconds
          setTimeout(() => setOpenKeys([]), 1000 * 8)
        }
      }
    }, 1000 * 10)

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
    // eslint-disable-next-line
  }, [])

  //handle sticky popover
  useEffect(() => {
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line
  }, [])

  const handleScroll = () => {
    setIsScrolled(document.documentElement.scrollTop > 50 && !isScrolled)
  }

  const setLanguage = (lang) => () => {
    UIStore.setLanguage(lang)
  }

  const handleLogout = () => {
    EditorStore.clearClipboard()
    AuthStore.logout()
  }

  const handleChangeInstance = (instance) => () => {
    const { pathname } = location

    let [, , page] = pathname.split('/')

    if (page === 'importer') {
      page = 'importer/config'
    }

    window.location.href = `${basePath}/${instance}/${page}`
  }

  const onChangeMenuPopup = (keys) => {
    setOpenKeys(keys)
  }

  const getNotificationIcon = () => {
    if (openKeys.includes('Notification')) {
      return 'times'
    }
    if (notifications.length > 0) {
      return 'bell__solid'
    }

    return 'bell'
  }

  const handleClickNavigation = (e) => {
    e.stopPropagation()
    if (currentRoute.path === '/app') {
      window.location.reload()
    } else {
      history.push(
        createRedirectPath(currentRoute.parentPath || currentRoute.path)
      )
    }
  }

  const getCurrentRouteTitle = () => {
    if (currentRoute.path === '/app') {
      return get(AppStore.appInfo, ['title', UIStore.currentLanguage])
    }

    return t(currentRoute.title)
  }

  const { SubMenu, GroupTitle, MenuItem } = Menu

  const isShowTools =
    [
      routes.indexOverview.permission,
      routes.storefrontSettings.permission,
      routes.importerSchedule.permission,
      routes.auditList.permission,
      routes.dataInspector.permission,
      routes.userList.permission,
    ].some(
      (permission) =>
        intersection(permission, AuthStore.permissions).length !== 0
    ) || AuthStore.isSuperAdmin

  const isOnDashboard = /^\/[a-z]+\/dashboard$/.test(location.pathname)
  const filteredInstances = instances.filter(
    (instance) =>
      !search ||
      (search && instance.toLowerCase().includes(search.trim().toLowerCase()))
  )

  return (
    <div style={imgVariables} className="page-header">
      <div className="page-header__logo">
        <img src={`${logoMakaira}`} alt="Makaira Logo" />
      </div>

      <Menu
        className="page-header__menu"
        mode="horizontal"
        selectable={false}
        triggerSubMenuAction="click"
        onOpenChange={onChangeMenuPopup}
        openKeys={openKeys}
      >
        <SubMenu
          popupOffset={[0, 0]}
          title={<Avatar email={user.email} icon="angle-down" />}
          className="page-header__menu-item page-header__settings"
          key="User"
          popupClassName="page-header__popup"
        >
          <GroupTitle>
            <Text size="charlie" weight="bold">
              {user.name}
            </Text>
          </GroupTitle>
          <MenuItem onClick={handleLogout} key="User/Logout">
            <div className="page-header__logout">
              <Icon className="page-header__checked-icon" symbol="power-off" />{' '}
              {t('Logout')}
            </div>
          </MenuItem>
          <GroupTitle>{t('Language')}</GroupTitle>
          <MenuItem onClick={setLanguage('de')} key="User/Language/German">
            <div className="page-header__language">
              {t('German')}{' '}
              {currentLanguage === 'de' && (
                <Icon
                  className="page-header__checked-icon"
                  symbol="check__solid"
                />
              )}
            </div>
          </MenuItem>
          <MenuItem onClick={setLanguage('en')} key="User/Language/English">
            <div className="page-header__language">
              {t('English')}{' '}
              {currentLanguage === 'en' && (
                <Icon
                  className="page-header__checked-icon"
                  symbol="check__solid"
                />
              )}
            </div>
          </MenuItem>
        </SubMenu>
        <SubMenu
          className={classNames(
            'page-header__menu-item page-header__notification',
            {
              'page-header__notification--open':
                openKeys.includes('Notification'),
            }
          )}
          popupOffset={[-20, 0]}
          title={
            <Button
              icon={getNotificationIcon()}
              variant={
                notifications.length > 0 ? 'secondary-blocker' : 'reduced'
              }
            />
          }
          key="Notification"
          popupClassName={classNames('page-header__popup', {
            'page-header__popup--sticky': haveUrgentNotifications,
            'page-header__popup--scrolled':
              haveUrgentNotifications && isScrolled,
          })}
        >
          <NotificationsPopup
            notifications={notifications}
            urgentNotifications={urgentNotifications}
            onChangeMenuPopup={onChangeMenuPopup}
          />
        </SubMenu>
        {isShowTools && (
          <SubMenu
            popupOffset={[-20, 0]}
            title={<Button icon="tools" variant="reduced" />}
            className="page-header__menu-item page-header__settings"
            key="Settings"
            popupClassName="page-header__popup"
          >
            <GroupTitle>Tools</GroupTitle>
            <MenuItem key="index-overview">
              <PrivateElement permission={routes.indexOverview.permission}>
                <Link to={createRedirectPath(routes.indexOverview.path)}>
                  {t('Index Overview')}
                </Link>
              </PrivateElement>
            </MenuItem>
            {(intersection(
              routes.storefrontSettings.permission,
              AuthStore.permissions
            ) !== 0 ||
              AuthStore.isSuperAdmin) && (
              <SubMenu
                key="Storefront"
                title="Storefront"
                popupClassName="page-header__popup page-header__popup--level-2"
              >
                <MenuItem key="Storefront-settings">
                  <Link to={createRedirectPath(routes.storefrontSettings.path)}>
                    {t('Settings')}
                  </Link>
                </MenuItem>
                <MenuItem key="Storefront-logs">
                  <Link to={createRedirectPath(routes.storefrontLogs.path)}>
                    {t('Logs')}
                  </Link>
                </MenuItem>
              </SubMenu>
            )}
            {(intersection(
              routes.importerSchedule.permission,
              AuthStore.permissions
            ) !== 0 ||
              AuthStore.isSuperAdmin) && (
              <SubMenu
                key="Importer"
                title="Importer"
                popupClassName="page-header__popup page-header__popup--level-2"
              >
                <MenuItem key="Schedule">
                  <Link to={createRedirectPath(routes.importerSchedule.path)}>
                    {t('Configuration')}
                  </Link>
                </MenuItem>
                <MenuItem key="Logs">
                  <Link to={createRedirectPath(routes.importerLogs.path)}>
                    {t('Logs')}
                  </Link>
                </MenuItem>
              </SubMenu>
            )}
            <MenuItem key={`Audit Logs`}>
              <PrivateElement permission={routes.auditList.permission}>
                <Link to={createRedirectPath(routes.auditList.path)}>
                  {t('Audit Logs')}
                </Link>
              </PrivateElement>
            </MenuItem>
            <MenuItem key={`Data Inspector`}>
              <PrivateElement permission={routes.dataInspector.permission}>
                <Link to={createRedirectPath(routes.dataInspector.path)}>
                  {t('Data Inspector')}
                </Link>
              </PrivateElement>
            </MenuItem>
            <MenuItem key={`General Settings`}>
              <PrivateElement permission={routes.settings.permission}>
                <Link to={createRedirectPath(routes.settings.path)}>
                  {t('Settings')}
                </Link>
              </PrivateElement>
            </MenuItem>
            <MenuItem key={`User Management`}>
              <PrivateElement permission={routes.userList.permission}>
                <Link to={createRedirectPath(routes.userList.path)}>
                  {t('User')}
                </Link>
              </PrivateElement>
            </MenuItem>
          </SubMenu>
        )}
        <SubMenu
          key="Instance"
          popupClassName="page-header__popup"
          popupOffset={[0, -5]}
          title={
            <div className="page-header__instances">
              {currentInstance} <Icon symbol="angle-down" />
            </div>
          }
          className="page-header__menu-item page-header__menu-item--nav page-header__menu-item--instances"
        >
          <GroupTitle>{t('Instances')}</GroupTitle>
          {instances.length > 10 && (
            <TextInput
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={'Search instance'}
            />
          )}
          {filteredInstances.map((instance) => (
            <MenuItem
              onClick={handleChangeInstance(instance)}
              key={`Instance/${instance}`}
            >
              {instance}
            </MenuItem>
          ))}
          {filteredInstances.length === 0 && search && t('No results')}
        </SubMenu>
        <MenuItem
          className="page-header__menu-item page-header__menu-item--nav"
          key="Home"
          onClick={() =>
            history.push(createRedirectPath(routes.dashboard.path))
          }
        >
          <Link to={createRedirectPath(routes.dashboard.path)}>
            <Icon symbol="home" />
          </Link>
        </MenuItem>
        <SubMenu
          key="Path"
          popupClassName="page-header__popup"
          popupOffset={[currentRoute.title.length * 5, 5]}
          title={
            <div className="page-header__instances">
              <span onClick={handleClickNavigation}>
                {getCurrentRouteTitle()}
              </span>
              {currentRoute.path !== '/dashboard' && (
                <Icon symbol="angle-down" />
              )}
            </div>
          }
          className="page-header__menu-item page-header__menu-item--nav"
        >
          <MenuItem key={`Path/Page Editor`}>
            <PrivateElement permission={routes.pageEditor.permission}>
              <Link to={createRedirectPath(routes.pageEditor.path)}>
                {t('Page Editor')}
              </Link>
            </PrivateElement>
          </MenuItem>
          <MenuItem key={`Path/Menu Editor`}>
            <PrivateElement permission={routes.menuEditor.permission}>
              <Link to={createRedirectPath(routes.menuEditor.path)}>
                {t('Menu Editor')}
              </Link>
            </PrivateElement>
          </MenuItem>
          <MenuItem key={`Path/Smart Bundles`}>
            <PrivateElement permission={routes.bundleList.permission}>
              <Link to={createRedirectPath(routes.bundleList.path)}>
                {t('Smart Bundles')}
              </Link>
            </PrivateElement>
          </MenuItem>
          <MenuItem key={`Path/Index & Importer`}>
            <PrivateElement permission={routes.indexOverview.permission}>
              <Link to={createRedirectPath(routes.indexOverview.path)}>
                {t('Index & Importer')}
              </Link>
            </PrivateElement>
          </MenuItem>
          <MenuItem key={`Path/Setup Information`}>
            <Link to={createRedirectPath(routes.setupInformation.path)}>
              {t('Setup Information')}
            </Link>
          </MenuItem>
          {AppStore.externalApps.map((app) => (
            <MenuItem key={app.slug}>
              <Link to={createRedirectPath(`/app/${app.slug}`)}>
                {get(app, ['title', UIStore.currentLanguage], '')}
              </Link>
            </MenuItem>
          ))}
        </SubMenu>
        {currentRoute.subTitle && (
          <MenuItem
            className="page-header__menu-item page-header__menu-item--nav"
            key="subPath"
            onClick={() => history.push(createRedirectPath(currentRoute.path))}
          >
            <Link to={createRedirectPath(currentRoute.path)}>
              {currentRoute.subTitle}
            </Link>
          </MenuItem>
        )}
        {(!DashboardStore.isOnboardingSectionShown || !isOnDashboard) && (
          <SubMenu
            popupOffset={[-10, 0]}
            title={<Button icon="book" variant="reduced" />}
            className="page-header__menu-item page-header__documentation"
            key="Documentation"
            popupClassName="page-header__popup page-header__popup--right"
          >
            <MenuItem key="Documentation/Makaira-documentation">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.makaira.io/"
              >
                {t('Documentation')}
              </a>
            </MenuItem>
            <MenuItem key="Documentation/API-documentation">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.makaira.io/reference"
              >
                {t('API Documentation')}
              </a>
            </MenuItem>
            <MenuItem key="Documentation/SetupInformation">
              <Link to={createRedirectPath(routes.setupInformation.path)}>
                {t('Setup Information')}
              </Link>
            </MenuItem>
          </SubMenu>
        )}
      </Menu>
      {DashboardStore.isOnboardingSectionShown && isOnDashboard && (
        <Button
          className="page-header__documentation"
          icon="times"
          variant="reduced"
          onClick={DashboardStore.closeOnboardingSection}
        />
      )}
    </div>
  )
}

export default observer(PageHeader)
