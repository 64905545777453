import { observer } from 'mobx-react-lite'

import Text from '../../components/Text'
import Radio from '../../components/Radio'
import Button from '../../components/Button'
import t from '../../utils/translate'
import TrialDescription from './TrialDescription'

import PersonalizationStore from '../../stores/PersonalizationStore'

const TRAFFIC_PERCENT = ['10', '25', '40', '50', '60', '75', '90']

const TrialSection = () => {
  if (
    PersonalizationStore.status?.allPassed?.result === false ||
    PersonalizationStore.featureInfo.state !== 'not-booked' ||
    PersonalizationStore.isRunningExperiment
  )
    return null

  return (
    <div className="personalization__trial-section">
      <TrialDescription />
      <div className="arrow-right" />
      <div className="personalization__start-trial-section">
        <Text
          className="personalization__start-trial-section-title"
          element="div"
          weight="heavy"
          size="echo"
        >
          {t("Okay, let's go!")}
        </Text>
        <Text element="div" weight="bold" size="delta">
          {t('How much traffic should be sent to the Makaira Personalization?')}
        </Text>
        <div className="personalization__traffic">
          <div className="personalization__traffic-radio">
            <Radio.Group
              onChange={(value) =>
                PersonalizationStore.setTrafficPercent(value)
              }
              value={PersonalizationStore.trafficPercent}
            >
              {TRAFFIC_PERCENT.map((percent) => (
                <Radio
                  key={percent}
                  size="large"
                  label={<Text>{percent}%</Text>}
                  direction="vertical"
                  value={percent}
                />
              ))}
            </Radio.Group>
          </div>
          <Text size="alpha">{t('recommended')}</Text>
        </div>
        <Button
          onClick={PersonalizationStore.startTrialPeriod}
          loading={PersonalizationStore.isLoadingTrial}
          variant="green"
          icon="chevron-right"
        >
          {t('Start A/B test and trial')}
        </Button>
      </div>
    </div>
  )
}

export default observer(TrialSection)
