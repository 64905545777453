import { useEffect, useMemo } from 'react'
import moment from 'moment-timezone'
import truncate from 'lodash/truncate'
import get from 'lodash/get'
import ReactMarkdown from 'react-markdown'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'

import UIStore from '../../stores/UIStore'
import NotificationStore from '../../stores/NotificationStore'
import createRedirectPath from '../../utils/createRedirectPath'
import t from '../../utils/translate'
import Text from '../Text'
import Icon from '../Icon'

import './notificationsPopup.styl'
import ShadowScroll from '../ShadowScroll'

const NotificationsPopup = ({
  onChangeMenuPopup,
  notifications = [],
  urgentNotifications = [],
}) => {
  const haveUrgentNotifications = urgentNotifications.length > 0

  const notificationsList = useMemo(() => {
    // urgentNotifications is the highest priority
    if (urgentNotifications.length > 0) {
      return urgentNotifications
    }

    return notifications
  }, [urgentNotifications, notifications])

  const currentLanguage = UIStore.currentLanguage

  const history = useHistory()

  const handleDate = ({ date, timezone }) => {
    if (!date) return null

    moment.locale(currentLanguage)

    let formatted = moment.utc(date).local().fromNow()

    if (timezone !== 'UTC') {
      formatted = moment.tz(date, timezone).local().fromNow()
    }

    return formatted
  }

  const handleGoToList = () => {
    onChangeMenuPopup([])
    history.push(createRedirectPath('/notifications'))
    window.scrollTo(0, 0)
  }

  const markReadAllNotifications = async () => {
    await NotificationStore.markReadAllNotifications()
    NotificationStore.fetchUnreadNotifications()
    NotificationStore.fetchNotifications()
  }

  //limit messages to 200 characters
  useEffect(() => {
    const messages = document.querySelectorAll('.notification__item-message')
    messages.forEach(
      (message) =>
        (message.innerText = truncate(message.innerText, {
          length: 200,
          separator: ' ',
        }))
    )
  }, [notificationsList])

  return (
    <div className="notification">
      <div className="notification__header">
        <Text className="notification__header-title" size="delta" weight="bold">
          {t('Notifications')}
        </Text>
        {notifications.length > 0 && (
          <Text variant="book" size="charlie">{`${notifications.length} ${t(
            'unread'
          )}`}</Text>
        )}
        <span
          className="notification__header-close"
          onClick={() => onChangeMenuPopup([])}
        >
          <Icon symbol="times" />
        </span>
      </div>
      <ShadowScroll className="notification__body">
        {notificationsList.length === 0 && (
          <div className="notification__no-data">
            <Text>{t('No unread messages.')}</Text>
          </div>
        )}
        {notificationsList.length > 0 &&
          notificationsList.map((noti) => (
            <div
              onClick={handleGoToList}
              key={noti.id}
              className={`notification__item notification__item--${noti.level}`}
            >
              <Text
                className="notification__item-title"
                weight="bold"
                size="charlie"
              >
                {`${noti.source} · ${truncate(
                  get(noti, `title.${currentLanguage}`, ''),
                  {
                    length: 200,
                    separator: ' ',
                  }
                )}`}
              </Text>
              <ReactMarkdown
                className="notification__item-message text--charlie"
                allowedElements={[]}
                unwrapDisallowed
                key={currentLanguage}
              >
                {get(noti, `message.${currentLanguage}`, '')}
              </ReactMarkdown>
              <Text className="notification__item-date" size="alpha">
                {handleDate(noti.timestamp)}
              </Text>

              <Icon
                className="notification__item-icon"
                symbol="chevron-right"
              />
            </div>
          ))}
      </ShadowScroll>
      <div className="notification__footer">
        {!haveUrgentNotifications && notificationsList.length > 0 && (
          <div
            onClick={markReadAllNotifications}
            className="notification__footer-mark-all"
          >
            <Icon symbol="check" />
            <Text size="bravo"> {t('Mark all as read')}</Text>
          </div>
        )}
        <div onClick={handleGoToList} className="notification__footer-read-all">
          <Text size="charlie"> {t('All notifications')}</Text>
          <Icon symbol="chevron-right" />
        </div>
      </div>
    </div>
  )
}

export default observer(NotificationsPopup)
