import { observer } from 'mobx-react-lite'
import React from 'react'

import Select from '../../../../components/Select'
import Switch from '../../../../components/Switch'
import Text from '../../../../components/Text'
import Textarea from '../../../../components/Textarea'
import { FeedStore } from '../../../../stores'
import { t } from '../../../../utils'
import {
  LINE_END_OPTIONS,
  SEPARATOR_OPTIONS,
  WRAPPER_OPTIONS,
} from './constants'
import Preview from './Preview'

const FeedFormat = () => {
  const feed = FeedStore.feedDetail

  return (
    <div className="feed-setting__file-format-wrapper">
      <div className="feed-setting__file-format">
        <Select
          title={t('File Format')}
          options={['xml', 'json', 'csv', 'none'].map((type) => ({
            value: type,
            label: type.toUpperCase(),
          }))}
          value={feed.contentType}
          onChange={(value) => FeedStore.updateFeedData('contentType', value)}
        />
        {['xml', 'json', 'none'].includes(feed.contentType) && (
          <>
            <Textarea
              title={t('Header')}
              rows="auto"
              value={feed.header}
              onChange={(value) => FeedStore.updateFeedData('header', value)}
            />
            <Textarea
              rows="auto"
              title={t('Footer')}
              value={feed.footer}
              onChange={(value) => FeedStore.updateFeedData('footer', value)}
            />
          </>
        )}
        {feed.contentType === 'csv' && (
          <>
            <Text className="feed-setting__switch-title">{t('Header')}</Text>
            <Switch
              type="horizontal"
              title={t('include header')}
              checked={feed.renderHeader}
              onChange={(value) =>
                FeedStore.updateFeedData('renderHeader', value)
              }
            />
            <Select
              title={t('Separator')}
              options={SEPARATOR_OPTIONS}
              value={feed.separator}
              onChange={(value) => FeedStore.updateFeedData('separator', value)}
            />
            <Select
              title={t('Wrapper')}
              options={WRAPPER_OPTIONS}
              value={feed.wrapping}
              onChange={(value) => FeedStore.updateFeedData('wrapping', value)}
            />
            <Select
              title={t('Line-End')}
              options={LINE_END_OPTIONS}
              value={feed.lineSeparator}
              onChange={(value) =>
                FeedStore.updateFeedData('lineSeparator', value)
              }
            />
          </>
        )}
        <Textarea
          rows="auto"
          title={t('Optional Variables')}
          value={feed.content}
          onChange={(value) => FeedStore.updateFeedData('content', value)}
        />
      </div>
      <Preview feed={feed} />
    </div>
  )
}

export default observer(FeedFormat)
