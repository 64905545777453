import isString from 'lodash/isString'
import UIStore from '../../stores/UIStore'

/**
 * Appends the given path to the clientBaseurl.
 * The clientBaseurl is the URL where the shop of the instance is hosted.
 *
 * @param path
 * @returns {string}
 */
const buildClientURL = (path = '') => {
  let baseURL = UIStore?.enterpriseConfiguration?.clientBaseurl

  if (isString(baseURL) && baseURL.endsWith('/')) {
    baseURL = baseURL.substring(0, baseURL.length - 1)
  }

  if (isString(path) && path.startsWith('/')) {
    path = path.substring(1)
  }

  return baseURL + '/' + path
}

export default buildClientURL
