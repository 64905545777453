import { useDndContext, useDroppable } from '@dnd-kit/core'
import classNames from 'classnames'
import { sortBy } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import Text from '../../../components/Text'
import { ComponentStore } from '../../../stores'
import { t } from '../../../utils'
import { CONDITIONAL_SECTION_TYPE } from '../constants'
import SortableElementBuilder from './SortableElementBuilder'

const ObjectAndArrayElement = ({ field, level, isDraggingParent }) => {
  const dndContext = useDndContext()
  const properties = sortBy(
    toJS(ComponentStore.flattenFields.filter((f) => f.parentId === field.uuid)),
    'position'
  )
  const { setNodeRef, isOver } = useDroppable({
    id: `dropable--${field.uuid}--${properties.length}`,
    disabled:
      isDraggingParent ||
      dndContext.active?.data?.current?.type === 'section' ||
      dndContext.active?.data?.current?.type === 'form-section',
  })

  return (
    <div
      ref={setNodeRef}
      className="component-editor__element-builder-dropzone"
    >
      {field.type === CONDITIONAL_SECTION_TYPE.value && (
        <div className="component-editor__line"></div>
      )}
      {properties?.length > 0 && (
        <>
          {properties.map((childField, index) => (
            <SortableElementBuilder
              level={level + 1}
              key={childField.uuid}
              field={childField}
              lastItem={index === properties.length - 1}
              isDraggingParent={isDraggingParent}
            />
          ))}
          <div
            className={classNames('component-editor__placeholder-element', {
              'component-editor__placeholder-element--is-over': isOver,
            })}
          />
        </>
      )}
      {!properties?.length && (
        <Text
          element="div"
          className={classNames(
            'component-editor__element-builder-dropzone-placeholder',
            {
              'component-editor__element-builder-dropzone-placeholder--draging-over':
                isOver,
            }
          )}
        >
          {t(
            field.type === 'hotspot'
              ? 'Place any element to configure additional hot spot properties'
              : 'Place any element from the library on the left in this area ...'
          )}
        </Text>
      )}
    </div>
  )
}

export default observer(ObjectAndArrayElement)
