import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import { delay } from '../../utils'
import { validate } from './validate'

class SearchableLinkStore {
  searchableLinks = []
  detail = {}
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  isFetchingDetail = false
  filter = {}
  isDirty = false
  state = 'pending' // "pending", "done" or "error"
  errors = {}

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchSearchableLinks()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchSearchableLinks()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchSearchableLinks()
  }

  getById(id) {
    return this.searchableLinks.find((s) => s.id == id)
  }

  *fetchSearchableLinks() {
    this.state = 'pending'
    try {
      const { data, total } = yield api.searchableLinks.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.searchableLinks = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the searchable link listing.')
      this.state = 'error'
    }
  }

  *fetchDetail(id) {
    this.errors = {}
    this.detail = {}
    this.isDirty = false
    this.isFetchingDetail = true
    if (id === 'new') {
      yield delay(300)
      this.filterDetail = {}
      this.isFetchingDetail = false
      this.state = 'done'
      return
    }
    try {
      const { data } = yield api.searchableLinks.get(id)

      this.detail = data
      this.state = 'done'
      this.isFetchingDetail = false
      return data
    } catch (error) {
      toast.error('Something went wrong loading the searchable link.')
      this.state = 'error'
    }
  }

  *updateOrCreate(stream) {
    try {
      if (stream.id) {
        yield api.searchableLinks.update(stream)
        toast.success('Updated successfully!')
        this.isDirty = false
      } else {
        const { data } = yield api.searchableLinks.create(stream)
        this.isDirty = false
        toast.success('Created successfully!')
        return data.id
      }
    } catch (error) {
      console.error(error)
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *delete(id) {
    try {
      yield api.searchableLinks.delete(id)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchSearchableLinks()
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  onChangeData(data, key, value) {
    data[key] = value
    this.isDirty = true
  }

  setErrors(errors) {
    this.errors = errors
  }

  onValidateForm() {
    try {
      const validData = validate.validateSync(
        JSON.parse(JSON.stringify(this.detail)),
        { abortEarly: false }
      )
      this.setErrors({})
      return validData
    } catch (err) {
      if (err.errors && Array.isArray(err.errors) && err.errors.length > 0) {
        const errors = {}
        for (const innerError of err.inner) {
          errors[innerError.path] = innerError.message
        }
        this.setErrors(errors)
      }
      return false
    }
  }
}

export default new SearchableLinkStore()
