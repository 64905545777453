import { useState, Fragment, useEffect } from 'react'
import { Popover } from 'antd'
import { toast } from 'react-toastify'
import UIStore from '../../stores/UIStore'
import openGallery from '../../utils/openGallery'
import uploadFileToCloudinary from '../../utils/uploadFileToCloudinary'
import api from '../../api'
import Preview from './FilePreview'
import './fileUpload.styl'
import Icon from '../Icon'
import classNames from 'classnames'
import FILE_TYPES from './fileType'
import Text from '../Text'
import Spinner from '../Spinner'
import isObject from 'lodash/isObject'

export default function FileUpload(props) {
  const { enterpriseConfiguration } = UIStore
  const { storageType } = enterpriseConfiguration

  const [isLoading, setLoading] = useState(false)
  const [isGalleryLoading, setGalleryLoading] = useState(false)
  const {
    title,
    value,
    level = -1,
    showDetail = true,
    size = 'normal', //normal or small or large
    onRemove = () => {},
    description = '',
  } = props

  useEffect(() => {
    // handle for pasting from Cloudinary to S3/different Cloudinary config
    if (isObject(value) && !value.fileName) {
      props.onChange('')
    }
    // eslint-disable-next-line
  }, [value])

  async function handleUpload(event) {
    setLoading(true)

    const { files } = event.target

    if (props.onChangeForPali) {
      await props.onChangeForPali(files)
      setLoading(false)
      return
    }

    try {
      if (storageType === 's3') {
        const {
          data: { key },
        } = await api.common.uploadFileToS3(files[0])

        props.onChange(key)
      } else {
        const data = await uploadFileToCloudinary(files[0])

        props.onChange(JSON.parse(data))
      }
    } catch (error) {
      toast.error('Could not upload image.')
    }

    setLoading(false)
  }

  function handleGallery() {
    setGalleryLoading(true)
    openGallery((data) => props.onChange(JSON.parse(data)), setGalleryLoading)
  }

  const onChangeUpload = (e) => {
    // when remove current file upload for better UX
    onRemove()
    handleUpload(e)
  }

  // TODO: Make the input focusable for better accessibility
  const uploadFile = props.onChange && (
    <label
      className={classNames('file-upload__label', {
        'file-upload__label--center': storageType !== 'cloudinary',
      })}
    >
      <input type="file" onChange={onChangeUpload} />

      {isLoading ? <Spinner size="small" /> : <Icon symbol="cloud-arrow-up" />}
    </label>
  )

  // TODO: Make the input focusable for better accessibility
  const uploadCloudinary = props.onChange && storageType === 'cloudinary' && (
    <label className="file-upload__gallery">
      <input type="button" onClick={handleGallery} />

      {isGalleryLoading ? <Spinner size="small" /> : <Icon symbol="images" />}
    </label>
  )

  const getFileName = (name) => {
    let fileName = name || ''
    if (isObject(fileName)) {
      fileName = name.fileName || ''
    }

    return fileName
  }

  const checkFileType = (name) => {
    const fileName = getFileName(name)
    if (!fileName) return null
    const ext = fileName.split('.').pop().toLowerCase()

    return Object.keys(FILE_TYPES).find((type) =>
      FILE_TYPES[type].includes(ext)
    )
  }

  const fileType = checkFileType(value)

  const PreviewWrapper = showDetail ? 'div' : Fragment
  const previewWrapperProps = {}
  if (showDetail) {
    previewWrapperProps.className = 'file-upload__preview-wrapper'
  }

  return (
    <div
      className={classNames(
        `file-upload file-upload--custom file-upload--level-${level} file-upload--${fileType} file-upload--${size}`,
        { 'file-upload--loading': isLoading }
      )}
    >
      {title}

      {getFileName(value) ? (
        <PreviewWrapper {...previewWrapperProps}>
          <Preview
            pali={!!props.onChangeForPali}
            src={value}
            onRemove={onRemove}
            uploadCloudinary={uploadCloudinary}
            uploadFile={uploadFile}
            fileType={fileType}
            showDetail={showDetail}
            size={size}
            storageConfiguration={enterpriseConfiguration}
          />
        </PreviewWrapper>
      ) : (
        <div className="file-upload__container">
          {size === 'small' && uploadCloudinary ? (
            <Popover
              overlayClassName="file-upload__popover"
              content={
                <div className="file-preview__actions">
                  <div className="file-preview__actions-row">
                    {uploadFile}
                    {uploadCloudinary}
                  </div>
                </div>
              }
            >
              <label
                className={classNames('file-upload__label', {
                  'file-upload__label--center': storageType !== 'cloudinary',
                })}
              >
                <Icon symbol="cloud-arrow-up" />
              </label>
            </Popover>
          ) : (
            <>
              <div className="file-upload__actions">
                {uploadCloudinary}
                {uploadFile}
              </div>
              {!isGalleryLoading && description && (
                <Text variant="book">{description}</Text>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}
