import { observer } from 'mobx-react-lite'
import Select from '../../components/Select'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import { BannerStore, UIStore } from '../../stores'
import Images from './Images'
import { t } from '../../utils'
import { useState } from 'react'

function ContentSection() {
  const { datatypes, updatingBanner, searchDocuments, onChangeBanner } =
    BannerStore
  const { shopId } = UIStore
  const [linkType, setLinkType] = useState('link')

  const [searchOptions, setSearchOptions] = useState([])

  const fetchSearchData = async (value, newLinkType) => {
    const searchResults = await searchDocuments(
      newLinkType,
      value,
      updatingBanner.language,
      shopId,
      ['url']
    )
    const mapped = searchResults.map((item) => {
      return {
        value: item.url,
        label: `${
          item.title || item.category_title || item.manufacturer_title
        } (${item.url})`,
      }
    })
    setSearchOptions(mapped)
    return mapped
  }

  const onLinkTypeChange = (value) => {
    setSearchOptions([])
    setLinkType(value)
    onChangeBanner('link', null)
  }

  return (
    <>
      <div className="devider" />
      <div className="banner-edit__section content-section">
        <Text size="echo" weight="bold">
          {t('Content')}
        </Text>
        <div className="section__row section__row--link row--no-gap">
          <Select
            className="banner-edit__link-select"
            value={linkType}
            title={t('Link')}
            options={datatypes}
            onChange={onLinkTypeChange}
            flexible
            dropdownMatchSelectWidth={false}
          />

          {linkType === 'link' ? (
            <TextInput
              className="text-input--link"
              label="&nbsp;"
              value={updatingBanner.link}
              onChange={(e) => onChangeBanner('link', e.target.value)}
            />
          ) : (
            <Select
              className="banner-edit__select-search"
              title="&nbsp;"
              showSearch
              value={updatingBanner.link}
              onChange={(value) => onChangeBanner('link', value)}
              onSearch={(value) => fetchSearchData(value, linkType)}
              options={searchOptions}
              filterOption={false}
              allowClear={updatingBanner?.length > 0}
              placeholder={t('Search for ...')}
              fullWidth
            />
          )}
        </div>
        <TextInput
          className="text-input--title"
          label={t('Title')}
          value={updatingBanner.title}
          onChange={(e) => onChangeBanner('title', e.target.value)}
        />
        <TextInput
          label={t('Text')}
          value={updatingBanner.description}
          onChange={(e) => onChangeBanner('description', e.target.value)}
        />
        <Images />
      </div>
    </>
  )
}

export default observer(ContentSection)
