import { useEffect, useState } from 'react'
import Select from '../../../components/Select'
import { t } from '../../../utils'

export default function Operator(props) {
  const { formikProps, className, index } = props
  const { setFieldValue, values } = formikProps
  const connection = values.connections[index]
  const [value, setValue] = useState(connection.comparison)

  useEffect(() => {
    setValue(connection.comparison)
  }, [connection])

  const options = [
    { value: 'eq', label: t('equals') },
    { value: 'inList', label: t('in list') },
  ]

  function handleChange(value) {
    setValue(value)
    setFieldValue(`connections.${index}.comparison`, value)
  }

  return (
    <Select
      value={value}
      className={className}
      options={options}
      onChange={handleChange}
    />
  )
}
