import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import HeadingMain from '../../../../components/Headings/HeadingMain'
import Text from '../../../../components/Text'
import { ComponentStore } from '../../../../stores'
import { t } from '../../../../utils'

import SectionSettings from './SectionSettings'
import FieldSettings from './FieldSettings'
import ConditionalSectionSettings from './ConditionalSectionSettings'

import './fieldConfiguration.styl'

function FieldConfiguration() {
  const settings = ComponentStore.activeConfiguration
  const { type } = settings || {}

  return (
    <section className="field-configuration">
      <HeadingMain>{t('Configuration')}</HeadingMain>
      <div
        className={classNames('field-configuration__container', {
          'field-configuration__container--active': settings !== undefined,
        })}
      >
        {!settings && (
          <Text className="field-configuration--note">
            {t(
              'Select an element or section in the middle to configure it here'
            )}
          </Text>
        )}
        {settings && type !== 'section' && type !== 'conditional-section' && (
          <FieldSettings settings={settings} />
        )}
        {settings && type === 'section' && (
          <SectionSettings settings={settings} />
        )}
        {settings && type === 'conditional-section' && (
          <ConditionalSectionSettings settings={settings} />
        )}
      </div>
      {settings && type === 'section' && (
        <div
          className={classNames(
            'field-configuration__container',
            'field-configuration__container--save-section'
          )}
        >
          <SectionSettings isSave settings={settings} />
        </div>
      )}
    </section>
  )
}

export default observer(FieldConfiguration)
