import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useMemo, useCallback, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import classNames from 'classnames'
import { Tooltip } from 'antd'

import Checkbox from '../../components/Checkbox'
import ContentWrapper from '../../components/ContentWrapper'
import FormikOnChange from '../../components/FormikOnChange'
import LoadingScreen from '../../components/LoadingScreen'
import PageTitle from '../../components/PageTitle'
import TextInput from '../../components/TextInput'
import Textarea from '../../components/Textarea'
import Collapse, { Panel } from '../../components/Collapse'
import MatchingText from './components/MatchingText'
import { FeaturerPermission } from './components/FeaturerPermission'
import Icon from '../../components/Icon'

import {
  ActionLayerStore,
  RoleManagementStore,
  AppStore,
  UIStore,
} from '../../stores'
import { createRedirectPath, t } from '../../utils'
import { baseFeatures } from './constant'

import './style.styl'

const EditRole = () => {
  const formikRef = useRef()
  const { id } = useParams()
  const history = useHistory()
  const [searchPermission, setSearchPermission] = useState()
  const {
    isDirty,
    roleDetail: detail,
    permissions: availablePermissions,
    reset,
    setRoleDetail,
    state,
    fetchRoleDetail,
    rolePrefix,
    getRoleName,
    getRoleNameWithPrefix,
    addPermission,
    removePermission,
    setDirty,
  } = RoleManagementStore

  const isBaseRole = detail?.name?.toLowerCase().startsWith('makaira')
  const { externalApps, fetchExternalApps } = AppStore
  const { currentLanguage } = UIStore
  const schema = Yup.object().shape({
    name: Yup.string().test({
      test: (value, ctx) => {
        const name = value?.toLowerCase().replace(`${rolePrefix}.`, '')
        if (!name) {
          return ctx.createError({ message: t('Name is required!') })
        }

        return true
      },
    }),
  })

  useEffect(() => {
    fetchRoleDetail(id)
    fetchExternalApps()

    return () => {
      reset()
    }
    // eslint-disable-next-line
  }, [id])

  function handleClose() {
    reset()
    history.push(createRedirectPath('/role'))
  }

  async function handleSave(stay = true) {
    await formikRef.current?.submitForm()

    if (formikRef.current !== undefined && !formikRef.current?.isValid) {
      return false
    }

    const newId = await RoleManagementStore.updateOrCreate()

    // We need to redirect to the correct URL if the page was created
    if (newId) {
      if (stay) {
        history.replace(createRedirectPath(`/role/${newId}`))
      } else {
        history.push(createRedirectPath('/role'))
      }
    }

    setDirty(false)
  }

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        saveTitle: t('Save & back to list'),
        closeTitle: t('Abort & back to list'),
        onSaveWithContinue: handleSave,
        onSave: () => handleSave(false),
        onClose: handleClose,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty, handleSave, detail])

  const isPermissionExist = useCallback(
    (permission = '') => {
      return detail?.permissions.some((p) => p === permission)
    },
    [detail?.permissions]
  )

  const addOrRemovePermission = (feature, permissionName, isAdd) => {
    const permission = `${feature.key}.${permissionName}`
    if (isAdd) {
      addPermission(permission)
    } else {
      removePermission(permission)
    }
  }

  const handleSubmit = (values) => setRoleDetail({ ...detail, ...values })

  const featuresPermissionBySection = useMemo(() => {
    const apps = isBaseRole
      ? [
          {
            name: 'Apps',
            key: 'app',
            section: 'Apps',
          },
        ]
      : (externalApps || []).map((app) => {
          return {
            name: app.title[currentLanguage] || app.title.de,
            key: app.slug,
            section: 'Apps',
          }
        })

    const featuresAndApps = [...baseFeatures, ...apps]

    const allItems = featuresAndApps
      .filter((feature) => {
        if (searchPermission?.trim()) {
          return feature.name
            .toLowerCase()
            .includes(searchPermission.trim().toLowerCase())
        }
        return true
      })
      .map((feature) => {
        const permissionRead = feature.key + '.read'
        const permissionWrite = feature.key + '.write'
        return {
          name: feature.name,
          key: feature.key,
          section: feature.section,
          read: isPermissionExist(permissionRead),
          write: isPermissionExist(permissionWrite),
          existingReadPermission: availablePermissions.includes(permissionRead),
          existingWitePermission:
            availablePermissions.includes(permissionWrite),
        }
      })
      .filter((feature) => {
        // filter out not booked feature, if we still want to render just remove this filter
        return feature.existingReadPermission || feature.existingWitePermission
      })

    const itemsGroupBySection = allItems.reduce((data, item) => {
      const section = item.section
      const found = data.find((d) => d.name === section)
      if (found) {
        const existingItems = found.items || []
        found.items = [...existingItems, item]
        return data
      }

      return [...data, { name: section, items: [item] }]
    }, [])

    return itemsGroupBySection
  }, [
    externalApps,
    isPermissionExist,
    searchPermission,
    currentLanguage,
    availablePermissions,
    isBaseRole,
  ])

  if (state === 'pending') return <LoadingScreen />

  return (
    <>
      <PageTitle prefix={id ? t('You are editing') : t('You are creating')}>
        {id ? getRoleName(detail.name) : t('A new user account')}
      </PageTitle>
      <ContentWrapper className="role-management__edit">
        <div className="role-management__edit-wrapper">
          <Formik
            innerRef={formikRef}
            initialValues={{
              name: getRoleName(detail?.name), // remove the domain prefix when editing
              description: detail?.description,
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
            validateOnBlur
          >
            {(formikProps) => (
              <>
                <FormikOnChange onChange={handleSubmit} />
                <TextInput
                  description={
                    isBaseRole
                      ? t('This is a default role and can not be changed!')
                      : ''
                  }
                  name="name"
                  autoFocus
                  label={t('Name')}
                  value={getRoleName(formikProps.values.name)}
                  onChange={(e) =>
                    formikProps.setFieldValue(
                      'name',
                      getRoleNameWithPrefix(e.target.value)
                    )
                  }
                  error={formikProps.errors.name}
                  disabled={formikProps.isSubmitting || isBaseRole}
                  onBlur={formikProps.handleBlur}
                />
                <Textarea
                  title={t('Description')}
                  name="description"
                  value={formikProps.values.description}
                  onChange={(value) =>
                    formikProps.setFieldValue('description', value)
                  }
                  error={formikProps.errors.description}
                  disabled={formikProps.isSubmitting || isBaseRole}
                />
              </>
            )}
          </Formik>
          <div className="devider" />
          <TextInput
            value={searchPermission}
            rounded
            icon="magnifying-glass"
            placeholder={t('Search a permission')}
            onChange={(e) => {
              setSearchPermission(e.target.value)
            }}
          />
          {featuresPermissionBySection.map((section) => (
            <Collapse
              level={1}
              type={'arrow'}
              title={
                isBaseRole && section.name === 'Apps' ? (
                  <>
                    {section.name}
                    <Tooltip
                      trigger="hover"
                      title={t(
                        'General Permission to read and write for all apps. This permission only exists for the default makaira roles.'
                      )}
                    >
                      <Icon
                        style={{ marginLeft: 5, verticalAlign: 'top' }}
                        symbol="circle-info__solid"
                        width={20}
                        height={20}
                      />
                    </Tooltip>
                  </>
                ) : (
                  section.name
                )
              }
              key={section.name}
              accordion={false}
              className="feature-section"
            >
              {section.items.map((feature, idx) => {
                let isCheckedAll = feature.read
                if (feature.existingWitePermission) {
                  isCheckedAll = feature.read && feature.write
                }
                const isPartialChecked = feature.read || feature.write
                const bookedFeature =
                  feature.existingReadPermission ||
                  feature.existingWitePermission

                return (
                  <Panel
                    key={`panel_${idx}`}
                    header={
                      <Checkbox
                        checked={isCheckedAll}
                        partialChecked={isPartialChecked}
                        disabled={isBaseRole || !bookedFeature}
                        label={
                          <MatchingText
                            size={'charlie'}
                            label={t(feature.name)}
                            searching={searchPermission}
                          />
                        }
                        onChange={() => {
                          addOrRemovePermission(feature, 'read', !isCheckedAll)
                          if (feature.existingWitePermission) {
                            addOrRemovePermission(
                              feature,
                              'write',
                              !isCheckedAll
                            )
                          }
                        }}
                        className={classNames({
                          'feature-booked': bookedFeature,
                        })}
                      />
                    }
                    collapsible={bookedFeature ? undefined : 'disabled'}
                  >
                    {bookedFeature && (
                      <FeaturerPermission
                        feature={feature}
                        isBaseRole={isBaseRole}
                        addOrRemovePermission={addOrRemovePermission}
                      />
                    )}
                  </Panel>
                )
              })}
            </Collapse>
          ))}
        </div>
      </ContentWrapper>
    </>
  )
}

export default observer(EditRole)
