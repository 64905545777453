import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import t from '../../utils/translate'
import { ArrowAction, Column } from '../../components/Table'
import { ImporterStore, IndexStore } from '../../stores'
import ImporterPageTitle from './ImporterPageTitle'
import { createRedirectPath } from '../../utils'
import ResourceTable from '../../components/ResourceTable'
import DateColumn from './components/DateColumn'
import './indexList.styl'
import StatusColumn from './components/StatusColumn'
import ColumnWithTooltip from './components/ColumnWithTooltip'
import StatusLabelColumn from './components/StatusLabelColumn'
import Text from '../../components/Text'
import { routes } from '../../routing'
import Button from '../../components/Button'

function IndexList() {
  function loadData() {
    IndexStore.fetchIndices()
    ImporterStore.fetchScheduleStatus()
    ImporterStore.fetchIndexStatus()
    ImporterStore.fetchAll()
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line
  }, [])

  const history = useHistory()

  const handleRowClick = (data) => {
    history.push(createRedirectPath(`/index/${data.language}`))
  }

  const getIndice = (record) =>
    ImporterStore.indicies.find(
      (item) => item.language === record.language && item.state === record.state
    ) || {}

  return (
    <>
      <ImporterPageTitle />
      <div className="content-wrapper overview-table">
        <ResourceTable
          rowKey="name"
          title={
            <>
              <span>{t('All Indices')}</span>
              <Button
                iconPosition="left"
                icon="rotate"
                className="overview-table__refresh-btn"
                onClick={loadData}
                disabled={
                  IndexStore.state === 'pending' ||
                  ImporterStore.state === 'pending'
                }
              >
                {t('Reload')}
              </Button>
            </>
          }
          store={IndexStore}
          resourceName="indexOverview"
          loading={
            IndexStore.state === 'pending' || ImporterStore.state === 'pending'
          }
          onRowClick={handleRowClick}
          emptyText={t('indexListDescription')}
          createActionText={t('Configure Importer')}
          createAction={() =>
            history.push(createRedirectPath(routes.importerCreate.path))
          }
        >
          <Column
            width={50}
            title={t('Status')}
            dataIndex="state"
            render={(val, row) => <StatusColumn {...row} />}
          />
          <Column
            width={150}
            title={t('Language')}
            dataIndex="language"
            render={(val) => <Text>{val.toUpperCase()}</Text>}
          />
          <Column
            width={150}
            title={t('Revision')}
            dataIndex="lastRevision"
            render={(val) => (
              <ColumnWithTooltip
                tooltip={t('highest Revisionnumber in makaira index')}
                text={val}
              />
            )}
          />
          <Column width={150} title={t('Document')} dataIndex="cleanCount" />
          {ImporterStore.replicatype === 'default' && (
            <Column
              width={140}
              title={t('Open Changes')}
              render={(val, row) => (
                <ColumnWithTooltip
                  tooltip={t(
                    'Count of open changes (documents) that still have to be imported into Makaira, to be in sync with your shop'
                  )}
                  text={getIndice(row).openChanges}
                />
              )}
            />
          )}
          <Column
            width={150}
            title={t('Created')}
            render={(created) => <DateColumn date={created.date} />}
            dataIndex="created"
            key="created"
          />
          <Column
            dataIndex="language"
            render={(lang, row) => (
              <StatusLabelColumn
                defaultLabel={null}
                language={lang}
                {...row}
                state="passive"
                hideMessage
                isDetail={false}
              />
            )}
          />
          <Column width={50} render={ArrowAction} />
        </ResourceTable>
      </div>
    </>
  )
}

export default observer(IndexList)
