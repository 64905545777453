import { observer } from 'mobx-react-lite'
import ReactApexChart from 'react-apexcharts'
import Text from '../../components/Text'
import { MonitoringStore } from '../../stores'
import { t } from '../../utils'
import { CHART_COLORS } from './constants'

const Chart = () => {
  const top5searchTerms = MonitoringStore.top5SearchTerms
  const data = top5searchTerms.map((item) => ({
    x: item.search_phrase,
    y: item.count,
  }))

  return (
    <div className="search-monitoring__chart-wrapper">
      {top5searchTerms.length < 5 && <Text>{t('No chart data')}</Text>}
      {top5searchTerms.length >= 5 && (
        <ReactApexChart
          options={{
            legend: {
              show: false,
            },
            chart: {
              height: 365,
              type: 'treemap',
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              formatter: function (val) {
                if (val.length > 15) {
                  return val.slice(0, 15) + '...'
                }
                return val
              },
            },
            colors: CHART_COLORS,
            plotOptions: {
              treemap: {
                distributed: true,
                enableShades: false,
              },
            },
          }}
          series={[
            {
              data,
            },
          ]}
          type="treemap"
          height={350}
        />
      )}
    </div>
  )
}

export default observer(Chart)
