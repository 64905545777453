import { toJS } from 'mobx'
import get from 'lodash/get'
import classNames from 'classnames'

import Text from '../../../../components/Text'
import ShadowScroll from '../../../../components/ShadowScroll'

import t from '../../../../utils/translate'
import getFieldContent from '../../../../utils/pageEditor/getFieldContent'

import EditorStore from '../../../../stores/PageEditor/EditorStore'
import ComponentStore from '../../../../stores/ComponentStore'

const ClipboardMenu = ({ onMenuHide, field }) => {
  const clipboard = toJS(EditorStore.clipboard).reverse()

  const getTitle = (data) => {
    if (data.type === 'single') {
      const component =
        ComponentStore.getByIdentifier(data.data.component) || {}
      return component.name || data.data.component
    }
    const totalComponent =
      get(data, 'data.top.elements.length', 0) +
      get(data, 'data.bottom.elements.length', 0)

    return `${totalComponent} ${t('components from page')}`
  }

  const getContentPreview = (fieldContent, data) => {
    if (data.type === 'single') {
      const component = ComponentStore.getByIdentifier(data.data.component)
      if (!component) return t('Component not available')
    }

    if (data.type === 'page') {
      return (
        get(data, ['title', EditorStore.contentLanguage, 'title']) ||
        t('no title yet')
      )
    }
    if (fieldContent?.length) {
      return fieldContent
    }

    const content = get(data, [
      'data',
      'properties',
      EditorStore.contentLanguage,
      'content',
    ])

    if (!content) {
      return t('Content not displayable')
    }

    // we only want to show "no content yet" if the component has fields
    if (Object.keys(content).length) {
      return t('No content yet')
    }

    return ''
  }

  return (
    <div onClick={onMenuHide} className="page-editor__field-menu-action">
      <ShadowScroll maxHeight="300px">
        {clipboard.map((item, index) => {
          const fieldContent = getFieldContent(item.data)

          return (
            <div
              className="page-editor__field-menu-item page-editor__field-menu-item--component"
              key={item.data.id + index}
              onClick={() =>
                EditorStore.pasteAction({
                  clipboardData: item,
                  currentElement: field,
                  eventSource: field ? 'element' : 'page',
                })
              }
            >
              <Text element="div" variant="book">
                {getTitle(item)}
              </Text>
              <Text
                className={classNames({
                  'page-editor__no-content': item === 'single' && !fieldContent,
                })}
                element="div"
                title={getContentPreview(fieldContent, item)}
              >
                {getContentPreview(fieldContent, item)}
              </Text>
            </div>
          )
        })}
      </ShadowScroll>
    </div>
  )
}

export default ClipboardMenu
