import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async get() {
    return fetch(`/ranking-mix-config`)
  },

  async update(data) {
    await fetch('/ranking-mix-config', {
      method: 'PUT',
      body: JSON.stringify(data),
    })
  },

  async getFieldsValidation() {
    return fetch('/ranking-mix-config/check-data')
  },
}
