import { useEffect } from 'react'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'

import Text from '../../components/Text'
import Table, { Column } from '../../components/Table'
import TextInput from '../../components/TextInput'
import Icon from '../../components/Icon'
import Checkbox from '../../components/Checkbox'
import { GeneralSettingsBannerStore } from '../../stores'
import { t } from '../../utils'

import './banner.styl'

const BANNER_TYPES = ['A', 'B', 'C', 'D', 'E']

function Banner() {
  const {
    state,
    fetchDeviceTypes,
    fetchBannerType,
    onChangeBannerLabel,
    onChangeBannerTypeDevice,
    onChangeDeviceTypeName,
    editingBannerTypes,
    editingDeviceTypes,
  } = GeneralSettingsBannerStore

  const fetchData = async () => {
    await fetchDeviceTypes()
    await fetchBannerType()
  }

  useEffect(() => {
    fetchData()

    return () => {
      GeneralSettingsBannerStore.discard()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div className="banner-sections">
        <div className="banner-section banner-section--labels">
          <Text size="delta" weight="bold">
            {t('Upload media label')}
          </Text>
          <Text
            size="bravo"
            weight="medium"
            className="banner-section--description"
          >
            {t(
              'You can give the upload fields custom labels (eg. wide, square, vertical)'
            )}
          </Text>
          <Table
            data={toJS(editingDeviceTypes)}
            loading={state === 'pending' && editingDeviceTypes.length === 0}
            className="banner-table--custom"
          >
            <Column
              title={t('No.')}
              render={(id, record, index) => <Text>{index + 1}</Text>}
              dataIndex="id"
              className="column-id"
              width={34}
            />
            <Column
              title={t('Name')}
              render={(name, record, index) => (
                <TextInput
                  name={`${record.id}.name`}
                  value={name}
                  onChange={(e) =>
                    onChangeDeviceTypeName(e.target.value, index)
                  }
                />
              )}
              dataIndex="name"
            />
          </Table>
        </div>
        <div className="banner-section">
          <Text size="delta" weight="bold">
            {t('Banner types')}
          </Text>
          <Text
            size="bravo"
            weight="medium"
            className="banner-section--description"
          >
            {t(
              'You can configure banners: Choose a custom labels for easy identification and pick which upload fields should be visible available'
            )}
          </Text>
          <Table
            data={toJS(editingBannerTypes)}
            loading={state === 'pending' && editingBannerTypes.length === 0}
            scroll
            className="banner-table--custom"
          >
            <Column
              title={t('Type')}
              render={(id, record, index) => <Text>{BANNER_TYPES[index]}</Text>}
              dataIndex="id"
              className="column-id"
              width={34}
            />
            <Column
              title={t('Label')}
              render={(label, record, index) => (
                <TextInput
                  name={`${record.id}.label`}
                  value={label}
                  onChange={(e) => onChangeBannerLabel(e.target.value, index)}
                />
              )}
              dataIndex="label"
            />
            <Column
              render={() => (
                <div className="connector-icon">
                  <Icon symbol="chevron-right" />
                </div>
              )}
              dataIndex="actions"
              width={34}
            />
            <Column
              title={t('Frontend variable')}
              render={(slug) => (
                <Tooltip title={slug}>
                  <div className="frontend-variable">{slug}</div>
                </Tooltip>
              )}
              dataIndex="slug"
            />
            {editingDeviceTypes.map((media, deviceTypeIndex) => {
              if (!media?.name?.trim()) return null

              return (
                <Column
                  title={
                    <div className="column-media-type" key={media.name}>
                      {media.name}
                    </div>
                  }
                  render={(_, record, bannerIndex) => (
                    <Checkbox
                      checked={record.deviceTypeIndexs.includes(
                        deviceTypeIndex
                      )}
                      onChange={(e) =>
                        onChangeBannerTypeDevice(
                          e.target.checked,
                          deviceTypeIndex,
                          bannerIndex
                        )
                      }
                    />
                  )}
                  dataIndex="mediaId"
                  key={media.id}
                  align="center"
                />
              )
            })}
          </Table>
        </div>
      </div>
    </div>
  )
}

export default observer(Banner)
