import { useEffect, useMemo, useState } from 'react'

/**
 * Check whether an reference object is currently viewable on the screen.
 * Taken from https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
 *
 * @param ref
 * @param options
 * @returns {boolean}
 */
function useOnScreen(ref, options) {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        options
      ),
    [options]
  )

  useEffect(() => {
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
    // eslint-disable-next-line
  }, [])

  return isIntersecting
}

export default useOnScreen
