import RcMenu, { SubMenu, MenuItem } from 'rc-menu'
import Icon from '../Icon'

import 'rc-menu/assets/index.less'
import 'rc-menu/assets/menu.less'
import './style.styl'

const GroupTitle = ({ children }) => {
  return <div className="menu-v3__block-title">{children}</div>
}

const SubMenuCustom = ({ title, children, className, ...rest }) => {
  return (
    <SubMenu
      popupOffset={[8, 0]}
      className={`menu-v3__submenu ${className}`}
      title={title}
      expandIcon={<Icon className="menu-v3__icon" symbol="chevron-right" />}
      {...rest}
    >
      {children}
    </SubMenu>
  )
}

const MenuItemCustom = ({ children, className, ...rest }) => {
  return (
    <MenuItem className={`menu-v3__item ${className}`} {...rest}>
      {children}
    </MenuItem>
  )
}

const Menu = ({ children, className, ...rest }) => {
  return (
    <RcMenu
      className={`menu-v3 ${className}`}
      triggerSubMenuAction="hover"
      motion={{ motionName: 'rc-menu-open-zoom' }}
      {...rest}
    >
      {children}
    </RcMenu>
  )
}

Menu.GroupTitle = GroupTitle
Menu.SubMenu = SubMenuCustom
Menu.MenuItem = MenuItemCustom

export default Menu
