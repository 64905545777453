import range from 'lodash/range'

import Text from '../../../components/Text'
import Radio from '../../../components/Radio'
import Select from '../../../components/Select'
import Button from '../../../components/Button'
import TextInput from '../../../components/TextInput'

import t from '../../../utils/translate'

const ScheduleItem = ({ index, formikProps, schedule, onRemove }) => {
  const updateCronExpression = (frequency, time, text) => {
    let cronExpression = ''

    switch (frequency) {
      case 'every':
        cronExpression = `*/${time || schedule.every} * * * *`
        break
      case 'hourly':
        cronExpression = `${time || schedule.hourly} * * * *`
        break
      case 'daily':
        cronExpression = `${(time && time[1]) || schedule.daily[1]} ${
          (time && time[0]) || schedule.daily[0]
        } * * *`
        break
      default:
        cronExpression = text
    }

    formikProps.setFieldValue(
      `scheduledImporters.${index}.cronExpression`,
      cronExpression
    )
  }

  const onChangeFrequency = (e) => {
    formikProps.setFieldValue(`scheduledImporters.${index}.selectedType`, e)
    updateCronExpression(e)
  }

  const onChangeHourly = (e) => {
    const formartMinute =
      e.target.value.length > 2
        ? parseInt(e.target.value).toString()
        : parseInt(e.target.value)
    if (isNaN(formartMinute)) {
      onChangeTime(0)
    } else {
      if (/^([0-5][0-9]|[0-9])$/.test(formartMinute.toString())) {
        onChangeTime(formartMinute)
      }
    }
  }

  const onChangeDailyTime = (index) => (e) => {
    const newDailyTime = [...schedule.daily]
    newDailyTime[index] =
      e.target.value.length > 2
        ? parseInt(e.target.value).toString()
        : parseInt(e.target.value)
    if (isNaN(newDailyTime[index])) {
      newDailyTime[index] = 0
      onChangeTime(newDailyTime)
    } else {
      if (
        /^([0-1]?[0-9]|2[0-3]):([0-5][0-9]|[0-9])$/.test(newDailyTime.join(':'))
      ) {
        onChangeTime(newDailyTime)
      }
    }
  }

  const onChangeTime = (value) => {
    formikProps.setFieldValue(
      `scheduledImporters.${index}.${schedule.selectedType}`,
      value
    )
    updateCronExpression(schedule.selectedType, value)
  }

  return (
    <div className="importer-config__section-row importer-config__section-row--other">
      <Button
        variant="tertiary"
        level={0}
        icon="times"
        iconPosition="left"
        className="importer-config__remove-schedule"
        onClick={onRemove}
      >
        {t('Delete Schedule')}
      </Button>
      <Text style={{ marginBottom: '1.5rem' }} size="delta">
        {t('Schedule #')}
        {index + 1}
      </Text>
      <Text className="importer-config__guide-text" size="bravo">
        {t('Frequency')}
      </Text>
      <Radio.Group onChange={onChangeFrequency} value={schedule.selectedType}>
        <div className="importer-config__section-row importer-config__section-row--frequency importer-config__section-row--no-border">
          <Radio
            label={<Text size="charlie">{t('Every')}</Text>}
            value="every"
          />

          <Select
            disabled={schedule.selectedType !== 'every'}
            onChange={onChangeTime}
            value={schedule.every}
            options={range(15, 59, 15).map((minute) => ({
              label: minute + ' ' + t('minutes'),
              value: minute,
            }))}
          />
        </div>
        <div className="importer-config__section-row importer-config__section-row--frequency importer-config__section-row--hourly">
          <Radio
            label={<Text size="charlie">{t('Hourly')}</Text>}
            value="hourly"
          />

          <TextInput
            style={{ marginRight: '0.5rem' }}
            width="50px"
            disabled={schedule.selectedType !== 'hourly'}
            onWhiteGround
            type="number"
            onChange={onChangeHourly}
            value={parseInt(schedule.hourly).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          />

          <div style={{ marginRight: '2rem' }}>
            {t('minutes past the hour')}
          </div>
        </div>
        <div className="importer-config__section-row importer-config__section-row--frequency importer-config__section-row--hourly">
          <Radio
            label={<Text size="charlie">{t('Daily')}</Text>}
            value="daily"
          />

          <TextInput
            style={{ marginRight: '0.5rem' }}
            width="50px"
            disabled={schedule.selectedType !== 'daily'}
            onWhiteGround
            type="number"
            onChange={onChangeDailyTime(0)}
            value={schedule.daily[0]}
          />

          <div style={{ marginRight: '0.5rem' }}>:</div>

          <TextInput
            width="50px"
            disabled={schedule.selectedType !== 'daily'}
            onWhiteGround
            type="number"
            onChange={onChangeDailyTime(1)}
            value={parseInt(schedule.daily[1]).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          />
        </div>
        <div className="importer-config__section-row  importer-config__section-row--frequency">
          <Radio
            label={<Text size="charlie">{t('Cron-tab')}</Text>}
            value="cron"
          />

          <TextInput
            onWhiteGround
            style={{ width: 373 }}
            disabled={schedule.selectedType !== 'cron'}
            value={schedule.cronExpression}
            onChange={(e) => updateCronExpression(4, null, e.target.value)}
          />
        </div>
      </Radio.Group>
    </div>
  )
}

export default ScheduleItem
