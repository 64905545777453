import { observer } from 'mobx-react-lite'
import RcSelect from 'rc-select'
import classes from 'classnames'
import Icon from '../Icon'
import Text from '../Text'

import './tags-select.styl'
import './style.styl'

const TagsInput = ({
  title,
  description,
  error,
  className,
  tokenSeparators,
  ...rest
}) => {
  const classNames = classes(className, {
    'tags-input--error': error,
  })

  return (
    <div className={classNames}>
      <Text className="tags-input__label">{title}</Text>
      <RcSelect
        open={false}
        prefixCls="tags-input"
        mode="tags"
        tokenSeparators={tokenSeparators || [' ', ',', '\n']}
        tagRender={(data) => {
          const { onClose } = data
          return (
            <div className="tags-input__item">
              <Text weight="bold" size="bravo">
                {data.value}
              </Text>
              <Icon
                onClick={onClose}
                width="16px"
                height="16px"
                symbol="xmark"
              />
            </div>
          )
        }}
        {...rest}
      />
      {(description || error) && (
        <Text
          element="p"
          weight="book"
          size="bravo"
          className="tags-input__message"
        >
          {description || error}
        </Text>
      )}
    </div>
  )
}

export default observer(TagsInput)
