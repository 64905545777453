import * as Yup from 'yup'
import slugify from 'slugify'
import { Formik, Field } from 'formik'
import { observer } from 'mobx-react-lite'

import FormikURLInput from '../../components/TextInput/FormikURLInput'
import FormikTextInput from '../../components/TextInput/FormikTextInput'
import UpdateStoreDirtyState from '../../components/UpdateStoreDirtyState'
import Swich from '../../components/Switch'

import t from '../../utils/translate'

import UIStore from '../../stores/UIStore'
import SmartBundleStore from '../../stores/SmartBundleStore'
import './metaConfiguration.styl'

function MetaConfiguration({ formikRef }) {
  const { languages } = UIStore

  const schema = Yup.object().shape({
    internal_title: Yup.string()
      .required(t('Internal title has to be filled.'))
      .max(255, t('Internal title can not be longer than 255 characters.')),
  })

  const formatInitialValues = (values) => {
    if (!values.url) values.url = {}
    if (!values.title) values.title = {}
    if (!values.identifier) values.identifier = ''

    languages.forEach((lang) => {
      if (values.url[lang] === undefined) {
        values.url[lang] = ''
      }

      if (values.title[lang] === undefined) {
        values.title[lang] = ''
      }
    })

    return values
  }

  const handleTitleChange = (event, formikProps) => {
    const { handleChange, setFieldValue, touched } = formikProps
    handleChange(event)

    // Always update the title in the store so that in the PageHeader
    // the user input is displayed.
    SmartBundleStore.setBundle({
      ...SmartBundleStore.bundleToEdit,
      internal_title: event.target.value,
    })

    if (canBeAutofilled(touched)) {
      const slug = slugify(event.target.value.toLowerCase())

      setFieldValue('identifier', slug)

      languages.forEach((lang) => {
        setFieldValue(`title.${lang}`, event.target.value)
        setFieldValue(`url.${lang}`, `/${lang}/${slug}`)
      })
    }
  }

  /**
   * Check if the form is automatically fillable (urls & title automatically set)
   * The form is auto-fillable, during the creation of a new bundle when
   * only the internal title has been et.
   *
   * @param touched
   * @returns {boolean}
   */
  const canBeAutofilled = (touched) => {
    if (SmartBundleStore.bundleToEdit.id !== undefined) return false

    const touchedCount = Object.keys(touched).length

    return !!(
      (touchedCount === 1 && touched.internal_title) ||
      touchedCount === 0
    )
  }

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={schema}
      initialValues={formatInitialValues(SmartBundleStore.bundleToEdit)}
      onSubmit={(values) => SmartBundleStore.setBundle(values)}
    >
      {(formikProps) => (
        <>
          <UpdateStoreDirtyState store={SmartBundleStore} />

          <Swich
            onChange={(e) => formikProps.setFieldValue('active', e)}
            checked={formikProps.values.active}
            title={
              <div className="text-input__label text-input__label--white-ground">
                {t('Active')}
              </div>
            }
          />

          <Field
            onWhiteGround
            name="internal_title"
            component={FormikTextInput}
            label={t('Internal title')}
            onChange={(event) => handleTitleChange(event, formikProps)}
            placeholder={!formikProps?.values?.id ? t('New Bundle…') : ''}
            description={t('Will be shown in Makaira admin overviews')}
            autoFocus
          />

          <Field
            onWhiteGround
            name="identifier"
            component={FormikTextInput}
            label={t('Identifier')}
            description={t('Can be used in Frontend for technical reasons')}
          />

          {languages.map((lang) => (
            <div className="bundle__form-row" key={lang}>
              <Field
                name={`title.${lang}`}
                label={`${t('Title')} (${lang.toUpperCase()})`}
                component={FormikTextInput}
                onWhiteGround
              />

              <Field
                name={`url.${lang}`}
                label={`${t('URL')} (${lang.toUpperCase()})`}
                component={FormikURLInput}
                onWhiteGround
                id={SmartBundleStore.bundleToEdit.id}
                type="bundle"
              />
            </div>
          ))}
        </>
      )}
    </Formik>
  )
}

export default observer(MetaConfiguration)
