import React, { useContext } from 'react'
import classNames from 'classnames'
import './style.styl'

const RadioContext = React.createContext({})

const Radio = ({
  label,
  value,
  disabled,
  size = 'medium',
  direction = 'horizontal', // 'horizontal' or 'vertical'
  labelClickable = true,
  wrapperClassName = '',
  ...rest
}) => {
  const { value: selectedValue, onChange = () => {} } = useContext(RadioContext)

  const handleChange = (e) => {
    if (disabled) return

    onChange(e.target.value)
  }

  if (labelClickable) {
    return (
      <Label
        disabled={disabled}
        size={size}
        direction={direction}
        selectedValue={selectedValue}
        value={value}
        handleChange={handleChange}
        label={label}
        wrapperClassName={wrapperClassName}
        {...rest}
      />
    )
  }

  return (
    <div
      className={classNames(
        'radio-button__label-not-clickable',
        wrapperClassName
      )}
    >
      <Label
        disabled={disabled}
        size={size}
        direction={direction}
        selectedValue={selectedValue}
        value={value}
        handleChange={handleChange}
        {...rest}
      />
      {label}
    </div>
  )
}

const Label = ({
  disabled,
  size,
  direction,
  selectedValue,
  value,
  handleChange,
  label,
  wrapperClassName,
  ...rest
}) => {
  return (
    <label
      className={classNames(
        'radio-button',
        `radio-button--${size}`,
        `radio-button--${direction}`,
        {
          ['radio-button--disabled']: disabled,
        },
        wrapperClassName
      )}
      {...rest}
    >
      <input
        type="radio"
        disabled={disabled}
        checked={selectedValue ? selectedValue === value : null}
        value={value}
        onChange={handleChange}
      />
      <span className="radio-button__checkmark" />
      {label}
    </label>
  )
}

const Group = ({ onChange, value, children }) => {
  return (
    <RadioContext.Provider value={{ onChange, value }}>
      {children}
    </RadioContext.Provider>
  )
}

Radio.Group = Group

export { Group }
export default Radio
