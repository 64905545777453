const DEFAULT_VALUES = {
  active: false,
  metadata: {
    description: '',
    keywords: '',
    robotFollow: 'follow',
    robotIndex: 'index',
    seoTitle: '',
    title: '',
  },
  seoUrls: '',
  searchable: false,
}

export { DEFAULT_VALUES }
