import { useEffect } from 'react'
import set from 'lodash/set'

const useHoverModal = (selector, isOpen) => {
  useEffect(() => {
    const modal = document.querySelector(selector)
    const setToFront = () => set(modal, 'parentElement.style.zIndex', '1000')
    const setToBack = () => set(modal, 'parentElement.style.zIndex', '999')

    if (modal) {
      modal.addEventListener('mouseenter', setToFront)
      modal.addEventListener('mouseleave', setToBack)
    }

    return () => {
      if (modal) {
        modal.removeEventListener('mouseenter', setToFront)
        modal.removeEventListener('mouseleave', setToBack)
      }
    }
  }, [selector, isOpen])
}

export default useHoverModal
