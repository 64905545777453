import { useState } from 'react'
import cx from 'classnames'
import moment from 'moment'
import { Tooltip } from 'antd'

import { t } from '../../../utils'
import Modal from '..'
import DatePicker from '../../DatePicker'
import Button from '../../Button'
import Switch from '../../Switch'
import { EditorStore, UIStore } from '../../../stores'
import Checkbox from '../../Checkbox'
import Icon from '../../Icon'

import './timeControlModal.styl'
/**
 * @typedef { Object } OnChangeParams
 * @property { Boolean } active
 * @property { String } from
 * @property { String } to
 * 
 * @typedef {Object} TimeControlModalProps
 * @property {String} className 
 * @property {boolean} visible 
 * @property {Function} onClose 
 * @property {(agr: OnChangeParams) => void} onChange 
 * @property {string} from 
 * @property {string} to 
 * @property {string} language 
 * @property {Boolean} hideCancelBtn 
 * @property {string} cancelTitle 
 * @property {string} confirmTitle 
 * @property {string} title 
 * @property {Boolean} maskClosable 
 * /
 
 * /
 * @param { TimeControlModalProps } props 
 * @returns 
 */
function TimeControlModal(props) {
  const {
    className,
    visible,
    onClose,
    customDialogClassName,
    from,
    to,
    onChange = () => {},
    language,
    hideCancelBtn,
    cancelTitle = '',
    confirmTitle = '',
    title = '',
    maskClosable = false,
    allPersona,
    personaIds,
  } = props

  const [activeFrom, setActiveFrom] = useState(from ? moment(from) : null)
  const [activeTo, setActiveTo] = useState(to ? moment(to) : null)
  const [enableStopTime, setEnableStopTime] = useState(!!to || false)
  const [allPersonaState, setAllPersona] = useState(allPersona)
  const [personaIdsState, setPersonaIds] = useState(personaIds || [])

  language &&
    moment.updateLocale(language, {
      week: {
        dow: 1,
      },
    })

  const onChangeStopTime = (value) => {
    setEnableStopTime(value)
    if (!value) {
      setActiveTo(null)
    }
  }

  const showPersonas = personaIds !== undefined && allPersona !== undefined

  const onSave = () => {
    let newData = {
      active: !!activeFrom || !!(enableStopTime && activeTo),
      from: activeFrom && activeFrom.toISOString(),
      to: activeTo && activeTo.toISOString(),
    }
    if (showPersonas) {
      newData.allPersona = allPersonaState
      newData.personaIds = personaIdsState
    }
    onChange(newData)
    onClose()
  }

  const handleChangePersonas = (identifier, checked, id) => {
    if (identifier === 'All') {
      setAllPersona(checked)
      setPersonaIds([])
      return
    }
    if (checked) {
      setPersonaIds((prev) => [...prev, id])
    } else {
      setPersonaIds((prev) => prev.filter((i) => i !== id))
    }
  }

  const Footer = () => (
    <>
      {!hideCancelBtn && (
        <Button
          type="button"
          key="close"
          iconPosition="left"
          icon="xmark"
          onClick={onClose}
        >
          {cancelTitle || t('Cancel')}
        </Button>
      )}
      <div />
      <Button
        type="button"
        variant="primary"
        icon="check"
        key="save"
        onClick={onSave}
      >
        {confirmTitle || t('Confirm')}
      </Button>
    </>
  )

  const timeFormat = language === 'de' ? 'DD.MM.YYYY HH:mm' : 'YYYY-MM-DD HH:mm'
  const { publishConfig, getDefaultPublishConfig } = EditorStore

  return (
    <Modal
      className={cx(className, 'time-control-modal')}
      visible={visible}
      onClose={onClose}
      header={title || t('Time-based display')}
      mask
      align="left"
      maskClosable={maskClosable}
      customDialogClassName={customDialogClassName}
      footer={<Footer />}
    >
      <div className="content">
        <DatePicker
          title={t('Set a start date and time')}
          placeholder={t('Active from')}
          showTime
          format={timeFormat}
          value={activeFrom}
          onChange={setActiveFrom}
        />
        <div>
          <div className="stop-datetime--label">{t('Stop displaying')}</div>
          <Switch checked={enableStopTime} onChange={onChangeStopTime} />
          {!enableStopTime ? (
            <span className="stop-datetime">
              {t('toggle to set an end date')}
            </span>
          ) : (
            <DatePicker
              placeholder={t('to')}
              className="stop-datetime"
              showTime
              format={timeFormat}
              value={activeTo}
              onChange={setActiveTo}
              disabledDate={(current) =>
                current.isSameOrBefore(moment(activeFrom))
              }
            />
          )}
        </div>
      </div>
      {showPersonas && (
        <div className="page-editor-setting-modal__user-group">
          <div className="stop-datetime--label">{t('Personas')}</div>
          {[getDefaultPublishConfig(), ...publishConfig].map((personas) => {
            const { name, identifier, description, id } = personas
            const descTooltip = description?.[UIStore.currentLanguage]
            return (
              <div key={identifier} className="user-group__item">
                <Checkbox
                  checked={
                    (identifier === 'All' && allPersonaState) ||
                    personaIdsState.includes(id)
                  }
                  label={
                    name && typeof name === 'object'
                      ? t(name[UIStore.currentLanguage])
                      : t(name)
                  }
                  onChange={(e) =>
                    handleChangePersonas(identifier, e.target.checked, id)
                  }
                  disabled={allPersonaState && identifier !== 'All'}
                />
                <Tooltip title={descTooltip} trigger="click">
                  <Icon
                    className={cx('resource-table__infor-icon', {
                      visible: !!descTooltip,
                    })}
                    symbol="circle-info__solid"
                    width={20}
                    height={20}
                  />
                </Tooltip>
              </div>
            )
          })}
        </div>
      )}
    </Modal>
  )
}

export default TimeControlModal
