import { observer } from 'mobx-react-lite'

import { t } from '../../../utils'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import StorefrontSettingStore from '../../../stores/StorefrontSettingStore'
import Text from '../../../components/Text'

function RemoveAuthenticationModal() {
  const {
    authenticationModalVisible,
    closeAuthenticationModal,
    removeAuthentication,
  } = StorefrontSettingStore

  const handleGoPublic = () => {
    removeAuthentication()
    closeAuthenticationModal()
  }

  return (
    <Modal
      visible={authenticationModalVisible}
      onClose={closeAuthenticationModal}
      header={t('Switching off authentication?')}
      mask
      maskClosable
      footer={[
        <Button
          key="cancel"
          variant="secondary"
          level={1}
          icon="xmark"
          onClick={closeAuthenticationModal}
        >
          {t('Ooops, go back')}
        </Button>,
        <Button
          key="save"
          variant="primary"
          level={1}
          icon="check"
          onClick={handleGoPublic}
        >
          {t('Yes, go public')}
        </Button>,
      ]}
    >
      <div className="storefront-settings__authentication-modal">
        <Text size="delta">
          {t('Are you aware of the fact that')} <br />{' '}
          <Text size="delta" weight="bold">
            {t(
              'your storefront is public available and all users and passwords will be deleted?'
            )}
          </Text>
        </Text>
      </div>
    </Modal>
  )
}

export default observer(RemoveAuthenticationModal)
