import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { observer } from 'mobx-react-lite'
import { FieldArray, Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Text from '../../components/Text'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import PageTitle from '../../components/PageTitle'
import TextInput from '../../components/TextInput'
import ScheduleItem from './components/ScheduleItem'
import LoadingScreen from '../../components/LoadingScreen'
import Collapse, { Panel } from '../../components/Collapse'
import ContentWrapper from '../../components/ContentWrapper'
import UpdateStoreDirtyState from '../../components/UpdateStoreDirtyState'

import DefaultImportPanel from './panels/DefaultImportPanel'
import PersistenceLayerImportPanel from './panels/PersistenceLayerImportPanel'

import { routes } from '../../routing'
import { createSchedule } from '../../models/Importer'
import { ActionLayerStore, ImporterStore, UIStore } from '../../stores'

import t from '../../utils/translate'
import createRedirectPath from '../../utils/createRedirectPath'
import { SOURCES_TYPE } from './constants'

import './edit.styl'

const regexDomain =
  /^(http(s?):\/\/)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/

const schema = Yup.object().shape({
  internalTitle: Yup.string()
    .required(t('Internal title has to be filled.'))
    .max(255, t('Internal title can not be longer than 255 characters.')),
  sourceUrl: Yup.string().when('sourceType', {
    is: (val) => val !== SOURCES_TYPE.PERSISTENCE_LAYER,
    then: Yup.string()
      .matches(regexDomain, t('Source-URL has to be a valid URL.'))
      .required(t('Source-URL has to be filled.')),
  }),
})

const PANEL_COMPONENTS = {
  [SOURCES_TYPE.OXID]: DefaultImportPanel,
  [SOURCES_TYPE.SHOPWARE]: DefaultImportPanel,
  [SOURCES_TYPE.NDJSON]: DefaultImportPanel,
  [SOURCES_TYPE.PERSISTENCE_LAYER]: PersistenceLayerImportPanel,
  [SOURCES_TYPE.OTHER]: DefaultImportPanel,
}

const Edit = () => {
  const SOURCES = [
    {
      label: 'OXID Connect Plugin',
      value: SOURCES_TYPE.OXID,
    },
    {
      label: 'Shopware Connect Plugin',
      value: SOURCES_TYPE.SHOPWARE,
    },
    {
      label: t('NDJSON File'),
      value: SOURCES_TYPE.NDJSON,
    },
    {
      label: t('Persistence Layer'),
      value: SOURCES_TYPE.PERSISTENCE_LAYER,
    },
    {
      label: t('Other Source'),
      value: SOURCES_TYPE.OTHER,
    },
  ]

  const [isLoading, setLoading] = useState(true)

  const formikRef = useRef()

  const { id } = useParams()
  const history = useHistory()

  const isDirty = ImporterStore.isDirty

  useEffect(() => {
    async function fetchImporter() {
      const [importer] = await Promise.all([
        ImporterStore.getById(id),
        ImporterStore.fetchScheduleStatus(),
        UIStore.fetchAvailableLanguages(),
        UIStore.fetchSetupConfiguration(),
      ])

      if (!id) {
        const secret = UIStore.setupConfiguration?.makaira?.sharedSecret || ''
        importer.sourceSecret = secret
      }

      ImporterStore.editImporter(importer)
      setLoading(false)
    }

    fetchImporter()
  }, [id])

  const handleActionLayerSave = async () => {
    const formValidation = await formikRef.current?.validateForm()
    if (Object.keys(formValidation).length === 0) {
      await formikRef.current?.submitForm()

      await ImporterStore.updateOrCreate()

      if (ImporterStore.state === 'error') {
        throw new Error()
      }
      history.push(createRedirectPath(routes.importerSchedule.path))
    } else {
      return false
    }
  }

  const handleActionLayerClose = () => {
    if (id) {
      formikRef.current.resetForm()
    } else {
      history.push(createRedirectPath(routes.importerSchedule.path))
    }
  }

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        saveTitle: t('Save'),
        closeTitle: t('Cancel'),
        onSave: handleActionLayerSave,
        onClose: handleActionLayerClose,
      })
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  const onChangeSourceType = (formikProps) => (type) => {
    const newSchedule = createSchedule(type)
    let newLanguages = []

    if (type === SOURCES_TYPE.PERSISTENCE_LAYER) {
      ImporterStore.checkPersistenceLayerAvailability()
    }

    // with ndjson: ONE language HAS to be selected
    if (type === SOURCES_TYPE.NDJSON) {
      newLanguages =
        UIStore.availableLanguages.length > 0
          ? [UIStore.availableLanguages[0]]
          : []
    }

    formikProps.setFieldValue('sourceType', type)
    formikProps.setFieldValue('schedules', newSchedule)
    formikProps.setFieldValue('languages', newLanguages)
  }

  const setValueForAllSchedules = (formikProps, key, value) => {
    const scheduleTypes = ['scheduledImporters', 'otherSchedules']

    scheduleTypes.forEach((scheduleType) => {
      formikProps.setFieldValue(
        scheduleType,
        formikProps.values[scheduleType].map((schedule) => ({
          ...schedule,
          [key]: value,
        }))
      )
    })
  }

  const onChangeActive = (formikProps) => (e) => {
    setValueForAllSchedules(formikProps, 'enabled', e)
  }

  const onChangeTimeout = (formikProps) => (e) => {
    setValueForAllSchedules(
      formikProps,
      'timeout',
      e.target.value && parseInt(e.target.value)
    )
  }

  const createNewSchedule = (formikProps) => ({
    id: uuidv4(),
    enabled: formikProps.values.schedules[0].enabled,
    kind: 'scheduled',
    bulk: true,
    active: false,
    autoswitch: true,
    cronExpression: '',
    timeout: formikProps.values.schedules[0].timeout,
    selectedType: 'cron',
    hourly: 0,
    every: 15,
    daily: [0, 0],
  })

  const finalize = (values) => {
    return {
      ...values,
      schedules: [...values.otherSchedules, ...values.scheduledImporters],
    }
  }

  if (Object.keys(ImporterStore.importerToEdit).length === 0)
    return <LoadingScreen />

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      validationSchema={schema}
      initialValues={ImporterStore.importerToEdit}
      onSubmit={(values) => ImporterStore.setImporter(finalize(values))}
    >
      {(formikProps) => (
        <>
          <UpdateStoreDirtyState store={ImporterStore} />
          <PageTitle prefix={t('You are editing')}>
            {formikProps.values.internalTitle}
          </PageTitle>
          <ContentWrapper className="importer-config" loading={isLoading}>
            <Text element="p" size="echo">
              {t('Basics')}
            </Text>
            <div className="importer-config__basic">
              <div className="importer-config__basic-row">
                <Switch
                  checked={formikProps.values.otherSchedules[0]?.enabled}
                  onChange={onChangeActive(formikProps)}
                  title={
                    <div className="text-input__label text-input__label--white-ground">
                      {t('Active')}
                    </div>
                  }
                />
              </div>
              <TextInput
                onWhiteGround
                style={{ maxWidth: 364 }}
                label={t('Internal title')}
                value={formikProps.values.internalTitle}
                placeholder={t('Enter title…')}
                description={t('Will be shown in Makaira admin overviews')}
                onChange={(e) =>
                  formikProps.setFieldValue('internalTitle', e.target.value)
                }
                error={formikProps.errors.internalTitle}
              />
            </div>

            <Text element="p" size="echo">
              {t('Source')}
            </Text>
            <div
              data-cy="source-collapse"
              className="importer-config__section-wrapper"
            >
              <Collapse
                type="radio"
                onChange={onChangeSourceType(formikProps)}
                value={formikProps.values.sourceType}
                activeKey={[formikProps.values.sourceType]}
              >
                {SOURCES.map((source) => {
                  const ImportPanel = PANEL_COMPONENTS[source.value]
                  return (
                    <Panel
                      key={source.value}
                      value={source.value}
                      header={
                        <Text data-cy="source-panel-label" size="delta">
                          {source.label}
                        </Text>
                      }
                    >
                      <ImportPanel
                        key={source.value}
                        formik={formikProps}
                        source={source}
                      />
                    </Panel>
                  )
                })}
              </Collapse>
            </div>
            <Text element="p" size="echo">
              {t('Rebuild Index Schedule')}
            </Text>
            <div className="importer-config__section-wrapper">
              <div className="importer-config__section-row">
                <Text className="importer-config__guide-text" size="bravo">
                  {t(
                    `You can setup a automated scheduled rebuild for your index if needed. For example for constantly changing NDJSON files \n or it your ERP system is operating directly on the database of your shop system where our Connect plugins cannot recognize changes.`
                  )}
                </Text>
              </div>
              <FieldArray
                name="scheduledImporters"
                render={(arrayHelpers) => (
                  <>
                    {formikProps.values.scheduledImporters.map(
                      (schedule, index) => (
                        <ScheduleItem
                          formikProps={formikProps}
                          key={schedule.id}
                          index={index}
                          schedule={schedule}
                          onRemove={() => arrayHelpers.remove(index)}
                        />
                      )
                    )}

                    <div className="importer-config__section-row">
                      <Button
                        variant="tertiary"
                        icon="plus"
                        level={0}
                        iconPosition="left"
                        onClick={() =>
                          arrayHelpers.push(createNewSchedule(formikProps))
                        }
                      >
                        {t('Add rebuild schedule')}
                      </Button>
                    </div>
                  </>
                )}
              />
            </div>
            <Text element="p" size="echo">
              {t('Limits')}
            </Text>
            <div className="importer-config__limits">
              <TextInput
                onWhiteGround
                style={{ width: 250 }}
                label={t('Maximum runtime for a rebuild (seconds)')}
                placeholder={t('Enter...')}
                type="number"
                onChange={onChangeTimeout(formikProps)}
                value={formikProps.values.otherSchedules[0]?.timeout}
              />
              <TextInput
                onWhiteGround
                style={{ width: 250 }}
                label={t('Maximum batch size for import')}
                disabled
                value={formikProps.values.runnerCountMax}
              />
            </div>
            <Text element="p" size="echo">
              {t('Notifications for errors')}
            </Text>

            <div className="importer-config__noti">
              <Text className="importer-config__guide-text" size="bravo">
                {t(
                  'We will only send out notifications if one of the importers cannot recover for more than 10 minutes \n or if the maximum execution time for an import is reached.'
                )}
              </Text>
              <div className="importer-config__noti-row">
                <Switch
                  title={
                    <div className="text-input__label text-input__label--white-ground">
                      {t('Notify')}
                    </div>
                  }
                  checked={formikProps.values.notificationEnabled}
                  onChange={(e) =>
                    formikProps.setFieldValue('notificationEnabled', e)
                  }
                />
                <TextInput
                  onWhiteGround
                  style={{ width: 300 }}
                  label={t('Send notifications to')}
                  placeholder={t('Enter email…')}
                  value={formikProps.values.notificationMails.join(',')}
                  onChange={(e) =>
                    formikProps.setFieldValue(
                      'notificationMails',
                      e.target.value.split(',')
                    )
                  }
                  description={t(
                    'Multiple e-mail addresses separated by comma'
                  )}
                />
              </div>
            </div>
          </ContentWrapper>
        </>
      )}
    </Formik>
  )
}

export default observer(Edit)
