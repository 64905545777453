import classNames from 'classnames'
import Text from '../../../components/Text'

import './matchingText.styl'

export default function MatchingText({ searching, label, className, ...rest }) {
  const regex = new RegExp(searching, 'gi')
  const matched = label.match(regex)

  if (matched && matched.length > 0) {
    const text = matched.reduce((prev, curr) => {
      return prev.replace(curr, `<b>${curr}</b>`)
    }, label)
    return (
      <Text {...rest} className={classNames('matching-text', className)}>
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </Text>
    )
  }
  return <Text {...rest}>{label}</Text>
}
