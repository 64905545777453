import React from 'react'
import { Spin } from 'antd'

function ContentWrapper(props) {
  return (
    <div className={`content-wrapper ${props.className}`}>
      {props.children}
      {props.loading && (
        <div className="content-wrapper__loading">
          <Spin size="large" />
        </div>
      )}
    </div>
  )
}

export default ContentWrapper
