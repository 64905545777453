import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'
import Icon from '../../Icon'
import ShadowScroll from '../../ShadowScroll'
import Text from '../../Text'
import TextInput from '../../TextInput'
import { FilterSectionStore } from '../../../stores'
import { t } from '../../../utils'
import { ICON_TYPE, SUPPORTED_FIELD_TYPES } from '../constants'

const ChooseField = ({ fieldOptions }) => {
  const { editingFilter, setEditingFilter } = FilterSectionStore

  const [selectedType, selectType] = useState('')
  const [search, setSearch] = useState('')

  const filteredList = useMemo(
    () =>
      fieldOptions.filter(
        (field) =>
          (field.type === selectedType || !selectedType) &&
          ((field?.title &&
            field.title.toLowerCase().includes(search.toLowerCase())) ||
            !search)
      ),
    [selectedType, search, fieldOptions]
  )

  return (
    <div className="filter-section__choose-product-wrapper">
      <Text weight="bold" size="delta">
        {t('Choose a product data field')}
      </Text>
      <div className="filter-section__choose-product">
        <div className="filter-section__choose-product-section">
          <Text className="filter-section__choose-product-title">
            {t('Display') + ' ...'}
          </Text>
          <div
            onClick={() => selectType('')}
            className={classNames('filter-section__filter-tag', {
              'filter-section__filter-tag--active': !selectedType,
            })}
          >
            <Icon width="16px" height="16px" symbol="star" />
            <Text weight="bold">{t('All')}</Text>
            <Icon width="16px" height="16px" symbol="chevron-right" />
          </div>
          <Text className="filter-section__choose-product-title">
            {t('or just of type')}
          </Text>
          <div className="filter-section__filter-tag-wrapper">
            {SUPPORTED_FIELD_TYPES.map((type) => (
              <div
                onClick={() => selectType(type)}
                key={type}
                className={classNames('filter-section__filter-tag', {
                  'filter-section__filter-tag--active': selectedType === type,
                })}
              >
                <Icon
                  width="16px"
                  height="16px"
                  symbol={ICON_TYPE[type] || 'question'}
                />
                <Text weight="bold">{t(type)}</Text>
                <Icon width="16px" height="16px" symbol="chevron-right" />
              </div>
            ))}
          </div>
        </div>
        <div className="filter-section__choose-product-section">
          <Text className="filter-section__choose-product-title">
            {t('Fields to choose from ...')}
          </Text>
          <TextInput
            icon="magnifying-glass"
            placeholder={t('Find a field')}
            rounded
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <ShadowScroll maxHeight="410px">
            {filteredList.map((field) => (
              <div
                onClick={() =>
                  setEditingFilter({ field: field.id, type: field.type })
                }
                key={field.id}
                className={classNames('filter-section__field', {
                  'filter-section__field--active':
                    field.id === editingFilter.field,
                })}
              >
                <Icon
                  width="16px"
                  height="16px"
                  symbol={ICON_TYPE[field.type] || 'question'}
                />
                <Text weight="bold">{field.title}</Text>
              </div>
            ))}
          </ShadowScroll>
        </div>
      </div>
    </div>
  )
}

export default observer(ChooseField)
