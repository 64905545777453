import csx from 'classnames'

import Text from '../../../components/Text'
import { UIStore } from '../../../stores'

import './timeline.styl'

const isHeaderShown = (header, index, amount, type) => {
  if (!header) return false
  if (type !== 'day') return true

  // First and last day will be displayed
  if (index === 0 || index + 1 === amount) return true

  const firstDayOfMonth = 1
  const middleOfMonth = Math.round(header.date.daysInMonth() / 2)
  const firstQuarterOfMonth = Math.round(header.date.daysInMonth() * 0.25)
  const lastQuarterOfMonth = Math.round(header.date.daysInMonth() * 0.75)

  return [
    firstDayOfMonth,
    firstQuarterOfMonth,
    middleOfMonth,
    lastQuarterOfMonth,
  ].includes(header.date.date())
}

const isMonthShown = (header, index, type) => {
  if (type !== 'day') return false

  if (index === 0) return true

  return header.date.date() === 1
}

const HeaderColumns = ({ headers = [], type = '' }) => {
  const monthFormatter = new Intl.DateTimeFormat(UIStore.currentLanguage, {
    month: 'long',
    timeZone: 'UTC',
  })

  return headers.map((header, index) => {
    if (!isHeaderShown(header, index, headers.length, type)) return null

    return (
      <div
        className={csx('timeline-header', {
          'timeline-header--multi-line': type === 'day',
        })}
        style={{ left: header.left }}
        key={header.key}
      >
        {isMonthShown(header, index, type) && (
          <Text
            className="timeline-header__text timeline-header__text--primary"
            variant="book"
          >
            {monthFormatter.format(header.date)}
          </Text>
        )}
        <Text
          className="timeline-header__text timeline-header__text--secondary"
          variant="book"
        >
          {header.title}
        </Text>
        <div className="timeline-header__line" />
      </div>
    )
  })
}

export default HeaderColumns
