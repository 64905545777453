import Icon from '../Icon'
import Spinner from '../Spinner'
import Text from '../Text'
import './badge.styl'

const Badge = ({ variant = 'secondary', text, icon, spinner, onClick }) => (
  <div
    onClick={onClick}
    className={`badge ${!text ? 'badge--icon-only' : ''} badge--${variant}`}
  >
    {spinner && icon && <Spinner size="small" icon={icon} />}
    {!spinner && icon && <Icon width={15} height={15} symbol={icon} />}
    {text && <Text>{text}</Text>}
  </div>
)

export default Badge
