import { useEffect, useRef } from 'react'

/**
 * This custom hook is designed to intentionally skip the initial
 * render phase of the useEffect-Hook.
 */
export default function useDidMountEffect(func, deps) {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      func()
    } else {
      didMount.current = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
