import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/publicfield?${queryString}`

    return fetch(target)
  },

  async create(field) {
    return fetch('/publicfield', {
      method: 'POST',
      body: JSON.stringify(field),
    })
  },

  async update(field) {
    return fetch(`/publicfield/${field.id}`, {
      method: 'PUT',
      body: JSON.stringify(field),
    })
  },

  async delete(id) {
    await fetch(`/publicfield/${id}`, { method: 'DELETE' })
  },
}
