import React from 'react'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import { observer } from 'mobx-react-lite'

const ValueItem = (
  { option, listeners, attributes, hidden, isOverlay, field, ...props },
  ref
) => {
  return (
    <div {...props} ref={ref} className="category-filter__sort-modal-item">
      <button {...listeners}>
        <Icon symbol="bars" width={15} height={15} />
      </button>
      <Text weight="bold" className="value">
        {field.title}
      </Text>
    </div>
  )
}

export default observer(ValueItem, { forwardRef: true })
