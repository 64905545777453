import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Column, ArrowAction } from '../../components/Table'
import BannerStore from '../../stores/BannerStore'
import ResourceTable from '../../components/ResourceTable'
import { useHistory } from 'react-router-dom'
import ContentWrapper from '../../components/ContentWrapper'
import Text from '../../components/Text'
import DownloadAnalyticsButton from '../../components/DownloadAnalyticsButton'
import t from '../../utils/translate'
import UIStore from '../../stores/UIStore'
import ActionLayerStore from '../../stores/ActionLayerStore'
import createRedirectPath from '../../utils/createRedirectPath'
import Icon from '../../components/Icon'
import { formatScheduleDate, getResourceTableFilters } from './utils'
import ActiveField from '../../components/ActiveField'
import './list.styl'

function BannerList() {
  const history = useHistory()
  const { languages } = UIStore
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    BannerStore.fetchBanners()
  }, [])

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    await BannerStore.delete(selectedRows)

    if (BannerStore.state !== 'error') {
      setSelectedRows([])
      ActionLayerStore.closeActionLayer()
    }
  }

  return (
    <>
      <ContentWrapper className="banner-list">
        <ResourceTable
          title={t('Werbebanner')}
          store={BannerStore}
          resourceName="banners"
          loading={BannerStore.state === 'pending'}
          emptyText={t(
            'Nothing found - just create a new banner or reset your search & filters above.'
          )}
          filters={getResourceTableFilters(BannerStore, languages)}
          filterParams="filter"
          bulkActions={[
            {
              title: t('Delete'),
              onExecute: () => showDeleteWarning(),
            },
          ]}
          rowSelection={{
            selectedRows,
            onSelectionChange: (newRows) => setSelectedRows(newRows),
          }}
          onRowClick={(record) =>
            history.push(createRedirectPath(`/banner/${record.id}`))
          }
          hideEmptyTextWhenNoFilters
          headerAction={{
            type: 'create',
            action: () => history.push(createRedirectPath('/banner/new')),
          }}
          createActionText={t('Add banner')}
          createAction={() => history.push(createRedirectPath('/banner/new'))}
        >
          <Column
            width={70}
            title={t('Active')}
            dataIndex="active"
            align="center"
            render={(value) => <ActiveField active={value} />}
            sorter={true}
          />
          <Column
            title={t('Name (ID)')}
            render={(_, record) => (
              <>
                <span className="text text--bravo text--medium text--regular">
                  {record.name}&nbsp;
                  <span className="banner-list__text-grey">{record.id}</span>
                </span>
              </>
            )}
            dataIndex="name"
            sorter={true}
          />
          <Column
            className="banner-list__listType"
            title={t('Displayed on')}
            dataIndex="listType"
            sorter={true}
          />
          <Column
            className="banner-list__language"
            title={t('Language')}
            dataIndex="language"
            sorter={true}
          />
          <Column
            title={t('Schedule start')}
            render={(_, record) => {
              const { textFrom, iconFrom, classFrom } =
                formatScheduleDate(record)
              return (
                <div className="banner-list__schedule-date">
                  <Icon className={classFrom} symbol={iconFrom} size="1x" />
                  <Text className={classFrom}>{textFrom}</Text>
                </div>
              )
            }}
          />
          <Column
            title={t('Schedule end')}
            render={(_, record) => {
              const { textTo, iconTo, classTo } = formatScheduleDate(record)
              return (
                <div className="banner-list__schedule-date">
                  <Icon className={classTo} symbol={iconTo} size="1x" />
                  <Text className={classTo}>{textTo}</Text>
                </div>
              )
            }}
          />
          <Column
            width={50}
            onCell={() => {
              return {
                onClick: (event) => {
                  event.stopPropagation()
                },
              }
            }}
            render={(_, record) => (
              <DownloadAnalyticsButton record={record} type="banner" />
            )}
          />
          <Column width={50} render={() => <ArrowAction />} />
        </ResourceTable>
      </ContentWrapper>
    </>
  )
}

export default observer(BannerList)
