import React from 'react'
import { observer } from 'mobx-react-lite'
import { ComponentStore } from '../../../stores'
import { t, getPreviewValue } from '../../../utils'
import ReactJson from 'react-json-view'
import Text from '../../../components/Text'

function getPreviewValueForComponent(fields = []) {
  return fields.reduce((acc, currentField) => {
    return {
      ...acc,
      ...getPreviewValue(currentField),
    }
  }, {})
}

const DataStructure = ({ middleSection }) => {
  const fields = ComponentStore.getFieldsTree(true)
  const previewData = getPreviewValueForComponent(fields)
  const shouldShow = !!fields.length

  if (middleSection !== 'data-structure') return null

  return (
    <div className="component-editor__data-structure">
      {shouldShow ? (
        <>
          <Text
            className="component-editor__data-structure__text"
            element="div"
          >
            JSON
          </Text>
          <ReactJson
            src={previewData}
            name={false}
            theme={'paraiso'}
            iconStyle={'circle'}
            sortKeys={true}
            collectionLimit={200}
            collapsed={false}
            collapseStringsAfterLength={28}
            groupArraysAfterLength={5}
            indentWidth={4}
            enableClipboard={true}
            onEdit={false}
            onDelete={false}
            onAdd={false}
            onSelect={false}
          />
        </>
      ) : (
        <Text
          className="component-editor__data-structure__placeholder"
          element="div"
        >
          {t('No fields created yet.')}
        </Text>
      )}
    </div>
  )
}

export default observer(DataStructure)
