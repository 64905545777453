import { observer } from 'mobx-react-lite'
import * as Yup from 'yup'
import { t } from '../../utils'
import Modal from '../../components/Modal'
import TextInput from '../../components/TextInput'
import { PersonasStore } from '../../stores'
import Button from '../../components/Button'
import { useEffect, useState } from 'react'
import { get } from 'lodash'

const personaSchema = Yup.object().shape({
  name: Yup.object().shape({
    de: Yup.string().required(t('Required!')),
    en: Yup.string().required(t('Required!')),
  }),
  description: Yup.object().shape({
    de: Yup.string().required(t('Required!')),
    en: Yup.string().required(t('Required!')),
  }),
  identifier: Yup.string().required(t('Required!')),
  updatedBy: Yup.string().required(t('Required!')),
})

function EditPersonasModal(props) {
  const { visible, closeModal, id } = props

  const [errors, setErrors] = useState(null)
  const [isSubmited, setSubmited] = useState(false)
  const [loading, setLoading] = useState(false)

  const onChangeData = (name, value) => {
    PersonasStore.onChangeDetail(name, value)
    if (isSubmited) {
      validate(PersonasStore.detail)
    }
  }

  useEffect(() => {
    if (!visible) {
      setErrors(null)
      setSubmited(false)
      setLoading(false)
    } else {
      if (id && PersonasStore.detail.id !== id) {
        PersonasStore.getById(id)
      }
    }
  }, [visible, id])

  const validate = (data) => {
    try {
      personaSchema.validateSync(data, { abortEarly: false })
      setErrors(null)
      return true
    } catch (error) {
      const errors = error.inner.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.path]: curr.message,
        }
      }, {})
      setErrors(errors)
    }
  }

  const onSubmitRequest = async () => {
    setSubmited(true)
    if (validate(PersonasStore.detail)) {
      setLoading(true)
      await PersonasStore.updateOrCreate()
      setLoading(false)
      PersonasStore.fetchPersonas()
      closeModal()
    }
  }

  if (!visible) return null

  const Footer = () => (
    <>
      <Button
        type="button"
        key="close"
        iconPosition="left"
        icon="xmark"
        onClick={closeModal}
      >
        {t('Cancel')}
      </Button>
      <Button
        loading={loading}
        onClick={onSubmitRequest}
        type="button"
        variant="primary"
        icon="check"
        key="save"
      >
        {id ? t('Update') : t('Create')}
      </Button>
    </>
  )

  return (
    <Modal
      visible={visible}
      onClose={closeModal}
      header={id ? t('Edit persona') : t('Create new persona')}
      mask
      maskClosable={false}
      className="general-settings__edit-personas-modal"
      footer={<Footer />}
      width={1000}
    >
      <div className="action-content">
        <TextInput
          label={t('Identifier')}
          value={get(PersonasStore, 'detail.identifier')}
          onChange={(e) => onChangeData('identifier', e.target.value)}
          error={errors?.identifier}
        />
        {['de', 'en'].map((language) => (
          <div key={language} className="general-settings__edit-personas-row">
            <TextInput
              label={`${t('Name')} (${language.toUpperCase()})`}
              value={get(PersonasStore, 'detail.name.' + language)}
              onChange={(e) => onChangeData(['name', language], e.target.value)}
              error={errors && errors[`name.${language}`]}
            />
            <TextInput
              label={`${t('Description')} (${language.toUpperCase()})`}
              value={get(PersonasStore, 'detail.description.' + language)}
              onChange={(e) =>
                onChangeData(['description', language], e.target.value)
              }
              error={errors && errors[`description.${language}`]}
            />
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default observer(EditPersonasModal)
