import Icon from '../Icon'
import { Tooltip } from 'antd'
import t from '../../utils/translate'
import Select from '../Select'

const HeaderColumn = ({
  sorter = false,
  title = '',
  dataIndex = '',
  onSort = () => {},
  currentSorter: { field, order } = {},
  customSortName = '',
  options,
  onChangeSelect,
  selectedValue,
}) => {
  if (!sorter)
    return (
      <div className="mk-table__header-column">
        <span>{title}</span>
        {options && (
          <Select
            options={options}
            onChange={onChangeSelect}
            value={selectedValue}
          />
        )}
      </div>
    )

  const currentFieldSorted =
    customSortName.length > 0 ? customSortName === field : dataIndex === field

  let newSortOrder = 'ascend'

  if (currentFieldSorted && order === 'ascend') {
    newSortOrder = 'descend'
  }

  if (!currentFieldSorted) {
    return (
      <Tooltip
        placement="topLeft"
        title={t(`Click to sort ${newSortOrder}ing`)}
      >
        <div
          className="mk-table__header-column mk-table__header-column--sortable"
          data-cy={`sort-${dataIndex}`}
          onClick={() =>
            onSort(
              customSortName.length > 0 ? customSortName : dataIndex,
              newSortOrder
            )
          }
        >
          <span>{title}</span>
        </div>
      </Tooltip>
    )
  } else {
    return (
      <Tooltip
        placement="topLeft"
        title={t(`Click to sort ${newSortOrder}ing`)}
      >
        <div
          className="mk-table__header-column mk-table__header-column--sortable mk-table__header-column--sorted"
          data-cy={`sort-${dataIndex}`}
          onClick={() =>
            onSort(
              customSortName.length > 0 ? customSortName : dataIndex,
              newSortOrder
            )
          }
        >
          <span>{title}</span>
          {order === 'ascend' ? (
            <Icon symbol="angle-up" />
          ) : (
            <Icon symbol="angle-down" />
          )}
        </div>
      </Tooltip>
    )
  }
}

export default HeaderColumn
