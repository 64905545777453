export default function SearchRedirect(languages = [], searchTerm = {}) {
  return {
    documentId: '',
    targetUrl: languages.reduce((current, lang) => {
      return {
        ...current,
        [lang]: '',
      }
    }, {}),
    type: 'url',
    words: languages.reduce((current, lang) => {
      return {
        ...current,
        [lang]: searchTerm.language === lang ? [searchTerm.searchTerm] : [],
      }
    }, {}),
  }
}
