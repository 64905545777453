import common from './common'
import configuration from './configuration'
import fields from './fields'
import searchFields from './searchFields'
import synonyms from './synonyms'
import antonyms from './antonyms'
import searchableLinks from './searchableLinks'
import banners from './banners'
import redirects from './redirects'
import searchRedirects from './searchRedirects'
import placements from './placements'
import filters from './filters'
import categories from './categories'
import streams from './streams'
import feeds from './feeds'
import experiments from './experiments'
import recommendations from './recommendations'
import components from './components'
import menu from './menu'
import pages from './pages'
import publicFields from './publicFields'
import indices from './indices'
import monitoring from './monitoring'
import aggregation from './aggregation'
import manufacturer from './manufacturer'
import bundles from './bundles'
import importer from './importer'
import installation from './installation'
import notifications from './notifications'
import audit from './audit'
import apps from './apps'
import storefrontSettings from './storefrontSettings'
import userManagement from './userManagement'
import rankingMix from './rankingMix'
import enterprise from './enterprise'
import abtests from './abtests'
import secret from './secret'
import rolesManagement from './roles'
import permission from './permission'

export default {
  aggregation,
  common,
  configuration,
  fields,
  searchFields,
  synonyms,
  antonyms,
  searchableLinks,
  banners,
  redirects,
  searchRedirects,
  placements,
  filters,
  categories,
  streams,
  feeds,
  experiments,
  recommendations,
  components,
  menu,
  pages,
  publicFields,
  indices,
  monitoring,
  manufacturer,
  bundles,
  importer,
  installation,
  notifications,
  audit,
  apps,
  storefrontSettings,
  rankingMix,
  abtests,
  userManagement,
  enterprise,
  secret,
  rolesManagement,
  permission,
}
