import ConfigurationPanel, {
  Panel,
} from '../../../components/ConfigurationPanel'
import t from '../../../utils/translate'
import * as Yup from 'yup'
import { Formik, getIn } from 'formik'
import { observer } from 'mobx-react-lite'

import Text from '../../../components/Text'
import Icon from '../../../components/Icon'
import icons from './icons'
import TextInput from '../../../components/TextInput'
import Select from '../../../components/Select'
import Switch from '../../../components/Switch'

function prepareSchema() {
  return Yup.object().shape({
    name: Yup.string().required(t('Please insert name.')),
    identifier: Yup.string().required(t('Please insert identifier.')),
    icon: Yup.string().nullable(),
    active: Yup.string().nullable(),
    deprecated: Yup.string().nullable(),
  })
}

const ComponentEditorSettings = ({
  show = false,
  initialValues = {},
  setFieldValue,
  formikRef,
}) => {
  // const handleSubmit = async (values) => {
  //   await formikRef.current?.submitForm()
  //   if (formikRef.current?.isValid) {

  //   }
  // }

  return (
    <ConfigurationPanel active={show} defaultActive="settings">
      <Panel header={t('Settings')} key="settings">
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          // onSubmit={handleSubmit}
          validationSchema={prepareSchema()}
        >
          {({ errors }) => {
            return (
              <div className="component-editor__settings">
                <div className="component-editor__settings__switch-group">
                  <Switch
                    defaultChecked={initialValues.active}
                    name="active"
                    onChange={(checked) => setFieldValue('active', checked)}
                    type="horizontal"
                    title={t('Activation')}
                  />
                  <Switch
                    defaultChecked={initialValues.deprecated}
                    name="deprecated"
                    onChange={(checked) => setFieldValue('deprecated', checked)}
                    type="horizontal"
                    title={t('Deprecated')}
                  />
                </div>
                <TextInput
                  label={t('Name')}
                  defaultValue={initialValues.name}
                  name="name"
                  error={getIn(errors, 'name')}
                  onChange={(e) => setFieldValue('name', e.target.value)}
                />
                <TextInput
                  label={t('Identifier')}
                  defaultValue={initialValues.identifier}
                  name="identifier"
                  error={getIn(errors, 'identifier')}
                  onChange={(e) => setFieldValue('identifier', e.target.value)}
                />
                <Select
                  title={t('Icon')}
                  defaultValue={initialValues.icon}
                  name="icon"
                  dropdownClassName="component-editor-select__dropdown"
                  options={icons.map(({ id, name }) => ({
                    value: id,
                    label: (
                      <div className="component-editor__settings__icons-options">
                        <Icon
                          makairaIcon={true}
                          symbol={id}
                          width={80}
                          height={35}
                        />
                        <Text>{name}</Text>
                      </div>
                    ),
                  }))}
                  error={getIn(errors, 'icon')}
                  onChange={(val) => setFieldValue('icon', val)}
                />
              </div>
            )
          }}
        </Formik>
      </Panel>
    </ConfigurationPanel>
  )
}

export default observer(ComponentEditorSettings)
