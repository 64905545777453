import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async get() {
    return fetch('/configuration')
  },

  async update(configuration, abTestingId) {
    const headers = {}
    if (abTestingId) {
      headers['x-makaira-scenario'] = abTestingId
    }
    return fetch('/configuration', {
      method: 'PUT',
      headers,
      body: JSON.stringify(configuration),
    })
  },
}
