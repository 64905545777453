import { makeAutoObservable } from 'mobx'
import { isBoolean } from 'lodash'
import { toast } from 'react-toastify'
import api from '../../api'
import { t } from '../../utils'

class SearchFieldStore {
  searchFields = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = null
  state = 'pending' // "pending", "done" or "error"

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchSearchFields()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchSearchFields()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchSearchFields()
  }

  getById(id) {
    return this.searchFields.find((s) => s.id == id)
  }

  *fetchSearchFields() {
    this.state = 'pending'
    try {
      const { data, total } = yield api.searchFields.getAll({
        filter: this.filter,
      })

      this.searchFields = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the search field listing.')
      this.state = 'error'
    }
  }

  *updateOrCreate(stream, refetch) {
    if (!isBoolean(refetch)) refetch = true
    try {
      if (stream.id) {
        yield api.searchFields.update(stream)
      } else {
        yield api.searchFields.create(stream)
        toast.success(t('Search field created'))
      }
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
    if (refetch) {
      this.fetchSearchFields()
    }
  }

  updateField(field, key, value, refetch) {
    field[key] = value
    this.updateOrCreate(field, refetch)
  }

  *delete(id) {
    try {
      yield api.searchFields.delete(id)
      toast.success(t('Search field deleted'))
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchSearchFields()
  }

  addNewField() {
    this.searchFields.push({ active: true, boostFactor: 1, field: '' })
  }
}

export default new SearchFieldStore()
