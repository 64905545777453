import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Text from '../Text'
import FilterList from './FilterList'
import FilterModal from './Modal'
import { FilterSectionStore } from '../../stores'
import { toJS } from 'mobx'
import { t } from '../../utils'

import './style.styl'

const FilterSection = ({
  title,
  type,
  onChange,
  defaultValue = [],
  hasInputProduct = false,
  required = false,
  buttonText,
  hasPlaceholder = true,
  disableEdit,
}) => {
  const [showModal, setShowModal] = useState(false)
  const {
    setMode,
    removeListFilter,
    setListFilter,
    setConfig,
    setEditingFilter,
    reset,
  } = FilterSectionStore

  useEffect(() => {
    if (defaultValue.length) {
      setListFilter(defaultValue, type)
    }
    setConfig(
      {
        hasInputProduct,
        required,
        hasPlaceholder,
      },
      type
    )
    return () => reset(type)
    // eslint-disable-next-line
  }, [defaultValue])

  return (
    <div className="filter-section__edit-section">
      {title && (
        <Text size="echo" weight="bold">
          {title}
        </Text>
      )}
      <FilterList
        type={type}
        onEdit={(filter) => {
          setMode('edit')
          setShowModal(true)
          setEditingFilter(filter)
        }}
        onDelete={(uuid) => {
          const list = removeListFilter(uuid, type)
          onChange && onChange(toJS(list))
        }}
        onCreate={() => {
          setMode('create')
          setEditingFilter({})
          setShowModal(true)
        }}
        createActionText={buttonText ? buttonText : t('Add Condition')}
        disableEdit={disableEdit}
      />
      <FilterModal
        type={type}
        visible={showModal}
        onClose={() => setShowModal(false)}
        onChange={onChange}
      />
    </div>
  )
}

export default observer(FilterSection)
