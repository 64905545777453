import { observer } from 'mobx-react-lite'

import PageTitle from '../../components/PageTitle'
import t from '../../utils/translate'
import { routes } from '../../routing'

const SearchPageTitle = () => {
  return (
    <PageTitle
      className="search-configuration__page-title"
      tabData={[
        {
          route: routes.searchMonitoring,
          title: t('Monitoring'),
        },
        {
          route: routes.searchConfiguration,
          title: t('Configuration'),
        },
        {
          route: routes.filterList,
          title: t('Filters'),
        },
        {
          route: routes.synonymsList,
          title: t('Synonyms'),
        },
        {
          route: routes.antonymsList,
          title: t('Antonyms'),
        },
        {
          route: routes.searchRedirectList,
          title: t('Redirects'),
        },
        {
          route: routes.searchableLinkList,
          title: t('Links'),
        },
      ]}
    />
  )
}

export default observer(SearchPageTitle)
