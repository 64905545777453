import { observer } from 'mobx-react-lite'

import PageTitle from '../../components/PageTitle'
import t from '../../utils/translate'
import { routes } from '../../routing'

const InspectorPageTitle = () => {
  return (
    <PageTitle
      tabData={[
        {
          route: routes.dataInspector,
          title: t('Search'),
        },
        {
          route: routes.streamInspector,
          title: t('Streams'),
        },
      ]}
    >
      {t('Data Inspector')}
    </PageTitle>
  )
}

export default observer(InspectorPageTitle)
