import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import Select from '../../../../components/Select'
import { UIStore } from '../../../../stores'
import { t } from '../../../../utils'

function FieldDocument({ fieldSettings, onChange }) {
  const { documentTypes, fetchDocumentTypes } = UIStore
  const { documentType } = fieldSettings

  useEffect(() => {
    if (documentTypes.length === 0) {
      fetchDocumentTypes()
    }
    // eslint-disable-next-line
  }, [])

  const onChangeDocument = (documentType) => {
    onChange({
      documentType,
    })
  }

  return (
    <>
      <Select
        title={t('Document')}
        value={documentType}
        options={documentTypes.map((type) => {
          return {
            label: t(type.title),
            value: type.id,
          }
        })}
        onChange={onChangeDocument}
      />
    </>
  )
}

export default observer(FieldDocument)
