import { observer } from 'mobx-react-lite'
import Text from '../../../components/Text'
import t from '../../../utils/translate'
import { RoleManagementStore } from '../../../stores'
import { toJS } from 'mobx'
import Button from '../../../components/Button'

const InstancesRoleColumn = ({
  instance,
  roles = [],
  truncate,
  selectEmail,
}) => {
  if (roles.length === 0) return null

  const mappingRole = roles
    .map((roleId) => {
      const role = RoleManagementStore.roles.find((item) => item.id === roleId)
      return role ? role.name : ''
    })
    .filter((roleName) => roleName !== '')
    .map((roleName) => {
      return roleName
        .replace('Makaira.', '')
        .replace(`${toJS(RoleManagementStore.rolePrefix)}.`, '')
    })

  return (
    <div>
      <Text style={{ margin: 0 }}>
        <b>{instance === '*' ? t('All instances') : instance}</b>:{' '}
        {mappingRole.length <= 3 && !truncate && mappingRole.join(', ')}
        {(mappingRole.length > 3 || truncate) && (
          <>
            {`${mappingRole.slice(0, 3).join(', ')}... `}
            <Button
              onClick={(e) => {
                e.stopPropagation()
                selectEmail()
              }}
              iconPosition="left"
            >
              {t('Show all')}
            </Button>
          </>
        )}
      </Text>
    </div>
  )
}

export default observer(InstancesRoleColumn)
