import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import FeedStore from '../../stores/FeedStore'
import StreamStore from '../../stores/StreamStore'
import t from '../../utils/translate'
import ResourceTable from '../../components/ResourceTable'
import { Column } from '../../components/Table'
import Text from '../../components/Text'
import { ActionLayerStore } from '../../stores'
import FileFormat from './components/FileFormat'
import './feeds.styl'
import URLField from './components/URLFIeld'
import LastUpdate from './components/LastUpdate'
import { createRedirectPath } from '../../utils'

function FeedsList() {
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    StreamStore.fetchAllStreams()
    FeedStore.fetchFeeds()
  }, [])

  const filters = [
    {
      type: 'search',
      title: t('Search'),
      name: 'q',
      onChange: FeedStore.setFilter,
      defaultValue: FeedStore.filter?.q,
    },
    {
      type: 'single-select',
      title: t('Filter by type'),
      placeholder: t('Select Type'),
      name: 'type',
      onChange: FeedStore.setFilter,
      options: [
        { label: t('All'), value: undefined },
        { label: 'CSV', value: 'csv' },
        { label: 'XML', value: 'xml' },
        { label: 'JSON', value: 'json' },
      ],
      value: FeedStore.filter?.type,
    },
  ]

  const handleDelete = async () => {
    await FeedStore.delete(selectedRows)

    if (FeedStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const handleDuplicate = async () => {
    await FeedStore.duplicate(selectedRows)

    if (FeedStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const showDuplicateWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('duplicate_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDuplicate,
      onClose: () => {},
    })
  }

  const bulkActions = [
    {
      title: t('Delete'),
      onExecute: () => showDeleteWarning(),
    },
    {
      title: t('Duplicate'),
      onExecute: () => showDuplicateWarning(),
    },
  ]

  const StreamName = observer(({ streamId }) => {
    return <Text>{StreamStore.getById(streamId)?.title}</Text>
  })

  return (
    <div className="content-wrapper feeds">
      <ResourceTable
        title={t('Feeds')}
        store={FeedStore}
        resourceName="feedsList"
        loading={FeedStore.state === 'pending'}
        headerAction={{
          type: 'create',
          action: () => history.push(createRedirectPath(`/feeds/new`)),
          children: t('Add feed'),
          iconPosition: 'left',
        }}
        filters={filters}
        bulkActions={bulkActions}
        rowSelection={{
          selectedRows,
          onSelectionChange: (newRows) => setSelectedRows(newRows),
        }}
        onRowClick={(record) =>
          history.push(createRedirectPath(`/feeds/${record.id}`))
        }
        noResultsText={t('No feed could be found.')}
        emptyText={t(
          'Create a Feed to generate a file of relevant articles to\nimport them into external sales portals (e.g. Google, Facebook etc).'
        )}
        createActionText={t('Add feed')}
        createAction={() => history.push(createRedirectPath('/feeds/new'))}
        hideEmptyComponentWhenEmpty={FeedStore.hideEmptyComponent}
      >
        <Column
          title={t('Name')}
          dataIndex="name"
          className="mk-table-cell--bold"
          sorter
        />
        <Column
          title={t('URL')}
          className="mk-table-cell--bold"
          render={(_, row) => <URLField data={row} />}
        />
        <Column
          title={t('File Format')}
          dataIndex="contentType"
          className="mk-table-cell--bold"
          render={(type) => (
            <Text>
              <FileFormat type={type} />
            </Text>
          )}
          sorter
        />
        <Column
          title={t('Stream used')}
          className="mk-table-cell--bold"
          dataIndex="stream"
          render={(streamId) => <StreamName streamId={streamId} />}
          sorter
        />
        <Column
          title={t('Last changed')}
          className="mk-table-cell--bold"
          dataIndex="changed"
          render={(_, row) => <LastUpdate data={row} />}
          sorter
        />
      </ResourceTable>
    </div>
  )
}

export default observer(FeedsList)
