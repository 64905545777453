import { useEffect } from 'react'
import SelectTags from '../../../components/SelectTags'
import { AppStore, UIStore } from '../../../stores'
import { t } from '../../../utils'

export function AppIntegration({ onChange, value }) {
  const { externalContentModals, fetchExternalApps } = AppStore
  const { currentLanguage } = UIStore

  useEffect(() => {
    if (externalContentModals.length === 0) {
      fetchExternalApps()
    }
    // eslint-disable-next-line
  }, [externalContentModals])

  return (
    <SelectTags
      label={t('App Support')}
      valueField="value"
      onChange={onChange}
      value={value}
      options={externalContentModals.map((app) => {
        return {
          label: app.title[currentLanguage] || app.title['en'] || app.title,
          value: app.slug,
        }
      })}
    />
  )
}
