import { observer } from 'mobx-react-lite'

import PageTitle from '../../../components/PageTitle'
import t from '../../../utils/translate'
import { routes } from '../../../routing'

const StoreFrontPageTitle = () => {
  return (
    <PageTitle
      prefix={t('You are configuring')}
      tabData={[
        {
          route: routes.storefrontSettings,
          title: t('Settings'),
        },
        {
          route: routes.storefrontLogs,
          title: t('Logs'),
        },
      ]}
    >
      {t('Storefront')}
    </PageTitle>
  )
}

export default observer(StoreFrontPageTitle)
