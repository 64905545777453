import { DatePicker as AntDatepicker } from 'antd'
import './datepicker.styl'

const { RangePicker } = AntDatepicker

export default function DatePicker(props) {
  const { title, ...rest } = props

  return (
    <div className="datepicker">
      {title && <div className="datepicker__label">{title}</div>}

      <AntDatepicker {...rest} />
    </div>
  )
}

function RangeDatePicker(props) {
  const { title, ...rest } = props

  return (
    <div className="datepicker">
      {title && <div className="datepicker__label">{title}</div>}

      <RangePicker {...rest} />
    </div>
  )
}

export { RangeDatePicker }
