import { observer } from 'mobx-react-lite'
import React from 'react'
import Switch from '../../../components/Switch'
import Text from '../../../components/Text'
import { RecommendationStore } from '../../../stores'
import { t } from '../../../utils'
import FilterSection from '../../../components/FilterSection'

const SortSection = ({ disableEdit }) => {
  const reco = RecommendationStore.currentRecoDetail

  return (
    <div className="recommendations__edit-section">
      <Text size="echo" weight="bold">
        {t('Sort products')}
      </Text>
      <div className="recommendations__sort-grid">
        <Switch
          type="horizontal"
          title={
            <div className="text-input__label">
              {t('Use Makaira Personalization')}
            </div>
          }
          checked={reco.isUseMachineLearning}
          onChange={(value) =>
            RecommendationStore.onChangeField(
              reco,
              'isUseMachineLearning',
              value
            )
          }
          disabled={disableEdit}
        />
        <Switch
          type="horizontal"
          title={
            <div className="text-input__label">{t('Diversify Results')}</div>
          }
          checked={reco.diversify}
          onChange={(value) =>
            RecommendationStore.onChangeField(reco, 'diversify', value)
          }
          disabled={disableEdit}
        />
        <Switch
          type="horizontal"
          title={
            <div className="text-input__label">{t('Use Ranking Mix')}</div>
          }
          checked={reco.isUseRankingMix}
          onChange={(value) =>
            RecommendationStore.onChangeField(reco, 'isUseRankingMix', value)
          }
          disabled={disableEdit}
        />
        <Switch
          type="horizontal"
          title={
            <div className="text-input__label">
              {t('Boost with similar attributes')}
            </div>
          }
          checked={reco.boostWithSimilarAttributes}
          onChange={(value) =>
            RecommendationStore.onChangeField(
              reco,
              'boostWithSimilarAttributes',
              value
            )
          }
          disabled={disableEdit}
        />
      </div>
      <FilterSection
        type="boosting"
        buttonText={t('Add boosting attribute')}
        hasInputProduct={true}
        defaultValue={reco?.boosting}
        onChange={(filters) =>
          RecommendationStore.onChangeField(reco, 'boosting', filters)
        }
        disableEdit={disableEdit}
      />
    </div>
  )
}

export default observer(SortSection)
