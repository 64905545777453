import { fetchUtil as fetch } from '../utils/fetchUtil'
import buildQueryString from '../utils/buildQueryString'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    return fetch(`/notifications?${queryString}`, {
      method: 'GET',
    })
  },

  async postReadAll() {
    return fetch('/notifications/read/all', {
      method: 'POST',
    })
  },
}
