import classNames from 'classnames'
import './text.styl'

function Text(props) {
  const {
    size = 'bravo',
    weight = 'medium',
    variant = 'regular', // or book
    className = '',
    element = 'span',
    error = false,
    ...rest // dangerouslySetInnerHTML etc.
  } = props

  const Element = element

  const classes = classNames(
    className,
    'text',
    `text--${size}`,
    `text--${weight}`,
    `text--${variant}`,
    {
      'text--error': error,
    }
  )

  return (
    <Element className={classes} {...rest}>
      {props.children}
    </Element>
  )
}

export default Text
