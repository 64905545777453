import { useState } from 'react'
import Select from '../../../../components/Select'
import NumberInput from '../../../../components/NumberInput'
import TextInput from '../../../../components/TextInput'
import SelectTags from '../../../../components/SelectTags'
import { t } from '../../../../utils'
import { observer } from 'mobx-react-lite'

function FieldSelect(props) {
  const { onChange, fieldSettings } = props
  const { type, options = [], defaultValue } = fieldSettings
  const [optionCount, setOptionCount] = useState(options?.length || 0)

  const onChangeOptionCount = (value) => {
    setOptionCount(value)
    if (options.length < value) {
      const newOptions = options.concat(
        new Array(value - options.length).fill({ value: '', label: '' })
      )
      onChange({
        options: newOptions,
      })
    } else if (options.length > value) {
      const newOptions = options.slice(0, value - options.length)
      onChange({
        options: newOptions,
      })
    }
  }

  const onChangeOptionValue = (changeIndex, field, value) => {
    const newOptions = options.map((option, index) => {
      if (index === changeIndex) {
        return {
          ...option,
          [field]: value,
        }
      }
      return option
    })
    onChange({
      options: newOptions,
    })
  }

  return (
    <>
      <NumberInput
        label={t('Number of options')}
        value={optionCount}
        min={0}
        onChange={onChangeOptionCount}
      />
      {options.length > 0 && (
        <table className="field-select__options">
          <thead>
            <tr>
              <th>{t('Label')}</th>
              <th>{t('Value')}</th>
            </tr>
          </thead>
          <tbody>
            {options.map((option, index) => {
              return (
                <tr key={index}>
                  <td>
                    <TextInput
                      value={option.label}
                      onChange={(value) =>
                        onChangeOptionValue(index, 'label', value.target.value)
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      value={option.value}
                      onChange={(value) =>
                        onChangeOptionValue(index, 'value', value.target.value)
                      }
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {options.length > 0 && type === 'multiselect' && (
        <SelectTags
          label={t('Default value (optional)')}
          options={options.filter((option) => option.value && option.label)}
          onChange={(value) => {
            onChange({
              defaultValue: value,
            })
          }}
          value={defaultValue}
          valueField="value"
        />
      )}
      {options.length > 0 && type === 'select' && (
        <Select
          title={t('Default value (optional)')}
          options={options.filter((option) => option.value && option.label)}
          onChange={(value) => {
            onChange({
              defaultValue: value,
            })
          }}
          defaultValue={defaultValue}
        />
      )}
    </>
  )
}

export default observer(FieldSelect)
