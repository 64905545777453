import AutoComplete from '../AutoComplete'
import operators from './operators/config'

export default function OperatorSelect(props) {
  const {
    name,
    condition: { field, operator, type },
    formikProps,
  } = props
  const { setFieldValue } = formikProps

  if (!field) return null

  return (
    <AutoComplete
      placeholder="Select field..."
      onSelect={(v) => setFieldValue(name, v)}
      value={operator}
      defaultOptions={operators[type]}
      translateLabel
    />
  )
}
