import React from 'react'
import { observer } from 'mobx-react-lite'

import checkeredBoard from '../../assets/svgs/checkered-board-1.svg'

const ImagePreview = (props) => {
  const { picture_url_main } = props

  return (
    <img
      className="data-inspector__preview-image"
      style={{ backgroundImage: `url("${checkeredBoard}")` }}
      src={picture_url_main}
    />
  )
}

export default observer(ImagePreview)
