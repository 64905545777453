import { makeAutoObservable } from 'mobx'
import { UIStore } from '..'
import api from '../../api'
import { toast } from 'react-toastify'
import moment from 'moment'
import 'moment/locale/de'
import get from 'lodash/get'
import ABTestStore from '../ABTestStore'

class PersonalizationStore {
  state = 'pending' // "pending", "done" or "error"

  status = null

  products = []
  productsCount = 0
  previewPage = 0
  MAX_PRODUCTS_IN_PREVIEW = 20

  options = {
    user: 0,
    type: 'product',
    searchPhrase: '',
    categoryId: '',
    withML: true,
  }
  trafficPercent = '50'
  graph = {}
  isLoadingTrial = false
  isRunningExperiment = false
  isFetchingProducts = false
  effect = {}

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetchPersonalizationStatus(initial) {
    initial = initial || true

    this.state = initial ? 'pending' : 'pending-manual'
    try {
      const { data } = yield api.common.getMachineLearningStatus()
      this.status = data
      ABTestStore.disableStartTest(data.allPassed.result === false)

      this.state = 'done'
    } catch (e) {
      this.status = null
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  get isPersonalizationPossible() {
    return this.status?.trackingIdSet.result
  }

  get hasRunSuccessfulBefore() {
    return (
      this.status?.machineLearningLastSuccessfulCalc?.result &&
      !this.status?.allPassed?.result
    )
  }

  get featureInfo() {
    return UIStore.getFeatureInfo('machine_learning') || {}
  }

  get trialDaysLeft() {
    const date = this.featureInfo.expires

    if (!date) return null

    let momentDate = moment.utc(date.date).local()
    if (date.timezone !== 'UTC') {
      momentDate = moment(date.date).local()
    }

    return momentDate.diff(moment(), 'days')
  }

  *fetchSearchResult(search, changedVariable) {
    const {
      searchValue,
      previewType,
      categoryId,
      withMachineLearning,
      page,
      user,
      weather,
    } = search
    this.isFetchingProducts = true

    if (
      (previewType === 'product' && !searchValue) ||
      (previewType === 'category' && !categoryId)
    ) {
      this.products = []
      this.productsCount = 0
      this.isFetchingProducts = false
      this.previewPage = 0
      return
    }

    let updatedPreviewPageIndex = page

    // We need to reset the current page if the user changes the search query itself.
    if (
      changedVariable === 'previewType' ||
      changedVariable === 'searchValue' ||
      changedVariable === 'categoryId'
    ) {
      updatedPreviewPageIndex = 0
    }

    try {
      const profile = { ...user }
      delete profile.name
      const data = yield api.common.fetchSearchPreviewResult({
        profile,
        searchPhrase: searchValue,
        language: UIStore.languages[0],
        shopId: UIStore.shopId,
        type: previewType,
        categoryId,
        page: updatedPreviewPageIndex,
        withML: withMachineLearning,
        count: this.MAX_PRODUCTS_IN_PREVIEW,
        weather,
        useStock: true,
      })

      this.previewPage = updatedPreviewPageIndex
      this.products = data?.product?.items || []
      this.productsCount = data?.product?.total || 0
      this.isFetchingProducts = false
    } catch (e) {
      this.isFetchingProducts = false
      toast.error('Something went wrong...')
    }
  }

  get maxPreviewPage() {
    return Math.ceil(this.productsCount / this.MAX_PRODUCTS_IN_PREVIEW)
  }

  setOptions(key, value) {
    this.options[key] = value
    if (key === 'type') {
      this.options.searchPhrase = ''
      this.options.categoryId = ''
      this.products = []
      return
    }
    this.fetchSearchResult()
  }

  setTrafficPercent(percent) {
    this.trafficPercent = percent
  }

  *startTrialPeriod() {
    try {
      this.isLoadingTrial = true

      // book trial for machine_learning and ab_testing first
      const abTestingFeatureInfo = UIStore.getFeatureInfo('ab_testing') || {}
      if (abTestingFeatureInfo.state === 'not-booked') {
        yield UIStore.bookFeature('ab_testing')
      }
      if (this.featureInfo.state === 'not-booked') {
        yield UIStore.bookFeature('machine_learning')
      }

      const experiment = {
        title: 'Personalization',
        description:
          'Test Makaira Personalization against without Personalization',
        case: 'personalization',
        percentageScenario: Number(this.trafficPercent),
      }

      // Create A/B Experiment
      const { data } = yield api.experiments.create(experiment)
      // Disable machine learning for version A ("normal" configuration of makaira)
      yield api.configuration.update({ enableMachineLearning: 0 })
      // Enable machine learning for version B ("scenario" configuration of makaira)
      yield api.configuration.update({ enableMachineLearning: 1 }, data?.id)
      // Start Experiment
      yield api.experiments.start(data?.id)

      this.isLoadingTrial = false
      UIStore.fetchFeaturesInfo()
      this.fetchPersonalizationExperiment()
    } catch (error) {
      this.isLoadingTrial = false
      this.state = 'error'
      console.error(error)
      toast.error('Something went wrong...')
    }
  }

  *fetchPersonalizationExperiment() {
    try {
      this.state = 'pending'
      // Get running experiment
      const running = yield api.experiments.running()
      const runningId = running.data.id

      if (runningId) {
        // Get experiment to check if its ml
        const runningDetail = yield api.experiments.get(runningId)
        if (runningDetail.data.case === 'personalization') {
          // Get Experiment Data
          const runningResult = yield api.experiments.results(runningId)
          this.graph = runningResult.data.graph
          this.effect = {
            detected_effect: get(
              runningResult,
              'data.table.nb_orders_revenue.1.detected_effect'
            ),
            significance_rate: get(
              runningResult,
              'data.table.nb_orders_revenue.1.significance_rate'
            ),
          }
          this.isRunningExperiment = true

          this.state = 'done'
        } else {
          this.isRunningExperiment = false
          this.state = 'done'
        }
      } else {
        this.isRunningExperiment = false
        this.state = 'done'
      }
    } catch (error) {
      this.isRunningExperiment = false
      this.state = 'error'
      console.error(error)
      toast.error('Something went wrong...')
    }
  }
}

export default new PersonalizationStore()
