import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'

import Modal from '../../components/Modal'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import DashboardStore from '../../stores/DashboardStore'
import { t } from '../../utils'
import { getApps } from './constants'
import Button from '../../components/Button'
import { UIStore } from '../../stores'
import { handleOpenApp } from './AppItem'
import { flatten } from 'lodash'

function AppDetailModal() {
  const { closeDetailModal, appDetailModalVisible, selectedApp } =
    DashboardStore

  const history = useHistory()

  if (!appDetailModalVisible) return null

  const app =
    flatten(Object.values(getApps())).find(
      (item) => item.name === selectedApp
    ) || {}
  const appFeature = app.v1 ? app.requireFeature : app.route.requireFeature
  const featureInfo = UIStore.getFeatureInfo(appFeature)

  const getFeatureStatus = () => {
    if (appFeature && !featureInfo) return 'tried'

    if (featureInfo) {
      if (featureInfo.state === 'not-booked') return 'not_tried_yet'
      if (featureInfo.state === 'canceled' || featureInfo.state === 'trial')
        return 'tried'
    }
  }

  const featureStatus = getFeatureStatus()

  const handleBookApp = () => {
    if ((!featureInfo && !appFeature) || !featureStatus) {
      handleOpenApp(app, history)
      closeDetailModal()
    }

    if (featureStatus === 'not_tried_yet') {
      UIStore.bookFeature(featureInfo.name)
    }

    if (featureStatus === 'tried') {
      document.location.href =
        'mailto:sales@makaira.io?subject=' +
        encodeURIComponent(
          `Makaira Feature Booking for ${window.location.hostname}`
        ) +
        '&body=' +
        encodeURIComponent(
          `Dear Makaira,\rhereby I would like to book the feature ${
            featureInfo?.name || appFeature
          }\r\rKind regards`
        )
    }
  }

  return (
    <Modal
      visible={appDetailModalVisible}
      onClose={closeDetailModal}
      header={t(selectedApp)}
      mask
      maskClosable
      footer={[
        <Button
          onClick={handleBookApp}
          key="start-trial"
          variant="secondary-blocker"
          loading={UIStore.isBookingFeature}
        >
          {featureStatus === 'not_tried_yet' && t('Start 30 days trial')}
          {featureStatus === 'tried' && t('Book app')}
          {!featureStatus && t('Open app')}
        </Button>,
      ]}
    >
      <div className="dashboard__detail-modal">
        <Text variant="book" size="bravo">
          {t('Information about the app')}
        </Text>
        <Text weight="bold">{t(app.description)}</Text>
        <Text variant="book" size="bravo">
          {t('Detailed information')}
        </Text>
        <Text
          size="bravo"
          weight="bold"
          element="a"
          href={'https://docs.makaira.io/docs' + app.doc}
          rel="noreferrer"
          target="_blank"
        >
          docs.makaira.io <Icon symbol="arrow-up-right-from-square__solid" />
        </Text>
      </div>
    </Modal>
  )
}

export default observer(AppDetailModal)
