import get from 'lodash/get'
import last from 'lodash/last'
import moment from 'moment/moment'
import { toast } from 'react-toastify'
import { makeAutoObservable } from 'mobx'

import api from '../../api'
import t from '../../utils/translate'

class ImporterLogStore {
  logs = {
    messages: [],
    total: 0,
  }

  logFilters = {
    podName: '',
    limit: 50,
  }

  state = 'pending' // 'pending', 'done' or 'error'

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  resetLogFilter() {
    this.logFilters = {
      podName: '',
      limit: 50,
    }
  }

  setLogParams(filter) {
    this.logFilters = {
      ...this.logFilters,
      ...filter,
    }
    this.fetchLogs()
  }

  *fetchLogs() {
    console.debug('fetch logs', this.logFilters)
    this.state = 'pending'
    try {
      const { data } = yield api.importer.fetchLogs({ params: this.logFilters })

      this.logs.messages = data.messages
      this.logs.total = data.total

      this.state = 'done'
    } catch (error) {
      toast.error(t('Could not fetch the importer logs.'))
      this.state = 'error'
    }
  }

  *fetchMoreLogs() {
    console.debug('fetch more logs', this.logFilters)

    this.state = 'pending'
    try {
      const before = moment(get(last(this.logs.messages), 'timestamp'))
        .subtract(1, 'seconds')
        .toISOString()

      const { data } = yield api.importer.fetchLogs({
        params: { ...this.logFilters, before },
      })

      this.logs.total = data.total
      this.logs.messages = [...this.logs.messages, ...data.messages]
    } catch (error) {
      toast.error(t('Could not fetch the importer logs.'))
      this.state = 'error'
    }
  }
}

export default new ImporterLogStore()
