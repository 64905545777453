import { observer } from 'mobx-react-lite'
import classnames from 'classnames'

import Item from './Item'
import Button from '../Button'

import { getDefaultFieldState } from '../../utils'

import './repeatable.styl'

const Repeatable = (props) => {
  const { childConfig = {}, value = [], readOnly, onChange = () => {} } = props

  const handleRemoveItem = (itemIndex) => {
    const newFields = [...value]
    newFields.splice(itemIndex, 1)
    props.onChange(newFields)
  }

  const handleAddItem = () => {
    const defaultState = getDefaultFieldState(childConfig)
    // The getDefaultFieldState is generates the default state for components with a nested structure like
    // {"{uuid}": { ... defaultValues ... }}
    // We don't need this nested structure here, so we take only the first value out of it.
    const finalDefaultState = Object.values(defaultState)[0]

    const newField = [...value, finalDefaultState]
    onChange(newField)
  }

  const handleMoveDown = (index) => {
    // last item can not be moved more down
    if (index === value.length - 1) return

    const modifiedFields = [...value]

    // swap items
    const movedUpItem = modifiedFields[index + 1]
    modifiedFields[index + 1] = modifiedFields[index]
    modifiedFields[index] = movedUpItem

    onChange(modifiedFields)
  }

  const handleMoveUp = (index) => {
    // first item can not be moved more up
    if (index === 0) return

    const modifiedFields = [...value]

    // swap items
    const movedDownItem = modifiedFields[index - 1]
    modifiedFields[index - 1] = modifiedFields[index]
    modifiedFields[index] = movedDownItem

    onChange(modifiedFields)
  }

  return (
    <div
      className={classnames('repeatable-input', {
        'repeatable-input--object': childConfig.type === 'object',
      })}
    >
      {value.map((input, index) => (
        <Item
          handleRemoveItem={() => handleRemoveItem(index)}
          handleMoveUp={() => handleMoveUp(index)}
          handleMoveDown={() => handleMoveDown(index)}
          key={`${props.id}.${index}`}
          readOnly={readOnly}
          config={childConfig}
          {...input}
          id={`${props.id}.${index}`}
        />
      ))}

      {!readOnly && (
        <Button
          onClick={handleAddItem}
          variant="tertiary"
          icon="plus"
          level={1}
        />
      )}
    </div>
  )
}

export default observer(Repeatable)
