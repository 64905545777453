import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async get() {
    return fetch('/indices')
  },

  async activate(indexName) {
    return fetch('/indices/' + indexName + '/active', {
      method: 'PUT',
    })
  },

  async delete(indexName) {
    return fetch('/indices/' + indexName, {
      method: 'DELETE',
    })
  },
}
