import moment from 'moment-timezone'
import 'moment/locale/de'
import set from 'lodash/set'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { observer } from 'mobx-react-lite'
import { Tooltip } from 'antd'
import classNames from 'classnames'

import Checkbox from '../../../components/Checkbox'
import { RangeDatePicker } from '../../../components/DatePicker'
import Icon from '../../../components/Icon'

import t from '../../../utils/translate'

import UIStore from '../../../stores/UIStore'
import EditorStore from '../../../stores/PageEditor/EditorStore'

function PublishConfig(props) {
  const { config, disabled } = props
  const { name, identifier, description } = config || {}
  const { setValueForPublishConfig, getValueForPublishConfig } = EditorStore
  const configToEdit = getValueForPublishConfig(identifier) || {}
  const isActive = get(configToEdit, 'active')
  const isTimed = get(configToEdit, 'isTimed')
  let activeFrom = get(configToEdit, 'activeFrom')
  let activeTo = get(configToEdit, 'activeTo')

  activeFrom = activeFrom ? moment(activeFrom) : null
  activeTo = activeTo ? moment(activeTo) : null

  moment.updateLocale(UIStore.currentLanguage, {
    week: {
      dow: 1,
    },
  })

  const handleChange = (path, val) => {
    let data = cloneDeep(configToEdit)

    if (path === 'isTimed' && val === true) {
      set(data, 'activeFrom', '')
      set(data, 'activeTo', '')
    }

    set(data, path, val)

    setValueForPublishConfig(identifier, data)
  }

  const handleRangeChange = (dates) => {
    let data = cloneDeep(configToEdit)

    if (dates) {
      set(data, 'activeFrom', dates[0].toISOString())
      set(data, 'activeTo', dates[1].toISOString())
    } else {
      set(data, 'activeFrom', '')
      set(data, 'activeTo', '')
    }

    setValueForPublishConfig(identifier, data)
  }
  const descTooltip = description?.[UIStore.currentLanguage]
  return (
    <div className="page-editor-setting-modal__user-group">
      <div className="user-group__item">
        <Checkbox
          checked={isActive}
          label={
            name && typeof name === 'object'
              ? t(name[UIStore.currentLanguage])
              : t(name)
          }
          onChange={(e) => handleChange('active', e.target.checked)}
          disabled={disabled}
        />
        <Tooltip title={descTooltip} trigger="click">
          <Icon
            className={classNames('resource-table__infor-icon', {
              visible: !!descTooltip,
            })}
            symbol="circle-info__solid"
            width={20}
            height={20}
          />
        </Tooltip>
      </div>
      <div className="user-group__item">
        <Checkbox
          checked={isTimed}
          label={t('Time-controlled playout')}
          onChange={(e) => handleChange('isTimed', e.target.checked)}
          disabled={disabled}
        />
      </div>
      {isTimed && (
        <div className="time-control">
          <RangeDatePicker
            placeholder={[t('Active from'), t('to')]}
            value={[activeFrom, activeTo]}
            onChange={handleRangeChange}
            showTime
            format={
              UIStore.currentLanguage === 'de'
                ? 'DD.MM.YYYY HH:mm'
                : 'YYYY-MM-DD HH:mm'
            }
          />
        </div>
      )}
    </div>
  )
}

export default observer(PublishConfig)
