export default function prepareDataForChart(metric, data, segments) {
  let variationLegend = ''
  let chosenMetric = data[metric]
  if (chosenMetric === 'There is no enough data' || !chosenMetric) {
    const x = null
    let y = null
    return { x, y }
  } else {
    const originalData = Object.entries(chosenMetric).map(
      ([date, variants]) => {
        const x = date
        const y = variants['Original']
        return { x, y }
      }
    )
    const variationData = Object.entries(chosenMetric).map(
      ([date, variants]) => {
        const x = date
        let y = Object.values(variants)[5]
        variationLegend = Object.keys(variants)[5]
        return { x, y }
      }
    )

    const standardDevA1 = Object.entries(chosenMetric).map(
      ([date, variants]) => {
        const x = date
        const y = Object.values(variants)[0]
        return { x, y }
      }
    )

    const standardDevA2 = Object.entries(chosenMetric).map(
      ([date, variants]) => {
        const x = date
        const y = Object.values(variants)[1]
        return { x, y }
      }
    )

    const standardDevB1 = Object.entries(chosenMetric).map(
      ([date, variants]) => {
        const x = date
        const y = Object.values(variants)[2]
        return { x, y }
      }
    )

    const standardDevB2 = Object.entries(chosenMetric).map(
      ([date, variants]) => {
        const x = date
        const y = Object.values(variants)[3]
        return { x, y }
      }
    )

    if (segments === 3) {
      const standardDevC1 = Object.entries(chosenMetric).map(
        ([date, variants]) => {
          const x = date
          const y = variants['variantC']
          return { x, y }
        }
      )

      const standardDevC2 = Object.entries(chosenMetric).map(
        ([date, variants]) => {
          const x = date
          const y = variants['variantC1']
          return { x, y }
        }
      )

      const variationC = Object.entries(chosenMetric).map(
        ([date, variants]) => {
          const x = date
          const y = variants['variantC2']
          return { x, y }
        }
      )

      return {
        originalData,
        variationData,
        standardDevA1,
        standardDevA2,
        standardDevB1,
        standardDevB2,
        variationLegend,
        standardDevC1,
        standardDevC2,
        variationC,
      }
    }

    return {
      originalData,
      variationData,
      standardDevA1,
      standardDevA2,
      standardDevB1,
      standardDevB2,
      variationLegend,
    }
  }
}
