export default function Recommendation() {
  return {
    id: '',
    isActive: true,
    name: '',
    recommendationId: '',
    recommendationType: 'none',
    isPopulateTextSimilarity: false,
    filter: [],
    boosting: [],
    isUseMachineLearning: false,
    isUseRankingMix: false,
    randomness: false,
  }
}
