import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import CategoryStore from '../../stores/CategoryStore'
import StreamStore from '../../stores/StreamStore'
import t from '../../utils/translate'
import ResourceTable from '../../components/ResourceTable'
import ContentWrapper from '../../components/ContentWrapper'
import { ArrowAction, Column } from '../../components/Table'
import Text from '../../components/Text'
import Icon from '../../components/Icon'
import Hierarchy from './Hierarchy'
import { ActionLayerStore } from '../../stores'
import DetailModal from './DetailModal'

import './style.styl'

const categoryOptions = [
  { value: 'all', label: 'All Categories' },
  { value: 'notHiddenNotArchived', label: 'Not hidden and not archived' },
  { value: 'hidden', label: 'Hidden categories' },
  { value: 'archived', label: 'Archived categories' },
  { value: 'stream', label: 'Categories with streams' },
  { value: 'overrideAggregations', label: 'Categories with custom filter' },
]

const Stream = observer(({ value }) => {
  const stream = StreamStore.getById(value) || {}
  return <Text>{stream.title}</Text>
})

function CategoryList() {
  const [selectedRows, setSelectedRows] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [detailId, setDetailId] = useState(null)

  useEffect(() => {
    CategoryStore.fetchCategories()
    StreamStore.fetchAllStreams()
    CategoryStore.fetchAggregation()
  }, [])

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    await CategoryStore.delete(selectedRows)

    if (CategoryStore.state !== 'error') {
      setSelectedRows([])
      ActionLayerStore.closeActionLayer()
    }
  }

  return (
    <ContentWrapper className="category-list">
      <ResourceTable
        headerAction={{
          type: 'create',
          action: CategoryStore.sync,
          children: t('Synchronize Categories'),
          icon: 'arrows-rotate',
        }}
        title={t('Categories')}
        store={CategoryStore}
        resourceName="categories"
        showSearch
        loading={CategoryStore.state === 'pending'}
        filters={[
          {
            type: 'single-select',
            title: t('Type'),
            placeholder: t('Select'),
            name: 'filterCategory',
            onChange: CategoryStore.setFilter,
            defaultValue: CategoryStore.filter.filterCategory,
            options: categoryOptions,
            translateLabel: true,
          },
          {
            type: 'search',
            title: t('Search'),
            name: 'q',
            onChange: CategoryStore.setFilter,
          },
        ]}
        filterParams="filter"
        hideEmptyComponentWhenEmpty={true}
        noResultsText={t('No data.')}
        bulkActions={[
          {
            title: t('Delete'),
            onExecute: () => showDeleteWarning(),
          },
        ]}
        rowSelection={{
          selectedRows,
          onSelectionChange: (newRows) => setSelectedRows(newRows),
        }}
        onRowClick={(record) => {
          setDetailId(record.id)
          setShowModal(true)
        }}
      >
        <Column
          title={t('Name')}
          dataIndex="name"
          render={(value) => <Text weight="bold">{value}</Text>}
        />
        <Column
          title={t('Stream')}
          dataIndex="stream"
          render={(value) => <Stream value={value} />}
        />
        <Column
          title={t('Hidden')}
          dataIndex="hidden"
          align="center"
          render={(value) => (
            <Text>
              <Icon
                symbol={value ? 'circle__solid' : 'circle'}
                width="16px"
                height="16px"
              />
            </Text>
          )}
        />
        <Column
          title={t('Archived')}
          dataIndex="archived"
          align="center"
          render={(value) => (
            <Text>
              <Icon
                symbol={value ? 'circle__solid' : 'circle'}
                width="16px"
                height="16px"
              />
            </Text>
          )}
        />
        <Column
          title={t('Custom filters')}
          dataIndex="overrideAggregations"
          align="center"
          render={(value) => (
            <Text>
              <Icon
                symbol={value ? 'circle__solid' : 'circle'}
                width="16px"
                height="16px"
              />
            </Text>
          )}
        />
        <Column
          title={t('Custom sorting')}
          dataIndex="overrideAggregationSorting"
          align="center"
          render={(value) => (
            <Text>
              <Icon
                symbol={value ? 'circle__solid' : 'circle'}
                width="16px"
                height="16px"
              />
            </Text>
          )}
        />
        <Column
          title={t('Hierarchie')}
          dataIndex="hierarchy"
          render={(value) => <Hierarchy text={value} />}
        />
        <Column width={50} render={() => <ArrowAction />} />
      </ResourceTable>
      <DetailModal
        close={() => setShowModal(false)}
        isOpen={showModal}
        detailId={detailId}
      />
    </ContentWrapper>
  )
}

export default observer(CategoryList)
