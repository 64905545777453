import classNames from 'classnames'
import { Link } from '../../routing'

import { usePathName } from '../../utils'

const Tab = ({ tabData }) => {
  const pathWithoutInstance = usePathName()

  const itemClassNames = (route) =>
    classNames('tabs__item', {
      'tabs__item--active': pathWithoutInstance === route.path,
    })

  return (
    <div className="tabs">
      {tabData.map((tab, index) => (
        <Link
          key={`${index}-item`}
          route={tab.route}
          className={itemClassNames(tab.route)}
        >
          {tab.title}
        </Link>
      ))}
    </div>
  )
}

export default Tab
