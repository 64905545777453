import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/smart-bundle?${queryString}`

    return fetch(target)
  },

  async getById(id) {
    const { data } = await fetch(`/smart-bundle/${id}`)

    return data
  },

  async create(bundle) {
    const { data } = await fetch('/smart-bundle', {
      method: 'POST',
      body: JSON.stringify(bundle),
    })
    return data
  },

  async update(bundle) {
    await fetch(`/smart-bundle/${bundle.id}`, {
      method: 'PUT',
      body: JSON.stringify(bundle),
    })
  },

  async delete(id) {
    await fetch(`/smart-bundle/${id}`, { method: 'DELETE' })
  },

  async bulkDelete(ids) {
    await fetch('/smart-bundle', {
      method: 'DELETE',
      body: JSON.stringify({
        ids,
      }),
    })
  },

  async getRevisions(bundleId) {
    const { data } = await fetch(`/smart-bundle/${bundleId}/revisions`)

    return data
  },

  async restoreRevision(bundleId, revisionId) {
    const { data } = await fetch(
      `/smart-bundle/${bundleId}/restore-revision/${revisionId}`,
      {
        method: 'PUT',
      }
    )

    return data
  },
}
