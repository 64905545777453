import Icon from '../../components/Icon'
import classnames from 'classnames'

const SuccessIcon = ({ large = false }) => {
  return (
    <Icon
      className={classnames(
        'personalization__status-icon personalization__status-icon--success',
        {
          'personalization__status-icon--large': large,
        }
      )}
      symbol="check__regular"
    />
  )
}

const FailureIcon = ({ large = false }) => {
  return (
    <Icon
      className={classnames(
        'personalization__status-icon personalization__status-icon--failure',
        {
          'personalization__status-icon--large': large,
        }
      )}
      symbol="times"
    />
  )
}

export { FailureIcon, SuccessIcon }
