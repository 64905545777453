import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { slice } from 'lodash'
import api from '../../api'
import { buildQueryString, downloadCSV } from '../../utils'
import { fetchUtil } from '../../utils/fetchUtil'

class MonitoringStore {
  monitoringPopular = []
  monitoringPopularNoResults = []
  top5SearchTerms = []
  fetchingPopular = false
  fetchingPopularNoResults = false
  filter = {
    includeBotTraffic: 0,
    language: 'de',
    since: '1M',
  }
  checkSearchTerms = {
    language: 'de',
    searchTerm: '',
    fetching: false,
    data: {},
  }
  paginationPopular = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  paginationPopularNoResults = {
    current: 1,
    pageSize: 10,
    total: 0,
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.paginationPopular = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
    this.paginationPopularNoResults = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchSearchTerm()
    this.fetchSearchTerm(true)
  }

  setPaginationPopular(pagination) {
    this.paginationPopular = pagination
    this.fetchSearchTerm()
  }

  setPaginationPopularNoResults(pagination) {
    this.paginationPopularNoResults = pagination
    this.fetchSearchTerm(true)
  }

  *fetchSearchTerm(topFailed) {
    const pagination = topFailed
      ? this.paginationPopularNoResults
      : this.paginationPopular

    this[topFailed ? 'fetchingPopularNoResults' : 'fetchingPopular'] = true

    try {
      let { data, total } = yield api.monitoring.getSearchTerm({
        filter: this.filter,
        pagination: pagination,
        params: { hits: topFailed ? 0 : 1 },
      })

      pagination.total = total

      this[topFailed ? 'monitoringPopularNoResults' : 'monitoringPopular'] =
        data
      if (!topFailed && pagination.current === 1) {
        this.top5SearchTerms = slice(data, 0, 5)
      }
    } catch (error) {
      toast.error('Something went wrong loading the monitoring.')
    } finally {
      this[topFailed ? 'fetchingPopularNoResults' : 'fetchingPopular'] = false
    }
  }

  *onChangeCheckSearchTerm(searchTermData, key, value) {
    searchTermData[key] = value
    if (!this.checkSearchTerms.searchTerm) return
    try {
      this.checkSearchTerms.fetching = true
      const { data } = yield api.monitoring.checkSearchTerm({
        params: {
          language: this.checkSearchTerms.language,
          searchTerm: this.checkSearchTerms.searchTerm,
        },
      })
      this.checkSearchTerms.data = data
      this.checkSearchTerms.data.searchTerm = this.checkSearchTerms.searchTerm
      this.checkSearchTerms.fetching = false
    } catch (error) {
      toast.error('Something went wrong checking searh term')
      this.checkSearchTerms.fetching = false
    }
  }

  resetCheckSearchTerms(language = 'de') {
    this.checkSearchTerms = {
      language,
      searchTerm: '',
      fetching: false,
      data: {},
    }
  }

  *handleDownload(topFailed) {
    const pagination = topFailed
      ? this.paginationPopularNoResults
      : this.paginationPopular

    const queryString = buildQueryString({
      filter: this.filter,
      pagination: pagination,
      params: { hits: topFailed ? 0 : 1 },
    })

    try {
      const { data } = yield fetchUtil(
        `/monitoring/search-term/download?${queryString}`
      )
      downloadCSV({ text: data, fileName: 'result.csv' })
    } catch (error) {
      toast.error('Something went wrong.')
    }
  }
}

export default new MonitoringStore()
