import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'

import api from '../../api'
import { AuthStore } from '../../stores'
import Modal from '../Modal'
import { t, usePrevious } from '../../utils'
import Button from '../Button'
import Text from '../Text'
import ChangedField from '../ChangedField'

const HEARTBEAT_INTERVAL_TIME = 15000

const LockingPage = ({
  type,
  targetId,
  redirectPath,
  onTakenOver = () => {},
}) => {
  const [showModal, setShowModal] = useState(false)
  const [lock, setLock] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [isTakenOver, setTakenOver] = useState(false)
  const [heartbeatInterval, setHeartbeatInterval] = useState()
  const prevHeartbeatInterval = usePrevious(heartbeatInterval)

  const history = useHistory()

  const handleHeartbeat = async () => {
    if (isTakenOver) return

    try {
      const lockData = await api.common.putHeartbeatLock(type, targetId)
      setLock(lockData.data)

      if (lockData.data.owner !== AuthStore.user.email) {
        setShowModal(true)
        setTakenOver(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    async function init() {
      try {
        const lockData = await api.common.getLock(type, targetId)
        if (isEmpty(lockData.data)) {
          await api.common.createLock(type, targetId)
          setHeartbeatInterval(
            setInterval(handleHeartbeat, HEARTBEAT_INTERVAL_TIME)
          )
          return
        }
        if (lockData.data.owner !== AuthStore.user.email) {
          setShowModal(true)
          setLock(lockData.data)
        } else {
          setHeartbeatInterval(
            setInterval(handleHeartbeat, HEARTBEAT_INTERVAL_TIME)
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
    init()

    return async () => {
      // call api remove lock when component unmount
      try {
        await api.common.removeLock(type, targetId)
      } catch (error) {
        console.log(error)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isTakenOver) onTakenOver()
    // eslint-disable-next-line
  }, [isTakenOver])

  useEffect(() => {
    clearInterval(prevHeartbeatInterval)
    // eslint-disable-next-line
  }, [heartbeatInterval])

  const handleTakeOver = async () => {
    setLoading(true)
    try {
      await api.common.createLock(type, targetId, true)
      setLoading(false)
      setHeartbeatInterval(
        setInterval(handleHeartbeat, HEARTBEAT_INTERVAL_TIME)
      )
      setShowModal(false)
    } catch (error) {
      setLoading(false)
      toast.error(t('Something went wrong taking over the lock'))
    }
  }

  return (
    <Modal
      zIndex={1052}
      visible={showModal}
      header={t(
        isTakenOver ? 'Page/Snippet taken over.' : 'Page/Snippet locked.'
      )}
      mask
      closable={false}
      footer={[
        <Button
          key="back"
          icon="times"
          variant={isTakenOver ? 'secondary-blocker' : 'secondary'}
          onClick={() => history.push(redirectPath)}
        >
          {t(isTakenOver ? 'Back to listing' : 'No, back to listing')}
        </Button>,
        !isTakenOver && (
          <Button
            key="take-over"
            variant="secondary-blocker"
            icon="chevron-right"
            onClick={handleTakeOver}
            loading={isLoading}
          >
            {t('Yes, take over')}
          </Button>
        ),
      ]}
    >
      <Text element="p">
        <Text weight="bold">{lock.owner}</Text>{' '}
        {isTakenOver ? (
          <>
            {t(
              'has taken over and currently edits the content. Your changes were saved on a draft revision!'
            )}
          </>
        ) : (
          <>
            {t('is currently editing this content. Do you want to take over?')}{' '}
            <Text weight="bold">{t('Attention:')}</Text>{' '}
            {t('If you take over the changes of the other person are lost!')}
          </>
        )}
      </Text>
      {lock.updatedAt && !isTakenOver && (
        <>
          <br />
          <Text element="p">
            <Text weight="bold">{t('Last seen:')}</Text>{' '}
            <ChangedField withoutUser timeOnly changed={lock.updatedAt} />
          </Text>
        </>
      )}
    </Modal>
  )
}

export default observer(LockingPage)
