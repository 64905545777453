import ReactApexChart from 'react-apexcharts'
import { t } from '../../utils'

const RadialChart = ({ data, categoryLimit }) => {
  const calculateUsagePercentage = (originData, categoryLimit) => {
    // Object to store the latest values for each category
    const transformedData = {
      'Page Editor': null,
      Recommendation: null,
      Search: null,
      Feeds: null,
    }

    // Iterate through the data and update the latest values for each category
    for (const date in data) {
      if (Object.hasOwnProperty.call(data, date)) {
        const values = data[date]
        for (const category in values) {
          if (Object.hasOwnProperty.call(values, category)) {
            transformedData[category] += values[category]
          }
        }
      }
    }

    // Calculate the percentage for each category
    const percentages = {}
    categoryLimit.forEach((category) => {
      const currentValue = transformedData[category.name]
      const limit = category.requestLimit || -1
      if (
        currentValue !== null &&
        limit !== null &&
        currentValue !== undefined &&
        limit !== undefined
      ) {
        percentages[category.name] =
          limit === -1 ? -1 : ((currentValue / limit) * 100).toFixed(2)
      }
    })
    return percentages
  }

  const dataTransformed = calculateUsagePercentage(data, categoryLimit)

  return (
    <ReactApexChart
      options={{
        labels: Object.keys(dataTransformed),
        plotOptions: {
          radialBar: {
            track: {
              opacity: 1,
              background: '#61304d',
            },
            dataLabels: {
              value: {
                color: '#fff',
                formatter: function (val) {
                  if (val === '-1') {
                    return t('Unlimited')
                  }
                  return val + '%'
                },
              },
            },
          },
        },
        chart: {
          id: 'statistic-radialBar',
          background: '#522941',
          toolbar: {
            show: false,
          },
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          labels: {
            colors: 'white',
          },
          position: 'bottom',
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          row: {
            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: 'white',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: 'white',
            },
          },
        },
      }}
      series={Object.values(dataTransformed)}
      height={350}
      type="radialBar"
    />
  )
}

export default RadialChart
