import AutoComplete from '../AutoComplete'
import { getIn } from 'formik'

export default function FieldSelect(props) {
  const { name, fields, formikProps } = props
  const { setFieldValue, values } = formikProps

  /**
   * Return a field in Value-Label-Object style for use in
   * a array map aggregation for a Ant-Design AutoComplete options array.
   *
   * @param field
   * @returns {{label, value}}
   */
  function forOptions(field) {
    return {
      key: field.title + '_' + field.id,
      value: field.id,
      label: field.title,
    }
  }

  /**
   * Get a single field by the id out of the fields array.
   *
   * @param id
   * @returns {Field}
   */
  function getField(id) {
    return fields.find((field) => field.id === id)
  }

  function handleChange(selected) {
    const selectedField = getField(selected)
    /*
     * Extend event handler because we also need to save the type of
     * the selected field o determine what the available operators are.
     */
    const type = selectedField.type
    updateFormikState(selected, type)
  }

  function updateFormikState(selected, type) {
    /*
     * We need to use setFieldValue here because the AntDesign-Select component does not emit a React.ChangeEvent
     */
    setFieldValue(`${name}.field`, selected)
    setFieldValue(`${name}.type`, type)
    setFieldValue(`${name}.operator`, '')
  }

  return (
    <AutoComplete
      itemKey="key"
      defaultOptions={fields.map(forOptions)}
      onSelect={handleChange}
      value={getField(getIn(values, name).field)?.title ?? ''}
      fullWidth
    />
  )
}
