import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import Checkbox from '../../../components/Checkbox'
import ConfigurationPanel, {
  Panel,
} from '../../../components/ConfigurationPanel'

import DashboardStore from '../../../stores/DashboardStore'

import t from '../../../utils/translate'
import { routes } from '../../../routing'
import createRedirectPath from '../../../utils/createRedirectPath'

import './onboardingSection.styl'

const OnboardingSection = () => {
  return (
    <ConfigurationPanel
      rightArrow
      noPadding
      active={DashboardStore.isOnboardingSectionShown}
      onCloseButtonClick={() => DashboardStore.closeOnboardingSection()}
    >
      <Panel key="onboarding" withoutCollapse={true}>
        <div className="onboarding-section">
          <div className="onboarding-section__inner">
            <Text
              weight="bold"
              element="p"
              size="echo"
              className="onboarding-section__headliner"
            >
              {t('Hey, nice to see you!')}
            </Text>

            <div className="onboarding-section__content">
              <div className="onboarding-section__video">
                <iframe
                  src={DashboardStore.onboardingVideoURL}
                  title="Makaira Introduction Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className="onboarding-section__right">
                <div className="onboarding-section__text">
                  <Text size="delta" weight="bold" element="p">
                    {t("Let's get started!")}
                  </Text>
                  <Text element="p" weight="medium">
                    {t(
                      'We recommend to watch the video on the left to get a good insight into the capabilities of Makaira. If you need any additional information you can always check out our documentation below.'
                    )}
                  </Text>
                  <div>
                    <Link to={createRedirectPath(routes.setupInformation.path)}>
                      <Button icon="chevron-right">
                        {t('Connect your shop system')}
                      </Button>
                    </Link>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://docs.makaira.io"
                    >
                      <Button icon="chevron-right">{t('Documentation')}</Button>
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://docs.makaira.io/reference"
                    >
                      <Button icon="chevron-right">
                        {t('API Documentation')}
                      </Button>
                    </a>
                  </div>
                </div>
                <Checkbox
                  checked={DashboardStore.onboardingSectionHideChecked}
                  onChange={DashboardStore.handleOnboardingHideClick}
                  label={t("Don't show this again")}
                />
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </ConfigurationPanel>
  )
}

export default observer(OnboardingSection)
