import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'

const MANUAL_URL = {
  id: 'url',
  title: 'Manual URL',
}

class SearchRedirectStore {
  BLACKLISTED_TYPES = ['product', 'link', 'searchredirect']
  searchTypes = []
  searchRedirects = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = {}
  state = 'pending' // "pending", "done" or "error"

  updating = {
    type: 'url',
    documentId: '',
    words: {},
    targetUrl: {},
  }
  isDirty = false
  hideEmptyComponent = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
    this.updating = {
      type: 'url',
      documentId: '',
      words: {},
      targetUrl: {},
    }
    this.isDirty = false
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchSearchRedirects()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchSearchRedirects()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchSearchRedirects()
    this.hideEmptyComponent = true
  }

  getById(id) {
    return this.searchRedirects.find((s) => s.id == id)
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  *fetchSearchRedirects(initial) {
    try {
      if (initial) {
        this.filter = {}
      }

      const { data, total } = yield api.searchRedirects.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.searchRedirects = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the search redirect listing.')
      this.state = 'error'
    }
  }

  *fetchSearchTypes() {
    try {
      const { data } = yield api.common.getDocumentTypes()
      if (data && Array.isArray(data)) {
        this.searchTypes = data.filter(
          (type) => !this.BLACKLISTED_TYPES.includes(type.id)
        )
        this.searchTypes.push(MANUAL_URL)
      }
    } catch (error) {
      toast.error('Something went wrong...')
      this.state = 'error'
    }
  }

  *update(body) {
    try {
      const { data } = yield api.searchRedirects.update(body)
      this.updating = data
      toast.success('Updated successfully!')
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong while update...')
    }
  }

  *create(body) {
    try {
      const { data } = yield api.searchRedirects.create(body)
      toast.success('Created successfully!')
      return data
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong while create...')
    }
  }

  *delete(ids) {
    try {
      yield api.searchRedirects.delete(ids)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchSearchRedirects()
  }

  *searchDocuments(datatype, searchPhrase, language, shopId) {
    const searchPhraseCleaned = searchPhrase.trim()
    if (searchPhraseCleaned === '') {
      return []
    }

    try {
      const data = yield api.common.fetchSearchResultPreview({
        searchPhrase: searchPhraseCleaned,
        shopId: shopId,
        datatypes: [datatype],
        fields: [
          'id',
          'title',
          'datatype',
          'url',
          'manufacturer_title',
          'category_title',
        ],
        state: 'active',
        showEverything: true,
        language,
      })

      if (data[datatype] && data[datatype].total > 0) {
        const results = data[datatype].items.map((item) => item.fields)

        return results.map((item) => ({
          value: item.id,
          label: `${
            item.title || item.category_title || item.manufacturer_title
          } (${item.url})`,
        }))
      }

      return []
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong while searchDocuments')
      return []
    }
  }

  *fetchRedirectsById(id) {
    try {
      const { data } = yield api.searchRedirects.get(id)
      this.updating = data
      this.state = 'done'
      return data
    } catch (error) {
      toast.error('Something went wrong while fetchRedirectsById')
      this.state = 'error'
    }
  }

  getByIdFormattedForFormik(id) {
    const searchRedirect = Object.assign(
      {},
      this.searchRedirects.find((s) => s.id === id)
    )

    if (searchRedirect === null) return null

    const words = {}
    Object.keys(searchRedirect.words).forEach((language) => {
      words[language] = searchRedirect.words[language].join(',')
    })

    searchRedirect.words = words

    if (
      Array.isArray(searchRedirect.targetUrl) &&
      searchRedirect.targetUrl.length === 0
    )
      searchRedirect.targetUrl = {}

    return searchRedirect
  }
}

export default new SearchRedirectStore()
