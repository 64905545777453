import { Link as ReactRouterLink } from 'react-router-dom'
import createInternalHref from '../utils/createInternalHref'

export default function Link(props) {
  const { route, id, children, ...rest } = props

  const href = createInternalHref({ route, id })

  return (
    <ReactRouterLink to={href} {...rest}>
      {children}
    </ReactRouterLink>
  )
}
