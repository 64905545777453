import { observer } from 'mobx-react-lite'
import Icon from '../../Icon'
import Select from '../../Select'
import { FilterSectionStore } from '../../../stores'

function ManufacturerField() {
  const { manufacturers, editingFilter, setEditingFilter } = FilterSectionStore

  if (editingFilter.field !== 'manufacturerid') {
    return null
  }

  return (
    <>
      <Icon width="20px" height="20px" symbol="arrow-down" />
      <Select
        style={{ minWidth: '11.5rem' }}
        flexible
        dropdownMatchSelectWidth={false}
        options={manufacturers.map((item) => ({
          label: item.name,
          value: item.shopid,
        }))}
        value={editingFilter.value}
        onChange={(value) =>
          setEditingFilter({
            ...editingFilter,
            operator: 'inList',
            value,
          })
        }
      />
    </>
  )
}

export default observer(ManufacturerField)
