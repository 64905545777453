import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import Modal from '../../../components/Modal'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import { ComponentStore, EditorStore } from '../../../stores'
import { t } from '../../../utils'
import TextInput from '../../../components/TextInput'

function AddModal() {
  const { isAddElementModalVisible, addElement, closeAddElementModal } =
    EditorStore
  const { filtedComponents, setSearchPhrase } = ComponentStore
  const inputRef = useRef(null)

  const handleAddElement = (identifier) => () => {
    addElement({
      identifier,
    })
    closeAddElementModal()
  }

  useEffect(() => {
    if (inputRef.current && isAddElementModalVisible) {
      inputRef.current.focus({
        cursor: 'start',
      })
    }

    if (!isAddElementModalVisible) {
      setSearchPhrase('')
    }

    // eslint-disable-next-line
  }, [isAddElementModalVisible])

  if (!isAddElementModalVisible) return null

  return (
    <Modal
      visible={isAddElementModalVisible}
      onClose={closeAddElementModal}
      header={t('Add a component')}
      mask
      align="left"
      maskClosable
      customDialogClassName="add-component-dialog"
    >
      <>
        <div className="page-editor__search-component">
          <TextInput
            onChange={(e) => setSearchPhrase(e.target.value)}
            placeholder={t('Search Component')}
            ref={inputRef}
            icon="magnifying-glass"
            rounded
          />
        </div>
        <div className="page-editor__add-modal">
          {filtedComponents.map((component) => (
            <div
              className="page-editor__add-modal-component"
              key={component.id}
              onClick={handleAddElement(component.identifier)}
              title={component.name}
            >
              <Icon
                width="155px"
                height="60px"
                symbol={component.icon || '001-pict-heading-text-cta-L'}
                makairaIcon
              />
              <Text size="bravo" variant="book">
                {component.name}
              </Text>
            </div>
          ))}
        </div>
      </>
    </Modal>
  )
}

export default observer(AddModal)
