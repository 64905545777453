import { range } from 'lodash'
import React from 'react'
import { t } from '../../../../utils'
import { SEPARATOR_VALUE } from './constants'

const Preview = ({ feed }) => {
  if (!['csv', 'xml', 'json'].includes(feed.contentType)) return null

  return (
    <div className="feed-setting__setting-preview">
      <div className="feed-setting__setting-preview-inner">
        {['xml', 'json'].includes(feed.contentType) && (
          <>
            <pre className="feed-setting__setting-preview-header">
              {feed.header}
            </pre>
            <div>{t('your feed content')}</div>
            <pre className="feed-setting__setting-preview-footer">
              {feed.footer}
            </pre>
          </>
        )}
        {feed.contentType === 'csv' && (
          <>
            {feed.renderHeader && (
              <pre className="feed-setting__setting-preview-header">
                header1,header2,header3,header4,header5
              </pre>
            )}
            {range(3).map((header) => (
              <div
                key={header}
                className="feed-setting__setting-preview-csv-content"
              >
                {range(1, 6).map((value) => {
                  const separator = feed.separator
                    ? SEPARATOR_VALUE[feed.separator]
                    : ''
                  return (
                    <React.Fragment key={value}>
                      <span>value{value}</span>
                      <strong>
                        {value !== 5 ? separator : feed.lineSeparator}
                      </strong>
                    </React.Fragment>
                  )
                })}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default Preview
