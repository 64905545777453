import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Tooltip } from 'antd'
import { useState, useEffect } from 'react'

import Modal from '../../../components/Modal'
import InputEditor from '../../../components/InputEditor'
import Text from '../../../components/Text'
import Icon from '../../../components/Icon'
import { EditorStore } from '../../../stores'
import Header from './Header'
import './editorModal.styl'
import Setting from './Setting'
import SettingModal from './SettingModal'
import t from '../../../utils/translate'
import useHoverModal from './useHoverModal'
import ContentAppModal from './ContentAppModal'

function EditorModal() {
  const { isEditElementModalVisible, component, closeEditElementModal } =
    EditorStore

  useHoverModal('.page-editor-modal', isEditElementModalVisible)

  if (!isEditElementModalVisible) return null

  // At the moment the page would crash if set the anchor of the ColorPicker Overlay to the modal itself
  // We have to set it to the parent element of the ColorPicker. But because of this the overlay will not be on
  // top of the Modal but inside it. If the height of the Modal itself is to small the ColorPicker overlay will
  // not be shown in total. We apply here a quick fix for this by assigning a min-height to the body if the
  // fields contain at least one color picker.
  // TODO: Find out why the page crashes when we assign the anchor of the ColorPicker overlay to the modal itself.
  const containsColorPicker =
    component.sections?.filter((section) => {
      return (
        section.fields.filter((_field) => _field.type === 'colorpicker')
          .length > 0
      )
    })?.length > 0

  return (
    <Modal
      className={classNames('page-editor-modal', {
        'page-editor-modal--min-height': containsColorPicker,
      })}
      closeIconText={t('Done')}
      visible={isEditElementModalVisible}
      onClose={closeEditElementModal}
      header={<Header />}
      mask
      align="left"
      maskClosable
      customDialogClassName="page-editor-dialog"
    >
      <Setting />

      <SectionInput sections={component?.sections || []} />
      <SettingModal />
      <ContentAppModal />
    </Modal>
  )
}

export const SectionInput = ({ sections }) => {
  const [conditionalValues, setConditionalValues] = useState({})

  useEffect(() => {
    let newConditionalValues = { ...conditionalValues }
    const getDefaultValue = (elementType) => {
      if (elementType === 'checkbox') {
        return false
      }

      if (elementType === 'number') {
        return 0
      }

      return undefined
    }

    const allFields = sections.reduce((acc, section) => {
      acc.push(...section.fields)
      return acc
    }, [])

    sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (
          field.conditionField &&
          !Object.keys(newConditionalValues).includes(field.conditionField)
        ) {
          const conditionalField = allFields.find(
            (f) => f.uuid === field.conditionField
          )
          newConditionalValues[field.conditionField] = getDefaultValue(
            conditionalField?.type
          )
        }
      })
    })
    setConditionalValues(newConditionalValues)
    // eslint-disable-next-line
  }, [sections])

  return (
    <div className="section-inputs">
      {sections?.map((section, index) => {
        return (
          <div className="section-input" key={index}>
            {section.label && (
              <Text
                size="echo"
                element="div"
                className="section-input__heading"
              >
                {section.label}
                {section.description && (
                  <Tooltip title={section.description} trigger="click">
                    <Icon
                      className="section-input__description"
                      symbol="circle-info__solid"
                      width={15}
                      height={15}
                    />
                  </Tooltip>
                )}
              </Text>
            )}

            <div className="section-input__container">
              {section.fields.map((field, fieldIndex) => {
                return (
                  <InputEditor
                    setConditionalValues={setConditionalValues}
                    conditionalValues={conditionalValues}
                    key={fieldIndex}
                    {...field}
                    showTranslations
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default observer(EditorModal)
