import { UIStore } from '../../stores'
import { DEFAULT_VALUES } from './defaultPageValues'

export default function Page() {
  const active = UIStore.languages.reduce((result, lang) => {
    result[lang] = lang === UIStore.enterpriseConfiguration.defaultLanguage
    return result
  }, {})

  const metadata = UIStore.languages.reduce((result, lang) => {
    result[lang] = DEFAULT_VALUES.metadata
    return result
  }, {})

  const seoUrls = UIStore.languages.reduce((result, lang) => {
    result[lang] = DEFAULT_VALUES.seoUrls
    return result
  }, {})

  const searchable = UIStore.languages.reduce((result, lang) => {
    result[lang] = DEFAULT_VALUES.searchable
    return result
  }, {})

  return {
    active,
    config: {},
    metadata,
    seoUrls,
    searchable,
    type: 'page',
    relatedDocumentId: '',
    manufacturerId: '',
    categoryId: '',
    internalTitle: '',
  }
}
