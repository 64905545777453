import { observer } from 'mobx-react-lite'
import moment from 'moment'
import DatePicker from '../../../../components/DatePicker'
import Switch from '../../../../components/Switch'
import { t } from '../../../../utils'

function FieldDate({ fieldSettings, onChange }) {
  const { defaultValue, showTime } = fieldSettings
  const onChangeDefaultValue = (e) => {
    onChange({
      defaultValue: e?.toISOString(),
    })
  }

  const defaultValueProps = {
    title: t('Default value (optional)'),
    label: t('Default value (optional)'),
    value: defaultValue ? moment(defaultValue) : null,
    onChange: onChangeDefaultValue,
    showTime: showTime,
  }

  return (
    <>
      <Switch
        checked={showTime}
        type="horizontal"
        title={t('Allow time')}
        onChange={(checked) => {
          onChange({
            showTime: checked,
          })
        }}
      />
      <DatePicker {...defaultValueProps} />
    </>
  )
}

export default observer(FieldDate)
