import { Tag } from 'antd'
import t from '../../utils/translate'

export default function ReasonField(props) {
  const reasons = props.preview_hidden_reasons || []

  if (reasons.length == 0) return null

  return (
    <>
      {reasons.map((reason) => (
        <Tag style={{ borderRadius: 25 }} key={reason}>
          {t(reason)}
        </Tag>
      ))}
    </>
  )
}
