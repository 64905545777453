import classnames from 'classnames'

import './container.styl'

const Container = ({
  children,
  level = 0,
  padding = 'm',
  paddingLeft = '',
  paddingRight = '',
  paddingTop = '',
  paddingBottom = '',
  className = '',
}) => {
  const paddingClasses = []

  if (String(paddingTop) !== '0' && String(padding) !== '0')
    paddingClasses.push(`container--pt-${paddingTop || padding}`)

  if (String(paddingLeft) !== '0' && String(padding) !== '0')
    paddingClasses.push(`container--pl-${paddingLeft || padding}`)

  if (String(paddingRight) !== '0' && String(padding) !== '0')
    paddingClasses.push(`container--pr-${paddingRight || padding}`)

  if (String(paddingBottom) !== '0' && String(padding) !== '0')
    paddingClasses.push(`container--pb-${paddingBottom || padding}`)

  return (
    <div
      className={classnames(
        'container',
        `container--level-${level}`,
        paddingClasses,
        className
      )}
    >
      {children}
    </div>
  )
}

export default Container
