import {
  cloneDeep,
  difference,
  drop,
  flatten,
  flattenDeep,
  get,
  has,
  set,
  toString,
} from 'lodash'
import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import { toast } from 'react-toastify'
import { v4 as uuidV4 } from 'uuid'

import {
  UIStore,
  PageStore,
  RevisionStore,
  ComponentStore,
  PageEditorPreviewStore,
} from '..'

import api from '../../api'
import { Component, Page } from '../../models'
import { DEFAULT_VALUES } from '../../models/Page/defaultPageValues'

import {
  delay,
  getDefaultFieldState,
  prepareDataForPageEditor,
  removeMediaData,
} from '../../utils'
import t from '../../utils/translate'
import { notifyStructureUpdate } from './utils'

const COPY_ANIMATION_DURATION = 3000
const CLIPBOARD_MAX_LENGTH = 15

class EditorStore {
  isAddElementModalVisible = false
  isEditElementModalVisible = false
  isSettingsVisible = false
  isContentModalAppVisible = false
  selectedContentModalApp = {}
  editingFieldInfo = {} // using for Content Modal App
  publishConfig = []
  pageToEdit = {}
  elementToEdit = {}
  component = {} // the pre-defined component that represents the data structure for elementToEdit
  state = 'pending' // "pending", "done" or "error"
  contentLanguage = UIStore.currentLanguage
  isDirty = false
  configuration = {}
  extraLanguageInformation = {}
  clipboard = []
  elementsWithAnimationPlaying = []
  showWarningMappingModal = false
  isDifferenceStorageType = false
  isDifferenceLanguageMapping = false
  languagesMapping = {}
  selectedElementOnAdd = null
  mediaMapping = {}
  previewPopup = null
  previewPopupStatus = ''
  hoveringInputId = null
  modifiedElements = []
  invalidData = { component: {}, field: {} }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  /**
   * Initializes the store when a detail/edit view is loaded.
   *
   * @param id ID of the page to load. "page", "snippet" for creating new empty pages.
   *           "related--document-id--document-type for creating a page that attaches content
   *           to existing elastic search documents.
   */
  *getById(id) {
    this.state = 'pending'
    this.invalidData = { component: {}, field: {} }
    this.pageToEdit = new Page()

    try {
      if (id === 'page' || id === 'snippet') {
        this.pageToEdit.type = id
        this.state = 'done'
        return
      }

      if (id.includes('related')) {
        yield this.initializePageForExistingShopContent(id)
        this.state = 'done'
        return
      }

      const pageData = yield api.pages.getById(id)

      // To ensure compatibility with pages that were created in PageEditor-V1
      // add top and bottom section if they don't exist and remove
      // deprecated main section.
      if (!get(pageData, 'config.top.elements'))
        set(pageData, 'config.top.elements', [])
      if (!get(pageData, 'config.bottom.elements'))
        set(pageData, 'config.bottom.elements', [])
      delete pageData.config.main

      // Check for every language in the shop if there already is a URL
      // and metadata block added. If not add default values.
      pageData.seoUrls = UIStore.languages.reduce((result, lang) => {
        result[lang] = pageData.seoUrls[lang] ?? DEFAULT_VALUES.seoUrls
        return result
      }, {})
      pageData.metadata = UIStore.languages.reduce((result, lang) => {
        result[lang] = pageData.metadata[lang] ?? DEFAULT_VALUES.metadata
        return result
      }, {})

      const { isDifference, pageContent, notifyUser, modifiedElements } =
        prepareDataForPageEditor({
          pageContent: pageData,
          components: ComponentStore.components,
          languages: UIStore.languages,
          publishConfigs: this.publishConfig,
        })

      this.pageToEdit = pageContent

      if (pageData.type !== 'snippet' && pageData.type !== 'page') {
        yield this.fetchAdditionalInfoForExistingDocuments(
          pageData.type,
          toString(pageData.relatedDocumentId || pageData.id)
        )
      } else {
        this.extraLanguageInformation = {}
      }

      if (isDifference && !UIStore.isStorefrontPreview) {
        if (notifyUser) {
          this.modifiedElements = modifiedElements
          notifyStructureUpdate()
        } else {
          this.modifiedElements = []
          this.isDirty = true
        }
      } else {
        this.modifiedElements = []
      }

      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the page.')
    }
  }

  *initializePageForExistingShopContent(id) {
    const [, relatedDocumentId, type] = id.split('--')
    this.pageToEdit.relatedDocumentId = relatedDocumentId
    this.pageToEdit.type = type

    if (type !== 'snippet') {
      yield this.fetchAdditionalInfoForExistingDocuments(
        type,
        relatedDocumentId
      )
    }
  }

  *savePage() {
    this.state = 'pending'
    this.isDirty = false
    this.isAddElementModalVisible = false
    this.isEditElementModalVisible = false
    this.isSettingsVisible = false
    PageEditorPreviewStore.setPreviewLoading(false)

    yield PageStore.updateOrCreate(this.pageToEdit)
    yield delay(1000)

    this.pageToEdit = {}
    this.elementToEdit = {}
    RevisionStore.clearRevisions()
    this.state = 'done'
  }

  /**
   * @returns {boolean} Returns a boolean whether the save was a create or update action.
   *                    True on update action, false on create action
   */
  *savePageAndStay() {
    this.state = 'pending'
    this.isDirty = false
    this.isAddElementModalVisible = false
    this.isEditElementModalVisible = false
    this.isSettingsVisible = false

    try {
      if (this.pageToEdit.id) {
        yield api.pages.update(this.pageToEdit)

        yield RevisionStore.fetchRevisions()
        yield this.fetchAdditionalInfoForExistingDocuments(
          this.pageToEdit.type,
          toString(this.pageToEdit.relatedDocumentId || this.pageToEdit.id)
        )
        this.modifiedElements = []

        this.state = 'done'
        return false
      } else {
        const savedPage = yield api.pages.create(this.pageToEdit)

        this.state = 'done'
        this.pageToEdit.id = savedPage.data.id
        return savedPage.data.id
      }
    } catch (e) {
      this.state = 'done'
      toast.error('Something went wrong...')
      return false
    }
  }

  /**
   * Fetches the title and the url in all languages of the shop for the provided document.
   * This is required because when we load a page that adds content to existing documents (e.g. categories)
   * we only get the relatedDocumentId from the load request. To display the title and the url we need to
   * fetch this additional information here.
   *
   * @param type The type of the document. E.g. "makaira-productgroup", "category", etc. or a custom data type.
   * @param id The id of the document.
   */
  *fetchAdditionalInfoForExistingDocuments(type, id) {
    const titleRequests = []
    const requiredLangauges = UIStore.languages

    // Send requests to get information in all languages
    for (let language of requiredLangauges) {
      const request = api.common.getDocumentsById({
        type,
        ids: [id],
        language: language,
      })

      titleRequests.push(request)
    }

    const documentResponse = yield Promise.all(titleRequests)
    const languageInformation = {}

    documentResponse.forEach((document, index) => {
      if (document?.length > 0) {
        languageInformation[requiredLangauges[index]] = {
          url: document[0].url,
          title: document[0].title,
        }
      }
    })

    this.extraLanguageInformation = languageInformation
  }

  /**
   * Set the element after which a newly added element will be inserted.
   *
   * @param element{{ id: string }}
   */
  selectElementOnAdd(element) {
    this.selectedElementOnAdd = element
  }

  /**
   * Add a component to the page.
   * EditorStore.selectedElementOnAdd will be respected for the position.
   *
   * @param identifier{string}
   */
  addElement({ identifier }) {
    // Grab the config for the element we want to add
    this.component = ComponentStore.getByIdentifier(identifier)

    // Create a new instance for it
    const component = new Component(this.component, this.contentLanguage)

    // handle add element after selected element
    if (this.selectedElementOnAdd) {
      const elements = this.elements
      const elementIndex = elements.findIndex(
        (el) => el.id === this.selectedElementOnAdd.id
      )
      this.updateElements(
        this.insertElements(elements, elementIndex + 1, component)
      )
      this.elementToEdit = this.elements.find(
        (element) => element.id === component.id
      )
    } else {
      // normal add element
      let elements = get(this.pageToEdit, `config.top.elements`)

      if (!elements) {
        set(this.pageToEdit, `config.top.elements`, [component])
      } else {
        elements.push(component)
      }

      // We need to explicitly assign the newly added element for Mobx to properly
      // track the changes here
      const newElements = get(this.pageToEdit, `config.top.elements`)
      this.elementToEdit = newElements[newElements.length - 1]
    }

    this.isDirty = true
    this.closeAddElementModal()
    this.showEditElementModal()
  }

  editElement(element) {
    this.elementToEdit = element
    this.component = ComponentStore.getByIdentifier(element.component)
    this.showEditElementModal()
  }

  deleteElement(id) {
    this.updateElements(this.elements.filter((element) => element.id !== id))
  }

  /**
   * Updates the page object in the store.
   *
   * @param page object that will be stored in pageToEdit
   * @param excludeConfigAndMetadata Excludes config and metadata from the update. All other keys will be updated.
   *        This is useful when we apply the Formik Changes in the Configuration Panel and don't want to override
   *        outdated data (Formik State may be old because after the Configuration Panel was opened changes
   *        were made to the seo setting for example) here.
   */
  setPage(page, excludeConfigAndMetadata) {
    if (excludeConfigAndMetadata) {
      this.pageToEdit = {
        ...page,
        config: { ...(this.pageToEdit.config || {}) },
        metadata: { ...(this.pageToEdit.metadata || {}) },
      }
    } else {
      this.pageToEdit = page
    }
  }

  closeEditElementModal() {
    this.isEditElementModalVisible = false
    this.elementToEdit = {}
    this.component = {}
  }

  showEditElementModal() {
    this.isEditElementModalVisible = true
    this.isSettingsVisible = false
  }

  showAddElementModal() {
    this.isAddElementModalVisible = true
  }

  closeAddElementModal() {
    this.isAddElementModalVisible = false
    this.selectedElementOnAdd = null
  }

  showSettings() {
    this.isSettingsVisible = true
  }

  closeSettings() {
    this.isSettingsVisible = false
  }

  showContenModalApp(app, fieldInfo = {}) {
    this.isContentModalAppVisible = true
    this.selectedContentModalApp = app
    this.editingFieldInfo = fieldInfo
  }

  closeContenModalApp() {
    this.isContentModalAppVisible = false
    this.selectedContentModalApp = {}
    this.editingFieldInfo = {}
  }

  getValueForField(id = '', language = this.contentLanguage) {
    /**
     * Nested IDs will be provided as "foo.bar.baz".
     * Therefore we need to split those access paths here
     * before providing it to the get() function.
     */
    const ids = id.split('.')

    return get(this.elementToEdit, [
      'properties',
      language || this.contentLanguage,
      'content',
      ...ids,
    ])
  }

  setValueForField(id, value) {
    /**
     * Nested IDs will be provided as "foo.bar.baz".
     * Therefore, we need to split those access paths here
     * before providing it to the get() function.
     */
    const ids = id.split('.')

    set(
      this.elementToEdit,
      ['properties', this.contentLanguage, 'content', ...ids],
      value
    )

    this.isDirty = true
  }

  updateValueFromContentWidget(key, value) {
    set(this.pageToEdit, key, value)

    this.isDirty = true
  }

  get active() {
    return get(this.elementToEdit, [
      'properties',
      this.contentLanguage,
      'active',
    ])
  }

  get selectedElementTimed() {
    const personas =
      get(this.elementToEdit, [
        'properties',
        this.contentLanguage,
        'personas',
      ]) || {}

    const defaultConfig = this.getDefaultPublishConfig()

    const getConfigName = (identifier) => {
      if (defaultConfig.identifier === identifier) {
        return defaultConfig.name
      } else {
        return this.publishConfig.find(
          (config) => config.identifier === identifier
        )?.name
      }
    }

    let personasWithNames = Object.keys(personas).map((identifier) => ({
      name: getConfigName(identifier),
      identifier,
      ...personas[identifier],
    }))

    personasWithNames = personasWithNames.filter((group) => group.active)

    return personasWithNames.map((group) => ({
      ...group,
      name: group.name,
      from: group.activeFrom,
      to: group.activeTo,
    }))
  }

  setActive(active) {
    this.isDirty = true
    set(
      this.elementToEdit,
      ['properties', this.contentLanguage, 'active'],
      active
    )
  }

  setActiveFromId(id, active) {
    const elements = this.elements
    const selectedElement = elements.find((element) => element.id === id)

    if (!selectedElement) {
      return
    }

    set(selectedElement, ['properties', this.contentLanguage, 'active'], active)
    this.isDirty = true
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  setValueForPublishConfig(id, config) {
    this.isDirty = true

    // disable all personas groups if active 'All'
    if (id === this.getDefaultPublishConfig().identifier && config.active) {
      const elementPersonas =
        get(this.elementToEdit, [
          'properties',
          this.contentLanguage,
          'personas',
        ]) || {}
      Object.values(elementPersonas).forEach(
        (personas) => (personas.active = false)
      )
    }

    set(
      this.elementToEdit,
      ['properties', this.contentLanguage, 'personas', id],
      config
    )
  }

  getValueForPublishConfig(id) {
    return get(
      this.elementToEdit,
      ['properties', this.contentLanguage, 'personas', id],
      {}
    )
  }

  getDefaultPublishConfig() {
    return {
      name: 'All Personas',
      description: '',
      identifier: 'All',
      meta: {},
    }
  }

  get elements() {
    const topElements = get(this.pageToEdit, 'config.top.elements', [])
    const bottomElements = get(this.pageToEdit, 'config.bottom.elements', [])
    const gridElement = {
      id:
        this.pageToEdit.type === 'makaira-productgroup'
          ? 'detail-grid'
          : 'grid',
    }
    const noGrid = ['page', 'snippet'].includes(this.pageToEdit.type)

    if (noGrid) {
      return [...topElements, ...bottomElements]
    }

    return [...topElements, gridElement, ...bottomElements]
  }

  updateElements(newElements = []) {
    const noGrid = ['page', 'snippet'].includes(this.pageToEdit.type)

    if (noGrid) {
      set(this.pageToEdit, 'config.top.elements', newElements)
      set(this.pageToEdit, 'config.bottom.elements', [])

      this.isDirty = true
      return
    }

    const gridIndex = newElements.findIndex(
      (element) => element.id === 'grid' || element.id === 'detail-grid'
    )
    const newTopElements = newElements.slice(0, gridIndex)
    const newBottomElements = newElements.slice(
      gridIndex + 1,
      newElements.length
    )

    set(this.pageToEdit, 'config.top.elements', newTopElements)
    set(this.pageToEdit, 'config.bottom.elements', newBottomElements)

    this.isDirty = true
  }

  setContentLanguage(lang) {
    this.contentLanguage = lang
  }

  get metadata() {
    return get(this.pageToEdit, ['metadata', this.contentLanguage], {})
  }

  setMetadataField(field, value) {
    // empty metadata is array? o.O
    if (
      !this.pageToEdit.metadata ||
      (this.pageToEdit.metadata && this.pageToEdit.metadata.length === 0)
    ) {
      this.pageToEdit.metadata = {}
    }

    set(this.pageToEdit, ['metadata', this.contentLanguage, field], value)
    this.isDirty = true
  }

  getFieldTimeStatus(field) {
    const personas = get(
      field,
      ['properties', this.contentLanguage, 'personas'],
      {}
    )
    const showClock =
      Object.values(personas).findIndex((group) => group.isTimed) !== -1
    const showUser =
      Object.keys(personas).findIndex(
        (groupName) =>
          personas[groupName].active &&
          groupName !== this.getDefaultPublishConfig().identifier
      ) !== -1

    return {
      showClock,
      showUser,
    }
  }

  getActiveField(field) {
    return get(field, ['properties', this.contentLanguage, 'active'], false)
  }

  *fetchPublishConfig() {
    try {
      const { data } = yield api.common.getPersonas()
      this.publishConfig = data
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
      // message.error('Something went wrong...')
    }
  }

  handleInputProps(props) {
    const newProps = { ...props }

    newProps.onChange = (value) => this.setValueForField(props.id, value)
    newProps.value = this.getValueForField(props.id, props.selectLanguage)

    // modify for checkbox
    if (props.type === 'checkbox') {
      newProps.onChange = (e) =>
        this.setValueForField(props.id, e.target.checked)
      newProps.checked = this.getValueForField(props.id)
      delete newProps.description

      return newProps
    }

    if (props.type === 'select') {
      newProps.getPopupContainer = () =>
        document.querySelector('.page-editor-modal .shadow-scroll__body')
    }

    // modify for text input
    if (props.type === 'text') {
      newProps.onChange = (e) => this.setValueForField(props.id, e.target.value)
    }

    if (props.type === 'file') {
      newProps.onRemove = () => {
        this.setValueForField(props.id, '')
        PageEditorPreviewStore.setReloadIframe(true)
      }
      newProps.level = 1
    }

    if (props.type === 'array') {
      newProps.value = Array.isArray(newProps.value) ? newProps.value : []
    }

    if (props.type === 'date') {
      newProps.value = newProps.value && moment(newProps.value)
    }

    delete newProps.description
    delete newProps.label

    return newProps
  }

  duplicateElement(selectedElement) {
    const elements = this.elements
    const elementIndex = elements.findIndex(
      (el) => el.id === selectedElement.id
    )
    const newElement = cloneDeep(selectedElement)
    newElement.id = uuidV4()

    const insert = (arr, index, newItem) => [
      ...arr.slice(0, index),
      newItem,
      ...arr.slice(index),
    ]

    this.updateElements(insert(elements, elementIndex + 1, newElement))
    this.isDirty = true
  }

  /**
   * Returns the string that will be displayed in the <PageTitle />.
   */
  get pageTitle() {
    const { type } = this.pageToEdit
    let title

    if (type === 'page' || type === 'snippet') {
      title = get(this.pageToEdit, ['metadata', this.contentLanguage, 'title'])
    } else {
      title = this.extraLanguageInformation[this.contentLanguage]?.title ?? ''
    }

    if (title !== '' && title !== undefined) return title

    if (type === 'page') return t('New Landing page...')

    if (type === 'snippet') return t('New Snippet...')

    return t('no title yet')
  }

  get publishedURLPath() {
    return get(this.extraLanguageInformation, [this.contentLanguage, 'url'], '')
  }

  insertElements(arr, index, newItem) {
    return flatten([...arr.slice(0, index), newItem, ...arr.slice(index)])
  }

  copyToClipboard(field = {}, single = false) {
    const { enterpriseConfiguration } = UIStore
    const { storageType } = enterpriseConfiguration

    if (!single) {
      field = {
        ...this.pageToEdit.config,
        id: this.pageToEdit.relatedDocumentId || this.pageToEdit.id,
      }
      this.clipboard.push({
        type: 'page',
        title: this.extraLanguageInformation,
        data: field,
        storageType,
      })
    } else {
      this.clipboard.push({
        type: 'single',
        data: field,
        storageType,
      })
    }
    // handle copied animation
    this.elementsWithAnimationPlaying.push(field.id)
    setTimeout(() => {
      this.elementsWithAnimationPlaying =
        this.elementsWithAnimationPlaying.filter((id) => id !== field.id)
    }, COPY_ANIMATION_DURATION)

    // only keep 15 newest elements
    if (this.clipboard.length > CLIPBOARD_MAX_LENGTH) {
      this.clipboard = drop(
        this.clipboard,
        this.clipboard.length - CLIPBOARD_MAX_LENGTH
      )
    }

    localStorage.setItem(
      'page_editor_clipboard',
      JSON.stringify(this.clipboard)
    )
  }

  updateClipboard() {
    this.clipboard = JSON.parse(
      localStorage.getItem('page_editor_clipboard') || '[]'
    )
  }

  clearClipboard() {
    this.clipboard = []
    localStorage.removeItem('page_editor_clipboard')
  }

  pasteAction({
    clipboardData,
    eventSource, // 'element' | 'page'
    currentElement = {},
  }) {
    const { enterpriseConfiguration } = UIStore
    const { storageType } = enterpriseConfiguration

    const {
      languagesFromElement,
      originalLanguagesNeedMapping,
      destinationLanguagesNeedMapping,
    } = this.checkShowWarningMappingModal(clipboardData, storageType)

    this.languagesMapping = {
      clipboardData: this.createNewId(clipboardData),
      languagesFromElement: languagesFromElement,
      originalLanguages: originalLanguagesNeedMapping,
      destinationLanguages: destinationLanguagesNeedMapping,
      currentElement,
      eventSource,
    }

    if (this.showWarningMappingModal) return

    this.handlePaste()

    this.isDirty = true

    this.resetMappingState()
  }

  checkShowWarningMappingModal(clipboardData, storageType) {
    let element = clipboardData.data

    if (clipboardData.type === 'page') {
      // get first element for page type
      element = [
        ...get(clipboardData.data, 'top.elements', []),
        ...get(clipboardData.data, 'bottom.elements', []),
      ][0]
    }

    const languagesFromElement = Object.keys(element.properties)

    const originalLanguagesNeedMapping = difference(
      languagesFromElement,
      UIStore.languages
    )
    const destinationLanguagesNeedMapping = difference(
      UIStore.languages,
      languagesFromElement
    )

    this.isDifferenceLanguageMapping =
      destinationLanguagesNeedMapping.length > 0
    this.isDifferenceStorageType = clipboardData.storageType !== storageType

    this.showWarningMappingModal =
      this.isDifferenceLanguageMapping || this.isDifferenceStorageType

    return {
      languagesFromElement,
      originalLanguagesNeedMapping,
      destinationLanguagesNeedMapping,
    }
  }

  createNewId(clipboardData) {
    const newData = cloneDeep(clipboardData)
    if (newData.type === 'page') {
      let topElementsToPaste = get(clipboardData, 'data.top.elements', [])
      topElementsToPaste = topElementsToPaste.map((element) => ({
        ...element,
        id: uuidV4(),
      }))
      let bottomElementsToPaste = get(clipboardData, 'data.bottom.elements', [])
      bottomElementsToPaste = bottomElementsToPaste.map((element) => ({
        ...element,
        id: uuidV4(),
      }))
      set(newData, 'data.top.elements', topElementsToPaste)
      set(newData, 'data.bottom.elements', bottomElementsToPaste)
    }

    if (newData.type === 'single') {
      newData.data = {
        ...newData.data,
        id: uuidV4(),
      }
    }

    return newData
  }

  handlePaste() {
    const { clipboardData } = this.languagesMapping

    this.removeUnusedLanguageData()

    if (clipboardData.type === 'page') {
      this.handlePastePage()
    } else {
      this.handlePasteElement()
    }
  }

  removeUnusedLanguageData() {
    const { clipboardData, originalLanguages } = this.languagesMapping
    const data = clipboardData.data || {}

    originalLanguages.forEach((lang) => {
      if (clipboardData.type === 'page') {
        if (data?.top?.elements) {
          data.top.elements.forEach(
            (element) => delete element.properties[lang]
          )
        }
        if (data?.bottom?.elements) {
          data.bottom.elements.forEach(
            (element) => delete element.properties[lang]
          )
        }
      } else {
        delete data.properties[lang]
      }
    })
  }

  handlePastePage() {
    const { eventSource, clipboardData } = this.languagesMapping

    if (eventSource === 'element') {
      // from element action
      this.handlePastePageFromElementAction(clipboardData.data)
    } else {
      // from page action
      this.handlePastePageFromPageAction(clipboardData.data)
    }
  }

  handlePastePageFromElementAction(data) {
    const { currentElement } = this.languagesMapping

    const elementsToPaste = [
      ...get(data, 'top.elements', []),
      ...get(data, 'bottom.elements', []),
    ]
    const elements = this.elements
    const elementIndex = elements.findIndex((el) => el.id === currentElement.id)

    this.updateElements(
      this.insertElements(elements, elementIndex + 1, elementsToPaste)
    )
  }

  handlePastePageFromPageAction(data) {
    if (this.pageToEdit.type === 'page') {
      const elementsToPaste = [
        ...get(data, 'top.elements', []),
        ...get(data, 'bottom.elements', []),
      ]
      let topElements = get(this.pageToEdit, `config.top.elements`)

      if (!topElements) {
        set(this.pageToEdit, `config.top.elements`, elementsToPaste)
      } else {
        topElements.push(...elementsToPaste)
      }
    } else {
      // add element to correct region for all other type
      let topElements = get(this.pageToEdit, `config.top.elements`)
      const topElementsToPaste = get(data, 'top.elements', [])

      if (!topElements) {
        set(this.pageToEdit, `config.top.elements`, topElementsToPaste)
      } else {
        topElements.push(...topElementsToPaste)
      }

      let bottomElements = get(this.pageToEdit, `config.bottom.elements`)
      const bottomElementsToPaste = get(data, 'bottom.elements', [])
      if (!bottomElements) {
        set(this.pageToEdit, `config.bottom.elements`, bottomElementsToPaste)
      } else {
        bottomElements.push(...bottomElementsToPaste)
      }
    }
  }

  handlePasteElement() {
    const { eventSource, clipboardData, currentElement } = this.languagesMapping
    const newElement = clipboardData.data

    // from element action
    if (eventSource === 'element') {
      const elements = this.elements
      const elementIndex = elements.findIndex(
        (el) => el.id === currentElement.id
      )
      this.updateElements(
        this.insertElements(elements, elementIndex + 1, newElement)
      )
    } else {
      // from page action
      let pageElements = get(this.pageToEdit, `config.top.elements`)
      if (!pageElements) {
        set(this.pageToEdit, `config.top.elements`, [newElement])
      } else {
        pageElements.push(newElement)
      }
    }
  }

  mappingAndPaste(languageMapped) {
    if (this.isDifferenceLanguageMapping) {
      this.mapLanguage(languageMapped)
    }

    if (this.isDifferenceStorageType) {
      this.mapMedia()
    }

    this.handlePaste()

    this.isDirty = true

    this.closeWarningMappingModal()
  }

  mapLanguage(languageMapped = []) {
    const { clipboardData } = this.languagesMapping

    languageMapped.forEach((languages) => {
      if (languages.destinationLanguage && languages.originalLanguage) {
        // handle page language mapping
        if (clipboardData.type === 'page') {
          this.mappingLanguageForPage(clipboardData.data, languages)
        } else {
          // handle element langugage mapping
          this.mappingLanguageForElement(clipboardData.data, languages)
        }
      }
    })
  }

  mappingLanguageForPage(data, languages) {
    if (data?.top?.elements) {
      data.top.elements.forEach((element) => {
        const content = this.getElementContent(
          element,
          languages.originalLanguage
        )
        set(element, ['properties', languages.destinationLanguage], content)
      })
    }
    if (data?.bottom?.elements) {
      data.bottom.elements.forEach((element) => {
        const content = this.getElementContent(
          element,
          languages.originalLanguage
        )
        set(element, ['properties', languages.destinationLanguage], content)
      })
    }
  }

  mappingLanguageForElement(data, languages) {
    const content = this.getElementContent(data, languages.originalLanguage)

    set(data, ['properties', languages.destinationLanguage], content)
  }

  getElementContent(element, language) {
    const keepContent = language !== 'no-content'
    const content = cloneDeep(
      get(
        element,
        [
          'properties',
          keepContent
            ? language
            : this.languagesMapping.languagesFromElement[0],
        ],
        {}
      )
    )

    // init default content if user dont want to keep content when mapping
    if (!keepContent) {
      const component = ComponentStore.getByIdentifier(element.component)
      if (component) {
        content.content = component.fields.reduce((acc, currentField) => {
          return {
            ...acc,
            ...getDefaultFieldState(currentField),
          }
        }, {})
      }
    }

    return content
  }

  mapMedia() {
    const { clipboardData } = this.languagesMapping
    const data = clipboardData.data

    if (clipboardData.type === 'page') {
      if (data?.top?.elements) {
        data.top.elements.forEach(
          (element) =>
            (element.properties = removeMediaData(element.properties))
        )
      }
      if (data?.bottom?.elements) {
        data.bottom.elements.forEach(
          (element) =>
            (element.properties = removeMediaData(element.properties))
        )
      }
    } else {
      data.properties = removeMediaData(data.properties)
    }
  }

  closeWarningMappingModal() {
    this.showWarningMappingModal = false
    this.resetMappingState()
  }

  resetMappingState() {
    this.languagesMapping = {}
    this.mediaMapping = {}
    this.isDifferenceStorageType = false
    this.isDifferenceLanguageMapping = false
    this.showWarningMappingModal = false
  }

  setPreviewPopup(popup) {
    this.previewPopup = popup
  }

  setPreviewPopupStatus(status) {
    this.previewPopupStatus = status
  }

  setHoveringInputId(id) {
    this.hoveringInputId = id
  }

  /**
   * Copies the whole content from the given language to the currently active language
   * for all elements on the current page.
   *
   * @param language {string} The code of the language to copy from.
   */
  copyContentFromLanguageForPage(language) {
    const updatedConfig = cloneDeep(this.pageToEdit.config)

    updatedConfig.top.elements.forEach((element) => {
      element.properties[this.contentLanguage].content =
        element.properties[language].content
    })

    updatedConfig.bottom.elements.forEach((element) => {
      element.properties[this.contentLanguage].content =
        element.properties[language].content
    })

    this.pageToEdit = { ...this.pageToEdit, config: updatedConfig }
    this.isDirty = true
  }

  /**
   * Copies the whole content from the given language to the currently active language
   * for the given element.
   *
   * @param language {string} The code of the language to copy from.
   * @param targetElement {object} The element for which the content should be copied.
   */
  copyContentFromLanguageForElement(language, targetElement) {
    const updatedConfig = cloneDeep(this.pageToEdit.config)

    updatedConfig.top.elements.forEach((element) => {
      if (targetElement.id === element.id)
        element.properties[this.contentLanguage].content =
          element.properties[language].content
    })

    updatedConfig.bottom.elements.forEach((element) => {
      if (targetElement.id === element.id)
        element.properties[this.contentLanguage].content =
          element.properties[language].content
    })

    this.pageToEdit = { ...this.pageToEdit, config: updatedConfig }
    this.isDirty = true
  }

  getFieldContentPath(field, fields) {
    if (!field.parentId) return []

    const parentField = fields.find((f) => f.uuid === field.parentId)

    return [...this.getFieldContentPath(parentField, fields), field.id]
  }

  checkInvalidField(obj, path) {
    function checkInvalidFieldRecursion(current, remainingPath, fullPath = []) {
      // if remainingPath empty
      if (remainingPath.length === 0) {
        // check current is empty then return fullPath
        if (current === '') return fullPath.join('.')
        return false
      }

      // get the next key
      const key = remainingPath[0]
      // get the rest of remainingPath (ignore the first key)
      const nextPath = remainingPath.slice(1)

      if (has(current, key)) {
        const nextCurrent = current[key]
        if (Array.isArray(nextCurrent)) {
          const invalidId = nextCurrent
            .map((item, index) =>
              checkInvalidFieldRecursion(item, nextPath, [
                ...fullPath,
                key,
                index,
              ])
            )
            .find((invalid) => invalid !== false)
          if (invalidId !== undefined) return invalidId
          return false
        } else
          return checkInvalidFieldRecursion(nextCurrent, nextPath, [
            ...fullPath,
            key,
          ])
      }

      return false
    }

    return checkInvalidFieldRecursion(obj, path)
  }

  checkValidateRequireFields() {
    const invalidData = {
      component: {},
      field: {},
    }
    const elements = [
      ...get(this.pageToEdit, 'config.top.elements', []),
      ...get(this.pageToEdit, 'config.bottom.elements', []),
    ]

    elements.forEach((element) => {
      const component = ComponentStore.getByIdentifier(element.component) || {}
      const sections = component.sections || []
      const allFields = flattenDeep(
        sections?.map(ComponentStore.getAllFields) || []
      )
      allFields.forEach((field) => {
        if (field.required) {
          const contentPath = this.getFieldContentPath(field, allFields)
          const invalidId = this.checkInvalidField(
            get(element, ['properties', this.contentLanguage, 'content']),
            [...contentPath]
          )
          if (invalidId) {
            invalidData.component[element.id] = true
            invalidData.field[invalidId] = true
          }
        }
      })
    })

    this.invalidData = invalidData
  }
}

export default new EditorStore()
