import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'
import ABTestStore from '../../../../stores/ABTestStore'

import Timeline from '../../../../views/ABTesting/Timeline'

/**
 * Component that gets the width of the graph and pass it down to the timeline.
 * The timeline component needs to know how wide it should be and this wrapper
 * keeps track of it. Updates the wide always when the graph is resized.
 * Also, the offset width is taken from the graph to match the width of the
 * label-width of the X-Axis and the timeline start lines up with the graph.
 *
 * All props are directly passed through the Timeline-Component.
 *
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const TimelineWrapper = (props) => {
  const [timelineWidth, setTimelineWidth] = useState(-1)
  const [chartWidth, setChartWidth] = useState(-1)
  const [offsetWidth, setOffsetWidth] = useState(-1)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateTimelineWidth = useCallback(
    debounce(updateTimelineWidth, 500),
    []
  )

  useEffect(() => {
    const chartWrappers = document.getElementsByClassName(
      'results-chart__chart'
    )
    const chartWrapper = chartWrappers.length > 0 ? chartWrappers[0] : null

    if (!chartWrapper) {
      console.log('No chart could be found')
      return
    }

    const resizeObserver = new ResizeObserver(() => {
      debouncedUpdateTimelineWidth()
    })

    resizeObserver.observe(chartWrapper)

    return () => {
      resizeObserver.unobserve(chartWrapper)
    }
  }, [debouncedUpdateTimelineWidth])

  useEffect(() => {
    updateTimelineWidth()
    // eslint-disable-next-line
  }, [ABTestStore.selectedTableMetric, ABTestStore.displayedVariants])

  function updateTimelineWidth() {
    const charts = document.getElementsByClassName('apexcharts-inner')

    const chart = charts.length > 0 ? charts[0] : null

    if (!chart) {
      console.error('No chart could be found. Timeline can not be calculated')
      return
    }

    // The chart is transformed to the bottom and right to make place for the label.
    // We need the size of the label here so that we know the offset for the timeline
    const offset = chart?.transform?.baseVal?.consolidate()?.matrix?.e ?? -1

    // And now we need also the width of the chart itself
    const width = chart?.getBBox()?.width ?? -1

    setTimelineWidth(offset + width)
    setOffsetWidth(offset)
    setChartWidth(width)
  }

  if (timelineWidth < 0) return null

  return (
    <div
      style={{
        width: timelineWidth,
      }}
    >
      <Timeline width={chartWidth} offset={offsetWidth} {...props} />
    </div>
  )
}

export default TimelineWrapper
