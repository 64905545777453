import { forwardRef } from 'react'
import Text from '../Text'

const BannerRow = forwardRef(({ position, banner = {} }, ref) => {
  return (
    <div className="product-preview__list-row banner-preview" ref={ref}>
      <div className="product-preview__list-column product-preview__position">
        <Text size="foxtrot" weight="bold">
          {position < 10 && '0'}
          {position}
        </Text>
      </div>
      <div className="product-preview__list-column product-preview__product">
        <img src={banner.fields?.picture_url_main} width={96} height={96} />
        <div className="product-preview__list-meta">
          <Text className="product-preview__product-info" variant="book">
            {banner.fields?.description}
          </Text>
          <Text className="product-preview__product-title" weight="bold">
            {banner.fields?.title}
          </Text>
        </div>
      </div>
    </div>
  )
})

export default BannerRow
