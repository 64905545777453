import { observer } from 'mobx-react-lite'
import Icon from '../../Icon'
import SliderInput from '../../SliderInput'
import Text from '../../Text'
import { FilterSectionStore } from '../../../stores'
import { t } from '../../../utils'

export const BOOSTING_VALUE = ['low', 'med', 'high']

function BoostingField({ type }) {
  const { editingFilter, setEditingFilter } = FilterSectionStore
  if (type !== 'boosting' || !editingFilter.field || !editingFilter.operator) {
    return null
  }

  return (
    <>
      <Icon width="20px" height="20px" symbol="arrow-down" />
      <div
        className="filter-section__boosting-input-wrapper"
        style={{ width: 288 }}
      >
        <Text weight="bold">{t('Boosting Strength')}</Text>
        <SliderInput
          max={3}
          min={1}
          value={BOOSTING_VALUE.indexOf(editingFilter.boostingStrength) + 1}
          onChange={(value) =>
            setEditingFilter({
              ...editingFilter,
              boostingStrength: BOOSTING_VALUE[value - 1],
            })
          }
        />
      </div>
    </>
  )
}

export default observer(BoostingField)
