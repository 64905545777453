import { observer } from 'mobx-react-lite'
import React from 'react'
import Switch from '../../../components/Switch'
import Text from '../../../components/Text'
import { RecommendationStore } from '../../../stores'
import { t } from '../../../utils'

const DisplaySection = ({ disableEdit }) => {
  const reco = RecommendationStore.currentRecoDetail

  return (
    <div className="recommendations__edit-section">
      <Text size="echo" weight="bold">
        {t('Display')}
      </Text>
      <Switch
        type="horizontal"
        title={
          <div className="text-input__label">
            {t("Randomise the results' order")}
          </div>
        }
        checked={reco.randomness}
        onChange={(value) =>
          RecommendationStore.onChangeField(reco, 'randomness', value)
        }
        disabled={disableEdit}
      />
    </div>
  )
}

export default observer(DisplaySection)
