import moment from 'moment/moment'
import Chart from 'react-apexcharts'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import TimelineWrapper from './TimelineWrapper'

import { prepareDataForChart, t } from '../../../../utils'

import ABTestStore from '../../../../stores/ABTestStore'

import './resultsChart.styl'

const midnight = new Date()
midnight.setHours(1)
midnight.setMinutes(0)
midnight.setSeconds(0)

const defaultOptions = {
  stroke: {
    curve: 'smooth',
    width: 2,
    dashArray: [0, 2, 2, 0, 2, 2, 0, 2, 2],
  },
  legend: {
    show: false,
  },
  grid: {
    row: {
      colors: ['var(--neutral-10)'], // takes an array which will be repeated on columns
      opacity: 1,
    },
  },
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
}

const UNITS = {
  sum_visit_length: 's',
  nb_conversions: '',
  nb_orders: '',
  nb_orders_revenue: '€',
  bounce_count: '%',
}

// The number of days that are displayed after the current date to show the
// future of the timeline.
const AMOUNT_FUTURE_DAYS = 14
// The number of days that should at least be displayed.
// Chart will always start at the day, where the test was started and will end
// at the end date OR at current date + AMOUNT_FUTURE_DAYS days. If start-date
// to current-date + 14 is not at least MINIMAL_AMOUNT_OF_DAYS the range will
// be increased so that MINIMAL_AMOUNT_OF_DAYS are displayed.
const MINIMAL_AMOUNT_OF_DAYS = 35

const ResultsChart = () => {
  const [timelineStartDate, setTimelineStartDate] = useState(moment())
  const [timelineEndDate, setTimelineEndDate] = useState(moment())

  useEffect(() => {
    const graph = ABTestStore.graph
    const days = ABTestStore.selectedTableMetric
      ? Object.keys(graph[ABTestStore.selectedTableMetric])
      : []

    const startDate = moment(days[0])
    const endDate = moment(days[days.length - 1])

    // When the test is still running we will also display some days in the future.
    if (ABTestStore.testDetail.running) {
      endDate.add(AMOUNT_FUTURE_DAYS, 'days')
      const rangeDays = endDate.diff(startDate, 'days')
      if (rangeDays < MINIMAL_AMOUNT_OF_DAYS) {
        endDate.add(MINIMAL_AMOUNT_OF_DAYS - rangeDays, 'days')
      }
    }

    endDate.startOf('day')

    // TODO: Fix this timezone problem
    endDate.hours(2)

    setTimelineStartDate(startDate)
    setTimelineEndDate(endDate)
    // eslint-disable-next-line
  }, [ABTestStore.graph, ABTestStore.selectedTableMetric])

  // const label = t('Revenue per visit in €')

  useEffect(() => {
    ABTestStore.fetchTimelineTest(true)
  }, [])

  const {
    originalData,
    variationData,
    standardDevA1,
    standardDevA2,
    standardDevB1,
    standardDevB2,
    variationLegend,
    variationC,
    standardDevC1,
    standardDevC2,
  } = prepareDataForChart(
    ABTestStore.selectedTableMetric,
    ABTestStore.graph,
    ABTestStore.editingExperiment.segments
  )

  const series = []

  if (ABTestStore.displayedVariants.includes(0)) {
    series.push({ name: t('Original'), data: originalData, color: '#53C099' })
    series.push({
      name: t('Upper limit ') + '(Original)',
      data: standardDevA1,
      color: '#53C099',
    })
    series.push({
      name: t('Lower limit ') + '(Original)',
      data: standardDevA2,
      color: '#53C099',
    })
  }

  if (ABTestStore.displayedVariants.includes(1)) {
    series.push({
      name: variationLegend,
      data: variationData,
      color: '#A6CEE3',
    })
    series.push({
      name: t('Upper limit ') + '(' + variationLegend + ')',
      data: standardDevB1,
      color: '#A6CEE3',
    })
    series.push({
      name: t('Lower limit ') + '(' + variationLegend + ')',
      data: standardDevB2,
      color: '#A6CEE3',
    })
  }

  if (ABTestStore.displayedVariants.includes(2)) {
    series.push({
      name: variationLegend + 'C',
      data: variationC,
      color: '#1f78b4',
    })
    series.push({
      name: t('Upper limit ') + '(' + variationLegend + 'C)',
      data: standardDevC1,
      color: '#1f78b4',
    })
    series.push({
      name: t('Lower limit ') + '(' + variationLegend + 'C)',
      data: standardDevC2,
      color: '#1f78b4',
    })
  }

  return (
    <div className="results-chart">
      <TimelineWrapper
        startDate={timelineStartDate.toISOString()}
        endDate={timelineEndDate.toISOString()}
        items={ABTestStore.timelineWithoutDraft}
        type="day"
        variant="detail"
        testInFocus={ABTestStore.testDetail.id}
      />

      <Chart
        className="results-chart__chart"
        options={{
          ...defaultOptions,
          xaxis: {
            type: 'datetime',
            labels: {
              show: false,
            },
            max: timelineEndDate.valueOf(),
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return (
                  Number(value).toLocaleString('de-DE', {
                    maximumFractionDigits: 3,
                  }) +
                  ' ' +
                  UNITS[ABTestStore.selectedTableMetric]
                )
              },
              style: {
                colors: 'var(--neutral-60)',
                fontSize: '12px',
                fontFamily: 'var(--font-family-regular)',
                fontWeight: 300,
              },
            },
          },
        }}
        series={series}
        type="line"
        height={300}
      />
    </div>
  )
}

export default observer(ResultsChart)
