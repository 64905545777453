import { observer } from 'mobx-react-lite'
import RcSelect from 'rc-select'
import Icon from '../Icon'
import { get } from 'lodash'
import { useMemo, useState } from 'react'

import Text from '../Text'
import Checkbox from '../Checkbox'
import { t } from '../../utils'

import './tags-select.styl'
import './style.styl'

const TagsProductInput = ({
  onChange,
  value = [],
  onSearch,
  searchedProducts = [],
  allProductByIds,
  languageSelect,
  title,
  keyPathTitle = 'fields.title',
  fallbackKeyPathTitle,
  className,
}) => {
  const [values, setValues] = useState(value)
  const [search, setSearch] = useState('')

  const handleOnSearch = (searchPhase) => {
    onSearch(searchPhase)
    setSearch(searchPhase)
  }

  const renderOptions = useMemo(
    () =>
      searchedProducts.map((product) => ({
        label: (
          <div className="product-tags__list-row">
            <Checkbox readOnly checked={values.includes(product.id)} />
            <div className="product-tags__list-column product-tags__product">
              {get(product, 'fields.picture_url_main') && (
                <img src={get(product, 'fields.picture_url_main')} />
              )}

              <div className="product-tags__list-meta">
                <Text className="product-tags__product-title" weight="bold">
                  {get(product, keyPathTitle) ||
                    get(product, fallbackKeyPathTitle) ||
                    t('no title yet')}
                </Text>
                {get(product, 'fields.ean') && (
                  <div>
                    <Text className="product-tags__product-info" variant="book">
                      {get(product, 'fields.ean')}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </div>
        ),
        value: product.id,
      })),
    [values, searchedProducts, keyPathTitle, fallbackKeyPathTitle]
  )

  const onRemoveItem = (id) => () => {
    const newValues = values.filter((v) => v !== id)
    setValues(newValues)
    onChange(newValues)
  }

  return (
    <div className={className}>
      {title && <Text className="text-input__label">{title}</Text>}
      <div className="product-tags__input-wrapper">
        <div className="product-tags__language">
          <Icon symbol="search" width="15px" height="15px" />
          {languageSelect}
        </div>

        <RcSelect
          dropdownStyle={{ maxHeight: '400px' }}
          virtual={false}
          prefixCls="tags-select"
          value={values}
          title={title}
          optionFilterProp="label"
          mode="multiple"
          onSearch={handleOnSearch}
          searchValue={search}
          showSearch
          autoClearSearchValue={false}
          dropdownClassName="product-tags__dropdown"
          filterOption={(_, option) => option}
          optionLabelProp="children"
          tagRender={() => null}
          onChange={(newValue) => {
            if (newValue.length > 0) {
              const product = allProductByIds[newValue[newValue.length - 1]]
              if (!product) {
                return
              }
            }
            setValues(newValue)
            onChange(newValue)
          }}
          options={renderOptions}
        />
      </div>
      <div className="product-tags__item-wrapper">
        {values.map((id) => {
          const product = allProductByIds[id]

          return (
            <div key={id} className="product-tags__item">
              <Text weight="bold" size="bravo">
                {get(product, keyPathTitle) ||
                  get(product, fallbackKeyPathTitle) ||
                  t('no title yet')}
              </Text>
              <Icon
                onClick={onRemoveItem(id)}
                width="16px"
                height="16px"
                symbol="xmark"
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default observer(TagsProductInput)
