import { observer } from 'mobx-react-lite'
import React from 'react'
import Button from '../../components/Button'
import { UserManagementStore } from '../../stores'
import { t } from '../../utils'

const ResendButton = ({ row }) => {
  const resendInvitation = (e) => {
    e.stopPropagation()
    UserManagementStore.resendInvitation(row)
  }

  return (
    <Button
      loading={row.user_id === UserManagementStore.resendingUserId}
      onClick={resendInvitation}
      variant="reduced"
      icon="envelope"
      iconPosition="left"
    >
      {t('Resend invitation mail')}
    </Button>
  )
}

export default observer(ResendButton)
