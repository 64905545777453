import { observer } from 'mobx-react-lite'
import FileUpload from '../../components/FileUpload'
import TextInput from '../../components/TextInput'
import { BannerStore } from '../../stores'
import { t } from '../../utils'

function Images() {
  const { updatingBanner, updateBannerImages, deviceTypes, bannerTypes } =
    BannerStore
  const bannerType =
    bannerTypes.find((type) => type.id === updatingBanner.type) || {}

  if (deviceTypes.length === 0) {
    return null
  }

  return (
    <div className="card">
      <div className="card__title">{t('Image')}</div>
      <div className="card__content image-section">
        {bannerType?.deviceTypeIds?.map((deviceTypeId, index) => {
          const deviceType =
            deviceTypes.find((type) => type.id === deviceTypeId) || {}
          let image =
            updatingBanner.images?.find(
              (img) => img.deviceTypeId === deviceTypeId
            ) || {}

          return (
            <div key={index} className="image-item">
              <FileUpload
                title={deviceType.name}
                value={image.data || ''}
                onChange={(value) =>
                  updateBannerImages(updatingBanner, {
                    deviceTypeId,
                    data: value,
                  })
                }
                onRemove={() =>
                  updateBannerImages(updatingBanner, { deviceTypeId, data: {} })
                }
              />
              <TextInput
                value={image.alt || ''}
                label={'Alt-Text'}
                onChange={(e) =>
                  updateBannerImages(updatingBanner, {
                    deviceTypeId,
                    alt: e.target.value,
                  })
                }
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default observer(Images)
