export const googleCategories = [
  {
    value: '537',
    label: 'Baby & Kleinkind',
  },
  {
    value: '4678',
    label: 'Baby & Kleinkind > Baby Badebedarf',
  },
  {
    value: '4679',
    label: 'Baby & Kleinkind > Baby Badebedarf > Babybadewannen',
  },
  {
    value: '7082',
    label: 'Baby & Kleinkind > Baby Badebedarf > Shampoo-Schutzschilder',
  },
  {
    value: '5252',
    label: 'Baby & Kleinkind > Baby Gesundheitsbedarf',
  },
  {
    value: '6290',
    label:
      'Baby & Kleinkind > Baby Gesundheitsbedarf > Baby-Gesundheits- & Pflegesets',
  },
  {
    value: '5253',
    label: 'Baby & Kleinkind > Baby Gesundheitsbedarf > Nasensauger',
  },
  {
    value: '7309',
    label:
      'Baby & Kleinkind > Baby Gesundheitsbedarf > Reinigungstücher für Schnuller',
  },
  {
    value: '566',
    label: 'Baby & Kleinkind > Baby Gesundheitsbedarf > Schnuller & Beißringe',
  },
  {
    value: '7016',
    label:
      'Baby & Kleinkind > Baby Gesundheitsbedarf > Schnullerclips & -ketten',
  },
  {
    value: '540',
    label: 'Baby & Kleinkind > Babysicherheit',
  },
  {
    value: '542',
    label: 'Baby & Kleinkind > Babysicherheit > Baby- & Haustierschutzgitter',
  },
  {
    value: '544',
    label: 'Baby & Kleinkind > Babysicherheit > Baby-Schutzleisten',
  },
  {
    value: '541',
    label: 'Baby & Kleinkind > Babysicherheit > Babyfone',
  },
  {
    value: '5049',
    label: 'Baby & Kleinkind > Babysicherheit > Babysicherheitsgurte & -leinen',
  },
  {
    value: '543',
    label:
      'Baby & Kleinkind > Babysicherheit > Babysicherungen & Schutzvorrichtungen',
  },
  {
    value: '6869',
    label:
      'Baby & Kleinkind > Babysicherheit > Zubehör für Baby- & Haustierschutzgitter',
  },
  {
    value: '2847',
    label: 'Baby & Kleinkind > Babyspielwaren',
  },
  {
    value: '3661',
    label: 'Baby & Kleinkind > Babyspielwaren > ABC-Lernspielzeuge',
  },
  {
    value: '7198',
    label: 'Baby & Kleinkind > Babyspielwaren > Baby-Aktiv-Spielzeug',
  },
  {
    value: '7191',
    label: 'Baby & Kleinkind > Babyspielwaren > Baby-Mobile-Zubehör',
  },
  {
    value: '1242',
    label: 'Baby & Kleinkind > Babyspielwaren > Baby-Mobiles',
  },
  {
    value: '1241',
    label: 'Baby & Kleinkind > Babyspielwaren > Babylauflernhilfen',
  },
  {
    value: '1244',
    label: 'Baby & Kleinkind > Babyspielwaren > Babyrasseln',
  },
  {
    value: '560',
    label: 'Baby & Kleinkind > Babyspielwaren > Babyschaukeln & Türhopser',
  },
  {
    value: '555',
    label: 'Baby & Kleinkind > Babyspielwaren > Babywippen',
  },
  {
    value: '539',
    label: 'Baby & Kleinkind > Babyspielwaren > Lauf- & Spielgitter',
  },
  {
    value: '7360',
    label: 'Baby & Kleinkind > Babyspielwaren > Schnuller & Beruhigung',
  },
  {
    value: '3860',
    label:
      'Baby & Kleinkind > Babyspielwaren > Sortier-, Stapel- & Steckspielzeug',
  },
  {
    value: '1243',
    label: 'Baby & Kleinkind > Babyspielwaren > Spiel- & Krabbeldecken',
  },
  {
    value: '3459',
    label: 'Baby & Kleinkind > Babyspielwaren > Zieh- & Schiebespielzeug',
  },
  {
    value: '2764',
    label: 'Baby & Kleinkind > Babytransport',
  },
  {
    value: '547',
    label: 'Baby & Kleinkind > Babytransport > Baby- & Kleinkindautositze',
  },
  {
    value: '538',
    label: 'Baby & Kleinkind > Babytransport > Babyträger',
  },
  {
    value: '568',
    label: 'Baby & Kleinkind > Babytransport > Kinderwagen',
  },
  {
    value: '4386',
    label: 'Baby & Kleinkind > Babytransportzubehör',
  },
  {
    value: '5845',
    label:
      'Baby & Kleinkind > Babytransportzubehör > Einkaufswagen- & Hochstuhlbezüge',
  },
  {
    value: '8537',
    label: 'Baby & Kleinkind > Babytransportzubehör > Fußsäcke',
  },
  {
    value: '4486',
    label:
      'Baby & Kleinkind > Babytransportzubehör > Zubehör für Baby- & Kleinkindautositze',
  },
  {
    value: '4916',
    label: 'Baby & Kleinkind > Babytransportzubehör > Zubehör für Babyträger',
  },
  {
    value: '4387',
    label: 'Baby & Kleinkind > Babytransportzubehör > Zubehör für Kinderwagen',
  },
  {
    value: '5859',
    label: 'Baby & Kleinkind > Geschenksets für Babys',
  },
  {
    value: '6899',
    label: 'Baby & Kleinkind > Puckdecken',
  },
  {
    value: '561',
    label: 'Baby & Kleinkind > Stillen & Füttern',
  },
  {
    value: '5629',
    label: 'Baby & Kleinkind > Stillen & Füttern > Baby-Spucktücher',
  },
  {
    value: '564',
    label: 'Baby & Kleinkind > Stillen & Füttern > Babyflaschen',
  },
  {
    value: '4768',
    label: 'Baby & Kleinkind > Stillen & Füttern > Babypflege-Timer',
  },
  {
    value: '5296',
    label:
      'Baby & Kleinkind > Stillen & Füttern > Flaschenwärmer & Sterilisatoren',
  },
  {
    value: '6950',
    label: 'Baby & Kleinkind > Stillen & Füttern > Lerntassen',
  },
  {
    value: '2125',
    label: 'Baby & Kleinkind > Stillen & Füttern > Lätzchen',
  },
  {
    value: '565',
    label: 'Baby & Kleinkind > Stillen & Füttern > Milchpumpen',
  },
  {
    value: '505366',
    label: 'Baby & Kleinkind > Stillen & Füttern > Milchpumpenzubehör',
  },
  {
    value: '7234',
    label: 'Baby & Kleinkind > Stillen & Füttern > Muttermilchbehälter',
  },
  {
    value: '562',
    label:
      'Baby & Kleinkind > Stillen & Füttern > Nahrung für Babys & Kleinkinder',
  },
  {
    value: '5720',
    label:
      'Baby & Kleinkind > Stillen & Füttern > Nahrung für Babys & Kleinkinder > Baby-Snacks',
  },
  {
    value: '5721',
    label:
      'Baby & Kleinkind > Stillen & Füttern > Nahrung für Babys & Kleinkinder > Babygetreide',
  },
  {
    value: '5719',
    label:
      'Baby & Kleinkind > Stillen & Füttern > Nahrung für Babys & Kleinkinder > Babynahrung',
  },
  {
    value: '5718',
    label:
      'Baby & Kleinkind > Stillen & Füttern > Nahrung für Babys & Kleinkinder > Babysäfte',
  },
  {
    value: '8436',
    label:
      'Baby & Kleinkind > Stillen & Füttern > Nahrung für Babys & Kleinkinder > Getränke und Milchmischgetränke',
  },
  {
    value: '563',
    label:
      'Baby & Kleinkind > Stillen & Füttern > Nahrung für Babys & Kleinkinder > Milchnahrung',
  },
  {
    value: '5630',
    label: 'Baby & Kleinkind > Stillen & Füttern > Sauger für Babyflaschen',
  },
  {
    value: '503762',
    label: 'Baby & Kleinkind > Stillen & Füttern > Stillhütchen & -einlagen',
  },
  {
    value: '5298',
    label: 'Baby & Kleinkind > Stillen & Füttern > Stillkissen',
  },
  {
    value: '8075',
    label: 'Baby & Kleinkind > Stillen & Füttern > Stillkissenbezüge',
  },
  {
    value: '5843',
    label: 'Baby & Kleinkind > Stillen & Füttern > Stilltücher',
  },
  {
    value: '6952',
    label: 'Baby & Kleinkind > Töpfchentraining',
  },
  {
    value: '552',
    label: 'Baby & Kleinkind > Töpfchentraining > Töpfchen',
  },
  {
    value: '6953',
    label: 'Baby & Kleinkind > Töpfchentraining > Töpfchentrainingsets',
  },
  {
    value: '548',
    label: 'Baby & Kleinkind > Wickelbedarf',
  },
  {
    value: '553',
    label: 'Baby & Kleinkind > Wickelbedarf > Baby-Reinigungstücher',
  },
  {
    value: '2949',
    label: 'Baby & Kleinkind > Wickelbedarf > Behandlung von Windeldermatitis',
  },
  {
    value: '7200',
    label:
      'Baby & Kleinkind > Wickelbedarf > Behälter & Warmhalter für Baby-Reinigungstücher',
  },
  {
    value: '5628',
    label: 'Baby & Kleinkind > Wickelbedarf > Wickelauflagen',
  },
  {
    value: '502999',
    label: 'Baby & Kleinkind > Wickelbedarf > Wickelauflagen & Bezüge',
  },
  {
    value: '6883',
    label: 'Baby & Kleinkind > Wickelbedarf > Windel-Organizer',
  },
  {
    value: '6971',
    label: 'Baby & Kleinkind > Wickelbedarf > Windelbeutel',
  },
  {
    value: '550',
    label: 'Baby & Kleinkind > Wickelbedarf > Windeleimer',
  },
  {
    value: '7001',
    label: 'Baby & Kleinkind > Wickelbedarf > Windeleimerzubehör',
  },
  {
    value: '6949',
    label: 'Baby & Kleinkind > Wickelbedarf > Windeleinlagen',
  },
  {
    value: '551',
    label: 'Baby & Kleinkind > Wickelbedarf > Windeln',
  },
  {
    value: '7014',
    label: 'Baby & Kleinkind > Wickelbedarf > Windelsets',
  },
  {
    value: '166',
    label: 'Bekleidung & Accessoires',
  },
  {
    value: '1604',
    label: 'Bekleidung & Accessoires > Bekleidung',
  },
  {
    value: '1594',
    label: 'Bekleidung & Accessoires > Bekleidung > Anzüge',
  },
  {
    value: '5183',
    label:
      'Bekleidung & Accessoires > Bekleidung > Anzüge > Anzüge & Hosenanzüge',
  },
  {
    value: '1516',
    label: 'Bekleidung & Accessoires > Bekleidung > Anzüge > Kostüme',
  },
  {
    value: '1580',
    label: 'Bekleidung & Accessoires > Bekleidung > Anzüge > Smokings',
  },
  {
    value: '182',
    label:
      'Bekleidung & Accessoires > Bekleidung > Baby- & Kleinkindbekleidung',
  },
  {
    value: '5622',
    label:
      'Bekleidung & Accessoires > Bekleidung > Baby- & Kleinkindbekleidung > Baby- & Kleinkind-Kombis',
  },
  {
    value: '5425',
    label:
      'Bekleidung & Accessoires > Bekleidung > Baby- & Kleinkindbekleidung > Baby- & Kleinkind-Oberbekleidung',
  },
  {
    value: '5423',
    label:
      'Bekleidung & Accessoires > Bekleidung > Baby- & Kleinkindbekleidung > Baby- & Kleinkindsocken',
  },
  {
    value: '5411',
    label:
      'Bekleidung & Accessoires > Bekleidung > Baby- & Kleinkindbekleidung > Baby-Bodys',
  },
  {
    value: '5412',
    label:
      'Bekleidung & Accessoires > Bekleidung > Baby- & Kleinkindbekleidung > Baby-Schlafkleidung & -Schlafsäcke',
  },
  {
    value: '5549',
    label:
      'Bekleidung & Accessoires > Bekleidung > Baby- & Kleinkindbekleidung > Babyüberhosen',
  },
  {
    value: '5409',
    label:
      'Bekleidung & Accessoires > Bekleidung > Baby- & Kleinkindbekleidung > Bademode für Babys & Kleinkinder',
  },
  {
    value: '5424',
    label:
      'Bekleidung & Accessoires > Bekleidung > Baby- & Kleinkindbekleidung > Kleider für Babys & Kleinkinder',
  },
  {
    value: '5621',
    label:
      'Bekleidung & Accessoires > Bekleidung > Baby- & Kleinkindbekleidung > Kleinkindunterwäsche',
  },
  {
    value: '5410',
    label:
      'Bekleidung & Accessoires > Bekleidung > Baby- & Kleinkindbekleidung > Oberteile für Babys & Kleinkinder',
  },
  {
    value: '5408',
    label:
      'Bekleidung & Accessoires > Bekleidung > Baby- & Kleinkindbekleidung > Unterteile für Babys & Kleinkinder',
  },
  {
    value: '211',
    label: 'Bekleidung & Accessoires > Bekleidung > Bademode',
  },
  {
    value: '5441',
    label: 'Bekleidung & Accessoires > Bekleidung > Brautmoden',
  },
  {
    value: '5330',
    label:
      'Bekleidung & Accessoires > Bekleidung > Brautmoden > Brautjungfernkleider',
  },
  {
    value: '5329',
    label: 'Bekleidung & Accessoires > Bekleidung > Brautmoden > Brautkleider',
  },
  {
    value: '5182',
    label: 'Bekleidung & Accessoires > Bekleidung > Einteiler',
  },
  {
    value: '7132',
    label: 'Bekleidung & Accessoires > Bekleidung > Einteiler > Latzhosen',
  },
  {
    value: '5250',
    label: 'Bekleidung & Accessoires > Bekleidung > Einteiler > Overalls',
  },
  {
    value: '5490',
    label:
      'Bekleidung & Accessoires > Bekleidung > Einteiler > Turn- & Gymnastikanzüge',
  },
  {
    value: '204',
    label: 'Bekleidung & Accessoires > Bekleidung > Hosen',
  },
  {
    value: '2271',
    label: 'Bekleidung & Accessoires > Bekleidung > Kleider',
  },
  {
    value: '7313',
    label: 'Bekleidung & Accessoires > Bekleidung > Kombinationen',
  },
  {
    value: '208',
    label: 'Bekleidung & Accessoires > Bekleidung > Nachtwäsche & Loungewear',
  },
  {
    value: '2302',
    label:
      'Bekleidung & Accessoires > Bekleidung > Nachtwäsche & Loungewear > Bademäntel',
  },
  {
    value: '5713',
    label:
      'Bekleidung & Accessoires > Bekleidung > Nachtwäsche & Loungewear > Loungewear',
  },
  {
    value: '5513',
    label:
      'Bekleidung & Accessoires > Bekleidung > Nachtwäsche & Loungewear > Nachthemden',
  },
  {
    value: '2580',
    label:
      'Bekleidung & Accessoires > Bekleidung > Nachtwäsche & Loungewear > Schlafanzüge',
  },
  {
    value: '1581',
    label: 'Bekleidung & Accessoires > Bekleidung > Röcke',
  },
  {
    value: '212',
    label: 'Bekleidung & Accessoires > Bekleidung > Shirts & Tops',
  },
  {
    value: '207',
    label: 'Bekleidung & Accessoires > Bekleidung > Shorts',
  },
  {
    value: '5344',
    label: 'Bekleidung & Accessoires > Bekleidung > Skorts',
  },
  {
    value: '5322',
    label: 'Bekleidung & Accessoires > Bekleidung > Sportbekleidung',
  },
  {
    value: '3951',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > American Football-Hosen',
  },
  {
    value: '5378',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Boxshorts',
  },
  {
    value: '5460',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Jagdbekleidung',
  },
  {
    value: '5462',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Jagdbekleidung > Ghillie-Anzüge',
  },
  {
    value: '5461',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Jagdbekleidung > Jagd- & Angelwesten',
  },
  {
    value: '5552',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Jagdbekleidung > Jagd- & taktische Hosen',
  },
  {
    value: '5379',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Kampfsporthosen',
  },
  {
    value: '5517',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Motorradbekleidung',
  },
  {
    value: '5463',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Motorradbekleidung > Motorrad-Kombis',
  },
  {
    value: '7003',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Motorradbekleidung > Motorradhosen',
  },
  {
    value: '6006',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Motorradbekleidung > Motorradjacken',
  },
  {
    value: '5555',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Paintballbekleidung',
  },
  {
    value: '5697',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Radsport-Funktionsbekleidung',
  },
  {
    value: '3188',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Radsport-Funktionsbekleidung > Fahrradhosen (kurz)',
  },
  {
    value: '3729',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Radsport-Funktionsbekleidung > Fahrradhosen (lang)',
  },
  {
    value: '3455',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Radsport-Funktionsbekleidung > Fahrradtrikots',
  },
  {
    value: '3128',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Radsport-Funktionsbekleidung > Fahrradträgerhosen',
  },
  {
    value: '6087',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Radsport-Funktionsbekleidung > Radsport-Zeitfahranzüge',
  },
  {
    value: '499979',
    label:
      'Bekleidung & Accessoires > Bekleidung > Sportbekleidung > Tanzkleider, Tanzröcke und Tanzkostüme',
  },
  {
    value: '5388',
    label:
      'Bekleidung & Accessoires > Bekleidung > Traditionelle & Festkleidung',
  },
  {
    value: '6031',
    label:
      'Bekleidung & Accessoires > Bekleidung > Traditionelle & Festkleidung > Dirndl',
  },
  {
    value: '5674',
    label:
      'Bekleidung & Accessoires > Bekleidung > Traditionelle & Festkleidung > Hakama-Hosen',
  },
  {
    value: '5673',
    label:
      'Bekleidung & Accessoires > Bekleidung > Traditionelle & Festkleidung > Kimono-Oberbekleidung',
  },
  {
    value: '5343',
    label:
      'Bekleidung & Accessoires > Bekleidung > Traditionelle & Festkleidung > Kimonos',
  },
  {
    value: '5483',
    label:
      'Bekleidung & Accessoires > Bekleidung > Traditionelle & Festkleidung > Religiöse Festkleidung',
  },
  {
    value: '8149',
    label:
      'Bekleidung & Accessoires > Bekleidung > Traditionelle & Festkleidung > Religiöse Festkleidung > Tauf- & Kommunionskleider',
  },
  {
    value: '8248',
    label:
      'Bekleidung & Accessoires > Bekleidung > Traditionelle & Festkleidung > Saris',
  },
  {
    value: '7281',
    label:
      'Bekleidung & Accessoires > Bekleidung > Traditionelle & Festkleidung > Trachten Lederhosen',
  },
  {
    value: '6227',
    label:
      'Bekleidung & Accessoires > Bekleidung > Traditionelle & Festkleidung > Traditionelle japanische formelle Kleidung',
  },
  {
    value: '5676',
    label:
      'Bekleidung & Accessoires > Bekleidung > Traditionelle & Festkleidung > Yukata',
  },
  {
    value: '2306',
    label: 'Bekleidung & Accessoires > Bekleidung > Uniformen',
  },
  {
    value: '5484',
    label: 'Bekleidung & Accessoires > Bekleidung > Uniformen > Arbeitshosen',
  },
  {
    value: '5878',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Fliegeruniformen',
  },
  {
    value: '5949',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Militäruniformen',
  },
  {
    value: '206',
    label: 'Bekleidung & Accessoires > Bekleidung > Uniformen > Schuluniformen',
  },
  {
    value: '3414',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Sicherheitsdienst-Bekleidung',
  },
  {
    value: '3598',
    label: 'Bekleidung & Accessoires > Bekleidung > Uniformen > Sportuniformen',
  },
  {
    value: '3888',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Sportuniformen > American Football-Uniformen',
  },
  {
    value: '3191',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Sportuniformen > Baseballuniformen',
  },
  {
    value: '3439',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Sportuniformen > Basketballuniformen',
  },
  {
    value: '3683',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Sportuniformen > Cheerleading-Uniformen',
  },
  {
    value: '5564',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Sportuniformen > Fußballuniformen',
  },
  {
    value: '3958',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Sportuniformen > Hockeyuniformen',
  },
  {
    value: '4003',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Sportuniformen > Kampfsportuniformen',
  },
  {
    value: '3724',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Sportuniformen > Kricketuniformen',
  },
  {
    value: '3852',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Sportuniformen > Ringeruniformen',
  },
  {
    value: '3253',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Sportuniformen > Schiedsrichteruniformen',
  },
  {
    value: '3379',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Sportuniformen > Softballuniformen',
  },
  {
    value: '7235',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Uniformen für das Restaurantgewerbe',
  },
  {
    value: '7236',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Uniformen für das Restaurantgewerbe > Kochhosen',
  },
  {
    value: '2396',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Uniformen für das Restaurantgewerbe > Kochjacken',
  },
  {
    value: '7237',
    label:
      'Bekleidung & Accessoires > Bekleidung > Uniformen > Uniformen für das Restaurantgewerbe > Kochmützen',
  },
  {
    value: '2292',
    label: 'Bekleidung & Accessoires > Bekleidung > Uniformen > Ärztekittel',
  },
  {
    value: '213',
    label: 'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken',
  },
  {
    value: '7207',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > BH-Zubehör',
  },
  {
    value: '7210',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > BH-Zubehör > BH-Einlagen',
  },
  {
    value: '7211',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > BH-Zubehör > BH-Träger & -Erweiterungen',
  },
  {
    value: '7208',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > BH-Zubehör > BH-Träger-Pads',
  },
  {
    value: '7209',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > BH-Zubehör > Brustaufkleber',
  },
  {
    value: '214',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Büstenhalter',
  },
  {
    value: '1772',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Dessous',
  },
  {
    value: '2563',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Dessous-Accessoires',
  },
  {
    value: '1675',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Dessous-Accessoires > Strumpfbänder',
  },
  {
    value: '2160',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Dessous-Accessoires > Strumpfhalter',
  },
  {
    value: '1807',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Lange Unterhosen',
  },
  {
    value: '2963',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Petticoats & Rüschenhöschen',
  },
  {
    value: '1578',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Shapewear',
  },
  {
    value: '209',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Socken',
  },
  {
    value: '215',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Strumpfhosen',
  },
  {
    value: '5327',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Suspensorien',
  },
  {
    value: '2745',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Unterhemden',
  },
  {
    value: '5834',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Unterhosen',
  },
  {
    value: '2562',
    label:
      'Bekleidung & Accessoires > Bekleidung > Unterwäsche & Socken > Unterwäsche',
  },
  {
    value: '203',
    label: 'Bekleidung & Accessoires > Bekleidung > Überbekleidung',
  },
  {
    value: '5506',
    label: 'Bekleidung & Accessoires > Bekleidung > Überbekleidung > Chaps',
  },
  {
    value: '5598',
    label:
      'Bekleidung & Accessoires > Bekleidung > Überbekleidung > Mäntel & Jacken',
  },
  {
    value: '3066',
    label:
      'Bekleidung & Accessoires > Bekleidung > Überbekleidung > Regenanzüge',
  },
  {
    value: '5514',
    label:
      'Bekleidung & Accessoires > Bekleidung > Überbekleidung > Regenhosen',
  },
  {
    value: '5909',
    label:
      'Bekleidung & Accessoires > Bekleidung > Überbekleidung > Schneehosen und - anzüge',
  },
  {
    value: '1831',
    label: 'Bekleidung & Accessoires > Bekleidung > Überbekleidung > Westen',
  },
  {
    value: '167',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires',
  },
  {
    value: '5390',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Accessoires für traditionelle Kleidung',
  },
  {
    value: '5687',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Accessoires für traditionelle Kleidung > Obis',
  },
  {
    value: '5685',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Accessoires für traditionelle Kleidung > Tabi-Socken',
  },
  {
    value: '4179',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Ansteckbuttons',
  },
  {
    value: '5942',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Armstulpen',
  },
  {
    value: '168',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Bandanas & Stofftücher',
  },
  {
    value: '5941',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Beinstulpen',
  },
  {
    value: '5443',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Braut-Accessoires',
  },
  {
    value: '5446',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Braut-Accessoires > Brautschleier',
  },
  {
    value: '5207',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Einstecktücher',
  },
  {
    value: '5114',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Fächer',
  },
  {
    value: '169',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Gürtel',
  },
  {
    value: '3913',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Gürtelschnallen',
  },
  {
    value: '171',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires',
  },
  {
    value: '5914',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Diademe',
  },
  {
    value: '8451',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Duttkissen',
  },
  {
    value: '1948',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Haargabeln',
  },
  {
    value: '1483',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Haargummis',
  },
  {
    value: '502988',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Haarklammern, -nadeln & -clips',
  },
  {
    value: '5915',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Haarkränze',
  },
  {
    value: '2477',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Haarkämme',
  },
  {
    value: '6183',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Haarnetze',
  },
  {
    value: '4057',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Haarverlängerungen',
  },
  {
    value: '181',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Perücken',
  },
  {
    value: '7305',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Perücken-Accessoires',
  },
  {
    value: '7306',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Perücken-Accessoires > Perückenkleber',
  },
  {
    value: '7307',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Perücken-Accessoires > Perückennetze',
  },
  {
    value: '1662',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Haaraccessoires > Stirnbänder',
  },
  {
    value: '7230',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Halswärmer',
  },
  {
    value: '170',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Handschuhe & Fausthandschuhe',
  },
  {
    value: '179',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Hosenträger',
  },
  {
    value: '173',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Hüte',
  },
  {
    value: '6985',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Knopfnieten',
  },
  {
    value: '2020',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Kopfbekleidung & -tücher',
  },
  {
    value: '7054',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Kopfbekleidung & -tücher > Fascinators',
  },
  {
    value: '1922',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Kopfbekleidung & -tücher > Kopfschmuck',
  },
  {
    value: '5939',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Kopfbekleidung & -tücher > Turbane',
  },
  {
    value: '6984',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Kragenstäbchen',
  },
  {
    value: '176',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Krawatten',
  },
  {
    value: '180',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Krawattennadeln',
  },
  {
    value: '6268',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Leis',
  },
  {
    value: '193',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Manschettenknöpfe',
  },
  {
    value: '7133',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Muffe',
  },
  {
    value: '6238',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Ohrenschützer',
  },
  {
    value: '177',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Schals & Halstücher',
  },
  {
    value: '502987',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Schwangerschaftsgürtel & Bauchstützen',
  },
  {
    value: '1893',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Schweißbänder',
  },
  {
    value: '499972',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Schärpen',
  },
  {
    value: '178',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Sonnenbrillen',
  },
  {
    value: '1786',
    label: 'Bekleidung & Accessoires > Bekleidungsaccessoires > Sturmhauben',
  },
  {
    value: '5422',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Zubehör für Baby- & Kleinkindbekleidung',
  },
  {
    value: '5624',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Zubehör für Baby- & Kleinkindbekleidung > Baby- & Kleinkindhandschuhe',
  },
  {
    value: '5626',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Zubehör für Baby- & Kleinkindbekleidung > Babyschutzbekleidung',
  },
  {
    value: '5623',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Zubehör für Baby- & Kleinkindbekleidung > Gürtel für Babys & Kleinkinder',
  },
  {
    value: '5625',
    label:
      'Bekleidung & Accessoires > Bekleidungsaccessoires > Zubehör für Baby- & Kleinkindbekleidung > Kopfbedeckungen für Babys & Kleinkinder',
  },
  {
    value: '6552',
    label: 'Bekleidung & Accessoires > Handtaschen & Geldbörsenaccessoires',
  },
  {
    value: '5841',
    label:
      'Bekleidung & Accessoires > Handtaschen & Geldbörsenaccessoires > Brieftaschenketten',
  },
  {
    value: '6460',
    label:
      'Bekleidung & Accessoires > Handtaschen & Geldbörsenaccessoires > Scheckheftetuis',
  },
  {
    value: '175',
    label:
      'Bekleidung & Accessoires > Handtaschen & Geldbörsenaccessoires > Schlüsselanhänger',
  },
  {
    value: '6277',
    label:
      'Bekleidung & Accessoires > Handtaschen & Geldbörsenaccessoires > Tragebänder',
  },
  {
    value: '6551',
    label: 'Bekleidung & Accessoires > Handtaschen, Geldbörsen & Etuis',
  },
  {
    value: '6170',
    label:
      'Bekleidung & Accessoires > Handtaschen, Geldbörsen & Etuis > Ausweistaschen',
  },
  {
    value: '2668',
    label:
      'Bekleidung & Accessoires > Handtaschen, Geldbörsen & Etuis > Geldbeutel & Geldklammern',
  },
  {
    value: '3032',
    label:
      'Bekleidung & Accessoires > Handtaschen, Geldbörsen & Etuis > Handtaschen',
  },
  {
    value: '6169',
    label:
      'Bekleidung & Accessoires > Handtaschen, Geldbörsen & Etuis > Visitenkartenetuis',
  },
  {
    value: '184',
    label: 'Bekleidung & Accessoires > Kostüme & Accessoires',
  },
  {
    value: '5192',
    label:
      'Bekleidung & Accessoires > Kostüme & Accessoires > Kostümaccessoires',
  },
  {
    value: '7304',
    label:
      'Bekleidung & Accessoires > Kostüme & Accessoires > Kostümaccessoires > Glatzen',
  },
  {
    value: '8017',
    label:
      'Bekleidung & Accessoires > Kostüme & Accessoires > Kostümaccessoires > Kostüm-Accessoire-Sets',
  },
  {
    value: '8200',
    label:
      'Bekleidung & Accessoires > Kostüme & Accessoires > Kostümaccessoires > Kostüm-Handschuhe',
  },
  {
    value: '5426',
    label:
      'Bekleidung & Accessoires > Kostüme & Accessoires > Kostümaccessoires > Kostümhüte',
  },
  {
    value: '8018',
    label:
      'Bekleidung & Accessoires > Kostüme & Accessoires > Kostümaccessoires > Plastikschmuck',
  },
  {
    value: '500118',
    label:
      'Bekleidung & Accessoires > Kostüme & Accessoires > Kostümaccessoires > Spezial-Schminkeffekte',
  },
  {
    value: '500008',
    label:
      'Bekleidung & Accessoires > Kostüme & Accessoires > Kostümaccessoires > Tabakwarenimitate',
  },
  {
    value: '5907',
    label:
      'Bekleidung & Accessoires > Kostüme & Accessoires > Kostümaccessoires > Umhänge für Kostüme',
  },
  {
    value: '5193',
    label:
      'Bekleidung & Accessoires > Kostüme & Accessoires > Kostüme & Verkleidungen',
  },
  {
    value: '5387',
    label: 'Bekleidung & Accessoires > Kostüme & Accessoires > Kostümschuhe',
  },
  {
    value: '5194',
    label: 'Bekleidung & Accessoires > Kostüme & Accessoires > Masken',
  },
  {
    value: '188',
    label: 'Bekleidung & Accessoires > Schmuck',
  },
  {
    value: '201',
    label: 'Bekleidung & Accessoires > Schmuck > Armbanduhren & Taschenuhren',
  },
  {
    value: '191',
    label: 'Bekleidung & Accessoires > Schmuck > Armbänder',
  },
  {
    value: '197',
    label: 'Bekleidung & Accessoires > Schmuck > Broschen und Anstecknadeln',
  },
  {
    value: '192',
    label: 'Bekleidung & Accessoires > Schmuck > Charms & Anhänger',
  },
  {
    value: '189',
    label: 'Bekleidung & Accessoires > Schmuck > Fußkettchen',
  },
  {
    value: '196',
    label: 'Bekleidung & Accessoires > Schmuck > Halsketten',
  },
  {
    value: '190',
    label: 'Bekleidung & Accessoires > Schmuck > Körperschmuck',
  },
  {
    value: '194',
    label: 'Bekleidung & Accessoires > Schmuck > Ohrringe',
  },
  {
    value: '200',
    label: 'Bekleidung & Accessoires > Schmuck > Ringe',
  },
  {
    value: '6463',
    label: 'Bekleidung & Accessoires > Schmuck > Schmucksets',
  },
  {
    value: '5122',
    label: 'Bekleidung & Accessoires > Schmuck > Uhrenaccessoires',
  },
  {
    value: '7471',
    label:
      'Bekleidung & Accessoires > Schmuck > Uhrenaccessoires > Aufkleber für Uhren',
  },
  {
    value: '5123',
    label:
      'Bekleidung & Accessoires > Schmuck > Uhrenaccessoires > Uhrenarmbänder',
  },
  {
    value: '6870',
    label:
      'Bekleidung & Accessoires > Schmuck > Uhrenaccessoires > Uhrenbeweger',
  },
  {
    value: '1933',
    label: 'Bekleidung & Accessoires > Schuh-Accessoires',
  },
  {
    value: '7078',
    label: 'Bekleidung & Accessoires > Schuh-Accessoires > Gamaschen',
  },
  {
    value: '1856',
    label: 'Bekleidung & Accessoires > Schuh-Accessoires > Schnürsenkel',
  },
  {
    value: '2427',
    label: 'Bekleidung & Accessoires > Schuh-Accessoires > Sporen',
  },
  {
    value: '5567',
    label: 'Bekleidung & Accessoires > Schuh-Accessoires > Stiefelsocken',
  },
  {
    value: '5385',
    label: 'Bekleidung & Accessoires > Schuh-Accessoires > Überschuhe',
  },
  {
    value: '187',
    label: 'Bekleidung & Accessoires > Schuhe',
  },
  {
    value: '922',
    label: 'Bürobedarf',
  },
  {
    value: '923',
    label: 'Bürobedarf > Ablage & Organisation',
  },
  {
    value: '5997',
    label: 'Bürobedarf > Ablage & Organisation > Adressbücher',
  },
  {
    value: '6885',
    label: 'Bürobedarf > Ablage & Organisation > Akten- & Schreibmappen',
  },
  {
    value: '925',
    label: 'Bürobedarf > Ablage & Organisation > Aktenaufbewahrungsboxen',
  },
  {
    value: '6884',
    label: 'Bürobedarf > Ablage & Organisation > Aktenhüllen und Klemmhefter',
  },
  {
    value: '4312',
    label: 'Bürobedarf > Ablage & Organisation > Bindesysteme',
  },
  {
    value: '4303',
    label: 'Bürobedarf > Ablage & Organisation > Bindesysteme > Aktenordner',
  },
  {
    value: '4086',
    label:
      'Bürobedarf > Ablage & Organisation > Bindesysteme > Aktenordnerzubehör',
  },
  {
    value: '4212',
    label:
      'Bürobedarf > Ablage & Organisation > Bindesysteme > Aktenordnerzubehör > Einbandringe',
  },
  {
    value: '2139',
    label:
      'Bürobedarf > Ablage & Organisation > Bindesysteme > Aktenordnerzubehör > Klarsichthüllen',
  },
  {
    value: '4183',
    label:
      'Bürobedarf > Ablage & Organisation > Bindesysteme > Aktenordnerzubehör > Registerblätter',
  },
  {
    value: '7080',
    label: 'Bürobedarf > Ablage & Organisation > Bindesysteme > Bindegeräte',
  },
  {
    value: '4182',
    label: 'Bürobedarf > Ablage & Organisation > Bindesysteme > Binderücken',
  },
  {
    value: '926',
    label: 'Bürobedarf > Ablage & Organisation > CD-/DVD-Ordnungssysteme',
  },
  {
    value: '3062',
    label: 'Bürobedarf > Ablage & Organisation > Federmappen',
  },
  {
    value: '928',
    label: 'Bürobedarf > Ablage & Organisation > Geldkassetten',
  },
  {
    value: '927',
    label:
      'Bürobedarf > Ablage & Organisation > Kalender, Organizer & Zeitplaner',
  },
  {
    value: '5531',
    label: 'Bürobedarf > Ablage & Organisation > Karteikästen',
  },
  {
    value: '6177',
    label: 'Bürobedarf > Ablage & Organisation > Kartenhüllen',
  },
  {
    value: '5070',
    label: 'Bürobedarf > Ablage & Organisation > Postkartenorganizer',
  },
  {
    value: '6962',
    label: 'Bürobedarf > Ablage & Organisation > Postsortiersysteme',
  },
  {
    value: '930',
    label: 'Bürobedarf > Ablage & Organisation > Register- & Hängemappen',
  },
  {
    value: '6779',
    label: 'Bürobedarf > Ablage & Organisation > Rezeptkästen',
  },
  {
    value: '939',
    label: 'Bürobedarf > Ablage & Organisation > Schreibtischorganizer',
  },
  {
    value: '6171',
    label: 'Bürobedarf > Ablage & Organisation > Visitenkarten-Ständer',
  },
  {
    value: '6190',
    label: 'Bürobedarf > Ablage & Organisation > Visitenkartenbücher',
  },
  {
    value: '6174',
    label: 'Bürobedarf > Bücherzubehör',
  },
  {
    value: '6176',
    label: 'Bürobedarf > Bücherzubehör > Bucheinbände',
  },
  {
    value: '6175',
    label: 'Bürobedarf > Bücherzubehör > Buchständer & -stützen',
  },
  {
    value: '4941',
    label: 'Bürobedarf > Bücherzubehör > Leselampen',
  },
  {
    value: '93',
    label: 'Bürobedarf > Bücherzubehör > Lesezeichen',
  },
  {
    value: '2986',
    label: 'Bürobedarf > Büroarbeitsmittel',
  },
  {
    value: '943',
    label: 'Bürobedarf > Büroarbeitsmittel > Bleistiftspitzer',
  },
  {
    value: '505830',
    label: 'Bürobedarf > Büroarbeitsmittel > Brieföffner',
  },
  {
    value: '4341',
    label: 'Bürobedarf > Büroarbeitsmittel > Bürostempel',
  },
  {
    value: '947',
    label: 'Bürobedarf > Büroarbeitsmittel > Heftgeräte',
  },
  {
    value: '4499',
    label: 'Bürobedarf > Büroarbeitsmittel > Heftklammernzieher',
  },
  {
    value: '503746',
    label: 'Bürobedarf > Büroarbeitsmittel > Klebebandabroller',
  },
  {
    value: '935',
    label: 'Bürobedarf > Büroarbeitsmittel > Klemmbretter',
  },
  {
    value: '941',
    label: 'Bürobedarf > Büroarbeitsmittel > Lupen',
  },
  {
    value: '977',
    label: 'Bürobedarf > Büroarbeitsmittel > Schreibgeräte',
  },
  {
    value: '6065',
    label:
      'Bürobedarf > Büroarbeitsmittel > Schreibgeräte > Füller & Bleistifte',
  },
  {
    value: '6068',
    label:
      'Bürobedarf > Büroarbeitsmittel > Schreibgeräte > Füller & Bleistifte > Bleistifte',
  },
  {
    value: '981',
    label:
      'Bürobedarf > Büroarbeitsmittel > Schreibgeräte > Füller & Bleistifte > Bleistifte > Schreibbleistifte',
  },
  {
    value: '3026',
    label:
      'Bürobedarf > Büroarbeitsmittel > Schreibgeräte > Füller & Bleistifte > Bleistifte > Zeichenbleistifte & Buntstifte',
  },
  {
    value: '6066',
    label:
      'Bürobedarf > Büroarbeitsmittel > Schreibgeräte > Füller & Bleistifte > Füller- & Bleistift-Sets',
  },
  {
    value: '982',
    label:
      'Bürobedarf > Büroarbeitsmittel > Schreibgeräte > Füller & Bleistifte > Schreibstifte, Kugelschreiber & Füller',
  },
  {
    value: '980',
    label:
      'Bürobedarf > Büroarbeitsmittel > Schreibgeräte > Markierstifte & Textmarker',
  },
  {
    value: '6067',
    label:
      'Bürobedarf > Büroarbeitsmittel > Schreibgeräte > Multifunktions-Schreibgeräte',
  },
  {
    value: '4752',
    label: 'Bürobedarf > Büroarbeitsmittel > Schreibgeräte > Pastellkreiden',
  },
  {
    value: '978',
    label: 'Bürobedarf > Büroarbeitsmittel > Schreibgeräte > Tafelkreide',
  },
  {
    value: '979',
    label: 'Bürobedarf > Büroarbeitsmittel > Schreibgeräte > Wachsmalstifte',
  },
  {
    value: '2623',
    label: 'Bürobedarf > Büroarbeitsmittel > Schreibgeräte > Zeichenkohle',
  },
  {
    value: '4470',
    label: 'Bürobedarf > Büroarbeitsmittel > Schreibwerkzeugzubehör',
  },
  {
    value: '4472',
    label:
      'Bürobedarf > Büroarbeitsmittel > Schreibwerkzeugzubehör > Bleistiftminen',
  },
  {
    value: '4471',
    label:
      'Bürobedarf > Büroarbeitsmittel > Schreibwerkzeugzubehör > Füllertinte & -patronen',
  },
  {
    value: '7117',
    label:
      'Bürobedarf > Büroarbeitsmittel > Schreibwerkzeugzubehör > Marker-Nachfülltinte',
  },
  {
    value: '2883',
    label: 'Bürobedarf > Büroarbeitsmittel > Tischglocken',
  },
  {
    value: '950',
    label: 'Bürobedarf > Bürogeräte',
  },
  {
    value: '953',
    label: 'Bürobedarf > Bürogeräte > Aktenvernichter',
  },
  {
    value: '954',
    label: 'Bürobedarf > Bürogeräte > Diktiergeräte & Geräte zur Transkription',
  },
  {
    value: '337',
    label:
      'Bürobedarf > Bürogeräte > Elektronische Wörterbücher & Übersetzungsgeräte',
  },
  {
    value: '952',
    label: 'Bürobedarf > Bürogeräte > Etikettendrucker',
  },
  {
    value: '1708',
    label: 'Bürobedarf > Bürogeräte > Frankiermaschinen',
  },
  {
    value: '1625',
    label: 'Bürobedarf > Bürogeräte > Laminiergeräte',
  },
  {
    value: '955',
    label: 'Bürobedarf > Bürogeräte > Schreibmaschinen',
  },
  {
    value: '6404',
    label: 'Bürobedarf > Bürogeräte > Stempeluhren',
  },
  {
    value: '333',
    label: 'Bürobedarf > Bürogeräte > Taschenrechner',
  },
  {
    value: '499864',
    label: 'Bürobedarf > Bürogeräte > Taschenrechner-Zubehör',
  },
  {
    value: '6519',
    label: 'Bürobedarf > Büromatten & Stuhlunterlagen',
  },
  {
    value: '6462',
    label: 'Bürobedarf > Büromatten & Stuhlunterlagen > Arbeitsplatzmatten',
  },
  {
    value: '6520',
    label: 'Bürobedarf > Büromatten & Stuhlunterlagen > Büromatten',
  },
  {
    value: '6521',
    label: 'Bürobedarf > Büromatten & Stuhlunterlagen > Stuhlunterlagen',
  },
  {
    value: '6373',
    label: 'Bürobedarf > Bürowagen',
  },
  {
    value: '6180',
    label: 'Bürobedarf > Bürowagen > Aktenwagen',
  },
  {
    value: '6182',
    label: 'Bürobedarf > Bürowagen > Bücherwagen',
  },
  {
    value: '1996',
    label: 'Bürobedarf > Bürowagen > Medienwagen',
  },
  {
    value: '6179',
    label: 'Bürobedarf > Bürowagen > Mehrzweckwagen',
  },
  {
    value: '6181',
    label: 'Bürobedarf > Bürowagen > Postwagen',
  },
  {
    value: '5829',
    label: 'Bürobedarf > Impulsschweißgeräte',
  },
  {
    value: '8499',
    label: 'Bürobedarf > Laptophalter',
  },
  {
    value: '2435',
    label: 'Bürobedarf > Namensschilder',
  },
  {
    value: '2014',
    label: 'Bürobedarf > Papierbearbeitung',
  },
  {
    value: '6486',
    label: 'Bürobedarf > Papierbearbeitung > Blattwender',
  },
  {
    value: '1803',
    label: 'Bürobedarf > Papierbearbeitung > Briefbeschwerer',
  },
  {
    value: '6467',
    label: 'Bürobedarf > Papierbearbeitung > Locher',
  },
  {
    value: '2207',
    label: 'Bürobedarf > Papierbearbeitung > Papierfalzmaschinen',
  },
  {
    value: '1836',
    label: 'Bürobedarf > Papierbearbeitung > Papierrüttler',
  },
  {
    value: '6178',
    label: 'Bürobedarf > Papierbearbeitung > Schreibunterlagen',
  },
  {
    value: '964',
    label: 'Bürobedarf > Präsentationsbedarf',
  },
  {
    value: '966',
    label: 'Bürobedarf > Präsentationsbedarf > Ausstellungstafeln',
  },
  {
    value: '2263',
    label:
      'Bürobedarf > Präsentationsbedarf > Ausstellungstafeln > Hartschaumstoffplatten',
  },
  {
    value: '2401',
    label: 'Bürobedarf > Präsentationsbedarf > Ausstellungstafeln > Pinnwände',
  },
  {
    value: '2674',
    label:
      'Bürobedarf > Präsentationsbedarf > Ausstellungstafeln > Plakatkartons',
  },
  {
    value: '1627',
    label:
      'Bürobedarf > Präsentationsbedarf > Ausstellungstafeln > Trägerplatten',
  },
  {
    value: '7525',
    label:
      'Bürobedarf > Präsentationsbedarf > Ausstellungstafeln > Zubehör für Anschlagbretter',
  },
  {
    value: '7526',
    label:
      'Bürobedarf > Präsentationsbedarf > Ausstellungstafeln > Zubehör für Anschlagbretter > Dekorative Elemente für Anschlagbretter',
  },
  {
    value: '4492',
    label: 'Bürobedarf > Präsentationsbedarf > Dokumentenkameras',
  },
  {
    value: '967',
    label: 'Bürobedarf > Präsentationsbedarf > Flipchart-Blöcke',
  },
  {
    value: '968',
    label: 'Bürobedarf > Präsentationsbedarf > Flipcharts',
  },
  {
    value: '963',
    label: 'Bürobedarf > Präsentationsbedarf > Folien',
  },
  {
    value: '969',
    label: 'Bürobedarf > Präsentationsbedarf > Laserpointer',
  },
  {
    value: '970',
    label: 'Bürobedarf > Präsentationsbedarf > Lesepulte',
  },
  {
    value: '4465',
    label: 'Bürobedarf > Präsentationsbedarf > Präsentationsfernbedienungen',
  },
  {
    value: '965',
    label: 'Bürobedarf > Präsentationsbedarf > Schreibtafeln',
  },
  {
    value: '971',
    label: 'Bürobedarf > Präsentationsbedarf > Whiteboards',
  },
  {
    value: '8078',
    label: 'Bürobedarf > Schreibtischunterlagen',
  },
  {
    value: '932',
    label: 'Bürobedarf > Schreibwaren',
  },
  {
    value: '936',
    label: 'Bürobedarf > Schreibwaren > Büroklammern & Papierklammern',
  },
  {
    value: '934',
    label: 'Bürobedarf > Schreibwaren > Büroklebeband',
  },
  {
    value: '960',
    label: 'Bürobedarf > Schreibwaren > Etiketten & Anhängerschilder',
  },
  {
    value: '4377',
    label:
      'Bürobedarf > Schreibwaren > Etiketten & Anhängerschilder > Adressaufkleber',
  },
  {
    value: '5502',
    label:
      'Bürobedarf > Schreibwaren > Etiketten & Anhängerschilder > Klebebänder für Etikettiergeräte',
  },
  {
    value: '4137',
    label:
      'Bürobedarf > Schreibwaren > Etiketten & Anhängerschilder > Label Clips',
  },
  {
    value: '4154',
    label:
      'Bürobedarf > Schreibwaren > Etiketten & Anhängerschilder > Trennblätter',
  },
  {
    value: '4117',
    label:
      'Bürobedarf > Schreibwaren > Etiketten & Anhängerschilder > Versandanhänger',
  },
  {
    value: '4200',
    label:
      'Bürobedarf > Schreibwaren > Etiketten & Anhängerschilder > Versandaufkleber & -etiketten',
  },
  {
    value: '944',
    label: 'Bürobedarf > Schreibwaren > Gummibänder',
  },
  {
    value: '948',
    label: 'Bürobedarf > Schreibwaren > Heftklammern',
  },
  {
    value: '505805',
    label: 'Bürobedarf > Schreibwaren > Klebepads',
  },
  {
    value: '2591',
    label:
      'Bürobedarf > Schreibwaren > Korrekturflüssigkeiten, Korrekturstifte & Korrekturbänder',
  },
  {
    value: '8015',
    label: 'Bürobedarf > Schreibwaren > Laminierfolie & Schutzhüllen',
  },
  {
    value: '6319',
    label: 'Bürobedarf > Schreibwaren > Musterklammern',
  },
  {
    value: '956',
    label: 'Bürobedarf > Schreibwaren > Papierprodukte',
  },
  {
    value: '5264',
    label: 'Bürobedarf > Schreibwaren > Papierprodukte > Blanko-Ausweise',
  },
  {
    value: '3457',
    label: 'Bürobedarf > Schreibwaren > Papierprodukte > Briefpapier',
  },
  {
    value: '958',
    label: 'Bürobedarf > Schreibwaren > Papierprodukte > Briefumschläge',
  },
  {
    value: '962',
    label:
      'Bürobedarf > Schreibwaren > Papierprodukte > Drucker- & Kopierpapier',
  },
  {
    value: '1513',
    label: 'Bürobedarf > Schreibwaren > Papierprodukte > Feinkarton',
  },
  {
    value: '5918',
    label:
      'Bürobedarf > Schreibwaren > Papierprodukte > Geschäftsformulare & -belege',
  },
  {
    value: '2689',
    label: 'Bürobedarf > Schreibwaren > Papierprodukte > Haftnotizen',
  },
  {
    value: '959',
    label: 'Bürobedarf > Schreibwaren > Papierprodukte > Karteikarten',
  },
  {
    value: '5919',
    label: 'Bürobedarf > Schreibwaren > Papierprodukte > Kassenrollen',
  },
  {
    value: '961',
    label:
      'Bürobedarf > Schreibwaren > Papierprodukte > Notizbücher & Notizblöcke',
  },
  {
    value: '2658',
    label: 'Bürobedarf > Schreibwaren > Papierprodukte > Papier für Hefter',
  },
  {
    value: '3871',
    label: 'Bürobedarf > Schreibwaren > Papierprodukte > Postkarten',
  },
  {
    value: '6930',
    label: 'Bürobedarf > Schreibwaren > Papierprodukte > Scheckformulare',
  },
  {
    value: '957',
    label: 'Bürobedarf > Schreibwaren > Papierprodukte > Visitenkarten',
  },
  {
    value: '938',
    label: 'Bürobedarf > Schreibwaren > Radiergummis',
  },
  {
    value: '949',
    label: 'Bürobedarf > Schreibwaren > Reißzwecken & Pins',
  },
  {
    value: '2636',
    label: 'Bürobedarf > Versandbedarf',
  },
  {
    value: '975',
    label: 'Bürobedarf > Versandbedarf > Packband',
  },
  {
    value: '973',
    label: 'Bürobedarf > Versandbedarf > Umzugs- & Versandkartons',
  },
  {
    value: '974',
    label: 'Bürobedarf > Versandbedarf > Verpackungsmaterialien',
  },
  {
    value: '222',
    label: 'Elektronik',
  },
  {
    value: '223',
    label: 'Elektronik > Audio',
  },
  {
    value: '2165',
    label: 'Elektronik > Audio > Audiokomponenten',
  },
  {
    value: '241',
    label: 'Elektronik > Audio > Audiokomponenten > AV-Receiver',
  },
  {
    value: '236',
    label: 'Elektronik > Audio > Audiokomponenten > Audiomixer',
  },
  {
    value: '5129',
    label: 'Elektronik > Audio > Audiokomponenten > Audiotransmitter',
  },
  {
    value: '5130',
    label:
      'Elektronik > Audio > Audiokomponenten > Audiotransmitter > Bluetooth-Sender',
  },
  {
    value: '4035',
    label:
      'Elektronik > Audio > Audiokomponenten > Audiotransmitter > FM-Transmitter',
  },
  {
    value: '224',
    label: 'Elektronik > Audio > Audiokomponenten > Audioverstärker',
  },
  {
    value: '4493',
    label:
      'Elektronik > Audio > Audiokomponenten > Audioverstärker > Kopfhörerverstärker',
  },
  {
    value: '5381',
    label:
      'Elektronik > Audio > Audiokomponenten > Audioverstärker > Stereoverstärker',
  },
  {
    value: '6545',
    label: 'Elektronik > Audio > Audiokomponenten > Channel Strips',
  },
  {
    value: '6546',
    label: 'Elektronik > Audio > Audiokomponenten > DI-Boxen',
  },
  {
    value: '505771',
    label: 'Elektronik > Audio > Audiokomponenten > Kopfhörer & Headsets',
  },
  {
    value: '249',
    label: 'Elektronik > Audio > Audiokomponenten > Lautsprecher',
  },
  {
    value: '234',
    label: 'Elektronik > Audio > Audiokomponenten > Mikrofone',
  },
  {
    value: '246',
    label: 'Elektronik > Audio > Audiokomponenten > Signalprozessoren',
  },
  {
    value: '247',
    label:
      'Elektronik > Audio > Audiokomponenten > Signalprozessoren > Effektprozessoren',
  },
  {
    value: '248',
    label:
      'Elektronik > Audio > Audiokomponenten > Signalprozessoren > Equalizer',
  },
  {
    value: '5435',
    label:
      'Elektronik > Audio > Audiokomponenten > Signalprozessoren > Frequenzweichen',
  },
  {
    value: '3945',
    label:
      'Elektronik > Audio > Audiokomponenten > Signalprozessoren > Mikrofonvorverstärker',
  },
  {
    value: '5596',
    label:
      'Elektronik > Audio > Audiokomponenten > Signalprozessoren > Noise Gates & Kompressoren',
  },
  {
    value: '5369',
    label:
      'Elektronik > Audio > Audiokomponenten > Signalprozessoren > Phono-Vorverstärker',
  },
  {
    value: '5597',
    label:
      'Elektronik > Audio > Audiokomponenten > Signalprozessoren > Steuerungssysteme für PA-Anlagen',
  },
  {
    value: '505298',
    label: 'Elektronik > Audio > Audiokomponenten > Studio-Aufnahmesets',
  },
  {
    value: '242',
    label: 'Elektronik > Audio > Audioplayer & -rekorder',
  },
  {
    value: '226',
    label:
      'Elektronik > Audio > Audioplayer & -rekorder > CD-Player & -Rekorder',
  },
  {
    value: '244',
    label: 'Elektronik > Audio > Audioplayer & -rekorder > Diktiergeräte',
  },
  {
    value: '225',
    label: 'Elektronik > Audio > Audioplayer & -rekorder > Ghettoblaster',
  },
  {
    value: '252',
    label: 'Elektronik > Audio > Audioplayer & -rekorder > Heimkinosysteme',
  },
  {
    value: '4652',
    label: 'Elektronik > Audio > Audioplayer & -rekorder > Jukeboxen',
  },
  {
    value: '230',
    label: 'Elektronik > Audio > Audioplayer & -rekorder > Karaokesysteme',
  },
  {
    value: '243',
    label:
      'Elektronik > Audio > Audioplayer & -rekorder > Kassettenspieler & -Rekorder',
  },
  {
    value: '233',
    label: 'Elektronik > Audio > Audioplayer & -rekorder > MP3-Player',
  },
  {
    value: '5434',
    label: 'Elektronik > Audio > Audioplayer & -rekorder > Mehrspurrekorder',
  },
  {
    value: '235',
    label: 'Elektronik > Audio > Audioplayer & -rekorder > MiniDisc-Player',
  },
  {
    value: '256',
    label: 'Elektronik > Audio > Audioplayer & -rekorder > Plattenspieler',
  },
  {
    value: '6886',
    label: 'Elektronik > Audio > Audioplayer & -rekorder > Radios',
  },
  {
    value: '251',
    label: 'Elektronik > Audio > Audioplayer & -rekorder > Stereoanlagen',
  },
  {
    value: '8271',
    label: 'Elektronik > Audio > Audioplayer & -rekorder > Tonbandgeräte',
  },
  {
    value: '1420',
    label: 'Elektronik > Audio > Audiozubehör',
  },
  {
    value: '503008',
    label:
      'Elektronik > Audio > Audiozubehör > Audio- & Video-Receiver-Zubehör',
  },
  {
    value: '505797',
    label: 'Elektronik > Audio > Audiozubehör > Kopfhörer- & Headset-Zubehör',
  },
  {
    value: '503004',
    label:
      'Elektronik > Audio > Audiozubehör > Kopfhörer- & Headset-Zubehör > Kopfhörer- & In-Ear-Ohrhörer-Polster',
  },
  {
    value: '7163',
    label: 'Elektronik > Audio > Audiozubehör > Lautsprecherzubehör',
  },
  {
    value: '500119',
    label:
      'Elektronik > Audio > Audiozubehör > Lautsprecherzubehör > Bass-Shaker',
  },
  {
    value: '500120',
    label:
      'Elektronik > Audio > Audiozubehör > Lautsprecherzubehör > Lautsprecherkomponenten & -sets',
  },
  {
    value: '8049',
    label:
      'Elektronik > Audio > Audiozubehör > Lautsprecherzubehör > Lautsprecherständer',
  },
  {
    value: '500112',
    label:
      'Elektronik > Audio > Audiozubehör > Lautsprecherzubehör > Lautsprechertaschen, -abdeckungen & -cases',
  },
  {
    value: '8047',
    label:
      'Elektronik > Audio > Audiozubehör > Lautsprecherzubehör > Taschen für Lautsprecherständer',
  },
  {
    value: '3912',
    label: 'Elektronik > Audio > Audiozubehör > Mikrofonständer',
  },
  {
    value: '3306',
    label: 'Elektronik > Audio > Audiozubehör > Mikrofonzubehör',
  },
  {
    value: '2372',
    label: 'Elektronik > Audio > Audiozubehör > Plattenspielerzubehör',
  },
  {
    value: '239',
    label: 'Elektronik > Audio > Audiozubehör > Satellitenradio-Zubehör',
  },
  {
    value: '5395',
    label: 'Elektronik > Audio > Audiozubehör > Zubehör für Karaoke-Systeme',
  },
  {
    value: '5396',
    label:
      'Elektronik > Audio > Audiozubehör > Zubehör für Karaoke-Systeme > Karaoke-Speicherchips',
  },
  {
    value: '232',
    label: 'Elektronik > Audio > Audiozubehör > Zubehör für MP3-Player',
  },
  {
    value: '3055',
    label:
      'Elektronik > Audio > Audiozubehör > Zubehör für MP3-Player > MP3-Player-Taschen',
  },
  {
    value: '7566',
    label:
      'Elektronik > Audio > Audiozubehör > Zubehör für MP3-Player > Zubehörsets für MP3-Player & Mobiltelefone',
  },
  {
    value: '2154',
    label: 'Elektronik > Audio > Beschallungsanlagen',
  },
  {
    value: '3727',
    label: 'Elektronik > Audio > Bühnenausstattung',
  },
  {
    value: '3242',
    label: 'Elektronik > Audio > Bühnenausstattung > Drahtlossender',
  },
  {
    value: '4921',
    label: 'Elektronik > Audio > DJ- & Spezialaudiogeräte',
  },
  {
    value: '4922',
    label: 'Elektronik > Audio > DJ- & Spezialaudiogeräte > DJ CD-Player',
  },
  {
    value: '4923',
    label: 'Elektronik > Audio > DJ- & Spezialaudiogeräte > DJ Systeme',
  },
  {
    value: '8159',
    label: 'Elektronik > Audio > Megafone',
  },
  {
    value: '278',
    label: 'Elektronik > Computer',
  },
  {
    value: '5254',
    label: 'Elektronik > Computer > Barebone-Computer',
  },
  {
    value: '331',
    label: 'Elektronik > Computer > Computerserver',
  },
  {
    value: '500002',
    label: 'Elektronik > Computer > Datenbrillen',
  },
  {
    value: '325',
    label: 'Elektronik > Computer > Desktop-Computer',
  },
  {
    value: '298',
    label: 'Elektronik > Computer > E-Book-Reader & Handheld-Computer',
  },
  {
    value: '5256',
    label:
      'Elektronik > Computer > E-Book-Reader & Handheld-Computer > Datenerfassungsgeräte',
  },
  {
    value: '3539',
    label:
      'Elektronik > Computer > E-Book-Reader & Handheld-Computer > E-Book-Reader',
  },
  {
    value: '3769',
    label: 'Elektronik > Computer > E-Book-Reader & Handheld-Computer > PDAs',
  },
  {
    value: '5255',
    label: 'Elektronik > Computer > Interaktive Kioske',
  },
  {
    value: '328',
    label: 'Elektronik > Computer > Laptops',
  },
  {
    value: '502995',
    label: 'Elektronik > Computer > Multitouch-Tischcomputer',
  },
  {
    value: '4745',
    label: 'Elektronik > Computer > Tablet-PCs',
  },
  {
    value: '8539',
    label: 'Elektronik > Computer > Thin Clients & Zero Clients',
  },
  {
    value: '345',
    label: 'Elektronik > Drucken, Kopieren, Scannen & Faxen',
  },
  {
    value: '6865',
    label: 'Elektronik > Drucken, Kopieren, Scannen & Faxen > 3D-Drucker',
  },
  {
    value: '499682',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > 3D-Drucker-Zubehör',
  },
  {
    value: '500106',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Drucker, Kopierer & Faxgeräte',
  },
  {
    value: '306',
    label: 'Elektronik > Drucken, Kopieren, Scannen & Faxen > Scanner',
  },
  {
    value: '284',
    label: 'Elektronik > Drucken, Kopieren, Scannen & Faxen > Scannerzubehör',
  },
  {
    value: '502990',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte',
  },
  {
    value: '5265',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte > Drucker-Duplexeinheiten',
  },
  {
    value: '5258',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte > Drucker-Verbrauchsmaterial',
  },
  {
    value: '5260',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte > Drucker-Verbrauchsmaterial > Drucker-Farbbänder',
  },
  {
    value: '5266',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte > Drucker-Verbrauchsmaterial > Druckerfilter',
  },
  {
    value: '5259',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte > Drucker-Verbrauchsmaterial > Druckertrommeln & Sets',
  },
  {
    value: '5262',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte > Drucker-Verbrauchsmaterial > Druckerwartungssets',
  },
  {
    value: '5261',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte > Drucker-Verbrauchsmaterial > Druckköpfe',
  },
  {
    value: '7362',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte > Drucker-Verbrauchsmaterial > Toner & Tintenpatronen',
  },
  {
    value: '356',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte > Drucker-Verbrauchsmaterial > Toner- & Inkjet-Kartuschen',
  },
  {
    value: '1683',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte > Druckerspeicher',
  },
  {
    value: '5459',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte > Druckertische',
  },
  {
    value: '502991',
    label:
      'Elektronik > Drucken, Kopieren, Scannen & Faxen > Zubehör für Drucker, Kopierer & Faxgeräte > Ersatzteile für Drucker, Kopierer & Faxgeräte',
  },
  {
    value: '340',
    label: 'Elektronik > Elektrische Instrumente Seefahrt',
  },
  {
    value: '8134',
    label:
      'Elektronik > Elektrische Instrumente Seefahrt > Audio- und Videoemfpänger',
  },
  {
    value: '1550',
    label: 'Elektronik > Elektrische Instrumente Seefahrt > Fischfinder',
  },
  {
    value: '8473',
    label:
      'Elektronik > Elektrische Instrumente Seefahrt > Marine-Lautsprecher',
  },
  {
    value: '1552',
    label: 'Elektronik > Elektrische Instrumente Seefahrt > Schiffsradar',
  },
  {
    value: '4450',
    label: 'Elektronik > Elektrische Instrumente Seefahrt > Seefunkgeräte',
  },
  {
    value: '2178',
    label:
      'Elektronik > Elektrische Instrumente Seefahrt > Seekartenplotter & GPS',
  },
  {
    value: '3702',
    label: 'Elektronik > Elektronische Leiterplatten & Bauteile',
  },
  {
    value: '7259',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Decoder & Encoder für Schaltkreise',
  },
  {
    value: '7258',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Elektronische Filter',
  },
  {
    value: '3991',
    label: 'Elektronik > Elektronische Leiterplatten & Bauteile > Halbleiter',
  },
  {
    value: '3632',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Halbleiter > Dioden',
  },
  {
    value: '7257',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Halbleiter > Integrierte Schaltkreise & Chips',
  },
  {
    value: '3949',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Halbleiter > Mikrocontroller',
  },
  {
    value: '3094',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Halbleiter > Transistoren',
  },
  {
    value: '7264',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten',
  },
  {
    value: '499898',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten > Computer-Leiterplatten',
  },
  {
    value: '499899',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten > Computer-Leiterplatten > Computer-Inverterplatine',
  },
  {
    value: '8546',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten > Computer-Leiterplatten > Festplatten-Leiterplatten',
  },
  {
    value: '289',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten > Computer-Leiterplatten > Motherboards',
  },
  {
    value: '3416',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten > Entwicklungsboard',
  },
  {
    value: '298419',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten > Kamera-Leiterplatten',
  },
  {
    value: '8544',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten > Leiterplatten für Drucker, Kopierer & Faxgeräte',
  },
  {
    value: '8516',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten > Leiterplatten für Fernsehgeräte',
  },
  {
    value: '499889',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten > Leiterplatten für Fitnessgeräte',
  },
  {
    value: '8545',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten > Leiterplatten für Haushaltsgeräte',
  },
  {
    value: '8549',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten > Leiterplatten für Pools und Spas',
  },
  {
    value: '499675',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplatten > Leiterplatten für Scanner',
  },
  {
    value: '500027',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Leiterplattenzubehör',
  },
  {
    value: '3635',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Passive elektronische Bauelemente',
  },
  {
    value: '3121',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Passive elektronische Bauelemente > Induktoren',
  },
  {
    value: '3220',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Passive elektronische Bauelemente > Kondensatoren',
  },
  {
    value: '7260',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Passive elektronische Bauelemente > Oszillatoren',
  },
  {
    value: '3424',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Passive elektronische Bauelemente > Widerstände',
  },
  {
    value: '3889',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Platinenentwicklung',
  },
  {
    value: '4010',
    label:
      'Elektronik > Elektronische Leiterplatten & Bauteile > Platinenentwicklung > Steckplatinen',
  },
  {
    value: '2082',
    label: 'Elektronik > Elektronisches Zubehör',
  },
  {
    value: '258',
    label: 'Elektronik > Elektronisches Zubehör > Adapter',
  },
  {
    value: '4463',
    label:
      'Elektronik > Elektronisches Zubehör > Adapter > Audio/Video-Adapter',
  },
  {
    value: '146',
    label: 'Elektronik > Elektronisches Zubehör > Adapter > Speicheradapter',
  },
  {
    value: '7182',
    label: 'Elektronik > Elektronisches Zubehör > Adapter > USB-Adapter',
  },
  {
    value: '1718',
    label: 'Elektronik > Elektronisches Zubehör > Antennen',
  },
  {
    value: '5476',
    label: 'Elektronik > Elektronisches Zubehör > Antennenzubehör',
  },
  {
    value: '5477',
    label:
      'Elektronik > Elektronisches Zubehör > Antennenzubehör > Antennenhalterungen & -befestigungen',
  },
  {
    value: '5478',
    label:
      'Elektronik > Elektronisches Zubehör > Antennenzubehör > Dreheinrichtungen',
  },
  {
    value: '6016',
    label:
      'Elektronik > Elektronisches Zubehör > Antennenzubehör > Satelliten-LNBs',
  },
  {
    value: '8156',
    label:
      'Elektronik > Elektronisches Zubehör > Audio- und Video-Splitter & -Switches',
  },
  {
    value: '499944',
    label:
      'Elektronik > Elektronisches Zubehör > Audio- und Video-Splitter & -Switches > DVI-Splitter und -Switches',
  },
  {
    value: '8164',
    label:
      'Elektronik > Elektronisches Zubehör > Audio- und Video-Splitter & -Switches > HDMI-Splitter & -Switches',
  },
  {
    value: '499945',
    label:
      'Elektronik > Elektronisches Zubehör > Audio- und Video-Splitter & -Switches > VGA-Splitter und -Switches',
  },
  {
    value: '285',
    label: 'Elektronik > Elektronisches Zubehör > Computerkomponenten',
  },
  {
    value: '6932',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Blade-Server-Gehäuse',
  },
  {
    value: '8158',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Computer-Backplates & I/O Shields',
  },
  {
    value: '295',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Computer-Kühlkomponenten',
  },
  {
    value: '294',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Computer-Starter-Kits',
  },
  {
    value: '287',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Computer-Steckkarten',
  },
  {
    value: '505299',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Computer-Steckkarten > Computer-Steckkarten & -Adapter',
  },
  {
    value: '297',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Computer-Steckkarten > Grafikkarten & Videoadapter',
  },
  {
    value: '503755',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Computer-Steckkarten > Riser-Karten',
  },
  {
    value: '286',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Computer-Steckkarten > Soundkarten',
  },
  {
    value: '1487',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Computer-Steckkarten > TV-Karten und -Adapter',
  },
  {
    value: '291',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Computernetzteile',
  },
  {
    value: '293',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Computerregale & -Befestigungssysteme',
  },
  {
    value: '1928',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte',
  },
  {
    value: '304',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > Computer Mäuse & Trackballs',
  },
  {
    value: '5309',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > Digitale Stifte',
  },
  {
    value: '499950',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > Eingabegeräte zur Gestensteuerung',
  },
  {
    value: '505801',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > Elektronische Kartenlesegeräte',
  },
  {
    value: '5366',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > Fingerabdruck-Lesegeräte',
  },
  {
    value: '301',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > Gamecontroller',
  },
  {
    value: '302',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > Grafiktabletts',
  },
  {
    value: '1562',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > KVM-Switches',
  },
  {
    value: '3580',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > Speicherkartenlesegerät',
  },
  {
    value: '139',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > Strichcode-Scanner',
  },
  {
    value: '303',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > Tastaturen',
  },
  {
    value: '308',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > Touchpads',
  },
  {
    value: '4512',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Eingabegeräte > Ziffernblöcke',
  },
  {
    value: '8162',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Ersatzteile für E-Book-Reader',
  },
  {
    value: '8163',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Ersatzteile für E-Book-Reader > Ersatzbildschirme für E-Book-Reader',
  },
  {
    value: '4224',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Laptopteile',
  },
  {
    value: '8160',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Laptopteile > Laptop-Bildschirmdigitalisierer',
  },
  {
    value: '4102',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Laptopteile > Laptop-Ersatzbildschirme',
  },
  {
    value: '7501',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Laptopteile > Laptop-Ersatzkabel',
  },
  {
    value: '43617',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Laptopteile > Laptop-Ersatzlautsprecher',
  },
  {
    value: '4301',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Laptopteile > Laptop-Ersatztastaturen',
  },
  {
    value: '6416',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Laptopteile > Laptop-Scharniere',
  },
  {
    value: '4270',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Laptopteile > Laptopgehäuse',
  },
  {
    value: '296',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > PC-Gehäuse',
  },
  {
    value: '292',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Prozessoren',
  },
  {
    value: '2414',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte',
  },
  {
    value: '385',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Bandlaufwerke',
  },
  {
    value: '5268',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Datenspeicher-Duplizierer',
  },
  {
    value: '376',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Datenspeicher-Duplizierer > CD-/DVD-Brenner',
  },
  {
    value: '5271',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Datenspeicher-Duplizierer > Festplatten-Duplizierer',
  },
  {
    value: '5112',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Datenspeicher-Duplizierer > USB-Laufwerk-Duplizierer',
  },
  {
    value: '5272',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Disk-Arrays',
  },
  {
    value: '1301',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Diskettenlaufwerke',
  },
  {
    value: '380',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Festplatten',
  },
  {
    value: '1623',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Festplattenzubehör',
  },
  {
    value: '4417',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Festplattenzubehör > Festplatten-Dockingstationen',
  },
  {
    value: '381',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Festplattenzubehör > Festplatten-Transporttaschen',
  },
  {
    value: '505767',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Festplattenzubehör > Festplattengehäuse & -steckplätze',
  },
  {
    value: '5269',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Netzwerkspeichersysteme',
  },
  {
    value: '377',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > Optische Laufwerke',
  },
  {
    value: '3712',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Speichergeräte > USB-Massenspeicher',
  },
  {
    value: '7349',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Tablet-PC-Ersatzteile',
  },
  {
    value: '500013',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Tablet-PC-Ersatzteile > Tablet-Bildschirme und Bildschirm-Digitizer',
  },
  {
    value: '45262',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Tablet-PC-Ersatzteile > Tablet-Ersatzlautsprecher',
  },
  {
    value: '503002',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Tablet-PC-Ersatzteile > Tablet-Gehäuse & -Rahmen',
  },
  {
    value: '311',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > USB- & FireWire-Hubs',
  },
  {
    value: '6475',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Zubehör für Eingabegeräte',
  },
  {
    value: '6476',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Zubehör für Eingabegeräte > Halterung für Strichcodeleser',
  },
  {
    value: '500052',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Zubehör für Eingabegeräte > Mäuse und Trackball-Zubehör',
  },
  {
    value: '503003',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Zubehör für Eingabegeräte > Tastaturtasten & -kappen',
  },
  {
    value: '8008',
    label:
      'Elektronik > Elektronisches Zubehör > Computerkomponenten > Zubehör für Eingabegeräte > Zubehör für Gamecontroller',
  },
  {
    value: '279',
    label: 'Elektronik > Elektronisches Zubehör > Computerzubehör',
  },
  {
    value: '5489',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Computer-Monitorständer',
  },
  {
    value: '7530',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Computer-Schutzhüllen',
  },
  {
    value: '500040',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Computerzubehör-Sets',
  },
  {
    value: '5669',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Ersatzspitzen für Stylus Pens',
  },
  {
    value: '300',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Laptop-Dockingstationen',
  },
  {
    value: '1993',
    label: 'Elektronik > Elektronisches Zubehör > Computerzubehör > Mousepads',
  },
  {
    value: '5308',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Stylus Pens',
  },
  {
    value: '499956',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Ständer für Tablet-PCs',
  },
  {
    value: '6291',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Tastatur- & Maus-Handballenauflage',
  },
  {
    value: '6979',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Tastaturablagen',
  },
  {
    value: '280',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Zubehör für E-Book-Reader & Handheld-Computer',
  },
  {
    value: '4737',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Zubehör für E-Book-Reader & Handheld-Computer > PDA-Zubehör',
  },
  {
    value: '4739',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Zubehör für E-Book-Reader & Handheld-Computer > PDA-Zubehör > PDA-Taschen',
  },
  {
    value: '4736',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Zubehör für E-Book-Reader & Handheld-Computer > Zubehör für E-Book-Reader',
  },
  {
    value: '4738',
    label:
      'Elektronik > Elektronisches Zubehör > Computerzubehör > Zubehör für E-Book-Reader & Handheld-Computer > Zubehör für E-Book-Reader > E-Book-Reader-Taschen',
  },
  {
    value: '499878',
    label:
      'Elektronik > Elektronisches Zubehör > Drei- und Einbeinstative für Mobiltelefone und Tablets',
  },
  {
    value: '5466',
    label:
      'Elektronik > Elektronisches Zubehör > Elektronik-Folien & -Überzüge',
  },
  {
    value: '5467',
    label:
      'Elektronik > Elektronisches Zubehör > Elektronik-Folien & -Überzüge > Blickschutzfilter',
  },
  {
    value: '5468',
    label:
      'Elektronik > Elektronisches Zubehör > Elektronik-Folien & -Überzüge > Display-Schutzfolien',
  },
  {
    value: '5523',
    label:
      'Elektronik > Elektronisches Zubehör > Elektronik-Folien & -Überzüge > Elektronik-Sticker & -Aufkleber',
  },
  {
    value: '5469',
    label:
      'Elektronik > Elektronisches Zubehör > Elektronik-Folien & -Überzüge > Tastaturschutz',
  },
  {
    value: '4617',
    label: 'Elektronik > Elektronisches Zubehör > Elektronikreiniger',
  },
  {
    value: '341',
    label: 'Elektronik > Elektronisches Zubehör > Fernbedienungen',
  },
  {
    value: '259',
    label: 'Elektronik > Elektronisches Zubehör > Kabel',
  },
  {
    value: '1867',
    label: 'Elektronik > Elektronisches Zubehör > Kabel > Audio- & Videokabel',
  },
  {
    value: '500035',
    label:
      'Elektronik > Elektronisches Zubehör > Kabel > Datenübertragungs- & Speicherkabel',
  },
  {
    value: '3461',
    label: 'Elektronik > Elektronisches Zubehör > Kabel > KVM-Kabel',
  },
  {
    value: '1480',
    label: 'Elektronik > Elektronisches Zubehör > Kabel > Netzwerkkabel',
  },
  {
    value: '1763',
    label: 'Elektronik > Elektronisches Zubehör > Kabel > System- & Netzkabel',
  },
  {
    value: '3541',
    label: 'Elektronik > Elektronisches Zubehör > Kabel > Telefonkabel',
  },
  {
    value: '3328',
    label: 'Elektronik > Elektronisches Zubehör > Kabel-Management',
  },
  {
    value: '6780',
    label:
      'Elektronik > Elektronisches Zubehör > Kabel-Management > Aderhülsen & Kabelmuffen',
  },
  {
    value: '4016',
    label:
      'Elektronik > Elektronisches Zubehör > Kabel-Management > Kabelbinder',
  },
  {
    value: '500036',
    label:
      'Elektronik > Elektronisches Zubehör > Kabel-Management > Kabelbinderpistolen',
  },
  {
    value: '499686',
    label:
      'Elektronik > Elektronisches Zubehör > Kabel-Management > Kabelmarkierungen',
  },
  {
    value: '3764',
    label:
      'Elektronik > Elektronisches Zubehör > Kabel-Management > Kabelschellen',
  },
  {
    value: '6402',
    label:
      'Elektronik > Elektronisches Zubehör > Kabel-Management > Kabelträger',
  },
  {
    value: '5273',
    label:
      'Elektronik > Elektronisches Zubehör > Kabel-Management > Schalttafeln',
  },
  {
    value: '367',
    label: 'Elektronik > Elektronisches Zubehör > Leermedien',
  },
  {
    value: '5695',
    label: 'Elektronik > Elektronisches Zubehör > Signalstörer',
  },
  {
    value: '5612',
    label:
      'Elektronik > Elektronisches Zubehör > Signalstörer > GPS-Störsender',
  },
  {
    value: '5696',
    label:
      'Elektronik > Elektronisches Zubehör > Signalstörer > Mobiltelefon-Störsender',
  },
  {
    value: '5589',
    label: 'Elektronik > Elektronisches Zubehör > Signalstörer > Radarstörer',
  },
  {
    value: '5473',
    label: 'Elektronik > Elektronisches Zubehör > Signalverstärker',
  },
  {
    value: '288',
    label: 'Elektronik > Elektronisches Zubehör > Speicher',
  },
  {
    value: '1665',
    label: 'Elektronik > Elektronisches Zubehör > Speicher > Cache-Speicher',
  },
  {
    value: '384',
    label: 'Elektronik > Elektronisches Zubehör > Speicher > Flash-Speicher',
  },
  {
    value: '3387',
    label:
      'Elektronik > Elektronisches Zubehör > Speicher > Flash-Speicher > Flash-Speicherkarten',
  },
  {
    value: '1733',
    label: 'Elektronik > Elektronisches Zubehör > Speicher > RAM',
  },
  {
    value: '2130',
    label: 'Elektronik > Elektronisches Zubehör > Speicher > ROM',
  },
  {
    value: '1767',
    label: 'Elektronik > Elektronisches Zubehör > Speicher > Videospeicher',
  },
  {
    value: '3422',
    label: 'Elektronik > Elektronisches Zubehör > Speicherkartenzubehör',
  },
  {
    value: '3672',
    label:
      'Elektronik > Elektronisches Zubehör > Speicherkartenzubehör > Speicherkartenetuis',
  },
  {
    value: '275',
    label: 'Elektronik > Elektronisches Zubehör > Stromversorgung',
  },
  {
    value: '276',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien',
  },
  {
    value: '1880',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien > Akkus & Batterien für schnurlose Telefone',
  },
  {
    value: '7438',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien > Akkus für Tablet-Computer',
  },
  {
    value: '7551',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien > Batterien für E-Book-Reader',
  },
  {
    value: '500117',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien > Batterien für Videospielkonsolen & Game-Controller',
  },
  {
    value: '1745',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien > Handy-Akkus',
  },
  {
    value: '1722',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien > Kameraakkus & -batterien',
  },
  {
    value: '1564',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien > Laptopakkus',
  },
  {
    value: '499810',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien > MP3-Player-Batterien',
  },
  {
    value: '4928',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien > Mehrzweckbatterien',
  },
  {
    value: '5133',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien > PDA-Batterien',
  },
  {
    value: '6289',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien > USV-Batterien',
  },
  {
    value: '2222',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Akkus & Batterien > Videokameraakkus & -batterien',
  },
  {
    value: '7166',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Batteriezubehör',
  },
  {
    value: '3130',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Batteriezubehör > Akkuladegeräte für Kameras',
  },
  {
    value: '8243',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Batteriezubehör > Batteriehalter',
  },
  {
    value: '7167',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Batteriezubehör > Batterieladegeräte',
  },
  {
    value: '6817',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Batteriezubehör > Laderegler',
  },
  {
    value: '499928',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Batteriezubehör > Universal-Batterieprüfgeräte',
  },
  {
    value: '2978',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Brennstoffzellen',
  },
  {
    value: '5274',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Gehäuse für Stromversorgungsgeräte',
  },
  {
    value: '6790',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Leistungsregler',
  },
  {
    value: '6933',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Netzteil- & Ladegerätezubehör',
  },
  {
    value: '505295',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Netzteile & Ladegeräte',
  },
  {
    value: '7135',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Reiseadapter',
  },
  {
    value: '3160',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Steckdosenleisten & Überspannungsschutz',
  },
  {
    value: '1348',
    label: 'Elektronik > Elektronisches Zubehör > Stromversorgung > USV',
  },
  {
    value: '1375',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Zubehör für USV',
  },
  {
    value: '5380',
    label:
      'Elektronik > Elektronisches Zubehör > Stromversorgung > Überspannungsschutzgeräte',
  },
  {
    value: '339',
    label: 'Elektronik > GPS-Navigationssysteme',
  },
  {
    value: '6544',
    label: 'Elektronik > GPS-Tracker',
  },
  {
    value: '3895',
    label: 'Elektronik > GPS-Zubehör',
  },
  {
    value: '3213',
    label: 'Elektronik > GPS-Zubehör > GPS-Halterungen',
  },
  {
    value: '3781',
    label: 'Elektronik > GPS-Zubehör > GPS-Taschen',
  },
  {
    value: '4488',
    label: 'Elektronik > Geräte zur Mauterhebung',
  },
  {
    value: '262',
    label: 'Elektronik > Kommunikationsgeräte',
  },
  {
    value: '266',
    label: 'Elektronik > Kommunikationsgeräte > Anrufbeantworter',
  },
  {
    value: '5275',
    label: 'Elektronik > Kommunikationsgeräte > Anruferkennung',
  },
  {
    value: '2471',
    label: 'Elektronik > Kommunikationsgeräte > Funkgeräte',
  },
  {
    value: '2106',
    label: 'Elektronik > Kommunikationsgeräte > Funkgeräte > CB-Funkgeräte',
  },
  {
    value: '4415',
    label: 'Elektronik > Kommunikationsgeräte > Funkgeräte > Funkscanner',
  },
  {
    value: '273',
    label: 'Elektronik > Kommunikationsgeräte > Funkgeräte > Funksprechgeräte',
  },
  {
    value: '263',
    label: 'Elektronik > Kommunikationsgeräte > Funkzubehör',
  },
  {
    value: '268',
    label: 'Elektronik > Kommunikationsgeräte > Pager',
  },
  {
    value: '360',
    label: 'Elektronik > Kommunikationsgeräte > Sprechanlagen',
  },
  {
    value: '5404',
    label: 'Elektronik > Kommunikationsgeräte > Sprechanlagen-Zubehör',
  },
  {
    value: '270',
    label: 'Elektronik > Kommunikationsgeräte > Telefone',
  },
  {
    value: '4666',
    label: 'Elektronik > Kommunikationsgeräte > Telefone > Konferenztelefone',
  },
  {
    value: '267',
    label: 'Elektronik > Kommunikationsgeräte > Telefone > Mobiltelefone',
  },
  {
    value: '264',
    label: 'Elektronik > Kommunikationsgeräte > Telefone > Mobiltelefonzubehör',
  },
  {
    value: '499916',
    label:
      'Elektronik > Kommunikationsgeräte > Telefone > Mobiltelefonzubehör > Auswurfhilfen für SIM-Karten',
  },
  {
    value: '7347',
    label:
      'Elektronik > Kommunikationsgeräte > Telefone > Mobiltelefonzubehör > Ersatzteile für Mobiltelefone',
  },
  {
    value: '6030',
    label:
      'Elektronik > Kommunikationsgeräte > Telefone > Mobiltelefonzubehör > Handy-Prepaid- & -SIM-Karten',
  },
  {
    value: '4550',
    label:
      'Elektronik > Kommunikationsgeräte > Telefone > Mobiltelefonzubehör > Handycharms & -anhänger',
  },
  {
    value: '5566',
    label:
      'Elektronik > Kommunikationsgeräte > Telefone > Mobiltelefonzubehör > Mobiltelefonhalterungen & -ständer',
  },
  {
    value: '2353',
    label:
      'Elektronik > Kommunikationsgeräte > Telefone > Mobiltelefonzubehör > Mobiltelefontaschen',
  },
  {
    value: '8111',
    label:
      'Elektronik > Kommunikationsgeräte > Telefone > Mobiltelefonzubehör > Zubehör für Mobiltelefon-Kameras',
  },
  {
    value: '1924',
    label: 'Elektronik > Kommunikationsgeräte > Telefone > Satellitentelefone',
  },
  {
    value: '272',
    label: 'Elektronik > Kommunikationsgeräte > Telefone > Schnurlostelefone',
  },
  {
    value: '271',
    label: 'Elektronik > Kommunikationsgeräte > Telefone > Schnurtelefone',
  },
  {
    value: '265',
    label: 'Elektronik > Kommunikationsgeräte > Telefone > Telefonzubehör',
  },
  {
    value: '269',
    label:
      'Elektronik > Kommunikationsgeräte > Telefone > Telefonzubehör > Telefonkarten',
  },
  {
    value: '274',
    label: 'Elektronik > Kommunikationsgeräte > Videokonferenzsysteme',
  },
  {
    value: '1801',
    label: 'Elektronik > Komponenten',
  },
  {
    value: '7395',
    label: 'Elektronik > Komponenten > Beschleunigungssensoren',
  },
  {
    value: '1337',
    label: 'Elektronik > Komponenten > Modulatoren',
  },
  {
    value: '1544',
    label: 'Elektronik > Komponenten > Splitter',
  },
  {
    value: '1977',
    label: 'Elektronik > Komponenten > Steckverbinder',
  },
  {
    value: '2182',
    label: 'Elektronik > Komponenten > Wandler',
  },
  {
    value: '503001',
    label: 'Elektronik > Komponenten > Wandler > Audiowandler',
  },
  {
    value: '2205',
    label: 'Elektronik > Komponenten > Wandler > Signalwandler',
  },
  {
    value: '342',
    label: 'Elektronik > Netzwerktechnik',
  },
  {
    value: '1350',
    label: 'Elektronik > Netzwerktechnik > Bridges & Router',
  },
  {
    value: '5659',
    label: 'Elektronik > Netzwerktechnik > Bridges & Router > Netzwerk-Bridges',
  },
  {
    value: '2358',
    label:
      'Elektronik > Netzwerktechnik > Bridges & Router > VoIP-Gateways & -Router',
  },
  {
    value: '5496',
    label:
      'Elektronik > Netzwerktechnik > Bridges & Router > Wireless Access Points',
  },
  {
    value: '5497',
    label: 'Elektronik > Netzwerktechnik > Bridges & Router > Wireless Router',
  },
  {
    value: '3425',
    label: 'Elektronik > Netzwerktechnik > Druckerserver',
  },
  {
    value: '2455',
    label: 'Elektronik > Netzwerktechnik > Hubs & Schalter',
  },
  {
    value: '2479',
    label: 'Elektronik > Netzwerktechnik > Konzentratoren & Multiplexer',
  },
  {
    value: '343',
    label: 'Elektronik > Netzwerktechnik > Modems',
  },
  {
    value: '5576',
    label: 'Elektronik > Netzwerktechnik > Modemzubehör',
  },
  {
    value: '290',
    label: 'Elektronik > Netzwerktechnik > Netzwerkkarten',
  },
  {
    value: '3742',
    label:
      'Elektronik > Netzwerktechnik > Netzwerksicherheitssysteme & Firewalls',
  },
  {
    value: '6508',
    label: 'Elektronik > Netzwerktechnik > PoE-Adapter',
  },
  {
    value: '2121',
    label: 'Elektronik > Netzwerktechnik > Repeater & Sende/-Empfangsgeräte',
  },
  {
    value: '500091',
    label: 'Elektronik > Radargeschwindigkeitsmesser',
  },
  {
    value: '912',
    label: 'Elektronik > Radarwarner',
  },
  {
    value: '3356',
    label: 'Elektronik > Spielautomatenbedarf',
  },
  {
    value: '8085',
    label: 'Elektronik > Spielautomatenbedarf > Basketballspiele',
  },
  {
    value: '3140',
    label: 'Elektronik > Spielautomatenbedarf > Flipper',
  },
  {
    value: '3946',
    label: 'Elektronik > Spielautomatenbedarf > Flipper-Zubehör',
  },
  {
    value: '3681',
    label: 'Elektronik > Spielautomatenbedarf > Skee-Ball-Maschinen',
  },
  {
    value: '3117',
    label: 'Elektronik > Spielautomatenbedarf > Spielautomaten',
  },
  {
    value: '3676',
    label: 'Elektronik > Spielautomatenbedarf > Zubehör für Spielautomaten',
  },
  {
    value: '386',
    label: 'Elektronik > Video',
  },
  {
    value: '305',
    label: 'Elektronik > Video > Computermonitore',
  },
  {
    value: '404',
    label: 'Elektronik > Video > Fernseher',
  },
  {
    value: '396',
    label: 'Elektronik > Video > Projektoren',
  },
  {
    value: '399',
    label: 'Elektronik > Video > Projektoren > Diaprojektoren',
  },
  {
    value: '397',
    label: 'Elektronik > Video > Projektoren > Multimedia-Projektoren',
  },
  {
    value: '398',
    label: 'Elektronik > Video > Projektoren > Overhead-Projektoren',
  },
  {
    value: '5561',
    label: 'Elektronik > Video > Satelliten- & Kabelfernsehen',
  },
  {
    value: '5562',
    label:
      'Elektronik > Video > Satelliten- & Kabelfernsehen > Kabelfernsehen-Receiver',
  },
  {
    value: '401',
    label:
      'Elektronik > Video > Satelliten- & Kabelfernsehen > Satelliten-Receiver',
  },
  {
    value: '1634',
    label: 'Elektronik > Video > Video-Multiplexer',
  },
  {
    value: '387',
    label: 'Elektronik > Video > Videoabspielgeräte & -rekorder',
  },
  {
    value: '388',
    label:
      'Elektronik > Video > Videoabspielgeräte & -rekorder > DVD- & Blu-ray-Player',
  },
  {
    value: '389',
    label: 'Elektronik > Video > Videoabspielgeräte & -rekorder > DVD-Rekorder',
  },
  {
    value: '390',
    label:
      'Elektronik > Video > Videoabspielgeräte & -rekorder > Digitale Videorekorder',
  },
  {
    value: '5276',
    label:
      'Elektronik > Video > Videoabspielgeräte & -rekorder > Netzwerk-Mediaplayer',
  },
  {
    value: '391',
    label:
      'Elektronik > Video > Videoabspielgeräte & -rekorder > Videorekorder',
  },
  {
    value: '1368',
    label:
      'Elektronik > Video > Videobearbeitungshardware & Videoproduktionsausrüstung',
  },
  {
    value: '5278',
    label: 'Elektronik > Video > Videoserver',
  },
  {
    value: '5450',
    label: 'Elektronik > Video > Videotransmitter',
  },
  {
    value: '2027',
    label: 'Elektronik > Video > Videozubehör',
  },
  {
    value: '4760',
    label: 'Elektronik > Video > Videozubehör > 3D-Brillen',
  },
  {
    value: '283',
    label: 'Elektronik > Video > Videozubehör > Computerbildschirmzubehör',
  },
  {
    value: '5516',
    label:
      'Elektronik > Video > Videozubehör > Computerbildschirmzubehör > Farbkalibrierung',
  },
  {
    value: '393',
    label: 'Elektronik > Video > Videozubehör > Projektorzubehör',
  },
  {
    value: '395',
    label:
      'Elektronik > Video > Videozubehör > Projektorzubehör > Projektionsleinwände',
  },
  {
    value: '394',
    label:
      'Elektronik > Video > Videozubehör > Projektorzubehör > Projektorersatzleuchten',
  },
  {
    value: '5257',
    label:
      'Elektronik > Video > Videozubehör > Projektorzubehör > Projektorhalterungen',
  },
  {
    value: '5599',
    label:
      'Elektronik > Video > Videozubehör > Projektorzubehör > Rockvorhänge für Leinwände',
  },
  {
    value: '4570',
    label:
      'Elektronik > Video > Videozubehör > Projektorzubehör > Ständer für Projektionsflächen',
  },
  {
    value: '2145',
    label: 'Elektronik > Video > Videozubehör > Spulgeräte',
  },
  {
    value: '403',
    label: 'Elektronik > Video > Videozubehör > TV-Zubehör',
  },
  {
    value: '5471',
    label:
      'Elektronik > Video > Videozubehör > TV-Zubehör > Ersatzlampen für Fernseher',
  },
  {
    value: '4458',
    label:
      'Elektronik > Video > Videozubehör > TV-Zubehör > TV- & Monitorhalterungen',
  },
  {
    value: '5503',
    label:
      'Elektronik > Video > Videozubehör > TV-Zubehör > TV-Konverter & Receiver',
  },
  {
    value: '43616',
    label: 'Elektronik > Video > Videozubehör > TV-Zubehör > TV-Lautsprecher',
  },
  {
    value: '1294',
    label: 'Elektronik > Videospielkonsolen',
  },
  {
    value: '1270',
    label: 'Elektronik > Zubehör für Videospielkonsolen',
  },
  {
    value: '1505',
    label:
      'Elektronik > Zubehör für Videospielkonsolen > Zubehör für Spielkonsolen',
  },
  {
    value: '2070',
    label:
      'Elektronik > Zubehör für Videospielkonsolen > Zubehör für mobile Spielkonsolen',
  },
  {
    value: '888',
    label: 'Fahrzeuge & Teile',
  },
  {
    value: '5614',
    label: 'Fahrzeuge & Teile > Fahrzeuge',
  },
  {
    value: '1267',
    label: 'Fahrzeuge & Teile > Fahrzeuge > Kraftfahrzeuge',
  },
  {
    value: '916',
    label:
      'Fahrzeuge & Teile > Fahrzeuge > Kraftfahrzeuge > Autos, LKWs & Lieferwagen',
  },
  {
    value: '503031',
    label:
      'Fahrzeuge & Teile > Fahrzeuge > Kraftfahrzeuge > Gelände- & Allradfahrzeuge',
  },
  {
    value: '2528',
    label:
      'Fahrzeuge & Teile > Fahrzeuge > Kraftfahrzeuge > Gelände- & Allradfahrzeuge > Go-Karts',
  },
  {
    value: '3018',
    label:
      'Fahrzeuge & Teile > Fahrzeuge > Kraftfahrzeuge > Gelände- & Allradfahrzeuge > Quads',
  },
  {
    value: '3931',
    label: 'Fahrzeuge & Teile > Fahrzeuge > Kraftfahrzeuge > Golfwagen',
  },
  {
    value: '919',
    label:
      'Fahrzeuge & Teile > Fahrzeuge > Kraftfahrzeuge > Motorräder & -roller',
  },
  {
    value: '3549',
    label: 'Fahrzeuge & Teile > Fahrzeuge > Kraftfahrzeuge > Schneemobile',
  },
  {
    value: '920',
    label: 'Fahrzeuge & Teile > Fahrzeuge > Kraftfahrzeuge > Wohnmobile',
  },
  {
    value: '3395',
    label: 'Fahrzeuge & Teile > Fahrzeuge > Luftfahrzeuge',
  },
  {
    value: '3540',
    label: 'Fahrzeuge & Teile > Fahrzeuge > Wasserfahrzeuge',
  },
  {
    value: '1130',
    label: 'Fahrzeuge & Teile > Fahrzeuge > Wasserfahrzeuge > Jetski',
  },
  {
    value: '3095',
    label: 'Fahrzeuge & Teile > Fahrzeuge > Wasserfahrzeuge > Motorboote',
  },
  {
    value: '3087',
    label: 'Fahrzeuge & Teile > Fahrzeuge > Wasserfahrzeuge > Segelboote',
  },
  {
    value: '5644',
    label: 'Fahrzeuge & Teile > Fahrzeuge > Wasserfahrzeuge > Yachten',
  },
  {
    value: '5613',
    label: 'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör',
  },
  {
    value: '8301',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit',
  },
  {
    value: '362737',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > ATV- & UTV-Schutzausrüstung',
  },
  {
    value: '362738',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > ATV- & UTV-Schutzausrüstung > ATV- & UTV-Griffpolster',
  },
  {
    value: '2768',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme',
  },
  {
    value: '1802',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme > Auto-Alarmanlagen',
  },
  {
    value: '2699',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme > Fernbediente schlüssellose Systeme',
  },
  {
    value: '8302',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme > Kfz-Türschlösser',
  },
  {
    value: '8305',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme > Kfz-Türschlösser > Kfz-Türschlosssicherungen',
  },
  {
    value: '8304',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme > Kfz-Türschlösser > Türknöpfe',
  },
  {
    value: '8303',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme > Kfz-Türschlösser > Türschlösser & Schließsysteme',
  },
  {
    value: '235921',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme > Kupplungsschlösser',
  },
  {
    value: '2750',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme > Lenkradschlösser',
  },
  {
    value: '6083',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme > Motorrad-Schlösser & -Alarmanlagen',
  },
  {
    value: '500077',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme > Radkrallen',
  },
  {
    value: '3024',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme > Wegfahrsperren',
  },
  {
    value: '6084',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Auto-Alarmanlagen & Schließsysteme > Zubehör für Auto-Alarmanlagen',
  },
  {
    value: '2879',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Kfz-Sicherheitsausrüstung',
  },
  {
    value: '8448',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Kfz-Sicherheitsausrüstung > Airbagteile',
  },
  {
    value: '8447',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Kfz-Sicherheitsausrüstung > Fensternetze',
  },
  {
    value: '326120',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Kfz-Sicherheitsausrüstung > Gurtpolster',
  },
  {
    value: '8477',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Kfz-Sicherheitsausrüstung > Gurtschlösser',
  },
  {
    value: '6966',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Kfz-Sicherheitsausrüstung > Kfz-Warnflaggen',
  },
  {
    value: '8476',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Kfz-Sicherheitsausrüstung > Sicherheitsgurtbänder',
  },
  {
    value: '8449',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Kfz-Sicherheitsausrüstung > Sicherheitsgurte',
  },
  {
    value: '8506',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Kfz-Sicherheitsausrüstung > Unterlegkeile',
  },
  {
    value: '8445',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Kfz-Sicherheitsausrüstung > Warnleuchten',
  },
  {
    value: '8446',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Kfz-Sicherheitsausrüstung > Überrollkäfige & -bügel',
  },
  {
    value: '5547',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Motorrad-Schutzausrüstung',
  },
  {
    value: '5961',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Motorrad-Schutzausrüstung > Motorrad-Genickschutz',
  },
  {
    value: '8507',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Motorrad-Schutzausrüstung > Motorrad-Handprotektoren',
  },
  {
    value: '5960',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Motorrad-Schutzausrüstung > Motorrad-Nierengurte',
  },
  {
    value: '5959',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Motorrad-Schutzausrüstung > Motorrad: Brust- und Rückenprotektoren',
  },
  {
    value: '5963',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Motorrad-Schutzausrüstung > Motorrad: Ellbogen- und Handgelenkschützer',
  },
  {
    value: '5962',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Motorrad-Schutzausrüstung > Motorrad: Knie- & Schienbeinschützer',
  },
  {
    value: '5106',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Motorrad-Schutzausrüstung > Motorradbrillen',
  },
  {
    value: '5908',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Motorrad-Schutzausrüstung > Motorradhandschuhe',
  },
  {
    value: '2110',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Motorrad-Schutzausrüstung > Motorradhelme',
  },
  {
    value: '6493',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Fahrzeugsicherheit > Motorrad-Schutzausrüstung > Motorradhelmersatzteile & -zubehör',
  },
  {
    value: '3977',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Flugzeugteile & -Zubehör',
  },
  {
    value: '8526',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Elektronik',
  },
  {
    value: '505766',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Elektronik > A/V-Player & im Armaturenbrett integrierte Systeme für Autos',
  },
  {
    value: '891',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Elektronik > Audioverstärker für Autos',
  },
  {
    value: '894',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Elektronik > Equalizer & Frequenzweichen für Autos',
  },
  {
    value: '5572',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Elektronik > Freisprechanlagen',
  },
  {
    value: '8483',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Elektronik > Halterungen für Kfz-Videomonitore',
  },
  {
    value: '5525',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Elektronik > Kassettenadapter',
  },
  {
    value: '5438',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Elektronik > Kassettenspieler für Autos',
  },
  {
    value: '895',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Elektronik > Lautsprecher für Autos',
  },
  {
    value: '6968',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Elektronik > Parkkameras für Autos',
  },
  {
    value: '2833',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Elektronik > Subwoofer für Autos',
  },
  {
    value: '899',
    label: 'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile',
  },
  {
    value: '8228',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Abschleppzubehör',
  },
  {
    value: '2935',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Aufhängungsteile für Autos',
  },
  {
    value: '8232',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Bodenbeläge & Polster',
  },
  {
    value: '2641',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Getriebe- & Antriebsteile',
  },
  {
    value: '908',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Auspuff',
  },
  {
    value: '3318',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Beleuchtung',
  },
  {
    value: '2977',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Bremsen',
  },
  {
    value: '8231',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Elektroniksysteme',
  },
  {
    value: '8233',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Innenausstattung',
  },
  {
    value: '2805',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Klimatisierung',
  },
  {
    value: '8137',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Motoren',
  },
  {
    value: '2820',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Motorteile',
  },
  {
    value: '3020',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Räder',
  },
  {
    value: '2932',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Räder > Felgen & Reifen für Kfz',
  },
  {
    value: '6090',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Räder > Felgen & Reifen für Kfz > Autofelgen & -räder',
  },
  {
    value: '7253',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Räder > Felgen & Reifen für Kfz > Felgen & Räder für ATV & UTV',
  },
  {
    value: '6088',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Räder > Felgen & Reifen für Kfz > Motorradfelgen & -räder',
  },
  {
    value: '2556',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Räder > Kfz-Radteile',
  },
  {
    value: '911',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Räder > Kfz-Reifen',
  },
  {
    value: '6093',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Räder > Kfz-Reifen > Autoreifen',
  },
  {
    value: '6091',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Räder > Kfz-Reifen > Motorradreifen',
  },
  {
    value: '7252',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Räder > Kfz-Reifen > Reifen für ATV & UTV',
  },
  {
    value: '2989',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Räder > Kfz-Reifenzubehör',
  },
  {
    value: '8234',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Sensoren & -Messinstrumente',
  },
  {
    value: '8238',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Sitze',
  },
  {
    value: '2642',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Spiegel',
  },
  {
    value: '8235',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kfz-Steuerelemente',
  },
  {
    value: '2727',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Kraftstoffanlagen',
  },
  {
    value: '8227',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Rahmen- & Karosserieteile',
  },
  {
    value: '2534',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Teile für Kfz-Fenster',
  },
  {
    value: '2550',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Teile > Teile für Kfz-Ölkreislauf',
  },
  {
    value: '913',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege',
  },
  {
    value: '3436',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugabdeckungen',
  },
  {
    value: '2494',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugabdeckungen > Fahrzeug-Hardtops',
  },
  {
    value: '8309',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugabdeckungen > Fahrzeugabdeckplanen',
  },
  {
    value: '8312',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugabdeckungen > Fahrzeugabdeckplanen > Bootsabdeckplanen',
  },
  {
    value: '8314',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugabdeckungen > Fahrzeugabdeckplanen > Golfcart-Abdeckplanen',
  },
  {
    value: '8310',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugabdeckungen > Fahrzeugabdeckplanen > Kfz-Abdeckplanen',
  },
  {
    value: '8313',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugabdeckungen > Fahrzeugabdeckplanen > Motorradabdeckplanen',
  },
  {
    value: '8311',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugabdeckungen > Fahrzeugabdeckplanen > Unterstände & Abdeckungen für Wohnmobile',
  },
  {
    value: '7031',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugabdeckungen > Fahrzeugverdecke',
  },
  {
    value: '8306',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugabdeckungen > Golfcart-Gehäuse',
  },
  {
    value: '8308',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugabdeckungen > Ladeflächenabdeckungen',
  },
  {
    value: '8316',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugabdeckungen > Windschutzscheiben-Abdeckungen',
  },
  {
    value: '3812',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeuglack',
  },
  {
    value: '8144',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeuglack > Bremssattellack',
  },
  {
    value: '8450',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeuglack > Rahmen- & Karosserielack',
  },
  {
    value: '2895',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreinigung',
  },
  {
    value: '2894',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreinigung > Autowaschbürsten',
  },
  {
    value: '2590',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreinigung > Autowaschmittel',
  },
  {
    value: '499766',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreinigung > Einspritzanlagenreinigungs-Sets',
  },
  {
    value: '2846',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreinigung > Fahrzeug-Glasreiniger',
  },
  {
    value: '2704',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreinigung > Kfz-Teppich- & Polsterreiniger',
  },
  {
    value: '2643',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreinigung > Wachse, Polituren & Schutzmittel für Fahrzeuge',
  },
  {
    value: '8236',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreparatur- & Spezialwerkzeuge',
  },
  {
    value: '7414',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreparatur- & Spezialwerkzeuge > Aufladegeräte für Fahrzeugbatterien',
  },
  {
    value: '499929',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreparatur- & Spezialwerkzeuge > Fahrzeugbatterie-Prüfgeräte',
  },
  {
    value: '6482',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreparatur- & Spezialwerkzeuge > Fahrzeugdiagnosegeräte',
  },
  {
    value: '499774',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreparatur- & Spezialwerkzeuge > Karosseriedichtmasse',
  },
  {
    value: '8260',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreparatur- & Spezialwerkzeuge > Kfz-Bremsreparatur-Sets',
  },
  {
    value: '5068',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreparatur- & Spezialwerkzeuge > Kfz-Starthilfen',
  },
  {
    value: '3326',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreparatur- & Spezialwerkzeuge > Kfz-Überbrückungskabel',
  },
  {
    value: '8259',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreparatur- & Spezialwerkzeuge > Werkzeuge für Kupplungsausrichtung & -ausbau',
  },
  {
    value: '8261',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreparatur- & Spezialwerkzeuge > Werkzeuge für Reifenreparatur & -wechsel',
  },
  {
    value: '2647',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Fahrzeugreparatur- & Spezialwerkzeuge > Windschutzscheibenreparatursets',
  },
  {
    value: '2495',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor',
  },
  {
    value: '8145',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Abdeckungen für Anhängerkupplungen',
  },
  {
    value: '2722',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Autofolien',
  },
  {
    value: '8202',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Fahrzeug-Außendesigns',
  },
  {
    value: '8469',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Fahrzeug-Dekosets',
  },
  {
    value: '5995',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Fahrzeug-Embleme',
  },
  {
    value: '2248',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Kennzeichen',
  },
  {
    value: '8298',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Kennzeichenhalterungen',
  },
  {
    value: '5994',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Kennzeichenrahmen',
  },
  {
    value: '2588',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Kfz-Antennenbälle',
  },
  {
    value: '2582',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Kfz-Armaturenbrett-Zubehör',
  },
  {
    value: '2667',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Kfz-Aufkleber',
  },
  {
    value: '2652',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Kfz-Fahnen',
  },
  {
    value: '7022',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Kfz-Kennzeichenabdeckungen',
  },
  {
    value: '2789',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Kfz-Lufterfrischer',
  },
  {
    value: '8464',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Lenkradbezüge',
  },
  {
    value: '7532',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Magnete',
  },
  {
    value: '8478',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Rückspiegel-Deko',
  },
  {
    value: '8463',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Schalthebelverkleidung',
  },
  {
    value: '8142',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Dekor > Schaltknäufe',
  },
  {
    value: '2788',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten',
  },
  {
    value: '2770',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten > Benzin- & Ölzusätze',
  },
  {
    value: '3051',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten > Bremsflüssigkeit',
  },
  {
    value: '2916',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten > Flüssigkeit für hydraulische Kupplungen',
  },
  {
    value: '2635',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten > Frostschutzmittel',
  },
  {
    value: '2688',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten > Getriebeöl',
  },
  {
    value: '2735',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten > Kfz-Schmierstoffe',
  },
  {
    value: '2517',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten > Kühlsystemadditive',
  },
  {
    value: '2881',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten > Motorreiniger',
  },
  {
    value: '3044',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten > Motoröl',
  },
  {
    value: '2513',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten > Servolenkflüssigkeit',
  },
  {
    value: '2719',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten > Tankreiniger',
  },
  {
    value: '2943',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kfz-Flüssigkeiten > Wischwasser',
  },
  {
    value: '8534',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Kfz-Wartung & -Pflege > Kraftstoffkanister',
  },
  {
    value: '8237',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme',
  },
  {
    value: '8378',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Boxen & Sortiersysteme für Ladeflächen',
  },
  {
    value: '8475',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Bügel & Haken für Kopfstützen',
  },
  {
    value: '4027',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Anhänger',
  },
  {
    value: '1133',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Anhänger > Bootsanhänger',
  },
  {
    value: '4044',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Anhänger > Kleinanhänger & Transportanhänger',
  },
  {
    value: '4037',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Anhänger > Pferde- & Viehanhänger',
  },
  {
    value: '4243',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Anhänger > Wohnwagen',
  },
  {
    value: '3472',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträger',
  },
  {
    value: '6046',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträger > Autogepäckträger für Angelruten',
  },
  {
    value: '6047',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträger > Autogepäckträger für Kanus & Kajaks',
  },
  {
    value: '6044',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträger > Autogepäckträger für Motorräder & Motorroller',
  },
  {
    value: '6043',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträger > Autogepäckträger für Skier & Snowboards',
  },
  {
    value: '6042',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträger > Autogepäckträger für Surfbretter',
  },
  {
    value: '7115',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträger > Autogewehrhalter',
  },
  {
    value: '2836',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträger > Fahrradträger',
  },
  {
    value: '4240',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträger > Gepäckträger',
  },
  {
    value: '6041',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträger > Grundgestelle für Autogepäckträger',
  },
  {
    value: '6454',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträgerzubehör',
  },
  {
    value: '7122',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträgerzubehör > Fahrradträgerzubehör',
  },
  {
    value: '8086',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Gepäckträgerzubehör > Ski- & Snowboardträger-Zubehör',
  },
  {
    value: '6744',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Kfz-Ladenetze',
  },
  {
    value: '8147',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Laderampen',
  },
  {
    value: '5512',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Motorradtaschen & -koffer',
  },
  {
    value: '2290',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Stauraum- & Transportsysteme > Utensilientaschen fürs Auto',
  },
  {
    value: '3391',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör',
  },
  {
    value: '3315',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Anlegen & Ankern',
  },
  {
    value: '3189',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Anlegen & Ankern > Anker',
  },
  {
    value: '3452',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Anlegen & Ankern > Ankerketten',
  },
  {
    value: '3362',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Anlegen & Ankern > Ankerleinen & -seile',
  },
  {
    value: '3480',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Anlegen & Ankern > Ankerwinden',
  },
  {
    value: '3655',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Anlegen & Ankern > Bootshaken',
  },
  {
    value: '3718',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Anlegen & Ankern > Bootsleitern',
  },
  {
    value: '3572',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Anlegen & Ankern > Klampen & Klemmen',
  },
  {
    value: '3899',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Anlegen & Ankern > Stufentritte',
  },
  {
    value: '6293',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Beleuchtung für Wasserfahrzeuge',
  },
  {
    value: '3400',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Kraftstoffsysteme für Wasserfahrzeuge',
  },
  {
    value: '3968',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Kraftstoffsysteme für Wasserfahrzeuge > Benzinuhren für Wasserfahrzeuge',
  },
  {
    value: '3415',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Kraftstoffsysteme für Wasserfahrzeuge > Kraftstoffleitungen & Teile für Wasserfahrzeuge',
  },
  {
    value: '3892',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Kraftstoffsysteme für Wasserfahrzeuge > Kraftstoffpumpen & Teile für Wasserfahrzeuge',
  },
  {
    value: '3648',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Kraftstoffsysteme für Wasserfahrzeuge > Kraftstofftanks & Teile für Wasserfahrzeuge',
  },
  {
    value: '1132',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Segelbootteile',
  },
  {
    value: '1125',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugantriebe & -motoren',
  },
  {
    value: '3619',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugauspuffteile',
  },
  {
    value: '3309',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugauspuffteile > Schalldämpfer & Teile für Wasserfahrzeuge',
  },
  {
    value: '3232',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugauspuffteile > Wasserfahrzeugkrümmer',
  },
  {
    value: '3606',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugmotorteile',
  },
  {
    value: '3566',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugmotorteile > Motorlager für Wasserfahrzeuge',
  },
  {
    value: '3507',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugmotorteile > Motorschlösser für Wasserfahrzeuge',
  },
  {
    value: '3097',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugmotorteile > Wasserfahrzeug Laufrad',
  },
  {
    value: '3743',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugmotorteile > Wasserfahrzeug-Zündungsteile',
  },
  {
    value: '3277',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugmotorteile > Wasserfahrzeugkolben & Teile',
  },
  {
    value: '3143',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugmotorteile > Wasserfahrzeuglichtmaschinen',
  },
  {
    value: '3321',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugmotorteile > Wasserfahrzeugmotorsteuerung',
  },
  {
    value: '3806',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugmotorteile > Wasserfahrzeugpropeller',
  },
  {
    value: '3463',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugmotorteile > Wasserfahrzeugvergaser & Teile',
  },
  {
    value: '1122',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugpflege',
  },
  {
    value: '3955',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugpflege > Wasserfahrzeugpolituren',
  },
  {
    value: '3866',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugpflege > Wasserfahrzeugreiniger',
  },
  {
    value: '3995',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugsteuerung',
  },
  {
    value: '3308',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugsteuerung > Steuerkabel für Wasserfahrzeuge',
  },
  {
    value: '3663',
    label:
      'Fahrzeuge & Teile > Fahrzeugersatzteile & -zubehör > Wasserfahrzeugteile & Zubehör > Wasserfahrzeugsteuerung > Steuerräder für Wasserfahrzeuge',
  },
  {
    value: '772',
    label: 'Für Erwachsene',
  },
  {
    value: '773',
    label: 'Für Erwachsene > Erotik',
  },
  {
    value: '779',
    label: 'Für Erwachsene > Erotik > Erotikbücher',
  },
  {
    value: '776',
    label: 'Für Erwachsene > Erotik > Erotische DVDs & Videos',
  },
  {
    value: '774',
    label: 'Für Erwachsene > Erotik > Erotische Kleidung',
  },
  {
    value: '5055',
    label: 'Für Erwachsene > Erotik > Erotische Lebensmittel & Esswaren',
  },
  {
    value: '6040',
    label: 'Für Erwachsene > Erotik > Erotische Spiele',
  },
  {
    value: '4060',
    label: 'Für Erwachsene > Erotik > Erotische Zeitschriften',
  },
  {
    value: '6536',
    label: 'Für Erwachsene > Erotik > Pole Dance-Tanzstangenkits',
  },
  {
    value: '778',
    label: 'Für Erwachsene > Erotik > Sexspielzeug',
  },
  {
    value: '780',
    label: 'Für Erwachsene > Waffen',
  },
  {
    value: '727',
    label: 'Für Erwachsene > Waffen > Elektroschockpistolen & Taser-Waffen',
  },
  {
    value: '3666',
    label: 'Für Erwachsene > Waffen > Fechtwaffen',
  },
  {
    value: '3924',
    label: 'Für Erwachsene > Waffen > Hieb- und Stichwaffen',
  },
  {
    value: '6109',
    label: 'Für Erwachsene > Waffen > Kampfmesser',
  },
  {
    value: '3092',
    label: 'Für Erwachsene > Waffen > Nunchakus',
  },
  {
    value: '3437',
    label: 'Für Erwachsene > Waffen > Peitschen',
  },
  {
    value: '3833',
    label: 'Für Erwachsene > Waffen > Schlagringe',
  },
  {
    value: '7567',
    label: 'Für Erwachsene > Waffen > Schlagstockwaffen',
  },
  {
    value: '782',
    label: 'Für Erwachsene > Waffen > Schusswaffen',
  },
  {
    value: '2214',
    label: 'Für Erwachsene > Waffen > Schusswaffenzubehör',
  },
  {
    value: '503026',
    label:
      'Für Erwachsene > Waffen > Schusswaffenzubehör > Material & Ausrüstung zum Nachladen von Schusswaffen',
  },
  {
    value: '499857',
    label:
      'Für Erwachsene > Waffen > Schusswaffenzubehör > Material & Ausrüstung zum Nachladen von Schusswaffen > Ladepressen',
  },
  {
    value: '781',
    label: 'Für Erwachsene > Waffen > Schusswaffenzubehör > Munition',
  },
  {
    value: '505762',
    label:
      'Für Erwachsene > Waffen > Schusswaffenzubehör > Munitionskisten & -halter',
  },
  {
    value: '1822',
    label: 'Für Erwachsene > Waffen > Schusswaffenzubehör > Picatinny-Schienen',
  },
  {
    value: '1783',
    label: 'Für Erwachsene > Waffen > Schusswaffenzubehör > Pistolentaschen',
  },
  {
    value: '1806',
    label: 'Für Erwachsene > Waffen > Schusswaffenzubehör > Schusswaffengriffe',
  },
  {
    value: '499853',
    label: 'Für Erwachsene > Waffen > Schusswaffenzubehör > Schusswaffengurte',
  },
  {
    value: '503021',
    label:
      'Für Erwachsene > Waffen > Schusswaffenzubehör > Schusswaffenreinigung',
  },
  {
    value: '499854',
    label:
      'Für Erwachsene > Waffen > Schusswaffenzubehör > Schusswaffenreinigung > Schusswaffen-Reinigungsmittel',
  },
  {
    value: '499856',
    label:
      'Für Erwachsene > Waffen > Schusswaffenzubehör > Schusswaffenreinigung > Schusswaffen-Reinigungspatches',
  },
  {
    value: '499855',
    label:
      'Für Erwachsene > Waffen > Schusswaffenzubehör > Schusswaffenreinigung > Schusswaffen-Reinigungstücher',
  },
  {
    value: '500048',
    label:
      'Für Erwachsene > Waffen > Schusswaffenzubehör > Waffenkoffer und -taschen',
  },
  {
    value: '5067',
    label: 'Für Erwachsene > Waffen > Schusswaffenzubehör > Waffenleuchten',
  },
  {
    value: '7175',
    label: 'Für Erwachsene > Waffen > Speerwaffen',
  },
  {
    value: '726',
    label: 'Für Erwachsene > Waffen > Tränengas & Pfefferspray',
  },
  {
    value: '3694',
    label: 'Für Erwachsene > Waffen > Wurfsterne',
  },
  {
    value: '469',
    label: 'Gesundheit & Schönheit',
  },
  {
    value: '491',
    label: 'Gesundheit & Schönheit > Gesundheitspflege',
  },
  {
    value: '5849',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Akupunktur',
  },
  {
    value: '5850',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Akupunktur > Akupunkturmodelle',
  },
  {
    value: '5851',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Akupunktur > Akupunkturnadeln',
  },
  {
    value: '8105',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Amputationsstrümpfe',
  },
  {
    value: '4551',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Beatmungsmedizin',
  },
  {
    value: '7317',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Beatmungsmedizin > CPAP-Geräte',
  },
  {
    value: '7316',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Beatmungsmedizin > CPAP-Masken',
  },
  {
    value: '505669',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Beatmungsmedizin > Dampfinhalatoren',
  },
  {
    value: '4552',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Beatmungsmedizin > Inhalatoren',
  },
  {
    value: '499692',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Beatmungsmedizin > Sauerstoffbehälter',
  },
  {
    value: '7002',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Behälter für Verhütungsmittel',
  },
  {
    value: '7220',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Bettpfannen',
  },
  {
    value: '5870',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Ergo- & Physiotherapeutische Hilfsmittel',
  },
  {
    value: '8541',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Ergo- & Physiotherapeutische Hilfsmittel > Elektrische Muskelstimulatoren',
  },
  {
    value: '505352',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Ergo- & Physiotherapeutische Hilfsmittel > Therapieschaukeln',
  },
  {
    value: '508',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Erste Hilfe',
  },
  {
    value: '2954',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Erste Hilfe > Antiseptika & Reinigung',
  },
  {
    value: '4527',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Erste Hilfe > Augenwaschbedarf',
  },
  {
    value: '510',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Erste Hilfe > Erste-Hilfe-Koffer',
  },
  {
    value: '509',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Erste Hilfe > Medizinisches Klebeband & Verbandsmaterial',
  },
  {
    value: '6206',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Erste Hilfe > Schutzhüllen für Gipsverbände',
  },
  {
    value: '516',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Erste Hilfe > Warm & Kalt Therapie',
  },
  {
    value: '4753',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Erste Hilfe > Warm & Kalt Therapie > Eisanwendungen',
  },
  {
    value: '5848',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Erste Hilfe > Warm & Kalt Therapie > Wärmecremes',
  },
  {
    value: '6205',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Erste Hilfe > Warm & Kalt Therapie > Wärmflaschen & Heizkissen',
  },
  {
    value: '2890',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Fitness & Ernährung',
  },
  {
    value: '6242',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Fitness & Ernährung > Energie-Gel & -Nahrung',
  },
  {
    value: '7413',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Fitness & Ernährung > Flüssignahrung zur künstlichen Ernährung',
  },
  {
    value: '5702',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Fitness & Ernährung > Mahlzeitenersatz-Getränke',
  },
  {
    value: '6871',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Fitness & Ernährung > Pürierte Lebensmittel',
  },
  {
    value: '2984',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Fitness & Ernährung > Sportriegel',
  },
  {
    value: '525',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Fitness & Ernährung > Vitamine & Nahrungsergänzungsmittel',
  },
  {
    value: '5966',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Hausnotrufgeräte',
  },
  {
    value: '5690',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Hörhilfen',
  },
  {
    value: '517',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Inkontinenzartikel',
  },
  {
    value: '775',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Kondome',
  },
  {
    value: '505820',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Kontaktgels & -lotionen',
  },
  {
    value: '500087',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Lichttherapielampen',
  },
  {
    value: '518',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medikamente & Arzneimittel',
  },
  {
    value: '494',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Messgeräte',
  },
  {
    value: '500009',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Messgeräte > Aktivitätsmonitore',
  },
  {
    value: '2633',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Messgeräte > Alkoholmessgeräte',
  },
  {
    value: '495',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Messgeräte > Blutdruckmessgeräte',
  },
  {
    value: '2246',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Messgeräte > Blutzuckermessgeräte',
  },
  {
    value: '497',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Messgeräte > Cholesterinmessgeräte',
  },
  {
    value: '501',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Messgeräte > Fieberthermometer',
  },
  {
    value: '505822',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Messgeräte > Fruchtbarkeitsmonitore & Ovulationstests ',
  },
  {
    value: '496',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Messgeräte > Körperfettanalyse',
  },
  {
    value: '500',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Messgeräte > Personenwaagen',
  },
  {
    value: '4767',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Messgeräte > Pränatale Herzfrequenzmesser',
  },
  {
    value: '5551',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Messgeräte > Pulsoximeter',
  },
  {
    value: '505293',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Tests',
  },
  {
    value: '499934',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Tests > Allergietest-Sets',
  },
  {
    value: '7337',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Tests > Blutgruppentests',
  },
  {
    value: '2552',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Tests > Drogentests',
  },
  {
    value: '7336',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Tests > HIV-Tests',
  },
  {
    value: '505294',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Tests > Harnwegsinfektionstests',
  },
  {
    value: '1680',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinische Tests > Schwangerschaftstests',
  },
  {
    value: '5923',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Medizinisches Gleitgel',
  },
  {
    value: '5965',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Notfall-Armband',
  },
  {
    value: '3777',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Pillendosen',
  },
  {
    value: '8082',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Probenbehälter',
  },
  {
    value: '519',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf',
  },
  {
    value: '5488',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Behindertengerechte Möbel & Vorrichtungen',
  },
  {
    value: '7243',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Behindertengerechte Möbel & Vorrichtungen > Duschbänke & -sitze',
  },
  {
    value: '5164',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Gehhilfen',
  },
  {
    value: '5165',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Gehhilfen > Gehstöcke',
  },
  {
    value: '4248',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Gehhilfen > Krücken',
  },
  {
    value: '5166',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Gehhilfen > Rollatoren',
  },
  {
    value: '6929',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Gehhilfenzubehör',
  },
  {
    value: '520',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Zubehör & Bedarf zur Barrierefreiheit',
  },
  {
    value: '3512',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Zubehör & Bedarf zur Barrierefreiheit > Elektromobile',
  },
  {
    value: '3364',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Zubehör & Bedarf zur Barrierefreiheit > Rollstühle',
  },
  {
    value: '7138',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Zubehör & Bedarf zur Barrierefreiheit > Treppenlifte',
  },
  {
    value: '502969',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Zubehör & Bedarf zur Barrierefreiheit > Umlagerungsbretter & -tücher',
  },
  {
    value: '521',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Senioren- & Behindertenbedarf > Zubehör für Behindertengerechte Möbel & Vorrichtungen',
  },
  {
    value: '7186',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Spermizide',
  },
  {
    value: '523',
    label: 'Gesundheit & Schönheit > Gesundheitspflege > Stützen & Bandagen',
  },
  {
    value: '5071',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Zubehör für biometrische Messgeräte',
  },
  {
    value: '3688',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Zubehör für biometrische Messgeräte > Blutzuckermessung',
  },
  {
    value: '6323',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Zubehör für biometrische Messgeräte > Blutzuckermessung > Blutzucker-Kontrolllösung',
  },
  {
    value: '3905',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Zubehör für biometrische Messgeräte > Blutzuckermessung > Blutzuckerteststreifen',
  },
  {
    value: '3111',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Zubehör für biometrische Messgeräte > Blutzuckermessung > Stechhilfen',
  },
  {
    value: '505819',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Zubehör für biometrische Messgeräte > Zubehör für Aktivitätsmonitore',
  },
  {
    value: '5072',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Zubehör für biometrische Messgeräte > Zubehör für Badezimmerwaagen',
  },
  {
    value: '6284',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Zubehör für biometrische Messgeräte > Zubehör für Blutdrucküberwachungsgeräte',
  },
  {
    value: '6285',
    label:
      'Gesundheit & Schönheit > Gesundheitspflege > Zubehör für biometrische Messgeräte > Zubehör für Blutdrucküberwachungsgeräte > Blutdruckmanschetten',
  },
  {
    value: '2915',
    label: 'Gesundheit & Schönheit > Körperpflege',
  },
  {
    value: '1380',
    label: 'Gesundheit & Schönheit > Körperpflege > Augenpflege',
  },
  {
    value: '2922',
    label: 'Gesundheit & Schönheit > Körperpflege > Augenpflege > Augentropfen',
  },
  {
    value: '524',
    label: 'Gesundheit & Schönheit > Körperpflege > Augenpflege > Brillen',
  },
  {
    value: '2733',
    label:
      'Gesundheit & Schönheit > Körperpflege > Augenpflege > Brillengläser',
  },
  {
    value: '2521',
    label:
      'Gesundheit & Schönheit > Körperpflege > Augenpflege > Brillenzubehör',
  },
  {
    value: '8204',
    label:
      'Gesundheit & Schönheit > Körperpflege > Augenpflege > Brillenzubehör > Brillenbänder & -ketten',
  },
  {
    value: '5507',
    label:
      'Gesundheit & Schönheit > Körperpflege > Augenpflege > Brillenzubehör > Brillenetuis & -halter',
  },
  {
    value: '352853',
    label:
      'Gesundheit & Schönheit > Körperpflege > Augenpflege > Brillenzubehör > Kontaktlinsenreiniger',
  },
  {
    value: '2923',
    label:
      'Gesundheit & Schönheit > Körperpflege > Augenpflege > Kontaktlinsen',
  },
  {
    value: '3011',
    label:
      'Gesundheit & Schönheit > Körperpflege > Augenpflege > Kontaktlinsenpflege',
  },
  {
    value: '7363',
    label:
      'Gesundheit & Schönheit > Körperpflege > Augenpflege > Kontaktlinsenpflege > Kontaktlinsen-Pflegesets',
  },
  {
    value: '6510',
    label:
      'Gesundheit & Schönheit > Körperpflege > Augenpflege > Kontaktlinsenpflege > Kontaktlinsenbehälter',
  },
  {
    value: '6509',
    label:
      'Gesundheit & Schönheit > Körperpflege > Augenpflege > Kontaktlinsenpflege > Kontaktlinsenpflegemittel',
  },
  {
    value: '6977',
    label:
      'Gesundheit & Schönheit > Körperpflege > Augenpflege > Sonnenbrillengläser',
  },
  {
    value: '485',
    label: 'Gesundheit & Schönheit > Körperpflege > Damenhygieneartikel',
  },
  {
    value: '6862',
    label:
      'Gesundheit & Schönheit > Körperpflege > Damenhygieneartikel > Damen-Deodorant',
  },
  {
    value: '5821',
    label:
      'Gesundheit & Schönheit > Körperpflege > Damenhygieneartikel > Intim-Waschlotionen für Frauen',
  },
  {
    value: '8122',
    label:
      'Gesundheit & Schönheit > Körperpflege > Damenhygieneartikel > Menstruationstassen',
  },
  {
    value: '2387',
    label:
      'Gesundheit & Schönheit > Körperpflege > Damenhygieneartikel > Slipeinlagen & Binden',
  },
  {
    value: '2564',
    label:
      'Gesundheit & Schönheit > Körperpflege > Damenhygieneartikel > Tampons',
  },
  {
    value: '484',
    label:
      'Gesundheit & Schönheit > Körperpflege > Deodorants & Antitranspirante',
  },
  {
    value: '7134',
    label: 'Gesundheit & Schönheit > Körperpflege > Einlaufsets & -zubehör',
  },
  {
    value: '515',
    label: 'Gesundheit & Schönheit > Körperpflege > Fußpflege',
  },
  {
    value: '2992',
    label: 'Gesundheit & Schönheit > Körperpflege > Fußpflege > Ballenpflege',
  },
  {
    value: '2801',
    label:
      'Gesundheit & Schönheit > Körperpflege > Fußpflege > Einlegesohlen & Einlagen',
  },
  {
    value: '3049',
    label:
      'Gesundheit & Schönheit > Körperpflege > Fußpflege > Fußgeruchskiller',
  },
  {
    value: '3022',
    label:
      'Gesundheit & Schönheit > Körperpflege > Fußpflege > Hühneraugen- & Hornhautprodukte',
  },
  {
    value: '7495',
    label: 'Gesundheit & Schönheit > Körperpflege > Fußpflege > Zehenspreizer',
  },
  {
    value: '777',
    label: 'Gesundheit & Schönheit > Körperpflege > Gleitmittel',
  },
  {
    value: '486',
    label: 'Gesundheit & Schönheit > Körperpflege > Haarkosmetik',
  },
  {
    value: '6053',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Haar-Decoloration',
  },
  {
    value: '2814',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Haarfärbemittel',
  },
  {
    value: '8452',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Haarpflege-Sets',
  },
  {
    value: '3013',
    label: 'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Haarscheren',
  },
  {
    value: '6019',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Haarstyling-Geräte',
  },
  {
    value: '3407',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Haarstyling-Geräte > Haarglätter',
  },
  {
    value: '490',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Haarstyling-Geräte > Haartrockner',
  },
  {
    value: '499992',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Haarstyling-Geräte > Hairstyling-Sets',
  },
  {
    value: '487',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Haarstyling-Geräte > Kämme & Bürsten',
  },
  {
    value: '489',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Haarstyling-Geräte > Lockenstäbe',
  },
  {
    value: '488',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Haarstyling-Geräte > Lockenwickler',
  },
  {
    value: '6099',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Haarverdichter',
  },
  {
    value: '6052',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Mittel für Dauerwellen & Haarglättung, chemische Haarglättung',
  },
  {
    value: '4766',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Mittel gegen Haarverlust',
  },
  {
    value: '2441',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Shampoo & Spülung',
  },
  {
    value: '1901',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Styling-Gel, Haarspray & Haarschaum',
  },
  {
    value: '6429',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Trockenhauben',
  },
  {
    value: '5977',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Zubehör für Haarcolorationen',
  },
  {
    value: '6018',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Zubehör für Haarstyling-Geräte',
  },
  {
    value: '4569',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Zubehör für Haarstyling-Geräte > Haareisenzubehör',
  },
  {
    value: '4475',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Zubehör für Haarstyling-Geräte > Haartrocknerzubehör',
  },
  {
    value: '5317',
    label:
      'Gesundheit & Schönheit > Körperpflege > Haarkosmetik > Zubehör für Haarstyling-Geräte > Lockenwicklerklammern & -nadeln',
  },
  {
    value: '473',
    label: 'Gesundheit & Schönheit > Körperpflege > Kosmetika',
  },
  {
    value: '474',
    label: 'Gesundheit & Schönheit > Körperpflege > Kosmetika > Badeartikel',
  },
  {
    value: '2875',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Badeartikel > Bade- & Naturschwämme',
  },
  {
    value: '2876',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Badeartikel > Badebürsten',
  },
  {
    value: '4049',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Badeartikel > Duschhauben',
  },
  {
    value: '499913',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Badeartikel > Feuchttücher für Erwachsene',
  },
  {
    value: '3208',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Badeartikel > Flüssigseifen',
  },
  {
    value: '3691',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Badeartikel > Handdesinfektionsmittel',
  },
  {
    value: '2522',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Badeartikel > Schaumbäder & Badesalze',
  },
  {
    value: '2503',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Badeartikel > Seife',
  },
  {
    value: '7417',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Badeartikel > Seife in Pulverform',
  },
  {
    value: '2747',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Badeartikel > Waschlotion',
  },
  {
    value: '479',
    label: 'Gesundheit & Schönheit > Körperpflege > Kosmetika > Düfte',
  },
  {
    value: '475',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Geschenkkörbe Bad & Körperpflege',
  },
  {
    value: '567',
    label: 'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege',
  },
  {
    value: '481',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Aknebehandlung',
  },
  {
    value: '7429',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Anti-Aging-Hautpflegeprodukte',
  },
  {
    value: '2740',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Bräunungsprodukte',
  },
  {
    value: '5338',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Bräunungsprodukte > Selbstbräuner',
  },
  {
    value: '5339',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Bräunungsprodukte > Sonnenöle & -lotionen',
  },
  {
    value: '6262',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Gesichtspeeling',
  },
  {
    value: '2526',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Gesichtsreiniger',
  },
  {
    value: '7467',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Gesichtsreinigersets',
  },
  {
    value: '5820',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Insektenschutzmittel',
  },
  {
    value: '8029',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Komprimierte Gesichtsmasken',
  },
  {
    value: '5980',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Körperpuder',
  },
  {
    value: '6104',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Körperöle',
  },
  {
    value: '482',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Lippenpflege',
  },
  {
    value: '2592',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Lotion & Feuchtigkeitscremes',
  },
  {
    value: '6034',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Make-up-Entferner',
  },
  {
    value: '6791',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Porenreinigungspflaster',
  },
  {
    value: '5976',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Reinigungswasser & Adstringentien',
  },
  {
    value: '2844',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Sonnencreme',
  },
  {
    value: '6753',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Vaseline',
  },
  {
    value: '6863',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Hautpflege > Warzenentferner',
  },
  {
    value: '2619',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör',
  },
  {
    value: '2958',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Hautpflegeutensilien',
  },
  {
    value: '499926',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Hautpflegeutensilien > Applikatoren für Lotionen und Sonnenschutzcremes',
  },
  {
    value: '2511',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Hautpflegeutensilien > Bimssteine',
  },
  {
    value: '7190',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Hautpflegeutensilien > Fußfeilen',
  },
  {
    value: '6260',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Hautpflegeutensilien > Gesichtsbürsten',
  },
  {
    value: '6760',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Hautpflegeutensilien > Gesichtssaunas',
  },
  {
    value: '7018',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Hautpflegeutensilien > Hautpflegeroller',
  },
  {
    value: '8132',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Hautpflegeutensilien > Hautreinigungs-Bürstenköpfe',
  },
  {
    value: '6261',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Hautpflegeutensilien > Mitesser-Entferner',
  },
  {
    value: '2548',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör',
  },
  {
    value: '6555',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Augenbrauenschablonen',
  },
  {
    value: '6282',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Ersatzkissen für Wimpernzangen',
  },
  {
    value: '3025',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Make-up-Pinsel',
  },
  {
    value: '4106',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Make-up-Schwämme',
  },
  {
    value: '476',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Make-up-Spiegel',
  },
  {
    value: '499822',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Nachfüllbare Make-up-Paletten & -Koffer',
  },
  {
    value: '4121',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Pudertücher',
  },
  {
    value: '7356',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Schlupflid-Kleber & -Klebestreifen',
  },
  {
    value: '2780',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Wimpernformer',
  },
  {
    value: '502996',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Zubehör für künstliche Wimpern',
  },
  {
    value: '7493',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Zubehör für künstliche Wimpern > Applikatoren für künstliche Wimpern',
  },
  {
    value: '502997',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Zubehör für künstliche Wimpern > Entferner für künstliche Wimpern',
  },
  {
    value: '7256',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Make-up Zubehör > Zubehör für künstliche Wimpern > Kleber für künstliche Wimpern',
  },
  {
    value: '2975',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Maniküre & Pediküre',
  },
  {
    value: '7494',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Maniküre & Pediküre > Finger- & Zehenspreizer',
  },
  {
    value: '6300',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Maniküre & Pediküre > Maniküre-Sets',
  },
  {
    value: '2734',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Maniküre & Pediküre > Nagelfeilen & Sandblattfeilen',
  },
  {
    value: '7490',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Maniküre & Pediküre > Nagelfräser',
  },
  {
    value: '3037',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Maniküre & Pediküre > Nagelhautscheren',
  },
  {
    value: '2739',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Maniküre & Pediküre > Nagelhautschieber',
  },
  {
    value: '2828',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Maniküre & Pediküre > Nagelknipser',
  },
  {
    value: '499698',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Maniküre & Pediküre > Nagelpiercing-Zubehör',
  },
  {
    value: '6341',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Maniküre & Pediküre > Nagelpolierer',
  },
  {
    value: '5880',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetik-Zubehör > Maniküre & Pediküre > Nageltrockner',
  },
  {
    value: '6069',
    label: 'Gesundheit & Schönheit > Körperpflege > Kosmetika > Kosmetiksets',
  },
  {
    value: '477',
    label: 'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up',
  },
  {
    value: '2779',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Augen',
  },
  {
    value: '2686',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Augen > Augenbrauen Make-up',
  },
  {
    value: '2807',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Augen > Eyeliner & Kajal',
  },
  {
    value: '2761',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Augen > Künstliche Wimpern',
  },
  {
    value: '2904',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Augen > Lidschatten',
  },
  {
    value: '8220',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Augen > Lidschatten-Grundierung',
  },
  {
    value: '2834',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Augen > Mascara',
  },
  {
    value: '8219',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Augen > Mascara-Grundierung',
  },
  {
    value: '6340',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Augen > Wimpern- & Augenbrauenbehandlung',
  },
  {
    value: '4779',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Kostüm- & Bühnen-Make-up',
  },
  {
    value: '5978',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Körper-Make-up',
  },
  {
    value: '5979',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Körper-Make-up > Bodypainting-Farbe',
  },
  {
    value: '5981',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Körper-Make-up > Glitter für Körper und Haar',
  },
  {
    value: '2645',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Lippen',
  },
  {
    value: '2858',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Lippen > Lipgloss',
  },
  {
    value: '2589',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Lippen > Lipliner',
  },
  {
    value: '6306',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Lippen > Lippen- & Wangentönung',
  },
  {
    value: '3021',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Lippen > Lippenstift',
  },
  {
    value: '8217',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Lippen > Lippenstift-Grundierung',
  },
  {
    value: '6072',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Spray zum Fixieren von Make-up',
  },
  {
    value: '2571',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Teint',
  },
  {
    value: '8218',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Teint > Gesichtsgrundierung',
  },
  {
    value: '2765',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Teint > Grundierungen & Concealer',
  },
  {
    value: '6304',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Teint > Highlighter & Luminizer',
  },
  {
    value: '2980',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Teint > Puder',
  },
  {
    value: '6305',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Teint > Rouge & Bronzepuder',
  },
  {
    value: '3509',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Make-up > Temporäre Tattoos',
  },
  {
    value: '478',
    label: 'Gesundheit & Schönheit > Körperpflege > Kosmetika > Nagelpflege',
  },
  {
    value: '4218',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Nagelpflege > Künstliche Nägel',
  },
  {
    value: '3009',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Nagelpflege > Nagelhautcremes & -Lotionen',
  },
  {
    value: '6893',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Nagelpflege > Nagelkleber',
  },
  {
    value: '5975',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Nagelpflege > Nagelkunst-Zubehör',
  },
  {
    value: '233419',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Nagelpflege > Nagellack-Überlacke und -Trockensprays',
  },
  {
    value: '2683',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Nagelpflege > Nagellacke',
  },
  {
    value: '2946',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Nagelpflege > Nagellackentferner',
  },
  {
    value: '7445',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Nagelpflege > Nagellackverdünner',
  },
  {
    value: '6331',
    label:
      'Gesundheit & Schönheit > Körperpflege > Kosmetika > Reiniger für Kosmetikwerkzeuge',
  },
  {
    value: '5663',
    label: 'Gesundheit & Schönheit > Körperpflege > Massage & Entspannung',
  },
  {
    value: '233420',
    label:
      'Gesundheit & Schönheit > Körperpflege > Massage & Entspannung > Augenkissen',
  },
  {
    value: '471',
    label:
      'Gesundheit & Schönheit > Körperpflege > Massage & Entspannung > Massagegeräte',
  },
  {
    value: '8530',
    label:
      'Gesundheit & Schönheit > Körperpflege > Massage & Entspannung > Massagestein-Wärmer',
  },
  {
    value: '8135',
    label:
      'Gesundheit & Schönheit > Körperpflege > Massage & Entspannung > Massagesteine',
  },
  {
    value: '1442',
    label:
      'Gesundheit & Schönheit > Körperpflege > Massage & Entspannung > Massagestühle',
  },
  {
    value: '2074',
    label:
      'Gesundheit & Schönheit > Körperpflege > Massage & Entspannung > Massagetische',
  },
  {
    value: '5664',
    label:
      'Gesundheit & Schönheit > Körperpflege > Massage & Entspannung > Massageöle',
  },
  {
    value: '500060',
    label:
      'Gesundheit & Schönheit > Körperpflege > Massage & Entspannung > Rückenkratzer',
  },
  {
    value: '526',
    label: 'Gesundheit & Schönheit > Körperpflege > Mundpflege',
  },
  {
    value: '5823',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Aufbissschienen',
  },
  {
    value: '6455',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Ersatzdüsen für Mundduschen',
  },
  {
    value: '5824',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Gebissreiniger',
  },
  {
    value: '6715',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Interdentalreiniger',
  },
  {
    value: '5295',
    label: 'Gesundheit & Schönheit > Körperpflege > Mundpflege > Mundduschen',
  },
  {
    value: '6189',
    label: 'Gesundheit & Schönheit > Körperpflege > Mundpflege > Mundspray',
  },
  {
    value: '3040',
    label: 'Gesundheit & Schönheit > Körperpflege > Mundpflege > Mundwasser',
  },
  {
    value: '8543',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Reparatursets für Zahnprothesen',
  },
  {
    value: '505367',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Spangen-, Gebiss- & Aufbissschienendosen',
  },
  {
    value: '3019',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnaufhellung',
  },
  {
    value: '527',
    label: 'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnbürsten',
  },
  {
    value: '4775',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnbürstenzubehör',
  },
  {
    value: '4776',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnbürstenzubehör > Ersatzköpfe für Zahnbürsten',
  },
  {
    value: '4942',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnbürstenzubehör > Zahnbürstendesinfektionsmittel',
  },
  {
    value: '6920',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnbürstenzubehör > Zahnbürstenhüllen',
  },
  {
    value: '2527',
    label: 'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnersatz',
  },
  {
    value: '2769',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnfleischstimulierende Geräte',
  },
  {
    value: '1360',
    label: 'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnpasta',
  },
  {
    value: '5154',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnpastapressen & -spender',
  },
  {
    value: '5155',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnprothesehaftmittel',
  },
  {
    value: '2620',
    label: 'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnseide',
  },
  {
    value: '4316',
    label: 'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zahnstocher',
  },
  {
    value: '6441',
    label:
      'Gesundheit & Schönheit > Körperpflege > Mundpflege > Zungenreiniger',
  },
  {
    value: '506',
    label: 'Gesundheit & Schönheit > Körperpflege > Ohrenpflege',
  },
  {
    value: '6562',
    label:
      'Gesundheit & Schönheit > Körperpflege > Ohrenpflege > Ohrenreinigungssets',
  },
  {
    value: '2596',
    label: 'Gesundheit & Schönheit > Körperpflege > Ohrenpflege > Ohrenstöpsel',
  },
  {
    value: '6560',
    label:
      'Gesundheit & Schönheit > Körperpflege > Ohrenpflege > Ohrentrockner',
  },
  {
    value: '6559',
    label: 'Gesundheit & Schönheit > Körperpflege > Ohrenpflege > Ohrentropfen',
  },
  {
    value: '5706',
    label: 'Gesundheit & Schönheit > Körperpflege > Ohrenpflege > Ohrkerzen',
  },
  {
    value: '500024',
    label: 'Gesundheit & Schönheit > Körperpflege > Ohrenpflege > Ohrlöffel',
  },
  {
    value: '6561',
    label: 'Gesundheit & Schönheit > Körperpflege > Ohrenpflege > Ohrspritzen',
  },
  {
    value: '7542',
    label:
      'Gesundheit & Schönheit > Körperpflege > Ohrenpflege > Ohrstöpselspender',
  },
  {
    value: '2656',
    label: 'Gesundheit & Schönheit > Körperpflege > Pinzetten',
  },
  {
    value: '528',
    label: 'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen',
  },
  {
    value: '2508',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Alaunstifte',
  },
  {
    value: '532',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Elektrorasierer',
  },
  {
    value: '533',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Haar- & Bartschneider',
  },
  {
    value: '8214',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Haaraufheller',
  },
  {
    value: '4507',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Haarentfernung',
  },
  {
    value: '4509',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Haarentfernung > Elektrolysegeräte',
  },
  {
    value: '4508',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Haarentfernung > Enthaarungsmittel',
  },
  {
    value: '8136',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Haarentfernung > Enthaarungswachs-Wärmer',
  },
  {
    value: '4510',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Haarentfernung > Epilatoren',
  },
  {
    value: '7199',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Haarentfernung > Laserhaarentfernung',
  },
  {
    value: '4511',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Haarentfernung > Waxing-Sets & Zubehör',
  },
  {
    value: '2971',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Rasiercreme',
  },
  {
    value: '534',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Rasierer & Rasierklingen',
  },
  {
    value: '2681',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Rasierpinsel',
  },
  {
    value: '8531',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Rasierschalen',
  },
  {
    value: '5111',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Rasiersets',
  },
  {
    value: '529',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Rasierwasser',
  },
  {
    value: '6842',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Zubehör für Haarschneidegeräte & Trimmer',
  },
  {
    value: '531',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rasieren & Trimmen > Zubehör für elektrische Rasierer',
  },
  {
    value: '493',
    label: 'Gesundheit & Schönheit > Körperpflege > Rückengesundheit',
  },
  {
    value: '7404',
    label:
      'Gesundheit & Schönheit > Körperpflege > Rückengesundheit > Stützkissen für Rücken- & Lendenbereich',
  },
  {
    value: '4076',
    label: 'Gesundheit & Schönheit > Körperpflege > Schlafhilfen',
  },
  {
    value: '6017',
    label:
      'Gesundheit & Schönheit > Körperpflege > Schlafhilfen > Hilfsmittel gegen Schnarchen und Schlaf-Apnoe',
  },
  {
    value: '4211',
    label: 'Gesundheit & Schönheit > Körperpflege > Schlafhilfen > Reisekissen',
  },
  {
    value: '4313',
    label:
      'Gesundheit & Schönheit > Körperpflege > Schlafhilfen > Schlafmasken',
  },
  {
    value: '4056',
    label:
      'Gesundheit & Schönheit > Körperpflege > Schlafhilfen > Soundmaschinen',
  },
  {
    value: '472',
    label: 'Gesundheit & Schönheit > Körperpflege > Sonnenbänke',
  },
  {
    value: '6921',
    label: 'Gesundheit & Schönheit > Körperpflege > Tanningzelt',
  },
  {
    value: '4929',
    label: 'Gesundheit & Schönheit > Körperpflege > Wattebällchen',
  },
  {
    value: '2934',
    label: 'Gesundheit & Schönheit > Körperpflege > Wattestäbchen',
  },
  {
    value: '5573',
    label: 'Gesundheit & Schönheit > Schmuckreinigung & -pflege',
  },
  {
    value: '500083',
    label:
      'Gesundheit & Schönheit > Schmuckreinigung & -pflege > Dampfstrahlreiniger für Schmuck',
  },
  {
    value: '500082',
    label:
      'Gesundheit & Schönheit > Schmuckreinigung & -pflege > Hilfsmittel zur Schmuckreinigung',
  },
  {
    value: '499919',
    label:
      'Gesundheit & Schönheit > Schmuckreinigung & -pflege > Lösungen und Poliermittel zur Schmuckreinigung',
  },
  {
    value: '5974',
    label:
      'Gesundheit & Schönheit > Schmuckreinigung & -pflege > Schmuckständer & -kästen',
  },
  {
    value: '5124',
    label:
      'Gesundheit & Schönheit > Schmuckreinigung & -pflege > Uhrreparatursätze',
  },
  {
    value: '536',
    label: 'Heim & Garten',
  },
  {
    value: '574',
    label: 'Heim & Garten > Badzubehör',
  },
  {
    value: '577',
    label: 'Heim & Garten > Badzubehör > Badematten & Badteppiche',
  },
  {
    value: '575',
    label: 'Heim & Garten > Badzubehör > Badewannen- & Duschablagen',
  },
  {
    value: '4366',
    label: 'Heim & Garten > Badzubehör > Badewannenkissen',
  },
  {
    value: '6858',
    label: 'Heim & Garten > Badzubehör > Badezimmer-Zubehörsets',
  },
  {
    value: '7093',
    label: 'Heim & Garten > Badzubehör > Badzubehörhalterungen',
  },
  {
    value: '578',
    label: 'Heim & Garten > Badzubehör > Duschvorhangringe',
  },
  {
    value: '1962',
    label: 'Heim & Garten > Badzubehör > Duschvorhangstangen',
  },
  {
    value: '580',
    label: 'Heim & Garten > Badzubehör > Duschvorhänge',
  },
  {
    value: '2034',
    label: 'Heim & Garten > Badzubehör > Garderobenhaken',
  },
  {
    value: '8114',
    label: 'Heim & Garten > Badzubehör > Haltegriffe',
  },
  {
    value: '586',
    label: 'Heim & Garten > Badzubehör > Handtuchhalter',
  },
  {
    value: '5141',
    label: 'Heim & Garten > Badzubehör > Händetrockner',
  },
  {
    value: '579',
    label: 'Heim & Garten > Badzubehör > Kosmetiktuchhalter',
  },
  {
    value: '2418',
    label: 'Heim & Garten > Badzubehör > Medizinschränke',
  },
  {
    value: '4971',
    label: 'Heim & Garten > Badzubehör > Seifen- & Lotionsspender',
  },
  {
    value: '582',
    label: 'Heim & Garten > Badzubehör > Seifenablagen',
  },
  {
    value: '583',
    label: 'Heim & Garten > Badzubehör > Toilettenbürsten & -halter',
  },
  {
    value: '7509',
    label: 'Heim & Garten > Badzubehör > Toilettenbürstenköpfe',
  },
  {
    value: '584',
    label: 'Heim & Garten > Badzubehör > Toilettenpapierhalter',
  },
  {
    value: '585',
    label: 'Heim & Garten > Badzubehör > Zahnbürstenhalter',
  },
  {
    value: '8016',
    label: 'Heim & Garten > Badzubehör > Zubehör für Händetrockner',
  },
  {
    value: '594',
    label: 'Heim & Garten > Beleuchtung',
  },
  {
    value: '2370',
    label: 'Heim & Garten > Beleuchtung > Bilderbeleuchtung',
  },
  {
    value: '7401',
    label: 'Heim & Garten > Beleuchtung > Bodeneinbauleuchten',
  },
  {
    value: '1546',
    label: 'Heim & Garten > Beleuchtung > Flutlichter & Punktstrahler',
  },
  {
    value: '4636',
    label: 'Heim & Garten > Beleuchtung > Lampen',
  },
  {
    value: '3006',
    label: 'Heim & Garten > Beleuchtung > Leuchten',
  },
  {
    value: '2524',
    label: 'Heim & Garten > Beleuchtung > Leuchten > Deckenleuchten',
  },
  {
    value: '2249',
    label: 'Heim & Garten > Beleuchtung > Leuchten > Kronleuchter',
  },
  {
    value: '2809',
    label: 'Heim & Garten > Beleuchtung > Leuchten > Unterbauleuchten',
  },
  {
    value: '6073',
    label:
      'Heim & Garten > Beleuchtung > Leuchten > Wandleuchten, Wandbeleuchtung',
  },
  {
    value: '2425',
    label: 'Heim & Garten > Beleuchtung > Leuchtmittel',
  },
  {
    value: '2944',
    label: 'Heim & Garten > Beleuchtung > Leuchtmittel > Glühlampen',
  },
  {
    value: '2947',
    label:
      'Heim & Garten > Beleuchtung > Leuchtmittel > Kompaktleuchtstofflampen',
  },
  {
    value: '3329',
    label: 'Heim & Garten > Beleuchtung > Leuchtmittel > LED-Leuchtmittel',
  },
  {
    value: '2690',
    label: 'Heim & Garten > Beleuchtung > Leuchtmittel > Leuchtstoffröhren',
  },
  {
    value: '6274',
    label: 'Heim & Garten > Beleuchtung > Lichtschienensysteme',
  },
  {
    value: '4932',
    label:
      'Heim & Garten > Beleuchtung > Lichtschienensysteme > Leuchten für Lichtschienensysteme',
  },
  {
    value: '6273',
    label:
      'Heim & Garten > Beleuchtung > Lichtschienensysteme > Schienen für Lichtschienensysteme',
  },
  {
    value: '6272',
    label:
      'Heim & Garten > Beleuchtung > Lichtschienensysteme > Zubehör für Lichtschienensysteme',
  },
  {
    value: '2608',
    label: 'Heim & Garten > Beleuchtung > Lichtschläuche & Lichterketten',
  },
  {
    value: '505826',
    label: 'Heim & Garten > Beleuchtung > Nachtlichter & indirekte Beleuchtung',
  },
  {
    value: '1436',
    label: 'Heim & Garten > Beleuchtung > Notbeleuchtung',
  },
  {
    value: '500003',
    label: 'Heim & Garten > Beleuchtung > Schwimmende Lichter',
  },
  {
    value: '7399',
    label: 'Heim & Garten > Beleuchtung > Tiki-Fackeln & Öllampen',
  },
  {
    value: '7400',
    label: 'Heim & Garten > Beleuchtung > Wegbeleuchtung',
  },
  {
    value: '2956',
    label: 'Heim & Garten > Beleuchtungszubehör',
  },
  {
    value: '3522',
    label: 'Heim & Garten > Beleuchtungszubehör > Beleuchtungszeitschaltuhren',
  },
  {
    value: '505312',
    label: 'Heim & Garten > Beleuchtungszubehör > Brennstoff für Öllampen',
  },
  {
    value: '3185',
    label: 'Heim & Garten > Beleuchtungszubehör > Lampenschirme',
  },
  {
    value: '7447',
    label: 'Heim & Garten > Beleuchtungszubehör > Laternenhalterungen',
  },
  {
    value: '7338',
    label: 'Heim & Garten > Beleuchtungszubehör > Laternensockel',
  },
  {
    value: '4171',
    label: 'Heim & Garten > Bett- und Haushaltswäsche',
  },
  {
    value: '569',
    label: 'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche',
  },
  {
    value: '505803',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche > Baldachine & Betthimmel',
  },
  {
    value: '2541',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche > Bettbezüge',
  },
  {
    value: '2314',
    label: 'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche > Bettlaken',
  },
  {
    value: '2974',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche > Bettvolants',
  },
  {
    value: '1985',
    label: 'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche > Decken',
  },
  {
    value: '2927',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche > Kissenbezüge',
  },
  {
    value: '2700',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche > Kopfkissen',
  },
  {
    value: '4452',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche > Matratzenschoner',
  },
  {
    value: '2991',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche > Matratzenschoner > Matratzenauflagen',
  },
  {
    value: '4420',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche > Matratzenschoner > Matratzenbezüge',
  },
  {
    value: '1599',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche > Schlafunterlagen',
  },
  {
    value: '505287',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Bettwäsche > Steppdecken & Quilts',
  },
  {
    value: '4077',
    label: 'Heim & Garten > Bett- und Haushaltswäsche > Handtücher',
  },
  {
    value: '576',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Handtücher > Badhandtücher & Waschlappen',
  },
  {
    value: '4257',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Handtücher > Geschirrtücher',
  },
  {
    value: '4126',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Handtücher > Strandtücher',
  },
  {
    value: '505832',
    label: 'Heim & Garten > Bett- und Haushaltswäsche > Küchentuchsets',
  },
  {
    value: '601',
    label: 'Heim & Garten > Bett- und Haushaltswäsche > Tischwäsche',
  },
  {
    value: '4203',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Tischwäsche > Stoffservietten',
  },
  {
    value: '6325',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Tischwäsche > Tischläufer',
  },
  {
    value: '2547',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Tischwäsche > Tischsets',
  },
  {
    value: '4143',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Tischwäsche > Tischtücher',
  },
  {
    value: '6322',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Tischwäsche > Tischvorhänge',
  },
  {
    value: '4343',
    label:
      'Heim & Garten > Bett- und Haushaltswäsche > Tischwäsche > Zierdecken',
  },
  {
    value: '696',
    label: 'Heim & Garten > Dekoration',
  },
  {
    value: '597',
    label: 'Heim & Garten > Dekoration > Bilderrahmen',
  },
  {
    value: '6333',
    label: 'Heim & Garten > Dekoration > Briefkasten-Zubehör',
  },
  {
    value: '6334',
    label:
      'Heim & Garten > Dekoration > Briefkasten-Zubehör > Briefkasten-Standanlagen',
  },
  {
    value: '7052',
    label:
      'Heim & Garten > Dekoration > Briefkasten-Zubehör > Briefkastenanlagen',
  },
  {
    value: '7176',
    label:
      'Heim & Garten > Dekoration > Briefkasten-Zubehör > Briefkastenfahnen',
  },
  {
    value: '7177',
    label:
      'Heim & Garten > Dekoration > Briefkasten-Zubehör > Briefkastengehäuse',
  },
  {
    value: '7339',
    label:
      'Heim & Garten > Dekoration > Briefkasten-Zubehör > Briefkastentüren',
  },
  {
    value: '706',
    label: 'Heim & Garten > Dekoration > Briefkästen',
  },
  {
    value: '7436',
    label: 'Heim & Garten > Dekoration > Briefschlitze',
  },
  {
    value: '702',
    label: 'Heim & Garten > Dekoration > Brunnen & Teiche',
  },
  {
    value: '6763',
    label:
      'Heim & Garten > Dekoration > Brunnen & Teiche > Brunnen & Wasserfälle',
  },
  {
    value: '2921',
    label:
      'Heim & Garten > Dekoration > Brunnen & Teiche > Brunnen- & Teichzubehör',
  },
  {
    value: '2763',
    label: 'Heim & Garten > Dekoration > Brunnen & Teiche > Teiche',
  },
  {
    value: '587',
    label: 'Heim & Garten > Dekoration > Buchstützen',
  },
  {
    value: '3221',
    label: 'Heim & Garten > Dekoration > Deko-Aufkleber',
  },
  {
    value: '6317',
    label: 'Heim & Garten > Dekoration > Dekorative Flaschen',
  },
  {
    value: '7113',
    label: 'Heim & Garten > Dekoration > Dekorative Gefäße',
  },
  {
    value: '5875',
    label: 'Heim & Garten > Dekoration > Dekorteller',
  },
  {
    value: '6935',
    label: 'Heim & Garten > Dekoration > Ecosphere-Kugeln',
  },
  {
    value: '603',
    label: 'Heim & Garten > Dekoration > Fensterdrapierung',
  },
  {
    value: '6492',
    label: 'Heim & Garten > Dekoration > Fensterdrapierung > Buntglasscheiben',
  },
  {
    value: '5989',
    label: 'Heim & Garten > Dekoration > Fensterdrapierung > Fensterfolie',
  },
  {
    value: '4375',
    label: 'Heim & Garten > Dekoration > Fensterdrapierung > Fliegengitter',
  },
  {
    value: '2885',
    label:
      'Heim & Garten > Dekoration > Fensterdrapierung > Jalousien & Rollos',
  },
  {
    value: '2621',
    label: 'Heim & Garten > Dekoration > Fensterdrapierung > Querbehänge',
  },
  {
    value: '2882',
    label:
      'Heim & Garten > Dekoration > Fensterdrapierung > Vorhänge & Gardinen',
  },
  {
    value: '6530',
    label: 'Heim & Garten > Dekoration > Fensterschmuckmagnete',
  },
  {
    value: '596',
    label: 'Heim & Garten > Dekoration > Festtags-Dekoartikel',
  },
  {
    value: '5359',
    label:
      'Heim & Garten > Dekoration > Festtags-Dekoartikel > Adventskalender',
  },
  {
    value: '5990',
    label:
      'Heim & Garten > Dekoration > Festtags-Dekoartikel > Haken für Weihnachtssocken',
  },
  {
    value: '6531',
    label: 'Heim & Garten > Dekoration > Festtags-Dekoartikel > Krippen',
  },
  {
    value: '505809',
    label:
      'Heim & Garten > Dekoration > Festtags-Dekoartikel > Modellbaudörfer & -zubehör mit saisonalem Bezug',
  },
  {
    value: '499805',
    label:
      'Heim & Garten > Dekoration > Festtags-Dekoartikel > Ostereier-Färbe-Sets',
  },
  {
    value: '5930',
    label:
      'Heim & Garten > Dekoration > Festtags-Dekoartikel > Traditionelle Japanische Puppen',
  },
  {
    value: '5504',
    label:
      'Heim & Garten > Dekoration > Festtags-Dekoartikel > Weihnachtsbaumdecken',
  },
  {
    value: '3144',
    label:
      'Heim & Garten > Dekoration > Festtags-Dekoartikel > Weihnachtsbaumschmuck',
  },
  {
    value: '6603',
    label:
      'Heim & Garten > Dekoration > Festtags-Dekoartikel > Weihnachtsbaumständer',
  },
  {
    value: '499804',
    label:
      'Heim & Garten > Dekoration > Festtags-Dekoartikel > Weihnachtsschmuck-Aufhänger',
  },
  {
    value: '6532',
    label:
      'Heim & Garten > Dekoration > Festtags-Dekoartikel > Weihnachtsschmuck-Displays',
  },
  {
    value: '5991',
    label:
      'Heim & Garten > Dekoration > Festtags-Dekoartikel > Weihnachtsstrümpfe',
  },
  {
    value: '5609',
    label: 'Heim & Garten > Dekoration > Figuren zur Dekoration',
  },
  {
    value: '701',
    label: 'Heim & Garten > Dekoration > Flaggen & Windsäcke',
  },
  {
    value: '4770',
    label: 'Heim & Garten > Dekoration > Flammenlose Kerzen',
  },
  {
    value: '6993',
    label: 'Heim & Garten > Dekoration > Futterspender',
  },
  {
    value: '6994',
    label:
      'Heim & Garten > Dekoration > Futterspender > Eichhörnchenfutterspender',
  },
  {
    value: '6995',
    label:
      'Heim & Garten > Dekoration > Futterspender > Schmetterlingsfutterspender',
  },
  {
    value: '698',
    label: 'Heim & Garten > Dekoration > Futterspender > Vogelfutterspender',
  },
  {
    value: '2675',
    label: 'Heim & Garten > Dekoration > Fußmatten',
  },
  {
    value: '5708',
    label: 'Heim & Garten > Dekoration > Garderoben',
  },
  {
    value: '704',
    label: 'Heim & Garten > Dekoration > Garten- & Trittsteine',
  },
  {
    value: '6547',
    label: 'Heim & Garten > Dekoration > Gartendeko',
  },
  {
    value: '7173',
    label: 'Heim & Garten > Dekoration > Glasfensterbilder',
  },
  {
    value: '3262',
    label: 'Heim & Garten > Dekoration > Globen',
  },
  {
    value: '7382',
    label: 'Heim & Garten > Dekoration > Hausnummern & Buchstaben',
  },
  {
    value: '572',
    label: 'Heim & Garten > Dekoration > Hausnummernschilder',
  },
  {
    value: '7380',
    label: 'Heim & Garten > Dekoration > Kartonschablonen',
  },
  {
    value: '499693',
    label: 'Heim & Garten > Dekoration > Kindermesslatten',
  },
  {
    value: '7422',
    label: 'Heim & Garten > Dekoration > Kreuzblumen',
  },
  {
    value: '6267',
    label: 'Heim & Garten > Dekoration > Kränze & Girlanden',
  },
  {
    value: '9',
    label: 'Heim & Garten > Dekoration > Kunst',
  },
  {
    value: '11',
    label: 'Heim & Garten > Dekoration > Kunst > Figuren, Skulpturen & Statuen',
  },
  {
    value: '500044',
    label: 'Heim & Garten > Dekoration > Kunst > Poster & Bildende Kunst',
  },
  {
    value: '500045',
    label: 'Heim & Garten > Dekoration > Kunst > Wandteppiche',
  },
  {
    value: '573',
    label: 'Heim & Garten > Dekoration > Körbe',
  },
  {
    value: '5876',
    label: 'Heim & Garten > Dekoration > Kühlschrankmagnete',
  },
  {
    value: '6265',
    label: 'Heim & Garten > Dekoration > Künstliche Pflanzen',
  },
  {
    value: '6266',
    label: 'Heim & Garten > Dekoration > Lebensmittel-Attrappen',
  },
  {
    value: '710',
    label: 'Heim & Garten > Dekoration > Niederschlags-Messgeräte',
  },
  {
    value: '6927',
    label: 'Heim & Garten > Dekoration > Quadratische Sitzkissen',
  },
  {
    value: '500121',
    label: 'Heim & Garten > Dekoration > Raumduft-Zubehör',
  },
  {
    value: '500122',
    label: 'Heim & Garten > Dekoration > Raumduft-Zubehör > Kerzenlöscher',
  },
  {
    value: '2784',
    label: 'Heim & Garten > Dekoration > Raumduft-Zubehör > Kerzenständer',
  },
  {
    value: '4741',
    label:
      'Heim & Garten > Dekoration > Raumduft-Zubehör > Räuchermittelhalter',
  },
  {
    value: '6336',
    label: 'Heim & Garten > Dekoration > Raumduft-Zubehör > Wachs- & Ölwärmer',
  },
  {
    value: '592',
    label: 'Heim & Garten > Dekoration > Raumdüfte',
  },
  {
    value: '6767',
    label: 'Heim & Garten > Dekoration > Raumdüfte > Duftwachs',
  },
  {
    value: '5847',
    label: 'Heim & Garten > Dekoration > Raumdüfte > Duftöle',
  },
  {
    value: '588',
    label: 'Heim & Garten > Dekoration > Raumdüfte > Kerzen',
  },
  {
    value: '3898',
    label: 'Heim & Garten > Dekoration > Raumdüfte > Lufterfrischer',
  },
  {
    value: '4740',
    label: 'Heim & Garten > Dekoration > Raumdüfte > Potpourri',
  },
  {
    value: '3686',
    label: 'Heim & Garten > Dekoration > Raumdüfte > Räuchermittel',
  },
  {
    value: '709',
    label: 'Heim & Garten > Dekoration > Regenketten',
  },
  {
    value: '4456',
    label: 'Heim & Garten > Dekoration > Rückenstützkissen',
  },
  {
    value: '503000',
    label: 'Heim & Garten > Dekoration > Sanduhren',
  },
  {
    value: '6457',
    label: 'Heim & Garten > Dekoration > Schalen',
  },
  {
    value: '5922',
    label: 'Heim & Garten > Dekoration > Schaukästen',
  },
  {
    value: '6535',
    label: 'Heim & Garten > Dekoration > Schneekugeln',
  },
  {
    value: '599',
    label: 'Heim & Garten > Dekoration > Schonbezüge',
  },
  {
    value: '6324',
    label: 'Heim & Garten > Dekoration > Serviettenringe',
  },
  {
    value: '4453',
    label: 'Heim & Garten > Dekoration > Sitzpolster',
  },
  {
    value: '711',
    label: 'Heim & Garten > Dekoration > Sonnenuhren',
  },
  {
    value: '4295',
    label: 'Heim & Garten > Dekoration > Sparschweine & -Dosen',
  },
  {
    value: '5885',
    label: 'Heim & Garten > Dekoration > Spaßschilder',
  },
  {
    value: '595',
    label: 'Heim & Garten > Dekoration > Spiegel',
  },
  {
    value: '3473',
    label: 'Heim & Garten > Dekoration > Spieluhren',
  },
  {
    value: '6456',
    label: 'Heim & Garten > Dekoration > Tabletts',
  },
  {
    value: '2334',
    label: 'Heim & Garten > Dekoration > Tapeten',
  },
  {
    value: '598',
    label: 'Heim & Garten > Dekoration > Teppiche',
  },
  {
    value: '7172',
    label: 'Heim & Garten > Dekoration > Traumfänger',
  },
  {
    value: '6936',
    label: 'Heim & Garten > Dekoration > Trockenblumen',
  },
  {
    value: '4233',
    label: 'Heim & Garten > Dekoration > Truhen',
  },
  {
    value: '505827',
    label: 'Heim & Garten > Dekoration > Uhrbauteile',
  },
  {
    value: '3890',
    label: 'Heim & Garten > Dekoration > Uhren',
  },
  {
    value: '3696',
    label: 'Heim & Garten > Dekoration > Uhren > Standuhren',
  },
  {
    value: '6912',
    label: 'Heim & Garten > Dekoration > Uhren > Tisch- und Regaluhren',
  },
  {
    value: '3840',
    label: 'Heim & Garten > Dekoration > Uhren > Wanduhren',
  },
  {
    value: '4546',
    label: 'Heim & Garten > Dekoration > Uhren > Wecker',
  },
  {
    value: '602',
    label: 'Heim & Garten > Dekoration > Vasen',
  },
  {
    value: '6424',
    label: 'Heim & Garten > Dekoration > Vasenfüller & Streudeko',
  },
  {
    value: '500078',
    label: 'Heim & Garten > Dekoration > Vogel- und Tierhäuschen',
  },
  {
    value: '500079',
    label:
      'Heim & Garten > Dekoration > Vogel- und Tierhäuschen > Fledermaushäuschen',
  },
  {
    value: '500080',
    label:
      'Heim & Garten > Dekoration > Vogel- und Tierhäuschen > Schmetterlingshäuschen',
  },
  {
    value: '699',
    label:
      'Heim & Garten > Dekoration > Vogel- und Tierhäuschen > Vogelhäuschen',
  },
  {
    value: '230911',
    label: 'Heim & Garten > Dekoration > Vogelhaus-Zubehör',
  },
  {
    value: '697',
    label: 'Heim & Garten > Dekoration > Vogeltränken',
  },
  {
    value: '712',
    label: 'Heim & Garten > Dekoration > Wetterfahnen & Dachdekoration',
  },
  {
    value: '2839',
    label: 'Heim & Garten > Dekoration > Windräder',
  },
  {
    value: '714',
    label: 'Heim & Garten > Dekoration > Windspiele',
  },
  {
    value: '7206',
    label: 'Heim & Garten > Dekoration > Zierglocken',
  },
  {
    value: '4454',
    label: 'Heim & Garten > Dekoration > Zierkissen',
  },
  {
    value: '708',
    label: 'Heim & Garten > Dekoration > Ziertafeln',
  },
  {
    value: '7419',
    label: 'Heim & Garten > Dekoration > Zubehör für Fahnen & Windsäcke',
  },
  {
    value: '503010',
    label:
      'Heim & Garten > Dekoration > Zubehör für Fahnen & Windsäcke > Befestigungsvorrichtungen & -sets für Fahnen- & Windsackstangen',
  },
  {
    value: '7420',
    label:
      'Heim & Garten > Dekoration > Zubehör für Fahnen & Windsäcke > Beleuchtung für Fahnen- & Windsackstangen',
  },
  {
    value: '7421',
    label:
      'Heim & Garten > Dekoration > Zubehör für Fahnen & Windsäcke > Stangen für Fahnen & Windsäcke',
  },
  {
    value: '5521',
    label: 'Heim & Garten > Dekoration > Zubehör für Futterspender',
  },
  {
    value: '6254',
    label: 'Heim & Garten > Dekoration > Zubehör zur Fensterdekoration',
  },
  {
    value: '8042',
    label:
      'Heim & Garten > Dekoration > Zubehör zur Fensterdekoration > Ersatzteile für Vorhänge & Jalousien',
  },
  {
    value: '6256',
    label:
      'Heim & Garten > Dekoration > Zubehör zur Fensterdekoration > Gardinen- & Vorhangringe',
  },
  {
    value: '6257',
    label:
      'Heim & Garten > Dekoration > Zubehör zur Fensterdekoration > Gardinen- & Vorhangstangen',
  },
  {
    value: '6255',
    label:
      'Heim & Garten > Dekoration > Zubehör zur Fensterdekoration > Gardinenhalter & -quasten',
  },
  {
    value: '1679',
    label: 'Heim & Garten > Feuer- & Gasschutz',
  },
  {
    value: '1434',
    label: 'Heim & Garten > Feuer- & Gasschutz > Feuerlöscher',
  },
  {
    value: '1871',
    label: 'Heim & Garten > Feuer- & Gasschutz > Feuermelder',
  },
  {
    value: '7226',
    label: 'Heim & Garten > Feuer- & Gasschutz > Feuermelder-Steuerungen',
  },
  {
    value: '7227',
    label: 'Heim & Garten > Feuer- & Gasschutz > Hitzemelder',
  },
  {
    value: '1639',
    label:
      'Heim & Garten > Feuer- & Gasschutz > Kästen & Halterungen für Feuerlöscher',
  },
  {
    value: '499673',
    label: 'Heim & Garten > Feuer- & Gasschutz > Rauch- & Kohlenmonoxidmelder',
  },
  {
    value: '1934',
    label: 'Heim & Garten > Feuer- & Gasschutz > Sprinkleranlagen',
  },
  {
    value: '1306',
    label: 'Heim & Garten > Feuer- & Gasschutz > Wassermelder',
  },
  {
    value: '630',
    label: 'Heim & Garten > Haushaltsbedarf',
  },
  {
    value: '4516',
    label: 'Heim & Garten > Haushaltsbedarf > Abfallbehälter',
  },
  {
    value: '500039',
    label: 'Heim & Garten > Haushaltsbedarf > Abfallbehälter > Müllcontainer',
  },
  {
    value: '637',
    label:
      'Heim & Garten > Haushaltsbedarf > Abfallbehälter > Mülltonnen & Abfalleimer',
  },
  {
    value: '4517',
    label:
      'Heim & Garten > Haushaltsbedarf > Abfallbehälter > Recycling-Behälter',
  },
  {
    value: '5143',
    label:
      'Heim & Garten > Haushaltsbedarf > Abfallbehälter > Sondermüllbehälter',
  },
  {
    value: '6757',
    label: 'Heim & Garten > Haushaltsbedarf > Abfallbehälterzubehör',
  },
  {
    value: '6726',
    label:
      'Heim & Garten > Haushaltsbedarf > Abfallbehälterzubehör > Abfallbehälter-Verkleidungen',
  },
  {
    value: '4717',
    label:
      'Heim & Garten > Haushaltsbedarf > Abfallbehälterzubehör > Abfallbehälterdeckel',
  },
  {
    value: '6758',
    label:
      'Heim & Garten > Haushaltsbedarf > Abfallbehälterzubehör > Abfallbehälterrollen',
  },
  {
    value: '6765',
    label:
      'Heim & Garten > Haushaltsbedarf > Abfallbehälterzubehör > Abfallbehälterwagen',
  },
  {
    value: '500115',
    label:
      'Heim & Garten > Haushaltsbedarf > Abfallbehälterzubehör > Beschriftungen & Etiketten für Abfallbehälter',
  },
  {
    value: '636',
    label: 'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme',
  },
  {
    value: '5631',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Aufbewahrungsbehälter',
  },
  {
    value: '3561',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Aufbewahrungsbeutel',
  },
  {
    value: '2446',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Aufbewahrungshaken & Regale',
  },
  {
    value: '499930',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Aufbewahrungshaken & Regale > Bügelbrettaufhänger und -ablagen',
  },
  {
    value: '5707',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Aufbewahrungshaken & Regale > Mehrzweckhaken',
  },
  {
    value: '5494',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Aufbewahrungshaken & Regale > Schirmständer',
  },
  {
    value: '4360',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Fotoaufbewahrung',
  },
  {
    value: '40',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Fotoaufbewahrung > Fotoalben',
  },
  {
    value: '4237',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Fotoaufbewahrung > Fotoboxen',
  },
  {
    value: '6986',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Haushalts-Ordnungshelfer',
  },
  {
    value: '5558',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Kleider- & Wandschrankaufbewahrung',
  },
  {
    value: '5716',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Kleider- & Wandschrankaufbewahrung > Herrendiener',
  },
  {
    value: '7514',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Kleider- & Wandschrankaufbewahrung > Hutschachteln',
  },
  {
    value: '631',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Kleider- & Wandschrankaufbewahrung > Kleiderbügel',
  },
  {
    value: '5714',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Kleider- & Wandschrankaufbewahrung > Kleiderständer',
  },
  {
    value: '3722',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Kleider- & Wandschrankaufbewahrung > Ladestationen',
  },
  {
    value: '5559',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Kleider- & Wandschrankaufbewahrung > Schuhregale & -schränke',
  },
  {
    value: '8058',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Schubladeneinsätze',
  },
  {
    value: '7255',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Schubladensysteme',
  },
  {
    value: '5128',
    label:
      'Heim & Garten > Haushaltsbedarf > Aufbewahrung & Ordnungssysteme > Tischbesteckkommoden',
  },
  {
    value: '7351',
    label:
      'Heim & Garten > Haushaltsbedarf > Auskleidung für Schubladen & Regale',
  },
  {
    value: '499674',
    label: 'Heim & Garten > Haushaltsbedarf > Fußbodenschutzfolien & -läufer',
  },
  {
    value: '8522',
    label: 'Heim & Garten > Haushaltsbedarf > Garagenmatten',
  },
  {
    value: '3355',
    label: 'Heim & Garten > Haushaltsbedarf > Haushaltsthermometer',
  },
  {
    value: '2530',
    label: 'Heim & Garten > Haushaltsbedarf > Hygienepapiere',
  },
  {
    value: '2742',
    label:
      'Heim & Garten > Haushaltsbedarf > Hygienepapiere > Küchenrollen & Papierhandtücher',
  },
  {
    value: '3846',
    label:
      'Heim & Garten > Haushaltsbedarf > Hygienepapiere > Papierservietten',
  },
  {
    value: '624',
    label:
      'Heim & Garten > Haushaltsbedarf > Hygienepapiere > Papiertaschentücher',
  },
  {
    value: '629',
    label: 'Heim & Garten > Haushaltsbedarf > Hygienepapiere > Toilettenpapier',
  },
  {
    value: '7214',
    label: 'Heim & Garten > Haushaltsbedarf > Möbelgleiter',
  },
  {
    value: '2374',
    label: 'Heim & Garten > Haushaltsbedarf > Müllbeutel',
  },
  {
    value: '5056',
    label: 'Heim & Garten > Haushaltsbedarf > Müllpressezubehör',
  },
  {
    value: '623',
    label: 'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien',
  },
  {
    value: '2610',
    label: 'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Abzieher',
  },
  {
    value: '2857',
    label: 'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Besen',
  },
  {
    value: '4671',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Besen- & Bodenwischerstiele',
  },
  {
    value: '499892',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Besenköpfe',
  },
  {
    value: '499767',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Bodenwischeraufsätze',
  },
  {
    value: '6437',
    label: 'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Eimer',
  },
  {
    value: '6264',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Ersatz-Wischmops',
  },
  {
    value: '6419',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Gewebe- & Polsterschutz',
  },
  {
    value: '4973',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger',
  },
  {
    value: '7330',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Allzweckreiniger',
  },
  {
    value: '4978',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Backofen- & Grillreiniger',
  },
  {
    value: '4977',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Bodenreiniger',
  },
  {
    value: '7426',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Edelstahlreiniger & -polituren',
  },
  {
    value: '500065',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Entkalkungsmittel',
  },
  {
    value: '4975',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Geschirrspülmittel & -seifen',
  },
  {
    value: '4976',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Glas- & Oberflächenreiniger',
  },
  {
    value: '6474',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Haushaltsdesinfektion',
  },
  {
    value: '7552',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Klarspüler',
  },
  {
    value: '5825',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Möbelreiniger & -Politur',
  },
  {
    value: '7510',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Spülmaschinenreiniger',
  },
  {
    value: '4974',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Teppichreiniger',
  },
  {
    value: '8043',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Textil- & Polsterreiniger',
  },
  {
    value: '4979',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Tiergeruchs- & Fleckenentferner',
  },
  {
    value: '4980',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Toilettenreiniger',
  },
  {
    value: '4981',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Wannen- & Kachelreiniger',
  },
  {
    value: '7462',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Haushaltsreiniger > Waschmaschinenreiniger',
  },
  {
    value: '4515',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Kehrschaufeln',
  },
  {
    value: '5113',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Reinigungshandschuhe',
  },
  {
    value: '8071',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Reinigungstücher',
  },
  {
    value: '4670',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Scheuerbürsten',
  },
  {
    value: '2796',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Schwämme & Topfreiniger',
  },
  {
    value: '2250',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Staubwedel & -Tücher',
  },
  {
    value: '6263',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Staubwedel-Ersatzköpfe',
  },
  {
    value: '4677',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Teppichkehrer',
  },
  {
    value: '2713',
    label:
      'Heim & Garten > Haushaltsbedarf > Reinigungsutensilien > Wischmops & Bodenwischer',
  },
  {
    value: '628',
    label: 'Heim & Garten > Haushaltsbedarf > Schuhpflege',
  },
  {
    value: '8031',
    label: 'Heim & Garten > Haushaltsbedarf > Schuhpflege > Schuhabstreifer',
  },
  {
    value: '5601',
    label: 'Heim & Garten > Haushaltsbedarf > Schuhpflege > Schuhanzieher',
  },
  {
    value: '2301',
    label: 'Heim & Garten > Haushaltsbedarf > Schuhpflege > Schuhbeutel',
  },
  {
    value: '1874',
    label: 'Heim & Garten > Haushaltsbedarf > Schuhpflege > Schuhbürsten',
  },
  {
    value: '5604',
    label:
      'Heim & Garten > Haushaltsbedarf > Schuhpflege > Schuhpflege- & Färbemittel',
  },
  {
    value: '8033',
    label: 'Heim & Garten > Haushaltsbedarf > Schuhpflege > Schuhpflegesets',
  },
  {
    value: '8032',
    label: 'Heim & Garten > Haushaltsbedarf > Schuhpflege > Schuhpolierer',
  },
  {
    value: '1659',
    label:
      'Heim & Garten > Haushaltsbedarf > Schuhpflege > Schuhpolituren & -wachse',
  },
  {
    value: '2431',
    label: 'Heim & Garten > Haushaltsbedarf > Schuhpflege > Schuhspanner',
  },
  {
    value: '2371',
    label: 'Heim & Garten > Haushaltsbedarf > Schuhpflege > Schuhtrockner',
  },
  {
    value: '5600',
    label: 'Heim & Garten > Haushaltsbedarf > Schuhpflege > Stiefelknechte',
  },
  {
    value: '728',
    label: 'Heim & Garten > Haushaltsbedarf > Schädlingsbekämpfung',
  },
  {
    value: '2631',
    label: 'Heim & Garten > Haushaltsbedarf > Schädlingsbekämpfung > Fallen',
  },
  {
    value: '4220',
    label:
      'Heim & Garten > Haushaltsbedarf > Schädlingsbekämpfung > Fliegenklatschen',
  },
  {
    value: '2865',
    label:
      'Heim & Garten > Haushaltsbedarf > Schädlingsbekämpfung > Insektenschutz',
  },
  {
    value: '512',
    label:
      'Heim & Garten > Haushaltsbedarf > Schädlingsbekämpfung > Insektenschutz > Mittel gegen Haushaltsinsekten',
  },
  {
    value: '7137',
    label:
      'Heim & Garten > Haushaltsbedarf > Schädlingsbekämpfung > Insektenschutz > Tierabwehr',
  },
  {
    value: '2869',
    label:
      'Heim & Garten > Haushaltsbedarf > Schädlingsbekämpfung > Schädlingsbekämpfungsmittel',
  },
  {
    value: '499885',
    label: 'Heim & Garten > Haushaltsbedarf > Stufenteppiche',
  },
  {
    value: '3307',
    label: 'Heim & Garten > Haushaltsbedarf > Teppichunterlagen',
  },
  {
    value: '7406',
    label: 'Heim & Garten > Haushaltsbedarf > Trocknungsmittel',
  },
  {
    value: '627',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege',
  },
  {
    value: '4982',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege > Bleichmittel',
  },
  {
    value: '633',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege > Bügelbretter',
  },
  {
    value: '4656',
    label:
      'Heim & Garten > Haushaltsbedarf > Wäschepflege > Bügelbrettunterlagen & -bezüge',
  },
  {
    value: '499937',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege > Bügeleisenhalter',
  },
  {
    value: '499931',
    label:
      'Heim & Garten > Haushaltsbedarf > Wäschepflege > Ersatzteile für Bügeleisen',
  },
  {
    value: '4657',
    label:
      'Heim & Garten > Haushaltsbedarf > Wäschepflege > Fleckenentferner für Stoffe',
  },
  {
    value: '5705',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege > Fusselrasierer',
  },
  {
    value: '3080',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege > Fusselrollen',
  },
  {
    value: '7502',
    label:
      'Heim & Garten > Haushaltsbedarf > Wäschepflege > Mittel gegen Falten & statische Aufladung',
  },
  {
    value: '7457',
    label:
      'Heim & Garten > Haushaltsbedarf > Wäschepflege > Schweißabsorbierungspads',
  },
  {
    value: '6240',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege > Textilerfrischer',
  },
  {
    value: '7320',
    label:
      'Heim & Garten > Haushaltsbedarf > Wäschepflege > Trockenreinigungssets',
  },
  {
    value: '5084',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege > Waschbälle',
  },
  {
    value: '2754',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege > Waschpulver',
  },
  {
    value: '2794',
    label:
      'Heim & Garten > Haushaltsbedarf > Wäschepflege > Weichspüler & Trocknertücher',
  },
  {
    value: '5704',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege > Wäscheklammern',
  },
  {
    value: '634',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege > Wäschekörbe',
  },
  {
    value: '2677',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege > Wäscheständer',
  },
  {
    value: '6387',
    label: 'Heim & Garten > Haushaltsbedarf > Wäschepflege > Wäschestärke',
  },
  {
    value: '5085',
    label:
      'Heim & Garten > Haushaltsbedarf > Wäschepflege > Wäschesäcke & Waschkörbe',
  },
  {
    value: '604',
    label: 'Heim & Garten > Haushaltsgeräte',
  },
  {
    value: '235920',
    label: 'Heim & Garten > Haushaltsgeräte > Boden- & Teppichtrockner',
  },
  {
    value: '5294',
    label: 'Heim & Garten > Haushaltsgeräte > Bohner- & Poliermaschinen',
  },
  {
    value: '7121',
    label: 'Heim & Garten > Haushaltsgeräte > Dampf-Tapetenlöser',
  },
  {
    value: '4483',
    label: 'Heim & Garten > Haushaltsgeräte > Futontrockner',
  },
  {
    value: '609',
    label: 'Heim & Garten > Haushaltsgeräte > Garagentorantrieb',
  },
  {
    value: '6741',
    label: 'Heim & Garten > Haushaltsgeräte > Garagentoröffner',
  },
  {
    value: '621',
    label: 'Heim & Garten > Haushaltsgeräte > Heißwasserboiler',
  },
  {
    value: '1626',
    label: 'Heim & Garten > Haushaltsgeräte > Klimatisierung',
  },
  {
    value: '6709',
    label:
      'Heim & Garten > Haushaltsgeräte > Klimatisierung > Fog-Anlagen für den Außenbereich',
  },
  {
    value: '7328',
    label: 'Heim & Garten > Haushaltsgeräte > Klimatisierung > Kanalheizer',
  },
  {
    value: '605',
    label: 'Heim & Garten > Haushaltsgeräte > Klimatisierung > Klimaanlagen',
  },
  {
    value: '613',
    label: 'Heim & Garten > Haushaltsgeräte > Klimatisierung > Luftbefeuchter',
  },
  {
    value: '607',
    label: 'Heim & Garten > Haushaltsgeräte > Klimatisierung > Luftentfeuchter',
  },
  {
    value: '606',
    label: 'Heim & Garten > Haushaltsgeräte > Klimatisierung > Luftreiniger',
  },
  {
    value: '2060',
    label: 'Heim & Garten > Haushaltsgeräte > Klimatisierung > Radiatoren',
  },
  {
    value: '611',
    label: 'Heim & Garten > Haushaltsgeräte > Klimatisierung > Raumheizgeräte',
  },
  {
    value: '2649',
    label:
      'Heim & Garten > Haushaltsgeräte > Klimatisierung > Terrassenstrahler',
  },
  {
    value: '608',
    label: 'Heim & Garten > Haushaltsgeräte > Klimatisierung > Ventilatoren',
  },
  {
    value: '1700',
    label:
      'Heim & Garten > Haushaltsgeräte > Klimatisierung > Ventilatoren > Deckenventilatoren',
  },
  {
    value: '7527',
    label:
      'Heim & Garten > Haushaltsgeräte > Klimatisierung > Ventilatoren > Handventilatoren',
  },
  {
    value: '4485',
    label:
      'Heim & Garten > Haushaltsgeräte > Klimatisierung > Ventilatoren > Lüftungsventilatoren',
  },
  {
    value: '2535',
    label:
      'Heim & Garten > Haushaltsgeräte > Klimatisierung > Ventilatoren > Tisch- & Standventilatoren',
  },
  {
    value: '8090',
    label:
      'Heim & Garten > Haushaltsgeräte > Klimatisierung > Ventilatoren > Wandventilatoren',
  },
  {
    value: '6727',
    label:
      'Heim & Garten > Haushaltsgeräte > Klimatisierung > Verdunstungskühler',
  },
  {
    value: '3082',
    label: 'Heim & Garten > Haushaltsgeräte > Klimatisierung > Öfen & Kessel',
  },
  {
    value: '619',
    label: 'Heim & Garten > Haushaltsgeräte > Staubsauger',
  },
  {
    value: '616',
    label: 'Heim & Garten > Haushaltsgeräte > Teppich- & Dampfreinigungsgeräte',
  },
  {
    value: '500081',
    label: 'Heim & Garten > Haushaltsgeräte > Ultraschallreiniger',
  },
  {
    value: '2706',
    label: 'Heim & Garten > Haushaltsgeräte > Waschen & Trocknen',
  },
  {
    value: '5139',
    label:
      'Heim & Garten > Haushaltsgeräte > Waschen & Trocknen > Bügeleisen & -systeme',
  },
  {
    value: '5138',
    label:
      'Heim & Garten > Haushaltsgeräte > Waschen & Trocknen > Dampfglätter für Bekleidung',
  },
  {
    value: '5140',
    label:
      'Heim & Garten > Haushaltsgeräte > Waschen & Trocknen > Dampfpressen',
  },
  {
    value: '2549',
    label:
      'Heim & Garten > Haushaltsgeräte > Waschen & Trocknen > Waschmaschinen',
  },
  {
    value: '2849',
    label:
      'Heim & Garten > Haushaltsgeräte > Waschen & Trocknen > Waschtrockner',
  },
  {
    value: '2612',
    label:
      'Heim & Garten > Haushaltsgeräte > Waschen & Trocknen > Wäschetrockner',
  },
  {
    value: '3348',
    label: 'Heim & Garten > Haushaltsgeräte-Zubehör',
  },
  {
    value: '2751',
    label: 'Heim & Garten > Haushaltsgeräte-Zubehör > Boiler-Zubehör',
  },
  {
    value: '2221',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Boiler-Zubehör > Abgasrohre',
  },
  {
    value: '500063',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Boiler-Zubehör > Ausgleichsgefäß für Warmwasserbereiter',
  },
  {
    value: '2175',
    label: 'Heim & Garten > Haushaltsgeräte-Zubehör > Boiler-Zubehör > Boiler',
  },
  {
    value: '1835',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Boiler-Zubehör > Boilerwannen',
  },
  {
    value: '1744',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Boiler-Zubehör > Heizelemente',
  },
  {
    value: '1709',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Boiler-Zubehör > Heizungslüftung',
  },
  {
    value: '2310',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Boiler-Zubehör > Opferanoden',
  },
  {
    value: '4667',
    label: 'Heim & Garten > Haushaltsgeräte-Zubehör > Entfeuchterzubehör',
  },
  {
    value: '6773',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Heizkessel- & Boilerzubehör',
  },
  {
    value: '7110',
    label: 'Heim & Garten > Haushaltsgeräte-Zubehör > Heizkörperzubehör',
  },
  {
    value: '7111',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Heizkörperzubehör > Heizkörperreflexionsfolie',
  },
  {
    value: '4650',
    label: 'Heim & Garten > Haushaltsgeräte-Zubehör > Heizpilz-Zubehör',
  },
  {
    value: '4651',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Heizpilz-Zubehör > Heizpilz-Abdeckungen',
  },
  {
    value: '2367',
    label: 'Heim & Garten > Haushaltsgeräte-Zubehör > Klimaanlagen-Zubehör',
  },
  {
    value: '5826',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Klimaanlagen-Zubehör > Abdeckungen für Klimaanlagen',
  },
  {
    value: '3573',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Klimaanlagen-Zubehör > Klimaanlagenfilter',
  },
  {
    value: '3862',
    label: 'Heim & Garten > Haushaltsgeräte-Zubehör > Luftbefeuchter-Zubehör',
  },
  {
    value: '3402',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Luftbefeuchter-Zubehör > Luftbefeuchterfilter',
  },
  {
    value: '3410',
    label: 'Heim & Garten > Haushaltsgeräte-Zubehör > Luftreinigerzubehör',
  },
  {
    value: '3667',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Luftreinigerzubehör > Luftreinigerfilter',
  },
  {
    value: '618',
    label: 'Heim & Garten > Haushaltsgeräte-Zubehör > Staubsaugerzubehör',
  },
  {
    value: '4548',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Teppich- & Dampfreinigerzubehör',
  },
  {
    value: '5089',
    label: 'Heim & Garten > Haushaltsgeräte-Zubehör > Ventilatorenzubehör',
  },
  {
    value: '3456',
    label: 'Heim & Garten > Haushaltsgeräte-Zubehör > Wasch-Zubehör',
  },
  {
    value: '5159',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Wasch-Zubehör > Bügeleisenzubehör',
  },
  {
    value: '5158',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Wasch-Zubehör > Zubehör für Dampfmaschinen',
  },
  {
    value: '5160',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Wasch-Zubehör > Zubehör für Dampfpressen',
  },
  {
    value: '500085',
    label:
      'Heim & Garten > Haushaltsgeräte-Zubehör > Wasch-Zubehör > Zubehör für Waschmaschinen und Wäschetrockner',
  },
  {
    value: '359',
    label: 'Heim & Garten > Haussicherheit',
  },
  {
    value: '2161',
    label: 'Heim & Garten > Haussicherheit > Bewegungsmelder',
  },
  {
    value: '3873',
    label: 'Heim & Garten > Haussicherheit > Hausalarmsysteme',
  },
  {
    value: '499865',
    label: 'Heim & Garten > Haussicherheit > Safe-Zubehör',
  },
  {
    value: '3819',
    label: 'Heim & Garten > Haussicherheit > Safes & Tresore',
  },
  {
    value: '365',
    label: 'Heim & Garten > Haussicherheit > Sensoren für Sicherheitssysteme',
  },
  {
    value: '363',
    label: 'Heim & Garten > Haussicherheit > Sicherheitsleuchten',
  },
  {
    value: '500025',
    label: 'Heim & Garten > Haussicherheit > Sicherheitsspiegel',
  },
  {
    value: '5491',
    label: 'Heim & Garten > Haussicherheit > Überwachungskamera-Attrappen',
  },
  {
    value: '364',
    label: 'Heim & Garten > Haussicherheit > Überwachungsmonitore & -rekorder',
  },
  {
    value: '2639',
    label: 'Heim & Garten > Holzöfen',
  },
  {
    value: '6792',
    label: 'Heim & Garten > Kamine',
  },
  {
    value: '638',
    label: 'Heim & Garten > Küche & Esszimmer',
  },
  {
    value: '649',
    label: 'Heim & Garten > Küche & Esszimmer > Bar-Utensilien',
  },
  {
    value: '7075',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Absinth-Fontänen',
  },
  {
    value: '1817',
    label: 'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Bierzapfhähne',
  },
  {
    value: '6957',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Cocktail- & Barzubehörsets',
  },
  {
    value: '651',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Cocktailshaker & Zubehör',
  },
  {
    value: '4222',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Cocktailshaker & Zubehör > Bar-Eispickel',
  },
  {
    value: '505327',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Cocktailshaker & Zubehör > Barsiebe',
  },
  {
    value: '6956',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Cocktailshaker & Zubehör > Cocktailshaker',
  },
  {
    value: '503757',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Cocktailshaker & Zubehör > Cocktailstößel',
  },
  {
    value: '3427',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Cocktailshaker & Zubehör > Flaschenöffner',
  },
  {
    value: '650',
    label: 'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Dekanter',
  },
  {
    value: '4562',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Flaschenstöpsel & -verschlüsse',
  },
  {
    value: '499990',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Flaschenverschlüsse',
  },
  {
    value: '7139',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Folienschneider',
  },
  {
    value: '505806',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Getränkewannen & -kühler',
  },
  {
    value: '2363',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Glasuntersetzer & Bierdeckel',
  },
  {
    value: '2976',
    label: 'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Korkenzieher',
  },
  {
    value: '7569',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Kühlsteine & -Sticks für Getränke',
  },
  {
    value: '7238',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Untersetzerhalter',
  },
  {
    value: '4563',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Weindekantierer',
  },
  {
    value: '8493',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Weinflaschenhalter',
  },
  {
    value: '7008',
    label:
      'Heim & Garten > Küche & Esszimmer > Bar-Utensilien > Weinglasanhänger',
  },
  {
    value: '2920',
    label: 'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter',
  },
  {
    value: '669',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Brotdosen & -taschen',
  },
  {
    value: '3435',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Feldflaschen',
  },
  {
    value: '1444',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Flachmänner',
  },
  {
    value: '5060',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Getränkedeckel',
  },
  {
    value: '4520',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Getränkehüllen',
  },
  {
    value: '4521',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Getränkehüllen > Dosen- und Flaschenhüllen',
  },
  {
    value: '4522',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Getränkehüllen > Getränkebehälterhüllen',
  },
  {
    value: '4722',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Isolierbehälter',
  },
  {
    value: '1017',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Kühlboxen',
  },
  {
    value: '2507',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Kühltaschen',
  },
  {
    value: '671',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Picknickkörbe',
  },
  {
    value: '3800',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Thermosflaschen',
  },
  {
    value: '3809',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Wasserflaschen',
  },
  {
    value: '6449',
    label:
      'Heim & Garten > Küche & Esszimmer > Essens- & Getränkebehälter > Weinflaschentaschen',
  },
  {
    value: '2626',
    label: 'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung',
  },
  {
    value: '6478',
    label: 'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung - Zubehör',
  },
  {
    value: '499924',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung - Zubehör > Etiketten für Lebensmittel und Getränke',
  },
  {
    value: '6479',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung - Zubehör > Sauerstoffabsorber',
  },
  {
    value: '8039',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung - Zubehör > Spender für Frischhaltefolie',
  },
  {
    value: '5837',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung - Zubehör > Verschlussstreifen & Verschlussclips',
  },
  {
    value: '3337',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung > Brotkästen & -taschen',
  },
  {
    value: '3591',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung > Frischhaltebeutel',
  },
  {
    value: '5134',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung > Honiggläser',
  },
  {
    value: '2644',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung > Kecksdosen & -behälter',
  },
  {
    value: '6481',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung > Lebensmittel-Abdeckhauben',
  },
  {
    value: '667',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung > Lebensmittelbehälter',
  },
  {
    value: '3110',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung > Lebensmittelverpackungsmaterial',
  },
  {
    value: '1496',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung > Lebensmittelverpackungsmaterial > Alufolien',
  },
  {
    value: '3750',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung > Lebensmittelverpackungsmaterial > Frischhaltefolien',
  },
  {
    value: '5642',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung > Lebensmittelverpackungsmaterial > Pergamentpapier',
  },
  {
    value: '3956',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung > Lebensmittelverpackungsmaterial > Wachspapier',
  },
  {
    value: '6534',
    label:
      'Heim & Garten > Küche & Esszimmer > Essensaufbewahrung > Nascheimer',
  },
  {
    value: '6070',
    label: 'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen',
  },
  {
    value: '640',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backformen',
  },
  {
    value: '647',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backformen > Auflaufförmchen & Souffléförmchen',
  },
  {
    value: '641',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backformen > Back- & Plätzchenbleche',
  },
  {
    value: '4764',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backformen > Backzubehörsets',
  },
  {
    value: '642',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backformen > Brotbackformen',
  },
  {
    value: '648',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backformen > Bräter',
  },
  {
    value: '6756',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backformen > Grillbleche mit Fettpfannen',
  },
  {
    value: '645',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backformen > Kuchen- & Quichebackformen',
  },
  {
    value: '643',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backformen > Kuchenbackformen',
  },
  {
    value: '644',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backformen > Muffin- & Gebäckformen',
  },
  {
    value: '2843',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backformen > Pizzableche',
  },
  {
    value: '646',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backformen > Pizzasteine',
  },
  {
    value: '4502',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backzubehör',
  },
  {
    value: '7131',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backzubehör > Backgewichte',
  },
  {
    value: '4503',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backzubehör > Backmatten & -formen',
  },
  {
    value: '4726',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Backzubehör > Brätereinsätze',
  },
  {
    value: '654',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr',
  },
  {
    value: '6071',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr & Backformen (Kombisets)',
  },
  {
    value: '655',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Auflaufformen & Kasserollen',
  },
  {
    value: '658',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Grillpfannen & -aufsätze',
  },
  {
    value: '5110',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Grillpressen',
  },
  {
    value: '4721',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Kochgeschirrsets',
  },
  {
    value: '4459',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Paellapfannen',
  },
  {
    value: '6838',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Pfannen für Crêpes und Pfannkuchen',
  },
  {
    value: '6518',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Rum- & Einmachtöpfe',
  },
  {
    value: '4423',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Sautépfannen',
  },
  {
    value: '657',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Schmortöpfe',
  },
  {
    value: '660',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Schnellkochtöpfe',
  },
  {
    value: '662',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Stiel- & Bratpfannen',
  },
  {
    value: '661',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Stieltöpfe',
  },
  {
    value: '663',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Suppentöpfe',
  },
  {
    value: '5340',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Tajines & Tonkochtöpfe',
  },
  {
    value: '656',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Wasserbadtöpfe',
  },
  {
    value: '659',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Wasserkessel',
  },
  {
    value: '664',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirr > Woks',
  },
  {
    value: '4424',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirrzubehör',
  },
  {
    value: '4529',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirrzubehör > Dampfkörbe',
  },
  {
    value: '4501',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirrzubehör > Schnellkochtopfzubehör',
  },
  {
    value: '4660',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirrzubehör > Topf- & Pfannendeckel',
  },
  {
    value: '4661',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirrzubehör > Topf- & Pfannengriffe',
  },
  {
    value: '4427',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirrzubehör > Wokzubehör',
  },
  {
    value: '4663',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirrzubehör > Wokzubehör > Wokbürsten',
  },
  {
    value: '4662',
    label:
      'Heim & Garten > Küche & Esszimmer > Kochgeschirr & Backformen > Kochgeschirrzubehör > Wokzubehör > Wokringe',
  },
  {
    value: '8161',
    label: 'Heim & Garten > Küche & Esszimmer > Küchenblöcke & Pantryküchen',
  },
  {
    value: '730',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte',
  },
  {
    value: '610',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Abfallzerkleinerer',
  },
  {
    value: '684',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Abzugshauben',
  },
  {
    value: '3181',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Aufschnittmaschine',
  },
  {
    value: '2985',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Außengrills',
  },
  {
    value: '683',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Backöfen',
  },
  {
    value: '732',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Brotbackautomaten',
  },
  {
    value: '1015',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Campingkocher',
  },
  {
    value: '743',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Dörrautomaten',
  },
  {
    value: '749',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Eis-Crusher & Eis-Shaver',
  },
  {
    value: '4161',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Eismaschinen',
  },
  {
    value: '4421',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Elektrische Pfannen & Woks',
  },
  {
    value: '7165',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Elektrogrills & -bratplatten',
  },
  {
    value: '750',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Entsafter',
  },
  {
    value: '4720',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Fonduetöpfe & -Sets',
  },
  {
    value: '738',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Fritteusen',
  },
  {
    value: '6524',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Gasgrillplatten',
  },
  {
    value: '681',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Gefrierschränke & -truhen',
  },
  {
    value: '680',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Geschirrspüler',
  },
  {
    value: '5287',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Getränkewärmer',
  },
  {
    value: '4495',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Grillöfen & Mini-Backöfen',
  },
  {
    value: '6543',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Heißgetränkebereiter',
  },
  {
    value: '766',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Joghurtmaschinen',
  },
  {
    value: '736',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kaffee- & Espressomaschinen',
  },
  {
    value: '1647',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kaffee- & Espressomaschinen > Espressokannen',
  },
  {
    value: '2422',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kaffee- & Espressomaschinen > Espressomaschinen',
  },
  {
    value: '1388',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kaffee- & Espressomaschinen > Filterkaffeemaschinen',
  },
  {
    value: '2247',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kaffee- & Espressomaschinen > Perkolatoren & Kaffeebrüher',
  },
  {
    value: '1557',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kaffee- & Espressomaschinen > Pressstempelkannen',
  },
  {
    value: '5286',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kaffee- & Espressomaschinen > Vakuumbereiter',
  },
  {
    value: '679',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kochfelder',
  },
  {
    value: '747',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kochplatten',
  },
  {
    value: '4532',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kochtöpfe & Dampfkochtöpfe',
  },
  {
    value: '760',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kochtöpfe & Dampfkochtöpfe > Dampfgarer',
  },
  {
    value: '739',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kochtöpfe & Dampfkochtöpfe > Eierkocher',
  },
  {
    value: '757',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kochtöpfe & Dampfkochtöpfe > Reiskocher',
  },
  {
    value: '737',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kochtöpfe & Dampfkochtöpfe > Schongarer',
  },
  {
    value: '6523',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kochtöpfe & Dampfkochtöpfe > Thermokocher',
  },
  {
    value: '6279',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kochtöpfe & Dampfkochtöpfe > Öfen zum Vakuumgaren',
  },
  {
    value: '685',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Küchenherde',
  },
  {
    value: '686',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Kühlschränke',
  },
  {
    value: '687',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Lebensmittel-Räucheröfen',
  },
  {
    value: '744',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Lebensmittelmühlen',
  },
  {
    value: '752',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Messerschärfer',
  },
  {
    value: '753',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Mikrowellenherde',
  },
  {
    value: '3526',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Milchaufschäumer',
  },
  {
    value: '505666',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Mixer & Pürierstäbe',
  },
  {
    value: '4482',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Mochi-Maschinen',
  },
  {
    value: '688',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Müllpressen',
  },
  {
    value: '755',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Nudelmaschinen',
  },
  {
    value: '756',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Popcornmaschinen',
  },
  {
    value: '5156',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Slush-Eis-Maschinen',
  },
  {
    value: '5057',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Sojamilchbereiter',
  },
  {
    value: '748',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Speiseeismaschinen',
  },
  {
    value: '5103',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Speisewärmer',
  },
  {
    value: '6548',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Speisewärmer > Buffetwärmer',
  },
  {
    value: '504633',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Speisewärmer > Reiswärmer',
  },
  {
    value: '5349',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Speisewärmer > Speisewärmelampen',
  },
  {
    value: '4292',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Speisewärmer > Warmhaltetische',
  },
  {
    value: '4528',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Teekocher',
  },
  {
    value: '5090',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Temperiermaschinen für Schokolade',
  },
  {
    value: '5289',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Toaster & Grills',
  },
  {
    value: '6516',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Toaster & Grills > Brezelbackautomat',
  },
  {
    value: '6819',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Toaster & Grills > Donutmaker',
  },
  {
    value: '761',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Toaster & Grills > Mini- & Toastöfen',
  },
  {
    value: '5318',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Toaster & Grills > Muffin- & Cupcake-Maker',
  },
  {
    value: '6278',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Toaster & Grills > Pizzaöfen',
  },
  {
    value: '5291',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Toaster & Grills > Pizzelle-Eisen',
  },
  {
    value: '5292',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Toaster & Grills > Quesadilla- & Tortilla-Maker',
  },
  {
    value: '759',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Toaster & Grills > Sandwichtoaster',
  },
  {
    value: '762',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Toaster & Grills > Toaster',
  },
  {
    value: '764',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Toaster & Grills > Waffeleisen',
  },
  {
    value: '3293',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Trinkwasserspender',
  },
  {
    value: '763',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Vakuumierer',
  },
  {
    value: '765',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Wasserfilter',
  },
  {
    value: '751',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Wasserkocher',
  },
  {
    value: '5577',
    label: 'Heim & Garten > Küche & Esszimmer > Küchengeräte > Wassersprudler',
  },
  {
    value: '4539',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Weinkühlschränke',
  },
  {
    value: '3319',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchengeräte > Zuckerwattemaschinen',
  },
  {
    value: '668',
    label: 'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien',
  },
  {
    value: '3835',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Abkühlgitter',
  },
  {
    value: '653',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Abtropf- & Küchensiebe',
  },
  {
    value: '6554',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Allespicker & Spieße',
  },
  {
    value: '3850',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Ausstechförmchen',
  },
  {
    value: '3430',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Backpinsel',
  },
  {
    value: '3768',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Backschaufeln',
  },
  {
    value: '3347',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Bratenspritzen',
  },
  {
    value: '4771',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Bratschläuche',
  },
  {
    value: '7329',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Dampfgarbeutel',
  },
  {
    value: '6787',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Dessertformen',
  },
  {
    value: '4247',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Dosenpressen',
  },
  {
    value: '733',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Dosenöffner',
  },
  {
    value: '6497',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Dressing-Shaker',
  },
  {
    value: '4746',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Eiswürfelformen',
  },
  {
    value: '741',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Elektromesser',
  },
  {
    value: '6411',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Elektromesserzubehör',
  },
  {
    value: '6412',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Elektromesserzubehör > Elektromesser-Ersatzklingen',
  },
  {
    value: '6526',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Essig- & Ölspender',
  },
  {
    value: '5928',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Flambierbrenner',
  },
  {
    value: '5078',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Fleischgabeln',
  },
  {
    value: '3248',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Fleischklopfer',
  },
  {
    value: '7485',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Fleischpistolen',
  },
  {
    value: '3268',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Geschirrständer & Abtropfflächen',
  },
  {
    value: '7149',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Getränkespender',
  },
  {
    value: '4762',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Gewürzmühlen',
  },
  {
    value: '5109',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Kartoffelpressen',
  },
  {
    value: '6342',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Keksmaschine',
  },
  {
    value: '3620',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Kellen',
  },
  {
    value: '3385',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Knoblauchpressen',
  },
  {
    value: '3091',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Kochthermometer',
  },
  {
    value: '4630',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Kuchen-Dekorationsbedarf',
  },
  {
    value: '3713',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Kurzzeitmesser & Eieruhren',
  },
  {
    value: '8006',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenformen',
  },
  {
    value: '665',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenmesser',
  },
  {
    value: '2948',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer',
  },
  {
    value: '3831',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Besteckkästen',
  },
  {
    value: '2344',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Gewürzregale & -ständer',
  },
  {
    value: '8012',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Halter für Küchenutensilien',
  },
  {
    value: '6480',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Konservenregal',
  },
  {
    value: '3061',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Küchenrollenhalter & -spender',
  },
  {
    value: '6487',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Küchenschranksysteme',
  },
  {
    value: '5157',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Messerblöcke & -halter',
  },
  {
    value: '3177',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Organizer für Küchenutensilien',
  },
  {
    value: '3072',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Serviettenhalter & -spender',
  },
  {
    value: '3845',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Topfregale',
  },
  {
    value: '3479',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Trinkgefäßhalter',
  },
  {
    value: '5059',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Trinkhalmhalter & -spender',
  },
  {
    value: '4322',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Zahnstocherbehälter & -spender',
  },
  {
    value: '6415',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenorganizer > Zuckertütenhalter',
  },
  {
    value: '3256',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenschaber',
  },
  {
    value: '3633',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenschaber > Grillschaber',
  },
  {
    value: '3419',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenschaber > Teigschaber',
  },
  {
    value: '3086',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenschaber > Teigspatel',
  },
  {
    value: '5251',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenscheren',
  },
  {
    value: '3206',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenschneidegeräte',
  },
  {
    value: '7331',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenthermometer-Zubehör',
  },
  {
    value: '503005',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchentrichter',
  },
  {
    value: '4765',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenutensilien-Sets',
  },
  {
    value: '4005',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Küchenzangen',
  },
  {
    value: '505316',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Lebensmittelschablonen',
  },
  {
    value: '4334',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Löffelablagen',
  },
  {
    value: '5370',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Mehlsiebe',
  },
  {
    value: '3475',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Messbecher & Dosierlöffel',
  },
  {
    value: '3999',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Mörser & Stößel',
  },
  {
    value: '3723',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Nahrungsmittelspender',
  },
  {
    value: '6749',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Pastaförmchen',
  },
  {
    value: '3196',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Pfannenwender',
  },
  {
    value: '3421',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Pizzaschneider',
  },
  {
    value: '7365',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Pizzaschneider-Zubehör',
  },
  {
    value: '3175',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Portionierer',
  },
  {
    value: '3202',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Portionierer > Eiscremeportionierer',
  },
  {
    value: '3708',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Portionierer > Eisportionierer',
  },
  {
    value: '3258',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Portionierer > Kugelausstecher',
  },
  {
    value: '502966',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Portionierer > Popcorn- & Pommesschaufeln',
  },
  {
    value: '3156',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Reiben',
  },
  {
    value: '4530',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Rührschüsseln',
  },
  {
    value: '3914',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Salatschleudern',
  },
  {
    value: '4777',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Saucenspender',
  },
  {
    value: '6388',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Schaumlöffel',
  },
  {
    value: '3597',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Schneebesen',
  },
  {
    value: '666',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Schneidebretter',
  },
  {
    value: '3521',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Schäler & Entkerner',
  },
  {
    value: '639',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Schürzen',
  },
  {
    value: '3381',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Spezialbesteck & Nussknacker',
  },
  {
    value: '3586',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Spezialbesteck & Nussknacker > Hummer- & Krebszangen',
  },
  {
    value: '3685',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Spezialbesteck & Nussknacker > Nussknacker',
  },
  {
    value: '4214',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Spezialbesteck & Nussknacker > Nussknacker > Dekorative Nussknacker',
  },
  {
    value: '6746',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Spülbeckenkörbe',
  },
  {
    value: '5080',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Spülbeckenmatten',
  },
  {
    value: '3294',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Stampfer',
  },
  {
    value: '7247',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Sushi-Matten',
  },
  {
    value: '4559',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Teesiebe',
  },
  {
    value: '4332',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Teigmischer',
  },
  {
    value: '3467',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Teigrollen',
  },
  {
    value: '4705',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Teigrollenzubehör',
  },
  {
    value: '4707',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Teigrollenzubehör > Abstandsringe für Teigrollen',
  },
  {
    value: '4706',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Teigrollenzubehör > Teigrollenhüllen & -überzüge',
  },
  {
    value: '6723',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Teigräder',
  },
  {
    value: '4708',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Teigtücher',
  },
  {
    value: '670',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Topfhandschuhe & Topflappen',
  },
  {
    value: '6408',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Tortenheber',
  },
  {
    value: '6522',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Ziseliermesser',
  },
  {
    value: '4788',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Zubehör für Gewürzmühlen',
  },
  {
    value: '6974',
    label:
      'Heim & Garten > Küche & Esszimmer > Küchenhelfer & -utensilien > Zuckerspender',
  },
  {
    value: '672',
    label: 'Heim & Garten > Küche & Esszimmer > Tafelgeschirr',
  },
  {
    value: '673',
    label: 'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Geschirr',
  },
  {
    value: '5537',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Geschirr > Geschirrsets',
  },
  {
    value: '3498',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Geschirr > Schüsseln',
  },
  {
    value: '3553',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Geschirr > Teller',
  },
  {
    value: '652',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Kaffee- & Teekannen',
  },
  {
    value: '6740',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Kaffee- & Teeservices',
  },
  {
    value: '8046',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Klemmen & Gewichte für Tischdecken',
  },
  {
    value: '676',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Salz- & Pfefferstreuer',
  },
  {
    value: '6425',
    label: 'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierbesteck',
  },
  {
    value: '6434',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierbesteck > Bowlenschüsseluntersatz',
  },
  {
    value: '6427',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierbesteck > Terrinendeckel',
  },
  {
    value: '6426',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierbesteck > Terrinenuntersatz',
  },
  {
    value: '4026',
    label: 'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierware',
  },
  {
    value: '4735',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierware > Bowleschüsseln',
  },
  {
    value: '6086',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierware > Butterdosen',
  },
  {
    value: '7550',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierware > Eierbecher',
  },
  {
    value: '3330',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierware > Karaffen',
  },
  {
    value: '5135',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierware > Kuchenplatten',
  },
  {
    value: '3703',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierware > Saucièren',
  },
  {
    value: '3802',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierware > Servierplatten',
  },
  {
    value: '4009',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierware > Serviertabletts',
  },
  {
    value: '3941',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierware > Terrinen',
  },
  {
    value: '4372',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierware > Tortenständer',
  },
  {
    value: '3373',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Servierware > Zuckerdosen & Milchkännchen',
  },
  {
    value: '675',
    label: 'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Tischbestecke',
  },
  {
    value: '5647',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Tischbestecke > Besteck-Sets',
  },
  {
    value: '3699',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Tischbestecke > Essstäbchen',
  },
  {
    value: '6439',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Tischbestecke > Essstäbchenzubehör',
  },
  {
    value: '4015',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Tischbestecke > Gabeln',
  },
  {
    value: '3939',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Tischbestecke > Löffel',
  },
  {
    value: '3844',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Tischbestecke > Tafelmesser',
  },
  {
    value: '677',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Topfuntersetzer',
  },
  {
    value: '674',
    label: 'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Trinkgefäße',
  },
  {
    value: '7568',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Trinkgefäße > Biergläser',
  },
  {
    value: '2169',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Trinkgefäße > Kaffee- & Teebecher',
  },
  {
    value: '6049',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Trinkgefäße > Kaffee- und Teetassen',
  },
  {
    value: '6051',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Trinkgefäße > Kaffee- und Teeuntertassen',
  },
  {
    value: '2694',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Trinkgefäße > Schnapsgläser',
  },
  {
    value: '2712',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Trinkgefäße > Stielgläser',
  },
  {
    value: '6958',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Trinkgefäße > Trinkbehältersets',
  },
  {
    value: '2951',
    label:
      'Heim & Garten > Küche & Esszimmer > Tafelgeschirr > Trinkgefäße > Trinkgläser',
  },
  {
    value: '2901',
    label: 'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte',
  },
  {
    value: '3684',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Außengrillzubehör',
  },
  {
    value: '7540',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Außengrillzubehör > Anzündkamine',
  },
  {
    value: '3855',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Außengrillzubehör > Außengrillabdeckungen',
  },
  {
    value: '3382',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Außengrillzubehör > Außengrillroste & -auflagen',
  },
  {
    value: '4560',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Außengrillzubehör > Außengrillspieße & -körbe',
  },
  {
    value: '505667',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Außengrillzubehör > Ersatzteile für Außengrills',
  },
  {
    value: '5672',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Außengrillzubehör > Grillbretter',
  },
  {
    value: '5670',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Außengrillzubehör > Grillwagen',
  },
  {
    value: '5694',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Außengrillzubehör > Holzkohle',
  },
  {
    value: '5671',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Außengrillzubehör > Räucherchips & -Pellets',
  },
  {
    value: '8087',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Dunstabzugshauben-Zubehör',
  },
  {
    value: '4653',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Dörrautomatenzubehör',
  },
  {
    value: '4655',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Dörrautomatenzubehör > Dörrautomatenbleche',
  },
  {
    value: '4654',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Dörrautomatenzubehör > Dörrautomatentabletts',
  },
  {
    value: '4519',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Entsafterzubehör',
  },
  {
    value: '6944',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Fonduesetzubehör',
  },
  {
    value: '503725',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Fonduesetzubehör > Chafing Dish- & Fonduebrennpasten',
  },
  {
    value: '6945',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Fonduesetzubehör > Fonduegabeln',
  },
  {
    value: '6946',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Fonduesetzubehör > Fonduetopfständer',
  },
  {
    value: '3954',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Fritteusenzubehör',
  },
  {
    value: '6747',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Küchenabfallzerkleinerer-Zubehör',
  },
  {
    value: '3848',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Kühlschrankzubehör',
  },
  {
    value: '1334',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Mikrowellenzubehör',
  },
  {
    value: '2540',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Nudelmaschinenzubehör',
  },
  {
    value: '4674',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Speiseeismaschinenzubehör',
  },
  {
    value: '4675',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Speiseeismaschinenzubehör > Gefrierschüsseln für Speiseeismaschinen',
  },
  {
    value: '3443',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Spülmaschinenzubehör',
  },
  {
    value: '7444',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Toasterzubehör',
  },
  {
    value: '3523',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Vakuumiererzubehör',
  },
  {
    value: '3124',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Vakuumiererzubehör > Vakuumierer-Beutel',
  },
  {
    value: '7118',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Wasserspenderzubehör',
  },
  {
    value: '7119',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Wasserspenderzubehör > Flaschen für Wasserspender',
  },
  {
    value: '8106',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Weinkühlschrank-Zubehör',
  },
  {
    value: '3489',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Brotbackautomaten',
  },
  {
    value: '7006',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Campingöfen',
  },
  {
    value: '5042',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Eis-Crusher & Eis-Shaver',
  },
  {
    value: '7187',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Eismaschinen',
  },
  {
    value: '7570',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Gefrierschränke',
  },
  {
    value: '500004',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Herdplatten, Backöfen und Küchenherde',
  },
  {
    value: '5570',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Joghurtmaschinen',
  },
  {
    value: '3988',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Kaffee- & Espressomaschinen',
  },
  {
    value: '5066',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Kaffee- & Espressomaschinen > Aufschäumer',
  },
  {
    value: '503736',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Kaffee- & Espressomaschinen > Ersatzteile für Espresso- & Kaffeemaschinen',
  },
  {
    value: '3450',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Kaffee- & Espressomaschinen > Kaffeefilter',
  },
  {
    value: '4500',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Kaffee- & Espressomaschinen > Kaffeefilterkörbe',
  },
  {
    value: '3239',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Kaffee- & Espressomaschinen > Kaffeekannen',
  },
  {
    value: '6888',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Kaffee- & Espressomaschinen > Kaffeekannenwarmhalter',
  },
  {
    value: '734',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Kaffee- & Espressomaschinen > Kaffeemühlen',
  },
  {
    value: '4786',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Kaffee- & Espressomaschinen > Kaffeemühlenzubehör',
  },
  {
    value: '3838',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Kaffee- & Espressomaschinen > Siebträger',
  },
  {
    value: '5065',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Kaffee- & Espressomaschinen > Wasserfilter für Kaffeemaschinen',
  },
  {
    value: '4763',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Lebensmittelmühlen',
  },
  {
    value: '505765',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Mixer & Pürierstäbe',
  },
  {
    value: '5075',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Popcornmaschinen',
  },
  {
    value: '502989',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Trinkwassersprudler',
  },
  {
    value: '499996',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Waffeleisen',
  },
  {
    value: '8051',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Warmhaltetheken',
  },
  {
    value: '8053',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Warmhaltetheken > Behälter für Warmhaltetheken',
  },
  {
    value: '8052',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Warmhaltetheken > Deckel für Warmhaltebehälter',
  },
  {
    value: '500066',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Wasserkocher',
  },
  {
    value: '5076',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für Zuckerwattemaschinen',
  },
  {
    value: '7355',
    label:
      'Heim & Garten > Küche & Esszimmer > Zubehör für Küchengeräte > Zubehör für elektrische Pfannen & Woks',
  },
  {
    value: '5835',
    label: 'Heim & Garten > Notfallvorsorge',
  },
  {
    value: '4490',
    label: 'Heim & Garten > Notfallvorsorge > Erdbebenmelder',
  },
  {
    value: '4491',
    label: 'Heim & Garten > Notfallvorsorge > Möbelanker',
  },
  {
    value: '6897',
    label: 'Heim & Garten > Notfallvorsorge > Notfalldecken',
  },
  {
    value: '7058',
    label: 'Heim & Garten > Notfallvorsorge > Notfallwerkzeuge',
  },
  {
    value: '5836',
    label: 'Heim & Garten > Notfallvorsorge > Notvorräte',
  },
  {
    value: '985',
    label: 'Heim & Garten > Pflanzen',
  },
  {
    value: '984',
    label: 'Heim & Garten > Pflanzen > Blumen',
  },
  {
    value: '1684',
    label: 'Heim & Garten > Pflanzen > Bäume',
  },
  {
    value: '6762',
    label: 'Heim & Garten > Pflanzen > Grünpflanzen, Büsche & Sträucher',
  },
  {
    value: '505285',
    label: 'Heim & Garten > Pflanzen > Pflanzen- & Kräuteranbausets',
  },
  {
    value: '2802',
    label: 'Heim & Garten > Pflanzen > Saatgut',
  },
  {
    value: '5590',
    label: 'Heim & Garten > Pflanzen > Wasserpflanzen',
  },
  {
    value: '729',
    label: 'Heim & Garten > Pool & Spa',
  },
  {
    value: '2832',
    label: 'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör',
  },
  {
    value: '2860',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Badeinseln & Schwimmliegen',
  },
  {
    value: '2672',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Badespielzeug',
  },
  {
    value: '500042',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Chlorgeneratoren für Pools und Whirlpools',
  },
  {
    value: '3017',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Mechanische & Chemische Poolreiniger',
  },
  {
    value: '2981',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Pool- & Whirlpool-Filter',
  },
  {
    value: '2994',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Poolabdeckungen & -unterlagen',
  },
  {
    value: '6996',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Poolbürsten',
  },
  {
    value: '503751',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Poolfolien',
  },
  {
    value: '6766',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Poolleitern, -treppen & -rampen',
  },
  {
    value: '2997',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Poolreinigungsgeräte',
  },
  {
    value: '2755',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Poolskimmer',
  },
  {
    value: '6771',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Schläuche für Poolreiniger',
  },
  {
    value: '7496',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Sonnendecks',
  },
  {
    value: '2939',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Sprungbretter',
  },
  {
    value: '5654',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Swimmingpoolheizungen',
  },
  {
    value: '505815',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Wartungssets für Pools & Whirlpools',
  },
  {
    value: '5546',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Wasserrutschen',
  },
  {
    value: '500050',
    label:
      'Heim & Garten > Pool & Spa > Pool- & Whirlpool-Zubehör > Zubehör für Poolabdeckungen',
  },
  {
    value: '2810',
    label: 'Heim & Garten > Pool & Spa > Pools',
  },
  {
    value: '3992',
    label: 'Heim & Garten > Pool & Spa > Saunen',
  },
  {
    value: '2982',
    label: 'Heim & Garten > Pool & Spa > Spa-Becken',
  },
  {
    value: '689',
    label: 'Heim & Garten > Rasen & Garten',
  },
  {
    value: '3568',
    label: 'Heim & Garten > Rasen & Garten > Bewässerungssysteme',
  },
  {
    value: '4201',
    label:
      'Heim & Garten > Rasen & Garten > Bewässerungssysteme > Gartenschlauchsprühköpfe',
  },
  {
    value: '4718',
    label:
      'Heim & Garten > Rasen & Garten > Bewässerungssysteme > Gartenschlauchzubehör & -ventile',
  },
  {
    value: '2313',
    label:
      'Heim & Garten > Rasen & Garten > Bewässerungssysteme > Gartenschläuche',
  },
  {
    value: '6318',
    label: 'Heim & Garten > Rasen & Garten > Bewässerungssysteme > Gießkannen',
  },
  {
    value: '7561',
    label:
      'Heim & Garten > Rasen & Garten > Bewässerungssysteme > Sprinkler & -köpfe',
  },
  {
    value: '3780',
    label:
      'Heim & Garten > Rasen & Garten > Bewässerungssysteme > Sprinklersysteme',
  },
  {
    value: '3491',
    label:
      'Heim & Garten > Rasen & Garten > Bewässerungssysteme > Sprinklersysteme > Sprinklerventile',
  },
  {
    value: '1302',
    label:
      'Heim & Garten > Rasen & Garten > Bewässerungssysteme > Sprinklersysteme > Steuerelemente für Sprinkleranlagen',
  },
  {
    value: '230912',
    label:
      'Heim & Garten > Rasen & Garten > Bewässerungssysteme > Wasserspender für Pflanzen',
  },
  {
    value: '505814',
    label:
      'Heim & Garten > Rasen & Garten > Bewässerungssysteme > Zubehör für Gießkannen',
  },
  {
    value: '2918',
    label: 'Heim & Garten > Rasen & Garten > Garten & Balkon',
  },
  {
    value: '2613',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Gartenhäuschen & Gartenbauten',
  },
  {
    value: '703',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Gartenhäuschen & Gartenbauten > Bögen, Gitter, Laubengänge & Pergolen',
  },
  {
    value: '700',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Gartenhäuschen & Gartenbauten > Gartenbrücken',
  },
  {
    value: '6105',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Gartenhäuschen & Gartenbauten > Pavillon-Zubehör',
  },
  {
    value: '6108',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Gartenhäuschen & Gartenbauten > Pavillon-Zubehör > Dächer für Pavillions',
  },
  {
    value: '7424',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Gartenhäuschen & Gartenbauten > Pavillon-Zubehör > Gewichte für Sonnendächer',
  },
  {
    value: '6106',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Gartenhäuschen & Gartenbauten > Pavillon-Zubehör > Rahmen für Pavillons',
  },
  {
    value: '6107',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Gartenhäuschen & Gartenbauten > Pavillon-Zubehör > Seitenwand-Sets für Pavillons',
  },
  {
    value: '7423',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Gartenhäuschen & Gartenbauten > Pavillon-Zubehör > Stangen für Sonnendächer',
  },
  {
    value: '716',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Gartenhäuschen & Gartenbauten > Pavillons & Gartenlauben',
  },
  {
    value: '720',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Gartenhäuschen & Gartenbauten > Schuppen, Garagen & Carports',
  },
  {
    value: '718',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Hollywoodschaukeln',
  },
  {
    value: '717',
    label: 'Heim & Garten > Rasen & Garten > Garten & Balkon > Hängematten',
  },
  {
    value: '6737',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Hängemattenzubehör',
  },
  {
    value: '499907',
    label: 'Heim & Garten > Rasen & Garten > Garten & Balkon > Markisen',
  },
  {
    value: '499908',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Markisen-Zubehör',
  },
  {
    value: '5910',
    label: 'Heim & Garten > Rasen & Garten > Garten & Balkon > Outdoor-Decken',
  },
  {
    value: '5913',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Outdoor-Decken > Picknickdecken',
  },
  {
    value: '5912',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Outdoor-Decken > Poncholiner',
  },
  {
    value: '5911',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Outdoor-Decken > Strandmatten',
  },
  {
    value: '719',
    label: 'Heim & Garten > Rasen & Garten > Garten & Balkon > Sonnenschirme',
  },
  {
    value: '499955',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Zubehör für Hollywoodschaukeln',
  },
  {
    value: '6751',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Zubehör für Sonnenschirme',
  },
  {
    value: '499948',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Zubehör für Sonnenschirme > Sonnenschirmabdeckungen',
  },
  {
    value: '8020',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Zubehör für Sonnenschirme > Sonnenschirmbeleuchtung',
  },
  {
    value: '7107',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Zubehör für Sonnenschirme > Sonnenschirmschutzhüllen',
  },
  {
    value: '7108',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Zubehör für Sonnenschirme > Sonnenschirmstoff',
  },
  {
    value: '5493',
    label:
      'Heim & Garten > Rasen & Garten > Garten & Balkon > Zubehör für Sonnenschirme > Sonnenschirmständer',
  },
  {
    value: '2962',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau',
  },
  {
    value: '499894',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Blumenkäfige & Zubehör',
  },
  {
    value: '721',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Blumentöpfe & Pflanzgefäße',
  },
  {
    value: '113',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau > Dünger',
  },
  {
    value: '500033',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartentopfuntersetzer und Trays',
  },
  {
    value: '3173',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge',
  },
  {
    value: '6967',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Astsägen',
  },
  {
    value: '3841',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Baumscheren',
  },
  {
    value: '505292',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Gartensicheln & Macheten',
  },
  {
    value: '7537',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Geräte zum Pflanzen von Knollen',
  },
  {
    value: '3071',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Mistgabeln',
  },
  {
    value: '3644',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Pflanzkellen',
  },
  {
    value: '4000',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Pflanzwerkzeuge',
  },
  {
    value: '1967',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Rasen- & Gartenspritzgeräte',
  },
  {
    value: '499922',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Rasenwalzen',
  },
  {
    value: '3388',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Rechen',
  },
  {
    value: '2147',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Schaufeln & Spaten',
  },
  {
    value: '3616',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Schubkarren',
  },
  {
    value: '3828',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Gartenwerkzeuge > Streugeräte',
  },
  {
    value: '693',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau > Gewächshäuser',
  },
  {
    value: '3103',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau > Herbizide',
  },
  {
    value: '4085',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau > Kompostieren',
  },
  {
    value: '690',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Kompostieren > Kompost',
  },
  {
    value: '6436',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Kompostieren > Komposter',
  },
  {
    value: '6840',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Kompostieren > Kompostlüfter',
  },
  {
    value: '691',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau > Krankheitsbekämpfung',
  },
  {
    value: '2988',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau > Mulch',
  },
  {
    value: '499962',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Pflanzeinsätze für Blumentöpfe und -kübel',
  },
  {
    value: '6428',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau > Pflanzenständer',
  },
  {
    value: '6834',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau > Regenfässer',
  },
  {
    value: '1794',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau > Sand & Gartenerde',
  },
  {
    value: '6381',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau > Unkrautvlies',
  },
  {
    value: '6413',
    label: 'Heim & Garten > Rasen & Garten > Gartenbau > Unkrautvlies-Zubehör',
  },
  {
    value: '6422',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Unkrautvlies-Zubehör > Erdanker & -nägel',
  },
  {
    value: '6421',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Unkrautvlies-Zubehör > Garten - Gewebeband',
  },
  {
    value: '5632',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Zubehör für Gartenarbeit',
  },
  {
    value: '5633',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Zubehör für Gartenarbeit > Gartentaschen',
  },
  {
    value: '503756',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Zubehör für Gartenarbeit > Rollsitze, Sitzhilfen & Kniekissen für Gartenarbeit',
  },
  {
    value: '7184',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Zubehör für Gartenarbeit > Umtopftische',
  },
  {
    value: '505326',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Zubehör für Gartengeräte',
  },
  {
    value: '505321',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Zubehör für Gartengeräte > Aufsätze für Gartengeräte',
  },
  {
    value: '505322',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Zubehör für Gartengeräte > Griffe für Gartengeräte',
  },
  {
    value: '4972',
    label:
      'Heim & Garten > Rasen & Garten > Gartenbau > Zubehör für Gartengeräte > Schubkarrenteile',
  },
  {
    value: '3798',
    label: 'Heim & Garten > Rasen & Garten > Gartenmaschinen',
  },
  {
    value: '7332',
    label:
      'Heim & Garten > Rasen & Garten > Gartenmaschinen > Basiseinheiten für elektrische Gartengeräte',
  },
  {
    value: '2204',
    label:
      'Heim & Garten > Rasen & Garten > Gartenmaschinen > Bodenbearbeitungsmaschinen',
  },
  {
    value: '3120',
    label:
      'Heim & Garten > Rasen & Garten > Gartenmaschinen > Elektrische Heckenscheren',
  },
  {
    value: '7245',
    label:
      'Heim & Garten > Rasen & Garten > Gartenmaschinen > Elektrische Kombi-Gartenmaschinen-Sets',
  },
  {
    value: '1226',
    label:
      'Heim & Garten > Rasen & Garten > Gartenmaschinen > Hochdruckreiniger',
  },
  {
    value: '500016',
    label: 'Heim & Garten > Rasen & Garten > Gartenmaschinen > Kehrmaschine',
  },
  {
    value: '3610',
    label: 'Heim & Garten > Rasen & Garten > Gartenmaschinen > Kettensägen',
  },
  {
    value: '3340',
    label: 'Heim & Garten > Rasen & Garten > Gartenmaschinen > Laubbläser',
  },
  {
    value: '6789',
    label: 'Heim & Garten > Rasen & Garten > Gartenmaschinen > Laubsauger',
  },
  {
    value: '2218',
    label:
      'Heim & Garten > Rasen & Garten > Gartenmaschinen > Rasenkantentrimmer',
  },
  {
    value: '500034',
    label:
      'Heim & Garten > Rasen & Garten > Gartenmaschinen > Rasenlüfter und Vertikutierer',
  },
  {
    value: '694',
    label: 'Heim & Garten > Rasen & Garten > Gartenmaschinen > Rasenmäher',
  },
  {
    value: '3311',
    label:
      'Heim & Garten > Rasen & Garten > Gartenmaschinen > Rasenmäher > Aufsitzrasenmäher',
  },
  {
    value: '3730',
    label:
      'Heim & Garten > Rasen & Garten > Gartenmaschinen > Rasenmäher > Handrasenmäher',
  },
  {
    value: '6788',
    label:
      'Heim & Garten > Rasen & Garten > Gartenmaschinen > Rasenmäher > Mähroboter',
  },
  {
    value: '6258',
    label:
      'Heim & Garten > Rasen & Garten > Gartenmaschinen > Rasenmäher > Quad-Anhänger',
  },
  {
    value: '1223',
    label:
      'Heim & Garten > Rasen & Garten > Gartenmaschinen > Rasentrimmer & Freischneider',
  },
  {
    value: '1541',
    label: 'Heim & Garten > Rasen & Garten > Gartenmaschinen > Schneefräsen',
  },
  {
    value: '5866',
    label: 'Heim & Garten > Rasen & Garten > Gartenmaschinen > Traktoren',
  },
  {
    value: '5362',
    label: 'Heim & Garten > Rasen & Garten > Schnee- und Eisbeseitigung',
  },
  {
    value: '5364',
    label:
      'Heim & Garten > Rasen & Garten > Schnee- und Eisbeseitigung > Eiskratzer & Schneebesen',
  },
  {
    value: '5363',
    label:
      'Heim & Garten > Rasen & Garten > Schnee- und Eisbeseitigung > Schneeschaufeln',
  },
  {
    value: '4564',
    label: 'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen',
  },
  {
    value: '8485',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Aufsätze für elektrische Kombi-Gartenmaschinen',
  },
  {
    value: '8488',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Aufsätze für elektrische Kombi-Gartenmaschinen > Fräsen- und Grubber-Aufsätze',
  },
  {
    value: '7334',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Aufsätze für elektrische Kombi-Gartenmaschinen > Heckenscheren-Aufsätze',
  },
  {
    value: '8487',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Aufsätze für elektrische Kombi-Gartenmaschinen > Laubbläser-Aufsätze',
  },
  {
    value: '7564',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Aufsätze für elektrische Kombi-Gartenmaschinen > Rasentrimmer-Aufsätze',
  },
  {
    value: '8489',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Aufsätze für elektrische Kombi-Gartenmaschinen > Stangensägen-Aufsätze',
  },
  {
    value: '7335',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Aufsätze für elektrische Kombi-Gartenmaschinen > Unkrauttrimmer-Aufsätze',
  },
  {
    value: '7333',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Batterien für elektrische Kombi-Gartenmaschinen',
  },
  {
    value: '7265',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Heckenscheren-Zubehör',
  },
  {
    value: '6328',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Hochdruckreiniger-Zubehör',
  },
  {
    value: '4565',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Kettensägenzubehör',
  },
  {
    value: '4646',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Kettensägenzubehör > Kettensägenketten',
  },
  {
    value: '4647',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Kettensägenzubehör > Kettensägenschwerter',
  },
  {
    value: '7168',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Laubbläser-Zubehör',
  },
  {
    value: '7171',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Laubbläser-Zubehör > Laubbläser-Rohre',
  },
  {
    value: '4566',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör',
  },
  {
    value: '4645',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Grasauffangbehälter',
  },
  {
    value: '499923',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Mulchkits für Rasenmäher',
  },
  {
    value: '499960',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Mulchstopfen für Rasenmäher',
  },
  {
    value: '6541',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Rasenkehrmaschinen',
  },
  {
    value: '499921',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Rasenmäher-Antriebsrollen',
  },
  {
    value: '4643',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Rasenmäher-Keilriemen',
  },
  {
    value: '6095',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Rasenmäher-Reifen',
  },
  {
    value: '499872',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Rasenmäher-Reifenschläuche',
  },
  {
    value: '4644',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Rasenmäher-Riemenscheiben',
  },
  {
    value: '6094',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Rasenmäher-Räder',
  },
  {
    value: '4642',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Rasenmäherabdeckungen',
  },
  {
    value: '4641',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Rasenmähermesser',
  },
  {
    value: '6542',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasenmäher-Zubehör > Wiesenmäher-Zusatzteile',
  },
  {
    value: '7563',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Rasentrimmer-Zubehör',
  },
  {
    value: '4567',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Schneefräsenzubehör',
  },
  {
    value: '5867',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Traktorteile & Zubehör',
  },
  {
    value: '499880',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Traktorteile & Zubehör > Traktorreifen',
  },
  {
    value: '499881',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Traktorteile & Zubehör > Traktorräder',
  },
  {
    value: '7169',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Unkrauttrimmer-Zubehör',
  },
  {
    value: '8034',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Unkrauttrimmer-Zubehör > Abdeckungen für Rasentrimmer-Kabelrollen',
  },
  {
    value: '7170',
    label:
      'Heim & Garten > Rasen & Garten > Zubehör für Gartenmaschinen > Unkrauttrimmer-Zubehör > Messer & Kabelrollen für Rasentrimmer',
  },
  {
    value: '600',
    label: 'Heim & Garten > Rauchzubehör',
  },
  {
    value: '4082',
    label: 'Heim & Garten > Rauchzubehör > Aschenbecher',
  },
  {
    value: '6878',
    label: 'Heim & Garten > Rauchzubehör > Humidore',
  },
  {
    value: '6880',
    label: 'Heim & Garten > Rauchzubehör > Humidorzubehör',
  },
  {
    value: '500007',
    label: 'Heim & Garten > Rauchzubehör > Zigarettenhalter',
  },
  {
    value: '6881',
    label: 'Heim & Garten > Rauchzubehör > Zigarettenkisten',
  },
  {
    value: '6882',
    label: 'Heim & Garten > Rauchzubehör > Zigarrenkisten',
  },
  {
    value: '6879',
    label: 'Heim & Garten > Rauchzubehör > Zigarrenschneider & -locher',
  },
  {
    value: '6173',
    label: 'Heim & Garten > Schutzhüllen für Sonnenschirme',
  },
  {
    value: '4358',
    label: 'Heim & Garten > Sonnen- & Regenschirme',
  },
  {
    value: '2862',
    label: 'Heim & Garten > Zubehör für Kamine & Holzöfen',
  },
  {
    value: '2044',
    label: 'Heim & Garten > Zubehör für Kamine & Holzöfen > Blasebälge',
  },
  {
    value: '7523',
    label: 'Heim & Garten > Zubehör für Kamine & Holzöfen > Feuerböcke',
  },
  {
    value: '625',
    label:
      'Heim & Garten > Zubehör für Kamine & Holzöfen > Feuerholz & Brennstoffe',
  },
  {
    value: '695',
    label:
      'Heim & Garten > Zubehör für Kamine & Holzöfen > Feuerholz-Aufbewahrung',
  },
  {
    value: '4918',
    label:
      'Heim & Garten > Zubehör für Kamine & Holzöfen > Holzofenventilatoren & -gebläse',
  },
  {
    value: '6563',
    label:
      'Heim & Garten > Zubehör für Kamine & Holzöfen > Kamin- & Holzofenroste',
  },
  {
    value: '7091',
    label: 'Heim & Garten > Zubehör für Kamine & Holzöfen > Kaminbodenplatten',
  },
  {
    value: '2365',
    label: 'Heim & Garten > Zubehör für Kamine & Holzöfen > Kamingitter',
  },
  {
    value: '7109',
    label: 'Heim & Garten > Zubehör für Kamine & Holzöfen > Kaminreflektoren',
  },
  {
    value: '1530',
    label: 'Heim & Garten > Zubehör für Kamine & Holzöfen > Kaminwerkzeug',
  },
  {
    value: '7029',
    label:
      'Heim & Garten > Zubehör für Kamine & Holzöfen > Zubehör für Feuerholz-Aufbewahrung',
  },
  {
    value: '632',
    label: 'Heimwerkerbedarf',
  },
  {
    value: '503739',
    label: 'Heimwerkerbedarf > Baumaterial',
  },
  {
    value: '2277',
    label: 'Heimwerkerbedarf > Baumaterial > Chemikalien',
  },
  {
    value: '1749',
    label: 'Heimwerkerbedarf > Baumaterial > Chemikalien > Abflussreiniger',
  },
  {
    value: '6795',
    label: 'Heimwerkerbedarf > Baumaterial > Chemikalien > Ammonium',
  },
  {
    value: '500088',
    label: 'Heimwerkerbedarf > Baumaterial > Chemikalien > Anzünderflüssigkeit',
  },
  {
    value: '7504',
    label:
      'Heimwerkerbedarf > Baumaterial > Chemikalien > Beton- & Mauerreiniger',
  },
  {
    value: '6191',
    label: 'Heimwerkerbedarf > Baumaterial > Chemikalien > Enteiser',
  },
  {
    value: '7503',
    label:
      'Heimwerkerbedarf > Baumaterial > Chemikalien > Holzterrassen- & -zaunreiniger',
  },
  {
    value: '1479',
    label: 'Heimwerkerbedarf > Baumaterial > Chemikalien > Kaminreiniger',
  },
  {
    value: '505319',
    label:
      'Heimwerkerbedarf > Baumaterial > Chemikalien > Kältespray für Elektronik',
  },
  {
    value: '7470',
    label:
      'Heimwerkerbedarf > Baumaterial > Chemikalien > Reiniger für Abwassertanks & Senkgruben',
  },
  {
    value: '1735',
    label: 'Heimwerkerbedarf > Baumaterial > Chemikalien > Säureneutralisierer',
  },
  {
    value: '2212',
    label: 'Heimwerkerbedarf > Baumaterial > Klebebänder',
  },
  {
    value: '503742',
    label: 'Heimwerkerbedarf > Baumaterial > Kraftklebstoffe & -leime',
  },
  {
    value: '503741',
    label:
      'Heimwerkerbedarf > Baumaterial > Lösungsmittel, Abbeizmittel & Verdünner',
  },
  {
    value: '1995',
    label: 'Heimwerkerbedarf > Baumaterial > Lötmetall & Flussmittel',
  },
  {
    value: '503740',
    label: 'Heimwerkerbedarf > Baumaterial > Malermaterial',
  },
  {
    value: '1361',
    label: 'Heimwerkerbedarf > Baumaterial > Malermaterial > Farbe',
  },
  {
    value: '2474',
    label: 'Heimwerkerbedarf > Baumaterial > Malermaterial > Farbenbindemittel',
  },
  {
    value: '1648',
    label: 'Heimwerkerbedarf > Baumaterial > Malermaterial > Farbstoffe',
  },
  {
    value: '2058',
    label: 'Heimwerkerbedarf > Baumaterial > Malermaterial > Grundierungen',
  },
  {
    value: '503738',
    label:
      'Heimwerkerbedarf > Baumaterial > Malermaterial > Lacke & Beschichtungen',
  },
  {
    value: '503743',
    label: 'Heimwerkerbedarf > Baumaterial > Maurermaterial',
  },
  {
    value: '3031',
    label:
      'Heimwerkerbedarf > Baumaterial > Maurermaterial > Mauersteine & Betonblöcke',
  },
  {
    value: '499876',
    label: 'Heimwerkerbedarf > Baumaterial > Maurermaterial > Mörtel',
  },
  {
    value: '2282',
    label:
      'Heimwerkerbedarf > Baumaterial > Maurermaterial > Zement, Mörtel & Beton',
  },
  {
    value: '505305',
    label: 'Heimwerkerbedarf > Baumaterial > Primer für Rohrverbindungen',
  },
  {
    value: '1753',
    label: 'Heimwerkerbedarf > Baumaterial > Schmiermittel',
  },
  {
    value: '503744',
    label:
      'Heimwerkerbedarf > Baumaterial > Schutzbeschichtungen & Dichtmittel',
  },
  {
    value: '505802',
    label: 'Heimwerkerbedarf > Baumaterial > Wandausbesserungsmaterial & Gips',
  },
  {
    value: '115',
    label: 'Heimwerkerbedarf > Baumaterialien',
  },
  {
    value: '2729',
    label: 'Heimwerkerbedarf > Baumaterialien > Arbeitsplatten',
  },
  {
    value: '125',
    label: 'Heimwerkerbedarf > Baumaterialien > Bauholz',
  },
  {
    value: '123',
    label: 'Heimwerkerbedarf > Baumaterialien > Bedachung',
  },
  {
    value: '121',
    label: 'Heimwerkerbedarf > Baumaterialien > Bedachung > Dachrinnen',
  },
  {
    value: '8270',
    label:
      'Heimwerkerbedarf > Baumaterialien > Bedachung > Dachschindeln & -pfannen',
  },
  {
    value: '2008',
    label: 'Heimwerkerbedarf > Baumaterialien > Bedachung > Kehlbleche',
  },
  {
    value: '4544',
    label:
      'Heimwerkerbedarf > Baumaterialien > Bedachung > Zubehör für Dachrinnen',
  },
  {
    value: '503777',
    label: 'Heimwerkerbedarf > Baumaterialien > Betonstahl & -gitter',
  },
  {
    value: '124',
    label: 'Heimwerkerbedarf > Baumaterialien > Fenster',
  },
  {
    value: '499772',
    label: 'Heimwerkerbedarf > Baumaterialien > Fensterbauteile',
  },
  {
    value: '499773',
    label: 'Heimwerkerbedarf > Baumaterialien > Fensterbauteile > Fensterhebel',
  },
  {
    value: '503728',
    label:
      'Heimwerkerbedarf > Baumaterialien > Fensterbauteile > Fensterrahmen',
  },
  {
    value: '6943',
    label: 'Heimwerkerbedarf > Baumaterialien > Fensterläden',
  },
  {
    value: '2826',
    label: 'Heimwerkerbedarf > Baumaterialien > Fußböden & Teppichböden',
  },
  {
    value: '503776',
    label: 'Heimwerkerbedarf > Baumaterialien > Gipskartonplatten',
  },
  {
    value: '120',
    label: 'Heimwerkerbedarf > Baumaterialien > Glas',
  },
  {
    value: '499949',
    label: 'Heimwerkerbedarf > Baumaterialien > Handläufe und Geländersysteme',
  },
  {
    value: '503775',
    label: 'Heimwerkerbedarf > Baumaterialien > Hausverkleidung',
  },
  {
    value: '122',
    label: 'Heimwerkerbedarf > Baumaterialien > Isolierung',
  },
  {
    value: '7112',
    label: 'Heimwerkerbedarf > Baumaterialien > Leisten',
  },
  {
    value: '2030',
    label: 'Heimwerkerbedarf > Baumaterialien > Luken',
  },
  {
    value: '7439',
    label: 'Heimwerkerbedarf > Baumaterialien > Schalldämpfende Materialien',
  },
  {
    value: '7004',
    label: 'Heimwerkerbedarf > Baumaterialien > Treppen',
  },
  {
    value: '6343',
    label: 'Heimwerkerbedarf > Baumaterialien > Türbeschläge & -klingeln',
  },
  {
    value: '6458',
    label:
      'Heimwerkerbedarf > Baumaterialien > Türbeschläge & -klingeln > Schlossfallen',
  },
  {
    value: '99338',
    label:
      'Heimwerkerbedarf > Baumaterialien > Türbeschläge & -klingeln > Schlüssellochverzierungen für Türen',
  },
  {
    value: '499970',
    label:
      'Heimwerkerbedarf > Baumaterialien > Türbeschläge & -klingeln > Türdruckplatten',
  },
  {
    value: '2972',
    label:
      'Heimwerkerbedarf > Baumaterialien > Türbeschläge & -klingeln > Türklingeln',
  },
  {
    value: '2795',
    label:
      'Heimwerkerbedarf > Baumaterialien > Türbeschläge & -klingeln > Türklopfer',
  },
  {
    value: '1356',
    label:
      'Heimwerkerbedarf > Baumaterialien > Türbeschläge & -klingeln > Türknäufe & -klinken',
  },
  {
    value: '503727',
    label:
      'Heimwerkerbedarf > Baumaterialien > Türbeschläge & -klingeln > Türrahmen',
  },
  {
    value: '6446',
    label:
      'Heimwerkerbedarf > Baumaterialien > Türbeschläge & -klingeln > Türschließer',
  },
  {
    value: '2665',
    label:
      'Heimwerkerbedarf > Baumaterialien > Türbeschläge & -klingeln > Türstopper',
  },
  {
    value: '119',
    label: 'Heimwerkerbedarf > Baumaterialien > Türen',
  },
  {
    value: '4468',
    label: 'Heimwerkerbedarf > Baumaterialien > Türen > Garagentore',
  },
  {
    value: '4634',
    label: 'Heimwerkerbedarf > Baumaterialien > Türen > Haustüren',
  },
  {
    value: '7136',
    label: 'Heimwerkerbedarf > Baumaterialien > Wand- & Deckenfliesen',
  },
  {
    value: '7053',
    label: 'Heimwerkerbedarf > Baumaterialien > Wandverkleidung',
  },
  {
    value: '505300',
    label:
      'Heimwerkerbedarf > Baumaterialien > Zugluftstopper, Fenster- & Türdichtungen',
  },
  {
    value: '2878',
    label: 'Heimwerkerbedarf > Bauzubehör',
  },
  {
    value: '1979',
    label: 'Heimwerkerbedarf > Bauzubehör > Abdeckfolien',
  },
  {
    value: '500054',
    label: 'Heimwerkerbedarf > Bauzubehör > Befestigungselemente',
  },
  {
    value: '1508',
    label: 'Heimwerkerbedarf > Bauzubehör > Befestigungselemente > Dübel',
  },
  {
    value: '500055',
    label:
      'Heimwerkerbedarf > Bauzubehör > Befestigungselemente > Gewindestangen',
  },
  {
    value: '7062',
    label: 'Heimwerkerbedarf > Bauzubehör > Befestigungselemente > Nieten',
  },
  {
    value: '2408',
    label: 'Heimwerkerbedarf > Bauzubehör > Befestigungselemente > Nägel',
  },
  {
    value: '2251',
    label: 'Heimwerkerbedarf > Bauzubehör > Befestigungselemente > Schrauben',
  },
  {
    value: '1739',
    label:
      'Heimwerkerbedarf > Bauzubehör > Befestigungselemente > Schrauben & Muttern',
  },
  {
    value: '2230',
    label:
      'Heimwerkerbedarf > Bauzubehör > Befestigungselemente > Schraubverbinder',
  },
  {
    value: '2195',
    label:
      'Heimwerkerbedarf > Bauzubehör > Befestigungselemente > Unterlegscheiben',
  },
  {
    value: '7086',
    label: 'Heimwerkerbedarf > Bauzubehör > Betonformen',
  },
  {
    value: '8112',
    label: 'Heimwerkerbedarf > Bauzubehör > Bodenspieße',
  },
  {
    value: '499933',
    label: 'Heimwerkerbedarf > Bauzubehör > Federn',
  },
  {
    value: '1816',
    label: 'Heimwerkerbedarf > Bauzubehör > Filter & Siebe',
  },
  {
    value: '6841',
    label: 'Heimwerkerbedarf > Bauzubehör > Gasschläuche',
  },
  {
    value: '503773',
    label: 'Heimwerkerbedarf > Bauzubehör > Haken, Schnallen & Verschlüsse',
  },
  {
    value: '502978',
    label:
      'Heimwerkerbedarf > Bauzubehör > Haken, Schnallen & Verschlüsse > Gear Tie-Universalkabelbinder',
  },
  {
    value: '503764',
    label:
      'Heimwerkerbedarf > Bauzubehör > Haken, Schnallen & Verschlüsse > Kettenverbinder & -glieder',
  },
  {
    value: '503770',
    label:
      'Heimwerkerbedarf > Bauzubehör > Haken, Schnallen & Verschlüsse > Lasthaken, Trageklemmen & Schäkel',
  },
  {
    value: '502992',
    label:
      'Heimwerkerbedarf > Bauzubehör > Haken, Schnallen & Verschlüsse > Schnallen- & Steckschnallen',
  },
  {
    value: '7092',
    label: 'Heimwerkerbedarf > Bauzubehör > Halterungen',
  },
  {
    value: '502977',
    label: 'Heimwerkerbedarf > Bauzubehör > Ketten, Drähte & Seile',
  },
  {
    value: '5119',
    label:
      'Heimwerkerbedarf > Bauzubehör > Ketten, Drähte & Seile > Bindegarne',
  },
  {
    value: '6904',
    label: 'Heimwerkerbedarf > Bauzubehör > Ketten, Drähte & Seile > Draht',
  },
  {
    value: '1492',
    label: 'Heimwerkerbedarf > Bauzubehör > Ketten, Drähte & Seile > Ketten',
  },
  {
    value: '3053',
    label: 'Heimwerkerbedarf > Bauzubehör > Ketten, Drähte & Seile > Seile',
  },
  {
    value: '6298',
    label:
      'Heimwerkerbedarf > Bauzubehör > Ketten, Drähte & Seile > Spanngummis',
  },
  {
    value: '6297',
    label:
      'Heimwerkerbedarf > Bauzubehör > Ketten, Drähte & Seile > Spanngurte',
  },
  {
    value: '4469',
    label:
      'Heimwerkerbedarf > Bauzubehör > Ketten, Drähte & Seile > Zugschalterketten und -kordeln',
  },
  {
    value: '499981',
    label: 'Heimwerkerbedarf > Bauzubehör > Laufrollen',
  },
  {
    value: '6769',
    label: 'Heimwerkerbedarf > Bauzubehör > Luftschläuche',
  },
  {
    value: '503731',
    label: 'Heimwerkerbedarf > Bauzubehör > Metallgussformen',
  },
  {
    value: '4988',
    label: 'Heimwerkerbedarf > Bauzubehör > Planen',
  },
  {
    value: '505320',
    label: 'Heimwerkerbedarf > Bauzubehör > Richtstützen',
  },
  {
    value: '1771',
    label: 'Heimwerkerbedarf > Bauzubehör > Scharniere',
  },
  {
    value: '6770',
    label: 'Heimwerkerbedarf > Bauzubehör > Schmierschläuche',
  },
  {
    value: '4696',
    label: 'Heimwerkerbedarf > Bauzubehör > Schrankbauteile',
  },
  {
    value: '232167',
    label:
      'Heimwerkerbedarf > Bauzubehör > Schrankbauteile > Schlüssellochverzierungen für Schränke',
  },
  {
    value: '4697',
    label: 'Heimwerkerbedarf > Bauzubehör > Schrankbauteile > Schrankbeschläge',
  },
  {
    value: '4700',
    label:
      'Heimwerkerbedarf > Bauzubehör > Schrankbauteile > Schrankknäufe & -griffe',
  },
  {
    value: '4698',
    label:
      'Heimwerkerbedarf > Bauzubehör > Schrankbauteile > Schrankscharniere',
  },
  {
    value: '4699',
    label: 'Heimwerkerbedarf > Bauzubehör > Schrankbauteile > Schranktüren',
  },
  {
    value: '8470',
    label: 'Heimwerkerbedarf > Bauzubehör > Schubladenschienen',
  },
  {
    value: '1318',
    label: 'Heimwerkerbedarf > Bauzubehör > Spulen',
  },
  {
    value: '8113',
    label: 'Heimwerkerbedarf > Bauzubehör > Standfüße für Pfosten',
  },
  {
    value: '500030',
    label:
      'Heimwerkerbedarf > Bauzubehör > Transport- und Schallschutzabdeckungen',
  },
  {
    value: '7557',
    label: 'Heimwerkerbedarf > Bauzubehör > Warnklebeband',
  },
  {
    value: '3974',
    label:
      'Heimwerkerbedarf > Bauzubehör > Werkzeugaufbewahrung & Ordnungssysteme',
  },
  {
    value: '2485',
    label:
      'Heimwerkerbedarf > Bauzubehör > Werkzeugaufbewahrung & Ordnungssysteme > Dienst- & Werkzeuggürtel',
  },
  {
    value: '500103',
    label:
      'Heimwerkerbedarf > Bauzubehör > Werkzeugaufbewahrung & Ordnungssysteme > Einsätze zur Werkzeugaufbewahrung',
  },
  {
    value: '4199',
    label:
      'Heimwerkerbedarf > Bauzubehör > Werkzeugaufbewahrung & Ordnungssysteme > Gartenschlauchhalterungen',
  },
  {
    value: '3919',
    label:
      'Heimwerkerbedarf > Bauzubehör > Werkzeugaufbewahrung & Ordnungssysteme > Werkbänke',
  },
  {
    value: '4031',
    label:
      'Heimwerkerbedarf > Bauzubehör > Werkzeugaufbewahrung & Ordnungssysteme > Werkzeugetuis',
  },
  {
    value: '3980',
    label:
      'Heimwerkerbedarf > Bauzubehör > Werkzeugaufbewahrung & Ordnungssysteme > Werkzeugkästen',
  },
  {
    value: '3280',
    label:
      'Heimwerkerbedarf > Bauzubehör > Werkzeugaufbewahrung & Ordnungssysteme > Werkzeugschränke',
  },
  {
    value: '6876',
    label:
      'Heimwerkerbedarf > Bauzubehör > Werkzeugaufbewahrung & Ordnungssysteme > Werkzeugtaschen',
  },
  {
    value: '7270',
    label: 'Heimwerkerbedarf > Bauzubehör > Zylinderstifte & Dübelstangen',
  },
  {
    value: '502975',
    label: 'Heimwerkerbedarf > Brennstoffbehälter & -tanks',
  },
  {
    value: '127',
    label: 'Heimwerkerbedarf > Elektrobedarf',
  },
  {
    value: '500049',
    label: 'Heimwerkerbedarf > Elektrobedarf > Anker, Rotoren und Statoren',
  },
  {
    value: '2006',
    label: 'Heimwerkerbedarf > Elektrobedarf > Anschlussdosen & Halterungen',
  },
  {
    value: '2274',
    label: 'Heimwerkerbedarf > Elektrobedarf > Drahtbindemaschinen',
  },
  {
    value: '2345',
    label: 'Heimwerkerbedarf > Elektrobedarf > Elektrische Leitungen',
  },
  {
    value: '7275',
    label: 'Heimwerkerbedarf > Elektrobedarf > Elektromotoren',
  },
  {
    value: '6459',
    label: 'Heimwerkerbedarf > Elektrobedarf > Elektroschalter',
  },
  {
    value: '1935',
    label: 'Heimwerkerbedarf > Elektrobedarf > Elektroschalter > Lichtschalter',
  },
  {
    value: '499932',
    label:
      'Heimwerkerbedarf > Elektrobedarf > Elektroschalter > Spezielle elektrische Schalter',
  },
  {
    value: '1218',
    label: 'Heimwerkerbedarf > Elektrobedarf > Generatoren',
  },
  {
    value: '499966',
    label: 'Heimwerkerbedarf > Elektrobedarf > Gerätestecker',
  },
  {
    value: '2413',
    label: 'Heimwerkerbedarf > Elektrobedarf > Heim- & Gebäudeautomatisierung',
  },
  {
    value: '503729',
    label:
      'Heimwerkerbedarf > Elektrobedarf > Kabelanschlüsse & Steckverbinder',
  },
  {
    value: '499893',
    label: 'Heimwerkerbedarf > Elektrobedarf > Kohlebürsten',
  },
  {
    value: '499768',
    label: 'Heimwerkerbedarf > Elektrobedarf > Leitungen & Gehäuse',
  },
  {
    value: '499770',
    label:
      'Heimwerkerbedarf > Elektrobedarf > Leitungen & Gehäuse > Kabelkanäle',
  },
  {
    value: '3797',
    label:
      'Heimwerkerbedarf > Elektrobedarf > Leitungen & Gehäuse > Schrumpfschläuche',
  },
  {
    value: '5533',
    label: 'Heimwerkerbedarf > Elektrobedarf > Schaltnetzteile',
  },
  {
    value: '4715',
    label: 'Heimwerkerbedarf > Elektrobedarf > Solarenergie-Kits',
  },
  {
    value: '4714',
    label: 'Heimwerkerbedarf > Elektrobedarf > Solarpaneele',
  },
  {
    value: '505318',
    label: 'Heimwerkerbedarf > Elektrobedarf > Spannungswandler & -regler',
  },
  {
    value: '1869',
    label: 'Heimwerkerbedarf > Elektrobedarf > Steckdosen',
  },
  {
    value: '6833',
    label:
      'Heimwerkerbedarf > Elektrobedarf > Steckdosen-Zeitschaltuhren & -Sensoren',
  },
  {
    value: '5627',
    label: 'Heimwerkerbedarf > Elektrobedarf > Steckdosenabdeckungen',
  },
  {
    value: '2028',
    label: 'Heimwerkerbedarf > Elektrobedarf > Telefon- & Internetbuchsen',
  },
  {
    value: '4789',
    label: 'Heimwerkerbedarf > Elektrobedarf > Verlängerungskabel',
  },
  {
    value: '6375',
    label: 'Heimwerkerbedarf > Elektrobedarf > Verlängerungskabelzubehör',
  },
  {
    value: '6807',
    label: 'Heimwerkerbedarf > Elektrobedarf > Verteilerkästen',
  },
  {
    value: '7183',
    label: 'Heimwerkerbedarf > Elektrobedarf > Vorschaltgeräte & Starter',
  },
  {
    value: '2377',
    label: 'Heimwerkerbedarf > Elektrobedarf > Wandplatten',
  },
  {
    value: '5142',
    label: 'Heimwerkerbedarf > Elektrobedarf > Wechselrichter',
  },
  {
    value: '4709',
    label: 'Heimwerkerbedarf > Elektrobedarf > Zubehör für Generatoren',
  },
  {
    value: '128',
    label: 'Heimwerkerbedarf > Hauseinzäunung',
  },
  {
    value: '499958',
    label: 'Heimwerkerbedarf > Hauseinzäunung > Absperrungen',
  },
  {
    value: '502986',
    label: 'Heimwerkerbedarf > Hauseinzäunung > Beeteinfassungen',
  },
  {
    value: '1788',
    label: 'Heimwerkerbedarf > Hauseinzäunung > Tore',
  },
  {
    value: '502973',
    label: 'Heimwerkerbedarf > Hauseinzäunung > Zaunfelder',
  },
  {
    value: '502984',
    label: 'Heimwerkerbedarf > Hauseinzäunung > Zaungitter',
  },
  {
    value: '1352',
    label: 'Heimwerkerbedarf > Hauseinzäunung > Zaunlatten',
  },
  {
    value: '1919',
    label: 'Heimwerkerbedarf > Hauseinzäunung > Zaunpfosten & -querlatten',
  },
  {
    value: '502983',
    label: 'Heimwerkerbedarf > Hauseinzäunung > Zubehör für Zäune & Gartentore',
  },
  {
    value: '499873',
    label: 'Heimwerkerbedarf > Heizen, Belüftung & Klimaanlagen',
  },
  {
    value: '500090',
    label:
      'Heimwerkerbedarf > Heizen, Belüftung & Klimaanlagen > Filtertrockner',
  },
  {
    value: '2766',
    label:
      'Heimwerkerbedarf > Heizen, Belüftung & Klimaanlagen > Luftdurchlässe & Abzugsrohre',
  },
  {
    value: '499874',
    label: 'Heimwerkerbedarf > Heizen, Belüftung & Klimaanlagen > Luftkanäle',
  },
  {
    value: '1519',
    label: 'Heimwerkerbedarf > Heizen, Belüftung & Klimaanlagen > Steuerungen',
  },
  {
    value: '500043',
    label:
      'Heimwerkerbedarf > Heizen, Belüftung & Klimaanlagen > Steuerungen > Feuchtigkeitsregler',
  },
  {
    value: '2238',
    label:
      'Heimwerkerbedarf > Heizen, Belüftung & Klimaanlagen > Steuerungen > Schalt- & Bedienungstafeln',
  },
  {
    value: '1897',
    label:
      'Heimwerkerbedarf > Heizen, Belüftung & Klimaanlagen > Steuerungen > Thermostate',
  },
  {
    value: '499982',
    label: 'Heimwerkerbedarf > Kleinmotoren',
  },
  {
    value: '133',
    label: 'Heimwerkerbedarf > Küchen- und Sanitärinstallationen',
  },
  {
    value: '1723',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Brunnenbedarf',
  },
  {
    value: '2216',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Installationsrohre',
  },
  {
    value: '1810',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Rohrverbindungsstücke & -halterungen',
  },
  {
    value: '6732',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Rohrverbindungsstücke & -halterungen > Dichtungen & O-Ringe',
  },
  {
    value: '2611',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Rohrverbindungsstücke & -halterungen > Druckminderer',
  },
  {
    value: '2068',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Rohrverbindungsstücke & -halterungen > Düsen',
  },
  {
    value: '499697',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Rohrverbindungsstücke & -halterungen > In der Wand verbaute Trägerleisten',
  },
  {
    value: '2710',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Rohrverbindungsstücke & -halterungen > Rohranschlüsse & Reduzierstücke',
  },
  {
    value: '1694',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Rohrverbindungsstücke & -halterungen > Rohrflansche',
  },
  {
    value: '2909',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Rohrverbindungsstücke & -halterungen > Rohrkappen & -verschlüsse',
  },
  {
    value: '2634',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Rohrverbindungsstücke & -halterungen > Rohrschellen',
  },
  {
    value: '2359',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Rohrverbindungsstücke & -halterungen > Rohrverbinder',
  },
  {
    value: '2466',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Rohrverbindungsstücke & -halterungen > Ventile',
  },
  {
    value: '2203',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitär-Reparatursätze',
  },
  {
    value: '1673',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitärkeramik & Armaturen',
  },
  {
    value: '2032',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitärkeramik & Armaturen > Armaturen',
  },
  {
    value: '1636',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitärkeramik & Armaturen > Badewannen',
  },
  {
    value: '499999',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitärkeramik & Armaturen > Badezimmerausstattungen',
  },
  {
    value: '7244',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitärkeramik & Armaturen > Duschkabinen',
  },
  {
    value: '2062',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitärkeramik & Armaturen > Toiletten & Bidets',
  },
  {
    value: '2376',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitärkeramik & Armaturen > Toiletten & Bidets > Bidets',
  },
  {
    value: '1921',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitärkeramik & Armaturen > Toiletten & Bidets > Toiletten',
  },
  {
    value: '1746',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitärkeramik & Armaturen > Toiletten & Bidets > Urinale',
  },
  {
    value: '1687',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitärkeramik & Armaturen > Waschbecken & Spülen',
  },
  {
    value: '2886',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitärkeramik & Armaturen > Waschbecken & Spülen > Badezimmerwaschbecken',
  },
  {
    value: '2757',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Sanitärkeramik & Armaturen > Waschbecken & Spülen > Küchenspülen & Spülbecken',
  },
  {
    value: '2570',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Schläuche & Leitungen',
  },
  {
    value: '2273',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Trinkwasserspender & Filter',
  },
  {
    value: '5646',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Trinkwasserspender & Filter > Salz zur Wasserenthärtung',
  },
  {
    value: '1390',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Trinkwasserspender & Filter > Wasserdestillationsgeräte',
  },
  {
    value: '1952',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Trinkwasserspender & Filter > Wasserenthärter',
  },
  {
    value: '2055',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Trinkwasserspender & Filter > Wasserleitungsfilter',
  },
  {
    value: '2343',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Trinkwasserspender & Filter > Wasserspender',
  },
  {
    value: '1821',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Trinkwasserspender & Filter > Wasserspender > Trinkbrunnen',
  },
  {
    value: '1354',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Trinkwasserspender & Filter > Wasserspender > Wasserkühler',
  },
  {
    value: '2171',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Trinkwasserspender & Filter > Zubehör für Wasserfilter',
  },
  {
    value: '2406',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Trinkwasserspender & Filter > Zubehör für Wasserfilter > Wasserfiltergehäuse',
  },
  {
    value: '2063',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Trinkwasserspender & Filter > Zubehör für Wasserfilter > Wasserfilterkartuschen',
  },
  {
    value: '6832',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Wasserschaltuhren',
  },
  {
    value: '2243',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Wasserstandsregler',
  },
  {
    value: '504635',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen',
  },
  {
    value: '504637',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Abflusskomponenten',
  },
  {
    value: '2257',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Abflusskomponenten > Abflussauskleidung bzw. -Abdichtung',
  },
  {
    value: '1514',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Abflusskomponenten > Abflussgitter',
  },
  {
    value: '1932',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Abflusskomponenten > Abflussreinigungswerkzeug',
  },
  {
    value: '2851',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Abflusskomponenten > Abflussstöpsel & -filter',
  },
  {
    value: '1319',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Abflusskomponenten > Geruchsverschlüsse',
  },
  {
    value: '1407',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Abflusskomponenten > Kanal- & Abflussstäbe',
  },
  {
    value: '2170',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Abflusskomponenten > Sanitärabläufe',
  },
  {
    value: '504636',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Abflüsse',
  },
  {
    value: '2206',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Duscharmaturen',
  },
  {
    value: '8320',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Duscharmaturen > Badewannen- & Duschdüsen',
  },
  {
    value: '504638',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Duscharmaturen > Duscharme & Verbindungsteile',
  },
  {
    value: '1779',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Duscharmaturen > Duschkabinen & -türen',
  },
  {
    value: '581',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Duscharmaturen > Duschköpfe',
  },
  {
    value: '2088',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Duscharmaturen > Duschpaneele',
  },
  {
    value: '4728',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Duscharmaturen > Duschwannen',
  },
  {
    value: '7130',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Duscharmaturen > Duschwände',
  },
  {
    value: '8277',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Duscharmaturen > Elektrische & Powerduschen',
  },
  {
    value: '5048',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Duscharmaturen > Wasserfilter für Duschen',
  },
  {
    value: '1458',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Halterungsplatten',
  },
  {
    value: '2691',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Toiletten- und Bidetzubehör',
  },
  {
    value: '504634',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Toiletten- und Bidetzubehör > Bidet-Hähne & -Sprüher',
  },
  {
    value: '1425',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Toiletten- und Bidetzubehör > Kugelhähne & Verschlüsse',
  },
  {
    value: '5666',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Toiletten- und Bidetzubehör > Spülkastenabdeckungen',
  },
  {
    value: '5665',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Toiletten- und Bidetzubehör > Spülkästen',
  },
  {
    value: '7446',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Toiletten- und Bidetzubehör > Toilettendeckel',
  },
  {
    value: '1865',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Toiletten- und Bidetzubehör > Toilettensitze',
  },
  {
    value: '2478',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Toiletten- und Bidetzubehör > Toilettenverkleidung',
  },
  {
    value: '7358',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Toiletten- und Bidetzubehör > WC-Papiersitze',
  },
  {
    value: '2817',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Toiletten- und Bidetzubehör > WC-Spülkastengriffe',
  },
  {
    value: '1963',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Waschbecken- & Spülenzubehör',
  },
  {
    value: '2410',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Waschbecken- & Spülenzubehör > Waschbeckenfüße',
  },
  {
    value: '1489',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Zubehör für Armaturen',
  },
  {
    value: '8116',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Zubehör für Armaturen > Armaturen-Griffe',
  },
  {
    value: '8115',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Zubehör für Armaturen > Strahlregler',
  },
  {
    value: '2996',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Zubehör für Badewannen',
  },
  {
    value: '2463',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Zubehör für Badewannen > Badewanneneinläufe',
  },
  {
    value: '505368',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Zubehör für Badewannen > Badewannenunterbau & -füße',
  },
  {
    value: '5508',
    label:
      'Heimwerkerbedarf > Küchen- und Sanitärinstallationen > Zubehör für sanitäre Anlagen > Zubehör für Badewannen > Badewannenverkleidungen',
  },
  {
    value: '500096',
    label: 'Heimwerkerbedarf > Metallpumpen',
  },
  {
    value: '500097',
    label:
      'Heimwerkerbedarf > Metallpumpen > Bewässerungs-, Sprinkler- und Boosterpumpen',
  },
  {
    value: '500100',
    label: 'Heimwerkerbedarf > Metallpumpen > Brunnenpumpen und -systeme',
  },
  {
    value: '500099',
    label: 'Heimwerkerbedarf > Metallpumpen > Haushaltsgerätepumpen',
  },
  {
    value: '500098',
    label: 'Heimwerkerbedarf > Metallpumpen > Pool-, Brunnen- und Teichpumpen',
  },
  {
    value: '500101',
    label:
      'Heimwerkerbedarf > Metallpumpen > Pumpen zur Entwässerung und Wasserleitung',
  },
  {
    value: '500102',
    label:
      'Heimwerkerbedarf > Metallpumpen > Sickergruben-, Abwasser- und Abflusspumpen',
  },
  {
    value: '1974',
    label: 'Heimwerkerbedarf > Schlösser & Schlüssel',
  },
  {
    value: '1870',
    label: 'Heimwerkerbedarf > Schlösser & Schlüssel > Kartenschließsysteme',
  },
  {
    value: '503730',
    label: 'Heimwerkerbedarf > Schlösser & Schlüssel > Schlösser & Riegel',
  },
  {
    value: '8067',
    label: 'Heimwerkerbedarf > Schlösser & Schlüssel > Schlüsselkappen',
  },
  {
    value: '6488',
    label: 'Heimwerkerbedarf > Schlösser & Schlüssel > Schlüsselrohlinge',
  },
  {
    value: '1910',
    label: 'Heimwerkerbedarf > Vorratsbehälter',
  },
  {
    value: '1167',
    label: 'Heimwerkerbedarf > Werkzeuge',
  },
  {
    value: '1169',
    label: 'Heimwerkerbedarf > Werkzeuge > Ambosse',
  },
  {
    value: '5927',
    label: 'Heimwerkerbedarf > Werkzeuge > Arbeitslampen',
  },
  {
    value: '5592',
    label: 'Heimwerkerbedarf > Werkzeuge > Arbeitsscheinwerfer',
  },
  {
    value: '1193',
    label: 'Heimwerkerbedarf > Werkzeuge > Bauwerkzeuge',
  },
  {
    value: '2305',
    label: 'Heimwerkerbedarf > Werkzeuge > Bauwerkzeuge > Betonmischer',
  },
  {
    value: '1450',
    label: 'Heimwerkerbedarf > Werkzeuge > Bauwerkzeuge > Fugenkellen',
  },
  {
    value: '7484',
    label: 'Heimwerkerbedarf > Werkzeuge > Bauwerkzeuge > Fugenschwamm',
  },
  {
    value: '2337',
    label: 'Heimwerkerbedarf > Werkzeuge > Bauwerkzeuge > Glättkellen',
  },
  {
    value: '4132',
    label: 'Heimwerkerbedarf > Werkzeuge > Bauwerkzeuge > Glättmaschinen',
  },
  {
    value: '1799',
    label:
      'Heimwerkerbedarf > Werkzeuge > Bauwerkzeuge > Kanten- & Schneidwerkzeuge',
  },
  {
    value: '2181',
    label: 'Heimwerkerbedarf > Werkzeuge > Bauwerkzeuge > Maurerkellen',
  },
  {
    value: '1555',
    label: 'Heimwerkerbedarf > Werkzeuge > Bauwerkzeuge > Maurerschnüre',
  },
  {
    value: '1668',
    label: 'Heimwerkerbedarf > Werkzeuge > Bauwerkzeuge > Maurerwerkzeuge',
  },
  {
    value: '4672',
    label: 'Heimwerkerbedarf > Werkzeuge > Betonbesen',
  },
  {
    value: '1667',
    label: 'Heimwerkerbedarf > Werkzeuge > Biegewerkzeuge für Rohre & Stangen',
  },
  {
    value: '4325',
    label: 'Heimwerkerbedarf > Werkzeuge > Bodenverdichter',
  },
  {
    value: '1217',
    label: 'Heimwerkerbedarf > Werkzeuge > Bohrmaschinen',
  },
  {
    value: '1994',
    label: 'Heimwerkerbedarf > Werkzeuge > Bohrmaschinen > Druckluftbohrer',
  },
  {
    value: '2629',
    label:
      'Heimwerkerbedarf > Werkzeuge > Bohrmaschinen > Elektrische Handbohrmaschinen',
  },
  {
    value: '1367',
    label: 'Heimwerkerbedarf > Werkzeuge > Bohrmaschinen > Schneckenbohrer',
  },
  {
    value: '1465',
    label: 'Heimwerkerbedarf > Werkzeuge > Bohrmaschinen > Stemmmaschinen',
  },
  {
    value: '1216',
    label:
      'Heimwerkerbedarf > Werkzeuge > Bohrmaschinen > Ständerbohrmaschinen',
  },
  {
    value: '1179',
    label: 'Heimwerkerbedarf > Werkzeuge > Brecheisen',
  },
  {
    value: '1663',
    label: 'Heimwerkerbedarf > Werkzeuge > Drehmaschinen',
  },
  {
    value: '6461',
    label: 'Heimwerkerbedarf > Werkzeuge > Einziehhilfen',
  },
  {
    value: '6868',
    label: 'Heimwerkerbedarf > Werkzeuge > Elektrohobel',
  },
  {
    value: '1391',
    label: 'Heimwerkerbedarf > Werkzeuge > Entgrater',
  },
  {
    value: '7556',
    label: 'Heimwerkerbedarf > Werkzeuge > Fettpressen',
  },
  {
    value: '503774',
    label: 'Heimwerkerbedarf > Werkzeuge > Feuerzeuge & Streichhölzer',
  },
  {
    value: '1184',
    label: 'Heimwerkerbedarf > Werkzeuge > Gewindebohrer',
  },
  {
    value: '1584',
    label: 'Heimwerkerbedarf > Werkzeuge > Gewindeschneidmaschinen',
  },
  {
    value: '1603',
    label: 'Heimwerkerbedarf > Werkzeuge > Glühbirnenwechsler',
  },
  {
    value: '1185',
    label: 'Heimwerkerbedarf > Werkzeuge > Griffe',
  },
  {
    value: '3932',
    label: 'Heimwerkerbedarf > Werkzeuge > Hacken & Spitzhacken',
  },
  {
    value: '499887',
    label: 'Heimwerkerbedarf > Werkzeuge > Handmischmaschinen',
  },
  {
    value: '1469',
    label: 'Heimwerkerbedarf > Werkzeuge > Handwerkzeuge für Drähte und Kabel',
  },
  {
    value: '1220',
    label: 'Heimwerkerbedarf > Werkzeuge > Heißluftpistole',
  },
  {
    value: '1187',
    label: 'Heimwerkerbedarf > Werkzeuge > Hobel',
  },
  {
    value: '7030',
    label: 'Heimwerkerbedarf > Werkzeuge > Holzspalter',
  },
  {
    value: '1186',
    label: 'Heimwerkerbedarf > Werkzeuge > Hämmer',
  },
  {
    value: '2208',
    label: 'Heimwerkerbedarf > Werkzeuge > Hämmer > Manuelle Hämmer',
  },
  {
    value: '505364',
    label: 'Heimwerkerbedarf > Werkzeuge > Hämmer > Maschinenbetriebene Hämmer',
  },
  {
    value: '2456',
    label: 'Heimwerkerbedarf > Werkzeuge > Industrievibratoren',
  },
  {
    value: '2792',
    label: 'Heimwerkerbedarf > Werkzeuge > Kaminbürste',
  },
  {
    value: '1202',
    label: 'Heimwerkerbedarf > Werkzeuge > Kittmesser & -spachtel',
  },
  {
    value: '2015',
    label: 'Heimwerkerbedarf > Werkzeuge > Kompressoren',
  },
  {
    value: '7416',
    label: 'Heimwerkerbedarf > Werkzeuge > Kontrollspiegel',
  },
  {
    value: '5873',
    label: 'Heimwerkerbedarf > Werkzeuge > Lasthebemagnete',
  },
  {
    value: '130',
    label: 'Heimwerkerbedarf > Werkzeuge > Leitern & Gerüste',
  },
  {
    value: '1809',
    label:
      'Heimwerkerbedarf > Werkzeuge > Leitern & Gerüste > Arbeitsplattformen',
  },
  {
    value: '1866',
    label: 'Heimwerkerbedarf > Werkzeuge > Leitern & Gerüste > Gerüste',
  },
  {
    value: '6928',
    label: 'Heimwerkerbedarf > Werkzeuge > Leitern & Gerüste > Leitern',
  },
  {
    value: '2416',
    label: 'Heimwerkerbedarf > Werkzeuge > Leitern & Gerüste > Leiterwagen',
  },
  {
    value: '635',
    label: 'Heimwerkerbedarf > Werkzeuge > Leitern & Gerüste > Tritthocker',
  },
  {
    value: '505315',
    label: 'Heimwerkerbedarf > Werkzeuge > Locheisen & Ahlen',
  },
  {
    value: '3501',
    label: 'Heimwerkerbedarf > Werkzeuge > Lochschaufeln',
  },
  {
    value: '1236',
    label: 'Heimwerkerbedarf > Werkzeuge > Lötkolben',
  },
  {
    value: '2077',
    label: 'Heimwerkerbedarf > Werkzeuge > Malerwerkzeug',
  },
  {
    value: '2486',
    label: 'Heimwerkerbedarf > Werkzeuge > Malerwerkzeug > Airbrushpistolen',
  },
  {
    value: '1774',
    label: 'Heimwerkerbedarf > Werkzeuge > Malerwerkzeug > Farbrollen',
  },
  {
    value: '499888',
    label: 'Heimwerkerbedarf > Werkzeuge > Malerwerkzeug > Farbrüttler',
  },
  {
    value: '505325',
    label: 'Heimwerkerbedarf > Werkzeuge > Malerwerkzeug > Farbsiebe',
  },
  {
    value: '2465',
    label: 'Heimwerkerbedarf > Werkzeuge > Malerwerkzeug > Farbspritzpistolen',
  },
  {
    value: '6557',
    label: 'Heimwerkerbedarf > Werkzeuge > Malerwerkzeug > Farbwannen',
  },
  {
    value: '6556',
    label: 'Heimwerkerbedarf > Werkzeuge > Malerwerkzeug > Kantenmaler',
  },
  {
    value: '1300',
    label: 'Heimwerkerbedarf > Werkzeuge > Malerwerkzeug > Malerpinsel',
  },
  {
    value: '1699',
    label: 'Heimwerkerbedarf > Werkzeuge > Malerwerkzeug > Malschwämme',
  },
  {
    value: '1832',
    label: 'Heimwerkerbedarf > Werkzeuge > Markierwerkzeuge',
  },
  {
    value: '1305',
    label: 'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber',
  },
  {
    value: '1207',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Balkensucher',
  },
  {
    value: '3602',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Barometer',
  },
  {
    value: '1640',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Durchflussmesser',
  },
  {
    value: '503737',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Elektrische Prüfgeräte',
  },
  {
    value: '5487',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Entfernungsmesser',
  },
  {
    value: '4755',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Feuchtigkeitsmesser',
  },
  {
    value: '1991',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Gasdetektoren',
  },
  {
    value: '5371',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Geigerzähler',
  },
  {
    value: '1413',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Haarlineale',
  },
  {
    value: '4754',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Hygrometer',
  },
  {
    value: '4022',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Höhenmesser',
  },
  {
    value: '4506',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Infrarotthermometer',
  },
  {
    value: '1533',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Kluppen',
  },
  {
    value: '4757',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Lautstärkemesser',
  },
  {
    value: '2481',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Maßbänder',
  },
  {
    value: '2330',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Messerführungen',
  },
  {
    value: '1732',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Messinstrumente',
  },
  {
    value: '1459',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Messräder',
  },
  {
    value: '2192',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Messschieber',
  },
  {
    value: '5515',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Raumluftmessgeräte',
  },
  {
    value: '1191',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Richtwaagen',
  },
  {
    value: '4931',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Richtwaagen > Laser-Wasserwaagen',
  },
  {
    value: '4294',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Richtwaagen > Schaugläser',
  },
  {
    value: '4081',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Richtwaagen > Wasserwaagen',
  },
  {
    value: '4759',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Schwingungsmesser',
  },
  {
    value: '4756',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Seismographen',
  },
  {
    value: '1785',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Sonden & Suchgeräte',
  },
  {
    value: '1539',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Stahlbewehrungssucher',
  },
  {
    value: '1850',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Stangenzirkel',
  },
  {
    value: '4340',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Theodolite',
  },
  {
    value: '2093',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Thermoelemente & -säulen',
  },
  {
    value: '7394',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Transducer & Messumformer',
  },
  {
    value: '4758',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > UV-Lichtmesser',
  },
  {
    value: '1698',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Waagen',
  },
  {
    value: '1374',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Wetterstationen',
  },
  {
    value: '500058',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Windgeschwindigkeitsmesser',
  },
  {
    value: '1205',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Winkel',
  },
  {
    value: '1198',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Winkelmesser',
  },
  {
    value: '6799',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Wärmekameras',
  },
  {
    value: '2021',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > Zollstöcke',
  },
  {
    value: '4074',
    label:
      'Heimwerkerbedarf > Werkzeuge > Messwerkzeuge & Messwertgeber > pH-Meter',
  },
  {
    value: '5587',
    label: 'Heimwerkerbedarf > Werkzeuge > Multifunktionales Elektrowerkzeug',
  },
  {
    value: '5077',
    label: 'Heimwerkerbedarf > Werkzeuge > Mühlen',
  },
  {
    value: '1196',
    label: 'Heimwerkerbedarf > Werkzeuge > Müll- & Papierkrallen',
  },
  {
    value: '1194',
    label: 'Heimwerkerbedarf > Werkzeuge > Nageleisen',
  },
  {
    value: '1206',
    label: 'Heimwerkerbedarf > Werkzeuge > Nagler & Tacker',
  },
  {
    value: '7064',
    label: 'Heimwerkerbedarf > Werkzeuge > Nietwerkzeuge',
  },
  {
    value: '7065',
    label: 'Heimwerkerbedarf > Werkzeuge > Nietwerkzeuge > Nietpistolen',
  },
  {
    value: '7066',
    label: 'Heimwerkerbedarf > Werkzeuge > Nietwerkzeuge > Nietzangen',
  },
  {
    value: '1841',
    label: 'Heimwerkerbedarf > Werkzeuge > Oberfräsen',
  },
  {
    value: '1819',
    label: 'Heimwerkerbedarf > Werkzeuge > Reibahlen',
  },
  {
    value: '1862',
    label: 'Heimwerkerbedarf > Werkzeuge > Rohrbürsten',
  },
  {
    value: '2053',
    label: 'Heimwerkerbedarf > Werkzeuge > Rohrreiniger',
  },
  {
    value: '126',
    label: 'Heimwerkerbedarf > Werkzeuge > Rollwagen & Sackkarren',
  },
  {
    value: '6938',
    label: 'Heimwerkerbedarf > Werkzeuge > Sandstrahler',
  },
  {
    value: '1563',
    label: 'Heimwerkerbedarf > Werkzeuge > Saugglocken',
  },
  {
    value: '1221',
    label: 'Heimwerkerbedarf > Werkzeuge > Schlagschrauber',
  },
  {
    value: '4419',
    label: 'Heimwerkerbedarf > Werkzeuge > Schleifblöcke',
  },
  {
    value: '1225',
    label: 'Heimwerkerbedarf > Werkzeuge > Schleifer & Poliermaschinen',
  },
  {
    value: '1219',
    label: 'Heimwerkerbedarf > Werkzeuge > Schleifmaschinen',
  },
  {
    value: '1180',
    label: 'Heimwerkerbedarf > Werkzeuge > Schneidegeräte',
  },
  {
    value: '1824',
    label:
      'Heimwerkerbedarf > Werkzeuge > Schneidegeräte > Betonstahlschneider',
  },
  {
    value: '1181',
    label: 'Heimwerkerbedarf > Werkzeuge > Schneidegeräte > Bolzenschneider',
  },
  {
    value: '2411',
    label: 'Heimwerkerbedarf > Werkzeuge > Schneidegeräte > Cutter',
  },
  {
    value: '2726',
    label:
      'Heimwerkerbedarf > Werkzeuge > Schneidegeräte > Fliesen-, Ziegel- & Schindelschneider',
  },
  {
    value: '1182',
    label: 'Heimwerkerbedarf > Werkzeuge > Schneidegeräte > Glasschneider',
  },
  {
    value: '1454',
    label:
      'Heimwerkerbedarf > Werkzeuge > Schneidegeräte > Metallscheren & Nibbelmaschinen',
  },
  {
    value: '2080',
    label: 'Heimwerkerbedarf > Werkzeuge > Schneidegeräte > Rohrschneider',
  },
  {
    value: '7562',
    label: 'Heimwerkerbedarf > Werkzeuge > Schneidegeräte > Zwickzangen',
  },
  {
    value: '1174',
    label: 'Heimwerkerbedarf > Werkzeuge > Schnitzwerkzeuge & Hohlmeißel',
  },
  {
    value: '1203',
    label: 'Heimwerkerbedarf > Werkzeuge > Schraubendreher',
  },
  {
    value: '1632',
    label: 'Heimwerkerbedarf > Werkzeuge > Schraubenschlüssel',
  },
  {
    value: '2835',
    label: 'Heimwerkerbedarf > Werkzeuge > Schraubzwingen & -stöcke',
  },
  {
    value: '1238',
    label: 'Heimwerkerbedarf > Werkzeuge > Schweißwerkzeuge & Plasmaschneider',
  },
  {
    value: '1188',
    label: 'Heimwerkerbedarf > Werkzeuge > Schwingschleifer',
  },
  {
    value: '1644',
    label: 'Heimwerkerbedarf > Werkzeuge > Schärfgeräte',
  },
  {
    value: '1195',
    label: 'Heimwerkerbedarf > Werkzeuge > Steckschlüssel',
  },
  {
    value: '1215',
    label: 'Heimwerkerbedarf > Werkzeuge > Stemmwerkzeuge',
  },
  {
    value: '1923',
    label: 'Heimwerkerbedarf > Werkzeuge > Stoßmaschinen',
  },
  {
    value: '1201',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägeböcke',
  },
  {
    value: '1235',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägen',
  },
  {
    value: '3582',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägen > Bandsägen',
  },
  {
    value: '4633',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägen > Dekupiersägen',
  },
  {
    value: '7077',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägen > Fliesensägen',
  },
  {
    value: '3517',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägen > Gehrungssägen',
  },
  {
    value: '3224',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägen > Handkreissägen',
  },
  {
    value: '3594',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägen > Handsägen',
  },
  {
    value: '499985',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägen > Plattensägen',
  },
  {
    value: '3725',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägen > Stichsägen',
  },
  {
    value: '3494',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägen > Säbelsägen',
  },
  {
    value: '3706',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägen > Tischkreissägen',
  },
  {
    value: '3516',
    label: 'Heimwerkerbedarf > Werkzeuge > Sägen > Trennsägen',
  },
  {
    value: '338',
    label: 'Heimwerkerbedarf > Werkzeuge > Taschenlampen & Stirnlampen',
  },
  {
    value: '7271',
    label: 'Heimwerkerbedarf > Werkzeuge > Tischlerbänke',
  },
  {
    value: '1787',
    label:
      'Heimwerkerbedarf > Werkzeuge > Wasseranschluss-Nachschleifwerkzeuge',
  },
  {
    value: '3745',
    label: 'Heimwerkerbedarf > Werkzeuge > Werkzeugfeilen',
  },
  {
    value: '2198',
    label: 'Heimwerkerbedarf > Werkzeuge > Werkzeugmesser',
  },
  {
    value: '1439',
    label: 'Heimwerkerbedarf > Werkzeuge > Werkzeugschlüssel',
  },
  {
    value: '4919',
    label: 'Heimwerkerbedarf > Werkzeuge > Werkzeugsets',
  },
  {
    value: '4716',
    label:
      'Heimwerkerbedarf > Werkzeuge > Werkzeugsets > Elektrowerkzeug-Combo-Sets',
  },
  {
    value: '6965',
    label: 'Heimwerkerbedarf > Werkzeuge > Werkzeugsets > Handwerkzeugsets',
  },
  {
    value: '1958',
    label: 'Heimwerkerbedarf > Werkzeuge > Zangen',
  },
  {
    value: '1171',
    label: 'Heimwerkerbedarf > Werkzeuge > Äxte',
  },
  {
    value: '5828',
    label: 'Heimwerkerbedarf > Werkzeuge > Ölfilterabfluss',
  },
  {
    value: '3650',
    label: 'Heimwerkerbedarf > Werkzeugzubehör',
  },
  {
    value: '7019',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Anstreichzubehör',
  },
  {
    value: '6887',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Anstreichzubehör > Airbrushzubehör',
  },
  {
    value: '7020',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Anstreichzubehör > Anstreichrollenzubehör',
  },
  {
    value: '328062',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Anstreichzubehör > Pinselreiniger',
  },
  {
    value: '7326',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Axtzubehör',
  },
  {
    value: '7370',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Axtzubehör > Axtgriffe',
  },
  {
    value: '7369',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Axtzubehör > Axtköpfe',
  },
  {
    value: '3944',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Bohrzubehör',
  },
  {
    value: '1540',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Bohrzubehör > Bohrer & Bits',
  },
  {
    value: '6378',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Bohrzubehör > Bohrerschleifgeräte',
  },
  {
    value: '7140',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Bohrzubehör > Bohrerverlängerungen',
  },
  {
    value: '8276',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Bohrzubehör > Bohrfutter',
  },
  {
    value: '8275',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Bohrzubehör > Bohrständer & -führungen',
  },
  {
    value: '6806',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Bohrzubehör > Lochsägen',
  },
  {
    value: '6471',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Drehmaschinenzubehör',
  },
  {
    value: '6295',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Elektrowerkzeug-Akkus',
  },
  {
    value: '7056',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Hammerzubehör',
  },
  {
    value: '7087',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Hammerzubehör > Drucklufthammerzubehör',
  },
  {
    value: '7057',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Hammerzubehör > Hammerköpfe',
  },
  {
    value: '7055',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Hammerzubehör > Hammerstiele',
  },
  {
    value: '505810',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Keile für Werkzeuggriffe',
  },
  {
    value: '505812',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Keilwerkzeuge',
  },
  {
    value: '2380',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Klammern',
  },
  {
    value: '6292',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Ladegeräte für Elektrowerkzeuge',
  },
  {
    value: '7472',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Magnetisierer & Entmagnetisierer',
  },
  {
    value: '499886',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Mischwerkzeuge',
  },
  {
    value: '6939',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Sandstrahler-Zubehör',
  },
  {
    value: '6940',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Sandstrahler-Zubehör > Sandstrahlkabinen',
  },
  {
    value: '499859',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Schleifmaschinenzubehör',
  },
  {
    value: '499860',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Schleifmaschinenzubehör > Schleifscheiben & -stifte',
  },
  {
    value: '4487',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Schleifzubehör',
  },
  {
    value: '3240',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Schleifzubehör > Schleifpapier & Schleifschwämme',
  },
  {
    value: '8117',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Schneidwerkzeug-Zubehör',
  },
  {
    value: '8118',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Schneidwerkzeug-Zubehör > Nibbelmaschinenstempel',
  },
  {
    value: '499947',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Schweißzubehör',
  },
  {
    value: '6907',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Spannvorrichtungen',
  },
  {
    value: '5571',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Steckschlüsseleinsätze',
  },
  {
    value: '6549',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Sägenzubehör',
  },
  {
    value: '7515',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Sägenzubehör > Bandsägenzubehör',
  },
  {
    value: '7345',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Sägenzubehör > Handkreissägen-Zubehör',
  },
  {
    value: '6503',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Sägenzubehör > Kreissägenzubehör',
  },
  {
    value: '7346',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Sägenzubehör > Laubsägenzubehör',
  },
  {
    value: '6501',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Sägenzubehör > Tischsägenzubehör',
  },
  {
    value: '2174',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Werkzeugklingen & -blätter',
  },
  {
    value: '505831',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Werkzeugklingen & -blätter > Cutter-, Schaber- & Spachtelklingen',
  },
  {
    value: '2202',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Werkzeugklingen & -blätter > Sägeblätter',
  },
  {
    value: '8258',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Werkzeugsicherungen',
  },
  {
    value: '4658',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Werkzeugständer',
  },
  {
    value: '4659',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Werkzeugständer > Sägeständer',
  },
  {
    value: '505323',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Hacken & Pickel',
  },
  {
    value: '505324',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Hacken & Pickel > Griffe für Hacken & Pickel',
  },
  {
    value: '3281',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Lötkolben',
  },
  {
    value: '3609',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Lötkolben > Lötkolbenspitzen',
  },
  {
    value: '3629',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Lötkolben > Lötkolbenständer',
  },
  {
    value: '5526',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Messwerkzeuge & Messwertgeber',
  },
  {
    value: '7415',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Messwerkzeuge & Messwertgeber > Multimeter-Zubehör',
  },
  {
    value: '5556',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Messwerkzeuge & Messwertgeber > Zubehör für Gasdetektoren',
  },
  {
    value: '503007',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Messwerkzeuge & Messwertgeber > Zubehör für Messskalen',
  },
  {
    value: '5557',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Messwerkzeuge & Messwertgeber > Zubehör für Spannungsmesser',
  },
  {
    value: '3744',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Oberfräsen',
  },
  {
    value: '3673',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Oberfräsen > Oberfräsenteile',
  },
  {
    value: '3300',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Oberfräsen > Oberfräsentische',
  },
  {
    value: '3470',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Stoßmaschinen',
  },
  {
    value: '3210',
    label:
      'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Stoßmaschinen > Stoßmaschinenwerkzeuge',
  },
  {
    value: '2447',
    label: 'Heimwerkerbedarf > Werkzeugzubehör > Zubehör für Taschenlampen',
  },
  {
    value: '141',
    label: 'Kameras & Optik',
  },
  {
    value: '39',
    label: 'Kameras & Optik > Fotografie',
  },
  {
    value: '4368',
    label:
      'Kameras & Optik > Fotografie > Aufbewahrung für Negative & Kontaktbögen',
  },
  {
    value: '42',
    label: 'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf',
  },
  {
    value: '1548',
    label:
      'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf > Belichtungsmesser',
  },
  {
    value: '2475',
    label:
      'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf > Lichtsteuerung',
  },
  {
    value: '3056',
    label:
      'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf > Lichtsteuerung > Blitzdiffusoren',
  },
  {
    value: '5431',
    label:
      'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf > Lichtsteuerung > Blitzreflektoren',
  },
  {
    value: '2490',
    label:
      'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf > Lichtsteuerung > Lichtfilter & Filterfolien',
  },
  {
    value: '5432',
    label:
      'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf > Lichtsteuerung > Softboxen',
  },
  {
    value: '2007',
    label:
      'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf > Studio-Halterungen',
  },
  {
    value: '1611',
    label:
      'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf > Studiohintergründe',
  },
  {
    value: '2926',
    label:
      'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf > Studioscheinwerfer & -blitzgeräte',
  },
  {
    value: '5499',
    label:
      'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf > Zubehör für Belichtungsmesser',
  },
  {
    value: '503018',
    label:
      'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf > Zubehör für Studiobeleuchtung & Blitzgeräte',
  },
  {
    value: '503017',
    label:
      'Kameras & Optik > Fotografie > Beleuchtung & Studiobedarf > Zubehör für Studioständer & -halterungen',
  },
  {
    value: '41',
    label: 'Kameras & Optik > Fotografie > Dunkelkammer',
  },
  {
    value: '2234',
    label:
      'Kameras & Optik > Fotografie > Dunkelkammer > Ausrüstung, Entwicklung & Verarbeitung',
  },
  {
    value: '2999',
    label:
      'Kameras & Optik > Fotografie > Dunkelkammer > Ausrüstung, Entwicklung & Verarbeitung > Dunkelkammerbecken',
  },
  {
    value: '2728',
    label:
      'Kameras & Optik > Fotografie > Dunkelkammer > Ausrüstung, Entwicklung & Verarbeitung > Entwicklungsschalen, Wascher & Trockner',
  },
  {
    value: '2650',
    label:
      'Kameras & Optik > Fotografie > Dunkelkammer > Ausrüstung, Entwicklung & Verarbeitung > Entwicklungstanks & -spiralen',
  },
  {
    value: '2625',
    label:
      'Kameras & Optik > Fotografie > Dunkelkammer > Ausrüstung, Entwicklung & Verarbeitung > Reprostative',
  },
  {
    value: '2516',
    label:
      'Kameras & Optik > Fotografie > Dunkelkammer > Ausrüstung, Entwicklung & Verarbeitung > Retuschierbedarf & -zubehör',
  },
  {
    value: '2600',
    label: 'Kameras & Optik > Fotografie > Dunkelkammer > Dunkelkammerleuchten',
  },
  {
    value: '1622',
    label: 'Kameras & Optik > Fotografie > Dunkelkammer > Fotochemikalien',
  },
  {
    value: '2804',
    label: 'Kameras & Optik > Fotografie > Dunkelkammer > Fotopapier',
  },
  {
    value: '2520',
    label: 'Kameras & Optik > Fotografie > Dunkelkammer > Vergrößerungszubehör',
  },
  {
    value: '2543',
    label:
      'Kameras & Optik > Fotografie > Dunkelkammer > Vergrößerungszubehör > Dunkelkammer-Timer',
  },
  {
    value: '2969',
    label:
      'Kameras & Optik > Fotografie > Dunkelkammer > Vergrößerungszubehör > Dunkelkammerstaffeleien',
  },
  {
    value: '3029',
    label:
      'Kameras & Optik > Fotografie > Dunkelkammer > Vergrößerungszubehör > Fokussierhilfen',
  },
  {
    value: '2698',
    label:
      'Kameras & Optik > Fotografie > Dunkelkammer > Vergrößerungszubehör > Fotovergrößerer',
  },
  {
    value: '2815',
    label:
      'Kameras & Optik > Fotografie > Dunkelkammer > Vergrößerungszubehör > Lichtmesser',
  },
  {
    value: '503735',
    label: 'Kameras & Optik > Fotografie > Passepartouts & Aufziehmaterialien',
  },
  {
    value: '2096',
    label: 'Kameras & Optik > Kamera- & Optisches Zubehör',
  },
  {
    value: '149',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamera- & Video-Objektive',
  },
  {
    value: '4432',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamera- & Video-Objektive > Kameraobjektive',
  },
  {
    value: '5346',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamera- & Video-Objektive > Objektive für Überwachungskameras',
  },
  {
    value: '5280',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamera- & Video-Objektive > Videokamera-Objektive',
  },
  {
    value: '463625',
    label: 'Kameras & Optik > Kamera- & Optisches Zubehör > Kamera-Ersatzkabel',
  },
  {
    value: '143',
    label: 'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör',
  },
  {
    value: '296248',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Bildsensoren',
  },
  {
    value: '2987',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Blitzhaltesysteme',
  },
  {
    value: '298420',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Digitale Rückteile',
  },
  {
    value: '296249',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Ersatzbildschirme & -Displays',
  },
  {
    value: '296246',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Ersatzgehäuseteile & -klappen',
  },
  {
    value: '296247',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Ersatztasten & -knöpfe',
  },
  {
    value: '500104',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Fokusvorrichtungen für Kameras',
  },
  {
    value: '148',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Kamera-Blitzgeräte',
  },
  {
    value: '5479',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Kamera-Blitzlichtzubehör',
  },
  {
    value: '5532',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Kamera-Fernbedienungen',
  },
  {
    value: '461567',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Kameraausrüstungen',
  },
  {
    value: '153',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Kamerafilme',
  },
  {
    value: '5429',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Kameragurte',
  },
  {
    value: '500037',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Kamerahaltegriffe',
  },
  {
    value: '500107',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Kameramonitore',
  },
  {
    value: '499998',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Kamerastabilisatoren und -stützen',
  },
  {
    value: '6308',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Kamerataschen & -koffer',
  },
  {
    value: '8174',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Kamerazubehör-Sets',
  },
  {
    value: '503020',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Schalldämmung & Sound Blimps für Kameras',
  },
  {
    value: '503019',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Sonnenschutz für Kameras & Sucheraufsätze',
  },
  {
    value: '6307',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Unterwassergehäuse für Kameras und Videokameras',
  },
  {
    value: '2394',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Videokameraleuchten',
  },
  {
    value: '461568',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Zoomkomponenten für Objektive',
  },
  {
    value: '8535',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Zubehör für Unterwasserkameragehäuse',
  },
  {
    value: '5937',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Kamerazubehör > Überwachungskamera-Zubehör',
  },
  {
    value: '160',
    label: 'Kameras & Optik > Kamera- & Optisches Zubehör > Optisches Zubehör',
  },
  {
    value: '5282',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Optisches Zubehör > Fernglas- & Fernrohrzubehör',
  },
  {
    value: '5545',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Optisches Zubehör > Taschen & Koffer für optische Instrumente',
  },
  {
    value: '4274',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Optisches Zubehör > Wärmebildzubehör',
  },
  {
    value: '5283',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Optisches Zubehör > Zubehör für Entferungsmesser',
  },
  {
    value: '5542',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Optisches Zubehör > Zubehör für Spektive',
  },
  {
    value: '5284',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Optisches Zubehör > Zubehör für Teleskope',
  },
  {
    value: '5543',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Optisches Zubehör > Zubehör für Zielfernrohre',
  },
  {
    value: '150',
    label: 'Kameras & Optik > Kamera- & Optisches Zubehör > Stative',
  },
  {
    value: '2911',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Kameraobjektive',
  },
  {
    value: '5588',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Kameraobjektive > Objektiv- & Filteradapter',
  },
  {
    value: '2829',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Kameraobjektive > Objektivabdeckungen',
  },
  {
    value: '147',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Kameraobjektive > Objektivfilter',
  },
  {
    value: '4416',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Kameraobjektive > Objektivkonverter',
  },
  {
    value: '4441',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Kameraobjektive > Objektivtaschen',
  },
  {
    value: '2627',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Kameraobjektive > Streulichtblenden',
  },
  {
    value: '4638',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Stative',
  },
  {
    value: '3035',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Stative > Schellen & Halterungen für Stative',
  },
  {
    value: '4640',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Stative > Stativaufbewahrung',
  },
  {
    value: '503726',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Stative > Stativgriffe',
  },
  {
    value: '4639',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Stative > Stativköpfe',
  },
  {
    value: '503016',
    label:
      'Kameras & Optik > Kamera- & Optisches Zubehör > Zubehör für Stative > Stativspinnen',
  },
  {
    value: '142',
    label: 'Kameras & Optik > Kameras',
  },
  {
    value: '154',
    label: 'Kameras & Optik > Kameras > Analogkameras',
  },
  {
    value: '499976',
    label: 'Kameras & Optik > Kameras > Boroskope',
  },
  {
    value: '152',
    label: 'Kameras & Optik > Kameras > Digitalkameras',
  },
  {
    value: '4024',
    label: 'Kameras & Optik > Kameras > Einwegkameras',
  },
  {
    value: '155',
    label: 'Kameras & Optik > Kameras > Videokameras',
  },
  {
    value: '312',
    label: 'Kameras & Optik > Kameras > Webcams',
  },
  {
    value: '5402',
    label: 'Kameras & Optik > Kameras > Wildkameras',
  },
  {
    value: '362',
    label: 'Kameras & Optik > Kameras > Überwachungskameras',
  },
  {
    value: '156',
    label: 'Kameras & Optik > Optische Geräte',
  },
  {
    value: '157',
    label: 'Kameras & Optik > Optische Geräte > Ferngläser',
  },
  {
    value: '4040',
    label: 'Kameras & Optik > Optische Geräte > Fernrohre',
  },
  {
    value: '4136',
    label: 'Kameras & Optik > Optische Geräte > Fernrohre > Spektive',
  },
  {
    value: '165',
    label: 'Kameras & Optik > Optische Geräte > Fernrohre > Teleskope',
  },
  {
    value: '1695',
    label:
      'Kameras & Optik > Optische Geräte > Fernrohre > Waffenspektive & Zielfernrohre',
  },
  {
    value: '4164',
    label: 'Kameras & Optik > Optische Geräte > Monokulare',
  },
  {
    value: '161',
    label: 'Kameras & Optik > Optische Geräte > Optische Entfernungsmesser',
  },
  {
    value: '8',
    label: 'Kunst & Unterhaltung',
  },
  {
    value: '499969',
    label: 'Kunst & Unterhaltung > Eintrittskarten',
  },
  {
    value: '5710',
    label: 'Kunst & Unterhaltung > Hobby & Kunst',
  },
  {
    value: '33',
    label: 'Kunst & Unterhaltung > Hobby & Kunst > Jonglieren',
  },
  {
    value: '16',
    label: 'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby',
  },
  {
    value: '505369',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Aufbewahrung für Kunst- & Bastelmaterial',
  },
  {
    value: '499971',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Aufbewahrung für Kunst- & Bastelmaterial > Arbeitssets für Näh- und Textilkunstarbeiten',
  },
  {
    value: '505395',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Aufbewahrung für Kunst- & Bastelmaterial > Aufbewahrung für Fäden & Garne',
  },
  {
    value: '505394',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Aufbewahrung für Kunst- & Bastelmaterial > Aufbewahrung für Näh-, Strick- & Häkelnadeln',
  },
  {
    value: '505372',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien',
  },
  {
    value: '505377',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Basteldraht',
  },
  {
    value: '505418',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Basteldraht > Blumendraht',
  },
  {
    value: '5062',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Basteldraht > Pfeifenreiniger',
  },
  {
    value: '6102',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Basteldraht > Schmuck- & Perlendraht',
  },
  {
    value: '505378',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelfarben, -tinten & -lacke',
  },
  {
    value: '500094',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelfarben, -tinten & -lacke > Fixative',
  },
  {
    value: '505415',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelfarben, -tinten & -lacke > Färbemittel für Kunstarbeiten',
  },
  {
    value: '499879',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelfarben, -tinten & -lacke > Keramik- & Tonglasuren',
  },
  {
    value: '505417',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelfarben, -tinten & -lacke > Kunst- & Bastelfarben',
  },
  {
    value: '6558',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelfarben, -tinten & -lacke > Malgründe',
  },
  {
    value: '505414',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelfarben, -tinten & -lacke > Stempelkissen',
  },
  {
    value: '505416',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelfarben, -tinten & -lacke > Tinte für Kunstarbeiten',
  },
  {
    value: '505376',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelklebstoffe & -magnete',
  },
  {
    value: '6418',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelklebstoffe & -magnete > Aufbügelbänder',
  },
  {
    value: '503745',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelklebstoffe & -magnete > Bastel- & Büroklebstoff',
  },
  {
    value: '36',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelklebstoffe & -magnete > Bastelmagnete',
  },
  {
    value: '7192',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelklebstoffe & -magnete > Blumenklebeband',
  },
  {
    value: '505419',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelklebstoffe & -magnete > Deko-Klebeband',
  },
  {
    value: '505382',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelwerkstoffe',
  },
  {
    value: '2669',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelwerkstoffe > Garne',
  },
  {
    value: '6140',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelwerkstoffe > Rohwolle',
  },
  {
    value: '6540',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelwerkstoffe > Schmuck- & Perlenband',
  },
  {
    value: '49',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Bastelwerkstoffe > Zwirn',
  },
  {
    value: '505380',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Befestigungen & Verschlüsse',
  },
  {
    value: '6145',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Befestigungen & Verschlüsse > Klettverschlüsse',
  },
  {
    value: '4226',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Befestigungen & Verschlüsse > Knöpfe',
  },
  {
    value: '500056',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Befestigungen & Verschlüsse > Reißverschlussziehgriffe',
  },
  {
    value: '4174',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Befestigungen & Verschlüsse > Reißverschlüsse',
  },
  {
    value: '505408',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Befestigungen & Verschlüsse > Schnallen & Haken',
  },
  {
    value: '505409',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Befestigungen & Verschlüsse > Ösen & Tüllen',
  },
  {
    value: '7402',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Dochte',
  },
  {
    value: '7403',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Dochthalter',
  },
  {
    value: '505381',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Formen & Rohlinge für Kunstarbeiten',
  },
  {
    value: '505404',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Formen & Rohlinge für Kunstarbeiten > Holz & Formen für Kunstarbeiten',
  },
  {
    value: '504419',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Formen & Rohlinge für Kunstarbeiten > Kranz- & Gesteckunterlagen',
  },
  {
    value: '6117',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Formen & Rohlinge für Kunstarbeiten > Moosgummi & Styropor',
  },
  {
    value: '505403',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Formen & Rohlinge für Kunstarbeiten > Pappmaschee-Formen',
  },
  {
    value: '6142',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Füllmaterial',
  },
  {
    value: '505406',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Füllmaterial > Füllgranulate',
  },
  {
    value: '505405',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Füllmaterial > Kissenformen',
  },
  {
    value: '505407',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Füllmaterial > Wattierung & Füllung',
  },
  {
    value: '505383',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Leder & Vinyl',
  },
  {
    value: '24',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Papier für Kunstarbeiten',
  },
  {
    value: '8168',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Papier für Kunstarbeiten > Bastelfolie',
  },
  {
    value: '2532',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Papier für Kunstarbeiten > Bastelpapier',
  },
  {
    value: '505399',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Papier für Kunstarbeiten > Kartenkarton & Scrapbooking-Papier',
  },
  {
    value: '2967',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Papier für Kunstarbeiten > Origami-Papier',
  },
  {
    value: '2741',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Papier für Kunstarbeiten > Pergament',
  },
  {
    value: '6110',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Papier für Kunstarbeiten > Transferpapier',
  },
  {
    value: '505400',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Papier für Kunstarbeiten > Zeichen- & Malpapier',
  },
  {
    value: '6121',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Prägepuder',
  },
  {
    value: '505375',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Rohes Kerzenwachs',
  },
  {
    value: '505384',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Textilien',
  },
  {
    value: '505397',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Textilien > Bastelleinwände',
  },
  {
    value: '19',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Textilien > Bastelleinwände > Leinwände & Keilrahmen',
  },
  {
    value: '6144',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Textilien > Bastelleinwände > Plastiksegeltuch',
  },
  {
    value: '505398',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Textilien > Bastelleinwände > Stickleinwände',
  },
  {
    value: '505396',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Textilien > Bedruckbare Gewebe',
  },
  {
    value: '47',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Textilien > Stoffe',
  },
  {
    value: '7076',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Textilien > Vlieseline',
  },
  {
    value: '44',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Töpferei & Bildhauerei',
  },
  {
    value: '505402',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Töpferei & Bildhauerei > Engoben',
  },
  {
    value: '505804',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Töpferei & Bildhauerei > Gipsgewebe',
  },
  {
    value: '3692',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Töpferei & Bildhauerei > Modellierton & Modelliermasse',
  },
  {
    value: '505401',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Töpferei & Bildhauerei > Pappmaschee-Mischungen',
  },
  {
    value: '505379',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen',
  },
  {
    value: '1927',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen > Aufnäher',
  },
  {
    value: '505412',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen > Bänder & Einfassungen',
  },
  {
    value: '505411',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen > Deko-Federn',
  },
  {
    value: '4054',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen > Dekorative Aufkleber',
  },
  {
    value: '198',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen > Edelsteine',
  },
  {
    value: '6146',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen > Elastikbund',
  },
  {
    value: '505410',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen > Pailletten & Glitzer',
  },
  {
    value: '32',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen > Perlen',
  },
  {
    value: '505413',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen > Schleifen & Rosetten',
  },
  {
    value: '5996',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen > Schmuck-Basisteile',
  },
  {
    value: '5982',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen > Strass & Deko-Elemente',
  },
  {
    value: '6955',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelmaterialien > Verzierungen & Trimmungen > Textilaufnäher & -aufbügler',
  },
  {
    value: '505370',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelsets',
  },
  {
    value: '4986',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelsets > Bastelsets',
  },
  {
    value: '6382',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelsets > Flicksets',
  },
  {
    value: '505374',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelsets > Kerzenherstellungssets',
  },
  {
    value: '6829',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelsets > Mosaikbausätze',
  },
  {
    value: '502979',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelsets > Schmuckherstellungssets',
  },
  {
    value: '503758',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelsets > Scrapbooking- & Stempelsets',
  },
  {
    value: '6989',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelsets > Sets für die Räucherwerkherstellung',
  },
  {
    value: '7096',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelsets > Sticksets',
  },
  {
    value: '4778',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelsets > Zeichen- & Malsets',
  },
  {
    value: '504639',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge',
  },
  {
    value: '505386',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Dekorationswerkzeuge für Kunstarbeiten',
  },
  {
    value: '505391',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Farbmischwerkzeuge',
  },
  {
    value: '1719',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Farbmischwerkzeuge > Farbpaletten',
  },
  {
    value: '1653',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Farbmischwerkzeuge > Palettenmesser',
  },
  {
    value: '6133',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Filzpolster & -matten',
  },
  {
    value: '6156',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Fingerhüte',
  },
  {
    value: '4073',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Klebepistolen',
  },
  {
    value: '5921',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Leuchtkästen',
  },
  {
    value: '505388',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Maschinen für Textilarbeiten',
  },
  {
    value: '6134',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Maschinen für Textilarbeiten > Filznadeln & -maschinen',
  },
  {
    value: '505422',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Maschinen für Textilarbeiten > Handwebstühle',
  },
  {
    value: '505421',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Maschinen für Textilarbeiten > Mechanische Webstühle',
  },
  {
    value: '615',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Maschinen für Textilarbeiten > Nähmaschinen',
  },
  {
    value: '6137',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Maschinen für Textilarbeiten > Spinnräder',
  },
  {
    value: '505392',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Mess- & Markierwerkzeuge für Kunstarbeiten',
  },
  {
    value: '6126',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Mess- & Markierwerkzeuge für Kunstarbeiten > Handroller',
  },
  {
    value: '505420',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Mess- & Markierwerkzeuge für Kunstarbeiten > Holzbrennwerkzeuge',
  },
  {
    value: '18',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Mess- & Markierwerkzeuge für Kunstarbeiten > Künstlerpinsel',
  },
  {
    value: '6160',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Mess- & Markierwerkzeuge für Kunstarbeiten > Maschenmarkierer & -zähler',
  },
  {
    value: '4032',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Mess- & Markierwerkzeuge für Kunstarbeiten > Motivstempel',
  },
  {
    value: '2671',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Mess- & Markierwerkzeuge für Kunstarbeiten > Schablonen & Matrizen',
  },
  {
    value: '5883',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Mess- & Markierwerkzeuge für Kunstarbeiten > Schablonendruckmaschinen',
  },
  {
    value: '6125',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Mess- & Markierwerkzeuge für Kunstarbeiten > Siebdruck-Rakel',
  },
  {
    value: '6157',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Mess- & Markierwerkzeuge für Kunstarbeiten > Textilkunst-Messgeräte',
  },
  {
    value: '3083',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Mess- & Markierwerkzeuge für Kunstarbeiten > Zeichenzirkel',
  },
  {
    value: '505393',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Näh-, Strick- & Häkelnadeln',
  },
  {
    value: '6127',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Näh-, Strick- & Häkelnadeln > Häkelnadeln',
  },
  {
    value: '6168',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Näh-, Strick- & Häkelnadeln > Knüpfhaken',
  },
  {
    value: '4579',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Näh-, Strick- & Häkelnadeln > Nähmaschinennadeln',
  },
  {
    value: '5992',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Näh-, Strick- & Häkelnadeln > Nähnadeln',
  },
  {
    value: '6139',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Näh-, Strick- & Häkelnadeln > Stricknadeln',
  },
  {
    value: '6158',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Rahmen, Reifen & Spannvorrichtungen',
  },
  {
    value: '5137',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Schneidematten',
  },
  {
    value: '6150',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Schneiderpuppen',
  },
  {
    value: '6101',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Sicherheitsnadeln',
  },
  {
    value: '6151',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Spanndrähte',
  },
  {
    value: '6152',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Spannmatten',
  },
  {
    value: '504640',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Stanz- & Prägewerkzeuge für Kunstarbeiten',
  },
  {
    value: '504641',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Stanz- & Prägewerkzeuge für Kunstarbeiten > Bastel- & Büroscheren',
  },
  {
    value: '5136',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Stanz- & Prägewerkzeuge für Kunstarbeiten > Bastelmesser',
  },
  {
    value: '6447',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Stanz- & Prägewerkzeuge für Kunstarbeiten > Fadenabschneider',
  },
  {
    value: '7340',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Stanz- & Prägewerkzeuge für Kunstarbeiten > Heißluftgeräte',
  },
  {
    value: '6161',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Stanz- & Prägewerkzeuge für Kunstarbeiten > Nahttrenner',
  },
  {
    value: '6122',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Stanz- & Prägewerkzeuge für Kunstarbeiten > Prägestifte',
  },
  {
    value: '6119',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Stanz- & Prägewerkzeuge für Kunstarbeiten > Rill- & Perforiergeräte für Papier',
  },
  {
    value: '504642',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Stanz- & Prägewerkzeuge für Kunstarbeiten > Stanzer & Präger für Kunstarbeiten',
  },
  {
    value: '6159',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Stecknadeln',
  },
  {
    value: '505387',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Werkzeuge für die Faden- & Garnverarbeitung',
  },
  {
    value: '6155',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Werkzeuge für die Faden- & Garnverarbeitung > Faden- & Garnführer',
  },
  {
    value: '6154',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Werkzeuge für die Faden- & Garnverarbeitung > Faden- & Garnspulen',
  },
  {
    value: '6153',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Werkzeuge für die Faden- & Garnverarbeitung > Faden- & Garnwickler',
  },
  {
    value: '6138',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Werkzeuge für die Faden- & Garnverarbeitung > Handspindeln',
  },
  {
    value: '6164',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Werkzeuge für die Faden- & Garnverarbeitung > Kardierer',
  },
  {
    value: '6163',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Werkzeuge für die Faden- & Garnverarbeitung > Nadeleinfädler',
  },
  {
    value: '6167',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Werkzeuge für die Faden- & Garnverarbeitung > Webschlagkämme',
  },
  {
    value: '6166',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Kunst- & Bastelwerkzeuge > Werkzeuge für die Faden- & Garnverarbeitung > Webschützen',
  },
  {
    value: '505371',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Muster & Formen für Kunstarbeiten',
  },
  {
    value: '6135',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Muster & Formen für Kunstarbeiten > Filzformen',
  },
  {
    value: '8007',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Muster & Formen für Kunstarbeiten > Gießformen',
  },
  {
    value: '3697',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Muster & Formen für Kunstarbeiten > Nähmuster',
  },
  {
    value: '505373',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Muster & Formen für Kunstarbeiten > Stick-, Strick- & Häkelmuster',
  },
  {
    value: '6999',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Muster & Formen für Kunstarbeiten > Vorlagen für Perlenarbeiten',
  },
  {
    value: '504643',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Zubehör für Kunst- & Bastelwerkzeuge',
  },
  {
    value: '503348',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Zubehör für Kunst- & Bastelwerkzeuge > Ersatzteile für Nähmaschinen',
  },
  {
    value: '232168',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Zubehör für Kunst- & Bastelwerkzeuge > Klingen für Bastelmesser',
  },
  {
    value: '5120',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Zubehör für Kunst- & Bastelwerkzeuge > Nähmaschinenfüße',
  },
  {
    value: '4580',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Zubehör für Kunst- & Bastelwerkzeuge > Nähmaschinengehäuse & -abdeckungen',
  },
  {
    value: '6136',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Zubehör für Kunst- & Bastelwerkzeuge > Spinnzubehör',
  },
  {
    value: '499918',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Zubehör für Kunst- & Bastelwerkzeuge > Stempelblöcke',
  },
  {
    value: '505286',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Kunsthandwerk & Hobby > Zubehör für Kunst- & Bastelwerkzeuge > Verlängerungstische für Nähmaschinen',
  },
  {
    value: '5999',
    label: 'Kunst & Unterhaltung > Hobby & Kunst > Modellbau',
  },
  {
    value: '4175',
    label: 'Kunst & Unterhaltung > Hobby & Kunst > Modellbau > Modellbausätze',
  },
  {
    value: '5150',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Modellbau > Modelleisenbahn & Eisenbahnsets',
  },
  {
    value: '5151',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Modellbau > Modelleisenbahnzubehör',
  },
  {
    value: '3885',
    label: 'Kunst & Unterhaltung > Hobby & Kunst > Modellbau > Modellraketen',
  },
  {
    value: '54',
    label: 'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente',
  },
  {
    value: '4983',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Akkordeons & Konzertinas',
  },
  {
    value: '63',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Blechblasinstrumente',
  },
  {
    value: '505769',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Blechblasinstrumente > Alt- & Baritonhörner',
  },
  {
    value: '65',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Blechblasinstrumente > Euphonien',
  },
  {
    value: '70',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Blechblasinstrumente > Posaunen',
  },
  {
    value: '505770',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Blechblasinstrumente > Trompeten & Kornetts',
  },
  {
    value: '72',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Blechblasinstrumente > Tubas',
  },
  {
    value: '67',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Blechblasinstrumente > Waldhörner',
  },
  {
    value: '4984',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Dudelsäcke',
  },
  {
    value: '6001',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Elektronische Musikinstrumente',
  },
  {
    value: '245',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Elektronische Musikinstrumente > Audio-Sampler',
  },
  {
    value: '6002',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Elektronische Musikinstrumente > MIDI-Controller',
  },
  {
    value: '74',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Elektronische Musikinstrumente > Musik-Keyboards',
  },
  {
    value: '6003',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Elektronische Musikinstrumente > Synthesizer',
  },
  {
    value: '87',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente',
  },
  {
    value: '6721',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Blechflöten',
  },
  {
    value: '90',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Blockflöten',
  },
  {
    value: '4540',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Fagotte',
  },
  {
    value: '89',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Flöten',
  },
  {
    value: '7188',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Kinderflöten',
  },
  {
    value: '88',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Klarinetten',
  },
  {
    value: '7250',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Längsflöten & Panflöten',
  },
  {
    value: '4744',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Maultrommeln',
  },
  {
    value: '5481',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Melodicas',
  },
  {
    value: '4743',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Mundharmonikas',
  },
  {
    value: '4541',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Oboen',
  },
  {
    value: '7249',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Okarinas',
  },
  {
    value: '91',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Saxophone',
  },
  {
    value: '6728',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Holzblasinstrumente > Zugpfeife',
  },
  {
    value: '76',
    label: 'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Klaviere',
  },
  {
    value: '77',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Saiteninstrumente',
  },
  {
    value: '85',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Saiteninstrumente > Bratschen',
  },
  {
    value: '79',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Saiteninstrumente > Cellos',
  },
  {
    value: '80',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Saiteninstrumente > Gitarren',
  },
  {
    value: '84',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Saiteninstrumente > Harfen',
  },
  {
    value: '78',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Saiteninstrumente > Kontrabässe',
  },
  {
    value: '86',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Saiteninstrumente > Violinen',
  },
  {
    value: '75',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente',
  },
  {
    value: '2917',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Basstrommeln',
  },
  {
    value: '3043',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Becken',
  },
  {
    value: '2856',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Elektronische Schlagzeuge',
  },
  {
    value: '7431',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Glockenspiele & Xylophone',
  },
  {
    value: '6098',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Gongs',
  },
  {
    value: '7285',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente',
  },
  {
    value: '7288',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Finger- & Handzimbeln',
  },
  {
    value: '7555',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Handschellen & -glocken',
  },
  {
    value: '7295',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Handtrommeln',
  },
  {
    value: '7299',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Handtrommeln > Beckentrommeln',
  },
  {
    value: '7298',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Handtrommeln > Bongos',
  },
  {
    value: '7297',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Handtrommeln > Cajons',
  },
  {
    value: '7296',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Handtrommeln > Congas',
  },
  {
    value: '7300',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Handtrommeln > Rahmentrommeln',
  },
  {
    value: '7301',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Handtrommeln > Sprechtrommeln',
  },
  {
    value: '7302',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Handtrommeln > Tablas',
  },
  {
    value: '7291',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Holzblöcke',
  },
  {
    value: '7289',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Kastagnetten',
  },
  {
    value: '7293',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Kuhglocken',
  },
  {
    value: '7287',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Rasseln',
  },
  {
    value: '7286',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Scraper & Schnarren',
  },
  {
    value: '2515',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Tamburine',
  },
  {
    value: '7290',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Triangeln',
  },
  {
    value: '7294',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Handperkussionsinstrumente > Vibraslaps',
  },
  {
    value: '3015',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Hi-Hats',
  },
  {
    value: '2518',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Schlagzeuge',
  },
  {
    value: '2797',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Snaredrums',
  },
  {
    value: '3005',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Tom-Toms',
  },
  {
    value: '7232',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Musikinstrumente > Schlaginstrumente > Übungspads',
  },
  {
    value: '216',
    label: 'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke',
  },
  {
    value: '3599',
    label: 'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Autogramme',
  },
  {
    value: '219',
    label: 'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Briefmarken',
  },
  {
    value: '37',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Maßstabsmodelle',
  },
  {
    value: '6000',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Modellbau-Zubehör',
  },
  {
    value: '217',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Münzen & Währungen',
  },
  {
    value: '3893',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Retro-Werbung',
  },
  {
    value: '6997',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sammelkarten',
  },
  {
    value: '220',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sammlerwaffen',
  },
  {
    value: '499953',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sammlerwaffen > Dekowaffen',
  },
  {
    value: '5311',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sammlerwaffen > Sammlermesser',
  },
  {
    value: '221',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sammlerwaffen > Schwerter',
  },
  {
    value: '1340',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sammlerwaffen > Schwertständer & Schwerthalter',
  },
  {
    value: '3865',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte',
  },
  {
    value: '3515',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sport-Fanartikel',
  },
  {
    value: '1095',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sport-Fanartikel > American Football-Fanartikel',
  },
  {
    value: '1074',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sport-Fanartikel > Baseball- & Softball-Fanartikel',
  },
  {
    value: '1084',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sport-Fanartikel > Basketball-Fanartikel',
  },
  {
    value: '3576',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sport-Fanartikel > Fußball-Fanartikel',
  },
  {
    value: '4006',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sport-Fanartikel > Hockey-Fanartikel',
  },
  {
    value: '1051',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sport-Fanartikel > Rennsport-Fanartikel',
  },
  {
    value: '6187',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sport-Fanartikel > Tennis-Fanzubehör',
  },
  {
    value: '4333',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sportartikel mit Autogramm',
  },
  {
    value: '4124',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sportartikel mit Autogramm > American Football-Artikel mit Autogramm',
  },
  {
    value: '4149',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sportartikel mit Autogramm > Baseball- & Softballartikel mit Autogramm',
  },
  {
    value: '4279',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sportartikel mit Autogramm > Basketballartikel mit Autogramm',
  },
  {
    value: '8210',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sportartikel mit Autogramm > Boxartikel mit Autogramm',
  },
  {
    value: '4093',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sportartikel mit Autogramm > Fußballartikel mit Autogramm',
  },
  {
    value: '4144',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sportartikel mit Autogramm > Hockeyartikel mit Autogramm',
  },
  {
    value: '4180',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sportartikel mit Autogramm > Rennsportartikel mit Autogramm',
  },
  {
    value: '6186',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Sport-Sammelobjekte > Sportartikel mit Autogramm > Tennisartikel mit Autogramm',
  },
  {
    value: '218',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Steine & Fossilien',
  },
  {
    value: '1312',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Sammlerstücke > Stempelsiegel',
  },
  {
    value: '35',
    label: 'Kunst & Unterhaltung > Hobby & Kunst > Zauberbedarf',
  },
  {
    value: '3577',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Bier- & Weinherstellung',
  },
  {
    value: '502980',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Bier- & Weinherstellung > Abfüllflaschen',
  },
  {
    value: '3014',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Bier- & Weinherstellung > Brauzutaten',
  },
  {
    value: '499891',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Bier- & Weinherstellung > Sets zum Selbstherstellen von Bier & Wein',
  },
  {
    value: '2579',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Bier- & Weinherstellung > Weinherstellung',
  },
  {
    value: '55',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente',
  },
  {
    value: '60',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Keyboardzubehör',
  },
  {
    value: '3588',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Keyboardzubehör > Keyboardständer',
  },
  {
    value: '7357',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Keyboardzubehör > Keyboardtaschen',
  },
  {
    value: '3324',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Keyboardzubehör > Sustain-Pedale',
  },
  {
    value: '505328',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Klavierbänke & Musikerhocker',
  },
  {
    value: '500001',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Marschgabeln und Notenhalter',
  },
  {
    value: '505365',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Metronome',
  },
  {
    value: '4142',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Notenständer',
  },
  {
    value: '3465',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör',
  },
  {
    value: '7231',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Dämpfer für Schlagzeug & Becken',
  },
  {
    value: '7455',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Module für elektronische Schlagzeuge',
  },
  {
    value: '7099',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Schlagzeug-Hardware',
  },
  {
    value: '7103',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Schlagzeug-Hardware > Beater',
  },
  {
    value: '7101',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Schlagzeug-Hardware > Fußmaschinen',
  },
  {
    value: '7102',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Schlagzeug-Hardware > Schlagzeughalterungen',
  },
  {
    value: '7153',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Schlagzeugfelle',
  },
  {
    value: '7100',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Schlagzeugkoffer',
  },
  {
    value: '59',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Sticks & Besen',
  },
  {
    value: '7152',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Stimmschlüssel',
  },
  {
    value: '7308',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Ständer für Perkussionsinstrumente',
  },
  {
    value: '4631',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Trommelschlägel',
  },
  {
    value: '7282',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Zubehör für Handperkussionsinstrumente',
  },
  {
    value: '7284',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Zubehör für Handperkussionsinstrumente > Ständer für Handperkussionsinstrumente',
  },
  {
    value: '7283',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Zubehör für Handperkussionsinstrumente > Taschen für Handperkussionsinstrumente',
  },
  {
    value: '7150',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Zubehör für Sticks & Besen',
  },
  {
    value: '7151',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Schlagzeugzubehör > Zubehör für Sticks & Besen > Taschen für Sticks & Besen',
  },
  {
    value: '3270',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Stimmgeräte',
  },
  {
    value: '7277',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Ständer für Musikinstrumente',
  },
  {
    value: '7280',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Ständer für Musikinstrumente > Beleuchtung für Instrumentenständer',
  },
  {
    value: '7278',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Ständer für Musikinstrumente > Notenklemmen',
  },
  {
    value: '7279',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Ständer für Musikinstrumente > Taschen für Instrumentenständer',
  },
  {
    value: '505288',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Taktstöcke',
  },
  {
    value: '56',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Verstärker für Musikinstrumente',
  },
  {
    value: '8072',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Verstärkerzubehör',
  },
  {
    value: '7364',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Verstärkerzubehör > Ständer für Verstärker',
  },
  {
    value: '8073',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Verstärkerzubehör > Verstärker-Fußschalter',
  },
  {
    value: '8461',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Verstärkerzubehör > Verstärkerabdeckungen',
  },
  {
    value: '6970',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Verstärkerzubehör > Verstärkergehäuse',
  },
  {
    value: '8462',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Verstärkerzubehör > Verstärkerknöpfe',
  },
  {
    value: '8480',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Verstärkerzubehör > Verstärkerröhren',
  },
  {
    value: '57',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente',
  },
  {
    value: '505309',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente > Aufhängungen & Ständer für Blechinstrumente',
  },
  {
    value: '4797',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente > Blechblasinstrumentenpflege &-reinigung',
  },
  {
    value: '4894',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente > Blechblasinstrumentenpflege &-reinigung > Klappenöl für Blechblasinstrumente',
  },
  {
    value: '4891',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente > Blechblasinstrumentenpflege &-reinigung > Pflegesets für Blechblasinstrumente',
  },
  {
    value: '4895',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente > Blechblasinstrumentenpflege &-reinigung > Poliertücher für Blechblasinstrumente',
  },
  {
    value: '4892',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente > Blechblasinstrumentenpflege &-reinigung > Reinigungs- & Desinfektionsmittel für Blechblasinstrumente',
  },
  {
    value: '4890',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente > Blechblasinstrumentenpflege &-reinigung > Reinigungswerkzeug für Blechblasinstrumente',
  },
  {
    value: '4893',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente > Blechblasinstrumentenpflege &-reinigung > Schutzvorrichtungen für Blechblasinstrumente',
  },
  {
    value: '505768',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente > Dämpfer für Blechinstrumente',
  },
  {
    value: '4798',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente > Ersatzteile für Blechblasinstrumente',
  },
  {
    value: '505310',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente > Koffer & Gigbags für Blechinstrumente',
  },
  {
    value: '505308',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Blechblasinstrumente > Mundstücke für Blechinstrumente',
  },
  {
    value: '62',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente',
  },
  {
    value: '503747',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Blockflötenzubehör',
  },
  {
    value: '503748',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Blockflötenzubehör > Blockflötenkoffer',
  },
  {
    value: '503750',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Blockflötenzubehör > Blockflötenteile',
  },
  {
    value: '503749',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Blockflötenzubehör > Pflege- & Reinigungsmittel für Blockflöten',
  },
  {
    value: '4957',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Blätteretui für Holzblasinstrumente',
  },
  {
    value: '4939',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Fagottblattmesser',
  },
  {
    value: '4790',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Fagottzubehör',
  },
  {
    value: '4812',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Fagottzubehör > Fagottblätter',
  },
  {
    value: '4809',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Fagottzubehör > Fagottpflege & -reinigung',
  },
  {
    value: '4815',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Fagottzubehör > Fagottpflege & -reinigung > Fagottreinigungstücher',
  },
  {
    value: '4814',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Fagottzubehör > Fagottriemen & -stützen',
  },
  {
    value: '4813',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Fagottzubehör > Fagottständer',
  },
  {
    value: '4811',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Fagottzubehör > Fagottteile',
  },
  {
    value: '4816',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Fagottzubehör > Fagottteile > Fagott-S-Bögen',
  },
  {
    value: '4817',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Fagottzubehör > Fagottteile > Kleinere Fagottzubehörteile',
  },
  {
    value: '4810',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Fagottzubehör > Koffer & Transporttaschen für Fagotte',
  },
  {
    value: '4792',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Flötenzubehör',
  },
  {
    value: '4837',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Flötenzubehör > Flötenhalter & -ständer',
  },
  {
    value: '4833',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Flötenzubehör > Flötenpflege & -reinigung',
  },
  {
    value: '4838',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Flötenzubehör > Flötenpflege & -reinigung > Flötenpflegesets',
  },
  {
    value: '4839',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Flötenzubehör > Flötenpflege & -reinigung > Flötenreinigungsstab',
  },
  {
    value: '4840',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Flötenzubehör > Flötenpflege & -reinigung > Flötenreinigungstücher',
  },
  {
    value: '4836',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Flötenzubehör > Flötenteile',
  },
  {
    value: '4841',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Flötenzubehör > Flötenteile > Flötenkopfstücke',
  },
  {
    value: '4842',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Flötenzubehör > Flötenteile > Kleinere Flötenzubehörteile',
  },
  {
    value: '4834',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Flötenzubehör > Koffer & Transporttaschen für Flöten',
  },
  {
    value: '4791',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör',
  },
  {
    value: '4820',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Blattschrauben & Kappen für Klarinetten',
  },
  {
    value: '4824',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Klarinettenblätter',
  },
  {
    value: '4823',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Klarinettenhalter/-ständer',
  },
  {
    value: '4818',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Klarinettenpflege &-reinigung',
  },
  {
    value: '4827',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Klarinettenpflege &-reinigung > Klarinettenklappenschutz',
  },
  {
    value: '4826',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Klarinettenpflege &-reinigung > Klarinettenpflegesets',
  },
  {
    value: '4828',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Klarinettenpflege &-reinigung > Klarinettenreinigungstücher',
  },
  {
    value: '4825',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Klarinettenriemen & -stützen',
  },
  {
    value: '4822',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Klarinettenteile',
  },
  {
    value: '4829',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Klarinettenteile > Klarinettenbirnen',
  },
  {
    value: '4831',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Klarinettenteile > Klarinettenmundstücke',
  },
  {
    value: '4830',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Klarinettenteile > Klarinettentrichter',
  },
  {
    value: '4832',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Klarinettenteile > Kleinere Klarinettenzubehörteile',
  },
  {
    value: '4819',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Klarinettenzubehör > Koffer & Transporttaschen für Klarinetten',
  },
  {
    value: '4866',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Korkfett für Holzblasinstrumente',
  },
  {
    value: '4955',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Mundharmonikazubehör',
  },
  {
    value: '4956',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Mundharmonikazubehör > Mundharmonika-Etuis',
  },
  {
    value: '5046',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Mundharmonikazubehör > Mundharmonikahalter',
  },
  {
    value: '4793',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Oboenzubehör',
  },
  {
    value: '4844',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Oboenzubehör > Koffer & Transporttaschen für Oboen',
  },
  {
    value: '4847',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Oboenzubehör > Oboenblätter',
  },
  {
    value: '4846',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Oboenzubehör > Oboenhalter & -ständer',
  },
  {
    value: '4843',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Oboenzubehör > Oboenpflege & -reinigung',
  },
  {
    value: '4849',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Oboenzubehör > Oboenpflege & -reinigung > Oboenpflegesets',
  },
  {
    value: '4850',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Oboenzubehör > Oboenpflege & -reinigung > Oboenreinigungstücher',
  },
  {
    value: '4848',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Oboenzubehör > Oboenriemen & -stützen',
  },
  {
    value: '4845',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Oboenzubehör > Oboenteile',
  },
  {
    value: '4851',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Oboenzubehör > Oboenteile > Kleinere Oboenzubehörteile',
  },
  {
    value: '4867',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Poliertücher für Holzblasinstrumente',
  },
  {
    value: '4794',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone',
  },
  {
    value: '4854',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Blattschrauben & Kappen für Saxophone',
  },
  {
    value: '4853',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Koffer & Transporttaschen für Saxophone',
  },
  {
    value: '4858',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Rohrblätter für Saxophone',
  },
  {
    value: '4857',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Saxophonhalter & -ständer',
  },
  {
    value: '4852',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Saxophonpflege &-reinigung',
  },
  {
    value: '4861',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Saxophonpflege &-reinigung > Saxophonklappenschutz',
  },
  {
    value: '4860',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Saxophonpflege &-reinigung > Saxophonpflegesets',
  },
  {
    value: '4862',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Saxophonpflege &-reinigung > Saxophonreinigungstücher',
  },
  {
    value: '4859',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Saxophonriemen/-stützen',
  },
  {
    value: '4856',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Saxophonteile',
  },
  {
    value: '4865',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Saxophonteile > Kleinere Saxophonzubehörteile',
  },
  {
    value: '4863',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Saxophonteile > Mundstücke für Saxophone',
  },
  {
    value: '4864',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Holzblasinstrumente > Zubehör für Saxophone > Saxophonteile > Saxophonhälse',
  },
  {
    value: '61',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente',
  },
  {
    value: '3502',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör',
  },
  {
    value: '5368',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Gitarren-Slides',
  },
  {
    value: '3392',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Gitarrenbefeuchter',
  },
  {
    value: '499688',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Gitarrengurte',
  },
  {
    value: '3882',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Gitarrenkoffer & -taschen',
  },
  {
    value: '4111',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Gitarrenplektren',
  },
  {
    value: '3178',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Gitarrensaiten',
  },
  {
    value: '503721',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Gitarrensaitenspanner',
  },
  {
    value: '3176',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Gitarrenstimmwirbel',
  },
  {
    value: '3646',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Gitarrenständer',
  },
  {
    value: '503032',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Gitarrenzubehör & -teile',
  },
  {
    value: '5367',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Kapodaster',
  },
  {
    value: '3775',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Tonabnehmer für Akustikgitarren',
  },
  {
    value: '3412',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Gitarrenzubehör > Tonabnehmer für E-Gitarren',
  },
  {
    value: '4806',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Saiteninstrumentenpflege &-reinigung',
  },
  {
    value: '3374',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Saiteninstrumentenpflege &-reinigung > Bogenharz',
  },
  {
    value: '4912',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Saiteninstrumentenpflege &-reinigung > Politur für Saiteninstrumente',
  },
  {
    value: '4911',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Saiteninstrumentenpflege &-reinigung > Reinigungstücher für Saiteninstrumente',
  },
  {
    value: '503033',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Zubehör für Streichinstrumente & Harfen',
  },
  {
    value: '8209',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Zubehör für Streichinstrumente & Harfen > Bogenkästen für Streichinstrumente',
  },
  {
    value: '503040',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Zubehör für Streichinstrumente & Harfen > Bögen für Saiteninstrumente',
  },
  {
    value: '503037',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Zubehör für Streichinstrumente & Harfen > Dämpfer für Saiteninstrumente',
  },
  {
    value: '503039',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Zubehör für Streichinstrumente & Harfen > Koffer für Saiteninstrumente',
  },
  {
    value: '503034',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Zubehör für Streichinstrumente & Harfen > Saiten für Saiteninstrumente',
  },
  {
    value: '503035',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Zubehör für Streichinstrumente & Harfen > Ständer für Saiteninstrumente',
  },
  {
    value: '503036',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Zubehör für Streichinstrumente & Harfen > Tonabnehmer für Saiteninstrumente',
  },
  {
    value: '503038',
    label:
      'Kunst & Unterhaltung > Hobby & Kunst > Zubehör für Musikinstrumente > Zubehör für Saiteninstrumente > Zubehör für Streichinstrumente & Harfen > Zubehör & Ersatzteile für Saiteninstrumente',
  },
  {
    value: '5709',
    label: 'Kunst & Unterhaltung > Party & Feiern',
  },
  {
    value: '96',
    label: 'Kunst & Unterhaltung > Party & Feiern > Partyzubehör',
  },
  {
    value: '1887',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Antwortkarten',
  },
  {
    value: '8110',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Aufblasbare Partydeko',
  },
  {
    value: '6311',
    label: 'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Ballon-Sets',
  },
  {
    value: '2531',
    label: 'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Banner',
  },
  {
    value: '1484',
    label: 'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Briefsiegel',
  },
  {
    value: '7007',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Cocktaildekorationen',
  },
  {
    value: '8216',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Dekorative Pompons',
  },
  {
    value: '1371',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Einladungskarten',
  },
  {
    value: '8038',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Eventprogramme',
  },
  {
    value: '4914',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Feuerwerkskörper',
  },
  {
    value: '4730',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Geburtstagskerzen',
  },
  {
    value: '7097',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Halter für Karten zu besonderen Anlässen',
  },
  {
    value: '2781',
    label: 'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Konfetti',
  },
  {
    value: '2587',
    label: 'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Luftballons',
  },
  {
    value: '4351',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Luftschlangenspray',
  },
  {
    value: '5452',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Partyartikel',
  },
  {
    value: '5453',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Partyartikel > Kleine Geschenke für Hochzeitsgäste',
  },
  {
    value: '502981',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Partygirlanden & -vorhänge',
  },
  {
    value: '6906',
    label: 'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Partyhüte',
  },
  {
    value: '7160',
    label: 'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Partyspiele',
  },
  {
    value: '502972',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Partyzubehörsets',
  },
  {
    value: '3994',
    label: 'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Piñatas',
  },
  {
    value: '2104',
    label: 'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Platzkarten',
  },
  {
    value: '5472',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Platzkartenhalter',
  },
  {
    value: '328061',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Spruchkarten',
  },
  {
    value: '505763',
    label: 'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Stuhlbezüge',
  },
  {
    value: '5043',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Trinkhalme & Rührer',
  },
  {
    value: '3735',
    label: 'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Trinkspiele',
  },
  {
    value: '3361',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Trinkspiele > Bierpong',
  },
  {
    value: '3440',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Trinkspiele > Bierpong > Bierpong-Tische',
  },
  {
    value: '2783',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Tröten, Luftrüssel & Krachmacher',
  },
  {
    value: '4915',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Partyzubehör > Wunderkerzen',
  },
  {
    value: '2559',
    label: 'Kunst & Unterhaltung > Party & Feiern > Schenken',
  },
  {
    value: '5916',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Schenken > Anstecksträußchen',
  },
  {
    value: '6100',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Schenken > Befestigungsnadeln für Anstecksträußchen',
  },
  {
    value: '2899',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Schenken > Frische Schnittblumen',
  },
  {
    value: '53',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Schenken > Geschenkgutscheine',
  },
  {
    value: '94',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Schenken > Geschenkverpackungen',
  },
  {
    value: '8213',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Schenken > Geschenkverpackungen > Geschenkanhänger',
  },
  {
    value: '5091',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Schenken > Geschenkverpackungen > Geschenkboxen & -dosen',
  },
  {
    value: '2816',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Schenken > Geschenkverpackungen > Geschenkpapier',
  },
  {
    value: '5838',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Schenken > Geschenkverpackungen > Geschenktaschen',
  },
  {
    value: '6712',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Schenken > Geschenkverpackungen > Seidenpapier',
  },
  {
    value: '95',
    label: 'Kunst & Unterhaltung > Party & Feiern > Schenken > Grußkarten',
  },
  {
    value: '5868',
    label: 'Kunst & Unterhaltung > Party & Feiern > Trophäen & Preise',
  },
  {
    value: '408',
    label: 'Kunst & Unterhaltung > Party & Feiern > Visuelle Effekte',
  },
  {
    value: '5711',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Visuelle Effekte > Diskokugeln',
  },
  {
    value: '410',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Visuelle Effekte > Lichteffektbeleuchtung',
  },
  {
    value: '5967',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Visuelle Effekte > Lichtmischpulte',
  },
  {
    value: '409',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Visuelle Effekte > Nebelmaschinen',
  },
  {
    value: '503028',
    label:
      'Kunst & Unterhaltung > Party & Feiern > Visuelle Effekte > Ständer für Lichteffektbeleuchtung',
  },
  {
    value: '783',
    label: 'Medien',
  },
  {
    value: '499995',
    label: 'Medien > Baupläne für Tischler- und Holzarbeiten',
  },
  {
    value: '784',
    label: 'Medien > Bücher',
  },
  {
    value: '839',
    label: 'Medien > DVDs & Videos',
  },
  {
    value: '855',
    label: 'Medien > Musik & Tonaufnahmen',
  },
  {
    value: '887',
    label: 'Medien > Notenblätter',
  },
  {
    value: '5037',
    label: 'Medien > Produkthandbücher & Gebrauchsanleitungen',
  },
  {
    value: '499866',
    label:
      'Medien > Produkthandbücher & Gebrauchsanleitungen > Bürobedarf-Handbücher',
  },
  {
    value: '5038',
    label:
      'Medien > Produkthandbücher & Gebrauchsanleitungen > Handbücher für Elektrogeräte',
  },
  {
    value: '7516',
    label:
      'Medien > Produkthandbücher & Gebrauchsanleitungen > Handbücher für Elektrowerkzeuge',
  },
  {
    value: '5039',
    label:
      'Medien > Produkthandbücher & Gebrauchsanleitungen > Handbücher für Haushaltsgeräte',
  },
  {
    value: '5040',
    label:
      'Medien > Produkthandbücher & Gebrauchsanleitungen > Handbücher für Küchengeräte',
  },
  {
    value: '5860',
    label:
      'Medien > Produkthandbücher & Gebrauchsanleitungen > Handbücher für Modellbau und Spielzeuge',
  },
  {
    value: '499821',
    label:
      'Medien > Produkthandbücher & Gebrauchsanleitungen > Kamera- & Optikhandbücher',
  },
  {
    value: '5041',
    label: 'Medien > Produkthandbücher & Gebrauchsanleitungen > Kfz-Handbücher',
  },
  {
    value: '5861',
    label:
      'Medien > Produkthandbücher & Gebrauchsanleitungen > Sport- & Freizeithandbücher',
  },
  {
    value: '886',
    label: 'Medien > Zeitschriften & Zeitungen',
  },
  {
    value: '436',
    label: 'Möbel',
  },
  {
    value: '554',
    label: 'Möbel > Baby- & Kleinkindmöbel',
  },
  {
    value: '7070',
    label: 'Möbel > Baby- & Kleinkindmöbel > Gitter- & Kinderbettzubehör',
  },
  {
    value: '7072',
    label:
      'Möbel > Baby- & Kleinkindmöbel > Gitter- & Kinderbettzubehör > Bettnestchen & Randschutz für Gitterbetten',
  },
  {
    value: '7071',
    label:
      'Möbel > Baby- & Kleinkindmöbel > Gitter- & Kinderbettzubehör > Umbausätze für Gitterbetten',
  },
  {
    value: '6394',
    label: 'Möbel > Baby- & Kleinkindmöbel > Gitterbetten & Kleinkinderbetten',
  },
  {
    value: '559',
    label: 'Möbel > Baby- & Kleinkindmöbel > Hochstühle & Sitzerhöhungen',
  },
  {
    value: '6349',
    label:
      'Möbel > Baby- & Kleinkindmöbel > Möbelgarnituren für Babies & Kleinkinder',
  },
  {
    value: '558',
    label: 'Möbel > Baby- & Kleinkindmöbel > Wickeltische',
  },
  {
    value: '6393',
    label: 'Möbel > Baby- & Kleinkindmöbel > Wiegen & Stubenwagen',
  },
  {
    value: '7068',
    label: 'Möbel > Baby- & Kleinkindmöbel > Wiegen- & Stubenwagenzubehör',
  },
  {
    value: '6969',
    label:
      'Möbel > Baby- & Kleinkindmöbel > Zubehör für Hochstühle und Sitzerhöhungen',
  },
  {
    value: '6433',
    label: 'Möbel > Betten & Zubehör',
  },
  {
    value: '4437',
    label: 'Möbel > Betten & Zubehör > Bett- & Bettrahmenzubehör',
  },
  {
    value: '505764',
    label: 'Möbel > Betten & Zubehör > Betten & Bettgestelle',
  },
  {
    value: '451',
    label: 'Möbel > Betten & Zubehör > Kopf- & Fußenden',
  },
  {
    value: '2696',
    label: 'Möbel > Betten & Zubehör > Matratzen',
  },
  {
    value: '2720',
    label: 'Möbel > Betten & Zubehör > Matratzen-Untergestelle',
  },
  {
    value: '6362',
    label: 'Möbel > Büromöbel',
  },
  {
    value: '6908',
    label: 'Möbel > Büromöbel > Arbeitsplätze im Großraumbüro',
  },
  {
    value: '6363',
    label: 'Möbel > Büromöbel > Arbeitstische',
  },
  {
    value: '4317',
    label: 'Möbel > Büromöbel > Arbeitstische > Konferenztische',
  },
  {
    value: '2242',
    label: 'Möbel > Büromöbel > Arbeitstische > Verstellbare Zeichentische',
  },
  {
    value: '2045',
    label: 'Möbel > Büromöbel > Büro- & Schreibtischstühle',
  },
  {
    value: '500061',
    label: 'Möbel > Büromöbel > Büromöbelgarnituren',
  },
  {
    value: '4191',
    label: 'Möbel > Büromöbel > Schreibtische',
  },
  {
    value: '2786',
    label: 'Möbel > Futonauflagen',
  },
  {
    value: '6860',
    label: 'Möbel > Futonrahmen',
  },
  {
    value: '450',
    label: 'Möbel > Futons',
  },
  {
    value: '4299',
    label: 'Möbel > Gartenmöbel',
  },
  {
    value: '7310',
    label: 'Möbel > Gartenmöbel > Aufbewahrungsbehälter für draußen',
  },
  {
    value: '6892',
    label: 'Möbel > Gartenmöbel > Gartenbetten',
  },
  {
    value: '6822',
    label: 'Möbel > Gartenmöbel > Gartenhocker',
  },
  {
    value: '6367',
    label: 'Möbel > Gartenmöbel > Gartenmöbelgarnituren',
  },
  {
    value: '6368',
    label: 'Möbel > Gartenmöbel > Gartensitzmöbel',
  },
  {
    value: '5044',
    label: 'Möbel > Gartenmöbel > Gartensitzmöbel > Gartenbänke',
  },
  {
    value: '500111',
    label: 'Möbel > Gartenmöbel > Gartensitzmöbel > Gartensofaelemente',
  },
  {
    value: '4513',
    label: 'Möbel > Gartenmöbel > Gartensitzmöbel > Gartensofas',
  },
  {
    value: '6828',
    label: 'Möbel > Gartenmöbel > Gartensitzmöbel > Gartenstühle',
  },
  {
    value: '4105',
    label: 'Möbel > Gartenmöbel > Gartensitzmöbel > Sonnenliegen',
  },
  {
    value: '2684',
    label: 'Möbel > Gartenmöbel > Gartentische',
  },
  {
    value: '6963',
    label: 'Möbel > Gartenmöbelzubehör',
  },
  {
    value: '6964',
    label: 'Möbel > Gartenmöbelzubehör > Abdeckungen für Gartenmöbel',
  },
  {
    value: '442',
    label: 'Möbel > Kücheninseln & Küchenwagen',
  },
  {
    value: '6374',
    label: 'Möbel > Kücheninseln & Küchenwagen > Kücheninseln',
  },
  {
    value: '453',
    label: 'Möbel > Kücheninseln & Küchenwagen > Küchenwagen',
  },
  {
    value: '457',
    label: 'Möbel > Möbel für Unterhaltungselektronik',
  },
  {
    value: '6345',
    label: 'Möbel > Möbelgarnituren',
  },
  {
    value: '500000',
    label: 'Möbel > Möbelgarnituren > Badezimmergarnituren',
  },
  {
    value: '6347',
    label: 'Möbel > Möbelgarnituren > Küchen- & Esszimmergarnituren',
  },
  {
    value: '6346',
    label: 'Möbel > Möbelgarnituren > Schlafzimmergarnituren',
  },
  {
    value: '6348',
    label: 'Möbel > Möbelgarnituren > Wohnzimmergarnituren',
  },
  {
    value: '458',
    label: 'Möbel > Polsterhocker',
  },
  {
    value: '4163',
    label: 'Möbel > Raumteiler',
  },
  {
    value: '6915',
    label: 'Möbel > Raumteilerzubehör',
  },
  {
    value: '464',
    label: 'Möbel > Regalsysteme',
  },
  {
    value: '465',
    label: 'Möbel > Regalsysteme > Bücherregale & Bücherschränke',
  },
  {
    value: '6372',
    label: 'Möbel > Regalsysteme > Wandregale & Simse',
  },
  {
    value: '8023',
    label: 'Möbel > Regalzubehör',
  },
  {
    value: '8024',
    label: 'Möbel > Regalzubehör > Ersatzregale',
  },
  {
    value: '6356',
    label: 'Möbel > Schränke',
  },
  {
    value: '463',
    label: 'Möbel > Schränke > Aktenschränke',
  },
  {
    value: '4205',
    label: 'Möbel > Schränke > Aufbewahrungstruhen',
  },
  {
    value: '6947',
    label: 'Möbel > Schränke > Aufbewahrungstruhen > Aussteuertruhen',
  },
  {
    value: '4268',
    label: 'Möbel > Schränke > Aufbewahrungstruhen > Spielzeugtruhen',
  },
  {
    value: '447',
    label: 'Möbel > Schränke > Buffets & Sideboards',
  },
  {
    value: '465846',
    label: 'Möbel > Schränke > Bügelcenter',
  },
  {
    value: '5938',
    label: 'Möbel > Schränke > Büroschränke',
  },
  {
    value: '448',
    label: 'Möbel > Schränke > Geschirrschränke',
  },
  {
    value: '4063',
    label: 'Möbel > Schränke > Kleiderschränke',
  },
  {
    value: '4195',
    label: 'Möbel > Schränke > Kommoden',
  },
  {
    value: '6934',
    label: 'Möbel > Schränke > Küchenschränke',
  },
  {
    value: '6358',
    label: 'Möbel > Schränke > Mediaaufbewahrung',
  },
  {
    value: '6357',
    label: 'Möbel > Schränke > Spirituosenschränke',
  },
  {
    value: '4148',
    label: 'Möbel > Schränke > Wasch- und Schminktische',
  },
  {
    value: '2081',
    label:
      'Möbel > Schränke > Wasch- und Schminktische > Badezimmerwaschtische',
  },
  {
    value: '6360',
    label: 'Möbel > Schränke > Wasch- und Schminktische > Schminktische',
  },
  {
    value: '5578',
    label: 'Möbel > Schränke > Weinregale',
  },
  {
    value: '6539',
    label: 'Möbel > Schränke > Zeitschriftenständer',
  },
  {
    value: '441',
    label: 'Möbel > Sitzbänke',
  },
  {
    value: '6850',
    label: 'Möbel > Sitzbänke > Küchen- & Eckbänke',
  },
  {
    value: '4241',
    label: 'Möbel > Sitzbänke > Schminktischhocker',
  },
  {
    value: '6851',
    label: 'Möbel > Sitzbänke > Truhen- & Flurbänke',
  },
  {
    value: '7212',
    label: 'Möbel > Sofa-Zubehör',
  },
  {
    value: '500064',
    label: 'Möbel > Sofa-Zubehör > Anbausofas',
  },
  {
    value: '7213',
    label: 'Möbel > Sofa-Zubehör > Sitzverstärkung für Stühle & Sofas',
  },
  {
    value: '460',
    label: 'Möbel > Sofas',
  },
  {
    value: '7248',
    label: 'Möbel > Stuhlzubehör',
  },
  {
    value: '8206',
    label: 'Möbel > Stuhlzubehör > Ersatzteile für Hängesessel',
  },
  {
    value: '443',
    label: 'Möbel > Stühle',
  },
  {
    value: '500051',
    label: 'Möbel > Stühle > Bodenstühle',
  },
  {
    value: '2919',
    label: 'Möbel > Stühle > Elektrische Massagesessel',
  },
  {
    value: '1463',
    label: 'Möbel > Stühle > Hocker & Barhocker',
  },
  {
    value: '7197',
    label: 'Möbel > Stühle > Hängesessel',
  },
  {
    value: '3358',
    label: 'Möbel > Stühle > Klappstühle & Holzhocker',
  },
  {
    value: '5886',
    label: 'Möbel > Stühle > Küchen- und Esszimmerstühle',
  },
  {
    value: '456',
    label: 'Möbel > Stühle > Liegemöbel',
  },
  {
    value: '6800',
    label: 'Möbel > Stühle > Multimediasessel',
  },
  {
    value: '2002',
    label: 'Möbel > Stühle > Schaukelstühle',
  },
  {
    value: '6499',
    label: 'Möbel > Stühle > Sessel',
  },
  {
    value: '6859',
    label: 'Möbel > Stühle > Sessel ohne Armlehnen',
  },
  {
    value: '438',
    label: 'Möbel > Stühle > Sitzsäcke',
  },
  {
    value: '6392',
    label: 'Möbel > Tische',
  },
  {
    value: '6351',
    label: 'Möbel > Tische > Kinderspieltische',
  },
  {
    value: '4080',
    label: 'Möbel > Tische > Klapptische',
  },
  {
    value: '4484',
    label: 'Möbel > Tische > Kotatsu',
  },
  {
    value: '4355',
    label: 'Möbel > Tische > Küchen- & Esszimmertische',
  },
  {
    value: '462',
    label: 'Möbel > Tische > Nachttische',
  },
  {
    value: '5121',
    label: 'Möbel > Tische > Nähmaschinentische',
  },
  {
    value: '2693',
    label: 'Möbel > Tische > Poker- & Spieltische',
  },
  {
    value: '6369',
    label: 'Möbel > Tische > Ziertische',
  },
  {
    value: '1549',
    label: 'Möbel > Tische > Ziertische > Beistelltische',
  },
  {
    value: '1395',
    label: 'Möbel > Tische > Ziertische > Couchtische',
  },
  {
    value: '1602',
    label: 'Möbel > Tische > Ziertische > Konsolentische',
  },
  {
    value: '6913',
    label: 'Möbel > Tischzubehör',
  },
  {
    value: '6911',
    label: 'Möbel > Tischzubehör > Tischbeine',
  },
  {
    value: '6910',
    label: 'Möbel > Tischzubehör > Tischplatten',
  },
  {
    value: '503765',
    label: 'Möbel > Zubehör für Büromöbel',
  },
  {
    value: '7559',
    label: 'Möbel > Zubehör für Büromöbel > Bürostuhl-Zubehör',
  },
  {
    value: '503766',
    label: 'Möbel > Zubehör für Büromöbel > Schreibtischteile & -zubehör',
  },
  {
    value: '6909',
    label:
      'Möbel > Zubehör für Büromöbel > Zubehör für Arbeitsplätze im Großraumbüro',
  },
  {
    value: '412',
    label: 'Nahrungsmittel, Getränke & Tabak',
  },
  {
    value: '413',
    label: 'Nahrungsmittel, Getränke & Tabak > Getränke',
  },
  {
    value: '499676',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke',
  },
  {
    value: '5887',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Alkopops',
  },
  {
    value: '414',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Bier',
  },
  {
    value: '6761',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Cidre',
  },
  {
    value: '7486',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Cocktail-Bitter',
  },
  {
    value: '5725',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Cocktail-Premix',
  },
  {
    value: '417',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Liköre & Spirituosen',
  },
  {
    value: '505761',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Liköre & Spirituosen > Absinth',
  },
  {
    value: '1671',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Liköre & Spirituosen > Gin',
  },
  {
    value: '2933',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Liköre & Spirituosen > Liköre',
  },
  {
    value: '2605',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Liköre & Spirituosen > Rum',
  },
  {
    value: '502976',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Liköre & Spirituosen > Shochu & Soju',
  },
  {
    value: '2220',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Liköre & Spirituosen > Tequila',
  },
  {
    value: '2364',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Liköre & Spirituosen > Weinbrand',
  },
  {
    value: '1926',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Liköre & Spirituosen > Whiskey',
  },
  {
    value: '2107',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Liköre & Spirituosen > Wodka',
  },
  {
    value: '421',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Alkoholische Getränke > Wein',
  },
  {
    value: '6797',
    label: 'Nahrungsmittel, Getränke & Tabak > Getränke > Buttermilch',
  },
  {
    value: '8030',
    label: 'Nahrungsmittel, Getränke & Tabak > Getränke > Eierlikör',
  },
  {
    value: '7528',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Essighaltige Getränke',
  },
  {
    value: '8036',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Getränke mit Fruchtgeschmack',
  },
  {
    value: '6848',
    label: 'Nahrungsmittel, Getränke & Tabak > Getränke > Getränkepulver',
  },
  {
    value: '1868',
    label: 'Nahrungsmittel, Getränke & Tabak > Getränke > Kaffee',
  },
  {
    value: '415',
    label: 'Nahrungsmittel, Getränke & Tabak > Getränke > Kakao',
  },
  {
    value: '2628',
    label: 'Nahrungsmittel, Getränke & Tabak > Getränke > Limonaden',
  },
  {
    value: '418',
    label: 'Nahrungsmittel, Getränke & Tabak > Getränke > Milch',
  },
  {
    value: '5724',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Nicht-tierische Milch',
  },
  {
    value: '2887',
    label: 'Nahrungsmittel, Getränke & Tabak > Getränke > Saft',
  },
  {
    value: '5723',
    label:
      'Nahrungsmittel, Getränke & Tabak > Getränke > Sportgetränke & Energy Drinks',
  },
  {
    value: '2073',
    label: 'Nahrungsmittel, Getränke & Tabak > Getränke > Tees & Aufgüsse',
  },
  {
    value: '420',
    label: 'Nahrungsmittel, Getränke & Tabak > Getränke > Wasser',
  },
  {
    value: '422',
    label: 'Nahrungsmittel, Getränke & Tabak > Lebensmittel',
  },
  {
    value: '1876',
    label: 'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren',
  },
  {
    value: '5904',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Backwaren-Mischungen',
  },
  {
    value: '1573',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Bagels',
  },
  {
    value: '424',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Brot & Brötchen',
  },
  {
    value: '6195',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Cupcakes',
  },
  {
    value: '5751',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Donuts',
  },
  {
    value: '5790',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Eistüten',
  },
  {
    value: '5750',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Feingebäck',
  },
  {
    value: '5054',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Karamell-Fudge',
  },
  {
    value: '2194',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Kuchen',
  },
  {
    value: '6196',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Kuchen zum Kaffee',
  },
  {
    value: '1895',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Muffins',
  },
  {
    value: '5749',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Pasteten & Torten',
  },
  {
    value: '2229',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Plätzchen',
  },
  {
    value: '6891',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Tacoschalen',
  },
  {
    value: '5748',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Backwaren > Tortillas & Wraps',
  },
  {
    value: '136',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Delikatessen Präsentkörbe',
  },
  {
    value: '5740',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Dips & Brotaufstriche',
  },
  {
    value: '6204',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Dips & Brotaufstriche > Apfelbutter',
  },
  {
    value: '5785',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Dips & Brotaufstriche > Frischkäse',
  },
  {
    value: '6830',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Dips & Brotaufstriche > Gemüsedip',
  },
  {
    value: '5742',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Dips & Brotaufstriche > Guacamole',
  },
  {
    value: '5741',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Dips & Brotaufstriche > Hummus',
  },
  {
    value: '6831',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Dips & Brotaufstriche > Käse-Dips & -aufstriche',
  },
  {
    value: '2188',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Dips & Brotaufstriche > Marmeladen & Gelees',
  },
  {
    value: '3965',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Dips & Brotaufstriche > Nussbutter',
  },
  {
    value: '1702',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Dips & Brotaufstriche > Salsa-Saucen',
  },
  {
    value: '6784',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Dips & Brotaufstriche > Tapenade',
  },
  {
    value: '5814',
    label: 'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Fertiggerichte',
  },
  {
    value: '499988',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Fertiggerichte > Fertige Mahlzeiten und Hauptgerichte',
  },
  {
    value: '499989',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Fertiggerichte > Fertige Vorspeisen und Beilagen',
  },
  {
    value: '432',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Fleisch, Fisch, Meeresfrüchte & Eier',
  },
  {
    value: '4627',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Fleisch, Fisch, Meeresfrüchte & Eier > Eier',
  },
  {
    value: '4629',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Fleisch, Fisch, Meeresfrüchte & Eier > Fisch & Meeresfrüchte',
  },
  {
    value: '5813',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Fleisch, Fisch, Meeresfrüchte & Eier > Fisch & Meeresfrüchte > Fisch- & Meeresfrüchtekonserven',
  },
  {
    value: '5812',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Fleisch, Fisch, Meeresfrüchte & Eier > Fisch & Meeresfrüchte > Frische(r) & tiefgefrorene(r) Fisch/Meeresfrüchte',
  },
  {
    value: '4628',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Fleisch, Fisch, Meeresfrüchte & Eier > Fleisch',
  },
  {
    value: '5804',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Fleisch, Fisch, Meeresfrüchte & Eier > Fleisch > Fleisch- & Wurstwaren',
  },
  {
    value: '5811',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Fleisch, Fisch, Meeresfrüchte & Eier > Fleisch > Fleischkonserven',
  },
  {
    value: '5805',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Fleisch, Fisch, Meeresfrüchte & Eier > Fleisch > Frisches & Tiefgefrorenes Fleisch',
  },
  {
    value: '2660',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten',
  },
  {
    value: '5775',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Backaromen',
  },
  {
    value: '5765',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Backfett & Schmalz',
  },
  {
    value: '2572',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Backmischungen',
  },
  {
    value: '5778',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Backmischungen für Waffeln und Pfannkuchen',
  },
  {
    value: '2803',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Backpulver',
  },
  {
    value: '5774',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Backsoda',
  },
  {
    value: '7506',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Backwaren-Deko-Sets',
  },
  {
    value: '5776',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Blockschokoloade',
  },
  {
    value: '4613',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Bohnenpasten & -saucen',
  },
  {
    value: '500093',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Dosen- und Trockenmilch',
  },
  {
    value: '6775',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Essbare Backdekoration',
  },
  {
    value: '2140',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Essig',
  },
  {
    value: '8076',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Gemahlene Getreideprodukte',
  },
  {
    value: '5768',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Geschmacksneutrale Gelatine',
  },
  {
    value: '2905',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Hefe',
  },
  {
    value: '5777',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Kochwein',
  },
  {
    value: '5800',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Kuchen- & Pastetenfüllungen',
  },
  {
    value: '7127',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Lebensmittelfarben',
  },
  {
    value: '5770',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Maissirup',
  },
  {
    value: '5771',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Maisstärke',
  },
  {
    value: '5767',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Marshmallows',
  },
  {
    value: '2775',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Mehl',
  },
  {
    value: '5766',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Melasse',
  },
  {
    value: '5773',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Paniermehl',
  },
  {
    value: '6754',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Raspeln, Flocken & Tröpfchen',
  },
  {
    value: '2126',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Speiseöle',
  },
  {
    value: '7354',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Starterkulturen',
  },
  {
    value: '6774',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Stärkemehl & Paniermischungen',
  },
  {
    value: '499707',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Tapiokaperlen',
  },
  {
    value: '5752',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Teig',
  },
  {
    value: '5755',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Teig > Brot- & Gebäckteig',
  },
  {
    value: '5753',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Teig > Pastetenteig',
  },
  {
    value: '5756',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Teig > Plätzchen- & Brownieteig',
  },
  {
    value: '6922',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Tomatenmark',
  },
  {
    value: '499986',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Zitronen- und Limettensaft',
  },
  {
    value: '503734',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Zucker & Süßstoffe',
  },
  {
    value: '5769',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Zuckerguss & Glasur',
  },
  {
    value: '5105',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Koch- & Backzutaten > Zuckerwatte',
  },
  {
    value: '431',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Körner, Reis & Getreide',
  },
  {
    value: '4683',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Körner, Reis & Getreide > Amarant',
  },
  {
    value: '4684',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Körner, Reis & Getreide > Buchweizen',
  },
  {
    value: '7196',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Körner, Reis & Getreide > Couscous',
  },
  {
    value: '4689',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Körner, Reis & Getreide > Frühstücksflocken & Müsli',
  },
  {
    value: '4687',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Körner, Reis & Getreide > Gerste',
  },
  {
    value: '4690',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Körner, Reis & Getreide > Haferflocken, Grütze & Grieß',
  },
  {
    value: '4686',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Körner, Reis & Getreide > Hirse',
  },
  {
    value: '6259',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Körner, Reis & Getreide > Quinoa',
  },
  {
    value: '4682',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Körner, Reis & Getreide > Reis',
  },
  {
    value: '7374',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Körner, Reis & Getreide > Roggen',
  },
  {
    value: '4688',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Körner, Reis & Getreide > Weizen',
  },
  {
    value: '428',
    label: 'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Milchprodukte',
  },
  {
    value: '5827',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Milchprodukte > Butter & Margarine',
  },
  {
    value: '1855',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Milchprodukte > Hüttenkäse',
  },
  {
    value: '1954',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Milchprodukte > Joghurt',
  },
  {
    value: '4418',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Milchprodukte > Kaffeeweißer',
  },
  {
    value: '429',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Milchprodukte > Käse',
  },
  {
    value: '5786',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Milchprodukte > Sahne',
  },
  {
    value: '5787',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Milchprodukte > Sauerrahm',
  },
  {
    value: '6821',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Milchprodukte > Schlagsahne',
  },
  {
    value: '433',
    label: 'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Nüsse & Samen',
  },
  {
    value: '430',
    label: 'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse',
  },
  {
    value: '5797',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Dosenbohnen',
  },
  {
    value: '5793',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse',
  },
  {
    value: '6716',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Arakachas',
  },
  {
    value: '6570',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Artischocken',
  },
  {
    value: '6613',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Auberginen',
  },
  {
    value: '6622',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse',
  },
  {
    value: '6610',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse > Choi Sum',
  },
  {
    value: '6637',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse > Gartensalat',
  },
  {
    value: '6629',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse > Grünkohl',
  },
  {
    value: '6597',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse > Mangold',
  },
  {
    value: '6584',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse > Pak Choi',
  },
  {
    value: '6569',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse > Rauke',
  },
  {
    value: '6581',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse > Rübenblätter',
  },
  {
    value: '5792',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse > Salatmischungen',
  },
  {
    value: '6695',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse > Spinat',
  },
  {
    value: '6656',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse > Wasserspinat',
  },
  {
    value: '6717',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse > Wegwarte',
  },
  {
    value: '6706',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blattgemüse > Yu Choy',
  },
  {
    value: '6600',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Blumenkohl',
  },
  {
    value: '6577',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Bohnen',
  },
  {
    value: '6587',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Borretsch',
  },
  {
    value: '6591',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Brokkoli',
  },
  {
    value: '6617',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Chinesischer Brokkoli',
  },
  {
    value: '6669',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Erbsen',
  },
  {
    value: '6615',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Farnspitzen',
  },
  {
    value: '6816',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Fenchelknollen',
  },
  {
    value: '499905',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Gemüsemischungen',
  },
  {
    value: '6618',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Gobo-Wurzel',
  },
  {
    value: '6608',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Gurken',
  },
  {
    value: '6619',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Ingwer',
  },
  {
    value: '6808',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Kaktusblätter',
  },
  {
    value: '6586',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Kartoffeln',
  },
  {
    value: '6620',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Knoblauch',
  },
  {
    value: '6592',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Kohl',
  },
  {
    value: '6628',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Kohlrabi',
  },
  {
    value: '505354',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Kürbisse',
  },
  {
    value: '6644',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Lotoswurzel',
  },
  {
    value: '6609',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Mais',
  },
  {
    value: '6705',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Maniok',
  },
  {
    value: '6595',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Möhren',
  },
  {
    value: '6657',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Okra',
  },
  {
    value: '6668',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Paprikaschoten',
  },
  {
    value: '6663',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Pastinaken',
  },
  {
    value: '6664',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Petersilienwurzeln',
  },
  {
    value: '6653',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Pilze',
  },
  {
    value: '6627',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Porree',
  },
  {
    value: '6682',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Radieschen',
  },
  {
    value: '6631',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Rettich',
  },
  {
    value: '6681',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Rhabarber',
  },
  {
    value: '6590',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Rosenkohl',
  },
  {
    value: '6616',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Rutenkohl',
  },
  {
    value: '6580',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Rüben',
  },
  {
    value: '6818',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Schalotten',
  },
  {
    value: '6599',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Sellerie',
  },
  {
    value: '6598',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Sellerieknollen',
  },
  {
    value: '6596',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Spanische Artischocken',
  },
  {
    value: '6568',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Spargel',
  },
  {
    value: '503761',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Sprossen',
  },
  {
    value: '505329',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Steckrüben & Kohlrüben',
  },
  {
    value: '6585',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Süßkartoffeln',
  },
  {
    value: '6692',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Tamarillos',
  },
  {
    value: '6643',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Tannias',
  },
  {
    value: '6704',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Tarowurzeln',
  },
  {
    value: '6703',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Tomaten',
  },
  {
    value: '6693',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Topinambur',
  },
  {
    value: '6701',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Wasserkastanien',
  },
  {
    value: '6700',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Wasserkresse',
  },
  {
    value: '7193',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Weizengras',
  },
  {
    value: '6630',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Yambohnen',
  },
  {
    value: '8515',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Yams',
  },
  {
    value: '6694',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Zuckerrohr',
  },
  {
    value: '6655',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Gemüse > Zwiebeln',
  },
  {
    value: '5795',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst',
  },
  {
    value: '6670',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Ananas',
  },
  {
    value: '6571',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Atemoyas',
  },
  {
    value: '6572',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Avocados',
  },
  {
    value: '6574',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Bananen',
  },
  {
    value: '6582',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Beeren',
  },
  {
    value: '6573',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Berg-Papayas',
  },
  {
    value: '6665',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Birnen',
  },
  {
    value: '6614',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Brasilianische Guaven',
  },
  {
    value: '6688',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Breiäpfel',
  },
  {
    value: '6589',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Brotfrucht',
  },
  {
    value: '6602',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Cherimoyas',
  },
  {
    value: '6812',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Datteln',
  },
  {
    value: '6810',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Feigen',
  },
  {
    value: '6673',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Granatäpfel',
  },
  {
    value: '6647',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Große Sapoten',
  },
  {
    value: '6625',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Guaven',
  },
  {
    value: '6624',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Homli-Früchte',
  },
  {
    value: '6593',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Kaktusfeigen',
  },
  {
    value: '6633',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Kiwis',
  },
  {
    value: '6809',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Kokosnüsse',
  },
  {
    value: '6640',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Longan',
  },
  {
    value: '6639',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Loquats',
  },
  {
    value: '6638',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Lychees',
  },
  {
    value: '6813',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Madrono-Früchte',
  },
  {
    value: '6645',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Mangostanfrüchte',
  },
  {
    value: '6649',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Melonen',
  },
  {
    value: '499906',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Obstmischungen',
  },
  {
    value: '6661',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Papayas',
  },
  {
    value: '6667',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Passionsfrüchte',
  },
  {
    value: '6672',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Persimonen',
  },
  {
    value: '6671',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Physalis',
  },
  {
    value: '6676',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Pitahayas',
  },
  {
    value: '6679',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Quitten',
  },
  {
    value: '6678',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Rambutans',
  },
  {
    value: '6687',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Sapoten',
  },
  {
    value: '6691',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Stachelannonen',
  },
  {
    value: '503760',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Steinfrüchte',
  },
  {
    value: '6567',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Steinfrüchte > Aprikosen',
  },
  {
    value: '6601',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Steinfrüchte > Kirschen',
  },
  {
    value: '6646',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Steinfrüchte > Mangos',
  },
  {
    value: '505301',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Steinfrüchte > Pfirsiche & Nektarinen',
  },
  {
    value: '6674',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Steinfrüchte > Pflaumen',
  },
  {
    value: '6675',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Steinfrüchte > Plumcots',
  },
  {
    value: '6594',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Sternfrüchte',
  },
  {
    value: '6698',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Tamarinden',
  },
  {
    value: '6626',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Trauben',
  },
  {
    value: '6814',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Zimtäpfel',
  },
  {
    value: '503759',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Zitrusfrüchte',
  },
  {
    value: '6621',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Zitrusfrüchte > Grapefruits',
  },
  {
    value: '6632',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Zitrusfrüchte > Kumquats',
  },
  {
    value: '6641',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Zitrusfrüchte > Limequats',
  },
  {
    value: '6642',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Zitrusfrüchte > Limetten',
  },
  {
    value: '6658',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Zitrusfrüchte > Orangen',
  },
  {
    value: '6697',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Zitrusfrüchte > Tangelos',
  },
  {
    value: '6636',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Zitrusfrüchte > Zitronen',
  },
  {
    value: '6566',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Frisches & Tiefgefrorenes Obst > Äpfel',
  },
  {
    value: '5794',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Fruchtsoßen',
  },
  {
    value: '5798',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Gemüsekonserven',
  },
  {
    value: '5796',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Getrocknete Bohnen',
  },
  {
    value: '1755',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Getrocknete Früchte',
  },
  {
    value: '5799',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Obstkonserven',
  },
  {
    value: '7387',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Obst & Gemüse > Trockengemüse',
  },
  {
    value: '434',
    label: 'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Pasta & Nudeln',
  },
  {
    value: '6219',
    label: 'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Schokofrüchte',
  },
  {
    value: '2423',
    label: 'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Suppen & Brühen',
  },
  {
    value: '4748',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Süßigkeiten & Schokolade',
  },
  {
    value: '5788',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Tiefkühl-Desserts & -Neuheiten',
  },
  {
    value: '6873',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Tiefkühl-Desserts & -Neuheiten > Eissnacks',
  },
  {
    value: '499991',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Tiefkühl-Desserts & -Neuheiten > Speiseeis und gefrorener Joghurt',
  },
  {
    value: '5789',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Tiefkühl-Desserts & -Neuheiten > Stieleis',
  },
  {
    value: '5807',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Tofu- & Soja-Produkte',
  },
  {
    value: '6843',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Tofu- & Soja-Produkte > Fleischalternativen',
  },
  {
    value: '6839',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Tofu- & Soja-Produkte > Käsealternativen',
  },
  {
    value: '5808',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Tofu- & Soja-Produkte > Seitan',
  },
  {
    value: '5810',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Tofu- & Soja-Produkte > Tempeh',
  },
  {
    value: '5809',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Tofu- & Soja-Produkte > Tofu',
  },
  {
    value: '423',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks',
  },
  {
    value: '3446',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Brezeln',
  },
  {
    value: '2392',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Chips',
  },
  {
    value: '5746',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Croûtons',
  },
  {
    value: '6785',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Dessertküchlein',
  },
  {
    value: '5744',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Fruchtsnacks',
  },
  {
    value: '5747',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Getreide- & Müsliriegel',
  },
  {
    value: '7159',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Grissini',
  },
  {
    value: '1445',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Kräcker',
  },
  {
    value: '6192',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Käse-Flips',
  },
  {
    value: '1534',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Popcorn',
  },
  {
    value: '5743',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Pudding & Gelatine-Snacks',
  },
  {
    value: '7327',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Reiskuchen',
  },
  {
    value: '2432',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Reiswaffeln',
  },
  {
    value: '6847',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Salatgarnituren',
  },
  {
    value: '6194',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Schweineschwarten',
  },
  {
    value: '7427',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Sesamsticks',
  },
  {
    value: '5745',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Studentenfutter',
  },
  {
    value: '3284',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Vorspeisen & Snacks > Trockenfleisch',
  },
  {
    value: '4608',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzen & Verfeinern',
  },
  {
    value: '1529',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzen & Verfeinern > Kräuter & Gewürze',
  },
  {
    value: '4610',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzen & Verfeinern > Mononatriumglutamat',
  },
  {
    value: '6199',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzen & Verfeinern > Pfeffer',
  },
  {
    value: '4611',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzen & Verfeinern > Salz',
  },
  {
    value: '427',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen',
  },
  {
    value: '5762',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Bratensoße',
  },
  {
    value: '4614',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Chilisauce',
  },
  {
    value: '6772',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Cocktailsaucen',
  },
  {
    value: '6905',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Currysauce',
  },
  {
    value: '6845',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Dessertsaucen',
  },
  {
    value: '6844',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Dessertsaucen > Eissaucen',
  },
  {
    value: '6854',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Dessertsaucen > Fruchttopping',
  },
  {
    value: '500076',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Eingelegtes Obst und Gemüse',
  },
  {
    value: '5763',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Fischsauce',
  },
  {
    value: '4947',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Honig',
  },
  {
    value: '2018',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Ketchup',
  },
  {
    value: '500074',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Marinaden und Grillsoßen',
  },
  {
    value: '1568',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Mayonnaise',
  },
  {
    value: '6782',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Meerrettichsaucen',
  },
  {
    value: '5760',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Oliven & Kapern',
  },
  {
    value: '5759',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Pastasoßen',
  },
  {
    value: '6203',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Pizzasoße',
  },
  {
    value: '1969',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Salatdressing',
  },
  {
    value: '4615',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Sataysauce',
  },
  {
    value: '6783',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Sauce tartare',
  },
  {
    value: '1387',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Senf',
  },
  {
    value: '4943',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Sirup',
  },
  {
    value: '4616',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Sojasauce',
  },
  {
    value: '500089',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Süßsaure Soßen',
  },
  {
    value: '4692',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Tahini',
  },
  {
    value: '500105',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Weiße Soßen und Rahmsoßen',
  },
  {
    value: '6246',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Worcestershiresauce',
  },
  {
    value: '500075',
    label:
      'Nahrungsmittel, Getränke & Tabak > Lebensmittel > Würzmittel & Saucen > Würzsoße und Chutney',
  },
  {
    value: '435',
    label: 'Nahrungsmittel, Getränke & Tabak > Tabakprodukte',
  },
  {
    value: '3916',
    label: 'Nahrungsmittel, Getränke & Tabak > Tabakprodukte > Kautabak',
  },
  {
    value: '499963',
    label: 'Nahrungsmittel, Getränke & Tabak > Tabakprodukte > Pfeifen',
  },
  {
    value: '3741',
    label: 'Nahrungsmittel, Getränke & Tabak > Tabakprodukte > Tabak',
  },
  {
    value: '4091',
    label:
      'Nahrungsmittel, Getränke & Tabak > Tabakprodukte > Vaporizer & Rauchlose Zigaretten',
  },
  {
    value: '3151',
    label: 'Nahrungsmittel, Getränke & Tabak > Tabakprodukte > Zigaretten',
  },
  {
    value: '3682',
    label: 'Nahrungsmittel, Getränke & Tabak > Tabakprodukte > Zigarren',
  },
  {
    value: '5605',
    label: 'Religion & Feierlichkeiten',
  },
  {
    value: '5606',
    label: 'Religion & Feierlichkeiten > Artikel für Gedenkzeremonien',
  },
  {
    value: '5607',
    label: 'Religion & Feierlichkeiten > Artikel für Gedenkzeremonien > Urnen',
  },
  {
    value: '5455',
    label: 'Religion & Feierlichkeiten > Artikel für Hochzeitszeremonien',
  },
  {
    value: '503723',
    label:
      'Religion & Feierlichkeiten > Artikel für Hochzeitszeremonien > Hochzeitsläufer',
  },
  {
    value: '5456',
    label:
      'Religion & Feierlichkeiten > Artikel für Hochzeitszeremonien > Körbe für Blumenmädchen',
  },
  {
    value: '5457',
    label:
      'Religion & Feierlichkeiten > Artikel für Hochzeitszeremonien > Ringkissen',
  },
  {
    value: '97',
    label: 'Religion & Feierlichkeiten > Religiöse Artikel',
  },
  {
    value: '7120',
    label: 'Religion & Feierlichkeiten > Religiöse Artikel > Altare',
  },
  {
    value: '328060',
    label: 'Religion & Feierlichkeiten > Religiöse Artikel > Gebetskarten',
  },
  {
    value: '3923',
    label: 'Religion & Feierlichkeiten > Religiöse Artikel > Gebetsketten',
  },
  {
    value: '1949',
    label:
      'Religion & Feierlichkeiten > Religiöse Artikel > Religiöse Schleier',
  },
  {
    value: '499711',
    label: 'Religion & Feierlichkeiten > Religiöse Artikel > Tarotkarten',
  },
  {
    value: '2092',
    label: 'Software',
  },
  {
    value: '313',
    label: 'Software > Computersoftware',
  },
  {
    value: '5299',
    label: 'Software > Computersoftware > Antiviren- & Sicherheitssoftware',
  },
  {
    value: '321',
    label: 'Software > Computersoftware > Betriebssysteme',
  },
  {
    value: '5303',
    label: 'Software > Computersoftware > Büroanwendungssoftware',
  },
  {
    value: '315',
    label: 'Software > Computersoftware > Compiler & Programmierungstools',
  },
  {
    value: '5301',
    label: 'Software > Computersoftware > Computerdienstprogramme & -wartung',
  },
  {
    value: '3283',
    label: 'Software > Computersoftware > GPS-Karten & -Software',
  },
  {
    value: '318',
    label: 'Software > Computersoftware > Handheld- & PDA-Software',
  },
  {
    value: '317',
    label: 'Software > Computersoftware > Lernsoftware',
  },
  {
    value: '5302',
    label: 'Software > Computersoftware > Netzwerksoftware',
  },
  {
    value: '319',
    label: 'Software > Computersoftware > Software für Multimedia & Design',
  },
  {
    value: '4950',
    label:
      'Software > Computersoftware > Software für Multimedia & Design > Animationsbearbeitungssoftware',
  },
  {
    value: '4951',
    label:
      'Software > Computersoftware > Software für Multimedia & Design > Graphic-Design-Software',
  },
  {
    value: '4949',
    label:
      'Software > Computersoftware > Software für Multimedia & Design > Home-Publishing-Software',
  },
  {
    value: '5096',
    label:
      'Software > Computersoftware > Software für Multimedia & Design > Software für Musikkomposition',
  },
  {
    value: '6027',
    label:
      'Software > Computersoftware > Software für Multimedia & Design > Software zur Erstellung von 3D-Modellen',
  },
  {
    value: '6029',
    label:
      'Software > Computersoftware > Software für Multimedia & Design > Software zur Innenraumgestaltung',
  },
  {
    value: '6028',
    label:
      'Software > Computersoftware > Software für Multimedia & Design > Software zur Medienwiedergabe',
  },
  {
    value: '4952',
    label:
      'Software > Computersoftware > Software für Multimedia & Design > Tonbearbeitungssoftware',
  },
  {
    value: '4953',
    label:
      'Software > Computersoftware > Software für Multimedia & Design > Videobearbeitungssoftware',
  },
  {
    value: '4954',
    label:
      'Software > Computersoftware > Software für Multimedia & Design > Web-Design-Software',
  },
  {
    value: '5304',
    label: 'Software > Computersoftware > Steuer- & Buchhaltungssoftware',
  },
  {
    value: '5300',
    label:
      'Software > Computersoftware > Unternehmens- & Produktivitätssoftware',
  },
  {
    value: '7225',
    label: 'Software > Computersoftware > Wiederherstellungsdatenträger',
  },
  {
    value: '5127',
    label: 'Software > Computersoftware > Wörterbuch- & Übersetzungssoftware',
  },
  {
    value: '5032',
    label: 'Software > Digital-Content-Software',
  },
  {
    value: '5034',
    label: 'Software > Digital-Content-Software > Computer-Icons',
  },
  {
    value: '5035',
    label: 'Software > Digital-Content-Software > Desktop-Hintergrundbilder',
  },
  {
    value: '500046',
    label: 'Software > Digital-Content-Software > Digitales Bildmaterial',
  },
  {
    value: '8022',
    label: 'Software > Digital-Content-Software > Dokumentvorlagen',
  },
  {
    value: '5036',
    label: 'Software > Digital-Content-Software > Fonts',
  },
  {
    value: '2065',
    label:
      'Software > Digital-Content-Software > Stock-Fotografie & Videoclips',
  },
  {
    value: '5935',
    label: 'Software > Digital-Content-Software > Virtuelles Geld',
  },
  {
    value: '1279',
    label: 'Software > PC- & Videospiele',
  },
  {
    value: '1239',
    label: 'Spielzeuge & Spiele',
  },
  {
    value: '3867',
    label: 'Spielzeuge & Spiele > Puzzles & Geduldspiele',
  },
  {
    value: '6725',
    label: 'Spielzeuge & Spiele > Puzzles & Geduldspiele > Holzsteckpuzzle',
  },
  {
    value: '4011',
    label:
      'Spielzeuge & Spiele > Puzzles & Geduldspiele > Mechanische Geduldspiele',
  },
  {
    value: '7081',
    label: 'Spielzeuge & Spiele > Puzzles & Geduldspiele > Puzzle-Zubehör',
  },
  {
    value: '2618',
    label: 'Spielzeuge & Spiele > Puzzles & Geduldspiele > Puzzles',
  },
  {
    value: '4648',
    label: 'Spielzeuge & Spiele > Spiel-Timer',
  },
  {
    value: '3793',
    label: 'Spielzeuge & Spiele > Spiele',
  },
  {
    value: '6329',
    label: 'Spielzeuge & Spiele > Spiele > Battle Tops',
  },
  {
    value: '3749',
    label: 'Spielzeuge & Spiele > Spiele > Bingo-Spiele',
  },
  {
    value: '7411',
    label: 'Spielzeuge & Spiele > Spiele > Blackjack- & Würfelspiel-Sets',
  },
  {
    value: '1246',
    label: 'Spielzeuge & Spiele > Spiele > Brettspiele',
  },
  {
    value: '6054',
    label: 'Spielzeuge & Spiele > Spiele > Geschicklichkeitsspiele',
  },
  {
    value: '8472',
    label: 'Spielzeuge & Spiele > Spiele > Glücksspielautomaten',
  },
  {
    value: '6794',
    label: 'Spielzeuge & Spiele > Spiele > Kampfkreiselzubehör',
  },
  {
    value: '1247',
    label: 'Spielzeuge & Spiele > Spiele > Kartenspiele',
  },
  {
    value: '6853',
    label: 'Spielzeuge & Spiele > Spiele > Kartenspielzubehör',
  },
  {
    value: '6038',
    label: 'Spielzeuge & Spiele > Spiele > Legespiele',
  },
  {
    value: '7383',
    label: 'Spielzeuge & Spiele > Spiele > Pokerchip-Zubehör',
  },
  {
    value: '7384',
    label:
      'Spielzeuge & Spiele > Spiele > Pokerchip-Zubehör > Behälter für Pokerchips',
  },
  {
    value: '5403',
    label: 'Spielzeuge & Spiele > Spiele > Pokerchips & -sets',
  },
  {
    value: '7412',
    label: 'Spielzeuge & Spiele > Spiele > Rouletteräder & -zubehör',
  },
  {
    value: '4554',
    label: 'Spielzeuge & Spiele > Spiele > Tragbare elektronische Spiele',
  },
  {
    value: '6037',
    label: 'Spielzeuge & Spiele > Spiele > Würfelsets & -spiele',
  },
  {
    value: '1249',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen',
  },
  {
    value: '7219',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Hüpfburg-Zubehör',
  },
  {
    value: '6396',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Hüpfburgen',
  },
  {
    value: '2867',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Rutschen',
  },
  {
    value: '2743',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Sandkästen',
  },
  {
    value: '6270',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Schaukeln',
  },
  {
    value: '6271',
    label:
      'Spielzeuge & Spiele > Spielzeug für draußen > Schaukeln & Spielplatzgeräte',
  },
  {
    value: '1251',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Spielhäuser',
  },
  {
    value: '6397',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Spielzelte & -tunnel',
  },
  {
    value: '1863',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Springstöcke',
  },
  {
    value: '3948',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Stelzen',
  },
  {
    value: '1738',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Trampoline',
  },
  {
    value: '5524',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Trampolinzubehör',
  },
  {
    value: '6464',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Wasserspielgeräte',
  },
  {
    value: '500095',
    label:
      'Spielzeuge & Spiele > Spielzeug für draußen > Wasserspielgeräte > Planschbecken und Wasserrutschen',
  },
  {
    value: '6465',
    label:
      'Spielzeuge & Spiele > Spielzeug für draußen > Wasserspielgeräte > Wassersprenger für Kinder',
  },
  {
    value: '3556',
    label:
      'Spielzeuge & Spiele > Spielzeug für draußen > Wasserspielgeräte > Wassertische',
  },
  {
    value: '6450',
    label: 'Spielzeuge & Spiele > Spielzeug für draußen > Wippen',
  },
  {
    value: '6269',
    label:
      'Spielzeuge & Spiele > Spielzeug für draußen > Zubehör für Schaukeln & Spielplatzgeräte',
  },
  {
    value: '1253',
    label: 'Spielzeuge & Spiele > Spielzeuge',
  },
  {
    value: '2953',
    label: 'Spielzeuge & Spiele > Spielzeuge > Aufziehspielzeug',
  },
  {
    value: '3911',
    label: 'Spielzeuge & Spiele > Spielzeuge > Bad-Spielzeug',
  },
  {
    value: '1254',
    label: 'Spielzeuge & Spiele > Spielzeuge > Bausteine & Bauspielzeug',
  },
  {
    value: '3805',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Bausteine & Bauspielzeug > Baukästen',
  },
  {
    value: '3617',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Bausteine & Bauspielzeug > Holzbausteine',
  },
  {
    value: '3163',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Bausteine & Bauspielzeug > Murmelbahnen',
  },
  {
    value: '3172',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Bausteine & Bauspielzeug > Schaumstoffbausteine',
  },
  {
    value: '3287',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Bausteine & Bauspielzeug > Steckbausteine',
  },
  {
    value: '7311',
    label: 'Spielzeuge & Spiele > Spielzeuge > Bällebad-Zubehör',
  },
  {
    value: '7312',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Bällebad-Zubehör > Bälle für Bällebad',
  },
  {
    value: '3207',
    label: 'Spielzeuge & Spiele > Spielzeuge > Bällebäder',
  },
  {
    value: '3074',
    label: 'Spielzeuge & Spiele > Spielzeuge > Bürospielzeug',
  },
  {
    value: '5872',
    label: 'Spielzeuge & Spiele > Spielzeuge > Bürospielzeug > Magnetspielzeug',
  },
  {
    value: '2799',
    label: 'Spielzeuge & Spiele > Spielzeuge > Fahr, Wipp- & Schaukelspielzeug',
  },
  {
    value: '2753',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Fahr, Wipp- & Schaukelspielzeug > Elektrische Kinderfahrzeuge',
  },
  {
    value: '6379',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Fahr, Wipp- & Schaukelspielzeug > Hand- & Bollerwagen',
  },
  {
    value: '2724',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Fahr, Wipp- & Schaukelspielzeug > Schiebe- & Pedalfahrzeuge',
  },
  {
    value: '6407',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Fahr, Wipp- & Schaukelspielzeug > Steckenpferde',
  },
  {
    value: '3441',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Fahr, Wipp- & Schaukelspielzeug > Wipp- & Schaukelspielzeug',
  },
  {
    value: '2546',
    label: 'Spielzeuge & Spiele > Spielzeuge > Ferngesteuertes Spielzeug',
  },
  {
    value: '3601',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Ferngesteuertes Spielzeug > Ferngesteuerte Autos',
  },
  {
    value: '3532',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Ferngesteuertes Spielzeug > Ferngesteuerte Boote',
  },
  {
    value: '3677',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Ferngesteuertes Spielzeug > Ferngesteuerte Flugzeuge',
  },
  {
    value: '3554',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Ferngesteuertes Spielzeug > Ferngesteuerte Hubschrauber',
  },
  {
    value: '5968',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Ferngesteuertes Spielzeug > Ferngesteuerte Motorräder',
  },
  {
    value: '5969',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Ferngesteuertes Spielzeug > Ferngesteuerte Panzer',
  },
  {
    value: '6059',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Ferngesteuertes Spielzeug > Ferngesteuerte Roboter',
  },
  {
    value: '7090',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Ferngesteuertes Spielzeug > Ferngesteuerte Zeppeline',
  },
  {
    value: '1261',
    label: 'Spielzeuge & Spiele > Spielzeuge > Fliegendes Spielzeug',
  },
  {
    value: '3460',
    label: 'Spielzeuge & Spiele > Spielzeuge > Fliegendes Spielzeug > Drachen',
  },
  {
    value: '3966',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Fliegendes Spielzeug > Luft- & Wasserraketen',
  },
  {
    value: '3263',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Fliegendes Spielzeug > Spielzeug-Fallschirme',
  },
  {
    value: '3378',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Fliegendes Spielzeug > Spielzeug-Segelflugzeuge',
  },
  {
    value: '4352',
    label: 'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug',
  },
  {
    value: '7519',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug > Ball- & Becherspiele',
  },
  {
    value: '3733',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug > Flummis',
  },
  {
    value: '4216',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug > Hampelmänner',
  },
  {
    value: '3929',
    label: 'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug > Jo-Jos',
  },
  {
    value: '3466',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug > Kreisel',
  },
  {
    value: '3534',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug > Murmeln',
  },
  {
    value: '7425',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug > Paddleball-Spiele',
  },
  {
    value: '3212',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug > Seifenblasen',
  },
  {
    value: '3874',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug > Seifenblasen-Spielzeug',
  },
  {
    value: '7473',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug > Spiele mit Bändern',
  },
  {
    value: '4177',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug > Springspirale',
  },
  {
    value: '7148',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Interaktives Spielzeug > Teile und Zubehör für Jo-Jos',
  },
  {
    value: '3229',
    label: 'Spielzeuge & Spiele > Spielzeuge > Kinder-Rollenspiele',
  },
  {
    value: '3129',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Kinder-Rollenspiele > Berufespielzeug & Rollenspiele',
  },
  {
    value: '8295',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Kinder-Rollenspiele > Einkaufsladen',
  },
  {
    value: '3659',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Kinder-Rollenspiele > Elektronisches Rollenspielzeug',
  },
  {
    value: '3288',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Kinder-Rollenspiele > Gartengeräte für Kinder',
  },
  {
    value: '4004',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Kinder-Rollenspiele > Haushaltsspielzeug',
  },
  {
    value: '3680',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Kinder-Rollenspiele > Spielgeld & Banken',
  },
  {
    value: '3751',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Kinder-Rollenspiele > Spielwerkzeug',
  },
  {
    value: '3298',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Kinder-Rollenspiele > Spielzeugküchen & Spielzeuglebensmittel',
  },
  {
    value: '1262',
    label: 'Spielzeuge & Spiele > Spielzeuge > Lernspielzeug',
  },
  {
    value: '3088',
    label: 'Spielzeuge & Spiele > Spielzeuge > Lernspielzeug > Ameisenfarmen',
  },
  {
    value: '499938',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Lernspielzeug > Astronomisches Spielzeug und astronomische Modelle',
  },
  {
    value: '3928',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Lernspielzeug > Insektensammlung',
  },
  {
    value: '500015',
    label: 'Spielzeuge & Spiele > Spielzeuge > Lernspielzeug > Lernkarten',
  },
  {
    value: '5529',
    label: 'Spielzeuge & Spiele > Spielzeuge > Lernspielzeug > Lesespiele',
  },
  {
    value: '6466',
    label: 'Spielzeuge & Spiele > Spielzeuge > Lernspielzeug > Schieberechner',
  },
  {
    value: '3500',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Lernspielzeug > Wissenschaftssets',
  },
  {
    value: '1264',
    label: 'Spielzeuge & Spiele > Spielzeuge > Musikspielzeuge',
  },
  {
    value: '3252',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Musikspielzeuge > Spielzeuginstrumente',
  },
  {
    value: '3562',
    label: 'Spielzeuge & Spiele > Spielzeuge > Optische Spielzeuge',
  },
  {
    value: '3301',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Optische Spielzeuge > Kaleidoskope',
  },
  {
    value: '3782',
    label: 'Spielzeuge & Spiele > Spielzeuge > Optische Spielzeuge > Prismen',
  },
  {
    value: '1255',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren',
  },
  {
    value: '6058',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren > Action- & Spielzeugfiguren',
  },
  {
    value: '1258',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren > Marionetten',
  },
  {
    value: '8021',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren > Papier- & Magnetpuppen',
  },
  {
    value: '1257',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren > Puppen',
  },
  {
    value: '2497',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren > Puppenhaus-Zubehör',
  },
  {
    value: '2499',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren > Puppenhäuser',
  },
  {
    value: '6057',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren > Puppentheater',
  },
  {
    value: '3166',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren > Spielzeugsets',
  },
  {
    value: '1259',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren > Stofftiere',
  },
  {
    value: '7114',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren > Wackelfiguren',
  },
  {
    value: '3584',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren > Zubehör für Puppen & Actionfiguren',
  },
  {
    value: '6056',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Puppen, Spielkombinationen & Spielzeugfiguren > Zubehör für Puppen & Puppentheater',
  },
  {
    value: '3625',
    label: 'Spielzeuge & Spiele > Spielzeuge > Roboter',
  },
  {
    value: '7202',
    label: 'Spielzeuge & Spiele > Spielzeuge > Spielfahrzeug-Zubehör',
  },
  {
    value: '499712',
    label: 'Spielzeuge & Spiele > Spielzeuge > Spielzeug-Geschenkkörbe',
  },
  {
    value: '3731',
    label: 'Spielzeuge & Spiele > Spielzeuge > Spielzeuge zum Malen & Zeichnen',
  },
  {
    value: '505818',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Spielzeuge zum Malen & Zeichnen > Spielteig & Knetmasse',
  },
  {
    value: '3079',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Spielzeuge zum Malen & Zeichnen > Zeichentafeln',
  },
  {
    value: '2505',
    label: 'Spielzeuge & Spiele > Spielzeuge > Spielzeugfahrzeuge',
  },
  {
    value: '3474',
    label: 'Spielzeuge & Spiele > Spielzeuge > Spielzeugfahrzeuge > Rennbahnen',
  },
  {
    value: '3296',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Spielzeugfahrzeuge > Spielzeug-LKWs & -Baumaschinen',
  },
  {
    value: '3551',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Spielzeugfahrzeuge > Spielzeugautos',
  },
  {
    value: '3792',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Spielzeugfahrzeuge > Spielzeugboote',
  },
  {
    value: '3444',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Spielzeugfahrzeuge > Spielzeugflugzeuge',
  },
  {
    value: '3506',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Spielzeugfahrzeuge > Spielzeughubschrauber',
  },
  {
    value: '3590',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Spielzeugfahrzeuge > Spielzeugmotorräder',
  },
  {
    value: '3589',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Spielzeugfahrzeuge > Spielzeugraumschiffe',
  },
  {
    value: '5152',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Spielzeugfahrzeuge > Züge & Eisenbahnsets',
  },
  {
    value: '3627',
    label: 'Spielzeuge & Spiele > Spielzeuge > Spielzeugwaffen',
  },
  {
    value: '500005',
    label: 'Spielzeuge & Spiele > Spielzeuge > Spielzeugwaffenzubehör',
  },
  {
    value: '8127',
    label: 'Spielzeuge & Spiele > Spielzeuge > Sportspielzeug-Zubehör',
  },
  {
    value: '8129',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeug-Zubehör > Fitnessspielzeug-Zubehör',
  },
  {
    value: '8128',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeug-Zubehör > Fitnessspielzeug-Zubehör > Hula-Hoop-Zubehör',
  },
  {
    value: '1266',
    label: 'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge',
  },
  {
    value: '8529',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Angelspielzeug',
  },
  {
    value: '3776',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Baseball für Kinder',
  },
  {
    value: '3552',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Basketball für Kinder',
  },
  {
    value: '3665',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Bowling für Kinder',
  },
  {
    value: '3675',
    label: 'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Bumerangs',
  },
  {
    value: '500113',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Fingerboards & Fingerboard-Sets',
  },
  {
    value: '3199',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Fitness-Spielzeug',
  },
  {
    value: '3215',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Fitness-Spielzeug > Hula-Hoop-Reifen',
  },
  {
    value: '3909',
    label: 'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Footbags',
  },
  {
    value: '4167',
    label: 'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Frisbees',
  },
  {
    value: '3371',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Fußball für Kinder',
  },
  {
    value: '3226',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Golf für Kinder',
  },
  {
    value: '3943',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Hockey für Kinder',
  },
  {
    value: '499965',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Kinderspielbälle',
  },
  {
    value: '505284',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Sportspielzeuge > Spielzeugschläger',
  },
  {
    value: '1268',
    label: 'Spielzeuge & Spiele > Spielzeuge > Strand- & Sandspielzeug',
  },
  {
    value: '7366',
    label: 'Spielzeuge & Spiele > Spielzeuge > Zubehör für Flugspielzeug',
  },
  {
    value: '7368',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Zubehör für Flugspielzeug > Zubehör für Drachen',
  },
  {
    value: '7371',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Zubehör für Flugspielzeug > Zubehör für Drachen > Wickelspulen für Drachen',
  },
  {
    value: '5970',
    label: 'Spielzeuge & Spiele > Spielzeuge > Zubehör für Spielzeugfahrzeuge',
  },
  {
    value: '5971',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Zubehör für Spielzeugfahrzeuge > Autorennbahn-Zubehör',
  },
  {
    value: '5153',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Zubehör für Spielzeugfahrzeuge > Zubehör für Spielzeugeisenbahn',
  },
  {
    value: '2778',
    label:
      'Spielzeuge & Spiele > Spielzeuge > Zubehör für ferngesteuerte Fahrzeuge',
  },
  {
    value: '988',
    label: 'Sportartikel',
  },
  {
    value: '499713',
    label: 'Sportartikel > Athletik',
  },
  {
    value: '499799',
    label: 'Sportartikel > Athletik > Allgemeine Sportartikel',
  },
  {
    value: '499903',
    label:
      'Sportartikel > Athletik > Allgemeine Sportartikel > Ablagen & Transportwagen für Gymnastikmatten',
  },
  {
    value: '7434',
    label: 'Sportartikel > Athletik > Allgemeine Sportartikel > Ballpumpen',
  },
  {
    value: '7433',
    label:
      'Sportartikel > Athletik > Allgemeine Sportartikel > Ballpumpenzubehör',
  },
  {
    value: '7435',
    label:
      'Sportartikel > Athletik > Allgemeine Sportartikel > Ballpumpenzubehör > Ballpumpenaufsätze',
  },
  {
    value: '7397',
    label:
      'Sportartikel > Athletik > Allgemeine Sportartikel > Balltaschen & -wagen',
  },
  {
    value: '3971',
    label: 'Sportartikel > Athletik > Allgemeine Sportartikel > Chalk',
  },
  {
    value: '499803',
    label:
      'Sportartikel > Athletik > Allgemeine Sportartikel > Gymnastikmatten',
  },
  {
    value: '499802',
    label:
      'Sportartikel > Athletik > Allgemeine Sportartikel > Leitern & Hürden für Geschwindigkeits- & Koordinationstraining',
  },
  {
    value: '8319',
    label:
      'Sportartikel > Athletik > Allgemeine Sportartikel > Markierungshütchen',
  },
  {
    value: '8222',
    label:
      'Sportartikel > Athletik > Allgemeine Sportartikel > Masken für Höhentraining',
  },
  {
    value: '3877',
    label:
      'Sportartikel > Athletik > Allgemeine Sportartikel > Megaphone für Sportveranstaltungen',
  },
  {
    value: '499801',
    label:
      'Sportartikel > Athletik > Allgemeine Sportartikel > Sport-Mundschutz',
  },
  {
    value: '6344',
    label:
      'Sportartikel > Athletik > Allgemeine Sportartikel > Stadionsitze und Polster',
  },
  {
    value: '499800',
    label:
      'Sportartikel > Athletik > Allgemeine Sportartikel > Suspensorien für Athleten',
  },
  {
    value: '8077',
    label: 'Sportartikel > Athletik > Allgemeine Sportartikel > Übungsnetze',
  },
  {
    value: '1093',
    label: 'Sportartikel > Athletik > American Football',
  },
  {
    value: '3442',
    label:
      'Sportartikel > Athletik > American Football > American Football-Handschuhe',
  },
  {
    value: '1097',
    label:
      'Sportartikel > Athletik > American Football > American Football-Schutzbekleidung',
  },
  {
    value: '3510',
    label:
      'Sportartikel > Athletik > American Football > American Football-Schutzbekleidung > American Football-Girdle Shorts',
  },
  {
    value: '1098',
    label:
      'Sportartikel > Athletik > American Football > American Football-Schutzbekleidung > American Football-Helme',
  },
  {
    value: '3060',
    label:
      'Sportartikel > Athletik > American Football > American Football-Schutzbekleidung > American Football-Helmzubehör',
  },
  {
    value: '3090',
    label:
      'Sportartikel > Athletik > American Football > American Football-Schutzbekleidung > American Football-Helmzubehör > American Football-Facemasks',
  },
  {
    value: '3343',
    label:
      'Sportartikel > Athletik > American Football > American Football-Schutzbekleidung > American Football-Helmzubehör > American Football-Helmpolster',
  },
  {
    value: '3063',
    label:
      'Sportartikel > Athletik > American Football > American Football-Schutzbekleidung > American Football-Helmzubehör > American Football-Helmvisiere',
  },
  {
    value: '3247',
    label:
      'Sportartikel > Athletik > American Football > American Football-Schutzbekleidung > American Football-Helmzubehör > American Football-Kinnriemen',
  },
  {
    value: '3497',
    label:
      'Sportartikel > Athletik > American Football > American Football-Schutzbekleidung > American Football-Nackenschutz',
  },
  {
    value: '3621',
    label:
      'Sportartikel > Athletik > American Football > American Football-Schutzbekleidung > American Football-Schulterpads',
  },
  {
    value: '499778',
    label:
      'Sportartikel > Athletik > American Football > American Football-Schutzbekleidung > Schutz-Shirts und -Hemden',
  },
  {
    value: '3492',
    label:
      'Sportartikel > Athletik > American Football > American Football-Torstangen',
  },
  {
    value: '3998',
    label:
      'Sportartikel > Athletik > American Football > American Football-Trainingsausrüstung',
  },
  {
    value: '499779',
    label:
      'Sportartikel > Athletik > American Football > American Football-Trainingsausrüstung > Dummys & Schlitten',
  },
  {
    value: '1094',
    label: 'Sportartikel > Athletik > American Football > Football-Bälle',
  },
  {
    value: '3656',
    label:
      'Sportartikel > Athletik > American Football > Kicking Tees & Halter für Fußbälle',
  },
  {
    value: '1070',
    label: 'Sportartikel > Athletik > Baseball & Softball',
  },
  {
    value: '3544',
    label:
      'Sportartikel > Athletik > Baseball & Softball > Baseball- & Softball-Bases',
  },
  {
    value: '3747',
    label:
      'Sportartikel > Athletik > Baseball & Softball > Baseball- & Softball-Schlaghandschuhe',
  },
  {
    value: '1078',
    label:
      'Sportartikel > Athletik > Baseball & Softball > Baseball- & Softball-Schutzkleidung',
  },
  {
    value: '499716',
    label:
      'Sportartikel > Athletik > Baseball & Softball > Baseball- & Softball-Schutzkleidung > Ausrüstung für Fänger',
  },
  {
    value: '499715',
    label:
      'Sportartikel > Athletik > Baseball & Softball > Baseball- & Softball-Schutzkleidung > Baseball- & Softball-Brustpanzer',
  },
  {
    value: '499718',
    label:
      'Sportartikel > Athletik > Baseball & Softball > Baseball- & Softball-Schutzkleidung > Baseball- & Softball-Knie- & Schienbeinschoner',
  },
  {
    value: '3668',
    label:
      'Sportartikel > Athletik > Baseball & Softball > Baseball- & Softball-Schutzkleidung > Baseball- & Softballhelme',
  },
  {
    value: '499717',
    label:
      'Sportartikel > Athletik > Baseball & Softball > Baseball- & Softball-Schutzkleidung > Schutzhelme für Fänger',
  },
  {
    value: '234670',
    label:
      'Sportartikel > Athletik > Baseball & Softball > Baseball- & Softball-Wurfhügel',
  },
  {
    value: '234671',
    label:
      'Sportartikel > Athletik > Baseball & Softball > Baseball- & Softball-Wurfmale',
  },
  {
    value: '1076',
    label:
      'Sportartikel > Athletik > Baseball & Softball > Baseball- & Softballhandschuhe',
  },
  {
    value: '3783',
    label: 'Sportartikel > Athletik > Baseball & Softball > Baseball-Bälle',
  },
  {
    value: '3790',
    label: 'Sportartikel > Athletik > Baseball & Softball > Baseballschläger',
  },
  {
    value: '3671',
    label: 'Sportartikel > Athletik > Baseball & Softball > Softball-Bälle',
  },
  {
    value: '3679',
    label: 'Sportartikel > Athletik > Baseball & Softball > Softballschläger',
  },
  {
    value: '1077',
    label: 'Sportartikel > Athletik > Baseball & Softball > Wurfmaschinen',
  },
  {
    value: '1081',
    label: 'Sportartikel > Athletik > Basketball',
  },
  {
    value: '499751',
    label: 'Sportartikel > Athletik > Basketball > Basketball-Trainingshilfen',
  },
  {
    value: '4676',
    label:
      'Sportartikel > Athletik > Basketball > Basketballkorbteile & -zubehör',
  },
  {
    value: '4089',
    label:
      'Sportartikel > Athletik > Basketball > Basketballkorbteile & -zubehör > Basketball-Korbbretter',
  },
  {
    value: '3829',
    label:
      'Sportartikel > Athletik > Basketball > Basketballkorbteile & -zubehör > Basketballnetze',
  },
  {
    value: '4192',
    label:
      'Sportartikel > Athletik > Basketball > Basketballkorbteile & -zubehör > Basketballringe',
  },
  {
    value: '4050',
    label:
      'Sportartikel > Athletik > Basketball > Basketballkorbteile & -zubehör > Basketballständer',
  },
  {
    value: '7251',
    label:
      'Sportartikel > Athletik > Basketball > Basketballkorbteile & -zubehör > Schutzverkleidung für Basketballkörbe',
  },
  {
    value: '1082',
    label: 'Sportartikel > Athletik > Basketball > Basketballkörbe',
  },
  {
    value: '1083',
    label: 'Sportartikel > Athletik > Basketball > Basketbälle',
  },
  {
    value: '499719',
    label: 'Sportartikel > Athletik > Boxen & Kampfsport',
  },
  {
    value: '3652',
    label: 'Sportartikel > Athletik > Boxen & Kampfsport > Boxringe',
  },
  {
    value: '3411',
    label: 'Sportartikel > Athletik > Boxen & Kampfsport > Boxringelemente',
  },
  {
    value: '4008',
    label: 'Sportartikel > Athletik > Boxen & Kampfsport > Boxschutzbekleidung',
  },
  {
    value: '499725',
    label:
      'Sportartikel > Athletik > Boxen & Kampfsport > Boxschutzbekleidung > Armschutz',
  },
  {
    value: '3235',
    label:
      'Sportartikel > Athletik > Boxen & Kampfsport > Boxschutzbekleidung > Boxhandschuhe',
  },
  {
    value: '499726',
    label:
      'Sportartikel > Athletik > Boxen & Kampfsport > Boxschutzbekleidung > Handbandagen',
  },
  {
    value: '499722',
    label:
      'Sportartikel > Athletik > Boxen & Kampfsport > Boxschutzbekleidung > Kopfschutz',
  },
  {
    value: '499723',
    label:
      'Sportartikel > Athletik > Boxen & Kampfsport > Boxschutzbekleidung > Körperschutz',
  },
  {
    value: '499724',
    label:
      'Sportartikel > Athletik > Boxen & Kampfsport > Boxschutzbekleidung > Schienbeinschoner',
  },
  {
    value: '3717',
    label: 'Sportartikel > Athletik > Boxen & Kampfsport > Kampfsportgürtel',
  },
  {
    value: '4282',
    label: 'Sportartikel > Athletik > Boxen & Kampfsport > Kampfsportwaffen',
  },
  {
    value: '499720',
    label:
      'Sportartikel > Athletik > Boxen & Kampfsport > Trainingsausrüstung für Boxen & Kampfsport',
  },
  {
    value: '7129',
    label:
      'Sportartikel > Athletik > Boxen & Kampfsport > Trainingsausrüstung für Boxen & Kampfsport > Box- & Trainingssackzubehör',
  },
  {
    value: '3297',
    label:
      'Sportartikel > Athletik > Boxen & Kampfsport > Trainingsausrüstung für Boxen & Kampfsport > Box- & Trainingssäcke',
  },
  {
    value: '499769',
    label:
      'Sportartikel > Athletik > Boxen & Kampfsport > Trainingsausrüstung für Boxen & Kampfsport > Boxpratzen',
  },
  {
    value: '499721',
    label:
      'Sportartikel > Athletik > Boxen & Kampfsport > Trainingsausrüstung für Boxen & Kampfsport > Schlagpolster',
  },
  {
    value: '7116',
    label:
      'Sportartikel > Athletik > Boxen & Kampfsport > Trainingsausrüstung für Boxen & Kampfsport > Training-Dummys',
  },
  {
    value: '6734',
    label: 'Sportartikel > Athletik > Broomball-Ausrüstung',
  },
  {
    value: '3354',
    label: 'Sportartikel > Athletik > Cheerleading',
  },
  {
    value: '3953',
    label: 'Sportartikel > Athletik > Cheerleading > Cheerleader-Pompons',
  },
  {
    value: '1087',
    label: 'Sportartikel > Athletik > Cricket',
  },
  {
    value: '499735',
    label: 'Sportartikel > Athletik > Cricket > Cricket-Aussrüstungssets',
  },
  {
    value: '499736',
    label: 'Sportartikel > Athletik > Cricket > Cricket-Schutzkleidung',
  },
  {
    value: '499739',
    label:
      'Sportartikel > Athletik > Cricket > Cricket-Schutzkleidung > Cricket-Beinschutz',
  },
  {
    value: '3339',
    label:
      'Sportartikel > Athletik > Cricket > Cricket-Schutzkleidung > Crickethandschuhe',
  },
  {
    value: '3543',
    label:
      'Sportartikel > Athletik > Cricket > Cricket-Schutzkleidung > Crickethelme',
  },
  {
    value: '3870',
    label: 'Sportartikel > Athletik > Cricket > Cricketbälle',
  },
  {
    value: '3815',
    label: 'Sportartikel > Athletik > Cricket > Cricketschläger',
  },
  {
    value: '499737',
    label: 'Sportartikel > Athletik > Cricket > Cricketschläger-Zubehör',
  },
  {
    value: '499738',
    label:
      'Sportartikel > Athletik > Cricket > Cricketschläger-Zubehör > Cricketschläger-Griffe',
  },
  {
    value: '499734',
    label: 'Sportartikel > Athletik > Cricket > Cricketstäbe',
  },
  {
    value: '499915',
    label: 'Sportartikel > Athletik > Eiskunstlauf & Hockey',
  },
  {
    value: '1105',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockey-Schutzausrüstung',
  },
  {
    value: '499890',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockey-Schutzausrüstung > Ausrüstungssets für Torwarte',
  },
  {
    value: '499975',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockey-Schutzausrüstung > Eishockeyhosenträger und -gürtel',
  },
  {
    value: '499756',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockey-Schutzausrüstung > Hockey-Ellenbogenschutz',
  },
  {
    value: '499755',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockey-Schutzausrüstung > Hockey-Schienbeinschoner & -Beinschutz',
  },
  {
    value: '499757',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockey-Schutzausrüstung > Hockey-Schulterpolster & -Brustpolster',
  },
  {
    value: '6078',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockey-Schutzausrüstung > Hockeyhandschuhe',
  },
  {
    value: '6080',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockey-Schutzausrüstung > Hockeyhelme',
  },
  {
    value: '3615',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockey-Schutzausrüstung > Hockeyhosen',
  },
  {
    value: '6077',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockeybälle & Pucks',
  },
  {
    value: '6857',
    label: 'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockeyschlitten',
  },
  {
    value: '6076',
    label: 'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockeyschläger',
  },
  {
    value: '7012',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockeyschlägerpflege',
  },
  {
    value: '7011',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockeyschlägerteile',
  },
  {
    value: '6942',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockeyschlägerteile > Hockey-Schlägerschäfte',
  },
  {
    value: '6852',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockeyschlägerteile > Hockeyschlägerkellen',
  },
  {
    value: '6074',
    label: 'Sportartikel > Athletik > Eiskunstlauf & Hockey > Hockeytore',
  },
  {
    value: '1057',
    label: 'Sportartikel > Athletik > Eiskunstlauf & Hockey > Schlittschuhe',
  },
  {
    value: '3791',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Schlittschuhzubehör',
  },
  {
    value: '6708',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Schlittschuhzubehör > Eiskunstlaufstiefel',
  },
  {
    value: '4019',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Schlittschuhzubehör > Kufenschoner',
  },
  {
    value: '7000',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Schlittschuhzubehör > Schlittschuhkufen',
  },
  {
    value: '3623',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Schlittschuhzubehör > Schlittschuhschleifer',
  },
  {
    value: '3241',
    label:
      'Sportartikel > Athletik > Eiskunstlauf & Hockey > Schlittschuhzubehör > Schnürhilfen',
  },
  {
    value: '1006',
    label: 'Sportartikel > Athletik > Fechten',
  },
  {
    value: '3261',
    label: 'Sportartikel > Athletik > Fechten > Fechtschutzbekleidung',
  },
  {
    value: '3366',
    label:
      'Sportartikel > Athletik > Fechten > Fechtschutzbekleidung > Fechthandschuhe',
  },
  {
    value: '499740',
    label:
      'Sportartikel > Athletik > Fechten > Fechtschutzbekleidung > Fechtjacken & -E-Westen',
  },
  {
    value: '3707',
    label:
      'Sportartikel > Athletik > Fechten > Fechtschutzbekleidung > Fechtmasken',
  },
  {
    value: '3622',
    label: 'Sportartikel > Athletik > Fechten > Sportfechtwaffen',
  },
  {
    value: '499741',
    label: 'Sportartikel > Athletik > Feldhockey & Lacrosse',
  },
  {
    value: '499744',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Feldhockey- & Lacrosse-Schutzkleidung',
  },
  {
    value: '499745',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Feldhockey- & Lacrosse-Schutzkleidung > Feldhockey- & Lacrosse-Handschuhe',
  },
  {
    value: '502970',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Feldhockey- & Lacrosse-Schutzkleidung > Feldhockey- & Lacrosse-Pads',
  },
  {
    value: '499746',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Feldhockey- & Lacrosse-Schutzkleidung > Hockey- & Lacrosse-Helme',
  },
  {
    value: '499747',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Feldhockey- & Lacrosse-Schutzkleidung > Masken & Schutzbrillen',
  },
  {
    value: '1089',
    label: 'Sportartikel > Athletik > Feldhockey & Lacrosse > Feldhockeybälle',
  },
  {
    value: '1092',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Feldhockeyschläger',
  },
  {
    value: '3001',
    label: 'Sportartikel > Athletik > Feldhockey & Lacrosse > Feldhockeytore',
  },
  {
    value: '499742',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Lacrosse-Ausrüstungen',
  },
  {
    value: '3536',
    label: 'Sportartikel > Athletik > Feldhockey & Lacrosse > Lacrosse-Bälle',
  },
  {
    value: '3817',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Lacrosse-Schläger',
  },
  {
    value: '3336',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Lacrosse-Schlägerteile',
  },
  {
    value: '3418',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Lacrosse-Schlägerteile > Lacrosse-Schlägerköpfe',
  },
  {
    value: '3785',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Lacrosse-Schlägerteile > Lacrosse-Schlägernetze & Strings',
  },
  {
    value: '3423',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Lacrosse-Schlägerteile > Lacrosse-Schlägerschäfte',
  },
  {
    value: '3970',
    label: 'Sportartikel > Athletik > Feldhockey & Lacrosse > Lacrosse-Tore',
  },
  {
    value: '3204',
    label:
      'Sportartikel > Athletik > Feldhockey & Lacrosse > Lacrosse-Trainingszubehör',
  },
  {
    value: '1111',
    label: 'Sportartikel > Athletik > Fußball',
  },
  {
    value: '3973',
    label: 'Sportartikel > Athletik > Fußball > Eckfahnen',
  },
  {
    value: '499784',
    label: 'Sportartikel > Athletik > Fußball > Fußball-Schutzkleidung',
  },
  {
    value: '1114',
    label:
      'Sportartikel > Athletik > Fußball > Fußball-Schutzkleidung > Fußballschienbeinschützer',
  },
  {
    value: '1113',
    label: 'Sportartikel > Athletik > Fußball > Fußballtore & -netze',
  },
  {
    value: '1112',
    label: 'Sportartikel > Athletik > Fußball > Fußbälle',
  },
  {
    value: '3141',
    label: 'Sportartikel > Athletik > Fußball > Torwarthandschuhe',
  },
  {
    value: '6055',
    label: 'Sportartikel > Athletik > Fußball > Zubehör für Fußballtore',
  },
  {
    value: '1047',
    label: 'Sportartikel > Athletik > Handball',
  },
  {
    value: '499785',
    label: 'Sportartikel > Athletik > Handball > Handbälle',
  },
  {
    value: '1060',
    label: 'Sportartikel > Athletik > Leichtathletik',
  },
  {
    value: '3478',
    label: 'Sportartikel > Athletik > Leichtathletik > Diskusscheiben',
  },
  {
    value: '3445',
    label: 'Sportartikel > Athletik > Leichtathletik > Hochsprunglatten',
  },
  {
    value: '3864',
    label: 'Sportartikel > Athletik > Leichtathletik > Hochsprungmatten',
  },
  {
    value: '3149',
    label: 'Sportartikel > Athletik > Leichtathletik > Hürden',
  },
  {
    value: '3770',
    label: 'Sportartikel > Athletik > Leichtathletik > Kugelstoß-Kugeln',
  },
  {
    value: '3389',
    label: 'Sportartikel > Athletik > Leichtathletik > Speere',
  },
  {
    value: '3987',
    label: 'Sportartikel > Athletik > Leichtathletik > Stabhochsprungmatten',
  },
  {
    value: '4020',
    label: 'Sportartikel > Athletik > Leichtathletik > Stabhochsprungstäbe',
  },
  {
    value: '3878',
    label: 'Sportartikel > Athletik > Leichtathletik > Staffelstäbe',
  },
  {
    value: '499786',
    label: 'Sportartikel > Athletik > Leichtathletik > Startblöcke',
  },
  {
    value: '3997',
    label: 'Sportartikel > Athletik > Leichtathletik > Startpistolen',
  },
  {
    value: '3880',
    label: 'Sportartikel > Athletik > Leichtathletik > Wurfhämmer',
  },
  {
    value: '503752',
    label: 'Sportartikel > Athletik > Racquetball & Squash',
  },
  {
    value: '503753',
    label:
      'Sportartikel > Athletik > Racquetball & Squash > Racquetball- & Squash-Bälle',
  },
  {
    value: '3714',
    label:
      'Sportartikel > Athletik > Racquetball & Squash > Racquetball-Schläger',
  },
  {
    value: '499783',
    label:
      'Sportartikel > Athletik > Racquetball & Squash > Squash- & Racquetball-Handschuhe',
  },
  {
    value: '3119',
    label:
      'Sportartikel > Athletik > Racquetball & Squash > Squash- & Racquetball-Schutzbrillen',
  },
  {
    value: '4002',
    label: 'Sportartikel > Athletik > Racquetball & Squash > Squashschläger',
  },
  {
    value: '1068',
    label: 'Sportartikel > Athletik > Ringen',
  },
  {
    value: '3057',
    label: 'Sportartikel > Athletik > Ringen > Schutzbekleidung für Ringer',
  },
  {
    value: '499790',
    label:
      'Sportartikel > Athletik > Ringen > Schutzbekleidung für Ringer > Ringer-Kniepolster',
  },
  {
    value: '499791',
    label:
      'Sportartikel > Athletik > Ringen > Schutzbekleidung für Ringer > Ringer-Kopfschutz',
  },
  {
    value: '7156',
    label: 'Sportartikel > Athletik > Rounders',
  },
  {
    value: '7157',
    label: 'Sportartikel > Athletik > Rounders > Rounders-Handschuhe',
  },
  {
    value: '7158',
    label: 'Sportartikel > Athletik > Rounders > Rounders-Schläger',
  },
  {
    value: '1110',
    label: 'Sportartikel > Athletik > Rugby',
  },
  {
    value: '3761',
    label: 'Sportartikel > Athletik > Rugby > Rugby-Bälle',
  },
  {
    value: '3487',
    label: 'Sportartikel > Athletik > Rugby > Rugby-Handschuhe',
  },
  {
    value: '499782',
    label: 'Sportartikel > Athletik > Rugby > Rugby-Schutzkleidung',
  },
  {
    value: '3077',
    label:
      'Sportartikel > Athletik > Rugby > Rugby-Schutzkleidung > Rugby-Helme',
  },
  {
    value: '3881',
    label: 'Sportartikel > Athletik > Rugby > Rugby-Tore',
  },
  {
    value: '3983',
    label: 'Sportartikel > Athletik > Rugby > Rugby-Trainingsausrüstung',
  },
  {
    value: '989',
    label: 'Sportartikel > Athletik > Tanzen',
  },
  {
    value: '3269',
    label: 'Sportartikel > Athletik > Tanzen > Ballettstangen',
  },
  {
    value: '1065',
    label: 'Sportartikel > Athletik > Tennis',
  },
  {
    value: '3565',
    label: 'Sportartikel > Athletik > Tennis > Tennisballdosen',
  },
  {
    value: '3105',
    label: 'Sportartikel > Athletik > Tennis > Tennisballkörbe & -wagen',
  },
  {
    value: '3985',
    label: 'Sportartikel > Athletik > Tennis > Tennisballwurfmaschinen',
  },
  {
    value: '3113',
    label: 'Sportartikel > Athletik > Tennis > Tennisbälle',
  },
  {
    value: '3961',
    label: 'Sportartikel > Athletik > Tennis > Tennisnetze',
  },
  {
    value: '3906',
    label: 'Sportartikel > Athletik > Tennis > Tennisschläger',
  },
  {
    value: '3658',
    label: 'Sportartikel > Athletik > Tennis > Tennisschlägerzubehör',
  },
  {
    value: '3403',
    label:
      'Sportartikel > Athletik > Tennis > Tennisschlägerzubehör > Griffbänder',
  },
  {
    value: '3352',
    label:
      'Sportartikel > Athletik > Tennis > Tennisschlägerzubehör > Schlägerdämpfer',
  },
  {
    value: '3922',
    label:
      'Sportartikel > Athletik > Tennis > Tennisschlägerzubehör > Tennissaiten',
  },
  {
    value: '3638',
    label:
      'Sportartikel > Athletik > Tennis > Tennisschlägerzubehör > Tennistaschen',
  },
  {
    value: '3295',
    label:
      'Sportartikel > Athletik > Tennis > Tennisschlägerzubehör > Ösenbänder für Tennisschläger',
  },
  {
    value: '6739',
    label: 'Sportartikel > Athletik > Trainer- & Schiedsrichterausrüstung',
  },
  {
    value: '8505',
    label:
      'Sportartikel > Athletik > Trainer- & Schiedsrichterausrüstung > Anzeigetafeln',
  },
  {
    value: '505813',
    label:
      'Sportartikel > Athletik > Trainer- & Schiedsrichterausrüstung > Begrenzungsmarkierungen für Spielfelder',
  },
  {
    value: '499730',
    label:
      'Sportartikel > Athletik > Trainer- & Schiedsrichterausrüstung > Handzähler',
  },
  {
    value: '499729',
    label:
      'Sportartikel > Athletik > Trainer- & Schiedsrichterausrüstung > Kapitänsbinden',
  },
  {
    value: '6731',
    label:
      'Sportartikel > Athletik > Trainer- & Schiedsrichterausrüstung > Linienrichterfahnen',
  },
  {
    value: '499731',
    label:
      'Sportartikel > Athletik > Trainer- & Schiedsrichterausrüstung > Pitch Counter',
  },
  {
    value: '6730',
    label:
      'Sportartikel > Athletik > Trainer- & Schiedsrichterausrüstung > Schiedsrichter- & Trainerpfeifen',
  },
  {
    value: '499732',
    label:
      'Sportartikel > Athletik > Trainer- & Schiedsrichterausrüstung > Schiedsrichter-Münzen',
  },
  {
    value: '499727',
    label:
      'Sportartikel > Athletik > Trainer- & Schiedsrichterausrüstung > Schiedsrichtermappen',
  },
  {
    value: '499733',
    label:
      'Sportartikel > Athletik > Trainer- & Schiedsrichterausrüstung > Schiedsrichterstände & -stühle',
  },
  {
    value: '6729',
    label:
      'Sportartikel > Athletik > Trainer- & Schiedsrichterausrüstung > Strafkarten & -flaggen',
  },
  {
    value: '1000',
    label: 'Sportartikel > Athletik > Turnen',
  },
  {
    value: '3182',
    label: 'Sportartikel > Athletik > Turnen > Pauschenpferde',
  },
  {
    value: '3808',
    label: 'Sportartikel > Athletik > Turnen > Schutzausrüstung',
  },
  {
    value: '499781',
    label:
      'Sportartikel > Athletik > Turnen > Schutzausrüstung > Turner-Handbandagen',
  },
  {
    value: '3779',
    label: 'Sportartikel > Athletik > Turnen > Sprungpferde',
  },
  {
    value: '3774',
    label: 'Sportartikel > Athletik > Turnen > Turnringe',
  },
  {
    value: '3123',
    label: 'Sportartikel > Athletik > Turnen > Turnsprungbretter',
  },
  {
    value: '503763',
    label: 'Sportartikel > Athletik > Turnen > Turnstangen & Schwebebalken',
  },
  {
    value: '1115',
    label: 'Sportartikel > Athletik > Volleyball',
  },
  {
    value: '499788',
    label: 'Sportartikel > Athletik > Volleyball > Volleyball-Schutzkleidung',
  },
  {
    value: '499789',
    label:
      'Sportartikel > Athletik > Volleyball > Volleyball-Schutzkleidung > Volleyball-Knieschoner',
  },
  {
    value: '499787',
    label: 'Sportartikel > Athletik > Volleyball > Volleyball-Trainingshilfen',
  },
  {
    value: '1117',
    label: 'Sportartikel > Athletik > Volleyball > Volleyballnetze',
  },
  {
    value: '1116',
    label: 'Sportartikel > Athletik > Volleyball > Volleybälle',
  },
  {
    value: '499861',
    label: 'Sportartikel > Athletik > Wallyball-Ausrüstung',
  },
  {
    value: '1145',
    label: 'Sportartikel > Athletik > Wasserball',
  },
  {
    value: '3575',
    label: 'Sportartikel > Athletik > Wasserball > Wasserballkappen',
  },
  {
    value: '3678',
    label: 'Sportartikel > Athletik > Wasserball > Wasserballtore',
  },
  {
    value: '3794',
    label: 'Sportartikel > Athletik > Wasserball > Wasserbälle',
  },
  {
    value: '990',
    label: 'Sportartikel > Fitness',
  },
  {
    value: '499796',
    label: 'Sportartikel > Fitness > Balancetrainer',
  },
  {
    value: '499797',
    label: 'Sportartikel > Fitness > Bauchtrainer',
  },
  {
    value: '499792',
    label: 'Sportartikel > Fitness > Cardio',
  },
  {
    value: '4589',
    label: 'Sportartikel > Fitness > Cardio > Cardiogeräte',
  },
  {
    value: '992',
    label: 'Sportartikel > Fitness > Cardio > Cardiogeräte > Crosstrainer',
  },
  {
    value: '994',
    label: 'Sportartikel > Fitness > Cardio > Cardiogeräte > Heimtrainer',
  },
  {
    value: '997',
    label: 'Sportartikel > Fitness > Cardio > Cardiogeräte > Laufbänder',
  },
  {
    value: '995',
    label: 'Sportartikel > Fitness > Cardio > Cardiogeräte > Rudergeräte',
  },
  {
    value: '996',
    label: 'Sportartikel > Fitness > Cardio > Cardiogeräte > Stepper',
  },
  {
    value: '2614',
    label: 'Sportartikel > Fitness > Cardio > Springseile',
  },
  {
    value: '4598',
    label: 'Sportartikel > Fitness > Cardio > Zubehör für Cardiogeräte',
  },
  {
    value: '499703',
    label:
      'Sportartikel > Fitness > Cardio > Zubehör für Cardiogeräte > Crosstrainer-Zubehör',
  },
  {
    value: '499702',
    label:
      'Sportartikel > Fitness > Cardio > Zubehör für Cardiogeräte > Heimtrainer-Zubehör',
  },
  {
    value: '499699',
    label:
      'Sportartikel > Fitness > Cardio > Zubehör für Cardiogeräte > Laufbandzubehör',
  },
  {
    value: '499701',
    label:
      'Sportartikel > Fitness > Cardio > Zubehör für Cardiogeräte > Rudergerät-Zubehör',
  },
  {
    value: '499700',
    label:
      'Sportartikel > Fitness > Cardio > Zubehör für Cardiogeräte > Stepper-Zubehör',
  },
  {
    value: '5869',
    label: 'Sportartikel > Fitness > Fitnessbänder',
  },
  {
    value: '499978',
    label: 'Sportartikel > Fitness > Fitnessgeräte-Sets',
  },
  {
    value: '499793',
    label: 'Sportartikel > Fitness > Gewichtheben',
  },
  {
    value: '3654',
    label: 'Sportartikel > Fitness > Gewichtheben > Gewichthebergürtel',
  },
  {
    value: '3858',
    label:
      'Sportartikel > Fitness > Gewichtheben > Gewichtheberhandschuhe & Handgelenkschoner',
  },
  {
    value: '3164',
    label: 'Sportartikel > Fitness > Gewichtheben > Hanteln',
  },
  {
    value: '6452',
    label: 'Sportartikel > Fitness > Gewichtheben > Hantelzubehör',
  },
  {
    value: '3271',
    label:
      'Sportartikel > Fitness > Gewichtheben > Hantelzubehör > Hantelstangen',
  },
  {
    value: '499794',
    label:
      'Sportartikel > Fitness > Gewichtheben > Hantelzubehör > Hantelstangen-Feststellringe',
  },
  {
    value: '8083',
    label:
      'Sportartikel > Fitness > Gewichtheben > Hantelzubehör > Hantelständer',
  },
  {
    value: '3542',
    label: 'Sportartikel > Fitness > Gewichtheben > Krafttrainingsgeräte',
  },
  {
    value: '3217',
    label:
      'Sportartikel > Fitness > Gewichtheben > Zubehör für Krafttrainingsgeräte',
  },
  {
    value: '6103',
    label: 'Sportartikel > Fitness > Gewichtswesten & -manschetten',
  },
  {
    value: '993',
    label: 'Sportartikel > Fitness > Gymnastikbälle',
  },
  {
    value: '6867',
    label: 'Sportartikel > Fitness > Handtrainer',
  },
  {
    value: '355576',
    label: 'Sportartikel > Fitness > Inversionstische',
  },
  {
    value: '8471',
    label: 'Sportartikel > Fitness > Keilmatten',
  },
  {
    value: '8215',
    label: 'Sportartikel > Fitness > Klimmzugstangen',
  },
  {
    value: '4669',
    label: 'Sportartikel > Fitness > Matten für Heimtrainer',
  },
  {
    value: '3938',
    label: 'Sportartikel > Fitness > Medizinbälle',
  },
  {
    value: '499912',
    label: 'Sportartikel > Fitness > Power Tower',
  },
  {
    value: '7174',
    label: 'Sportartikel > Fitness > Reaktionsbälle',
  },
  {
    value: '5319',
    label: 'Sportartikel > Fitness > Schaumroller',
  },
  {
    value: '6337',
    label: 'Sportartikel > Fitness > Schaumroller-Zubehör',
  },
  {
    value: '6338',
    label:
      'Sportartikel > Fitness > Schaumroller-Zubehör > Aufbewahrungstaschen für Schaumroller',
  },
  {
    value: '505302',
    label:
      'Sportartikel > Fitness > Sicherheitslichter & -reflektoren für Sport',
  },
  {
    value: '237166',
    label: 'Sportartikel > Fitness > Steppbretter',
  },
  {
    value: '5693',
    label: 'Sportartikel > Fitness > Stoppuhren',
  },
  {
    value: '499798',
    label: 'Sportartikel > Fitness > Suspension Trainer',
  },
  {
    value: '8062',
    label: 'Sportartikel > Fitness > Trainings- & Bremsschirme',
  },
  {
    value: '499795',
    label: 'Sportartikel > Fitness > Trainingsbänke',
  },
  {
    value: '8066',
    label: 'Sportartikel > Fitness > Vibrationstrainer',
  },
  {
    value: '999',
    label: 'Sportartikel > Fitness > Yoga & Pilates',
  },
  {
    value: '5107',
    label: 'Sportartikel > Fitness > Yoga & Pilates > Beutel für Yogamatten',
  },
  {
    value: '3810',
    label: 'Sportartikel > Fitness > Yoga & Pilates > Pilates-Geräte',
  },
  {
    value: '6750',
    label: 'Sportartikel > Fitness > Yoga & Pilates > Yoga- & Pilatesblöcke',
  },
  {
    value: '3640',
    label: 'Sportartikel > Fitness > Yoga & Pilates > Yoga- & Pilatesmatten',
  },
  {
    value: '6743',
    label: 'Sportartikel > Fitness > Yoga & Pilates > Yoga- & Pilatestücher',
  },
  {
    value: '1001',
    label: 'Sportartikel > Indoor - Aktivitäten',
  },
  {
    value: '1002',
    label: 'Sportartikel > Indoor - Aktivitäten > Air-Hockey',
  },
  {
    value: '505330',
    label:
      'Sportartikel > Indoor - Aktivitäten > Air-Hockey > Air-Hockey-Ausrüstung',
  },
  {
    value: '3245',
    label:
      'Sportartikel > Indoor - Aktivitäten > Air-Hockey > Air-Hockey-Tische',
  },
  {
    value: '3548',
    label:
      'Sportartikel > Indoor - Aktivitäten > Air-Hockey > Air-Hockey-Tischzubehör',
  },
  {
    value: '1003',
    label: 'Sportartikel > Indoor - Aktivitäten > Billard',
  },
  {
    value: '3059',
    label: 'Sportartikel > Indoor - Aktivitäten > Billard > Billarddreiecke',
  },
  {
    value: '3755',
    label: 'Sportartikel > Indoor - Aktivitäten > Billard > Billardhandschuhe',
  },
  {
    value: '3135',
    label: 'Sportartikel > Indoor - Aktivitäten > Billard > Billardkugeln',
  },
  {
    value: '3910',
    label:
      'Sportartikel > Indoor - Aktivitäten > Billard > Billardqueues & Queuebrücken',
  },
  {
    value: '3469',
    label:
      'Sportartikel > Indoor - Aktivitäten > Billard > Billardtisch-Beleuchtung',
  },
  {
    value: '3183',
    label:
      'Sportartikel > Indoor - Aktivitäten > Billard > Billardtisch-Teile & Zubehör',
  },
  {
    value: '3574',
    label:
      'Sportartikel > Indoor - Aktivitäten > Billard > Billardtisch-Teile & Zubehör > Ballfangtaschen',
  },
  {
    value: '3754',
    label:
      'Sportartikel > Indoor - Aktivitäten > Billard > Billardtisch-Teile & Zubehör > Billardtisch-Bürsten',
  },
  {
    value: '3547',
    label:
      'Sportartikel > Indoor - Aktivitäten > Billard > Billardtisch-Teile & Zubehör > Billardtücher',
  },
  {
    value: '8065',
    label:
      'Sportartikel > Indoor - Aktivitäten > Billard > Billardtisch-Teile & Zubehör > Billiardtischabdeckungen',
  },
  {
    value: '3139',
    label: 'Sportartikel > Indoor - Aktivitäten > Billard > Billardtische',
  },
  {
    value: '3222',
    label: 'Sportartikel > Indoor - Aktivitäten > Billard > Queuezubehör',
  },
  {
    value: '499993',
    label:
      'Sportartikel > Indoor - Aktivitäten > Billard > Queuezubehör > Billiardkoffer',
  },
  {
    value: '499994',
    label:
      'Sportartikel > Indoor - Aktivitäten > Billard > Queuezubehör > Billiardkreide',
  },
  {
    value: '3720',
    label:
      'Sportartikel > Indoor - Aktivitäten > Billard > Queuezubehör > Queueständer',
  },
  {
    value: '1004',
    label: 'Sportartikel > Indoor - Aktivitäten > Bowling',
  },
  {
    value: '3260',
    label:
      'Sportartikel > Indoor - Aktivitäten > Bowling > Bowling-Handgelenkstützen',
  },
  {
    value: '3535',
    label: 'Sportartikel > Indoor - Aktivitäten > Bowling > Bowlinghandschuhe',
  },
  {
    value: '3669',
    label: 'Sportartikel > Indoor - Aktivitäten > Bowling > Bowlingkegel',
  },
  {
    value: '3219',
    label: 'Sportartikel > Indoor - Aktivitäten > Bowling > Bowlingkugeln',
  },
  {
    value: '3698',
    label: 'Sportartikel > Indoor - Aktivitäten > Bowling > Bowlingtaschen',
  },
  {
    value: '1005',
    label: 'Sportartikel > Indoor - Aktivitäten > Dartspiele',
  },
  {
    value: '3957',
    label: 'Sportartikel > Indoor - Aktivitäten > Dartspiele > Dart-Rückwände',
  },
  {
    value: '3839',
    label: 'Sportartikel > Indoor - Aktivitäten > Dartspiele > Dartpfeile',
  },
  {
    value: '3559',
    label: 'Sportartikel > Indoor - Aktivitäten > Dartspiele > Dartscheiben',
  },
  {
    value: '3327',
    label: 'Sportartikel > Indoor - Aktivitäten > Dartspiele > Dartteile',
  },
  {
    value: '3766',
    label:
      'Sportartikel > Indoor - Aktivitäten > Dartspiele > Dartteile > Dartflights',
  },
  {
    value: '3109',
    label:
      'Sportartikel > Indoor - Aktivitäten > Dartspiele > Dartteile > Dartschäfte',
  },
  {
    value: '3250',
    label:
      'Sportartikel > Indoor - Aktivitäten > Dartspiele > Dartteile > Dartspitzen',
  },
  {
    value: '1009',
    label: 'Sportartikel > Indoor - Aktivitäten > Jakkolo',
  },
  {
    value: '3996',
    label: 'Sportartikel > Indoor - Aktivitäten > Jakkolo > Jakkolo-Pulver',
  },
  {
    value: '4021',
    label:
      'Sportartikel > Indoor - Aktivitäten > Jakkolo > Jakkolo-Spielsteine',
  },
  {
    value: '3148',
    label:
      'Sportartikel > Indoor - Aktivitäten > Jakkolo > Shuffleboard-Tische',
  },
  {
    value: '7010',
    label: 'Sportartikel > Indoor - Aktivitäten > Multispieltische',
  },
  {
    value: '1007',
    label: 'Sportartikel > Indoor - Aktivitäten > Tischfußball',
  },
  {
    value: '3641',
    label:
      'Sportartikel > Indoor - Aktivitäten > Tischfußball > Tischfußball-Bälle',
  },
  {
    value: '3847',
    label:
      'Sportartikel > Indoor - Aktivitäten > Tischfußball > Tischfußball-Tische',
  },
  {
    value: '3524',
    label:
      'Sportartikel > Indoor - Aktivitäten > Tischfußball > Zubehör für Tischfußballtische',
  },
  {
    value: '1008',
    label: 'Sportartikel > Indoor - Aktivitäten > Tischtennis',
  },
  {
    value: '3546',
    label:
      'Sportartikel > Indoor - Aktivitäten > Tischtennis > Tischtennis-Roboter',
  },
  {
    value: '3964',
    label:
      'Sportartikel > Indoor - Aktivitäten > Tischtennis > Tischtennisbälle',
  },
  {
    value: '3788',
    label:
      'Sportartikel > Indoor - Aktivitäten > Tischtennis > Tischtennisnetze und -pfosten',
  },
  {
    value: '3345',
    label:
      'Sportartikel > Indoor - Aktivitäten > Tischtennis > Tischtennisplatten',
  },
  {
    value: '3375',
    label:
      'Sportartikel > Indoor - Aktivitäten > Tischtennis > Tischtennisschläger & -sets',
  },
  {
    value: '3132',
    label:
      'Sportartikel > Indoor - Aktivitäten > Tischtennis > Zubehör für Tischtennis-Roboter',
  },
  {
    value: '3900',
    label:
      'Sportartikel > Indoor - Aktivitäten > Tischtennis > Zubehör für Tischtennisschläger',
  },
  {
    value: '1011',
    label: 'Sportartikel > Outdoor-Aktivitäten',
  },
  {
    value: '3334',
    label: 'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen',
  },
  {
    value: '499823',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelgeräte',
  },
  {
    value: '3651',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelgeräte > Angelblei',
  },
  {
    value: '3359',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelgeräte > Angelhaken',
  },
  {
    value: '7222',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelgeräte > Karabiner & Wirbel',
  },
  {
    value: '3603',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelgeräte > Kunstköder',
  },
  {
    value: '3859',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelgeräte > Schwimmer',
  },
  {
    value: '1041',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelkästen & Angeltaschen',
  },
  {
    value: '1037',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelleinen & -wirbel',
  },
  {
    value: '4926',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelrollen',
  },
  {
    value: '8092',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelrollen-Zubehör',
  },
  {
    value: '8208',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelrollen-Zubehör > Ersatzspulen',
  },
  {
    value: '8094',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelrollen-Zubehör > Schmierstoffe',
  },
  {
    value: '8273',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelrollen-Zubehör > Taschen & Behälter',
  },
  {
    value: '4927',
    label: 'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelruten',
  },
  {
    value: '8093',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelruten-Zubehör',
  },
  {
    value: '499942',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelruten-Zubehör > Angelrutenhalter und -ständer',
  },
  {
    value: '8272',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Angelruten-Zubehör > Taschen & Behälter für Angelruten',
  },
  {
    value: '8064',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Bissanzeiger',
  },
  {
    value: '7221',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Fallen für den Angelsport',
  },
  {
    value: '3614',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Fischernetze',
  },
  {
    value: '7342',
    label: 'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Gaffs',
  },
  {
    value: '7343',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Harpunen für den Angelsport',
  },
  {
    value: '7217',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Köderbefestigung',
  },
  {
    value: '6440',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Köderbefestigung > Angelschnur',
  },
  {
    value: '7125',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Köderbefestigung > Pop-Up-Kugeln',
  },
  {
    value: '6495',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Köderbehälter',
  },
  {
    value: '3096',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Lebendköder',
  },
  {
    value: '5406',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Wathosen für Angler & Jäger',
  },
  {
    value: '7344',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Angeln & Fischen > Werkzeuge zum Entfernen des Angelhakens',
  },
  {
    value: '499811',
    label: 'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport',
  },
  {
    value: '7178',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Ablagen für Wasserfahrzeuge',
  },
  {
    value: '8173',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Ablagen für Wasserfahrzeuge > Halterungen für Boards',
  },
  {
    value: '8172',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Ablagen für Wasserfahrzeuge > Halterungen für Boote',
  },
  {
    value: '499813',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Boots- & Wassersportkleidung',
  },
  {
    value: '499814',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Boots- & Wassersportkleidung > Neoprenanzugteile',
  },
  {
    value: '5399',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Boots- & Wassersportkleidung > Neoprenanzugteile > Kappen, Handschuhe & Stiefel für Neoprenanzüge',
  },
  {
    value: '5401',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Boots- & Wassersportkleidung > Neoprenanzugteile > Neopren-Oberteile',
  },
  {
    value: '5400',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Boots- & Wassersportkleidung > Neoprenanzugteile > Neopren-Unterteile',
  },
  {
    value: '1147',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Boots- & Wassersportkleidung > Neoprenanzüge',
  },
  {
    value: '3376',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Boots- & Wassersportkleidung > Rashguards & Schwimmshirts',
  },
  {
    value: '1128',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Boots- & Wassersportkleidung > Rettungswesten',
  },
  {
    value: '6496',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Boots- & Wassersportkleidung > Schwimmwestenzubehör',
  },
  {
    value: '1138',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Boots- & Wassersportkleidung > Trockenanzüge',
  },
  {
    value: '499687',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Boots- & Wassersportkleidung > Wassersporthelme',
  },
  {
    value: '1120',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Bootssport',
  },
  {
    value: '7449',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Bootssport > Handschuhe für den Bootssport',
  },
  {
    value: '1127',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Bootssport > Kajaks',
  },
  {
    value: '6312',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Bootssport > Kajakzubehör',
  },
  {
    value: '1124',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Bootssport > Kanus',
  },
  {
    value: '6314',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Bootssport > Kanuzubehör',
  },
  {
    value: '1129',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Bootssport > Paddel & Ruder',
  },
  {
    value: '499964',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Bootssport > Paddelriemen',
  },
  {
    value: '3476',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Bootssport > Rafts',
  },
  {
    value: '3406',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Bootssport > Ruderboote',
  },
  {
    value: '6097',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Bootssport > Tretboote',
  },
  {
    value: '5579',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Kitesurfen',
  },
  {
    value: '5584',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Kitesurfen > Kiteboard-Taschen',
  },
  {
    value: '5580',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Kitesurfen > Kiteboards',
  },
  {
    value: '5581',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Kitesurfen > Kiteboardteile',
  },
  {
    value: '5582',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Kitesurfen > Kitesurfing-Kites',
  },
  {
    value: '5583',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Kitesurfen > Trapeze für Kite- & Windsurfen',
  },
  {
    value: '1144',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen',
  },
  {
    value: '3807',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Badekappen',
  },
  {
    value: '3596',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Gegenstromanlagen',
  },
  {
    value: '6473',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Handpaddel',
  },
  {
    value: '6515',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Nasenklammern',
  },
  {
    value: '3595',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Pull Buoys',
  },
  {
    value: '2966',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Schwimmbretter',
  },
  {
    value: '3360',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Schwimmbrillen & -masken',
  },
  {
    value: '6511',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Schwimmflossen',
  },
  {
    value: '6512',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Schwimmflossen > Monoflossen',
  },
  {
    value: '2512',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Schwimmflossen > Trainingsflossen',
  },
  {
    value: '7104',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Schwimmflügel',
  },
  {
    value: '6550',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Schwimmgewichte',
  },
  {
    value: '6513',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Schwimmgürtel',
  },
  {
    value: '3304',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Schwimmhandschuhe',
  },
  {
    value: '6330',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Schwimmen > Zubehör für Schwimmbrillen & -masken',
  },
  {
    value: '3195',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten',
  },
  {
    value: '7452',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Handschuhe für seilgebundene Wassersportarten',
  },
  {
    value: '3370',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Kneeboarding',
  },
  {
    value: '3101',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Kneeboarding > Kneeboards',
  },
  {
    value: '6301',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Tubes & Towables',
  },
  {
    value: '3282',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Wakeboarding',
  },
  {
    value: '505317',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Wakeboarding > Kiteboard- & Wakeboard-Bindungen',
  },
  {
    value: '505291',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Wakeboarding > Wakeboard-Ersatzteile',
  },
  {
    value: '3353',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Wakeboarding > Wakeboards',
  },
  {
    value: '1146',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Wasserski',
  },
  {
    value: '3289',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Wasserski > Hydrofoils',
  },
  {
    value: '6302',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Wasserski > Wasserski-Bindungen',
  },
  {
    value: '3350',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Wasserski > Wasserskier',
  },
  {
    value: '6296',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Wasserski > Wasserskikoffer & -taschen',
  },
  {
    value: '3636',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Seilgebundene Wassersportarten > Zugseile für Wassersport',
  },
  {
    value: '1143',
    label: 'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Surfen',
  },
  {
    value: '6287',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Surfen > Bodyboards',
  },
  {
    value: '3649',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Surfen > Fußleinen für Surfbretter',
  },
  {
    value: '7451',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Surfen > Handschuhe für Surfer',
  },
  {
    value: '6288',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Surfen > Paddelboards',
  },
  {
    value: '6286',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Surfen > Skimboards',
  },
  {
    value: '3579',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Surfen > Surfboard-Taschen',
  },
  {
    value: '3801',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Surfen > Surfboard-Wachs',
  },
  {
    value: '3320',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Surfen > Surfbretter',
  },
  {
    value: '3525',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Surfen > Surfbrettfinnen',
  },
  {
    value: '3762',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Surfen > Surfpads',
  },
  {
    value: '1135',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Tauchen & Schnorcheln',
  },
  {
    value: '1141',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Tauchen & Schnorcheln > Atemregler',
  },
  {
    value: '6514',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Tauchen & Schnorcheln > Bleigürtel',
  },
  {
    value: '1142',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Tauchen & Schnorcheln > Schnorchel',
  },
  {
    value: '1136',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Tauchen & Schnorcheln > Tarierwesten',
  },
  {
    value: '499867',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Tauchen & Schnorcheln > Tauch- & Schnorchelausrüstung',
  },
  {
    value: '1139',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Tauchen & Schnorcheln > Tauch- & Schnorchelflossen',
  },
  {
    value: '1140',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Tauchen & Schnorcheln > Tauch- & Schnorchelmasken',
  },
  {
    value: '1137',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Tauchen & Schnorcheln > Tauchcomputer',
  },
  {
    value: '5312',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Tauchen & Schnorcheln > Tauchermesser & -scheren',
  },
  {
    value: '1148',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Windsurfen',
  },
  {
    value: '3624',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Windsurfen > Windsurfboard-Ausrüstung',
  },
  {
    value: '3908',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Windsurfen > Windsurfboard-Ausrüstung > Windsurf-Finnen',
  },
  {
    value: '3285',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Windsurfen > Windsurfboard-Ausrüstung > Windsurfmasten',
  },
  {
    value: '3894',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Windsurfen > Windsurfbretter',
  },
  {
    value: '3413',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Boot- & Wassersport > Windsurfen > Windsurfsegel',
  },
  {
    value: '1013',
    label: 'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern',
  },
  {
    value: '1016',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Camping-Töpfe & -Geschirr',
  },
  {
    value: '3937',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Campinghilfsmittel',
  },
  {
    value: '3495',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Campinghilfsmittel > Jagdmesser',
  },
  {
    value: '4095',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Campinghilfsmittel > Multifunktionswerkzeuge & -messer',
  },
  {
    value: '1019',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Campingleuchten & -laternen',
  },
  {
    value: '1014',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Campingmöbel',
  },
  {
    value: '3089',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Campingmöbel > Feldbetten',
  },
  {
    value: '3695',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Campingmöbel > Luftbetten',
  },
  {
    value: '4451',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Campingmöbel > Zubehör für Luftmatratzen & Schlafunterlagen',
  },
  {
    value: '5881',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Innenschlafsack',
  },
  {
    value: '1021',
    label: 'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Isomatten',
  },
  {
    value: '5636',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Kompressionssäcke',
  },
  {
    value: '502993',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Mobile Toiletten & Duschen',
  },
  {
    value: '502994',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Mobile Toiletten & Duschen > Mobile Duschen & Sichtschutz',
  },
  {
    value: '503009',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Mobile Toiletten & Duschen > Mobile Toiletten & Urinale',
  },
  {
    value: '3538',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Moskitonetze & Fliegengitter',
  },
  {
    value: '4785',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Navigationskompasse',
  },
  {
    value: '1020',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Schlafsäcke',
  },
  {
    value: '3508',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Taschenwärmer',
  },
  {
    value: '1023',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Tragbare Wasserfilter & Wasserreiniger',
  },
  {
    value: '3738',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Trekkingstöcke',
  },
  {
    value: '8079',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Windschutz',
  },
  {
    value: '1022',
    label: 'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Zelte',
  },
  {
    value: '5655',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Zeltzubehör',
  },
  {
    value: '499680',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Zeltzubehör > Innenzelte',
  },
  {
    value: '5657',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Zeltzubehör > Vorzelte',
  },
  {
    value: '5658',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Zeltzubehör > Zeltstangen & -Heringe',
  },
  {
    value: '5656',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Zeltzubehör > Zeltunterlagen',
  },
  {
    value: '7154',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Camping & Wandern > Zubehör für Trekkingstöcke',
  },
  {
    value: '3789',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Drachenfliegen & Fallschirmspringen',
  },
  {
    value: '4023',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Drachenfliegen & Fallschirmspringen > Fallschirme',
  },
  {
    value: '5877',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Drachenfliegen & Fallschirmspringen > Fluganzüge',
  },
  {
    value: '4327',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Drachenfliegen & Fallschirmspringen > Hängegleiter',
  },
  {
    value: '1043',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf',
  },
  {
    value: '3106',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf > Golf-Tees',
  },
  {
    value: '3772',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf > Golf-Trainingshilfen',
  },
  {
    value: '7314',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf > Golf-Zubehörsets',
  },
  {
    value: '6864',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf > Golfballmarkierungen',
  },
  {
    value: '1045',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf > Golfbälle',
  },
  {
    value: '3578',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf > Golffahnen',
  },
  {
    value: '4466',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf > Golfhandschuhe',
  },
  {
    value: '4467',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf > Golfhandtücher',
  },
  {
    value: '1046',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf > Golfschläger',
  },
  {
    value: '3642',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Golf > Golfschläger-Teile & Zubehör',
  },
  {
    value: '4043',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Golf > Golfschläger-Teile & Zubehör > Golfschläger-Schutzhüllen',
  },
  {
    value: '4254',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Golf > Golfschläger-Teile & Zubehör > Golfschlägergriffe',
  },
  {
    value: '499780',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Golf > Golfschläger-Teile & Zubehör > Golfschlägerschäfte',
  },
  {
    value: '1044',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf > Golftaschen',
  },
  {
    value: '4605',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf > Golftaschenzubehör',
  },
  {
    value: '4525',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Golf > Golftaschenzubehör > Golftaschenhüllen',
  },
  {
    value: '4537',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Golf > Golftaschenzubehör > Golftaschentrolleys',
  },
  {
    value: '8044',
    label: 'Sportartikel > Outdoor-Aktivitäten > Golf > Pitchgabeln',
  },
  {
    value: '499824',
    label: 'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen',
  },
  {
    value: '1033',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen',
  },
  {
    value: '3773',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Armschutz für Bogenschützen',
  },
  {
    value: '499826',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Bogen- & Armbrustteile & -zubehör',
  },
  {
    value: '499825',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Bögen & Armbrüste',
  },
  {
    value: '3505',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Bögen & Armbrüste > Armbrüste',
  },
  {
    value: '3332',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Bögen & Armbrüste > Compoundbögen',
  },
  {
    value: '3715',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Bögen & Armbrüste > Langbögen',
  },
  {
    value: '499833',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Handschuhe & Auslöser',
  },
  {
    value: '3757',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Köcher',
  },
  {
    value: '3533',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Pfeile',
  },
  {
    value: '3291',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Pfeilelemente & Zubehör',
  },
  {
    value: '499830',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Pfeilelemente & Zubehör > Jagdspitzen & Feldspitzen',
  },
  {
    value: '499832',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Pfeilelemente & Zubehör > Nocken',
  },
  {
    value: '499831',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Pfeilelemente & Zubehör > Steuerfedern',
  },
  {
    value: '3883',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Bogenschießen > Zielscheiben für Bogenschießen',
  },
  {
    value: '3136',
    label: 'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Jagen',
  },
  {
    value: '3748',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Jagen > Hochsitze',
  },
  {
    value: '1034',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Jagen > Jagdansitze & Tarnung',
  },
  {
    value: '5917',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Jagen > Jagdhund-Ausrüstung',
  },
  {
    value: '7373',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Jagen > Jagdverstärker',
  },
  {
    value: '8011',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Jagen > Lockmittel für Wild',
  },
  {
    value: '8080',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Jagen > Lockmittel für Wild > Geruchstarnung & Lockdüfte',
  },
  {
    value: '3583',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Jagen > Lockmittel für Wild > Jagdköder',
  },
  {
    value: '8081',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Jagen > Lockmittel für Wild > Köder, Futter & Mineralien',
  },
  {
    value: '3756',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Jagen > Lockmittel für Wild > Lockinstrumente',
  },
  {
    value: '3674',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Jagen > Tierfallen',
  },
  {
    value: '6992',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Jagen > Wildfutterautomaten',
  },
  {
    value: '499834',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft',
  },
  {
    value: '1049',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball',
  },
  {
    value: '3187',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball > Ersatzteile & Zubehör für Paintball-Gewehre',
  },
  {
    value: '8514',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball > Ersatzteile & Zubehör für Paintball-Gewehre > Paintball-Drop-Forwards',
  },
  {
    value: '3690',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball > Ersatzteile & Zubehör für Paintball-Gewehre > Paintball-Gewehrläufe',
  },
  {
    value: '3152',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball > Ersatzteile & Zubehör für Paintball-Gewehre > Paintball-Hopper',
  },
  {
    value: '3244',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball > Ersatzteile & Zubehör für Paintball-Gewehre > Paintball-Pressluftflaschen',
  },
  {
    value: '6781',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball > Paintball-Battlepacks',
  },
  {
    value: '3408',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball > Paintball-Granaten',
  },
  {
    value: '6748',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball > Paintball-Granatwerfer',
  },
  {
    value: '3234',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball > Paintball-Markierer',
  },
  {
    value: '3438',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball > Paintballs',
  },
  {
    value: '499835',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball- & Airsoft-Schutzkleidung',
  },
  {
    value: '499836',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball- & Airsoft-Schutzkleidung > Paintball- & Airsoft-Handschuhe',
  },
  {
    value: '499838',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball- & Airsoft-Schutzkleidung > Paintball- & Airsoft-Schutzbrillen & Masken',
  },
  {
    value: '499839',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball- & Airsoft-Schutzkleidung > Paintball- & Airsoft-Schutzpolster',
  },
  {
    value: '499837',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Paintball- & Airsoft-Schutzkleidung > Paintball- & Airsoft-Westen',
  },
  {
    value: '2443',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Softair',
  },
  {
    value: '3925',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Softair > Softairkugeln',
  },
  {
    value: '3093',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Softair > Softairwaffen',
  },
  {
    value: '3116',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Softair > Softairwaffen-Teile & -Zubehör',
  },
  {
    value: '8005',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Paintball & Airsoft > Softair > Softairwaffen-Teile & -Zubehör > Batterien für Softairwaffen',
  },
  {
    value: '499840',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Schießsportzubehör',
  },
  {
    value: '499841',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Schießsportzubehör > Stative & Zweibeinständer',
  },
  {
    value: '499842',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Schießsportzubehör > Stützen',
  },
  {
    value: '3170',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Schießsportzubehör > Zielscheiben',
  },
  {
    value: '7460',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Schutzkleidung für Jagd- und Schießsport',
  },
  {
    value: '7461',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Schutzkleidung für Jagd- und Schießsport > Handschuhe für Jagd- und Schießsport',
  },
  {
    value: '7518',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Schutzkleidung für Jagd- und Schießsport > Jacken für Jagd- und Schießsport',
  },
  {
    value: '3125',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Tontaubenschießen',
  },
  {
    value: '3528',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Tontaubenschießen > Tontauben',
  },
  {
    value: '3305',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Jagen und Schießen > Tontaubenschießen > Tontauben-Wurfmaschinen',
  },
  {
    value: '7375',
    label: 'Sportartikel > Outdoor-Aktivitäten > Kitebuggying',
  },
  {
    value: '7377',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Kitebuggying > Kitebuggy-Zubehör',
  },
  {
    value: '7376',
    label: 'Sportartikel > Outdoor-Aktivitäten > Kitebuggying > Kitebuggys',
  },
  {
    value: '7059',
    label: 'Sportartikel > Outdoor-Aktivitäten > Klettern',
  },
  {
    value: '3322',
    label: 'Sportartikel > Outdoor-Aktivitäten > Klettern > Bouldermatten',
  },
  {
    value: '3211',
    label: 'Sportartikel > Outdoor-Aktivitäten > Klettern > Chalkbags',
  },
  {
    value: '7060',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Klettern > Eiskletterausrüstung',
  },
  {
    value: '7061',
    label: 'Sportartikel > Outdoor-Aktivitäten > Klettern > Eisschrauben',
  },
  {
    value: '3849',
    label: 'Sportartikel > Outdoor-Aktivitäten > Klettern > Expresssets',
  },
  {
    value: '3369',
    label: 'Sportartikel > Outdoor-Aktivitäten > Klettern > Gurtbänder',
  },
  {
    value: '3746',
    label: 'Sportartikel > Outdoor-Aktivitäten > Klettern > Karabiner',
  },
  {
    value: '499815',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Klettern > Kletterbekleidung & -zubehör',
  },
  {
    value: '499816',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Klettern > Kletterbekleidung & -zubehör > Kletterhandschuhe',
  },
  {
    value: '3314',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Klettern > Kletterbekleidung & -zubehör > Kletterhelme',
  },
  {
    value: '5394',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Klettern > Kletterbekleidung & -zubehör > Steigeisen',
  },
  {
    value: '3218',
    label: 'Sportartikel > Outdoor-Aktivitäten > Klettern > Klettergurte',
  },
  {
    value: '3266',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Klettern > Kletterschutzausrüstung',
  },
  {
    value: '3825',
    label: 'Sportartikel > Outdoor-Aktivitäten > Klettern > Kletterseile',
  },
  {
    value: '3518',
    label: 'Sportartikel > Outdoor-Aktivitäten > Klettern > Kletterwandgriffe',
  },
  {
    value: '3201',
    label: 'Sportartikel > Outdoor-Aktivitäten > Klettern > Seilsäcke',
  },
  {
    value: '3363',
    label: 'Sportartikel > Outdoor-Aktivitäten > Klettern > Sicherungsgeräte',
  },
  {
    value: '3454',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Klettern > Steigklemmen & Abseilausrüstung',
  },
  {
    value: '1025',
    label: 'Sportartikel > Outdoor-Aktivitäten > Radsport',
  },
  {
    value: '3634',
    label: 'Sportartikel > Outdoor-Aktivitäten > Radsport > Dreiradzubehör',
  },
  {
    value: '3531',
    label: 'Sportartikel > Outdoor-Aktivitäten > Radsport > Dreiräder',
  },
  {
    value: '3070',
    label: 'Sportartikel > Outdoor-Aktivitäten > Radsport > Einradzubehör',
  },
  {
    value: '1030',
    label: 'Sportartikel > Outdoor-Aktivitäten > Radsport > Einräder',
  },
  {
    value: '3618',
    label: 'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile',
  },
  {
    value: '499868',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrrad-Groupsets',
  },
  {
    value: '3740',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradbremsenteile',
  },
  {
    value: '4577',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradbremsenteile > Bremsen-Sets',
  },
  {
    value: '4575',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradbremsenteile > Bremshebel',
  },
  {
    value: '4576',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradbremsenteile > Bremsrotoren',
  },
  {
    value: '4574',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradbremsenteile > Bremssättel',
  },
  {
    value: '4603',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradgabeln',
  },
  {
    value: '499685',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradkabel',
  },
  {
    value: '499684',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradkabelgehäuse',
  },
  {
    value: '6960',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradlenkerverlängerungen',
  },
  {
    value: '4583',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradlenkervorbau',
  },
  {
    value: '7478',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradlenkkopf-Teile',
  },
  {
    value: '7479',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradlenkkopf-Teile > Fahrradlenkkopf-Abstandsringe',
  },
  {
    value: '7480',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradlenkkopf-Teile > Fahrradlenkkopf-Halterungen',
  },
  {
    value: '7477',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradlenkköpfe',
  },
  {
    value: '4582',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradlenkstangen',
  },
  {
    value: '3639',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradrahmen',
  },
  {
    value: '4571',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradreifen',
  },
  {
    value: '3216',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradräder',
  },
  {
    value: '4597',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradräderzubehör',
  },
  {
    value: '8528',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradräderzubehör > Fahrradachsen & -spanner',
  },
  {
    value: '4602',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradräderzubehör > Fahrradfelgen',
  },
  {
    value: '7538',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradräderzubehör > Fahrradfußrasten',
  },
  {
    value: '4599',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradräderzubehör > Fahrradnaben',
  },
  {
    value: '500053',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradräderzubehör > Fahrradnabenteile',
  },
  {
    value: '4600',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradräderzubehör > Fahrradspeichen',
  },
  {
    value: '499875',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradräderzubehör > Felgenbänder',
  },
  {
    value: '4601',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradräderzubehör > Speichennippel',
  },
  {
    value: '4194',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradsattelstützen',
  },
  {
    value: '4595',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradsattelstützenklemmen',
  },
  {
    value: '4585',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradschaltungsteile',
  },
  {
    value: '4588',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradschaltungsteile > Fahrradgangschaltungen',
  },
  {
    value: '4586',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradschaltungsteile > Fahrradkassetten',
  },
  {
    value: '4587',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradschaltungsteile > Fahrradketten',
  },
  {
    value: '4591',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradschaltungsteile > Fahrradkettenkränze',
  },
  {
    value: '4592',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradschaltungsteile > Fahrradkurbeln',
  },
  {
    value: '4593',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradschaltungsteile > Fahrradpedale',
  },
  {
    value: '4594',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradschaltungsteile > Fahrradschalthebel',
  },
  {
    value: '4590',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradschaltungsteile > Tretlagergehäuse',
  },
  {
    value: '4572',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradschläuche',
  },
  {
    value: '8239',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradständer',
  },
  {
    value: '3292',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Fahrradsättel',
  },
  {
    value: '4596',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Kleinere Fahrradzubehörteile',
  },
  {
    value: '499871',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Reifenventiladapter',
  },
  {
    value: '499870',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Reifenventile',
  },
  {
    value: '499869',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbauteile > Reifenventilkappen',
  },
  {
    value: '3982',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbekleidung & Zubehör',
  },
  {
    value: '8061',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbekleidung & Zubehör > Fahrrad-Schutzpolster',
  },
  {
    value: '3246',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbekleidung & Zubehör > Fahrradhandschuhe',
  },
  {
    value: '1029',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbekleidung & Zubehör > Fahrradhelme',
  },
  {
    value: '500028',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbekleidung & Zubehör > Fahrradhelmteile und -zubehör',
  },
  {
    value: '3272',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbekleidung & Zubehör > Fahrradüberschuhe',
  },
  {
    value: '3118',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbekleidung & Zubehör > Schuhplatten',
  },
  {
    value: '7474',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbekleidung & Zubehör > Schuhplatten-Zubehör',
  },
  {
    value: '7475',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbekleidung & Zubehör > Schuhplatten-Zubehör > Schuhplatten-Klemmen & -Keile',
  },
  {
    value: '7476',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbekleidung & Zubehör > Schuhplatten-Zubehör > Schuhplatten-Schrauben',
  },
  {
    value: '7453',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradbekleidung & Zubehör > Schuhplatten-Zubehör > Schuhplatten-Schutzhüllen',
  },
  {
    value: '3214',
    label: 'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör',
  },
  {
    value: '6506',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Dämpferpumpen',
  },
  {
    value: '7223',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrrad-Fußriemen',
  },
  {
    value: '6442',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradabdeckungen',
  },
  {
    value: '3811',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradanhänger',
  },
  {
    value: '3243',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradcomputer',
  },
  {
    value: '1028',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradgepäckträger',
  },
  {
    value: '3879',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradklingeln & -hupen',
  },
  {
    value: '3341',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradkörbe',
  },
  {
    value: '3827',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradpumpen',
  },
  {
    value: '6445',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradsattelbezüge & -polster',
  },
  {
    value: '1027',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradschlösser',
  },
  {
    value: '3719',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradschutzbleche',
  },
  {
    value: '7448',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradspeichen-Reflektoren',
  },
  {
    value: '3368',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradspiegel',
  },
  {
    value: '3428',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradständer & Fahrradparksysteme',
  },
  {
    value: '3778',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradtaschen',
  },
  {
    value: '3558',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradtaschen & -koffer',
  },
  {
    value: '505668',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Fahrradwerkzeug',
  },
  {
    value: '4145',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Flaschenhalter für Fahrradrahmen',
  },
  {
    value: '499694',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Flicken & Reparatur-Sets',
  },
  {
    value: '500092',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Griffe und Dekor für Fahrradlenker',
  },
  {
    value: '5842',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Kinderfahrradsitze',
  },
  {
    value: '3631',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Stützräder',
  },
  {
    value: '6048',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Surfbretthalterungen für Fahrräder',
  },
  {
    value: '3868',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Trainingsrollen',
  },
  {
    value: '500109',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Umrüstsatz für E-Bikes',
  },
  {
    value: '5540',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Zubehör für Fahrradcomputer',
  },
  {
    value: '500067',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrradzubehör > Zubehör für Fahrradkindersitze',
  },
  {
    value: '1026',
    label: 'Sportartikel > Outdoor-Aktivitäten > Radsport > Fahrräder',
  },
  {
    value: '1031',
    label: 'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör',
  },
  {
    value: '3257',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Pferdebedarf',
  },
  {
    value: '6898',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Pferdebedarf > Abreitdecken & Weidedecken',
  },
  {
    value: '499817',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Pferdebedarf > Fliegenmasken',
  },
  {
    value: '5569',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Pferdebedarf > Hufglocken & Gamaschen',
  },
  {
    value: '7481',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Pferdebedarf > Leckerli für Pferde',
  },
  {
    value: '7482',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Pferdebedarf > Pferdefutter',
  },
  {
    value: '5025',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Pferdebedarf > Pferdehaarpflege',
  },
  {
    value: '499818',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Pferdebedarf > Pferdehaarpflege > Kämme, Bürsten & Striegel',
  },
  {
    value: '6386',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Pferdebedarf > Pferdehaarpflege > Pferdeschermaschinen',
  },
  {
    value: '7459',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Pferdebedarf > Vitamine & Futterergänzungsmittel für Pferde',
  },
  {
    value: '499819',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Pferdebedarf > Wurmkur für Pferde',
  },
  {
    value: '5594',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Reitausrüstung',
  },
  {
    value: '3265',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Reitausrüstung > Gerten & Peitschen',
  },
  {
    value: '3084',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Reitausrüstung > Reithandschuhe',
  },
  {
    value: '3821',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Reitausrüstung > Reithelme',
  },
  {
    value: '6914',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Reitausrüstung > Reithosen',
  },
  {
    value: '5593',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Sattel- & Zaumzeug',
  },
  {
    value: '499710',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Sattel- & Zaumzeug > Halfter',
  },
  {
    value: '2756',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Sattel- & Zaumzeug > Pferdegeschirr',
  },
  {
    value: '499709',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Sattel- & Zaumzeug > Pferdeleinen',
  },
  {
    value: '1491',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Sattel- & Zaumzeug > Sattelgurte',
  },
  {
    value: '8109',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Sattel- & Zaumzeug > Steigbügel',
  },
  {
    value: '2210',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Sattel- & Zaumzeug > Sättel',
  },
  {
    value: '4018',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Sattel- & Zaumzeug > Trensen',
  },
  {
    value: '3426',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Sattel- & Zaumzeug > Zaumzeug',
  },
  {
    value: '1754',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Sattel- & Zaumzeug > Zügel',
  },
  {
    value: '7215',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Zubehör für Sattel & Zaumzeug',
  },
  {
    value: '499820',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Zubehör für Sattel & Zaumzeug > Sattel- & Zaumzeugbehälter',
  },
  {
    value: '8107',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Zubehör für Sattel & Zaumzeug > Sattelzubehör',
  },
  {
    value: '7216',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Zubehör für Sattel & Zaumzeug > Sattelzubehör > Sattelauflagen',
  },
  {
    value: '499959',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Zubehör für Sattel & Zaumzeug > Sattelzubehör > Sattelbezüge und -hüllen',
  },
  {
    value: '8108',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Zubehör für Sattel & Zaumzeug > Sattelzubehör > Sattelpolster & -decken',
  },
  {
    value: '326122',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Reitsportzubehör > Zubehör für Sattel & Zaumzeug > Sattelzubehör > Satteltaschen',
  },
  {
    value: '5879',
    label: 'Sportartikel > Outdoor-Aktivitäten > Roller',
  },
  {
    value: '3276',
    label: 'Sportartikel > Outdoor-Aktivitäten > Skateboarding',
  },
  {
    value: '3127',
    label: 'Sportartikel > Outdoor-Aktivitäten > Skateboarding > Skate-Rails',
  },
  {
    value: '3626',
    label: 'Sportartikel > Outdoor-Aktivitäten > Skateboarding > Skate-Rampen',
  },
  {
    value: '3670',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Skateboarding > Skateboard-Teile',
  },
  {
    value: '3192',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Skateboarding > Skateboard-Teile > Skateboard-Achsen',
  },
  {
    value: '3869',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Skateboarding > Skateboard-Teile > Skateboard-Decks',
  },
  {
    value: '505817',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Skateboarding > Skateboard-Teile > Skateboard-Kleinteile',
  },
  {
    value: '3637',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Skateboarding > Skateboard-Teile > Skateboard-Rollen',
  },
  {
    value: '3067',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Skateboarding > Skateboarding-Schutzausrüstung',
  },
  {
    value: '499776',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Skateboarding > Skateboarding-Schutzausrüstung > Skate-Helme',
  },
  {
    value: '7789',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Skateboarding > Skateboarding-Schutzausrüstung > Skateboarding-Handschuhe',
  },
  {
    value: '3488',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Skateboarding > Skateboarding-Schutzausrüstung > Skateboarding-Protektoren',
  },
  {
    value: '1059',
    label: 'Sportartikel > Outdoor-Aktivitäten > Skateboarding > Skateboards',
  },
  {
    value: '499761',
    label: 'Sportartikel > Outdoor-Aktivitäten > Skaten',
  },
  {
    value: '1058',
    label: 'Sportartikel > Outdoor-Aktivitäten > Skaten > Inline-Skates',
  },
  {
    value: '499759',
    label: 'Sportartikel > Outdoor-Aktivitäten > Skaten > Inlineskate-Teile',
  },
  {
    value: '2837',
    label: 'Sportartikel > Outdoor-Aktivitäten > Skaten > Rollerskates',
  },
  {
    value: '499760',
    label: 'Sportartikel > Outdoor-Aktivitäten > Skaten > Rollschuh-Teile',
  },
  {
    value: '500029',
    label: 'Sportartikel > Outdoor-Aktivitäten > Skaten > Rollskis',
  },
  {
    value: '499771',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Skaten > Schutzausrüstung für Skater',
  },
  {
    value: '499775',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Skaten > Schutzausrüstung für Skater > Rollschuh-Polster',
  },
  {
    value: '499846',
    label: 'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien',
  },
  {
    value: '1062',
    label: 'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Badminton',
  },
  {
    value: '3107',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Badminton > Badmintonnetze',
  },
  {
    value: '3950',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Badminton > Badmintonschläger & -sets',
  },
  {
    value: '3907',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Badminton > Federball',
  },
  {
    value: '7430',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Bälle für Paddle-Tennis',
  },
  {
    value: '3484',
    label: 'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Discgolf',
  },
  {
    value: '3227',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Discgolf > Discgolf-Fangkörbe',
  },
  {
    value: '3993',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Discgolf > Discgolf-Taschen',
  },
  {
    value: '499904',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Paddletennis',
  },
  {
    value: '499849',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Paddletennis > Paddletennis-Bälle',
  },
  {
    value: '499850',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Paddletennis > Paddletennis-Schläger',
  },
  {
    value: '3390',
    label: 'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Pickleball',
  },
  {
    value: '499847',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Pickleball > Pickleball-Bälle',
  },
  {
    value: '499848',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Pickleball > Pickleball-Schläger',
  },
  {
    value: '3405',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Rasenspiele',
  },
  {
    value: '3787',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Shuffleboard',
  },
  {
    value: '3689',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Shuffleboard > Shuffleboard-Cues',
  },
  {
    value: '3190',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Shuffleboard > Shuffleboard-Disks',
  },
  {
    value: '3126',
    label: 'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Tetherball',
  },
  {
    value: '499884',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Tetherball > Tetherball-Bälle',
  },
  {
    value: '499883',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Tetherball > Tetherball-Sets',
  },
  {
    value: '499882',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Spiele im Freien > Tetherball > Tetherball-Stangen',
  },
  {
    value: '5998',
    label: 'Sportartikel > Outdoor-Aktivitäten > Trinksystem-Zubehör',
  },
  {
    value: '5635',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Trinksystemrucksäcke & -taschen',
  },
  {
    value: '499844',
    label: 'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten',
  },
  {
    value: '499951',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Lawinenschutz',
  },
  {
    value: '499877',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Lawinenschutz > Lawinenairbags',
  },
  {
    value: '499952',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Lawinenschutz > Lawinensonden',
  },
  {
    value: '7539',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Rodelschlitten',
  },
  {
    value: '1166',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Schneeschuhe & Zubehör',
  },
  {
    value: '3073',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Schneeschuhe & Zubehör > Schneeschuh-Bindungen',
  },
  {
    value: '3064',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Schneeschuhe & Zubehör > Schneeschuhe',
  },
  {
    value: '499845',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden',
  },
  {
    value: '505296',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Ersatzteile für Skibindungen',
  },
  {
    value: '7558',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Ski- & Snowboard-Ablagen',
  },
  {
    value: '3550',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Ski- & Snowboard-Brillen',
  },
  {
    value: '8203',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Ski- & Snowboard-Brillenzubehör',
  },
  {
    value: '5050',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Ski- & Snowboard-Brillenzubehör > Ski- & Snowboard-Brillengläser',
  },
  {
    value: '499681',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Ski- & Snowboard-Leinen',
  },
  {
    value: '7224',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Ski- & Snowboard-Taschen',
  },
  {
    value: '8074',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Ski- & Snowboard-Wachs',
  },
  {
    value: '1161',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Ski- & Snowboardhelme',
  },
  {
    value: '6063',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Skibindungen',
  },
  {
    value: '6064',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Skier',
  },
  {
    value: '1158',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Skier > Alpinskier',
  },
  {
    value: '3331',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Skier > Langlaufskier',
  },
  {
    value: '6062',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Skischuhe',
  },
  {
    value: '1157',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Skistöcke',
  },
  {
    value: '1162',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Snowboard-Bindungen',
  },
  {
    value: '1164',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Snowboards',
  },
  {
    value: '1163',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Snowboardstiefel',
  },
  {
    value: '505772',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Tuningwerkzeuge für Skis & Snowboards',
  },
  {
    value: '5088',
    label:
      'Sportartikel > Outdoor-Aktivitäten > Wintersport & -Aktivitäten > Skisport & Snowboarden > Zubehör Snowboard-Bindungen',
  },
  {
    value: '5181',
    label: 'Taschen & Gepäck',
  },
  {
    value: '101',
    label: 'Taschen & Gepäck > Aktentaschen',
  },
  {
    value: '104',
    label: 'Taschen & Gepäck > Bauchtaschen',
  },
  {
    value: '5608',
    label: 'Taschen & Gepäck > Einkaufstaschen',
  },
  {
    value: '110',
    label: 'Taschen & Gepäck > Gepäckzubehör',
  },
  {
    value: '503014',
    label:
      'Taschen & Gepäck > Gepäckzubehör > Folien & Einsätze für Trockenboxen',
  },
  {
    value: '5620',
    label: 'Taschen & Gepäck > Gepäckzubehör > Gepäck-Organizer',
  },
  {
    value: '5651',
    label: 'Taschen & Gepäck > Gepäckzubehör > Gepäckanhänger',
  },
  {
    value: '499691',
    label: 'Taschen & Gepäck > Gepäckzubehör > Kofferablagen',
  },
  {
    value: '5652',
    label: 'Taschen & Gepäck > Gepäckzubehör > Koffergurte',
  },
  {
    value: '6919',
    label: 'Taschen & Gepäck > Gepäckzubehör > Reisebehälter',
  },
  {
    value: '5650',
    label: 'Taschen & Gepäck > Gepäckzubehör > Reisedokumententaschen',
  },
  {
    value: '7521',
    label: 'Taschen & Gepäck > Gepäckzubehör > Rucksack-Schutzhüllen',
  },
  {
    value: '105',
    label: 'Taschen & Gepäck > Kleidertaschen',
  },
  {
    value: '107',
    label: 'Taschen & Gepäck > Koffer',
  },
  {
    value: '6553',
    label: 'Taschen & Gepäck > Kosmetikkoffer',
  },
  {
    value: '108',
    label: 'Taschen & Gepäck > Kulturtaschen',
  },
  {
    value: '106',
    label: 'Taschen & Gepäck > Kurier- & Schultertaschen',
  },
  {
    value: '100',
    label: 'Taschen & Gepäck > Rucksäcke',
  },
  {
    value: '103',
    label: 'Taschen & Gepäck > Seesäcke',
  },
  {
    value: '502974',
    label: 'Taschen & Gepäck > Trockenboxen',
  },
  {
    value: '549',
    label: 'Taschen & Gepäck > Wickeltaschen',
  },
  {
    value: '1',
    label: 'Tiere & Tierbedarf',
  },
  {
    value: '2',
    label: 'Tiere & Tierbedarf > Haustierbedarf',
  },
  {
    value: '6983',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Agility-Ausrüstung',
  },
  {
    value: '6',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Aquaristik',
  },
  {
    value: '3238',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Aquarien',
  },
  {
    value: '5079',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Aquariumbeleuchtung',
  },
  {
    value: '5019',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Aquariumdekoration',
  },
  {
    value: '5020',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Aquariumfilter',
  },
  {
    value: '5023',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Aquariumständer',
  },
  {
    value: '505307',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Ausströmersteine & Diffusoren für Aquarien ',
  },
  {
    value: '5021',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Bodengrund für Aquarien',
  },
  {
    value: '5024',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Fischfutter',
  },
  {
    value: '6403',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Fischfutterautomaten',
  },
  {
    value: '505306',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Fischnetze für Aquarien',
  },
  {
    value: '505303',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Schläuche & Rohre für Aquarien & Teiche',
  },
  {
    value: '500062',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Temperaturregler für Aquarien',
  },
  {
    value: '5161',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Wasseraufbereitungsgeräte',
  },
  {
    value: '6085',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Wasserpflanzendünger',
  },
  {
    value: '500038',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Zubehör für Aquariumreinigung',
  },
  {
    value: '6951',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Aquaristik > Überlaufkästen',
  },
  {
    value: '8050',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Augentropfen für Haustiere',
  },
  {
    value: '6321',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Begrenzungssysteme',
  },
  {
    value: '6811',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Bügel für Haustierbekleidung',
  },
  {
    value: '505297',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Entsorgungssysteme & -werkzeug für Tierkot',
  },
  {
    value: '5093',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Erkennungsmarken',
  },
  {
    value: '8068',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Erste-Hilfe-Koffer für Haustiere',
  },
  {
    value: '6978',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Fitnessmonitor für Haustiere',
  },
  {
    value: '6980',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Fitnessmonitor für Haustiere > Glukosemessgeräte für Haustiere',
  },
  {
    value: '6982',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Fitnessmonitor für Haustiere > Schrittzähler für Haustiere',
  },
  {
    value: '6981',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Fitnessmonitor für Haustiere > Thermometer für Haustiere',
  },
  {
    value: '6248',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Floh- & Zeckenschutzmittel',
  },
  {
    value: '6252',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Futter- & Wasserspender',
  },
  {
    value: '5092',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Glocken & Anhänger',
  },
  {
    value: '6250',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Halsbänder & Geschirre',
  },
  {
    value: '6383',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Haustierpflegeartikel',
  },
  {
    value: '503733',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Haustierpflegeartikel > Düfte & Deosprays für Haustiere',
  },
  {
    value: '499917',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Haustierpflegeartikel > Feuchttücher für Haustiere',
  },
  {
    value: '8167',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Haustierpflegeartikel > Haartrockner für Haustiere',
  },
  {
    value: '6385',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Haustierpflegeartikel > Kämme & Bürsten zur Fellpflege',
  },
  {
    value: '7318',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Haustierpflegeartikel > Nagellack für Haustiere',
  },
  {
    value: '7319',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Haustierpflegeartikel > Nagelpflege für Haustiere',
  },
  {
    value: '6406',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Haustierpflegeartikel > Shampoos & Spülungen zur Fellpflege',
  },
  {
    value: '6384',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Haustierpflegeartikel > Tierhaarschneider',
  },
  {
    value: '4497',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Haustiertüren',
  },
  {
    value: '505314',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Hilfsmittel für Tierausbildung',
  },
  {
    value: '6846',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Hilfsmittel für Tierausbildung > Bodenschutz & Unterlagen',
  },
  {
    value: '505313',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Hilfsmittel für Tierausbildung > Clicker & Belohnungsspielzeug für Tierausbildung',
  },
  {
    value: '505304',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Hilfsmittel für Tierausbildung > Pad-Unterlagen für Tierausbildung',
  },
  {
    value: '505311',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Hilfsmittel für Tierausbildung > Sprays & Lösungen für Tierausbildung',
  },
  {
    value: '5',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Hundebedarf',
  },
  {
    value: '7372',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Hundebedarf > Einlagen für Hundewindeln',
  },
  {
    value: '5004',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Hundebedarf > Hundebekleidung',
  },
  {
    value: '4434',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Hundebedarf > Hundebetten',
  },
  {
    value: '3530',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Hundebedarf > Hundefutter',
  },
  {
    value: '5094',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Hundebedarf > Hundehütten',
  },
  {
    value: '8123',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Hundebedarf > Hundelaufbänder',
  },
  {
    value: '5010',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Hundebedarf > Hundespielzeug',
  },
  {
    value: '499900',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Hundebedarf > Hundewindeln',
  },
  {
    value: '7274',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Hundebedarf > Hundezwinger & -ausläufe',
  },
  {
    value: '5011',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Hundebedarf > Leckerbissen für Hunde',
  },
  {
    value: '7428',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Hundebedarf > Zubehör für Hundezwinger & -ausläufe',
  },
  {
    value: '4',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Katzenbedarf',
  },
  {
    value: '8069',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Katzenbedarf > Einlagen für Katzentoilette',
  },
  {
    value: '5082',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Katzenbedarf > Katzenbekleidung',
  },
  {
    value: '4433',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Katzenbedarf > Katzenbetten',
  },
  {
    value: '3367',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Katzenbedarf > Katzenfutter',
  },
  {
    value: '4997',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Katzenbedarf > Katzenmöbel',
  },
  {
    value: '5001',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Katzenbedarf > Katzenspielzeug',
  },
  {
    value: '4999',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Katzenbedarf > Katzenstreu',
  },
  {
    value: '5000',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Katzenbedarf > Katzentoiletten',
  },
  {
    value: '5002',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Katzenbedarf > Leckerbissen für Katzen',
  },
  {
    value: '7142',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Katzenbedarf > Vorleger für Katzentoiletten',
  },
  {
    value: '500059',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Katzenbedarf > Zubehör für Katzenmöbel',
  },
  {
    value: '5013',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Kleintierzubehör',
  },
  {
    value: '5014',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Kleintierzubehör > Decken & Streu für Kleintiere',
  },
  {
    value: '5015',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Kleintierzubehör > Futter für Kleintiere',
  },
  {
    value: '5017',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Kleintierzubehör > Lebensräume & Gehege für Kleintiere',
  },
  {
    value: '7517',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Kleintierzubehör > Leckerli für Kleintiere',
  },
  {
    value: '5016',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Kleintierzubehör > Zubehör für Lebensräume von Kleintieren',
  },
  {
    value: '8070',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Kotbeutel',
  },
  {
    value: '6249',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Leinen',
  },
  {
    value: '5144',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Maulkörbe',
  },
  {
    value: '5145',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Medizinische Halsbänder für Haustiere',
  },
  {
    value: '6861',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Medizinisches Klebeband & Verbandsmaterial für Haustiere',
  },
  {
    value: '7144',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Mundpflege für Haustiere',
  },
  {
    value: '8513',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Napfständer',
  },
  {
    value: '7143',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Napfunterlagen',
  },
  {
    value: '6276',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Pet-Stroller',
  },
  {
    value: '7',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Reptilien- & Amphibienbedarf',
  },
  {
    value: '5030',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Reptilien- & Amphibienbedarf > Bodengrund für Reptilien & Amphibien',
  },
  {
    value: '5028',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Reptilien- & Amphibienbedarf > Heizung & Beleuchtung für Terrarien',
  },
  {
    value: '5026',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Reptilien- & Amphibienbedarf > Reptilien- & Amphibienfutter',
  },
  {
    value: '5029',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Reptilien- & Amphibienbedarf > Terrarien',
  },
  {
    value: '5027',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Reptilien- & Amphibienbedarf > Zubehör für Terrarien',
  },
  {
    value: '7396',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Sonnenschutz für Haustiere',
  },
  {
    value: '502982',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Spender & Halterungen für Kotbeutel',
  },
  {
    value: '5087',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Spielgehege für Haustiere',
  },
  {
    value: '5162',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Tierfutterbehälter',
  },
  {
    value: '5163',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Tierfutterportionierer',
  },
  {
    value: '5086',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Tiermedikamente',
  },
  {
    value: '6251',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Transportboxen & -taschen',
  },
  {
    value: '8474',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Trennvorrichtungen für den Haustiertransport',
  },
  {
    value: '6973',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Treppen & Rampen für Haustiere',
  },
  {
    value: '6253',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Verlängerungsleinen',
  },
  {
    value: '5081',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Vitamine & Nahrungsergänzungsmittel für Haustiere',
  },
  {
    value: '3',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Vogelbedarf',
  },
  {
    value: '4993',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Vogelbedarf > Snacks für Vögel',
  },
  {
    value: '7398',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Vogelbedarf > Spielvorrichtungen für Vögel',
  },
  {
    value: '4990',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Vogelbedarf > Vogelfutter',
  },
  {
    value: '4989',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Vogelbedarf > Vogelkäfige & -ständer',
  },
  {
    value: '7385',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Vogelbedarf > Vogelkäfigzubehör',
  },
  {
    value: '7386',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Vogelbedarf > Vogelkäfigzubehör > Futter- & Wasserspender für Vogelkäfige',
  },
  {
    value: '499954',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Vogelbedarf > Vogelkäfigzubehör > Vogelbäder für Vogelkäfige',
  },
  {
    value: '4991',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Vogelbedarf > Vogelleitern & -sitzstangen',
  },
  {
    value: '4992',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Vogelbedarf > Vogelspielzeug',
  },
  {
    value: '499743',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Wärmekissen für Haustiere',
  },
  {
    value: '500110',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Zubehör für Haustier-Heizkissen',
  },
  {
    value: '500084',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Zubehör für Haustierbetten',
  },
  {
    value: '505811',
    label: 'Tiere & Tierbedarf > Haustierbedarf > Zubehör für Haustierklappen',
  },
  {
    value: '500026',
    label:
      'Tiere & Tierbedarf > Haustierbedarf > Zubehör für Haustiertragetaschen und -boxen',
  },
  {
    value: '3237',
    label: 'Tiere & Tierbedarf > Lebende Tiere',
  },
  {
    value: '111',
    label: 'Wirtschaft & Industrie',
  },
  {
    value: '2047',
    label: 'Wirtschaft & Industrie > Arbeitsschutzausrüstung',
  },
  {
    value: '2808',
    label:
      'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Chemikalienschutzanzüge',
  },
  {
    value: '8269',
    label:
      'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Gasmasken- & Atemschutzmaskenzubehör',
  },
  {
    value: '7085',
    label: 'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Halteseile',
  },
  {
    value: '499961',
    label: 'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Knieschoner',
  },
  {
    value: '2389',
    label:
      'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Kugelsichere Westen',
  },
  {
    value: '2227',
    label: 'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Schutzbrillen',
  },
  {
    value: '5591',
    label:
      'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Schutzhandschuhe',
  },
  {
    value: '2723',
    label: 'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Schutzhelme',
  },
  {
    value: '503724',
    label: 'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Schutzmasken',
  },
  {
    value: '2349',
    label:
      'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Schutzmasken > Feuerwehr-Atemschutzmasken',
  },
  {
    value: '2473',
    label:
      'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Schutzmasken > Gas- & Atemschutzmasken',
  },
  {
    value: '513',
    label:
      'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Schutzmasken > Medizinische Masken',
  },
  {
    value: '7407',
    label:
      'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Schutzmasken > Staubmasken',
  },
  {
    value: '499927',
    label: 'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Schweißhelme',
  },
  {
    value: '6764',
    label:
      'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Sicherheitsschürzen',
  },
  {
    value: '499708',
    label: 'Wirtschaft & Industrie > Arbeitsschutzausrüstung > Sicherungsgurte',
  },
  {
    value: '8025',
    label:
      'Wirtschaft & Industrie > Aufbewahrungszubehör für industrielle Zwecke',
  },
  {
    value: '7261',
    label: 'Wirtschaft & Industrie > Automatisierungskomponenten',
  },
  {
    value: '7262',
    label:
      'Wirtschaft & Industrie > Automatisierungskomponenten > Antriebssysteme mit variabler Frequenz & einstellbarer Drehzahl',
  },
  {
    value: '7263',
    label:
      'Wirtschaft & Industrie > Automatisierungskomponenten > Speicherprogrammierbare Steuerungen',
  },
  {
    value: '114',
    label: 'Wirtschaft & Industrie > Baugewerbe',
  },
  {
    value: '8278',
    label: 'Wirtschaft & Industrie > Baugewerbe > Verkehrskegel',
  },
  {
    value: '134',
    label: 'Wirtschaft & Industrie > Baugewerbe > Vermessung',
  },
  {
    value: '2187',
    label: 'Wirtschaft & Industrie > Bergbau & Steinbruch',
  },
  {
    value: '976',
    label: 'Wirtschaft & Industrie > Beschilderung',
  },
  {
    value: '8155',
    label: 'Wirtschaft & Industrie > Beschilderung > Digitale Schilder',
  },
  {
    value: '5898',
    label: 'Wirtschaft & Industrie > Beschilderung > Einzelhandelsschilder',
  },
  {
    value: '4297',
    label: 'Wirtschaft & Industrie > Beschilderung > Elektronische Schilder',
  },
  {
    value: '4131',
    label:
      'Wirtschaft & Industrie > Beschilderung > Elektronische Schilder > LED-Schilder',
  },
  {
    value: '4070',
    label:
      'Wirtschaft & Industrie > Beschilderung > Elektronische Schilder > Neonschilder',
  },
  {
    value: '7323',
    label:
      'Wirtschaft & Industrie > Beschilderung > Geöffnet- und Geschlossen-Schilder',
  },
  {
    value: '5900',
    label: 'Wirtschaft & Industrie > Beschilderung > Hinweisschilder',
  },
  {
    value: '5894',
    label:
      'Wirtschaft & Industrie > Beschilderung > Notfall- & Notausgangsschilder',
  },
  {
    value: '5896',
    label: 'Wirtschaft & Industrie > Beschilderung > Parkplatzschilder',
  },
  {
    value: '5893',
    label: 'Wirtschaft & Industrie > Beschilderung > Sicherheitsschilder',
  },
  {
    value: '5899',
    label: 'Wirtschaft & Industrie > Beschilderung > Tragbare Schilder',
  },
  {
    value: '5895',
    label: 'Wirtschaft & Industrie > Beschilderung > Verkehrsschilder',
  },
  {
    value: '5892',
    label: 'Wirtschaft & Industrie > Beschilderung > Warnschilder',
  },
  {
    value: '5897',
    label: 'Wirtschaft & Industrie > Beschilderung > Wegweiser',
  },
  {
    value: '7322',
    label: 'Wirtschaft & Industrie > Beschilderung > Öffnungszeiten-Schilder',
  },
  {
    value: '138',
    label: 'Wirtschaft & Industrie > Einzelhandel',
  },
  {
    value: '1837',
    label:
      'Wirtschaft & Industrie > Einzelhandel > Einkaufstüten aus Papier & Plastik',
  },
  {
    value: '4181',
    label: 'Wirtschaft & Industrie > Einzelhandel > Geldhandhabung',
  },
  {
    value: '4290',
    label:
      'Wirtschaft & Industrie > Einzelhandel > Geldhandhabung > Geldscheinprüfgeräte',
  },
  {
    value: '4329',
    label:
      'Wirtschaft & Industrie > Einzelhandel > Geldhandhabung > Geldtaschen',
  },
  {
    value: '3273',
    label:
      'Wirtschaft & Industrie > Einzelhandel > Geldhandhabung > Geldwechsler',
  },
  {
    value: '4151',
    label:
      'Wirtschaft & Industrie > Einzelhandel > Geldhandhabung > Geldzählmaschinen für Münzen und Scheine',
  },
  {
    value: '505824',
    label:
      'Wirtschaft & Industrie > Einzelhandel > Geldhandhabung > Ladenkassen & POS-Terminals',
  },
  {
    value: '4055',
    label:
      'Wirtschaft & Industrie > Einzelhandel > Geldhandhabung > Münzrollenpapier & Geldscheinbanderolen',
  },
  {
    value: '505825',
    label:
      'Wirtschaft & Industrie > Einzelhandel > Geldhandhabung > Zubehör für Ladenkassen & POS-Terminals',
  },
  {
    value: '4283',
    label:
      'Wirtschaft & Industrie > Einzelhandel > Geldhandhabung > Zubehör für Ladenkassen & POS-Terminals > Kassenschubladen',
  },
  {
    value: '505808',
    label:
      'Wirtschaft & Industrie > Einzelhandel > Geldhandhabung > Zubehör für Ladenkassen & POS-Terminals > Kreditkartenterminals',
  },
  {
    value: '5310',
    label:
      'Wirtschaft & Industrie > Einzelhandel > Geldhandhabung > Zubehör für Ladenkassen & POS-Terminals > Unterschriftenpads',
  },
  {
    value: '4127',
    label: 'Wirtschaft & Industrie > Einzelhandel > Preisauszeichnungsgeräte',
  },
  {
    value: '4244',
    label:
      'Wirtschaft & Industrie > Einzelhandel > Präsentationsständer für Bekleidung',
  },
  {
    value: '499897',
    label: 'Wirtschaft & Industrie > Einzelhandel > Schaufensterdeko & -puppen',
  },
  {
    value: '3803',
    label: 'Wirtschaft & Industrie > Einzelhandel > Schaufensterpuppen',
  },
  {
    value: '7128',
    label: 'Wirtschaft & Industrie > Einzelhandel > Schaufensterpuppenteile',
  },
  {
    value: '4160',
    label: 'Wirtschaft & Industrie > Einzelhandel > Vitrinen',
  },
  {
    value: '2155',
    label: 'Wirtschaft & Industrie > Film & Fernsehen',
  },
  {
    value: '1813',
    label: 'Wirtschaft & Industrie > Finanzwesen & Versicherung',
  },
  {
    value: '7565',
    label: 'Wirtschaft & Industrie > Finanzwesen & Versicherung > Bullion',
  },
  {
    value: '1827',
    label: 'Wirtschaft & Industrie > Forst- & Holzwirtschaft',
  },
  {
    value: '7240',
    label: 'Wirtschaft & Industrie > Friseur- & Kosmetikgewerbe',
  },
  {
    value: '505670',
    label:
      'Wirtschaft & Industrie > Friseur- & Kosmetikgewerbe > Friseurumhänge & Halskrausen',
  },
  {
    value: '7242',
    label:
      'Wirtschaft & Industrie > Friseur- & Kosmetikgewerbe > Pedikürestuhl',
  },
  {
    value: '7241',
    label: 'Wirtschaft & Industrie > Friseur- & Kosmetikgewerbe > Salonstühle',
  },
  {
    value: '5830',
    label: 'Wirtschaft & Industrie > Gewerbliche Lagerung',
  },
  {
    value: '5831',
    label: 'Wirtschaft & Industrie > Gewerbliche Lagerung > Frachtcontainer',
  },
  {
    value: '5833',
    label: 'Wirtschaft & Industrie > Gewerbliche Lagerung > Industrieregale',
  },
  {
    value: '5832',
    label: 'Wirtschaft & Industrie > Gewerbliche Lagerung > Industrieschränke',
  },
  {
    value: '8274',
    label:
      'Wirtschaft & Industrie > Gewerbliche Lagerung > Zäune, Einfriedungen & Türen',
  },
  {
    value: '1470',
    label: 'Wirtschaft & Industrie > Herstellung',
  },
  {
    value: '1475',
    label: 'Wirtschaft & Industrie > Hotels & Gaststätten',
  },
  {
    value: '112',
    label: 'Wirtschaft & Industrie > Landwirtschaft',
  },
  {
    value: '6991',
    label: 'Wirtschaft & Industrie > Landwirtschaft > Viehhaltung',
  },
  {
    value: '499997',
    label:
      'Wirtschaft & Industrie > Landwirtschaft > Viehhaltung > Brutapparate',
  },
  {
    value: '505821',
    label: 'Wirtschaft & Industrie > Landwirtschaft > Viehhaltung > Viehfutter',
  },
  {
    value: '6990',
    label:
      'Wirtschaft & Industrie > Landwirtschaft > Viehhaltung > Viehfutterspender',
  },
  {
    value: '499946',
    label:
      'Wirtschaft & Industrie > Landwirtschaft > Viehhaltung > Viehhalfter',
  },
  {
    value: '6987',
    label: 'Wirtschaft & Industrie > Materialbeförderung',
  },
  {
    value: '131',
    label:
      'Wirtschaft & Industrie > Materialbeförderung > Aufzüge & Hebeeinrichtungen',
  },
  {
    value: '503769',
    label:
      'Wirtschaft & Industrie > Materialbeförderung > Aufzüge & Hebeeinrichtungen > Flaschenzüge & Rollen',
  },
  {
    value: '503768',
    label:
      'Wirtschaft & Industrie > Materialbeförderung > Aufzüge & Hebeeinrichtungen > Hebezüge, Kräne & Laufkatzen',
  },
  {
    value: '503771',
    label:
      'Wirtschaft & Industrie > Materialbeförderung > Aufzüge & Hebeeinrichtungen > Hubwagen',
  },
  {
    value: '503767',
    label:
      'Wirtschaft & Industrie > Materialbeförderung > Aufzüge & Hebeeinrichtungen > Personenaufzüge',
  },
  {
    value: '503772',
    label:
      'Wirtschaft & Industrie > Materialbeförderung > Aufzüge & Hebeeinrichtungen > Seilwinden',
  },
  {
    value: '6988',
    label: 'Wirtschaft & Industrie > Materialbeförderung > Förderanlagen',
  },
  {
    value: '503011',
    label:
      'Wirtschaft & Industrie > Materialbeförderung > Paletten & Ladeflächen',
  },
  {
    value: '2496',
    label: 'Wirtschaft & Industrie > Medizinischer Bereich',
  },
  {
    value: '6275',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Krankenhaus-Vorhänge',
  },
  {
    value: '1898',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Krankenhausbekleidung',
  },
  {
    value: '6303',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Bettwäsche',
  },
  {
    value: '3477',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Geräte',
  },
  {
    value: '3230',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Geräte > Automatisierte externe Defibrillatoren',
  },
  {
    value: '503006',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Geräte > Medizinische Hebegürtel',
  },
  {
    value: '4245',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Geräte > Otoskope & Ophthalmoskope',
  },
  {
    value: '7522',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Geräte > Patientenlifter',
  },
  {
    value: '6972',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Geräte > Reflexhämmer',
  },
  {
    value: '4364',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Geräte > Stethoskope',
  },
  {
    value: '499858',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Geräte > Tragen',
  },
  {
    value: '6280',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Geräte > Vitaldatenmonitore',
  },
  {
    value: '6714',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Geräte > Zubehör für Vitaldatenmonitore',
  },
  {
    value: '230913',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Instrumente',
  },
  {
    value: '499935',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Instrumente > Chirurgische Nadeln und Fäden',
  },
  {
    value: '6281',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Instrumente > Medizinische Zangen',
  },
  {
    value: '8026',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Instrumente > Skalpelle',
  },
  {
    value: '232166',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Instrumente > Skalpellklingen',
  },
  {
    value: '5167',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Möbel',
  },
  {
    value: '5168',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Möbel > Chiropraktische Tische',
  },
  {
    value: '4435',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Möbel > Krankenhaus- und Pflegebetten',
  },
  {
    value: '5170',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Möbel > Medikamentenaufbewahrung',
  },
  {
    value: '5171',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Möbel > Medizinische Wagen',
  },
  {
    value: '5174',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Möbel > Medizinische Wagen > Infusionsständer & -wagen',
  },
  {
    value: '5173',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Möbel > Medizinische Wagen > Reanimationswagen',
  },
  {
    value: '5172',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Möbel > OP-Tische',
  },
  {
    value: '5169',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Möbel > Untersuchungsstühle & -tische',
  },
  {
    value: '6490',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Schulungsgeräte',
  },
  {
    value: '6491',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinische Schulungsgeräte > HLW-Puppen',
  },
  {
    value: '2907',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinischer Bedarf',
  },
  {
    value: '511',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinischer Bedarf > Einweghandschuhe',
  },
  {
    value: '7063',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinischer Bedarf > Fingerlinge',
  },
  {
    value: '7324',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinischer Bedarf > Mundspatel',
  },
  {
    value: '499696',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinischer Bedarf > Nadeln & Spritzen',
  },
  {
    value: '505828',
    label:
      'Wirtschaft & Industrie > Medizinischer Bereich > Medizinischer Bedarf > Stomazubehör',
  },
  {
    value: '2928',
    label: 'Wirtschaft & Industrie > Medizinischer Bereich > OP-Bekleidung',
  },
  {
    value: '5602',
    label: 'Wirtschaft & Industrie > Medizinischer Bereich > OP-Hauben',
  },
  {
    value: '1645',
    label: 'Wirtschaft & Industrie > Medizinischer Bereich > OP-Kittel',
  },
  {
    value: '135',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice',
  },
  {
    value: '8533',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Auslagenschutz',
  },
  {
    value: '7303',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Backwarenbehälter',
  },
  {
    value: '7088',
    label:
      'Wirtschaft & Industrie > Nahrungsmittelservice > Einweg-Serviergeschirr',
  },
  {
    value: '7089',
    label:
      'Wirtschaft & Industrie > Nahrungsmittelservice > Einweg-Serviergeschirr > Einwegtabletts',
  },
  {
    value: '5097',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Einwegbehälter',
  },
  {
    value: '8059',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Einwegdeckel',
  },
  {
    value: '4632',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Einweggeschirr',
  },
  {
    value: '5099',
    label:
      'Wirtschaft & Industrie > Nahrungsmittelservice > Einweggeschirr > Einwegbecher',
  },
  {
    value: '5100',
    label:
      'Wirtschaft & Industrie > Nahrungsmittelservice > Einweggeschirr > Einwegbesteck',
  },
  {
    value: '5098',
    label:
      'Wirtschaft & Industrie > Nahrungsmittelservice > Einweggeschirr > Einwegschüsseln',
  },
  {
    value: '5101',
    label:
      'Wirtschaft & Industrie > Nahrungsmittelservice > Einweggeschirr > Einwegteller',
  },
  {
    value: '7353',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Eisbehälter',
  },
  {
    value: '6786',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Gemüseschleudern',
  },
  {
    value: '6517',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Hot-Dog-Grills',
  },
  {
    value: '7553',
    label:
      'Wirtschaft & Industrie > Nahrungsmittelservice > Kippbare Industriepfannen',
  },
  {
    value: '4096',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Lebensmittelkörbe',
  },
  {
    value: '8532',
    label:
      'Wirtschaft & Industrie > Nahrungsmittelservice > Scheck- & Kartenetuis',
  },
  {
    value: '4742',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Servierwagen',
  },
  {
    value: '4217',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Spülwannen',
  },
  {
    value: '5102',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Takeaway-Behälter',
  },
  {
    value: '5104',
    label:
      'Wirtschaft & Industrie > Nahrungsmittelservice > Teller- & Speisenwärmer',
  },
  {
    value: '137',
    label: 'Wirtschaft & Industrie > Nahrungsmittelservice > Verkaufsautomaten',
  },
  {
    value: '4285',
    label: 'Wirtschaft & Industrie > Piercing & Tätowieren',
  },
  {
    value: '4350',
    label: 'Wirtschaft & Industrie > Piercing & Tätowieren > Piercingbedarf',
  },
  {
    value: '4122',
    label:
      'Wirtschaft & Industrie > Piercing & Tätowieren > Piercingbedarf > Piercingnadeln',
  },
  {
    value: '4326',
    label: 'Wirtschaft & Industrie > Piercing & Tätowieren > Tätowierbedarf',
  },
  {
    value: '5853',
    label:
      'Wirtschaft & Industrie > Piercing & Tätowieren > Tätowierbedarf > Tattoo-Abdeckungen',
  },
  {
    value: '4215',
    label:
      'Wirtschaft & Industrie > Piercing & Tätowieren > Tätowierbedarf > Tätowierfarben',
  },
  {
    value: '4379',
    label:
      'Wirtschaft & Industrie > Piercing & Tätowieren > Tätowierbedarf > Tätowiermaschinen',
  },
  {
    value: '4072',
    label:
      'Wirtschaft & Industrie > Piercing & Tätowieren > Tätowierbedarf > Tätowiernadeln',
  },
  {
    value: '500086',
    label: 'Wirtschaft & Industrie > Reinigungswagen',
  },
  {
    value: '1795',
    label: 'Wirtschaft & Industrie > Schwermaschinen',
  },
  {
    value: '2072',
    label: 'Wirtschaft & Industrie > Schwermaschinen > Häcksler',
  },
  {
    value: '1556',
    label: 'Wirtschaft & Industrie > Strafverfolgung',
  },
  {
    value: '361',
    label: 'Wirtschaft & Industrie > Strafverfolgung > Metalldetektoren',
  },
  {
    value: '1906',
    label: 'Wirtschaft & Industrie > Strafverfolgung > Schellen',
  },
  {
    value: '5863',
    label: 'Wirtschaft & Industrie > Werbung & Marketing',
  },
  {
    value: '5884',
    label: 'Wirtschaft & Industrie > Werbung & Marketing > Broschüren',
  },
  {
    value: '5865',
    label: 'Wirtschaft & Industrie > Werbung & Marketing > Messestand-Displays',
  },
  {
    value: '5864',
    label: 'Wirtschaft & Industrie > Werbung & Marketing > Messetheken',
  },
  {
    value: '1624',
    label: 'Wirtschaft & Industrie > Wissenschaft & Labor',
  },
  {
    value: '6975',
    label: 'Wirtschaft & Industrie > Wissenschaft & Labor > Biochemikalien',
  },
  {
    value: '4335',
    label: 'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung',
  },
  {
    value: '4116',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Autoklaven',
  },
  {
    value: '500057',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Gefriertrockenmaschinen',
  },
  {
    value: '500114',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Labor-Gefrierschränke',
  },
  {
    value: '4133',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Labor-Heizplatten',
  },
  {
    value: '4474',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Labormixer',
  },
  {
    value: '503722',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Labortrichter',
  },
  {
    value: '4231',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Laboröfen',
  },
  {
    value: '158',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Mikroskope',
  },
  {
    value: '4555',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Mikroskopzubehör',
  },
  {
    value: '4664',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Mikroskopzubehör > Ersatzlampen für Mikroskope',
  },
  {
    value: '4665',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Mikroskopzubehör > Mikroskop-Objektivlinsen',
  },
  {
    value: '4557',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Mikroskopzubehör > Mikroskopkameras',
  },
  {
    value: '4556',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Mikroskopzubehör > Mikroskopokulare & Adapter',
  },
  {
    value: '4558',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Mikroskopzubehör > Objektträger',
  },
  {
    value: '7437',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Mikrotome',
  },
  {
    value: '7393',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Spektrometer',
  },
  {
    value: '7218',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Trockeneismaschinen',
  },
  {
    value: '4336',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Zentrifugen',
  },
  {
    value: '7468',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborausstattung > Zubehör für Spektrometer',
  },
  {
    value: '4255',
    label: 'Wirtschaft & Industrie > Wissenschaft & Labor > Laborbedarf',
  },
  {
    value: '4310',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborbedarf > Bechergläse',
  },
  {
    value: '4036',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborbedarf > Laborkolben',
  },
  {
    value: '4061',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborbedarf > Messzylinder',
  },
  {
    value: '4276',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborbedarf > Petrischalen',
  },
  {
    value: '4075',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborbedarf > Pipetten',
  },
  {
    value: '4155',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborbedarf > Reagenzglasständer',
  },
  {
    value: '4306',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborbedarf > Reagenzgläser',
  },
  {
    value: '4140',
    label:
      'Wirtschaft & Industrie > Wissenschaft & Labor > Laborbedarf > Spritzflaschen',
  },
  {
    value: '3002',
    label: 'Wirtschaft & Industrie > Wissenschaft & Labor > Laborchemikalien',
  },
  {
    value: '8119',
    label: 'Wirtschaft & Industrie > Wissenschaft & Labor > Laborproben',
  },
  {
    value: '7325',
    label: 'Wirtschaft & Industrie > Wissenschaft & Labor > Sezierinstrumente',
  },
  {
    value: '7497',
    label: 'Wirtschaft & Industrie > Zahnmedizin',
  },
  {
    value: '8130',
    label: 'Wirtschaft & Industrie > Zahnmedizin > Prophy-Paste',
  },
  {
    value: '7499',
    label:
      'Wirtschaft & Industrie > Zahnmedizin > Zahnmedizinische Instrumente',
  },
  {
    value: '8490',
    label:
      'Wirtschaft & Industrie > Zahnmedizin > Zahnmedizinische Instrumente > Dappengläser',
  },
  {
    value: '7498',
    label:
      'Wirtschaft & Industrie > Zahnmedizin > Zahnmedizinische Instrumente > Dentalspiegel',
  },
  {
    value: '8121',
    label:
      'Wirtschaft & Industrie > Zahnmedizin > Zahnmedizinische Instrumente > Prophy-Kelche',
  },
  {
    value: '8120',
    label:
      'Wirtschaft & Industrie > Zahnmedizin > Zahnmedizinische Instrumente > Prophy-Köpfe',
  },
  {
    value: '7531',
    label:
      'Wirtschaft & Industrie > Zahnmedizin > Zahnmedizinische Instrumente > Zahnmedizinische Instrumentensets',
  },
  {
    value: '7500',
    label: 'Wirtschaft & Industrie > Zahnmedizin > Zahnzement',
  },
]
