import { useEffect, useState } from 'react'
import SearchableLinkStore from '../../stores/SearchableLinkStore'
import { observer } from 'mobx-react-lite'
import t from '../../utils/translate'
import ContentWrapper from '../../components/ContentWrapper'
import ResourceTable from '../../components/ResourceTable'
import { ArrowAction, Column } from '../../components/Table'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import ImporterPageTitle from '../SearchConfigurationV3/ImporterPageTitle'
import { ActionLayerStore } from '../../stores'
import { createRedirectPath } from '../../utils'
import { useHistory } from 'react-router-dom'

function SearchableLinksList() {
  const [selectedRows, setSelectedRows] = useState([])
  const { setFilter } = SearchableLinkStore
  const history = useHistory()

  useEffect(() => {
    SearchableLinkStore.fetchSearchableLinks()
  }, [])

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    await SearchableLinkStore.delete(selectedRows)

    if (SearchableLinkStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const bulkActions = [
    {
      title: t('Delete'),
      onExecute: () => showDeleteWarning(),
    },
  ]

  const handleRowClick = (row) =>
    history.push(createRedirectPath('/searchable-link/' + row.id))

  return (
    <>
      <ImporterPageTitle />
      <ContentWrapper>
        <ResourceTable
          title={t('Searchable Links')}
          store={SearchableLinkStore}
          resourceName="searchableLinks"
          filterParams="filter"
          filters={[
            {
              type: 'search',
              title: t('Search'),
              name: 'q',
              onChange: setFilter,
            },
          ]}
          loading={SearchableLinkStore.state === 'pending'}
          bulkActions={bulkActions}
          rowSelection={{
            selectedRows,
            onSelectionChange: (newRows) => setSelectedRows(newRows),
          }}
          headerAction={{
            type: 'create',
            action: () =>
              history.push(createRedirectPath('/searchable-link/new')),
          }}
          onRowClick={handleRowClick}
          emptyText={t('No links found.')}
          createAction={() =>
            history.push(createRedirectPath(`/searchable-link/new`))
          }
          createActionText={t('Create Searchable Link')}
        >
          <Column
            title={t('External')}
            dataIndex="isExternal"
            render={(_, record) => (
              <Text>
                {record.isExternal ? (
                  <Icon symbol="check__regular" width="15px" height="15px" />
                ) : (
                  '-'
                )}
              </Text>
            )}
          />
          <Column
            title={t('Name (ID)')}
            className="mk-table-cell--bold"
            render={(_, record) => (
              <Text weight="bold" element="div">
                {record.title}{' '}
                <span className="bundle__list-id">({record.id})</span>
              </Text>
            )}
          />
          <Column title={t('URL')} dataIndex="url" />
          <Column title={t('Words')} dataIndex="keywords" />
          <Column width={50} render={() => <ArrowAction />} />
        </ResourceTable>
      </ContentWrapper>
    </>
  )
}
export default observer(SearchableLinksList)
