import isObject from 'lodash/isObject'

function buildCloudinaryUrl(data, assetUrl) {
  if (data.resourceType === 'video') {
    if (data.version) {
      return `${assetUrl}/${data.cloudName}/v${data.version}/${data.fileName}`
    }

    return `${assetUrl}/${data.cloudName}/video/upload/${data.fileName}`
  }
  if (data.version) {
    return `${assetUrl}/${data.cloudName}/v${data.version}/${data.fileName}`
  }

  return `${assetUrl}/${data.cloudName}/${data.fileName}`
}

export default function prepareImageUrl({ source, storageType, assetUrl }) {
  if (source.includes && source.includes('base64')) {
    const ext = source.split(';')[0].split('/').pop()
    return {
      url: source,
      thumbUrl: source,
      name: 'file.' + ext,
    }
  }

  if (storageType === 's3' && !isObject(source)) {
    return {
      url: `${assetUrl}/${source}`,
      thumbUrl: `${assetUrl}/${source}`,
      name: source,
    }
  }

  if (storageType === 'cloudinary' && isObject(source)) {
    try {
      const data = source
      const url = buildCloudinaryUrl(data, assetUrl)
      return {
        url: url,
        thumbUrl: `${assetUrl}/${data.cloudName}/h_100/${data.fileName}`,
        name: data.fileName,
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    error: true,
    url: '',
    thumbUrl: '',
    name: '',
  }
}
