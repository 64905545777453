import React, { Fragment } from 'react'
import Icon from '../../components/Icon'
import Text from '../../components/Text'

const Hierarchy = ({ text = '' }) => {
  const textToArr = text.split(' >> ')

  return (
    <div className="category-list__hierarchy">
      {textToArr.map((item, index) => (
        <Fragment key={index}>
          <Text weight="book" size="bravo">
            {item}
          </Text>{' '}
          {index !== textToArr.length - 1 && (
            <Icon width="10px" height="10px" symbol="angle-right__solid" />
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default Hierarchy
