import normalizeDateTimeString from '../normalizeDateTimeString'

export default function formatDate({
  dateString,
  options = {},
  locale = 'de-DE',
}) {
  const date = new Date(normalizeDateTimeString(dateString))

  return date.toLocaleString(locale, options)
}

function formatDateWithoutSeconds({ dateString = '', locale = [] }) {
  const date = new Date(normalizeDateTimeString(dateString))

  return date.toLocaleString(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

export { formatDateWithoutSeconds }
