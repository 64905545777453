import t from '../../utils/translate'

export const getResourceTableFilters = (Store) => {
  const filters = [
    {
      type: 'single-select',
      title: t('Active'),
      placeholder: t('Select'),
      name: 'active',
      onChange: Store.setFilter,
      defaultValue: Store.filter.active || '',
      options: [
        {
          label: t('Show all'),
          value: '',
        },
        {
          label: t('Active'),
          value: '1',
        },
        {
          label: t('Not active'),
          value: '0',
        },
      ],
    },
    {
      type: 'search',
      title: t('Search'),
      name: 'q',
      onChange: Store.setFilter,
      defaultValue: Store.filter.q,
    },
  ]

  return filters
}

export const getSymbolCreatedBy = (createdBy) => {
  const isMakaira = createdBy === 'Makaira'

  return isMakaira ? 'gear' : 'user'
}
