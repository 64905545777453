import { buildQueryString } from '../utils'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  getInstances() {
    return fetch('/instances', { headers: { 'X-Makaira-Instance': '' } })
  },

  requestIntance(body) {
    return fetch('/instance/request-change', {
      method: 'POST',
      body: JSON.stringify(body),
    })
  },

  getLanguages() {
    return fetch('/languages')
  },

  getShopId() {
    return fetch('/search/shopId')
  },

  getEnterpriseConfiguration() {
    return fetch('/enterprise/config')
  },

  getUserGroups() {
    return fetch('/usergroups')
  },

  getPersonas(options) {
    const queryString = buildQueryString(options)
    return fetch('/personas?' + queryString)
  },
  getPersona(id) {
    return fetch('/personas/' + id)
  },
  createPersona(data) {
    return fetch('/personas', {
      method: 'POST',
      body: JSON.stringify(data),
    })
  },
  updatePersona(data) {
    return fetch('/personas/' + data.id, {
      method: 'PUT',
      body: JSON.stringify(data),
    })
  },

  async deletePersonas(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))

    await fetch(`/personas?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },

  uploadFileToS3(file) {
    const data = new FormData()
    data.append('file', file)

    return fetch('/upload', {
      method: 'POST',
      body: data,
    })
  },

  getDocumentsByQuery({ type, query, language }) {
    const url = `/documents/search/${type}?q=${query}&language=${language}`

    return fetch(url)
  },

  async getDocumentsById({ type, ids, language }) {
    const url = '/documents/byId'

    const body = {
      datatype: type,
      ids,
      constraints: {
        'query.language': language,
      },
    }

    const { data } = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })

    return data
  },

  async getDocumentsByType(type) {
    const url = '/documents/list/' + type
    return fetch(url)
  },

  getDocumentTypes() {
    return fetch('/aggregation/fields?type=datatype')
  },

  async fetchSearchResultPreview({
    searchPhrase,
    language = 'de',
    shopId,
    datatypes,
    fields = ['es_id', 'title', 'url'],
    state = 'active',
    count = 100,
    showEverything = false,
  }) {
    const url = '/search/preview'

    let constraints = {
      'query.language': language,
      'query.shop_id': shopId,
    }

    if (datatypes) {
      constraints['query.datatypes'] = datatypes
      constraints['query.use_stock'] = true
    }

    if (showEverything) {
      constraints['query.everything'] = true
    }

    const body = {
      searchPhrase: searchPhrase,
      isSearch: true,
      enableAggregations: false,
      apiVersion: '2021.3',
      constraints,
      fields: fields,
      count,
      state,
    }

    const { data } = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })

    delete data.suggestion // we never want to display suggestions
    delete data.banners // we never want to display banners

    return data
  },

  async fetchDocumentPreview({ type = 'product', esId, state, language }) {
    const url = `/search/preview/${esId}`
    const body = {
      type,
      state,
      language,
    }

    const { data } = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })

    return data
  },

  async getFeatures() {
    return fetch('/features')
  },

  async getFeaturesInfo() {
    return fetch('/feature')
  },

  async bookFeature(feature) {
    return fetch(`/feature/${feature}/book`, {
      method: 'POST',
    })
  },

  async checkURLExists({ id, type, url }) {
    const body = {
      id,
      type,
      url,
    }

    const { data } = await fetch('/enterprise/check-url', {
      method: 'POST',
      body: JSON.stringify(body),
    })

    return data
  },

  async getAvailableLanguages() {
    return fetch('/languages_available')
  },

  async getMachineLearningStatus() {
    return fetch('/machine-learning/check-data-satisfaction')
  },

  async fetchSearchPreviewResult({
    searchPhrase,
    language = 'de',
    shopId,
    type = 'product', // product or category
    profile = {},
    categoryId,
    withML = false,
    count = 20,
    page = 1,
    rankingMix,
    weather,
    useStock = false,
    group,
  }) {
    const url = '/search/preview'

    let constraints = {
      'query.language': language,
      'query.shop_id': shopId,
      'query.use_stock': useStock,
      'oi.user.timezone': '+0200',
      'query.machine_learning': withML,
      'query.group': group,
      ...profile,
    }

    const fields = ['id', 'es_id', 'ean', 'title', 'picture_url_main', '_score']

    if (categoryId) {
      constraints['query.category_id'] = [categoryId]
    }

    if (rankingMix) {
      constraints['ranking_mix_overriding_config'] = rankingMix
    }

    if (weather) {
      constraints.weather_groups = [weather]
    }

    const body = {
      searchPhrase: searchPhrase,
      isSearch: type === 'product',
      enableAggregations: false,
      count,
      offset: page * count,
      constraints,
      fields,
      useWeather: weather !== '',
    }

    const { data } = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })

    return data
  },

  async getStatistics() {
    return fetch('/statistics', { stage: true })
  },

  async createLock(type, targetId, isTakeOver = false) {
    return fetch('/entity-lock', {
      method: 'POST',
      body: JSON.stringify({ type, targetId, override: isTakeOver }),
    })
  },

  async getLock(type, targetId) {
    return fetch(`/entity-lock/${type}/${targetId}`)
  },

  async putHeartbeatLock(type, targetId) {
    return fetch(`/entity-lock/${type}/${targetId}/heartbeat`, {
      method: 'PUT',
    })
  },

  async removeLock(type, targetId) {
    return fetch(`/entity-lock/${type}/${targetId}`, {
      method: 'DELETE',
    })
  },

  async permissionsPreview(roles) {
    return fetch('/permissions/preview', {
      method: 'POST',
      body: JSON.stringify(roles),
    })
  },

  async routeStatistics(options) {
    const queryString = buildQueryString(options)
    return fetch(`/statistics/routes?${queryString}`)
  },

  async fetchProductGroups(language) {
    return fetch(`/${language}/product-groups`)
  },
}
