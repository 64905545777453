import Icon from '../Icon'
import Button from '../Button'
import InputEditor from '../InputEditor'

const Item = (props) => {
  const {
    handleRemoveItem = () => {},
    handleMoveUp = () => {},
    handleMoveDown = () => {},
    readOnly = false,
    config = {},
  } = props
  const { type, properties } = config

  const additionProps = {}

  additionProps.showTranslations = false
  additionProps.onChange = (e, setValue) =>
    e?.target?.value !== undefined && setValue(e?.target?.value)

  if (type === 'file') {
    additionProps.size = 'small'
    additionProps.onRemove = (setValue) => setValue(null)
    additionProps.onChange = (value, setValue) => setValue(value)
  }

  if (type === 'object') {
    additionProps.size = 'small'
  }

  return (
    <div className="repeatable-input__item">
      {!readOnly && (
        <div className="repeatable-input__sort-actions">
          <Icon symbol="arrow-up" onClick={handleMoveUp} />
          <Icon symbol="arrow-down" onClick={handleMoveDown} />
        </div>
      )}
      <InputEditor
        type={type}
        properties={properties}
        id={props.id}
        {...additionProps}
        isChildComponent
        setConditionalValues={props.setConditionalValues}
        conditionalValues={props.conditionalValues}
      />
      {!readOnly && (
        <Button
          onClick={handleRemoveItem}
          className="repeatable-input__remove-btn"
          variant="tertiary"
          icon="trash"
          level={1}
        />
      )}
    </div>
  )
}

export default Item
