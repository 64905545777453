import classnames from 'classnames'
import Icon from '../Icon'

import './spinner.styl'

function Spinner({ size = 'normal', icon, className, ...rest }) {
  return (
    <Icon
      symbol={icon || 'spinner'}
      className={classnames('spinner', `spinner--${size}`, className)}
      {...rest}
    />
  )
}

export default Spinner
