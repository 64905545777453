export const CHART_COLORS = [
  '#E54139',
  '#F5BF33',
  '#63B6C8',
  '#61304D',
  '#537074',
]
export const SEARCH_TERMS_TYPE_LABLES = {
  antonym: 'Antonym',
  synonym: 'Synonym',
  search_redirect: 'Redirect',
}
export const SEARCH_TERMS_TYPE_ROUTE = {
  antonym: '/antonyms/',
  synonym: '/synonyms/',
  search_redirect: '/search-redirects/',
}
