import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { flatten, uniq } from 'lodash'

import t from '../../../utils/translate'
import { baseFeatures } from '../../Role/constant'
import { AppStore, UIStore, UserManagementStore } from '../../../stores'
import Select from '../../../components/Select'
import Table, { Column } from '../../../components/Table'
import EmptyTable from '../../../components/Table/EmptyTable'
import ActiveField from '../../../components/ActiveField'
import Text from '../../../components/Text'
import HeadingMain from '../../../components/Headings/HeadingMain'
import api from '../../../api'

const PermissionsTable = () => {
  const { externalApps } = AppStore
  const { instancesTableResource } = UserManagementStore
  const { currentLanguage } = UIStore
  const [selectedInstance, setSelectedInstance] = useState('*')
  const [previewPermissions, setPreviewPermissions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const instances = instancesTableResource.map((i) => {
    return {
      label: i.instance === '*' ? t('All instances') : i.instance,
      value: i.instance,
    }
  })

  const selectedRoleIds = useMemo(
    () =>
      uniq(
        flatten(
          instancesTableResource
            .filter(
              (i) => i.instance === selectedInstance || i.instance === '*'
            )
            .map((i) => i.roles || [])
        )
      ),
    [instancesTableResource, selectedInstance]
  )

  async function fetchPreviewPermission(roleIds) {
    try {
      setLoading(true)

      if (roleIds.length === 0) {
        setPreviewPermissions([])
        setLoading(false)
        return
      }

      const { data } = await api.permission.preview(roleIds)
      setPreviewPermissions(data)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchPreviewPermission(selectedRoleIds)
  }, [selectedRoleIds])

  const isPermissionExist = useCallback(
    (permission = '') => {
      return previewPermissions.some((p) => p === permission)
    },
    [previewPermissions]
  )

  const featuresPermissionBySection = useMemo(() => {
    const featuresAndApps = [
      ...baseFeatures,
      ...(externalApps || []).map((app) => {
        return {
          name: app.title[currentLanguage] || app.title.de,
          key: app.slug,
          section: 'Apps',
        }
      }),
    ]

    const allItems = featuresAndApps.map((feature) => {
      const permissionRead = feature.key + '.read'
      const permissionWrite = feature.key + '.write'
      return {
        name: feature.name,
        key: feature.key,
        section: feature.section,
        read: isPermissionExist(permissionRead),
        write: isPermissionExist(permissionWrite),
      }
    })

    const itemsGroupBySection = allItems.reduce((data, item) => {
      if (!item.read && !item.write) {
        return data
      }
      const section = item.section
      const found = data.find((d) => d.name === section)
      if (found) {
        const existingItems = found.items || []
        found.items = [...existingItems, item]
        return data
      }

      return [...data, { name: section, items: [item] }]
    }, [])

    return itemsGroupBySection
  }, [externalApps, currentLanguage, isPermissionExist])

  const sections = useMemo(() => {
    return featuresPermissionBySection.map((section) => {
      return (
        <div key={`${section.name}-loading-${isLoading}`}>
          <Table
            data={section.items}
            loading={isLoading}
            key={`table-${section.name}-loading-${isLoading}`}
          >
            <Column
              title={<Text size="delta">{section.name}</Text>}
              dataIndex="name"
            />
            <Column
              width={100}
              align="center"
              title={t('Read')}
              dataIndex="read"
              render={(value) => <ActiveField active={value} />}
            />
            <Column
              width={100}
              align="center"
              title={t('Write')}
              dataIndex="write"
              render={(value) => <ActiveField active={value} />}
            />
          </Table>
        </div>
      )
    })
    // eslint-disable-next-line
  }, [featuresPermissionBySection, isLoading])

  return (
    <div className="permissions-table__wrapper">
      <div className="permissions-table__header">
        <HeadingMain>{t('Show permissions for')}</HeadingMain>
        <Select
          options={instances}
          defaultValue={selectedInstance}
          onChange={setSelectedInstance}
        />
      </div>
      {featuresPermissionBySection?.length > 0 ? (
        sections
      ) : (
        <EmptyTable emptyText={t('No permission available.')} />
      )}
    </div>
  )
}

export default observer(PermissionsTable)
