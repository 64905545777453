function formatNode(node, expandedKeys = []) {
  const {
    uuid,
    text,
    link,
    isRedirectOnly = false,
    css = '',
    esId = '',
    children = [],
  } = node

  let formattedChildren = children
  if (children.length) {
    formattedChildren = children.map((child) => formatNode(child, expandedKeys))
  }

  const mappedNode = {
    uuid,
    key: uuid,
    text,
    link,
    isRedirectOnly,
    css,
    esId,
    children: formattedChildren,
  }

  if (expandedKeys.includes(uuid)) {
    mappedNode.expanded = true
  }

  return mappedNode
}

export default function prepareMenu(menu, expandedKeys = []) {
  const prepared = menu.map((node) => formatNode(node, expandedKeys))

  return prepared
}
