import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { get } from 'lodash'

import Icon from '../../../components/Icon'
import Text from '../../../components/Text'

import t from '../../../utils/translate'
import { formatDateWithoutSeconds } from '../../../utils/formatDate'

import EditorStore from '../../../stores/PageEditor/EditorStore'
import { UIStore } from '../../../stores'

function Setting() {
  const {
    showSettings,
    selectedElementTimed,
    getFieldTimeStatus,
    elementToEdit,
    contentLanguage,
  } = EditorStore
  const { showClock, showUser } = getFieldTimeStatus(elementToEdit)

  const getText = () => {
    if (selectedElementTimed.length === 0)
      return t('time-based or persona based visibility can be changed')

    return selectedElementTimed.map((group) => {
      if (!group.isTimed) {
        return (
          <p key={group.identifier}>
            {t('Visible for')}{' '}
            {group.name?.[UIStore.currentLanguage] || group.name}
          </p>
        )
      }

      const formattedFrom = formatDateWithoutSeconds({
        dateString: group.from,
      })

      const formattedTo = formatDateWithoutSeconds({
        dateString: group.to,
      })

      return (
        <p key={group.identifier}>
          {`${t('Scheduled')} ${
            group?.name[UIStore.currentLanguage] || group?.name
          }: ${formattedFrom} ${t('to')} ${formattedTo}`}
        </p>
      )
    })
  }

  const content = get(elementToEdit, ['properties', contentLanguage, 'content'])
  const hasNoFields = !Object.keys(content).length

  return (
    <div
      className={classnames('editor-modal__setting', {
        'editor-modal__setting--no-fields': hasNoFields,
      })}
    >
      <Icon
        className="editor-modal__play-out-icon"
        symbol={showClock ? 'clock__solid' : 'clock'}
        width={16}
        height={16}
      />
      <Icon
        className="editor-modal__play-out-icon"
        symbol={showUser ? 'user__solid' : 'user'}
        width={16}
        height={16}
      />

      <Text
        className="editor-modal__setting--cta"
        variant="book"
        element="p"
        onClick={showSettings}
      >
        {getText()}
      </Text>
    </div>
  )
}

export default observer(Setting)
