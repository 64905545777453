import classNames from 'classnames'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import Icon from '../../../components/Icon'
import NumberInput from '../../../components/NumberInput'
import SliderInput from '../../../components/SliderInput'
import Text from '../../../components/Text'
import ABTestStore from '../../../stores/ABTestStore'
import RankingMixStore from '../../../stores/RankingMixStore'
import { t } from '../../../utils'

const MAX_RANGE_CONFIG = 10

const REASON_EXPLAIN = {
  'Low amount of data': 'ReasonExplain_Not enough data for ranking mix.',
  'No ranking mix data.':
    'Since all boosting data stored on the products are identical, the ranking mix will have no influence.',
  'Invalid boosting data':
    'The boosting values for the Ranking Mix are outside the valid value range (0-1)',
}

const RankingMixField = ({ config, title, value, type, disabled }) => {
  const fieldValidation = RankingMixStore.fieldsValidation[title] || {}
  const reasons = fieldValidation?.reasons || []
  const firstReason = reasons[0] || ''
  const invalidField = fieldValidation.result === false

  let marks = {}
  if (ABTestStore.showMetaconfigView && ABTestStore.showHighlightVariants) {
    let allVariants = ['A', 'B', 'C']
    if (ABTestStore.editingExperiment.segments === 2) {
      allVariants.pop()
    }
    allVariants.forEach((variant) => {
      if (ABTestStore.selectedVariant === variant) return
      const variantData = ABTestStore.rankingMixVariantsConfig.find(
        (item) => item.variation_name === variant
      )
      const dataByType = get(variantData, [
        'settings',
        type === 'search' ? 0 : 1,
      ])
      const variantValue = get(dataByType, [title])
      if (marks[variantValue]) {
        marks[variantValue] = (
          <div className="ranking-mix__variants-wrapper">
            {allVariants
              .filter((v) => v !== ABTestStore.selectedVariant)
              .map((v) => (
                <span
                  key={v}
                  className={`ab-frontend__variant ab-frontend__variant--${v}`}
                >
                  {v}
                </span>
              ))}
          </div>
        )
      } else {
        marks[variantValue] = (
          <div className="ranking-mix__variants-wrapper">
            <span
              className={`ab-frontend__variant ab-frontend__variant--${variant}`}
            >
              {variant}
            </span>
          </div>
        )
      }
    })
  }

  return (
    <div
      className={classNames('ranking-mix__config-slider-wrapper', {
        'ranking-mix__config-slider-wrapper--invalid': invalidField,
      })}
    >
      <div className="ranking-mix__invalid-field-wrapper">
        <div className="ranking-mix__invalid-icon">
          <Icon width={15} height={15} symbol="triangle-exclamation__solid" />
        </div>
      </div>
      <div className="ranking-mix__config-slider-inner">
        {invalidField && (
          <div className="ranking-mix__invalid-text-wrapper">
            <Text size="bravo" weight="bold">
              {t(firstReason)}
            </Text>
            <Text size="bravo">{t(REASON_EXPLAIN[firstReason])}</Text>
          </div>
        )}

        <Text>{t('rankingMix_' + title)}</Text>
        <div className="ranking-mix__config-slider">
          <NumberInput
            disabled={disabled}
            max={MAX_RANGE_CONFIG}
            min={0}
            value={value}
            onChange={(newValue) =>
              RankingMixStore.updateConfigData(config, title, newValue)
            }
          />
          <SliderInput
            marks={marks}
            disabled={disabled}
            max={MAX_RANGE_CONFIG}
            min={0}
            value={value}
            onChange={(newValue) =>
              RankingMixStore.updateConfigData(config, title, newValue)
            }
          />
        </div>
      </div>
    </div>
  )
}

export default observer(RankingMixField)
