import { useState } from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import * as Yup from 'yup'

import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { t } from '../../utils'
import Select from '../../components/Select'
import TextInput from '../../components/TextInput'
import Text from '../../components/Text'
import { UIStore } from '../../stores'

const deleteInstanceSchema = Yup.object().shape({
  instanceName: Yup.string().required(t('Please select an instance!')),
})

const addInstanceSchema = Yup.object().shape({
  instanceName: Yup.string().required(t('Required!')),
  frontEndUrl: Yup.string().required(t('Required!')),
  previewUrl: Yup.string().required(t('Required!')),
})

function RequestModal(props) {
  const {
    requestAction = 'add',
    className,
    visible,
    onClose,
    maskClosable = true,
    customDialogClassName,
    confirmTitle,
    onSave,
    cancelTitle,
    hideCancelBtn,
  } = props

  const { instances } = UIStore

  const [requestBody, setRequestBody] = useState({})
  const [isSubmited, setSubmited] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)

  const onChangeRequestBodyField = (e) => {
    const updating = {
      ...requestBody,
      [e.target.name]: e.target.value,
    }
    setRequestBody(updating)
    if (isSubmited) {
      validate(updating)
    }
  }

  const validate = (data) => {
    try {
      if (requestAction === 'delete') {
        deleteInstanceSchema.validateSync(data, { abortEarly: false })
      } else if (requestAction === 'new') {
        addInstanceSchema.validateSync(data, { abortEarly: false })
      }
      setErrors(null)
      return true
    } catch (error) {
      const errors = error.inner.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.path]: curr.message,
        }
      }, {})
      setErrors(errors)
    }
  }

  const onSubmitRequest = () => {
    setSubmited(true)
    setLoading(true)
    if (validate(requestBody) && onSave) {
      onSave(requestAction, requestBody).then(() => {
        setLoading(false)
        setRequestBody({})
        onClose()
      })
    }
  }

  const Footer = () => (
    <>
      {!hideCancelBtn && (
        <Button
          type="button"
          key="close"
          iconPosition="left"
          icon="xmark"
          onClick={onClose}
        >
          {cancelTitle || t('Cancel')}
        </Button>
      )}
      <div />
      <Button
        type="submit"
        variant="primary"
        icon="check"
        key="save"
        onClick={onSubmitRequest}
        disabled={!!errors}
        loading={loading}
      >
        {confirmTitle || t('Confirm')}
      </Button>
    </>
  )

  return (
    <Modal
      className={cx(className, 'general-settings__request-instance-modal')}
      visible={visible}
      onClose={onClose}
      header={t('Instances')}
      mask
      align="left"
      maskClosable={maskClosable}
      customDialogClassName={customDialogClassName}
      footer={<Footer />}
    >
      <div className="content">
        <Text>
          {t(
            'For now your instances list can only be updated by the support team.'
          )}
        </Text>
        <Text size="delta" weight="bold">
          {t(
            requestAction === 'new'
              ? 'I want to add an instance'
              : 'I want to delete an instance'
          )}
        </Text>
        {requestAction === 'delete' && (
          <div className="action-content">
            <Select
              name="instanceName"
              title={t('Instance name')}
              options={instances.map((instance) => ({
                value: instance,
                label: instance,
              }))}
              value={requestBody['instanceName'] || ''}
              disabled={requestAction !== 'delete'}
              onChange={(value) => {
                console.log('SELECT CHANGE', value)
                onChangeRequestBodyField({
                  target: {
                    value,
                    name: 'instanceName',
                  },
                })
              }}
              error={errors?.instance}
            />
          </div>
        )}
        {requestAction === 'new' && (
          <div className="action-content">
            <TextInput
              name="instanceName"
              label={t('Instance name')}
              value={requestBody['instanceName'] || ''}
              disabled={requestAction !== 'new'}
              onChange={onChangeRequestBodyField}
              error={errors?.instanceName}
            />
            <TextInput
              name="frontEndUrl"
              label={t('Front-End URL')}
              value={requestBody['frontEndUrl'] || ''}
              disabled={requestAction !== 'new'}
              onChange={onChangeRequestBodyField}
              error={errors?.frontEndUrl}
            />
            <TextInput
              name="previewUrl"
              label={t('Preview URL')}
              value={requestBody['previewUrl'] || ''}
              disabled={requestAction !== 'new'}
              onChange={onChangeRequestBodyField}
              error={errors?.previewUrl}
            />
          </div>
        )}
        <Text>
          {requestAction === 'delete' &&
            t('We will contact you to verify that you submitted this request.')}
          {requestAction === 'new' &&
            t(
              'We will contact you to verify that you submitted this request and inform you about the prices.'
            )}
        </Text>
      </div>
    </Modal>
  )
}

export default observer(RequestModal)
