import classnames from 'classnames'

import Text from '../Text'

import './headings.styl'

const HeadingMain = ({ children, className = '' }) => {
  return (
    <div className={classnames('heading', className)}>
      <Text size="echo" weight="bold">
        {children}
      </Text>
    </div>
  )
}

export default HeadingMain
