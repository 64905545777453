import { observer } from 'mobx-react-lite'

import ChangedField from '../../components/ChangedField'
import Button from '../../components/Button'
import ConfirmModal from '../../components/Modal/ConfirmModal'
import Table, { Column } from '../../components/Table'
import SmartBundleStore from '../../stores/SmartBundleStore'
import { t } from '../../utils'

const Revision = () => {
  const handleRestoreRevision = (revision) => {
    SmartBundleStore.restoreRevision(revision)
  }

  const handleCloseConfirmModal = () => {
    SmartBundleStore.closeConfirmRestoreModal()
  }

  return (
    <>
      <Table
        rowKey="id"
        emptyContainerSize="small"
        emptyText={t(
          'Here you will find older versions of your bundle.\nEach save creates a new revision. We keep 20 revisions at max.'
        )}
        data={SmartBundleStore.revisions}
        scroll={SmartBundleStore.revisions.length > 6 && { y: 425 }}
      >
        <Column title={t('Title')} dataIndex="internal_title" />
        <Column title={t('User')} dataIndex="user" />
        <Column
          title={t('Last changed')}
          dataIndex="changed"
          render={(_, record) => <ChangedField {...record} />}
        />
        <Column
          render={(_, record) => (
            <Button
              onClick={() => handleRestoreRevision(record)}
              type="button"
              level={1}
            >
              {t('Restore')}
            </Button>
          )}
        />
      </Table>

      <ConfirmModal
        visible={SmartBundleStore.isConfirmRestoreModalVisible}
        onClose={handleCloseConfirmModal}
        onSubmit={() => handleRestoreRevision()}
      />
    </>
  )
}

export default observer(Revision)
