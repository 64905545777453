import { observer } from 'mobx-react-lite'
import moment from 'moment'
import classNames from 'classnames'
import { useHistory } from 'react-router'

import t from '../../utils/translate'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import Button from '../../components/Button'
import DashboardStore from '../../stores/DashboardStore'
import { createRedirectPath } from '../../utils'
import { AuthStore, UIStore } from '../../stores'

export const handleOpenApp = (app, history) => {
  if (app.v1) {
    const url =
      (process.env.NODE_ENV === 'development'
        ? 'http://localhost:2000'
        : window.location.origin) +
      '/#/' +
      app.route
    window.open(url, '_self')
  } else {
    history.push(createRedirectPath(app.route.path))
  }
}

const AppItem = ({ app }) => {
  const history = useHistory()

  const appFeature = app.v1 ? app.requireFeature : app.route.requireFeature
  const featureInfo = UIStore.getFeatureInfo(appFeature)

  const getBadgeText = () => {
    // Fallback if feature flag is missing completely
    if (appFeature && !featureInfo) return t('Book app')

    if (!featureInfo) return

    if (featureInfo) {
      if (featureInfo.state === 'paid') return
      if (featureInfo.state === 'trial') {
        const date = featureInfo.expires || {}
        let momentDate = moment.utc(date.date).local()
        if (date.timezone !== 'UTC') {
          momentDate = moment(date.date).local()
        }

        const daysLeft = momentDate.diff(moment(), 'days')
        let daysLeftToString = ''

        if (daysLeft > 0) {
          daysLeftToString = t('day_left')(daysLeft)
        } else if (daysLeft === 0) {
          daysLeftToString = t('last day')
        } else {
          daysLeftToString = t('expired')
        }

        return `Trial | ${daysLeftToString}`
      }
      if (featureInfo.state === 'canceled') return t('Book app')
      if (featureInfo.state === 'not-booked') return t('Book free trial')
    }
  }

  const badgeText = getBadgeText()

  const handleClickApp = () => {
    if (
      (!featureInfo && !appFeature) ||
      featureInfo?.active ||
      appFeature === 'machine_learning' ||
      AuthStore.isSuperAdmin
    ) {
      handleOpenApp(app, history)
    } else {
      DashboardStore.showAppDetailModal(app.name)
    }
  }

  return (
    <div
      title={app.external ? app.name : t(app.name)}
      className="dashboard__app"
      onClick={handleClickApp}
    >
      <div className="dashboard__icon-wrapper">
        {app.external && (app.icon?.type === 'font-awesome' || !app.icon) && (
          <Icon symbol={app.icon?.content || 'rocket'} />
        )}
        {app.external && app.icon?.type === 'image' && app.icon.content && (
          <img
            alt=""
            src={app.icon.content.replace(
              'image/upload',
              'image/upload/e_colorize,co_rgb:61304d,w_150'
            )}
          />
        )}
        {!app.external && <Icon symbol={app.icon} />}
      </div>
      <div className="dashboard__infor-wrapper">
        <Text className="dashboard__app-title" size="charlie" weight="bold">
          {app.external ? app.name : t(app.name)}
        </Text>
        {badgeText && (
          <Text weight="bold" className="dashboard__app-badge">
            {badgeText}
          </Text>
        )}
      </div>
      {!app.external && app.doc && app.description && (
        <div
          onClick={(e) => e.stopPropagation()}
          className={classNames('dashboard__app-actions')}
        >
          <Button
            onClick={() => DashboardStore.showAppDetailModal(app.name)}
            tooltip={t('Show app information')}
            icon="ellipsis-vertical"
            variant="secondary"
          />
        </div>
      )}
    </div>
  )
}

export default observer(AppItem)
