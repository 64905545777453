import { useEffect, useState, useRef, useMemo } from 'react'
import JoditEditor from 'jodit-react'
import { UIStore } from '../../stores'

import './richTextInput.styl'

export default function RichTextInput(props) {
  const { description, label, onChange } = props
  const touched = useRef(false)
  const [value, setValue] = useState(props.value)

  const editorConfig = useMemo(
    () => ({
      language: UIStore.currentLanguage,
      placeholder: '',
      tabIndex: null,
      readonly: props.disabled,
      minHeight: 140,
      toolbarAdaptive: false,
      colorPickerDefaultTab: 'color',
      toolbarSticky: true,
      buttons:
        'bold,strikethrough,underline,italic,|,ul,ol,brush,paragraph,,image,video,table,link,align,source,fullsize,superscript',
    }),
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const handleChange = (val) => {
    if (!touched.current) return
    setValue(val)
    onChange(val)
  }

  return (
    <div
      onFocus={() => (touched.current = true)}
      onClick={() => (touched.current = true)}
      className="rich-text"
    >
      {label && <div className="rich-text__label">{label}</div>}
      <JoditEditor
        onChange={handleChange}
        config={editorConfig}
        value={value}
      />
      {description && <div className="rich-text__message">{description}</div>}
    </div>
  )
}
