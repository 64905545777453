import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import get from 'lodash/get'
import LanguageContent from './LanguageContent'
import { MenuStore } from '../../stores'
import Button from '../../components/Button'
import EditNode from './EditNode'
import { t } from '../../utils'

function MenuEditorNode(props) {
  const { node, updateNode, addChild, removeNode } = props
  const { uuid } = node

  const setOpenEdit = (isOpen) => {
    let updated = { ...toJS(node) }
    updated.isEditing = isOpen
    updateNode(uuid, updated, false)
  }

  return (
    <>
      <div className="menu-editor-node">
        <div className="menu-editor-node__languages">
          {node.isEditing && (
            <EditNode
              key={node.isEditing}
              updateNode={updateNode}
              setOpenEdit={setOpenEdit}
              node={node}
            />
          )}
          {!node.isEditing && (
            <LanguageContent
              linked={!!node.esId}
              text={get(node, ['text', MenuStore.contentLanguage])}
              link={get(node, ['link', MenuStore.contentLanguage])}
              language={MenuStore.contentLanguage}
              setOpenEdit={setOpenEdit}
              updateNode={updateNode}
              node={node}
            />
          )}
        </div>

        <div className="menu-editor-node__actions">
          <Button
            level={1}
            variant="reduced"
            icon="pencil"
            onClick={() => setOpenEdit(true)}
            tooltip={t('Edit')}
          />
          <Button
            onClick={() => MenuStore.openSettingModal(node)}
            level={1}
            variant="reduced"
            icon="gear"
            tooltip={t('Settings')}
          />
          <Button
            level={1}
            variant="reduced"
            icon="trash"
            onClick={() => removeNode(uuid)}
            tooltip={t('Delete')}
          />
          <Button
            level={1}
            variant="reduced"
            icon="plus"
            onClick={() => addChild(uuid)}
            tooltip={t('Add child')}
          />
        </div>
      </div>
    </>
  )
}

export default observer(MenuEditorNode)
