// keep in sync with src/Makaira/SearchBundle/Service/BannerService.php
export const types = [
  { id: 'search', name: 'Search results' },
  { id: 'category', name: 'Category list' },
  { id: 'manufacturer', name: 'Manufacturer list' },
]

export const searchTypes = [
  { id: 'search', name: 'search result only' },
  { id: 'autosuggest', name: 'search suggestions only' },
  { id: 'both', name: 'search results and suggestions' },
]
