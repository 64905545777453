import { Popover } from 'antd'
import classNames from 'classnames'
import { isFunction } from 'lodash'
import { useState } from 'react'
import Button from '../Button'
import Icon from '../Icon'
import Menu from '../Menu'
import Text from '../Text'

import './style.styl'

export default function DropdownButton(props) {
  const {
    action,
    actionIcon = 'plus',
    onSelect,
    text,
    options,
    menu,
    buttonLevel,
    loading,
    destroyTooltipOnHide,
    variant = 'tertiary',
    disabled,
    className,
    iconOnly = false,
  } = props

  const { MenuItem } = Menu

  const [open, setOpen] = useState(false)

  const hide = () => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen)
  }

  const toggleOpen = () => setOpen(true)

  const hasContextMenu = menu || options?.length > 0

  const renderMenu = isFunction(menu) ? menu(hide) : menu

  const content = renderMenu || (
    <Menu
      onSelect={(value) => {
        onSelect(value)
        hide()
      }}
      className="dropdown-button__menu"
    >
      {options.map((option) => (
        <MenuItem key={option.value}>
          <Text weight="bold">{option.label}</Text>
        </MenuItem>
      ))}
    </Menu>
  )

  return (
    <Button
      level={buttonLevel}
      variant={variant}
      className={classNames('dropdown-button', className, {
        'dropdown-button--no-context-menu': !hasContextMenu,
        'button--icon button--icon-only': iconOnly,
      })}
      loading={loading}
      disabled={disabled}
    >
      {!iconOnly && (
        <span onClick={action || toggleOpen} className="dropdown-button__main">
          {!loading && <Icon symbol={actionIcon} width={16} height={16} />}

          {text}
          {hasContextMenu && <span className="dropdown-button__divine" />}
        </span>
      )}

      {hasContextMenu && !iconOnly && (
        <Popover
          onVisibleChange={handleOpenChange}
          trigger="click"
          content={!disabled && content}
          placement="bottomLeft"
          overlayClassName="dropdown-button__popover"
          destroyTooltipOnHide={destroyTooltipOnHide}
          visible={open}
        >
          <span className="dropdown-button__ellipsis-icon">
            <Icon symbol="ellipsis" width={16} height={16} />
          </span>
        </Popover>
      )}

      {hasContextMenu && iconOnly && (
        <Popover
          onVisibleChange={handleOpenChange}
          trigger="click"
          content={content}
          placement="bottomLeft"
          overlayClassName="dropdown-button__popover"
          destroyTooltipOnHide={destroyTooltipOnHide}
          visible={open}
        >
          <span className="dropdown-button__icon-wrapper">
            <Icon
              onClick={action || toggleOpen}
              symbol={actionIcon}
              width={16}
              height={16}
            />
          </span>
        </Popover>
      )}
    </Button>
  )
}
