import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ActiveField from '../../components/ActiveField'
import ResourceTable from '../../components/ResourceTable'
import { ArrowAction, Column } from '../../components/Table'
import Text from '../../components/Text'
import {
  ActionLayerStore,
  FieldStore,
  FilterStore,
  UIStore,
} from '../../stores'
import { createRedirectPath, t } from '../../utils'
import SearchPageTitle from '../SearchConfigurationV3/ImporterPageTitle'
import FieldTitle from './FieldTitle'

const FilterV3 = () => {
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState([])
  const { setFilter } = FilterStore

  useEffect(() => {
    FilterStore.fetchFilters()
    FieldStore.fetchFields()
  }, [])

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    await FilterStore.delete(selectedRows)

    if (FilterStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const bulkActions = [
    {
      title: t('Delete'),
      onExecute: () => showDeleteWarning(),
    },
  ]

  return (
    <>
      <SearchPageTitle />
      <div className="content-wrapper">
        <ResourceTable
          title={t('Filters')}
          store={FilterStore}
          resourceName="filters"
          filterParams="filter"
          filters={[
            {
              type: 'single-select',
              title: t('Active'),
              placeholder: t('Select'),
              name: 'active',
              onChange: FilterStore.setFilter,
              defaultValue: FilterStore.filter.active,
              options: [
                {
                  label: t('Show all'),
                  value: undefined,
                },
                {
                  label: t('Active'),
                  value: '1',
                },
                {
                  label: t('Not active'),
                  value: '0',
                },
              ],
            },
            {
              type: 'search',
              title: t('Search'),
              name: 'q',
              onChange: setFilter,
            },
          ]}
          headerAction={{
            type: 'create',
            action: () => history.push(createRedirectPath('/filter/new')),
          }}
          loading={FilterStore.state === 'pending'}
          bulkActions={bulkActions}
          rowSelection={{
            selectedRows,
            onSelectionChange: (newRows) => setSelectedRows(newRows),
          }}
          onRowClick={(record) =>
            history.push(createRedirectPath(`/filter/${record.id}`))
          }
          emptyText={t('No filter found.')}
          createAction={() => history.push(createRedirectPath('/filter/new'))}
          createActionText={t('Create Filter')}
        >
          <Column
            width={70}
            title={t('Position')}
            dataIndex="position"
            align="center"
          />
          <Column
            width={70}
            title={t('Active')}
            dataIndex="active"
            align="center"
            render={(value) => <ActiveField active={value} />}
          />
          <Column
            title={t('Title (ID)')}
            className="mk-table-cell--bold"
            render={(_, record) => (
              <Text weight="bold" element="div">
                {get(record, [
                  'title',
                  UIStore.enterpriseConfiguration.defaultLanguage,
                ])}{' '}
                <span className="bundle__list-id">({record.id})</span>
              </Text>
            )}
          />
          <Column width={100} title={t('Frontend-ID')} dataIndex="key" />
          <Column
            width={600}
            title={t('Field')}
            dataIndex="field"
            render={(value) => <FieldTitle id={value} />}
          />
          <Column width={50} render={() => <ArrowAction />} />
        </ResourceTable>
      </div>
    </>
  )
}

export default observer(FilterV3)
