import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import DropdownButton from '../../../components/DropdownButton'
import Switch from '../../../components/Switch'
import Text from '../../../components/Text'
import { FeedStore, UIStore } from '../../../stores'
import { getInstance, t } from '../../../utils'
import { Tooltip } from 'antd'
import SearchPreview from '../../../components/SearchPreview'
import Icon from '../../../components/Icon'
import { toast } from 'react-toastify'

const FeedPreview = () => {
  const { id } = useParams()
  const feedPreview = FeedStore.feedPreview
  const feed = FeedStore.feedDetail
  const feedOriginal = FeedStore.feedDetailOriginal

  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(data).then(() => {
      toast.success(t('Copied') + '!')
    })
  }

  return (
    <div className="feeds__preview">
      <Text weight="bold" size="echo">
        {t('Preview')}
      </Text>
      <div className="feeds__search-product">
        <SearchPreview
          searchLabel={t('Which product would you like to preview?')}
          limit={3}
          extraDescription={t(
            'we only show the first 3 products to your search for a better overview.'
          )}
          type="product"
          fields={['id', 'es_id', 'ean', 'title', 'picture_url_main']}
          showEAN
          showImage
          onDataFetched={(data) => {
            FeedStore.setSampleProductEsIds(
              data.map((item) => item.fields.es_id)
            )
            FeedStore.fetchFeedPreview()
          }}
        />
      </div>
      {feedPreview && (
        <>
          <Switch
            title={t('Show header and footer code')}
            type="horizontal"
            checked={FeedStore.showHeaderAndFooter}
            onChange={(checked) => FeedStore.setShowHeaderAndFooter(checked)}
          />
          <div className="feeds__data-preview">
            {FeedStore.showHeaderAndFooter && (
              <pre className="feeds__data-preview-header">
                {FeedStore.fieldErrors.header && (
                  <Tooltip
                    title={FeedStore.fieldErrors.header.message}
                    placement="left"
                  >
                    <Icon
                      className="feeds__error"
                      symbol="triangle-exclamation"
                      width={20}
                      height={20}
                    />
                  </Tooltip>
                )}

                {feedPreview.header}
              </pre>
            )}
            <pre className="feeds__data-preview-body">
              {FeedStore.fieldErrors.body && (
                <Tooltip
                  title={FeedStore.fieldErrors.body.message}
                  placement="left"
                  overlayStyle={{ maxWidth: '500px' }}
                >
                  <Icon
                    className="feeds__error"
                    symbol="triangle-exclamation"
                    width={20}
                    height={20}
                  />
                </Tooltip>
              )}
              {feedPreview.body}
            </pre>
            {feed.contentType !== 'csv' && FeedStore.showHeaderAndFooter && (
              <pre className="feeds__data-preview-footer">
                {FeedStore.fieldErrors.footer && (
                  <Tooltip
                    title={FeedStore.fieldErrors.footer.message}
                    placement="left"
                  >
                    <Icon
                      className="feeds__error"
                      symbol="triangle-exclamation"
                      width={20}
                      height={20}
                    />
                  </Tooltip>
                )}

                {feedPreview.footer}
              </pre>
            )}
          </div>
        </>
      )}
      {id !== 'new' && (
        <div className="feeds__actions">
          <DropdownButton
            action={() =>
              copyToClipboard(
                `${window.location.origin}/feeds/${UIStore.languages[0]}/${
                  feedOriginal.name
                }?instance=${getInstance()}${
                  feedOriginal.token ? '&token=' + feedOriginal.token : ''
                }`
              )
            }
            actionIcon="copy"
            text={t('Copy URL for') + ' ' + UIStore.languages[0]?.toUpperCase()}
            options={UIStore.languages
              .filter((_, idx) => idx !== 0)
              .map((lang) => ({
                label: `${t('Copy URL for')} ${lang.toUpperCase()}`,
                value: `${window.location.origin}/feeds/${lang}/${
                  feedOriginal.name
                }?instance=${getInstance()}${
                  feedOriginal.token ? '&token=' + feedOriginal.token : ''
                }`,
              }))}
            onSelect={(value) => copyToClipboard(value.key)}
            buttonLevel={1}
            destroyTooltipOnHide
            variant="secondary"
          />
          <DropdownButton
            action={() =>
              window.open(
                `${window.location.origin}/feeds/${UIStore.languages[0]}/${
                  feedOriginal.name
                }?instance=${getInstance()}${
                  feedOriginal.token ? '&token=' + feedOriginal.token : ''
                }`,
                '_blank'
              )
            }
            actionIcon="download"
            text={t('Download for') + ' ' + UIStore.languages[0]?.toUpperCase()}
            options={UIStore.languages
              .filter((_, idx) => idx !== 0)
              .map((lang) => ({
                label: `${t('Download for')} ${lang.toUpperCase()}`,
                value: `${window.location.origin}/feeds/${lang}/${
                  feedOriginal.name
                }?instance=${getInstance()}${
                  feedOriginal.token ? '&token=' + feedOriginal.token : ''
                }`,
              }))}
            onSelect={(value) => window.open(value.key, '_blank')}
            buttonLevel={1}
            destroyTooltipOnHide
            variant="secondary"
          />
        </div>
      )}
    </div>
  )
}

export default observer(FeedPreview)
