import { toast } from 'react-toastify'

import UIStore from '../../stores/UIStore'
import { basePath } from '../constants'
import t from '../translate'

/**
 * Checks if the user is on a valid makaira instance.
 * Redirects to first available instance when required.
 * Displays a message that the user has been redirected.
 *
 * @param {History} history
 */
export default function checkValidInstance(history) {
  // when the /instances request did not return a valid response we can't redirect the user so we cancel here.
  // App.js will take care of displaying an error screen.
  if (UIStore.instanceFetchStatusCode !== 200) return

  const { pathname } = location
  const path = pathname.split('/').splice(3).join('/')

  if (!UIStore.instances.includes(UIStore.currentInstance)) {
    // redirect with url parameter so we can display an error message on the next page
    window.location.href = `${basePath}/${UIStore.instances[0]}/${path}?wi=${UIStore.currentInstance}`
  } else {
    const parameters = new URLSearchParams(history.location.search)

    const oldInstance = parameters.get('wi')

    if (oldInstance !== null) {
      toast.info(
        t('wrong_instance_redirect')(oldInstance, UIStore.currentInstance),
        5
      )
      history.replace(`/${UIStore.instances[0]}/${path}`)
    }
  }
}
