import {
  defaultDropAnimation,
  DragOverlay as DndDragOverlay,
} from '@dnd-kit/core'
import React from 'react'
import { createPortal } from 'react-dom'

export default function DragOverlay(props) {
  return createPortal(
    <DndDragOverlay
      dropAnimation={{
        ...defaultDropAnimation,
        dragSourceOpacity: 0.5,
      }}
    >
      {props.children}
    </DndDragOverlay>,
    document.body
  )
}
