import ConfigurationPanel, {
  Panel,
} from '../../../components/ConfigurationPanel'

import GeneralSettings from './GeneralSettings'
import Revisions from './Revisions'

import t from '../../../utils/translate'

import './pageConfiguration.styl'

const PageConfiguration = ({ shown = false, formikRef, pageToEdit }) => {
  return (
    <ConfigurationPanel active={shown} defaultActive="settings">
      <Panel header={t('Settings')} key="settings">
        <GeneralSettings
          key={pageToEdit?.changed?.date}
          formikRef={formikRef}
        />
      </Panel>
      {!!pageToEdit.id && (
        <Panel header={t('Revisions')} key="revisions">
          <Revisions />
        </Panel>
      )}
    </ConfigurationPanel>
  )
}

export default PageConfiguration
