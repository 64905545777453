import { observer } from 'mobx-react-lite'
import Select from '../../../../components/Select'
import { t } from '../../../../utils'
import { AVAILABLE_FIELD_TYPES } from '../../constants'

function FieldArray({ fieldSettings, onChange }) {
  const { type, childConfig } = fieldSettings
  if (type !== 'array') return null

  // Array's item only allow below type
  const childTypeWhitelist = ['text', 'number', 'file', 'object']
  const options = AVAILABLE_FIELD_TYPES.filter((type) =>
    childTypeWhitelist.includes(type.value)
  )

  const onChangeArray = (itemType) => {
    onChange({
      childConfig: {
        type: itemType,
      },
    })
  }
  return (
    <>
      <Select
        title={t('Type of array elements')}
        value={childConfig?.type}
        options={options}
        onChange={onChangeArray}
      />
    </>
  )
}

export default observer(FieldArray)
