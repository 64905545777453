import { useMemo } from 'react'
import Button from '../Button'
import HeadingMain from '../Headings/HeadingMain'
import { t } from '../../utils'
import DropdownButton from '../DropdownButton'

const ResourceTableTitle = ({ title, headerAction = {} }) => {
  const { type, ...options } = headerAction
  const actions = useMemo(
    () => ({
      create: ({ action, ...props }) => (
        <Button variant="tertiary" icon="plus" onClick={action} {...props} />
      ),
      'mark-all-as-read': ({ action, ...props }) => (
        <Button icon="check" iconPosition="left" onClick={action} {...props}>
          {t('Mark all as read')}
        </Button>
      ),
      'dropdown-button': DropdownButton,
    }),
    []
  ) // eslint-disable-line

  const Component = actions[type]

  if (!title && !Component) {
    return null
  }

  return (
    <div className="resource-table__title">
      <HeadingMain>{title}</HeadingMain>

      {Component && <Component {...options} />}
    </div>
  )
}

export default ResourceTableTitle
