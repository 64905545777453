import Icon from '../../Icon'
import t from '../../../utils/translate'

import './confirmModal.styl'
import Button from '../../Button'
import Modal from '..'

const Header = () => {
  return (
    <div className="confirm-modal__header">
      <Icon
        symbol="triangle-exclamation"
        className="confirm-modal__header-icon"
      />
      <div className="confirm-modal__header-title">{t('warning')}!</div>
    </div>
  )
}

const Footer = ({ onClose, onSubmit }) => {
  return (
    <div>
      <Button
        className="confirm-modal__button"
        variant="secondary"
        icon="times"
        onClick={onClose}
      >
        {t('No')}
      </Button>

      <Button
        className="confirm-modal__button"
        variant="primary-blocker"
        icon="chevron-right"
        onClick={onSubmit}
      >
        {t('Yes')}
      </Button>
    </div>
  )
}

const ConfirmModal = ({ onClose, onSubmit, message, ...props }) => {
  return (
    <Modal
      onClose={onClose}
      header={<Header />}
      footer={<Footer onClose={onClose} onSubmit={onSubmit} />}
      maskClosable={false}
      {...props}
    >
      {message
        ? message
        : t(
            'You have unsaved changes, do you really want to jump to the old revision? Your current changes will be lost.'
          )}
    </Modal>
  )
}

export default ConfirmModal
