import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  /**
   * Return list of permission
   * @param { null | "feature" | "api" } type
   * @param { boolean } showAll default showAll = false => return filtered permission as booked
   * @returns
   */
  async getAll(type, showAll = false) {
    let filter = {}
    if (type) {
      filter['type'] = type
    }
    if (showAll) {
      filter['showAll'] = 'true'
    }
    const queryString = buildQueryString({ filter })

    let target = '/permissions?' + queryString
    return fetch(target)
  },

  /**
   * Preview list of permission base on list role's ids
   * @param {*} roleIds
   * @returns
   */
  async preview(roleIds = []) {
    return fetch('/permissions/preview', {
      method: 'POST',
      body: JSON.stringify(roleIds),
    })
  },
}
