import { observer } from 'mobx-react-lite'
import SmartBundleStore from '../../stores/SmartBundleStore'
import Text from '../../components/Text'
import Select from '../../components/Select'
import t from '../../utils/translate'
import classNames from 'classnames'

function SlotRequired(props) {
  const { id, required = true, title } = props

  const isEmptySlot =
    (title && Object.values(title).some((item) => !item)) || !title

  return (
    <div
      className={classNames('bundle__slot-required', {
        'bundle__slot-required--empty': isEmptySlot,
      })}
    >
      <Text size="echo" weight="bold">
        {t('and')}
      </Text>

      <Select
        size="large"
        value={required}
        dropdownMatchSelectWidth={false}
        disabled={isEmptySlot}
        options={[
          {
            label: t('required'),
            value: true,
          },
          {
            label: t('optional'),
            value: false,
          },
        ]}
        onChange={(value) =>
          SmartBundleStore.updateSlotRequiredStatus({ id, required: value })
        }
      />
    </div>
  )
}

export default observer(SlotRequired)
