import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/field?${queryString}`

    return fetch(target)
  },

  async create(searchField) {
    await fetch('/field', { method: 'POST', body: JSON.stringify(searchField) })
  },

  async update(searchField) {
    await fetch(`/field/${searchField.id}`, {
      method: 'PUT',
      body: JSON.stringify(searchField),
    })
  },

  async delete(id) {
    await fetch(`/field/${id}`, { method: 'DELETE' })
  },
}
