import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import ABTestStore from '../../../stores/ABTestStore'
import { createRedirectPath, t } from '../../../utils'
import Button from '../../Button'
import Switch from '../../Switch'
import Text from '../../Text'
import { getRedirectPath } from '../utils'
import RecommendationStore from '../../../stores/RecommendationStore'

const ConfigVariant = () => {
  const { editingExperiment } = ABTestStore

  const location = useLocation()
  const history = useHistory()
  const search = location.search
  const searchParams = new URLSearchParams(search)
  const currentVariant = searchParams.get('ab-variant')
  const abTestId = searchParams.get('ab-test')
  const isCreateView = location.pathname.endsWith('/ab-testing/new')

  const handleChangeVariant = (variant) => {
    if (variant === currentVariant) return

    ABTestStore.selectVariant(variant)

    if (ABTestStore.testDetail.case === 'recommendation') {
      RecommendationStore.fetchPreviewByRecommendation()
    }

    const config = ABTestStore.variationConfigByCase
    const newSearchParams = new URLSearchParams(search)
    newSearchParams.set('ab-variant', variant)
    const newURL = `${createRedirectPath(
      getRedirectPath(editingExperiment.case, config?.recommendation_id)
    )}?${newSearchParams.toString()}`
    history.replace(newURL)
  }

  useEffect(() => {
    if (currentVariant) {
      ABTestStore.selectVariant(currentVariant)
    }
    // eslint-disable-next-line
  }, [])

  if (isCreateView || !currentVariant) return null

  return (
    <div className="ab-metaconfig__versions">
      <Text
        weight="bold"
        size="delta"
        className="ab-metaconfig__versions-title"
      >
        Version
      </Text>
      {['A', 'B', 'C'].map((version, index) => {
        if (index + 1 > editingExperiment.segments) return null
        return (
          <div
            key={version}
            onClick={() => handleChangeVariant(version)}
            className={classNames('ab-metaconfig__version-tab', {
              'ab-metaconfig__version-tab--active': currentVariant === version,
            })}
          >
            <div className="traffic-distribution__variant">{version}</div>
            {version === 'A' && (
              <Text weight="bold" size="delta">
                Current
              </Text>
            )}
          </div>
        )
      })}
      {ABTestStore.testDetail?.case === 'ranking_mix' && (
        <Switch
          checked={ABTestStore.showHighlightVariants}
          onChange={ABTestStore.setShowHighlightVariants}
          title="Highlight affected settings/content"
          type="horizontal"
        />
      )}
      {ABTestStore.isVariationsDirty && (
        <div className="ab-metaconfig__versions-btns">
          <Button
            onClick={ABTestStore.undoVariantConfig}
            variant="primary-blocker"
          >
            {t('Discard changes')}
          </Button>
          <Button
            onClick={() =>
              ABTestStore.putVariationConfigs(
                ABTestStore.variationConfigByCase,
                abTestId
              )
            }
            variant="secondary-blocker"
          >
            {t('Save versions')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default observer(ConfigVariant)
