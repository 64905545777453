export default function Banner() {
  return {
    name: '',
    identifier: [],
    images: [],
    active: false,
    listType: 'search',
    searchAutosuggestBoth: null,
    language: null,
    position: 1,
    activeTiming: false,
    activeFrom: null,
    activeTo: null,
    title: '',
    description: '',
    imageMobile: null,
    imageDesktop: null,
    link: null,
    type: '1-spaltig', // backwards compatiblity
    allPersona: true,
  }
}
