import { observer } from 'mobx-react-lite'
import ReactDiffViewer from 'react-diff-viewer-continued'

import AuditStore from '../../stores/AuditStore'
import Modal from '../../components/Modal'
import t from '../../utils/translate'

function DiffModal() {
  return (
    <Modal
      visible={AuditStore.diffModal}
      header={t('View Changes')}
      onClose={() => AuditStore.openDiffModal(false)}
      width={1000}
      centered={true}
      mask
    >
      <ReactDiffViewer
        oldValue={AuditStore.transformData(AuditStore.diffData.old)}
        newValue={AuditStore.transformData(AuditStore.diffData.new)}
      />
    </Modal>
  )
}

export default observer(DiffModal)
