import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    let target = `/landingpages?${queryString}`
    let headers = {}

    return fetch(target, { headers })
  },

  async getById(id) {
    let target = `/landingpage/${id}`
    let headers = {}

    const { data } = await fetch(target, { headers })
    return data
  },

  async create(page) {
    return fetch('/landingpage', {
      method: 'POST',
      body: JSON.stringify(page),
    })
  },

  async update(page) {
    await fetch(`/landingpage/${page.id}`, {
      method: 'PUT',
      body: JSON.stringify(page),
    })
  },

  async bulkDelete(ids) {
    await fetch(`/landingpage`, {
      method: 'DELETE',
      body: JSON.stringify({
        ids,
      }),
    })
  },

  async getRevisions(id) {
    return fetch(`/landingpage/${id}/revisions`)
  },

  async createRevisions(data) {
    return fetch(`/landingpage/create-revision/${data.id}`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
  },

  async restoreRevision(pid, rid) {
    const { data } = await fetch(
      `/landingpage/${pid}/restore-revision/${rid}`,
      {
        method: 'PUT',
      }
    )

    return data
  },

  async getPreview(data, language = 'de', persona = 'All') {
    return fetch(`/enterprise/preview`, {
      method: 'POST',
      body: JSON.stringify({
        constraints: {
          'query.shop_id': '1',
          'query.language': language,
          'page.preview_data': data,
          'query.persona': persona,
        },
      }),
    })
  },
}
