import { SOURCES_TYPE } from '../../views/Importer/constants'

export function createSchedule(type) {
  if (
    [SOURCES_TYPE.OXID, SOURCES_TYPE.SHOPWARE, SOURCES_TYPE.OTHER].includes(
      type
    )
  ) {
    return [
      {
        enabled: true,
        kind: 'continuously',
        bulk: true,
        active: true,
        autoswitch: false,
        cronExpression: '',
        timeout: 21600,
      },
      {
        enabled: true,
        kind: 'one-time',
        bulk: true,
        active: false,
        autoswitch: true,
        cronExpression: '',
        timeout: 21600,
      },
    ]
  }

  return [
    {
      enabled: true,
      kind: 'one-time',
      bulk: true,
      active: false,
      autoswitch: true,
      cronExpression: '',
      timeout: 21600,
    },
  ]
}

export default function Importer() {
  return {
    internalTitle: 'Importer',
    languages: [],
    runnerCountMax: 500,
    sourceSecret: 'nqzfpxkthzsybcxm',
    sourceType: SOURCES_TYPE.OTHER, // defaut = other systems, oxid, shopware, ndjson possible here
    sourceUrl: '',
    sourceAuthUser: '',
    sourceAuthPassword: '',
    notificationEnabled: false,
    notificationMails: [],
    schedules: createSchedule(SOURCES_TYPE.OTHER),
    targetType: 'makaira',
  }
}
