import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { t } from '../../utils'
import Modal from '../../components/Modal'
import MenuStore from '../../stores/MenuStore'
import api from '../../api'

import SearchPreview from '../../components/SearchPreview'

function PrefillModal({ updateNode }) {
  const { prefillModalVisible, closePrefillModal, modalNode } = MenuStore

  if (!prefillModalVisible) return null

  async function handleAssignment({ esId, type }) {
    try {
      const { data } = await api.menu.getMetadata({ esId, type })

      let updated = { ...toJS(modalNode) } // we don't want to change the observable
      updated['text'] = data[0]
      updated['link'] = data[1]
      updated['esId'] = esId
      updated.isEditing = false

      updateNode(modalNode.uuid, updated)
      closePrefillModal()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      visible={prefillModalVisible}
      onClose={closePrefillModal}
      header={t('Pre-fill menu entry')}
      mask
      maskClosable
    >
      <SearchPreview
        searchLabel={t('Where would you like your entry to link to?')}
        focus
        handleAssignment={handleAssignment}
      />
    </Modal>
  )
}

export default observer(PrefillModal)
