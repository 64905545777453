import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { observer } from 'mobx-react-lite'
import { Form, Formik, FieldArray } from 'formik'
import * as Yup from 'yup'

import Modal from '../../../components/Modal'
import Button from '../../../components/Button'

import Operator from './Operator'
import FirstSlotSelect from './FirstSlotSelect'
import SecondSlotSelect from './SecondSlotSelect'

import FieldStore from '../../../stores/FieldStore'
import SmartBundleStore from '../../../stores/SmartBundleStore'

import t from '../../../utils/translate'

import './connectionModal.styl'

const schema = Yup.object().shape({
  connections: Yup.array().of(
    Yup.object().shape({
      first_slot: Yup.object().shape({
        field_name: Yup.string().required(t('Please select a field.')),
      }),
      second_slot: Yup.object().shape({
        field_name: Yup.string().required(t('Please select a field.')),
      }),
    })
  ),
})

function ConnectionModal() {
  const [deletePossible, setDeletePossible] = useState(true)

  const { bundleConnectionFields } = FieldStore
  const { isConnectionModalVisible } = SmartBundleStore
  const connections = SmartBundleStore.getConnectionsForEdit()

  useEffect(() => {
    FieldStore.fetchFields()
  }, [])

  useEffect(() => {
    setDeletePossible(
      !(connections.length === 1 && SmartBundleStore.addNewConnection)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectionModalVisible])

  const slotPositions = [
    SmartBundleStore.getSlotPosition(connections[0]?.first_slot),
    SmartBundleStore.getSlotPosition(connections[0]?.second_slot),
  ].sort()

  return (
    <Modal
      className="bundle-modal"
      visible={isConnectionModalVisible}
      header={t('condition_modal_header')(slotPositions[0], slotPositions[1])}
      footer={[
        deletePossible && (
          <Button
            key="delete"
            variant="secondary"
            level={1}
            onClick={() => SmartBundleStore.deleteConnection()}
            className="bundle__connection-delete"
          >
            {t('Delete')}
          </Button>
        ),
        <Button
          key="cancel"
          variant="secondary"
          level={1}
          onClick={() => SmartBundleStore.closeConnectionModal()}
        >
          {t('Cancel')}
        </Button>,
        <Button
          key="submit"
          variant="primary"
          level={1}
          type="submit"
          form="connectionModal"
        >
          {t('Submit')}
        </Button>,
      ]}
      onClose={() => SmartBundleStore.closeConnectionModal()}
      width={800}
      centered={true}
    >
      <Formik
        initialValues={{
          // We assign to each connection a UUID (if the connection has not one yet) to have unique keys
          // so that when a connection is deleted the form is still correctly rendered.
          connections: connections.map((connection) => {
            if (!connection.id) {
              connection.id = uuidv4()
            }

            return connection
          }),
        }}
        validationSchema={schema}
        validateOnChange={false}
        onSubmit={(values) => {
          SmartBundleStore.updateConnection(values.connections)
        }}
      >
        {(formikProps) => {
          const { values } = formikProps

          return (
            <Form id="connectionModal">
              <FieldArray name="connections">
                {(arrayHelpers) => (
                  <>
                    <div>
                      {values.connections.map((connection, index) => (
                        <div
                          key={`${index}-${connection.id}`}
                          className="connection-modal__row"
                          data-testid="field-conditions__row"
                        >
                          <FirstSlotSelect
                            index={index}
                            fields={bundleConnectionFields}
                            formikProps={formikProps}
                          />

                          {connection.first_slot?.field_name && (
                            <Operator
                              className="connection-modal__operator"
                              index={index}
                              connection={connection}
                              formikProps={formikProps}
                            />
                          )}

                          {connection.first_slot?.field_name && (
                            <SecondSlotSelect
                              index={index}
                              fields={bundleConnectionFields}
                              formikProps={formikProps}
                            />
                          )}

                          <Button
                            className="connection-modal__remove-button"
                            icon="trash"
                            variant="reduced"
                            level={1}
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </div>
                      ))}
                    </div>

                    <Button
                      className="connection-modal__add-button"
                      icon="plus"
                      variant="tertiary"
                      level={1}
                      onClick={() =>
                        arrayHelpers.push({
                          id: uuidv4(),
                          first_slot: {
                            id: SmartBundleStore.selectedSlotsToConnect[0],
                          },
                          second_slot: {
                            id: SmartBundleStore.selectedSlotsToConnect[1],
                          },
                        })
                      }
                    />
                  </>
                )}
              </FieldArray>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default observer(ConnectionModal)
