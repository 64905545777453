import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useCallback } from 'react'
import { useRouteMatch, useHistory } from 'react-router'

import AppStore from '../../stores/Apps/AppStore'
import { createRedirectPath, t } from '../../utils'
import { ActionLayerStore } from '../../stores'

import './externalApps.styl'

const ExternalApps = () => {
  const history = useHistory()
  const routeMatch = useRouteMatch()
  const slug = routeMatch.params.slug
  const appInfo = AppStore.appInfo

  useEffect(() => {
    const getAppUrl = async () => {
      const data = await AppStore.fetchExternalAppsUrl(slug)
      // change iframeURL to working url for debugging purposes
      // data.iframeURL = 'https://makaira-app-boilerplate.herokuapp.com/?nonce=nonce&domain=domain&instance=instance&hmac=b137a266618aa0d3c133a8c1444875b144acabec2b3a8725c81b01d1aba7c340'
      if (!data) {
        history.push(createRedirectPath('/dashboard'))
      }
    }

    getAppUrl()
  }, [slug, history])

  const iframeRef = useRef()

  const handleMessageEvent = useCallback(
    async (event) => {
      const { source, action, hmac, nonce, makairaHmac, metadata } = event.data

      if (source !== `makaira-app-${appInfo.slug}`) return

      switch (action) {
        case 'requestUser': {
          const credentialsValid = await AppStore.validateCredentialsRequest(
            appInfo.slug,
            {
              hmac,
              nonce,
              makairaHmac,
            }
          )
          AppStore.sendValidationAnswerMessage(credentialsValid, event)
          break
        }
        case 'openActionLayer': {
          const { saveBackButton, backButton, saveButton, header } =
            metadata || {}
          let openActionLayerOptions = { header }
          // If no specific metadata we will show all button and bind event
          if (!metadata || (metadata && saveBackButton)) {
            openActionLayerOptions = {
              ...openActionLayerOptions,
              saveTitle:
                typeof saveBackButton === 'string'
                  ? saveBackButton
                  : t('Save & back to list'),
              onSave: () =>
                ActionLayerStore.sendExternalAppMessage(
                  source,
                  event,
                  'save_back'
                ),
            }
          }
          if (!metadata || (metadata && backButton)) {
            openActionLayerOptions = {
              ...openActionLayerOptions,
              closeTitle:
                typeof backButton === 'string'
                  ? backButton
                  : t('Abort & back to list'),
              onClose: () =>
                ActionLayerStore.sendExternalAppMessage(source, event, 'back'),
            }
          }
          if (!metadata || (metadata && saveButton)) {
            openActionLayerOptions = {
              ...openActionLayerOptions,
              saveWithContinue:
                typeof saveButton === 'string' ? saveButton : t('Save'),
              onSaveWithContinue: () =>
                ActionLayerStore.sendExternalAppMessage(source, event, 'save'),
            }
          }

          ActionLayerStore.openActionLayer(openActionLayerOptions)
          break
        }
        case 'closeActionLayer':
          ActionLayerStore.closeActionLayer()
          break
      }
    },
    [appInfo.slug]
  )

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent)

    return () => {
      window.removeEventListener('message', handleMessageEvent)
    }
  }, [handleMessageEvent])

  useEffect(() => {
    const windowHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--window-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', windowHeight)
    windowHeight()

    return () => {
      window.addEventListener('resize', windowHeight)
    }
  }, [])

  return (
    <iframe
      ref={iframeRef}
      className="external-apps__iframe"
      src={appInfo.iframeURL}
      allow="clipboard-read; clipboard-write"
    />
  )
}

export default observer(ExternalApps)
