import React, { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import UIStore from '../../stores/UIStore'
import ConfigurationStore from '../../stores/ConfigurationStore'
import { toast } from 'react-toastify'

import useDidMountEffect from '../../utils/useDidMountEffect'
import t from '../../utils/translate'
import flattenDocument from '../../utils/flattenDocument'
import normalizeTitleField from '../../utils/normalizeTitleField'
import api from '../../api'
import Heading from './Heading'
import ContentWrapper from '../../components/ContentWrapper'
import Text from '../../components/Text'
import InspectorPageTitle from '../Inspector/InspectorPageTitle'
import ResourceTable from '../../components/ResourceTable'
import { Column } from '../../components/Table'
import ItemPreview from './ItemPreview'
import ReasonField from './ReasonField'
import ImagePreview from './ImagePreview'
import ResourceTableFilter from '../../components/ResourceTable/ResourceTableFilter'

function DataInspector() {
  const [language, setLanguage] = useState(UIStore.languages[0])
  const [searchResult, setSearchResult] = useState({})
  const [cachedPhrase, setCachedPhrase] = useState('')
  const [indexState, setIndexState] = useState('active')
  const [showEverything, setShowEverything] = useState(false)
  const [isFetching, setFetching] = useState(false)
  const [previewItem, setPreviewItem] = useState({})
  const [isModalVisible, setModalVisible] = useState(false)
  const [selectedType, setSelectedType] = useState('product')

  const currentSelectedItems = useMemo(
    () =>
      (searchResult?.[selectedType]?.items ?? []).map((item) =>
        normalizeTitleField(flattenDocument(item))
      ),
    [searchResult, selectedType]
  )

  useEffect(() => {
    UIStore.fetchDocumentTypes()
    ConfigurationStore.fetchConfiguration()
  }, [])

  useDidMountEffect(() => {
    updateSearchResult(cachedPhrase)
  }, [language, indexState, showEverything])

  async function updateSearchResult(searchPhrase) {
    setFetching(true)
    const result = await UIStore.fetchSearchResultPreview(
      searchPhrase,
      showEverything,
      indexState,
      language
    )

    setSelectedType(
      Object.keys(result || {}).find((type) => result[type].count > 0) ||
        'product'
    )
    setFetching(false)
    setSearchResult(result)
    setCachedPhrase(searchPhrase.trim())
  }

  async function handleSelection(record, type) {
    try {
      const item = await api.common.fetchDocumentPreview({
        type,
        esId: record.es_id,
        state: indexState,
        language,
      })

      setPreviewItem(item)
      setModalVisible(true)
    } catch (error) {
      toast.error('Could not fetch item preview.')
    }
  }

  const filters = [
    {
      type: 'search',
      title: t('Search term'),
      name: 'searchPhrase',
      loading: isFetching,
      onChange: ({ searchPhrase }) => updateSearchResult(searchPhrase),
    },
    {
      type: 'single-select',
      title: t('Language'),
      name: 'language',
      onChange: ({ language: newLanguage }) => setLanguage(newLanguage),
      value: language,
      options: UIStore.languages.map((lang) => ({
        label: lang,
        value: lang,
      })),
    },
    {
      type: 'single-select',
      title: t('Index'),
      name: 'index',
      onChange: ({ index }) => setIndexState(index),
      value: indexState,
      options: [
        { label: t('live'), value: 'active' },
        { label: t('archived'), value: 'passive' },
      ],
    },
    {
      type: 'single-select',
      title: t('Mode'),
      name: 'mode',
      onChange: ({ mode }) => setShowEverything(mode),
      value: showEverything,
      info: t('dataInspectorModeInfo'),
      options: [
        { label: t('Search preview'), value: false },
        { label: t('Show all results'), value: true },
      ],
    },
  ]

  return (
    <>
      <InspectorPageTitle />

      <ContentWrapper>
        <div className="importer-config__section-wrapper">
          <Text className="importer-config__guide-text" size="bravo">
            {t(`dataInspectorDescription`)}
          </Text>
        </div>
        <ResourceTableFilter filters={filters} />

        <div className="data-inspector__table">
          <Heading
            selectedType={selectedType}
            onSelectType={setSelectedType}
            allTypes={UIStore.documentTypes}
            searchResultTypes={Object.entries(searchResult).map(
              ([type, result]) => ({ type, count: result.count })
            )}
          />

          <ResourceTable
            loading={isFetching}
            data={currentSelectedItems}
            store={UIStore}
            emptyText={
              cachedPhrase === ''
                ? t('Please enter a search term to see the results.')
                : t('There is no result for the provided search phrase.')
            }
            onRowClick={(record) => {
              handleSelection(record, selectedType)
            }}
            rowClassName={(record) =>
              record.preview_hidden ? 'data-inspector__table__reason-row' : ''
            }
            customFooterRender={() => null}
          >
            {selectedType === 'product' && (
              <Column
                width={150}
                title={t('Image')}
                dataIndex=""
                render={(_, record) => <ImagePreview {...record} />}
              />
            )}
            <Column width={350} title={t('ID')} dataIndex="id" />
            <Column title={t('Title')} dataIndex="title" />
            <Column width={150} title={t('Score')} dataIndex="_score" />
            {showEverything && (
              <Column
                width={200}
                title={t('Reason')}
                render={(_, record) => <ReasonField {...record} />}
              />
            )}
          </ResourceTable>
        </div>
      </ContentWrapper>
      <ItemPreview
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        item={previewItem}
      />
    </>
  )
}

export default observer(DataInspector)
