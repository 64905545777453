import { notification } from 'antd'
import { EditorStore } from '../../'
import t from '../../../utils/translate'

const notifyStructureUpdate = () => {
  notification.info({
    message: t('Structure updated'),
    description: t(
      'The components glowing blue in the list were adapted. It seems like the component structure has been changed after the last edit of this page. We just updated the data structure for you. Please check the content and save it afterwards.'
    ),
    placement: 'center',
    onClose: () => {
      EditorStore.setDirty(true)
    },
    duration: 7,
  })
}

export { notifyStructureUpdate }
