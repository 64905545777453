import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import Button from '../../../../components/Button'
import TextInput from '../../../../components/TextInput'
import Textarea from '../../../../components/Textarea'
import { ComponentStore } from '../../../../stores'
import FormSection from './FormSection'
import { t } from '../../../../utils'

function SectionSettings({ settings = {}, isSave }) {
  const { type, label, description, uuid, name: nameFromProps = '' } = settings
  const [name, setName] = useState(nameFromProps)

  useEffect(() => {
    setName(nameFromProps)
  }, [nameFromProps])

  const onChangeProperties = (properties) => {
    for (let key of Object.keys(properties)) {
      ComponentStore.updateField(
        ComponentStore.activeFieldId,
        key,
        properties[key]
      )
    }
  }

  // only handle section settings
  if (type !== 'section') return null

  return (
    <>
      {!isSave && (
        <FormSection title={t('Section')}>
          <TextInput
            label={t('Title')}
            value={label}
            rows={3}
            onChange={(e) => {
              onChangeProperties({ label: e.target.value })
            }}
            key={`label_${uuid}`}
            disabled={!ComponentStore.activeFieldId}
          />
          <Textarea
            title={t('Description')}
            value={description}
            rows={3}
            onChange={(description) => {
              onChangeProperties({ description })
            }}
            key={`description_${uuid}`}
            disabled={!ComponentStore.activeFieldId}
          />
        </FormSection>
      )}
      {isSave && (
        <FormSection>
          <TextInput
            label={
              <>
                {t(
                  'A saved section is a group of elements that can be reused to build other component. Saved section appear on left side.'
                )}
                <br />
                {t('Name')}
              </>
            }
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
            disabled={!ComponentStore.activeFieldId}
          />
          <Button onClick={() => ComponentStore.saveSection(settings, name)}>
            {t('Save section')}
          </Button>
        </FormSection>
      )}
    </>
  )
}

export default observer(SectionSettings)
