const Choices = {
  text: [
    { id: 'list', name: 'Liste' },
    { id: 'list_multiselect', name: 'Mehrfachauswahl' },
    { id: 'list_custom_1', name: 'Liste (Custom 1)' },
    { id: 'list_custom_2', name: 'Liste (Custom 2)' },
    { id: 'list_multiselect_custom_1', name: 'Mehrfachauswahl (Custom 1)' },
    { id: 'list_multiselect_custom_2', name: 'Mehrfachauswahl (Custom 2)' },
    //{ id: 'list_manufacturer_logo', name: 'Hersteller-Logos' },
    //{ id: 'list_color', name: 'Farbauswahl' },
    //{ id: 'list_ratings', name: 'Rating' },
  ],
  nested: [
    { id: 'list', name: 'Liste' },
    { id: 'list_multiselect', name: 'Mehrfachauswahl' },
    { id: 'list_custom_1', name: 'Liste (Custom 1)' },
    { id: 'list_custom_2', name: 'Liste (Custom 2)' },
    { id: 'list_multiselect_custom_1', name: 'Mehrfachauswahl (Custom 1)' },
    { id: 'list_multiselect_custom_2', name: 'Mehrfachauswahl (Custom 2)' },
  ],
  script: [{ id: 'script', name: 'Checkbox' }],
  long: [
    { id: 'range_slider', name: 'Range-Slider' },
    { id: 'range_slider_custom_1', name: 'Range-Slider (Custom 1)' },
    { id: 'range_slider_custom_2', name: 'Range-Slider (Custom 2)' },
    { id: 'range_slider_price', name: 'Range-Slider (Preis)' },
    { id: 'list_multiselect', name: 'Mehrfachauswahl' },
    { id: 'list_multiselect_custom_1', name: 'Mehrfachauswahl (Custom 1)' },
    { id: 'list_multiselect_custom_2', name: 'Mehrfachauswahl (Custom 2)' },
  ],
  double: [
    { id: 'range_slider', name: 'Range-Slider' },
    { id: 'range_slider_custom_1', name: 'Range-Slider (Custom 1)' },
    { id: 'range_slider_custom_2', name: 'Range-Slider (Custom 2)' },
    { id: 'range_slider_price', name: 'Range-Slider (Preis)' },
    { id: 'list_multiselect', name: 'Mehrfachauswahl' },
    { id: 'list_multiselect_custom_1', name: 'Mehrfachauswahl (Custom 1)' },
    { id: 'list_multiselect_custom_2', name: 'Mehrfachauswahl (Custom 2)' },
  ],
  date: [
    { id: 'range_slider', name: 'Range-Slider' },
    { id: 'range_slider_custom_1', name: 'Range-Slider (Custom 1)' },
    { id: 'range_slider_custom_2', name: 'Range-Slider (Custom 2)' },
    { id: 'list_multiselect', name: 'Mehrfachauswahl' },
    { id: 'list_multiselect_custom_1', name: 'Mehrfachauswahl (Custom 1)' },
    { id: 'list_multiselect_custom_2', name: 'Mehrfachauswahl (Custom 2)' },
  ],
  boolean: [],
  category: [
    { id: 'categorytree', name: 'Kategoriebaum' },
    {
      id: 'categorysubtree',
      name: 'Kategorie-Teilbaum der aktuellen Kategorie',
    },
    { id: 'fullcategorytree', name: 'Kategoriebaum (Alle Kategorien)' },
    {
      id: 'fullcategorysubtree',
      name: 'Kategorie-Teilbaum der aktuellen Kategorie (Alle Kategorien)',
    },
  ],
}

export default Choices
