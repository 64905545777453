import { observer } from 'mobx-react-lite'
import { t } from '../../../utils'
import Modal from '../../../components/Modal'
import { EditorStore, UIStore } from '../../../stores'
import ContentModal from '../Structure/ContentModal'

import './settingModal.styl'

function ContentAppModal() {
  const {
    closeContenModalApp,
    isContentModalAppVisible,
    selectedContentModalApp,
  } = EditorStore

  if (!isContentModalAppVisible) return null

  return (
    <Modal
      closeIconText={t('Close')}
      visible={isContentModalAppVisible}
      onClose={closeContenModalApp}
      header={selectedContentModalApp.title[UIStore.currentLanguage]}
      mask
      maskClosable={false}
      align="center"
      zIndex={1000}
      footer={
        <div className="content-modal__footer">
          {t('If you experience trouble, please contact the App developer.')}
        </div>
      }
    >
      <div>
        <ContentModal {...selectedContentModalApp} />
      </div>
    </Modal>
  )
}

export default observer(ContentAppModal)
