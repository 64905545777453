import isString from 'lodash/isString'
import Gravatar from 'react-gravatar'

const DEFAULT_URL = 'https://cdn.auth0.com/avatars/'

const MakairaGravatar = ({ email, ...rest }) => {
  return (
    <Gravatar
      default={`${DEFAULT_URL}${
        isString(email)
          ? email
              .replace(/[^a-zA-Z]+/g, '')
              .substring(0, 2)
              .toLowerCase()
          : ''
      }.png`}
      email={email}
      protocol="https://"
      size={32}
      {...rest}
    />
  )
}

export default MakairaGravatar
