import { makeAutoObservable, autorun, toJS } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import t from '../../utils/translate'
import prepareMenu from '../../utils/prepareMenu'
import { UIStore } from '..'

class MenuStore {
  isDirty = false
  originalMenuData = {}
  menuData = {
    menu: [],
  }
  contentLanguage = ''
  state = 'pending' // "pending", "done" or "error"
  prefillModalVisible = false
  settingModalVisible = false
  modalNode = {}

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })

    const disposer = autorun(() => {
      if (UIStore.enterpriseConfiguration.defaultLanguage) {
        this.contentLanguage = UIStore.enterpriseConfiguration.defaultLanguage
        disposer()
      }
    })
  }

  *fetchMenu() {
    try {
      let { data } = yield api.menu.get()

      data.menu = prepareMenu(data.menu)

      this.menuData = data
      this.originalMenuData = data
      this.state = 'done'
      yield
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong loading the menu.')
    }
  }

  resetMenu() {
    this.menuData = toJS(this.originalMenuData)
    this.isDirty = false
  }

  removeExpandedKeys(tree, expandedKeys = []) {
    return tree.map((node) => this.deleteKey(node, expandedKeys))
  }

  deleteKey(entry, expandedKeys) {
    if (entry.expanded) {
      expandedKeys.push(entry.uuid)
    }

    delete entry.expanded
    delete entry.isEditing
    delete entry.errors
    delete entry.isNewNode

    if (entry.children)
      entry.children = this.removeExpandedKeys(entry.children, expandedKeys)

    return entry
  }

  *saveMenu() {
    try {
      const expandedKeys = []

      let { data } = yield api.menu.update(
        this.removeExpandedKeys(this.menuData.menu, expandedKeys)
      )
      data.menu = prepareMenu(data.menu, expandedKeys)

      this.menuData = data
      this.isDirty = false
      toast.success(t('Menu successfully saved'))
    } catch (error) {
      this.isDirty = false
      this.state = 'error'
      toast.error('Something went wrong saving menu.')
    }
  }

  updateMenu(menu, setDirty = true) {
    this.menuData.menu = menu

    if (setDirty) {
      this.isDirty = true
    }
  }

  setContentLanguage(lang) {
    this.contentLanguage = lang
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  openPrefillModal(node) {
    this.prefillModalVisible = true
    this.modalNode = node
  }

  closePrefillModal() {
    this.prefillModalVisible = false
    this.modalNode = {}
  }

  openSettingModal(node) {
    this.settingModalVisible = true
    this.modalNode = node
  }

  closeSettingModal() {
    this.settingModalVisible = false
    this.modalNode = {}
  }
}

export default new MenuStore()
