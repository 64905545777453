import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = options ? buildQueryString(options) : ''

    let target = `/component?${queryString}`
    let headers = {}

    return fetch(target, { headers })
  },

  async get(id) {
    return fetch(`/component/${id}`)
  },

  async create(component) {
    return fetch('/component', {
      method: 'POST',
      body: JSON.stringify(component),
    })
  },

  async update(component) {
    return fetch(`/component/${component.id}`, {
      method: 'PUT',
      body: JSON.stringify(component),
    })
  },

  async delete(ids) {
    await fetch('/component', {
      method: 'DELETE',
      body: JSON.stringify({
        ids,
      }),
    })
  },

  async getUsage({ id, ...options }) {
    const queryString = buildQueryString(options)
    const target = `/component/${id}/usage?${queryString}`
    return fetch(target)
  },

  async getSections() {
    return fetch(`/section?_start=0&end=1000`)
  },

  async createSection(section) {
    return fetch(`/section?_start=0&end=1000`, {
      method: 'POST',
      body: JSON.stringify(section),
    })
  },

  async deleteSection(id) {
    return fetch(`/section/${id}`, { method: 'DELETE' })
  },
}
