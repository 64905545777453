import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router'

import Banner from './Banner'
import PublicFields from './PublicFields'

import ContentWrapper from '../../components/ContentWrapper'
import PageTitle from '../../components/PageTitle'

import {
  ActionLayerStore,
  GeneralSettingsBannerStore,
  PublicFieldStore,
} from '../../stores'
import { t, createRedirectPath } from '../../utils'
import UrlSettings from './UrlSettings'
import Personas from './Personas'

import './style.styl'

const Settings = () => {
  let { path } = useRouteMatch()
  const location = useLocation()
  const {
    isDirty: isDirtyBannerSettings,
    onDiscardBannerSettings,
    saveBannerSettings,
  } = GeneralSettingsBannerStore

  const {
    isDirty: isDirtyPublicField,
    discard: onDiscardPublicField,
    saveAddOrEditFields: savePublicField,
  } = PublicFieldStore

  const isDirty = location.pathname.includes('banners')
    ? isDirtyBannerSettings
    : isDirtyPublicField

  const handleSave = async () => {
    if (location.pathname.includes('banners')) {
      return saveBannerSettings()
    } else {
      return savePublicField()
    }
  }

  const handleClose = () => {
    onDiscardBannerSettings()
    onDiscardPublicField()
  }

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        saveTitle: t('Save all settings'),
        closeTitle: t('Abort'),
        onSave: handleSave,
        onClose: handleClose,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  return (
    <>
      <PageTitle
        prefix={t('You are configuring')}
        hiddenFields={['internal-title']}
        tabData={[
          {
            route: { path: '/settings/instance-and-urls' },
            title: t('Instance & URLs'),
          },
          {
            route: { path: '/settings/banners' },
            title: t('Banners'),
          },
          {
            route: { path: '/settings/public-fields' },
            title: t('Public fields'),
          },
          { route: { path: '/settings/personas' }, title: t('Personas') },
        ]}
      >
        {t('Settings')}
      </PageTitle>
      <ContentWrapper className="settings">
        <Switch>
          <Route exact path={path}>
            <Redirect to={createRedirectPath('/settings/instance-and-urls')} />
          </Route>
          <Route path={`${path}/instance-and-urls`}>
            <UrlSettings />
          </Route>
          <Route path={`${path}/banners`}>
            <Banner />
          </Route>
          <Route path={`${path}/public-fields`}>
            <PublicFields />
          </Route>
          <Route path={`${path}/personas`}>
            <Personas />
          </Route>
        </Switch>
      </ContentWrapper>
    </>
  )
}

export default observer(Settings)
