import { cloneDeep } from 'lodash'
import { makeAutoObservable, toJS } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import { delay } from '../../utils'

class AntonymStore {
  antonyms = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  detail = {}
  isFetchingDetail = false
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = {}
  state = 'pending' // "pending", "done" or "error"
  isDirty = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchAntonyms()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchAntonyms()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchAntonyms()
  }

  getById(id) {
    return this.antonyms.find((s) => s.id == id)
  }

  *fetchDetails(id, language) {
    this.isFetchingDetail = true

    if (id === 'new') {
      yield delay(100)
      this.detail = { language }
      this.isFetchingDetail = false
      return
    }

    try {
      const { data } = yield api.antonyms.get(id)
      this.detail = data
      this.isFetchingDetail = false
    } catch (error) {
      toast.error('Something went wrong loading the anatonym detail.')
      this.isFetchingDetail = false
    }
  }

  *fetchAntonyms(initial) {
    if (initial) {
      this.filter = {}
    }
    try {
      this.state = 'pending'
      const { data, total } = yield api.antonyms.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.antonyms = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the anatonym listing.')
      this.state = 'error'
    }
  }

  *updateOrCreate(stream) {
    stream = cloneDeep(toJS(stream || this.detail))
    if (stream.antonyms?.endsWith('=>')) {
      stream.antonyms = stream.antonyms.replace('=>', '')
    }
    try {
      if (stream.id) {
        yield api.antonyms.update(stream)
        this.isDirty = false
        toast.success('Updated successfully')
      } else {
        const { data } = yield api.antonyms.create(stream)
        toast.success('Created successfully')
        this.isDirty = false
        return data.id
      }
    } catch (error) {
      this.isDirty = false
      this.state = 'error'
      toast.error('Something went wrong while updateOrCreate...')
    }
    this.fetchAntonyms()
  }

  *delete(ids) {
    try {
      yield api.antonyms.delete(ids)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchAntonyms()
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  updateField(data, key, value) {
    data[key] = value
    this.isDirty = true
  }
}

export default new AntonymStore()
