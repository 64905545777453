import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import ContentWrapper from '../../components/ContentWrapper'
import FormikOnChange from '../../components/FormikOnChange'
import LoadingScreen from '../../components/LoadingScreen'
import PageTitle from '../../components/PageTitle'
import UserInstancePermission from './components/UserInstancePermission'
import TextInput from '../../components/TextInput'
import {
  ActionLayerStore,
  RoleManagementStore,
  UserManagementStore,
} from '../../stores'
import { createRedirectPath, t } from '../../utils'
import { toJS } from 'mobx'
import PermissionsTable from './components/PermissionsTable'

import './style.styl'
import DeleteUser from './components/DeleteUser'

const EditUser = () => {
  const formikRef = useRef()
  const { id } = useParams()
  const history = useHistory()
  const { isDirty } = UserManagementStore
  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Not a proper email')
      .required(t('Email is required!')),
  })
  const user = UserManagementStore.userDetail

  useEffect(() => {
    UserManagementStore.fetchUserDetail(id)
    RoleManagementStore.fetchRoles(true)

    return () => {
      UserManagementStore.reset()
    }
  }, [id])

  function handleClose() {
    UserManagementStore.setDirty(false)
    history.push(createRedirectPath('/user'))
  }

  async function handleSaveAndStay() {
    await formikRef.current?.submitForm()

    if (formikRef.current !== undefined && !formikRef.current?.isValid) {
      return false
    }

    const newId = await UserManagementStore.updateOrCreate()

    // We need to redirect to the correct URL if the page was created
    if (newId) {
      history.push(createRedirectPath(`/user/${newId}`))
    }
  }

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        onSave: handleSaveAndStay,
        onClose: handleClose,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  const handleSubmit = (values) =>
    UserManagementStore.setUserDetail({ ...user, ...values })

  if (UserManagementStore.isFetchingUserDetail) return <LoadingScreen />
  console.log(toJS(UserManagementStore.instancesTableResource))
  return (
    <>
      <PageTitle
        prefix={user.user_id ? t('You are editing') : t('You are creating')}
      >
        {user.user_id ? user.email : t('A new user account')}
      </PageTitle>
      <ContentWrapper className="user-management">
        <div className="user-management__edit-wrapper">
          <Formik
            innerRef={formikRef}
            initialValues={{
              email: user.email,
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {(formikProps) => (
              <>
                <FormikOnChange onChange={handleSubmit} />
                <TextInput
                  autoFocus
                  label={t('Email')}
                  value={formikProps.values.email}
                  onChange={(e) =>
                    formikProps.setFieldValue('email', e.target.value)
                  }
                  error={formikProps.errors.email}
                />
              </>
            )}
          </Formik>

          <UserInstancePermission user={user} />

          <DeleteUser user={user} />

          <PermissionsTable />
        </div>
      </ContentWrapper>
    </>
  )
}

export default observer(EditUser)
