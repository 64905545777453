import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { NotificationStore, UIStore } from '../../stores'
import ResourceTable from '../../components/ResourceTable'
import { Column } from '../../components/Table'
import { t } from '../../utils'
import ContentWrapper from '../../components/ContentWrapper'
import DateColumn from './components/DateColumn'
import Icon from '../../components/Icon'
import Button from '../../components/Button'
import Text from '../../components/Text'
import MessageColumn from './components/MessageColumn'
import './list.styl'

function List() {
  const {
    fetchNotifications,
    fetchUnreadNotifications = () => {},
    markReadAllNotifications,
    unreadNotifications = [],
  } = NotificationStore
  const { currentLanguage } = UIStore
  const hasUnreadItem = unreadNotifications.length > 0

  useEffect(() => {
    fetchNotifications(true)
    // eslint-disable-next-line
  }, [])

  const getRowClassName = (record) => {
    if (record.read) {
      return ''
    }

    switch (record.level) {
      case 'warning':
        return 'mk-table-row__warning'
      case 'info':
        return 'mk-table-row__info'
      case 'error':
        return 'mk-table-row__error'
      default:
        return ''
    }
  }

  const handleReadAll = async () => {
    await markReadAllNotifications()
    fetchNotifications()
    fetchUnreadNotifications()
  }

  return (
    <ContentWrapper className="notification-list">
      <ResourceTable
        title={t('Notifications')}
        store={NotificationStore}
        resourceName="notifications"
        rowClassName={getRowClassName}
        headerAction={{
          type: 'mark-all-as-read',
          action: handleReadAll,
          disabled: !hasUnreadItem,
        }}
        footerAdditionalActions={
          <Button
            icon="check"
            iconPosition="left"
            className="notification-list__mark-all-button"
            onClick={handleReadAll}
            disabled={!hasUnreadItem}
          >
            {t('Mark all as read')}
          </Button>
        }
      >
        <Column
          title={t('Unread')}
          width={60}
          dataIndex="read"
          headerAlign="center"
          align="center"
          render={(val) =>
            !val && (
              <Text size="charlie" variant="unset">
                <Icon symbol="circle__solid" width={12} height={12} />
              </Text>
            )
          }
        />
        <Column
          width={180}
          title={t('Date')}
          dataIndex="timestamp"
          render={(val) => <DateColumn date={val} />}
        />
        <Column
          className="notification-list__level"
          width={180}
          title={t('Level')}
          dataIndex="level"
          render={(val) => (
            <Text size="charlie" variant="unset">
              {t(val)}
            </Text>
          )}
        />
        <Column
          width={180}
          title={t('App')}
          dataIndex="source"
          render={(val) => (
            <Text size="charlie" variant="unset">
              {val}
            </Text>
          )}
        />
        <Column
          title={t('Notification and details')}
          dataIndex="message"
          render={(_, row) => (
            <MessageColumn
              key={currentLanguage}
              {...row}
              language={currentLanguage}
            />
          )}
        />
      </ResourceTable>
    </ContentWrapper>
  )
}

export default observer(List)
