import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import * as Yup from 'yup'
import {
  StreamStore,
  FieldStore,
  FilterSectionStore,
  CategoryStore,
  ActionLayerStore,
} from '../../stores'
import { t, createRedirectPath, debounce } from '../../utils'
import { useParams, useHistory } from 'react-router'
import PageTitle from '../../components/PageTitle'
import NumberInput from '../../components/NumberInput'
import SliderInput from '../../components/SliderInput'
import ContentWrapper from '../../components/ContentWrapper'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import Select from '../../components/Select'
import FilterSection from '../../components/FilterSection'
import UsageModal from './components/UsageModal'
import PreviewSection from './components/PreviewSection'
import { routes } from '../../routing'
import LoadingScreen from '../../components/LoadingScreen'
import './form.styl'
import UIStore from '../../stores/UIStore'

const streamFormSchema = Yup.object().shape({
  title: Yup.string().required(t('This field is required.')),
})

function StreamForm() {
  const { id } = useParams()
  const editing = id !== 'new'
  const [errors, setErrors] = useState({})
  const defaultDataType = 'product'
  const [formValues, setFormValues] = useState({
    query: {
      and: [{ field: 'active', type: 'boolean', operator: 'isTrue' }],
    },
    datatype: defaultDataType,
  })
  const { isDirty, setDirty, updateOrCreate } = StreamStore
  const [isFetching, setFetching] = useState(true)
  const [previewLanguage, setPreviewLanguage] = useState(
    UIStore.enterpriseConfiguration.defaultLanguage
  )
  const [previewGroup, setPreviewGroup] = useState('')
  const history = useHistory()

  useEffect(() => {
    UIStore.fetchProductGroups(previewLanguage)
  }, [previewLanguage])

  useEffect(() => {
    if (UIStore.documentTypes.length === 0) {
      UIStore.fetchDocumentTypes()
    }
    FieldStore.fetchFields(defaultDataType)
    FilterSectionStore.fetchManufacturers()
    CategoryStore.setPagination({ pageSize: 1000 })

    if (editing) {
      StreamStore.fetchById(id).then((d) => {
        setFormValues(d)
        setFetching(false)
      })
    } else {
      setFetching(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        saveTitle: t('Save & back to list'),
        closeTitle: t('Abort & back to list'),
        onSaveWithContinue: () => handleSave('continue'),
        onSave: () => handleSave('back'),
        onClose: () => {
          setDirty(false)
          history.push(createRedirectPath(routes.ProductStreamList?.path))
        },
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty, formValues])

  const setFormValue = (key, value, mergeObj = false) => {
    setDirty(true)
    setFormValues((values) => {
      return {
        ...values,
        [key]:
          mergeObj && typeof value === 'object'
            ? Object.assign({}, values[key], value)
            : value,
      }
    })
  }

  const validateForm = (values) => {
    try {
      const validData = streamFormSchema.validateSync(values, {
        abortEarly: false,
      })
      setErrors({})
      return validData
    } catch (err) {
      if (err.errors && Array.isArray(err.errors) && err.errors.length > 0) {
        const errors = {}
        for (const innerError of err.inner) {
          errors[innerError.path] = innerError.message
        }
        setErrors(errors)
      }
      return null
    }
  }

  const handleSave = async (action) => {
    const body = validateForm(formValues)

    if (!body) {
      return false
    }
    const result = await updateOrCreate(body)
    if (result && result.id) {
      switch (action) {
        case 'continue':
          history.replace(createRedirectPath(`/stream/${result.id}`))
          break
        case 'back':
          history.push(createRedirectPath(routes.ProductStreamList.path))
          break
      }
    }
    setDirty(false)
  }

  const handlePreviewChange = debounce((search) => {
    StreamStore.fetchStreamProducts({
      config: formValues,
      language: previewLanguage,
      page: search?.page ?? 1,
      group: search.group,
    })
  }, 500)

  const onChangePreviewLanguage = (lang) => {
    setPreviewLanguage(lang)
    StreamStore.fetchStreamProducts({
      config: formValues,
      language: lang,
      page: 1,
    })
  }

  const onChangePreviewGroup = (group) => {
    setPreviewGroup(group)
  }

  const shouldShowUsage =
    formValues.categoryCount > 0 || formValues.productFeedCount > 0

  if (isFetching) return <LoadingScreen />

  return (
    <>
      <PageTitle
        prefix={editing ? t('You are editing') : t('You are creating')}
        metaInfo={
          editing && {
            timestamp: formValues.changed?.date,
            timezone: formValues.changed?.timezone,
            user: formValues.user,
            id: formValues.id,
          }
        }
        hiddenFields={['internal-title']}
      >
        {formValues.title || t('Stream')}
      </PageTitle>
      <ContentWrapper className="product-stream">
        <div className="product-stream__edit-wrapper">
          {shouldShowUsage && (
            <div className="streams-usage">
              <Icon symbol="circle-info__regular" />
              <div className="streams-usage__content">
                <Text
                  size="delta"
                  element="h3"
                  className="streams-usage__title"
                >
                  {t('This stream is in use on ...')}
                </Text>
                <div className="streams-usage__tags">
                  {formValues.categoryCount > 0 && (
                    <span className="tag">
                      {t('Category')}
                      <Icon
                        symbol="circle-info__regular"
                        onClick={() =>
                          StreamStore.openStreamDialog(id, 'category')
                        }
                      />
                    </span>
                  )}
                  {formValues.productFeedCount > 0 && (
                    <span className="tag">
                      {t('Feed')}
                      <Icon
                        symbol="circle-info__regular"
                        onClick={() => StreamStore.openStreamDialog(id, 'feed')}
                      />
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="streams-form">
            <TextInput
              label={t('Name')}
              value={formValues.title}
              error={errors.title}
              onChange={(e) => setFormValue('title', e.target.value)}
            />

            <Select
              title={t('Document type')}
              dropdownMatchSelectWidth={false}
              options={UIStore.documentTypes.map((type) => ({
                label: type.title,
                value: type.id,
              }))}
              defaultValue={defaultDataType}
              value={formValues.datatype}
              onChange={(value) => {
                setFormValue('datatype', value)
                FieldStore.fetchFields(value)
              }}
            />

            <hr className="streams-divider" />

            <FilterSection
              type="and"
              title={t('Must fulfil these conditions')}
              required={true}
              defaultValue={formValues.query?.and}
              onChange={(filters) =>
                setFormValue('query', { and: filters }, true)
              }
            />

            <hr className="streams-divider" />

            <FilterSection
              type="or"
              title={t('Fulfil at least one condition')}
              defaultValue={formValues.query?.or}
              onChange={(filters) =>
                setFormValue('query', { or: filters }, true)
              }
            />

            <hr className="streams-divider" />

            <div className="streams-boosting">
              <Text size="echo" weight="bold">
                {t('Boosting')}
              </Text>
              <div className="streams-boosting__content">
                <div className="streams-boosting__input">
                  <NumberInput
                    max={100}
                    min={-99}
                    step={1}
                    value={formValues.boostFactor || 0}
                    onChange={(value) => {
                      if (!isNaN(value)) {
                        setFormValue('boostFactor', value)
                      }
                    }}
                  />
                  <SliderInput
                    max={100}
                    min={-99}
                    step={1}
                    value={formValues.boostFactor || 0}
                    onChange={(value) => {
                      if (!isNaN(value)) {
                        setFormValue('boostFactor', value)
                      }
                    }}
                  />
                </div>
                {/* <Icon symbol="circle-info__regular" /> */}
              </div>
            </div>
          </div>
          <UsageModal />
        </div>
        <PreviewSection
          key={JSON.stringify(formValues.query) || 'preview-stream-key'}
          onChange={handlePreviewChange}
          type={formValues.datatype}
          previewLanguage={previewLanguage}
          onChangePreviewLanguage={onChangePreviewLanguage}
          previewGroup={previewGroup}
          onChangePreviewGroup={onChangePreviewGroup}
        />
      </ContentWrapper>
    </>
  )
}

export default observer(StreamForm)
