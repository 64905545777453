import { useState } from 'react'
import classnames from 'classnames'

import Button from '../Button'
import Select from '../Select'
import Icon from '../Icon'

import t from '../../utils/translate'

import './bulkActions.styl'
import Checkbox from '../Checkbox'

const BulkActionsFixedContainer = ({ isVisible, children }) => (
  <div
    className={classnames('bulk-actions', {
      ['bulk-actions--fixed']: !isVisible,
    })}
  >
    <Icon
      className="bulk-actions__arrow-up"
      width={16}
      heigth={16}
      symbol="arrow-up"
    />
    {children}
  </div>
)

const BulkActions = ({
  bulkActions,
  checkedElements,
  isVisible,
  isShowCheckbox,
  onShowCheckbox,
  toggleSelectAll,
  isCheckedAll,
}) => {
  const [selectedAction, setSelectedAction] = useState(0)

  const handleActionChange = (newAction) => {
    setSelectedAction(newAction)
  }

  const handleActionExecute = () => {
    if (bulkActions.length < selectedAction) return

    if (typeof bulkActions[selectedAction].onExecute !== 'function') return

    bulkActions[selectedAction].onExecute(checkedElements)
  }

  if (!isShowCheckbox) {
    return (
      <BulkActionsFixedContainer isVisible={isVisible}>
        <Button
          className="bulk-actions__edit"
          variant="tertiary"
          icon="pencil"
          onClick={() => onShowCheckbox(true)}
        />
      </BulkActionsFixedContainer>
    )
  }

  return (
    <BulkActionsFixedContainer isVisible={isVisible}>
      <Checkbox
        onChange={(e) => toggleSelectAll(e.target.checked)}
        checked={isCheckedAll}
      />

      <Select
        value={'0'}
        options={bulkActions.map((action, index) => ({
          value: index.toString(),
          label: action.title,
        }))}
        onChange={handleActionChange}
        customDropdownPlacement="topLeft"
      />

      <Button
        variant="primary"
        icon="chevron-right"
        onClick={handleActionExecute}
        data-cy="bulk-action-execute"
      >
        {t('Execute')}
      </Button>

      <Button
        className="bulk-actions__deselect"
        variant="tertiary"
        icon="times"
        onClick={() => onShowCheckbox(false)}
      />
    </BulkActionsFixedContainer>
  )
}

export default BulkActions
