import { observer } from 'mobx-react-lite'
import { useState, useMemo, useEffect } from 'react'

import Modal from '../../../components/Modal'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import Select from '../../../components/Select'
import Button from '../../../components/Button'
import { EditorStore } from '../../../stores'
import { t } from '../../../utils'
import formatLanguageKey from '../../../utils/formatLanguageKey'

function WarningMappingModal() {
  const {
    showWarningMappingModal,
    closeWarningMappingModal,
    languagesMapping,
    mappingAndPaste,
    isDifferenceStorageType,
    isDifferenceLanguageMapping,
  } = EditorStore

  const [languageMapped, setLanguageMapped] = useState({})

  const originalLanguages = useMemo(
    () =>
      languagesMapping.languagesFromElement
        ? [...languagesMapping.languagesFromElement, 'no-content']
        : [],
    [languagesMapping.languagesFromElement]
  )
  const destinationLanguages = useMemo(
    () => languagesMapping.destinationLanguages || [],
    [languagesMapping.destinationLanguages]
  )

  // use “Don't map” as a fallback
  useEffect(() => {
    if (destinationLanguages.length > 0) {
      const newLanguageMapped = {}
      destinationLanguages.forEach((language, index) => {
        newLanguageMapped[index] = {
          originalLanguage: 'no-content',
          destinationLanguage: language,
        }
      })
      setLanguageMapped(newLanguageMapped)
    }
  }, [destinationLanguages])

  useEffect(() => {
    return () => {
      setLanguageMapped({})
    }
  }, [showWarningMappingModal])

  const isDisabledButton =
    isDifferenceLanguageMapping &&
    Object.values(languageMapped).every(
      (languages) => !languages.originalLanguage
    )

  if (!showWarningMappingModal) return null

  return (
    <Modal
      visible={showWarningMappingModal}
      onClose={closeWarningMappingModal}
      header={t('Warning')}
      mask
      align="left"
      maskClosable
      footer={[
        <Button
          key="cancel"
          variant="secondary"
          level={1}
          icon="xmark"
          onClick={closeWarningMappingModal}
        >
          {t('Cancel')}
        </Button>,
        <Button
          key="paste"
          variant="secondary-blocker"
          level={1}
          icon="chevron-right"
          disabled={isDisabledButton}
          onClick={() => mappingAndPaste(Object.values(languageMapped))}
        >
          {t('Paste')}
        </Button>,
      ]}
    >
      {isDifferenceStorageType && (
        <div className="page-editor__mapping-modal">
          <Text element="h2" size="charlie" weight="bold">
            {t('Media will be removed')}
          </Text>
          <Text element="p">
            {t(
              'Your media content of the components will be removed because of a missmatch of your media configuration. Please click Paste if you are aware of that.'
            )}
          </Text>
          {isDifferenceLanguageMapping && <br />}
        </div>
      )}

      {isDifferenceLanguageMapping && (
        <div className="page-editor__mapping-modal">
          <Text element="h2" size="charlie" weight="bold">
            {t('Language Mapping')}
          </Text>
          <Text element="p">{t('mapping_description')}</Text>
          {destinationLanguages.map((language, index) => {
            return (
              <div key={language} className="page-editor__mapping-row">
                <Select
                  title={t('Original Language')}
                  value={
                    languageMapped[index] &&
                    languageMapped[index].originalLanguage
                  }
                  onChange={(value) =>
                    setLanguageMapped((obj) => ({
                      ...obj,
                      [index]: {
                        originalLanguage: value,
                        destinationLanguage: language,
                      },
                    }))
                  }
                  options={originalLanguages.map((lang) => ({
                    label:
                      lang === 'no-content'
                        ? t("Don't map")
                        : formatLanguageKey(lang),
                    value: lang,
                  }))}
                />
                <Icon symbol="chevron-right" />
                <div>
                  <div className="select-input__label">
                    {t('Destination Language')}
                  </div>
                  <Text className="page-editor__language-value">
                    {formatLanguageKey(language)}
                  </Text>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </Modal>
  )
}

export default observer(WarningMappingModal)
