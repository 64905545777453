import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import { ComponentStore } from '../../../stores'
import { AVAILABLE_FIELD_TYPES, CONDITIONAL_SECTION_TYPE } from '../constants'
import ObjectAndArrayElement from './ObjectAndArrayElement'
import { useDraggable, useDroppable } from '@dnd-kit/core'
import { t } from '../../../utils'
import Button from '../../../components/Button'

const SortableElementBuilder = ({
  field,
  level = 1,
  lastItem,
  isDraggingParent,
  lastChildOfSection,
}) => {
  const { listeners, setNodeRef, isDragging, active } = useDraggable({
    id: field.uuid,
    data: {
      type: 'builder-element',
      field,
    },
  })

  const activeField = ComponentStore.getFieldByUUID(active?.id)
  const targetField = ComponentStore.getFieldByUUID(field.conditionField)

  const dropable = useDroppable({
    id: `dropable--${field.parentId || ''}--${field.position}--${field.uuid}`,
    disabled:
      isDraggingParent ||
      active?.data?.current?.type === 'section' ||
      active?.data?.current?.type === 'form-section',
  })

  const style = {
    opacity: isDragging ? 0.5 : 1,
  }

  const element =
    [...AVAILABLE_FIELD_TYPES, CONDITIONAL_SECTION_TYPE].find(
      (elm) => elm.value === field.type
    ) || {}

  const isArrayOfObject =
    field.type === 'array' && field.childConfig?.type === 'object'

  const isHaveChildren =
    isArrayOfObject ||
    field.type === 'object' ||
    field.type === 'hotspot' ||
    field.type === CONDITIONAL_SECTION_TYPE.value

  return (
    <>
      <div
        className={classNames('component-editor__placeholder-element', {
          'component-editor__placeholder-element--is-over':
            dropable.isOver &&
            (activeField?.position > field?.position ||
              active?.data?.current?.type === 'form-element' ||
              activeField?.parentId !== field?.parentId),
        })}
      />
      <div
        ref={isDraggingParent ? null : setNodeRef}
        onClick={(e) => {
          e.stopPropagation()
          ComponentStore.setActiveField(field.uuid)
        }}
        {...listeners}
      >
        <div
          style={style}
          ref={dropable.setNodeRef}
          className={classNames('component-editor__element-builder', {
            'component-editor__element-builder--dragging': isDragging,
            'component-editor__element-builder--object':
              isHaveChildren && field.type !== CONDITIONAL_SECTION_TYPE.value,
            'component-editor__element-builder--level-even': level % 2 === 0,
            'component-editor__element-builder--active':
              field.uuid === ComponentStore.activeFieldId,
            'component-editor__element-builder--conditional-section':
              field.type === CONDITIONAL_SECTION_TYPE.value,
          })}
        >
          <div className="component-editor__element-builder-label">
            <Icon width="12px" height="12px" symbol={element.icon} />
            {field.type === CONDITIONAL_SECTION_TYPE.value && (
              <Button
                className="component-editor__remove-conditional-section-btn"
                icon="trash"
                variant="tertiary"
                tooltip={t('Remove conditional section')}
                onClick={() => ComponentStore.removeElement(field)}
                level={-1}
              />
            )}
            {targetField && field.type === CONDITIONAL_SECTION_TYPE.value && (
              <div
                style={{ marginLeft: 5 }}
                className="component-editor__element-builder-label component-editor__element-builder-label--close"
              >
                <Text weight="bold">{targetField.id}</Text>
              </div>
            )}
            {field.type !== CONDITIONAL_SECTION_TYPE.value && (
              <Text weight="bold">{field.label}</Text>
            )}
            {!targetField && field.type === CONDITIONAL_SECTION_TYPE.value && (
              <Text weight="bold">
                {t(
                  'Set the condition to show this section in the tab on the right'
                )}
              </Text>
            )}

            {field.type === CONDITIONAL_SECTION_TYPE.value && !targetField && (
              <Icon
                style={{ marginLeft: 4 }}
                width="12px"
                height="12px"
                symbol={'arrow-right'}
              />
            )}
          </div>
          {field.type !== CONDITIONAL_SECTION_TYPE.value && (
            <div
              onClick={(e) => {
                e.stopPropagation()
                if (lastChildOfSection) {
                  ComponentStore.removeElement(
                    ComponentStore.getFieldByUUID(field.parentId)
                  )
                } else {
                  ComponentStore.removeElement(field)
                }
              }}
              className="component-editor__element-builder-label component-editor__element-builder-label--close"
            >
              {field.id && <Text weight="bold">{field.id}</Text>}
              <Icon width="12px" height="12px" symbol="times" />
            </div>
          )}

          {isHaveChildren && (
            <ObjectAndArrayElement
              level={level}
              field={field}
              isDraggingParent={isDraggingParent || isDragging}
            />
          )}
        </div>
      </div>
      <div
        className={classNames('component-editor__placeholder-element', {
          'component-editor__placeholder-element--is-over':
            dropable.isOver &&
            activeField?.position < field?.position &&
            activeField?.parentId === field?.parentId &&
            !lastItem,
        })}
      />
    </>
  )
}

export default observer(SortableElementBuilder)
