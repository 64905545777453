import t from '../../utils/translate'
import moment from 'moment'
import { formatDateWithoutSeconds } from '../../utils/formatDate'

export const getResourceTableFilters = (Store, languages) => {
  const filters = [
    {
      type: 'single-select',
      title: t('Active'),
      placeholder: t('Select'),
      name: 'active',
      onChange: Store.setFilter,
      defaultValue: Store.filter.active || '',
      options: [
        {
          label: t('Show all'),
          value: '',
        },
        {
          label: t('Active'),
          value: '1',
        },
        {
          label: t('Not active'),
          value: '0',
        },
      ],
    },
    {
      type: 'single-select',
      title: t('Displayed on'),
      name: 'list_type',
      onChange: Store.setFilter,
      defaultValue: Store.filter.list_type || '',
      options: [
        {
          label: t('Show all'),
          value: '',
        },
        {
          label: t('Search Result'),
          value: 'search',
        },
        {
          label: t('Category'),
          value: 'category',
        },
        {
          label: t('Manufacturer'),
          value: 'manufacturer',
        },
      ],
    },
    {
      type: 'datepicker',
      title: t('Start date before'),
      placeholder: t('Select Timestamp'),
      name: 'active_from_before',
      dataFormat: (date) => (date ? date.toISOString() : ''),
      onChange: Store.setFilter,
      position: 9,
      defaultValue:
        Store.filter.active_from_before &&
        moment(Store.filter.active_from_before),
    },
    {
      type: 'datepicker',
      title: t('End date after'),
      placeholder: t('Select Timestamp'),
      name: 'active_to_after',
      dataFormat: (date) => (date ? date.toISOString() : ''),
      onChange: Store.setFilter,
      position: 10,
      defaultValue:
        Store.filter.active_to_after && moment(Store.filter.active_to_after),
    },
    {
      type: 'search',
      title: t('Search'),
      name: 'q',
      onChange: Store.setFilter,
      defaultValue: Store.filter.q,
    },
  ]

  if (languages && languages.length) {
    const lang = {
      type: 'single-select',
      title: t('Languages'),
      placeholder: t('Select language'),
      name: 'language',
      onChange: Store.setFilter,
      defaultValue: Store.filter.language || '',
      options: [
        {
          label: t('Show all'),
          value: '',
        },
        ...languages.map((l) => ({
          value: l,
          label: t(l),
        })),
      ],
    }

    filters.splice(1, 0, lang)
  }

  return filters
}

export const formatScheduleDate = ({
  activeFrom,
  activeTo,
  activeTiming = false,
  active = false,
}) => {
  const from = moment(activeFrom)
  const to = moment(activeTo)
  const now = moment()
  const expired = to.isValid() && now.isAfter(to)

  if (!activeTiming || !active || expired) {
    const fromResult = activeFrom
      ? {
          textFrom: formatDateWithoutSeconds({ dateString: activeFrom }),
          iconFrom: 'clock__solid',
          classFrom: 'banner-list__text-grey',
        }
      : {}

    const toResult = activeTo
      ? {
          textTo: formatDateWithoutSeconds({ dateString: activeTo }),
          iconTo: 'circle-xmark__solid',
          classTo: 'banner-list__text-grey',
        }
      : {}

    return Object.assign({}, fromResult, toResult)
  }

  const upcoming = from.isValid() && now.isBefore(from)
  if (upcoming) {
    const fromResult = activeFrom
      ? {
          textFrom: formatDateWithoutSeconds({ dateString: activeFrom }),
          iconFrom: 'clock__solid',
          classFrom: '',
        }
      : {}

    const toResult = activeTo
      ? {
          textTo: formatDateWithoutSeconds({ dateString: activeTo }),
          iconTo: 'flag-checkered__solid',
          classTo: '',
        }
      : {}

    return Object.assign({}, fromResult, toResult)
  }

  const fromResult = activeFrom
    ? {
        textFrom: formatDateWithoutSeconds({ dateString: activeFrom }),
        iconFrom: 'clock__solid',
        classFrom: 'banner-list__text-green',
      }
    : {}

  const toResult = activeTo
    ? {
        textTo: formatDateWithoutSeconds({ dateString: activeTo }),
        iconTo: 'flag-checkered__solid',
        classTo: 'banner-list__text-green',
      }
    : {}

  return Object.assign({}, fromResult, toResult)
}
