import t from '../../utils/translate'
import Text from '../Text'
import { observer } from 'mobx-react-lite'
import '../ResourceTable/resourceTable.styl'

const DisabledFeature = ({
  title,
  requireFeature,
  showContactInfo = true,
  content,
}) => {
  return (
    <div className="content-wrapper">
      <div className="empty-table">
        <div className="resource-table__title">
          <div className="heading">
            <Text size="echo" weight="bold">
              {title}
            </Text>
          </div>
        </div>
        <div className="empty-table__container">
          <Text size="bravo" weight="medium">
            {content || t(`${requireFeature}Description`)}
          </Text>
          {showContactInfo && (
            <Text size="bravo" weight="medium">
              <br />
              {t('contactInformation')}
            </Text>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(DisabledFeature)
