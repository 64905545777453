import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import Text from '../../components/Text'

import ResourceTable from '../../components/ResourceTable'
import { Column, ArrowAction } from '../../components/Table'

import ActionLayerStore from '../../stores/ActionLayerStore'

import t from '../../utils/translate'
import createRedirectPath from '../../utils/createRedirectPath'

import ActiveField from '../../components/ActiveField'
import { RecommendationStore } from '../../stores'
import ABTestStore from '../../stores/ABTestStore'

function Recommendations() {
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState([])
  const { setFilter } = RecommendationStore
  const { search } = useLocation()
  const { originVariantConfig, showMetaconfigView } = ABTestStore

  useEffect(() => {
    RecommendationStore.fetchRecommendations(true)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (showMetaconfigView && originVariantConfig?.recommendation_id) {
      goTodetailPageWithAbTesting(originVariantConfig?.recommendation_id)
    }
    // eslint-disable-next-line
  }, [originVariantConfig])

  const goTodetailPageWithAbTesting = (id, isPush) => {
    const newSearchParams = new URLSearchParams(search)
    const newURL = `${createRedirectPath(
      `/recommendations/${id}`
    )}?${newSearchParams.toString()}`
    if (isPush) {
      history.push(newURL)
    } else history.replace(newURL)
  }

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    await RecommendationStore.delete(selectedRows)

    if (RecommendationStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const bulkActions = [
    {
      title: t('Delete'),
      onExecute: () => showDeleteWarning(),
    },
  ]

  return (
    <div className="content-wrapper">
      <ResourceTable
        title={t(
          !ABTestStore.showMetaconfigView
            ? 'Recommendations'
            : 'Select a recommendation to test...'
        )}
        store={RecommendationStore}
        resourceName="recommendations"
        filterParams="filter"
        filters={[
          {
            type: 'single-select',
            title: t('Active'),
            placeholder: t('Select'),
            name: 'active',
            onChange: setFilter,
            defaultValue: RecommendationStore.filter.active || '',
            options: [
              {
                label: t('Show all'),
                value: '',
              },
              {
                label: t('Active'),
                value: '1',
              },
              {
                label: t('Not active'),
                value: '0',
              },
            ],
          },
          {
            type: 'search',
            title: t('Search'),
            name: 'q',
            onChange: setFilter,
          },
        ]}
        headerAction={
          !ABTestStore.showMetaconfigView
            ? {
                type: 'create',
                action: () =>
                  history.push(createRedirectPath('/recommendations/new')),
              }
            : {}
        }
        loading={RecommendationStore.state === 'pending'}
        bulkActions={bulkActions}
        rowSelection={{
          selectedRows,
          onSelectionChange: (newRows) => setSelectedRows(newRows),
        }}
        onRowClick={(record) => {
          if (ABTestStore.showMetaconfigView) {
            ABTestStore.setRecommendationVariantsConfig(record)
            goTodetailPageWithAbTesting(record.id, true)
          } else {
            history.push(createRedirectPath(`/recommendations/${record.id}`))
          }
        }}
        emptyText={t(
          'Recommendations provides the shop users with recommended products'
        )}
        createAction={
          !ABTestStore.showMetaconfigView
            ? () => history.push(createRedirectPath('/recommendations/new'))
            : undefined
        }
        createActionText={t('Create Recommendation')}
      >
        <Column
          width={70}
          title={t('Active')}
          dataIndex="isActive"
          align="center"
          render={(value) => <ActiveField active={value} />}
        />
        <Column
          title={t('Name (ID)')}
          className="mk-table-cell--bold"
          render={(_, record) => (
            <Text weight="bold" element="div">
              {record.name}{' '}
              <span className="bundle__list-id">({record.id})</span>
            </Text>
          )}
        />
        <Column
          title={t('Identifier')}
          className="mk-table-cell--bold"
          render={(_, record) => (
            <Text weight="bold">{record.recommendationId}</Text>
          )}
        />
        <Column
          title={t('Type')}
          className="mk-table-cell--bold"
          render={(_, record) => (
            <Text weight="bold">
              {RecommendationStore.typeLabels[record.recommendationType]}
            </Text>
          )}
        />
        <Column width={50} render={() => <ArrowAction />} />
      </ResourceTable>
    </div>
  )
}

export default observer(Recommendations)
