import { get, isArray } from 'lodash'
import React, { useEffect, useState } from 'react'
import { t } from '../../utils'
import Tab from './Tab'
import PromotedDocuments from './PromotedDocuments'
import SearchPreview from '../SearchPreview'
import DocumentPreviewRow, { DocumentPreviewHeader } from './DocumentPreviewRow'
import Text from '../Text'
import Radio from '../Radio'

import './promotedDocuments.styl'

const PromoteDocuments = ({
  items = [],
  sortType = 'auto',
  /**
   * (items, sortType) => {}
   */
  onChange = () => {},
  activeType = ['product'],
}) => {
  const [activeDocumentType, setActiveDocumentType] = useState('product')
  const [searchResult, setSearchResult] = useState({})

  useEffect(() => {
    if (!activeType.includes(activeDocumentType)) {
      setActiveDocumentType(activeType[0])
    }
  }, [activeType, activeDocumentType])

  const onRemovePromotedDocuments = (es_id) => {
    const filteredItems = items.filter((item) => item.documentId !== es_id)
    onChange(filteredItems, sortType)
  }

  const onChangeSort = (value) => {
    onChange(items, value)
  }

  const onChangeTag = (document, tags) => {
    const changedDocuments = items.map((item) => {
      if (item.documentId === document.es_id) {
        item.tags = tags
      }
      return item
    })
    onChange(changedDocuments, sortType)
  }

  const tabs = [
    {
      title: t('Products'),
      id: 'product',
      disabled: !activeType.includes('product'),
    },
    {
      title: t('Categories'),
      id: 'category',
      disabled: !activeType.includes('category'),
    },
    {
      title: t('Manufacturer'),
      id: 'manufacturer',
      disabled: !activeType.includes('manufacturer'),
    },
  ]

  function sortByPosition(itemA, itemB) {
    return itemA.position - itemB.position
  }

  function mapDocument(document, position) {
    let title = document.raw_title || document.title
    if (activeDocumentType === 'category') {
      title = document.category_title
    } else if (activeDocumentType === 'manufacturer') {
      title = document.manufacturer_title
    }

    const stringEan = isArray(document.ean) ? document.ean[0] : document.ean

    return {
      key: document.es_id,
      picture_url_main: document.picture_url_main,
      documentId: document.es_id,
      documentProductNumber: stringEan || '',
      documentTitle: title,
      documentType: activeDocumentType,
      position,
      tags:
        activeDocumentType !== 'product'
          ? undefined
          : get(document, 'tags', []),
    }
  }

  function mapDocumentToPreview(document) {
    const searchedItem = searchResult[document.documentId]
    return {
      es_id: document.documentId,
      ean: document.documentProductNumber,
      title: document.documentTitle,
      type: document.documentType,
      position: document.position,
      id: document.documentId,
      picture_url_main:
        searchedItem?.fields.picture_url_main || document?.picture_url_main,
      tags: document.tags,
    }
  }

  const promotedItems = items
    .filter((item) => item.documentType === activeDocumentType)
    .map(mapDocumentToPreview)
    .sort(sortByPosition)

  const LOCALES = {
    product: {
      itemsUnit: t('products'),
    },
    manufacturer: {
      itemsUnit: t('manufacturers'),
    },
    category: {
      itemsUnit: t('categories'),
    },
  }

  return (
    <div className="promote-documents">
      <Text size="echo" weight="700">
        {t('Promoting')}
      </Text>
      <Tab
        items={tabs}
        activeTab={activeDocumentType}
        onChange={setActiveDocumentType}
      />
      <div className="promote-document__container">
        <div className="column document-preview">
          <Text
            element="div"
            className="column__header"
            weight="700"
            size="delta"
          >
            {t('promo_document_heading')(LOCALES[activeDocumentType].itemsUnit)}
          </Text>
          <SearchPreview
            key={activeDocumentType}
            searchLabel={t('promo_document_desc')(
              LOCALES[activeDocumentType].itemsUnit
            )}
            limit={10}
            type={activeDocumentType}
            fields={['id', 'es_id', 'ean', 'title', 'picture_url_main']}
            showEAN={activeDocumentType === 'product'}
            showImage={activeDocumentType === 'product'}
            onDataFetched={(data) => {
              const mapped = data.reduce((prev, curr) => {
                return {
                  ...prev,
                  [curr.fields.es_id]: curr,
                }
              }, {})
              setSearchResult((prevData) => ({ ...prevData, ...mapped }))
            }}
            resultHeader={<DocumentPreviewHeader hideSorter />}
            renderItem={({ item, type, position }) => {
              const disabled = !!promotedItems.find(
                (promo) => item.es_id === promo.es_id
              )
              return (
                <DocumentPreviewRow
                  document={item}
                  type={type}
                  onClick={() => {
                    if (disabled) return

                    const promotingItem = searchResult[item.es_id]
                    if (promotingItem) {
                      const highestPosition =
                        promotedItems[promotedItems.length - 1]?.position || 0
                      const mapped = mapDocument(
                        promotingItem.fields,
                        highestPosition + 1
                      )
                      onChange([...items, mapped], sortType)
                    }
                  }}
                  position={position}
                  hideSorter
                  hideAction
                  hideImage={activeDocumentType !== 'product'}
                  hidePosition={item.type !== 'product'}
                  hideTags
                  disabled={disabled}
                />
              )
            }}
          />
        </div>
        <div className="column">
          <Text
            element="div"
            className="column__header"
            weight="700"
            size="delta"
          >
            {t('Your promoted')} {t(LOCALES[activeDocumentType].itemsUnit)}
          </Text>
          {activeDocumentType === 'product' && (
            <div className="radio-group">
              <Radio.Group value={sortType} onChange={onChangeSort}>
                <Radio
                  value={'auto'}
                  label={t('Sort products by sort field')}
                />
                <Radio value={'manual'} label={t('Sort manually like below')} />
              </Radio.Group>
            </div>
          )}
          <PromotedDocuments
            items={promotedItems}
            onRemove={onRemovePromotedDocuments}
            hideHeader={activeDocumentType !== 'product'}
            onChangeTag={onChangeTag}
            onChangePosition={(data) =>
              onChange(data.map(mapDocument), sortType)
            }
          />
        </div>
      </div>
    </div>
  )
}

export default PromoteDocuments
