import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import Text from '../../../../components/Text'

function FormSection({ children, title, className }) {
  return (
    <div className={classNames('field-configuration__section', className)}>
      {title && (
        <Text size="delta" element="div">
          {title}
        </Text>
      )}

      {children}
    </div>
  )
}

export default observer(FormSection)
