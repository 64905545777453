import { v4 as uuidv4 } from 'uuid'
import { UIStore } from '../../stores'
import LanguageConfig from './LanguageConfig'

export default function Component(config, activeLanguage) {
  const { identifier, name, sections = [] } = config

  const fields = sections.reduce((fields, section) => {
    return [...fields, ...section.fields]
  }, [])

  const properties = UIStore.languages.reduce((properties, lang) => {
    properties[lang] = new LanguageConfig(fields, activeLanguage === lang)

    return properties
  }, {})

  return {
    id: uuidv4(),
    component: identifier,
    name,
    properties,
  }
}
