import Prism from 'prismjs'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { FeedStore } from '../../../stores'
import Editor from 'react-simple-code-editor'
import Icon from '../../../components/Icon'
import { Tooltip } from 'antd'
import { debounce } from 'lodash'
import { useMemo } from 'react'
import Button from '../../../components/Button'
import t from '../../../utils/translate'

const DEFAULT_TEMPLATE_CODE = '{{ item }}'

const CodeEditor = ({ field, onChange }) => {
  const checkValid = useMemo(
    () => debounce((feed) => FeedStore.fetchFieldValidate(feed), 300),
    []
  )

  const handleChange = (code) => {
    onChange(code)
    checkValid(FeedStore.feedDetail)
  }

  const handleResetClick = () => {
    onChange(DEFAULT_TEMPLATE_CODE)
    checkValid(FeedStore.feedDetail)
  }

  return (
    <div
      className={classNames('feeds__code-editor', {
        'feeds__code-editor--error': FeedStore.fieldErrors[field.uniqueId],
      })}
    >
      <Editor
        value={field.template}
        onValueChange={handleChange}
        highlight={(code) =>
          Prism.highlight(code, Prism.languages.twig, 'twig')
        }
        padding={10}
      />
      {FeedStore.fieldErrors[field.uniqueId] && (
        <Tooltip title={FeedStore.fieldErrors[field.uniqueId].message}>
          <Icon
            className="feeds__error"
            symbol="triangle-exclamation"
            width={20}
            height={20}
          />
        </Tooltip>
      )}
      {field.template !== DEFAULT_TEMPLATE_CODE && (
        <Tooltip title={t('Reset to default code')}>
          <Button
            className={classNames('feeds__reset-button', {
              'feeds__reset-button--plus-error':
                FeedStore.fieldErrors[field.uniqueId],
            })}
            icon="times"
            variant="flat"
            onClick={handleResetClick}
          />
        </Tooltip>
      )}
    </div>
  )
}

export default observer(CodeEditor)
