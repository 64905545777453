import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll() {
    const target = `/audit`

    return fetch(target)
  },

  async getById(id, options) {
    const queryString = buildQueryString(options)

    return fetch(`/audit/${id}?${queryString}`)
  },
}
