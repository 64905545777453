import { cloneDeep, isString, orderBy } from 'lodash'
import { makeAutoObservable, toJS } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import Filter from '../../models/Filter'
import { delay } from '../../utils'

class FilterStore {
  filters = []
  fields = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filterDetail = {}
  filter = {}
  errors = {}
  state = 'pending' // "pending", "done" or "error"
  isFetchingDetail = false
  isDirty = false
  sortModalVisible = false
  sortOptions = {}

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchFilters()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchFilters()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchFilters()
  }

  getById(id) {
    return this.filters.find((s) => s.id == id)
  }

  *fetchFields() {
    this.state = 'pending'
    try {
      const { data } = yield api.filters.getFields()

      this.fields = data.filter((f) => f.type !== 'boolean')
      this.state = 'done'
      return data
    } catch (error) {
      toast.error('Something went wrong loading the fields.')
      this.state = 'error'
    }
  }

  *fetchFilters() {
    this.state = 'pending'
    try {
      const { data, total } = yield api.filters.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.filters = data
      this.pagination.total = total
      this.state = 'done'
      return data
    } catch (error) {
      toast.error('Something went wrong loading the filter listing.')
      this.state = 'error'
    }
  }

  *fetchFilterDetails(id) {
    this.sortModalVisible = false
    this.errors = {}
    this.filterDetail = {}
    this.isFetchingDetail = true
    if (id === 'new') {
      yield delay(300)
      this.filterDetail = new Filter()
      this.isFetchingDetail = false
      this.state = 'done'
      return
    }
    try {
      const { data } = yield api.filters.get(id)

      if (isString(data.title) || !data.title) {
        data.title = {}
      }
      if (data.valueSorting?.length > 0) {
        data.valueSorting = orderBy(data.valueSorting, 'position', 'asc')
      }

      this.filterDetail = data
      this.state = 'done'
      this.isFetchingDetail = false
      this.fetchValues(true)

      return data
    } catch (error) {
      toast.error('Something went wrong loading the filter.')
      this.state = 'error'
    }
  }

  *updateOrCreate(stream) {
    stream = cloneDeep(toJS(stream || this.filterDetail))
    if (!stream.sorting) {
      stream.sorting = 'term'
    }
    try {
      if (stream.id) {
        const { data } = yield api.filters.update(stream)
        data.dependsOn = data.dependsOn?.id || null
        this.filterDetail = data
        toast.success('Filter updated successfully!')
        this.isDirty = false
      } else {
        const { data } = yield api.filters.create(stream)
        data.dependsOn = data.dependsOn?.id || null
        this.filterDetail = data
        toast.success('Filter created successfully!')
        this.isDirty = false
        return data.id
      }
    } catch (error) {
      this.isDirty = false
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *delete(id) {
    try {
      yield api.filters.delete(id)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchFilters()
  }

  onChangeFilter(filter, key, value) {
    filter[key] = value
    this.isDirty = true
  }

  resetFilter() {
    this.filterDetail = {}
    this.errors = {}
    this.isDirty = false
  }

  openSortModal() {
    this.sortModalVisible = true
  }

  closeSortModal() {
    this.sortModalVisible = false
  }

  onChangeSortItem(item, key, value) {
    item[key] = value
  }

  setErrors(errors) {
    this.errors = errors
  }

  *fetchValues(initial) {
    if (initial && this.filterDetail.valueSorting.length > 0) {
      this.sortOptions = cloneDeep(toJS(this.filterDetail.valueSorting))
    }
    this.sortOptions = {}
    try {
      let { data } = yield api.filters.getValues(this.filterDetail)
      Object.keys(data).forEach(
        (lang) =>
          (data[lang] = data[lang].map((value, index) => ({
            ...value,
            position: index + 1,
            id: value.value,
          })))
      )

      this.sortOptions = data
    } catch (error) {
      console.log(error)
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  updatePositionValue(value, newPosition) {
    if (newPosition < 1 || newPosition > 9999) return
    value.position = newPosition
  }
}

export default new FilterStore()
