import { observer } from 'mobx-react-lite'

import LimitLanguage from '../components/LimitLanguage'

import Text from '../../../components/Text'

import { ImporterStore, UIStore } from '../../../stores'
import { PERSISTENCE_LAYER_STATUS } from '../../../stores/ImporterStore'

import t from '../../../utils/translate'

const PersistenceLayerImportPanel = ({ formik }) => {
  return (
    <>
      {PERSISTENCE_LAYER_STATUS.LOADING ===
        ImporterStore.persistenceLayerStatus && (
        <Text>{t('Loading Persistence Layer information…')}</Text>
      )}

      {PERSISTENCE_LAYER_STATUS.UNAVAILABLE ===
        ImporterStore.persistenceLayerStatus && (
        <Text error>
          {t(
            'We do not have any data for this instance stored in the Persistence Layer.'
          )}
        </Text>
      )}

      {PERSISTENCE_LAYER_STATUS.ERROR ===
        ImporterStore.persistenceLayerStatus && (
        <Text error>
          {t(
            'An error occurred while trying to fetch the data from the Persistence Layer.'
          )}
        </Text>
      )}

      <LimitLanguage
        languageList={UIStore.availableLanguages}
        formikProps={formik}
      />
    </>
  )
}

export default observer(PersistenceLayerImportPanel)
