import { observer } from 'mobx-react-lite'
import React from 'react'
import Text from '../../../components/Text'
import { ComponentStore } from '../../../stores'
import { t } from '../../../utils'
import { SectionInput } from '../../PageEditor/EditorModal'

const Preview = ({ middleSection }) => {
  const fieldsTree = ComponentStore.getFieldsTree()

  if (middleSection !== 'preview') return null

  return (
    <div className="component-editor__preview">
      {fieldsTree.length ? (
        <SectionInput sections={fieldsTree} />
      ) : (
        <Text className="component-editor__preview__placeholder" element="div">
          {t('No fields created yet.')}
        </Text>
      )}
    </div>
  )
}

export default observer(Preview)
