import { makeAutoObservable } from 'mobx'
import api from '../../api'
import { CATEGORY_CATID } from '../../views/Recommendations-v3/constants'

class FieldStore {
  fields = []
  fieldsByIds = {}
  fieldsWithoutExcluded = []
  state = 'pending' // "pending", "done" or "error"

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetchFields(datatype) {
    /**
     * Fetching all fields from ElasticSearch is an expensive operation.
     * Since fields are not changing frequently it's enough to fetch them once.
     */

    this.state = 'pending'

    try {
      const { data } = yield api.fields.getAggregationByType(datatype)

      const dataWithCategory = [...data, CATEGORY_CATID]

      this.fields = dataWithCategory
      this.fieldsByIds = dataWithCategory.reduce((acc, field) => {
        acc[field.id] = { ...field }
        return acc
      }, {})

      this.state = 'done'
    } catch (error) {
      this.fields = []
      console.error(error)
      this.state = 'error'
    }
    // }
  }
  /**
   * Fetch all fields but excluded specific types.
   * Required e.g. for the public fields.
   *
   * @param excluded field types that will not be returned
   */
  *fetchFieldsWithoutExcluded(excluded = '') {
    /**
     * Fetching all fields from ElasticSearch is an expensive operation.
     * Since fields are not changing frequently it's enough to fetch them once.
     */
    if (this.fieldsWithoutExcluded.length == 0) {
      this.state = 'pending'
      try {
        const { data } = yield api.fields.getWithoutExcluded(excluded)

        this.fieldsWithoutExcluded = data
        this.state = 'done'
      } catch (error) {
        console.error(error)
        this.state = 'error'
      }
    }
  }

  get fieldOptions() {
    return this.fields.map((field) => ({ value: field.id, label: field.title }))
  }

  get streamFields() {
    const supportedFieldTypes = [
      'text',
      'keyword',
      'string',
      'nested',
      'long',
      'double',
      'boolean',
      'date',
      'integer',
      'float',
    ]

    return this.fields.filter((field) =>
      supportedFieldTypes.includes(field.type)
    )
  }

  get filtersFields() {
    const supportedFieldTypes = [
      'script',
      'category',
      'nested',
      'double',
      'long',
      'date',
      'text',
      'boolean',
    ]

    const filteredFields = this.fields.filter((field) =>
      supportedFieldTypes.includes(field.type)
    )
    return filteredFields
  }

  get suggestFields() {
    return this.fields.filter((field) => field.type == 'text')
  }

  get bundleConnectionFields() {
    const supportedFieldTypes = [
      'text',
      'keyword',
      'string',
      'nested',
      'long',
      'double',
      'date',
      'integer',
      'float',
    ]

    const filteredFields = this.fields.filter((field) =>
      supportedFieldTypes.includes(field.type)
    )
    return filteredFields
  }
}

export default new FieldStore()
