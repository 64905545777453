import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Button from '../Button'
import Icon from '../Icon'
import SliderInput from '../SliderInput'
import Text from '../Text'
import { CategoryStore, FieldStore, FilterSectionStore } from '../../stores'
import { ICON_TYPE } from './constants'
import { getOperator } from './utils'
import { t } from '../../utils'
import { BOOSTING_VALUE } from './Modal/BoostingField'
import EmptyTable from '../Table/EmptyTable'

const FilterList = ({
  onDelete,
  onEdit,
  onCreate,
  createActionText,
  type,
  disableEdit,
}) => {
  const { manufacturersById, listFilter, config } = FilterSectionStore
  const { categoriesById } = CategoryStore

  const list = listFilter[type]
  const required = config[type]?.required
  const hasPlaceholder = config[type]?.hasPlaceholder

  const handleValueField = (filter) => {
    if (filter.field === 'manufacturerid') {
      return manufacturersById[filter.value]?.name
    }
    if (filter.field === 'category.catid') {
      return categoriesById[filter.value]?.name
    }
    if (filter.operator === 'between') {
      return (filter.value[0] || 0) + ' - ' + (filter.value[1] || 0)
    }
    if (filter.compareWith === 'inputProduct') {
      return FieldStore.fieldsByIds[filter.value]?.title
    }
    if (filter.operator === 'within') {
      return `${filter.value[0]} ${filter.value[1]} ${
        filter.value[1] > 1 ? 'days' : 'day'
      }`
    }
    if (filter.type === 'date') {
      return filter.value[0]
    }

    return filter.value
  }

  if (!list?.length && !hasPlaceholder) {
    return null
  }

  return (
    <>
      <div className="filter-section__filter-list">
        {list?.length ? (
          list.map((filterItem) => {
            const { label } = getOperator({
              type: filterItem.type,
              operator: filterItem.operator,
            })

            const title = FieldStore.fieldsByIds[filterItem.field]?.title
            const value = handleValueField(filterItem)

            return (
              <div
                className={classNames('filter-section__filter-item', {
                  'filter-section__filter-item--boosting': type === 'boosting',
                })}
                key={filterItem.uuid}
              >
                <div className="filter-section__filter-item-infor">
                  <div title={title} className="filter-section__filter-field">
                    <Icon
                      symbol={ICON_TYPE[filterItem.type] || 'question_solid'}
                    />
                    <Text>{title}</Text>
                  </div>
                  <Text
                    element="div"
                    className="filter-section__filter-operator"
                  >
                    {label}
                  </Text>
                  {value && (
                    <div title={value} className="filter-section__filter-field">
                      {filterItem.compareWith === 'inputProduct' && (
                        <Icon
                          symbol={
                            ICON_TYPE[filterItem.type] || 'question_solid'
                          }
                        />
                      )}
                      <Text>{value}</Text>
                    </div>
                  )}
                  {type === 'boosting' && (
                    <div className="filter-section__filter-boosting">
                      <SliderInput
                        disabled
                        max={3}
                        min={1}
                        value={
                          BOOSTING_VALUE.indexOf(filterItem.boostingStrength) +
                          1
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="filter-section__filter-actions">
                  <Button
                    onClick={() => onDelete && onDelete(filterItem.uuid)}
                    icon="trash"
                    disabled={disableEdit}
                  />
                  <Button
                    onClick={() => onEdit && onEdit(filterItem)}
                    level={1}
                    variant="reduced"
                    icon="chevron-right"
                    disabled={disableEdit}
                  />
                </div>
              </div>
            )
          })
        ) : (
          <EmptyTable
            emptyText={
              required
                ? t('Add at least one condition') + ' ...'
                : t('Optional')
            }
            createAction={onCreate}
            emptyContainerSize="extreme-small"
            createActionText={createActionText}
            disabledCreateButton={disableEdit}
          />
        )}
      </div>
      {list?.length > 0 && (
        <div>
          <Button
            onClick={onCreate}
            variant="tertiary"
            icon="plus"
            iconPosition="left"
            disabled={disableEdit}
          >
            {createActionText}
          </Button>
        </div>
      )}
    </>
  )
}

export default observer(FilterList)
