import isObject from 'lodash/isObject'
import get from 'lodash/get'

export default function getContentFromComponent(
  field,
  content,
  typeCheck = []
) {
  if (typeCheck.includes(field.type)) {
    return get(content, [...field.id.split('.')], '')
  }

  if (field.type === 'file') {
    const data = get(content, [...field.id.split('.')])
    if (isObject(data)) {
      return get(content, [...field.id.split('.')], {}).fileName
    }

    return get(content, [...field.id.split('.')], '')
  }

  if (field.type === 'hotspot') {
    const newContent = get(content, [...field.id.split('.'), 'spots'], []).map(
      (item) => {
        if (field.properties.length) {
          return item[field.properties[0].id]
        }
        return item
      }
    )

    if (newContent.length === 0) return false

    if (field.properties.length) {
      return getContentFromComponent(
        {
          type: 'array',
          childConfig: field.properties[0],
          id: field.properties[0].id,
        },
        { [field.properties[0].id]: newContent },
        typeCheck
      )
    }

    return false
  }

  if (field.type === 'array') {
    let newContent = get(content, [...field.id.split('.')])
    newContent = Array.isArray(newContent) ? newContent : []
    return newContent.map((_, index) =>
      getContentFromComponent(
        { ...field.childConfig, id: index.toString() },
        newContent,
        typeCheck
      )
    )
  }

  if (field.type === 'object') {
    if (
      !field.properties ||
      (field.properties && field.properties.length === 0)
    )
      return false

    return field.properties.map((childField) =>
      getContentFromComponent(
        { ...childField, id: `${field.id}.${childField.id}` },
        content,
        typeCheck
      )
    )
  }

  return undefined
}
