import Icon from '../Icon'
import Radio from '../Radio'
import Text from '../Text'

import { t } from '../../utils'
import { types } from './constant'
import { DisplaySearch } from './DisplaySearch'
import { DisplayDataType } from './DisplayDataType'
import classNames from 'classnames'

import './styles.styl'

function DisplaySection({
  onChange = () => {},
  type,
  searchType,
  identifier,
  setPreviewPageUrl = () => {},
  className,
  hideSearchType,
  multiSelectDocs = true,
  language,
}) {
  const onChangeListType = (value) => {
    onChange({
      type: value,
      searchType: null,
      identifier: [],
    })
  }

  return (
    <div className={classNames(className, 'display-section')}>
      <Text size="echo" weight="bold">
        {t('Display')}
      </Text>
      <div className="section__row display-section__container">
        <div className="display-column">
          <div className="radio-group">
            <Radio.Group value={type} onChange={onChangeListType}>
              <label className="radio-group--label">{t('Where?')}</label>
              {types.map((item) => {
                return (
                  <Radio
                    name="listType"
                    label={t(item.name)}
                    value={item.id}
                    key={item.id}
                  ></Radio>
                )
              })}
            </Radio.Group>
          </div>
        </div>
        <div className="display-column column--center">
          <Icon symbol="arrow-right" className="display-icon-moving" />
        </div>
        <DisplaySearch
          onChange={onChange}
          type={type}
          searchType={searchType}
          identifier={identifier}
          hideSearchType={hideSearchType}
        />
        <DisplayDataType
          onChange={onChange}
          type={type}
          searchType={searchType}
          identifier={identifier}
          setPreviewPageUrl={setPreviewPageUrl}
          multiSelectDocs={multiSelectDocs}
          language={language}
        />
      </div>
    </div>
  )
}

export default DisplaySection
