import { makeAutoObservable, toJS } from 'mobx'
import { toast } from 'react-toastify'
import { notification } from 'antd'
import api from '../../api'
import { v4 as uuidv4 } from 'uuid'
import { t } from '../../utils'
import moment from 'moment'
import get from 'lodash/get'
import last from 'lodash/last'

class StorefrontSettingStore {
  deployments = {}
  isFetchingDeployments = false
  state = 'pending' // "pending", "done" or "error"
  outputStreams = {}
  originConfigVars = {}
  configVars = {}
  variables = []
  newUsers = []
  branches = {}
  domains = []
  newDomains = []
  authenticationModalVisible = false
  isUpdatingAutoDeployBranch = false
  isTriggeringDeploy = false
  isRefreshingACM = false
  branchInput = ''
  isDirty = false
  error = {}
  logs = {}
  logFilters = {
    limit: 50,
    offset: 0,
  }
  isFetchingLogs = true

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetchDeployments() {
    try {
      const { data } = yield api.storefrontSettings.getDeployments()

      this.deployments = data
      this.state = 'done'

      return data
    } catch (error) {
      try {
        this.error = JSON.parse(error.message)
        this.state = 'error'
        return JSON.parse(error.message)
      } catch (e) {
        console.error(e.message)
      }
      toast.error('Something went wrong loading the deployments.')
      this.state = 'error'
    }
  }

  updateOutputStream(build, text) {
    this.outputStreams[build.created_at + build.commit] += text
  }

  fetchOutputStream(build) {
    const outputStream = this.outputStreams[build.created_at + build.commit]
    if (!outputStream) {
      this.outputStreams[build.created_at + build.commit] = t('loading')
      fetch(build.output_stream_url).then((response) => {
        this.outputStreams[build.created_at + build.commit] = ''
        const reader = response.body.getReader()
        const start = (controller) => {
          const pump = async () => {
            const { done, value } = await reader.read()
            // When no more data needs to be consumed, close the stream
            if (done) {
              controller.close()
              if (build.build_state === 'pending') {
                this.fetchDeployments()
              }
              return
            }

            const uintToString = (uintArray) => {
              let encodedString = String.fromCharCode.apply(null, uintArray)
              return decodeURIComponent(escape(encodedString))
            }
            // update the next data chunk into our output
            this.updateOutputStream(build, uintToString(value))
            controller.enqueue(value)
            return pump()
          }
          return pump()
        }
        return new ReadableStream({
          start,
        })
      })
    }
  }

  *fetchBranches() {
    try {
      const { data } = yield api.storefrontSettings.getBranches()
      this.branches = data
      this.branchInput = data.auto_deploy_branch
    } catch (error) {
      toast.error('Something went wrong loading the branches.')
    }
  }

  *fetchConfigVars() {
    try {
      const { data } = yield api.storefrontSettings.getConfigVars()

      this.configVars = data
      this.originConfigVars = data
      this.variables = Object.entries(data.variables || {}).map((value) => ({
        name: value[0],
        value: value[1],
        id: uuidv4(),
      }))
    } catch (error) {
      toast.error('Something went wrong loading the config variables.')
    }
  }

  updateVariable(variable, key, value) {
    variable[key] = value
    if (key === 'name') {
      if (!value) {
        variable.error = ''
      } else if (value.startsWith('__')) {
        variable.error = t('should not begin with a double underscore')
      } else if (/\W+/g.test(value)) {
        variable.error = t(
          'only alphanumeric characters and the underscore are allowed'
        )
      } else {
        variable.error = false
      }
    }
    this.isDirty = true
  }

  addNewVariable() {
    this.variables.push({ name: '', value: '', id: uuidv4() })
    this.isDirty = true
  }

  removeVariable(id) {
    this.variables = this.variables.filter((variable) => variable.id !== id)
    this.isDirty = true
  }

  addNewUser() {
    this.newUsers.push({
      username: '',
      password: '',
      id: uuidv4(),
      error: {
        username: false,
        password: false,
      },
    })
    this.isDirty = true
  }

  updateUser(user, key, value) {
    user[key] = value
    user.error = this.checkValidUser(user)

    this.isDirty = true
  }

  checkValidUser(user) {
    let error = {}
    if (user.username === '') {
      error.username = t('username is required')
    } else if (/[^a-zA-Z0-9]/g.test(user.username)) {
      error.username = t('only alphanumeric characters are allowed')
    } else {
      error.username = false
    }
    if (user.password === '') {
      error.password = t('password is required')
    } else {
      error.password = false
    }

    return error
  }

  removeUser(user, isLocal) {
    if (isLocal) {
      this.newUsers = this.newUsers.filter((u) => u.id !== user.id)
    } else {
      this.configVars.users = this.configVars.users.filter(
        (u) => u.username !== user.username
      )
    }
    this.isDirty = true
  }

  *updateConfigVars() {
    let variables = {}
    this.variables.forEach(
      (variable) => (variables[variable.name] = variable.value)
    )
    const newUsersNoId = this.newUsers.map(({ username, password }) => ({
      username,
      password,
    }))

    try {
      const { data } = yield api.storefrontSettings.updateConfigVars({
        ...this.configVars,
        variables,
        users: Array.isArray(this.configVars.users)
          ? [...this.configVars.users, ...newUsersNoId]
          : newUsersNoId,
      })

      this.configVars = data
      this.originConfigVars = data
      this.variables = Object.entries(data.variables || {}).map((value) => ({
        name: value[0],
        value: value[1],
        id: uuidv4(),
      }))
      this.newUsers = []

      this.fetchDeployments()
      notification.info({
        message: t('A new build was triggered'),
        description: t(
          'Your changes will be in effect after the build has finished successfully!'
        ),
        placement: 'center',
        duration: 5,
      })

      this.isDirty = false
    } catch (error) {
      throw new Error(error)
    }
  }

  get isValidConfigVars() {
    return this.variables.every((value) => !value.error) && this.isValidUsers
  }

  get isValidUsers() {
    return this.newUsers.every(
      (user) =>
        user.username &&
        user.password &&
        !user.error.username &&
        !user.error.password
    )
  }

  setDirty(isDirty) {
    this.isDirty = isDirty
  }

  openAuthenticationModal() {
    this.authenticationModalVisible = true
  }

  closeAuthenticationModal() {
    this.authenticationModalVisible = false
  }

  removeAuthentication() {
    this.newUsers = []
    this.configVars.users = []
    this.isDirty = true
  }

  *updateAutoDeployBranch() {
    if (this.branchInput === this.branches.auto_deploy_branch) return

    this.isUpdatingAutoDeployBranch = true
    try {
      yield api.storefrontSettings.updateAutoDeployBranch(this.branchInput)
      yield this.fetchBranches()
      this.isUpdatingAutoDeployBranch = false
    } catch (error) {
      this.branchInput = this.branches.auto_deploy_branch
      this.isUpdatingAutoDeployBranch = false
      toast.error('Something went wrong updating auto deploy branch.')
    }
  }

  updateBranchInput(value) {
    this.branchInput = value
  }

  *triggerDeploy() {
    this.isTriggeringDeploy = true
    try {
      yield api.storefrontSettings.triggerDeploy()
      this.fetchDeployments()
      this.isTriggeringDeploy = false
    } catch (error) {
      this.isTriggeringDeploy = false
      toast.error('Something went wrong triggering deploy')
    }
  }

  *fetchDomains() {
    try {
      const { data } = yield api.storefrontSettings.getDomains()
      this.domains = data
    } catch (error) {
      toast.error('Something went wrong loading the domains')
    }
  }

  addNewDomain() {
    this.newDomains.push({ id: uuidv4(), hostname: '', step: 1 })
  }

  updateDomain(domain, key, value) {
    domain[key] = value
    this.checkValidDomain(domain)
  }

  checkValidDomain(domain) {
    if (domain.hostname === '') {
      domain.error = t('hostname is required')
    } else if (!/(.\.)+./g.test(domain.hostname)) {
      domain.error = t('please enter a valid domain')
    } else if (/[^a-z.0-9-]+/g.test(domain.hostname)) {
      domain.error = t(
        'only lowercase letters, dots, numbers and hyphen (-) are allowed'
      )
    } else {
      domain.error = false
    }
  }

  *postDomain(domain) {
    try {
      yield api.storefrontSettings.postDomain(domain.hostname)
      // requirement: wait for 2 minutes because it take time
      yield new Promise((resolve) => setTimeout(resolve, 1000 * 60 * 2))
      yield this.fetchDomains()
      this.updateDomain(domain, 'step', 4)
    } catch (error) {
      this.updateDomain(domain, 'step', 4)
      toast.error('Something went wrong adding the domains')
    }
  }

  *deleteDomain(domain) {
    this.updateDomain(domain, 'loading', true)
    try {
      yield api.storefrontSettings.deleteDomain(domain.hostname)
      yield this.fetchDomains()
      toast.success(t('Domain has been successfully removed.'))
    } catch (error) {
      this.updateDomain(domain, 'loading', false)
      toast.error('Something went wrong adding the domains')
    }
  }

  getDomainByHostname(hostname) {
    return this.domains.find((d) => d.hostname === hostname) || {}
  }

  *refreshACM(domain) {
    this.isRefreshingACM = true
    try {
      yield api.storefrontSettings.refreshACM()
      // requirement: wait for 1 minutes because refreshACM take time
      yield new Promise((resolve) => setTimeout(resolve, 1000 * 60))

      yield this.fetchDomains()
      const domainInfo = this.getDomainByHostname(domain.hostname)
      // wait for 2 second for user can see before remove it
      if (domainInfo.acm_status === 'cert issued') {
        window.setTimeout(() => {
          this.newDomains = this.newDomains.filter(
            (d) => d.hostname !== domain.hostname
          )
        }, 2000)
      }
      this.isRefreshingACM = false
    } catch (error) {
      this.isRefreshingACM = false
      toast.error('Something went wrong refreshing the domains')
    }
  }

  resetConfigVars() {
    this.isDirty = false
    const originData = toJS(this.originConfigVars)
    this.configVars = originData
    this.variables = Object.entries(originData.variables || {}).map(
      (value) => ({
        name: value[0],
        value: value[1],
        id: uuidv4(),
      })
    )
    this.newUsers = []
  }

  *fetchLogs(init, isLoadMore) {
    init = init || false
    isLoadMore = isLoadMore || false

    this.isFetchingLogs = init
    try {
      const { data } = yield api.storefrontSettings.getLogs({
        params: this.logFilters,
      })
      if (isLoadMore) {
        this.logs.total = data.total
        this.logs.messages = [...this.logs.messages, ...data.messages]
      } else {
        this.logs = data
      }
      this.isFetchingLogs = false
    } catch (error) {
      this.isFetchingLogs = false
      toast.error('Something went wrong fetching the logs.')
    }
  }

  setLogParams(filter) {
    this.logFilters = {
      ...this.logFilters,
      ...filter,
      limit: 50,
      offset: 0,
    }

    // remove empty params
    for (var propName in this.logFilters) {
      if (this.logFilters[propName] === '') {
        delete this.logFilters[propName]
      }
    }

    this.fetchLogs()
  }

  *fetchMoreLogs() {
    try {
      const before = moment(get(last(this.logs.messages), 'timestamp'))
        .subtract(1, 'seconds')
        .toISOString()

      const { data } = yield api.storefrontSettings.getLogs({
        params: { ...this.logFilters, before },
      })

      this.logs.total = data.total
      this.logs.messages = [...this.logs.messages, ...data.messages]
    } catch (error) {
      toast.error('Something went wrong fetching the logs.')
    }
  }
}

export default new StorefrontSettingStore()
