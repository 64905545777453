import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { t, createRedirectPath, debounce } from '../../utils'
import { routes } from '../../routing'
import PageTitle from '../../components/PageTitle'
import Select from '../../components/Select'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import TagsInput from '../../components/TagsInput'
import LoadingScreen from '../../components/LoadingScreen'
import ContentWrapper from '../../components/ContentWrapper'
import { SearchRedirectStore, UIStore, ActionLayerStore } from '../../stores'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getSearchRedirectsFormSchemaV3 } from './validate'
import SearchRedirect from '../../models/SearchRedirect'

import './searchRedirects.styl'

const SearchRedirectForm = () => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const { shopId, currentLanguage, languages, enterpriseConfiguration } =
    UIStore
  const [searchOptions, setSearchOptions] = useState([])
  const [formValues, setFormValues] = useState(new SearchRedirect(languages))
  const [errors, setErrors] = useState({})

  const editing = id !== 'new'

  const removeFormError = (key) => {
    setErrors((err) => ({
      ...err,
      [key]: undefined,
    }))
  }

  const setFormValue = (key, value, mergeObj = false) => {
    setDirty(true)
    setFormValues((values) => {
      return {
        ...values,
        [key]:
          mergeObj && typeof value === 'object'
            ? Object.assign({}, values[key], value)
            : value,
      }
    })
  }

  const {
    isDirty,
    updating,
    searchTypes,
    fetchSearchTypes,
    searchDocuments,
    update,
    create,
    fetchRedirectsById,
    setDirty,
  } = SearchRedirectStore

  useEffect(() => {
    if (editing) {
      fetchRedirectsById(id).then((data) => {
        if (data.type !== 'url') {
          searchDocuments(
            data.type,
            data.documentId,
            currentLanguage,
            shopId
          ).then((r) => {
            setSearchOptions(r)
          })
        }
        setFormValues({
          id: data.id,
          type: data.type,
          documentId: data.documentId,
          targetUrl:
            Array.isArray(data.targetUrl) && data.targetUrl.length
              ? {}
              : data.targetUrl,
          words: data.words,
        })
      })
    } else if (location.state?.searchTerm) {
      setFormValues(new SearchRedirect(languages, location.state))
      setDirty(true)
    }
    fetchSearchTypes()

    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        saveTitle: t('Save & back to list'),
        closeTitle: t('Abort & back to list'),
        onSaveWithContinue: () => handleSave('continue'),
        onSave: () => handleSave('back'),
        onClose: () => {
          setDirty(false)
          history.push(createRedirectPath(routes.searchRedirectList.path))
        },
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty, formValues])

  const onDataTypeChange = (value) => {
    if (value === 'url') {
      languages.forEach((l) => {
        if (updating?.targetUrl?.[l]) {
          setFormValue('targetUrl', { [l]: updating.targetUrl[l] }, true)
        }
      })
    } else {
      setFormValue('targetUrl', {})
      if (value !== updating.type) {
        setFormValue('documentId', '')
        setSearchOptions([])
      } else {
        searchDocuments(
          value,
          updating.documentId,
          currentLanguage,
          shopId
        ).then((r) => {
          setFormValue('documentId', updating.documentId)
          setSearchOptions(r)
        })
      }
    }
    setFormValue('type', value)
  }

  const validateForm = (values) => {
    try {
      const validData = getSearchRedirectsFormSchemaV3(languages).validateSync(
        values,
        { abortEarly: false }
      )
      setErrors({})
      return validData
    } catch (err) {
      if (err.errors && Array.isArray(err.errors) && err.errors.length > 0) {
        const errors = {}
        for (const innerError of err.inner) {
          errors[innerError.path] = innerError.message
        }
        setErrors(errors)
      }
      return null
    }
  }

  const handleSave = async (action) => {
    const body = validateForm(formValues)
    if (!body) {
      return false
    }
    const result = await (editing ? update : create)(body)
    if (result && result.id) {
      switch (action) {
        case 'continue':
          history.replace(
            createRedirectPath(routes.searchRedirectList.path + `/${result.id}`)
          )
          break
        case 'back':
          history.push(createRedirectPath(routes.searchRedirectList.path))
          break
      }
    }
    setDirty(false)
  }

  const onSearch = debounce(async (value) => {
    const result = await searchDocuments(
      formValues.type,
      value,
      ['de', 'en'].includes(enterpriseConfiguration.defaultLanguage)
        ? currentLanguage
        : enterpriseConfiguration.defaultLanguage,
      shopId
    )

    setSearchOptions(result)
    return result
  }, 500)

  if (!searchTypes?.length || (editing && !formValues.id)) {
    return <LoadingScreen />
  }

  return (
    <>
      <PageTitle
        prefix={editing ? t('You are editing') : t('You are creating')}
        metaInfo={
          editing && {
            timestamp: updating.changed?.date,
            timezone: updating.changed?.timezone,
            user: updating.user,
            id: updating.id,
          }
        }
        hiddenFields={['internal-title']}
      >
        {editing ? t('Redirect') + ` (${updating.id})` : t('New Redirect')}
      </PageTitle>
      <ContentWrapper>
        <div className="search-redirect-edit">
          <div className="search-redirect-edit__destination">
            <Text
              size="echo"
              weight="bold"
              element="h3"
              className="search-redirect-edit__title"
            >
              {t('Destination')}
            </Text>
            <div className={`destination__inputs ${formValues.type}`}>
              <Select
                className="destination__select"
                title={t('Type of destination')}
                value={formValues.type}
                options={[
                  ...searchTypes.map((item) => ({
                    value: item.id,
                    label: t(item.title),
                  })),
                ]}
                onChange={onDataTypeChange}
              />
              {formValues.type === 'url' ? (
                languages.map((l) => (
                  <TextInput
                    key={l}
                    className="destination__link"
                    label={l.toString().toUpperCase()}
                    error={errors[`targetUrl.${l}`]}
                    value={formValues.targetUrl?.[l]}
                    onChange={(e) => {
                      setFormValue('targetUrl', { [l]: e.target.value }, true)
                      removeFormError(`targetUrl.${l}`)
                    }}
                  />
                ))
              ) : (
                <Select
                  placeholder={t('Search for ...')}
                  allowClear
                  className="destination__link"
                  title={t('Choosen destination')}
                  showSearch
                  onSearch={onSearch}
                  options={searchOptions.map((option) => ({
                    ...option,
                    value: option?.value?.toString(),
                  }))}
                  filterOption={false}
                  error={errors.documentId}
                  value={formValues.documentId}
                  onChange={(v) => {
                    setFormValue('documentId', v)
                    removeFormError('documentId')
                  }}
                />
              )}
            </div>
          </div>
          <div className="search-redirect-edit__search-terms">
            <Text
              size="echo"
              weight="bold"
              element="h3"
              className="search-redirect-edit__title"
            >
              {t('Search terms')}
            </Text>
            <Text
              variant="book"
              element="p"
              className="search-redirect-edit__text"
            >
              {t(
                'Add all the words for this redirect, separate them by using a comma or a semicolon.'
              )}
            </Text>
            <div className="search-terms__inputs">
              {languages.map((l) => (
                <TagsInput
                  key={l}
                  title={l.toString().toUpperCase()}
                  error={errors[`words.${l}`]}
                  tokenSeparators={[';', ',']}
                  onChange={(v) => {
                    setFormValue('words', { [l]: v }, true)
                    removeFormError(`words.${l}`)
                  }}
                  value={formValues.words[l]}
                />
              ))}
            </div>
          </div>
        </div>
      </ContentWrapper>
    </>
  )
}

export default observer(SearchRedirectForm)
