import moment from 'moment'
import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import LogView from '../../components/LogView/LogView'
import StoreFrontPageTitle from './components/StoreFrontPageTitle'

import t from '../../utils/translate'
import StorefrontSettingStore from '../../stores/StorefrontSettingStore'

function Logs() {
  const filters = useMemo(
    () => [
      {
        type: 'single-select',
        title: t('System'),
        placeholder: t('Select system'),
        name: 'system',
        options: [
          { label: t('All'), value: undefined },
          { label: 'App', value: 'app' },
          { label: t('Platform as a Service (PaaS)'), value: 'paas' },
        ],
        value: '',
        minWidth: '23rem',
        position: 1,
      },
    ],
    [] // eslint-disable-line
  )

  const columns = [
    {
      title: 'Timestamp',
      key: 'timestamp',
      format: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'System',
      key: 'system',
    },
    {
      title: 'Message',
      key: 'message',
    },
  ]

  return (
    <>
      <StoreFrontPageTitle />

      <div className="content-wrapper storefront-settings">
        <LogView
          filters={filters}
          store={StorefrontSettingStore}
          columns={columns}
          generateRowKey={(row) => row.timestamp + row.system + row.message}
        />
      </div>
    </>
  )
}

export default observer(Logs)
