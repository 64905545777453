import { useDraggable, useDroppable } from '@dnd-kit/core'
import classNames from 'classnames'
import { sortBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import { ComponentStore } from '../../../stores'
import { t } from '../../../utils'
import SortableElementBuilder from './SortableElementBuilder'

const FormBuilder = ({ middleSection }) => {
  const { flattenFields } = ComponentStore
  const sections = sortBy(
    flattenFields.filter((field) => field.type === 'section'),
    'position'
  )

  if (middleSection !== 'form-builder') return null

  return (
    <>
      {sections.map((section) => (
        <Folder key={section.label} section={section} />
      ))}
      <Folder section={{ uuid: 'placeholder' }} />
    </>
  )
}

const Folder = observer(({ section }) => {
  const { flattenFields } = ComponentStore
  const fields = sortBy(
    flattenFields.filter((field) => field.parentId === section.uuid),
    'position'
  )
  const { listeners, setNodeRef, active } = useDraggable({
    id: section.uuid,
    data: {
      type: 'form-section',
      field: section,
      sectionId: section.id,
    },
  })
  const folderDropable = useDroppable({
    id: `dropable--${section.uuid}--${fields.length}`,
    disabled:
      (section.uuid !== 'placeholder' &&
        active?.data?.current?.type === 'section') ||
      (section.uuid === 'placeholder' &&
        active?.data?.current?.type === 'form-section') ||
      active?.id === section.uuid,
  })

  const activeField = ComponentStore.getFieldByUUID(active?.id)

  return (
    <>
      <div
        className={classNames('component-editor__placeholder-element', {
          'component-editor__placeholder-element--is-over':
            folderDropable.isOver &&
            activeField?.position > section?.position &&
            active?.data?.current?.type === 'form-section',
        })}
      />
      <div
        onClick={(e) => {
          e.stopPropagation()
          section.uuid !== 'placeholder' &&
            ComponentStore.setActiveField(section.uuid)
        }}
        className="component-editor__forn-builder"
        ref={section.uuid !== 'placeholder' ? setNodeRef : null}
        {...listeners}
      >
        <div
          ref={folderDropable.setNodeRef}
          className={classNames('component-editor__folder', {
            'component-editor__element-builder--active':
              section.uuid !== 'placeholder' &&
              section.uuid === ComponentStore.activeFieldId,
            'component-editor__folder--placeholder':
              section.uuid === 'placeholder',
          })}
        >
          <Icon width="15px" height="15px" symbol="folder__regular" />
          {section.uuid !== 'placeholder' && (
            <Button
              className="component-editor__remove-section-btn"
              icon="trash"
              variant="tertiary"
              tooltip={t('Remove section')}
              onClick={() => ComponentStore.removeElement(section)}
            />
          )}
          {fields.length === 0 && (
            <div
              className={classNames('component-editor__element-placeholder', {
                'component-editor__element-placeholder--dragging-over':
                  folderDropable.isOver,
              })}
            >
              <Text>
                {t(
                  'Drag form elements from the Library on the left into this area'
                )}
              </Text>
              <Text>
                {t('or double click on them to add elements to the form')}
              </Text>
            </div>
          )}
          {fields.length > 0 && (
            <div className="component-editor__element-builder-wrapper">
              {fields.map((field, index) => (
                <SortableElementBuilder
                  key={field.uuid}
                  field={field}
                  lastItem={index === fields.length - 1}
                  lastChildOfSection={fields.length === 1}
                />
              ))}
              <div
                className={classNames('component-editor__placeholder-element', {
                  'component-editor__placeholder-element--is-over':
                    folderDropable.isOver &&
                    active?.data?.current?.type !== 'form-section',
                })}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames('component-editor__placeholder-element', {
          'component-editor__placeholder-element--is-over':
            folderDropable.isOver &&
            activeField?.position < section?.position &&
            active?.data?.current?.type === 'form-section',
        })}
      />
    </>
  )
})

export default observer(FormBuilder)
