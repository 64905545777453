import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { t } from '../../utils'
import api from '../../api'

class NotificationStore {
  notifications = []
  unreadNotifications = []
  urgentNotifications = []
  pagination = {
    current: 1,
    pageSize: 30,
    total: 0,
  }
  sorter = {
    field: 'timestamp',
    order: 'descend',
  }
  filter = null
  state = 'pending' // "pending", "done" or "error"

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 30,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchNotifications()
  }

  *fetchNotifications(reset) {
    reset = reset || false

    if (reset) {
      this.reset()
    }

    this.state = 'pending'
    try {
      const { data, total } = yield api.notifications.getAll({
        pagination: this.pagination,
        sorter: this.sorter,
      })
      this.notifications = data
      this.pagination.total = total
      this.state = 'done'
    } catch (e) {
      toast.error(t('Something went wrong fetching notifications'))
      this.state = 'error'
    }
  }

  *fetchUnreadNotifications() {
    try {
      let { data } = yield api.notifications.getAll({
        params: { unread: true, _order: 'desc' },
      })
      this.unreadNotifications = data
      this.urgentNotifications = data.filter((noti) => noti.show_immediately)
    } catch (e) {
      toast.error(t('Something went wrong fetching notifications'))
    }
  }

  *markReadAllNotifications() {
    this.state = 'pending'
    try {
      const { data } = yield api.notifications.postReadAll()
      this.state = 'done'
      return data
    } catch (e) {
      toast.error(t('Something went wrong fetching notifications'))
      this.state = 'error'
    }
  }
}

export default new NotificationStore()
