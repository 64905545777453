import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import Icon from '../Icon'
import Text from '../Text'
import Button from '../Button'

import './documentInput.styl'

const SortableItem = (props) => {
  const { field, handleRemoveItem } = props
  const { id } = field

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="document-input__product-item"
    >
      <span
        className="document-input__drag-icon"
        {...attributes}
        {...listeners}
      >
        <Icon symbol="bars" />
      </span>
      <Text
        size="bravo"
        element="p"
        title={field.title}
        className="document-input__product-name"
      >
        {field.active === false && (
          <Icon symbol="triangle-exclamation" width="15px" height="15px" />
        )}
        {field.title}
      </Text>
      <Text variant="book" size="bravo">
        {field.subtitle}
      </Text>
      <Button
        onClick={handleRemoveItem}
        className="document-input__remove-btn"
        variant="tertiary"
        icon="trash"
        level={1}
      />
    </div>
  )
}

export default SortableItem
