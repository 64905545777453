import { observer } from 'mobx-react-lite'
import Icon from '../../Icon'
import Select from '../../Select'
import { FilterSectionStore } from '../../../stores'
import { OPERATORS } from '../constants'

function ComparisonField({ type }) {
  const { editingFilter, setEditingFilter } = FilterSectionStore

  const invalidField = ['category.catid', 'manufacturerid'].includes(
    editingFilter.field
  )

  if (!editingFilter.field || invalidField) {
    return null
  }

  let comparisonOptions =
    editingFilter.type in OPERATORS ? OPERATORS[editingFilter.type] : []
  comparisonOptions =
    type === 'boosting'
      ? comparisonOptions
      : comparisonOptions.filter((c) => c.value !== 'useValue')

  return (
    <>
      <Icon width="20px" height="20px" symbol="arrow-down" />
      <Select
        style={{ minWidth: '11.5rem' }}
        translateLabel
        flexible
        dropdownMatchSelectWidth={false}
        options={comparisonOptions}
        value={editingFilter.operator}
        onChange={(value) =>
          setEditingFilter({
            ...editingFilter,
            operator: value,
            compareWith: null,
            value: null,
          })
        }
      />
    </>
  )
}

export default observer(ComparisonField)
