import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import AutoComplete from '../../../components/AutoComplete'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import TextInput from '../../../components/TextInput'
import { FeedStore } from '../../../stores'
import CodeEditor from './CodeEditor'

const FeedField = (
  { listeners, attributes, hidden, isOverlay, field, ...props },
  ref
) => {
  const formikProps = useFormikContext()
  const fieldIndex = formikProps.values.fields.findIndex(
    (f) => f.uniqueId === field.id
  )
  const removeField = () => {
    formikProps.setFieldValue(
      'fields',
      formikProps.values.fields.filter((f) => f.uniqueId !== field.id)
    )
  }
  const setProduct = (optionKey) => {
    const option =
      FeedStore.fieldOptions.find((option) => option.value === optionKey) ?? {}

    const field = get(formikProps, ['values', 'fields', fieldIndex])
    formikProps.setFieldValue(`fields.${fieldIndex}`, {
      ...field,
      id: option.value,
      title: option.label,
    })
  }

  return (
    <div
      {...props}
      ref={ref}
      className={classNames('feeds__structure-row', {
        'feeds__structure-row--csv': FeedStore.feedDetail.contentType === 'csv',
      })}
    >
      <button {...listeners}>
        <Icon symbol="bars" width={15} height={15} />
      </button>
      {FeedStore.feedDetail.contentType === 'csv' && (
        <TextInput
          error={get(formikProps, ['errors', 'fields', fieldIndex, 'column'])}
          value={get(formikProps, ['values', 'fields', fieldIndex, 'column'])}
          onChange={(e) =>
            formikProps.setFieldValue(
              `fields.${fieldIndex}.column`,
              e.target.value
            )
          }
        />
      )}
      <AutoComplete
        error={get(formikProps, ['errors', 'fields', fieldIndex, 'id'])}
        value={get(formikProps, ['values', 'fields', fieldIndex, 'id'])}
        itemKey="label"
        defaultOptions={FeedStore.fieldOptions}
        fullWidth
        allowClear
        onSelect={(option) => setProduct(option)}
      />
      <CodeEditor
        field={field}
        onChange={(value) =>
          formikProps.setFieldValue(`fields.${fieldIndex}.template`, value)
        }
      />
      <Button onClick={removeField} variant="tertiary" icon="trash" level={1} />
    </div>
  )
}

export default observer(FeedField, { forwardRef: true })
