import { makeAutoObservable } from 'mobx'
import moment from 'moment/moment'
import { toast } from 'react-toastify'
import api from '../../api'
import t from '../../utils/translate'
import { ActionLayerStore } from '../index'
import EditorStore from './EditorStore'

class RevisionStore {
  state = 'done'
  revisions = []
  revisionToRestore = null
  isConfirmRestoreModalVisible = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetchRevisions() {
    this.state = 'pending'

    try {
      const { data } = yield api.pages.getRevisions(EditorStore.pageToEdit.id)
      this.revisions = data
    } catch (error) {
      toast.error('Something went wrong...')
      this.state = 'error'
    }
  }

  *restoreRevision(revision) {
    if (revision) {
      this.revisionToRestore = revision

      if (EditorStore.isDirty) {
        this.isConfirmRestoreModalVisible = true
        return
      }
    }

    this.state = 'pending'

    try {
      EditorStore.isDirty = false
      this.isConfirmRestoreModalVisible = false
      EditorStore.pageToEdit = yield api.pages.restoreRevision(
        EditorStore.pageToEdit.id,
        this.revisionToRestore.id
      )

      this.state = 'done'
      toast.success(
        t('revision_restored')(
          moment(this.revisionToRestore.changed.date).format('DD.MM.yyyy HH:mm')
        )
      )
    } catch (error) {
      this.state = 'error'
      this.isConfirmRestoreModalVisible = false
      ActionLayerStore.openActionLayer({
        error: t('Something went wrong restoring the revision of the page.'),
        saveTitle: t('OK'),
        onSave: () => {},
      })
    }
  }

  *createRevision(pageData) {
    this.state = 'pending'

    try {
      yield api.pages.createRevisions(pageData)
    } catch (error) {
      toast.error(t('Revision could not be created.'))
      this.state = 'error'
    }
  }

  closeConfirmRestoreModal() {
    this.isConfirmRestoreModalVisible = false
    this.revisionToRestore = null
  }

  clearRevisions() {
    this.state = 'done'
    this.revisions = []
    this.closeConfirmRestoreModal()
  }
}

export default new RevisionStore()
