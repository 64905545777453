import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import LoadingScreen from '../../components/LoadingScreen'
import PageTitle from '../../components/PageTitle'
import Switch from '../../components/Switch'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import {
  ActionLayerStore,
  BannerStore,
  SearchableLinkStore,
  UIStore,
} from '../../stores'
import { createRedirectPath, formatLanguageKey, t } from '../../utils'

import Checkbox from '../../components/Checkbox'
import './style.styl'
import TagsInput from '../../components/TagsInput'
import Select from '../../components/Select'

const Edit = () => {
  const { id } = useParams()
  const detail = SearchableLinkStore.detail
  const isDirty = SearchableLinkStore.isDirty
  const errors = SearchableLinkStore.errors
  const { shopId, enterpriseConfiguration } = UIStore
  const history = useHistory()
  const [linkType, setLinkType] = useState('link')
  const [searchOptions, setSearchOptions] = useState([])
  const [linkLanguage, setLinkLanguage] = useState(
    enterpriseConfiguration.defaultLanguage
  )

  const fetchSearchData = async (value, newLinkType, newLanguage) => {
    const searchResults = await BannerStore.searchDocuments(
      newLinkType,
      value,
      newLanguage,
      shopId,
      ['url']
    )
    const mapped = searchResults.map((item) => {
      return {
        value: item.url,
        label: `${
          item.title || item.category_title || item.manufacturer_title
        } (${item.url})`,
      }
    })
    setSearchOptions(mapped)
    return mapped
  }

  const onLinkTypeChange = (value) => {
    setSearchOptions([])
    setLinkType(value)
    SearchableLinkStore.onChangeData(detail, 'url', '')
  }

  const onLinkLanguageChange = (value) => {
    setSearchOptions([])
    setLinkLanguage(value)
    SearchableLinkStore.onChangeData(detail, 'url', '')
  }

  useEffect(() => {
    SearchableLinkStore.fetchDetail(id)
    BannerStore.fetchDataTypes()
    // eslint-disable-next-line
  }, [])

  const onSave = async (isStay) => {
    const validData = SearchableLinkStore.onValidateForm()
    if (validData) {
      const newId = await SearchableLinkStore.updateOrCreate(detail)

      // We need to redirect to the correct URL if the page was created
      if (newId && isStay) {
        history.replace(createRedirectPath(`/searchable-link/${newId}`))
        SearchableLinkStore.fetchDetail(newId)
      }
      if (!isStay) {
        history.push(createRedirectPath(`/searchable-link`))
      }
    } else return false
  }

  function handleClose() {
    SearchableLinkStore.setDirty(false)
    history.push(createRedirectPath('/searchable-link'))
  }

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        onSave,
        onClose: handleClose,
        saveTitle: t('Save & back to list'),
        closeTitle: t('Abort & back to list'),
        onSaveWithContinue: async () => onSave(true),
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  const handleLanguages = (lang) => () => {
    let newLanguages = [...(detail.language || [])]
    const isChecked = newLanguages.includes(lang)
    if (isChecked) {
      newLanguages = newLanguages.filter((l) => l !== lang)
    } else {
      newLanguages.push(lang)
    }
    SearchableLinkStore.onChangeData(detail, 'language', newLanguages)
  }

  if (SearchableLinkStore.isFetchingDetail) return <LoadingScreen />

  return (
    <>
      <PageTitle
        prefix={detail.id ? t('You are editing') : t('You are creating')}
        metaInfo={
          detail.id && {
            timestamp: detail.changed?.date,
            timezone: detail.changed?.timezone,
            user: detail.user,
            id: detail.id,
          }
        }
        hiddenFields={['internal-title']}
      >
        {detail.id ? detail.title : t('New searchable link')}
      </PageTitle>
      <div className="content-wrapper searchable-link-edit">
        <div className="searchable-link-edit__section">
          <Text size="echo" weight="bold">
            {t('Destination')}
          </Text>
          <Switch
            checked={detail.isExternal}
            type="horizontal"
            title={t('External link')}
            onChange={(checked) =>
              SearchableLinkStore.onChangeData(detail, 'isExternal', checked)
            }
          />
          <TextInput
            label={t('Title')}
            value={detail.title}
            onChange={(e) =>
              SearchableLinkStore.onChangeData(detail, 'title', e.target.value)
            }
            error={errors.title}
          />
          <Text
            className="searchable-link-edit__checkbox-group-title"
            size="bravo"
          >
            {t('Valid for the following languages')}
          </Text>
          {UIStore.languages.map((language) => (
            <Checkbox
              checked={detail.language?.includes(language)}
              key={language}
              label={language.toUpperCase()}
              onChange={handleLanguages(language)}
            />
          ))}
          <div className="searchable-link-edit__link">
            <Select
              className="searchable-link-edit__link-select"
              value={linkType}
              title={t('Link')}
              options={BannerStore.datatypes}
              onChange={onLinkTypeChange}
              flexible
              dropdownMatchSelectWidth={false}
            />

            {linkType === 'link' ? (
              <TextInput
                className="searchable-link-edit__input-link"
                label="&nbsp;"
                value={detail.url}
                onChange={(e) =>
                  SearchableLinkStore.onChangeData(
                    detail,
                    'url',
                    e.target.value
                  )
                }
                error={errors.url}
              />
            ) : (
              <>
                <Select
                  title="&nbsp;"
                  className="searchable-link-edit__link-language-select"
                  translateLabel
                  options={UIStore.languages.map((language) => ({
                    value: language,
                    label: formatLanguageKey(language),
                  }))}
                  flexible
                  value={linkLanguage}
                  dropdownMatchSelectWidth={false}
                  onChange={onLinkLanguageChange}
                />
                <Select
                  className="searchable-link-edit__select-search"
                  title="&nbsp;"
                  showSearch
                  value={detail.url}
                  onChange={(value) =>
                    SearchableLinkStore.onChangeData(detail, 'url', value)
                  }
                  onSearch={(value) =>
                    fetchSearchData(value, linkType, linkLanguage)
                  }
                  options={searchOptions}
                  filterOption={false}
                  allowClear
                  placeholder={t('Search for ...')}
                  fullWidth
                  error={errors.url}
                />
              </>
            )}
          </div>
        </div>
        <div className="searchable-link-edit__section">
          <Text size="echo" weight="bold">
            {t('Search terms')}
          </Text>
          <TagsInput
            value={(detail.keywords && detail.keywords?.split(',')) || []}
            onChange={(value) =>
              SearchableLinkStore.onChangeData(
                detail,
                'keywords',
                value.join(',')
              )
            }
            title={t(
              'Add all the words for this link, separate them by using a comma or a semicolon.'
            )}
            error={errors.keywords}
            tokenSeparators={[',', ';']}
          />
        </div>
      </div>
    </>
  )
}

export default observer(Edit)
