import App from './App'
import Callback from './auth/Callback'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { basePath } from './utils/constants'

export default function AppWrapper() {
  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        const allowTransition = window.confirm(message)
        window.setTimeout(() => {
          callback(allowTransition)
        }, 200)
      }}
      basename={basePath}
    >
      <Switch>
        <Route exact path="/callback" component={Callback} />

        <Route component={App} />
      </Switch>
    </BrowserRouter>
  )
}
