import { useRef } from 'react'

import Pagination from '../Pagination'
import Select from '../Select'
import BulkActions from './BulkActions'
import t from '../../utils/translate'

import useOnScreen from '../../utils/useOnScreen'

const DEFAULT_PAGINATION_LIMITS = [10, 30, 90]

const Footer = ({
  pagination,
  setPagination,
  bulkActions = [],
  dataSource = [],
  checkedElements = [],
  toggleSelectAll = () => {},
  isCheckedAll,
  customFooterRender,
  footerAdditionalActions,
  isShowCheckbox,
  onShowCheckbox,
  paginationLimits = DEFAULT_PAGINATION_LIMITS,
}) => {
  if (customFooterRender) {
    return customFooterRender
  }

  // eslint-disable-next-line
  const ref = useRef()
  // eslint-disable-next-line
  const isVisible = useOnScreen(ref, { rootMargin: '-49px' }) // (fixed height 81px) - (table bottom padding 32px)

  return (
    <div className="resource-table__footer">
      <div className="resource-table__bulk-actions" ref={ref}>
        {footerAdditionalActions}

        {dataSource.length > 0 && bulkActions.length > 0 && (
          <BulkActions
            isVisible={isVisible}
            bulkActions={bulkActions}
            toggleSelectAll={toggleSelectAll}
            checkedElements={checkedElements}
            isCheckedAll={isCheckedAll}
            isShowCheckbox={isShowCheckbox}
            onShowCheckbox={onShowCheckbox}
          />
        )}
      </div>

      {pagination && (
        <>
          <div className="resource-table__pagination">
            <Pagination
              initialCurrentPage={pagination.current}
              currentPage={pagination.current}
              maxPage={Math.ceil(pagination.total / pagination.pageSize)}
              onPageSwitch={(page) => {
                setPagination({
                  ...pagination,
                  current: page,
                })
              }}
            />
          </div>

          <div className="resource-table__count-select">
            <div className="resource-table__count-select--inner">
              <p>{t('Results per page')}</p>
              <Select
                options={paginationLimits.map((numb) => ({
                  value: numb,
                  label: numb.toString(),
                }))}
                value={pagination.pageSize}
                onChange={(value) => {
                  setPagination({
                    ...pagination,
                    current: 1,
                    pageSize: value,
                  })
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Footer
