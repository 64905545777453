import { forwardRef } from 'react'
import Text from '../Text'
import Icon from '../Icon'

import './documentPreviewRow.styl'
import { t } from '../../utils'
import classNames from 'classnames'
import TagsInput from '../TagsInput'

export const DocumentPreviewHeader = ({ hideSorter, hidePos }) => {
  return (
    <div className="document-preview__list-header">
      {!hidePos && (
        <div
          className={classNames(
            'document-preview__list-column document-preview__position',
            { sorter: !hideSorter }
          )}
        >
          <Text variant="book">Pos.</Text>
        </div>
      )}
      <div className="product-preview__list-column  product-preview__product">
        <Text variant="book">{t('Product / EAN')}</Text>
      </div>
    </div>
  )
}

const DocumentPreviewRow = forwardRef((props, ref) => {
  const {
    position,
    document = {},
    scoreShown = false,
    onClick,
    hidePosition,
    hideSorter,
    hideAction,
    hideImage,
    hideTags,
    onRemove = () => {},
    onChangeTag = () => {},
    disabled,
  } = props?.field?.props || props
  const score = document ? document?.['_score'] : undefined

  return (
    <div
      className={classNames('document-preview__list-row', {
        'de-active': disabled,
      })}
      style={props.style}
      ref={ref}
    >
      {!hidePosition && (
        <div
          className={classNames(
            'document-preview__list-column document-preview__position',
            { sorter: !hideSorter }
          )}
        >
          {!hideSorter && (
            <button {...(props?.listeners || {})}>
              <Icon symbol="bars" width={24} />
            </button>
          )}
          <Text size="foxtrot" weight="bold">
            {position < 10 && '0'}
            {position}
          </Text>
        </div>
      )}
      <div
        className="document-preview__list-column document-preview__content"
        onClick={onClick}
      >
        {!hideImage && <img src={document?.picture_url_main} />}
        <div className="document-preview__list-meta">
          <Text className="document-preview__document-title" weight="bold">
            {document?.title}
          </Text>
          <div className="basic-info">
            <Text className="document-preview__document-info" variant="book">
              {document?.ean}
            </Text>

            {score !== undefined && scoreShown && (
              <Text className="document-preview__document-score" variant="book">
                {score}
              </Text>
            )}
          </div>
          {!hideTags && (
            <div className="document-preview__tag-input">
              <TagsInput
                title={t('Tags')}
                value={document.tags}
                onChange={(tags) => onChangeTag(document, tags)}
              />
            </div>
          )}
        </div>
        {!hideAction && (
          <div className="document-preview__actions">
            <Icon symbol="trash" width={15} height={15} onClick={onRemove} />
          </div>
        )}
      </div>
    </div>
  )
})

export default DocumentPreviewRow
