import Icon from '../../../components/Icon'

const FileFormat = ({ type }) => {
  let icon = 'circle-question'
  if (type === 'csv') {
    icon = 'file-lines'
  }
  if (type === 'xml') {
    icon = 'rss'
  }
  if (type === 'json') {
    icon = 'brackets-curly'
  }

  return (
    <div className="feeds__file-format">
      <Icon symbol={icon} width={13} height={13} />
      {type}
    </div>
  )
}

export default FileFormat
