import { observer } from 'mobx-react-lite'
import React from 'react'
import Switch from '../../components/Switch'
import Text from '../../components/Text'
import { FilterStore } from '../../stores'
import { t } from '../../utils'

const DisplaySection = () => {
  const filter = FilterStore.filterDetail
  return (
    <div className="filter-edit__section">
      <Text size="echo" weight="bold">
        {t('Display')}
      </Text>
      <Switch
        checked={filter.showCount}
        type="horizontal"
        title={t('Show number of results')}
        onChange={(checked) =>
          FilterStore.onChangeFilter(filter, 'showCount', checked)
        }
      />
      <Switch
        checked={filter.showActiveOnTop}
        type="horizontal"
        title={t('Put active filters to the top')}
        onChange={(checked) =>
          FilterStore.onChangeFilter(filter, 'showActiveOnTop', checked)
        }
      />
    </div>
  )
}

export default observer(DisplaySection)
