import { useState, useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { t } from '../../utils'
import debounce from '../../utils/debounce'
import './textarea.styl'

export default function Textarea(props) {
  const {
    onChange,
    maxlength,
    title,
    placeholder,
    description,
    error,
    disabled,
    rows,
    defaultRows = 1,
  } = props
  const textarea = useRef(null)
  const [value, setValue] = useState(props.value || '')
  const warningClass = maxlength && value?.length > maxlength ? 'warning' : ''

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChange = useCallback(debounce(onChange, 250), [onChange])

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const handleChange = (e) => {
    setValue(e.target.value)
    debounceChange(e.target.value)
  }

  //handle auto row textarea
  useEffect(() => {
    let observe
    if (window.attachEvent) {
      observe = function (element, event, handler) {
        element.attachEvent('on' + event, handler)
      }
    } else {
      observe = function (element, event, handler) {
        element.addEventListener(event, handler, false)
      }
    }

    function init() {
      const text = textarea.current
      if (!text) return
      function resize() {
        text.style.height = 'auto'
        text.style.height = text.scrollHeight + 'px'
      }
      /* 0-timeout to get the already changed text */
      function delayedResize() {
        window.setTimeout(resize, 0)
      }
      observe(text, 'change', resize)
      observe(text, 'cut', delayedResize)
      observe(text, 'paste', delayedResize)
      observe(text, 'drop', delayedResize)
      observe(text, 'keydown', delayedResize)
      resize()
    }
    if (rows === 'auto') {
      init()
    }
    // eslint-disable-next-line
  }, [])

  const classes = classNames('textarea-input', props.className, {
    'textarea-input__has-error': error,
    'textarea-input--auto-rows': rows === 'auto',
    'textarea-input--disabled': disabled,
  })

  return (
    <div className={classes}>
      <div className="textarea-input__label">
        {title && <label>{title}</label>}
        {!!maxlength && (
          <span className="textarea-input__count">
            <span className={warningClass}>{value ? value.length : 0}</span>/
            {maxlength} {t('Character')}
          </span>
        )}
      </div>
      <textarea
        ref={textarea}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        rows={rows === 'auto' ? defaultRows : rows}
      />
      {description && (
        <div className="textarea-input__description">{description}</div>
      )}
      {error && (
        <div className="textarea-input__description textarea-input__description--error">
          {error}
        </div>
      )}
    </div>
  )
}
