import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/placement?${queryString}`

    return fetch(target)
  },

  async create(placement) {
    return fetch('/placement', {
      method: 'POST',
      body: JSON.stringify(placement),
    })
  },

  async update(placement) {
    return fetch(`/placement/${placement.id}`, {
      method: 'PUT',
      body: JSON.stringify(placement),
    })
  },

  async getById(id) {
    return fetch(`/placement/${id}`, { method: 'GET' })
  },

  async delete(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))

    await fetch(`/placement?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },
}
