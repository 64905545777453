import get from 'lodash/get'
import Switch from '../../../components/Switch'
import UIStore from '../../../stores/UIStore'
import t from '../../../utils/translate'

export default function ContentSetting({ pageToEdit, formikProps }) {
  const { type } = pageToEdit

  if (type === 'snippet' || type === 'page') return null

  const { values, setFieldValue } = formikProps

  return (
    <div className="page-configuration__global-settings">
      {UIStore.languages.map((language) => (
        <div key={language} className="page-configuration__language-row">
          <Switch
            checked={get(values, ['active', language])}
            title={`${t('Active')} (${language.toUpperCase()})`}
            onChange={(checked) => {
              setFieldValue(`active.${language}`, checked)
            }}
          />
        </div>
      ))}
    </div>
  )
}
