import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import ImporterPageTitle from './ImporterPageTitle'
import LogView from '../../components/LogView/LogView'
import { getGroupOptions, levelOptions } from './components/data'

import t from '../../utils/translate'

import UIStore from '../../stores/UIStore'
import ImporterStore from '../../stores/ImporterStore'
import ImporterLogStore from '../../stores/ImporterLogStore'

import './logs.styl'

const Logs = () => {
  const { scheduleStatus = [] } = ImporterStore
  const { logFilters } = ImporterLogStore

  useEffect(() => {
    ImporterStore.fetchScheduleStatus()
  }, [])

  const filters = useMemo(
    () => [
      {
        type: 'single-select',
        title: t('Importer'),
        placeholder: t('Select Importer'),
        name: 'podName',
        groupOptions: getGroupOptions(scheduleStatus),
        value: logFilters.podName,
        position: 1,
        className: 'importer-logs__importer-select',
      },
      {
        type: 'single-select',
        title: t('Type'),
        placeholder: t('Select Type'),
        name: 'level',
        options: levelOptions,
        value: logFilters.level,
        position: 11,
      },
    ],
    [scheduleStatus, UIStore.currentLanguage, logFilters] // eslint-disable-line
  )

  const getTypeText = (level) => {
    switch (level) {
      case 3:
        return t('ERROR')
      case 2:
        return t('CRITICAL')
      default:
        return t('INFO')
    }
  }

  const columns = [
    {
      title: 'Date',
      key: 'timestamp',
      format: (value) => moment.utc(value).local().format('DD.MM.YYYY'),
    },
    {
      title: 'Time',
      key: 'timestamp',
      format: (value) => moment.utc(value).local().format('HH:mm:ss'),
    },
    {
      title: 'Type',
      key: 'level',
      format: (value) => getTypeText(value),
    },
    {
      title: 'Language',
      key: 'language',
      format: (value) => (value ? value.toUpperCase() : ''),
    },
    {
      title: 'Message',
      key: 'message',
    },
    {
      title: 'Took (in seconds)',
      key: 'took',
      format: (value) => (value ? (value / 1000).toLocaleString() : ''),
    },
  ]

  return (
    <>
      <ImporterPageTitle />
      <div className="importer-logs content-wrapper">
        <LogView
          type={'importer'}
          store={ImporterLogStore}
          filters={filters}
          columns={columns}
          generateRowKey={(row) => row.timestamp + row.message}
        />
      </div>
    </>
  )
}

export default observer(Logs)
