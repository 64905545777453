import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import AuthStore from '../stores/AuthStore'
import { basePath } from '../utils/constants'
import LoadingScreen from '../components/LoadingScreen'

function Callback() {
  const history = useHistory()

  useEffect(() => {
    async function handleCallback() {
      await AuthStore.initialize()
      await AuthStore.callback()

      let target = '/'
      const lastEntry = sessionStorage.getItem('lastEntry')

      if (lastEntry && lastEntry.startsWith(basePath)) {
        target = lastEntry.replace(basePath, '')
      }

      history.push(target)
    }

    handleCallback()
  }, [history])

  return <LoadingScreen />
}

export default observer(Callback)
