import { observer } from 'mobx-react-lite'
import Icon from '../../../components/Icon'
import Spinner from '../../../components/Spinner'
import Text from '../../../components/Text'
import StorefrontSettingStore from '../../..//stores/StorefrontSettingStore'
import { t } from '../../../utils'

const CustomExpand = (props) => {
  if (!props.record.output_stream_url) return null
  let text
  if (
    StorefrontSettingStore.outputStreams[
      props.record.created_at + props.record.commit
    ] === 'loading'
  ) {
    text = (
      <Text>
        <Spinner size="small" />
      </Text>
    )
  } else if (props.expanded) {
    text = (
      <Text>
        {t('Close log view')}{' '}
        <Icon symbol="chevron-up" width={13} height={13} />
      </Text>
    )
  } else {
    text = (
      <Text>
        {t('View build log')}{' '}
        <Icon symbol="circle-info" width={13} height={13} />
      </Text>
    )
  }
  return (
    <button
      className="expand-row-icon"
      onClick={async (e) => {
        await StorefrontSettingStore.fetchOutputStream(props.record)
        props.onExpand(props.record, e)
      }}
    >
      {text}
    </button>
  )
}

export default observer(CustomExpand)
