import { observer } from 'mobx-react-lite'
import React from 'react'
import TrafficDistribution from '../../TrafficDistribution'
import ABTestStore from '../../../stores/ABTestStore'
import { range } from 'lodash'
import Text from '../../Text'
import t from '../../../utils/translate'

const TrafficDistributionGroup = ({ disabled }) => {
  const { variations, segments = 2 } = ABTestStore.editingExperiment

  return (
    <div className="ab-metaconfig__traffic-distribution-group">
      <Text
        size="delta"
        weight="bold"
        className="ab-metaconfig__traffic-distribution-label"
      >
        {t('Traffic distribution')}
      </Text>
      <div className="ab-metaconfig__traffic-distribution-inputs">
        {variations &&
          range(0, segments).map((index) => (
            <TrafficDistribution
              step={1}
              key={index}
              value={variations[index].trafficDistribution}
              locked={disabled || variations[index].locked}
              onToggleLock={() =>
                ABTestStore.toggleVariantLock(index, !variations[index].locked)
              }
              variant={variations[index].name}
              onChange={(value) => ABTestStore.updateVariantValue(index, value)}
            />
          ))}
      </div>
      <Text className="ab-metaconfig__traffic-distribution-des" variant="book">
        {t('Click on a lock symbol to freeze a traffic channel’s percentage.')}
        <br />
        {t(
          'The other channels will always interact to reach a 100% traffic total.'
        )}
      </Text>
    </div>
  )
}

export default observer(TrafficDistributionGroup)
