import classes from 'classnames'
import { observer } from 'mobx-react-lite'

import Button from '../../components/Button'
import Text from '../../components/Text'
import Collapse, { Panel as RcPanel } from '../../components/Collapse'

import t from '../../utils/translate'

import './configurationPanel.styl'

function ConfigurationPanel(props) {
  const {
    active,
    children,
    defaultActive,
    arrowPosition,
    accordion,
    rightArrow = false,
    className,
    onCloseButtonClick,
    noPadding = false,
  } = props

  return (
    <div
      className={classes('configuration-panel', className, {
        'configuration-panel--open': active,
        'configuration-panel--right-arrow': rightArrow,
        'configuration-panel--no-padding': noPadding,
      })}
      style={{
        '--arrow-left': arrowPosition,
      }}
    >
      <Collapse
        level={1}
        activeKey={active ? 'open' : ''}
        defaultActive={defaultActive}
        accordion={accordion}
      >
        <RcPanel className="configuration-panel__main-panel" key="open">
          {typeof onCloseButtonClick === 'function' && (
            <Button
              className="configuration-panel__close-button"
              variant="flat"
              icon="arrow-up"
              onClick={onCloseButtonClick}
            >
              {t('close')}
            </Button>
          )}
          <Collapse
            accordion={accordion}
            level={1}
            type="arrow"
            defaultActiveKey={defaultActive}
          >
            {children}
          </Collapse>
        </RcPanel>
      </Collapse>
    </div>
  )
}

export function Panel(props) {
  const { withoutCollapse, header, children } = props

  if (withoutCollapse) {
    return (
      <div>
        {header?.length > 0 && (
          <Text
            size="echo"
            element="p"
            className="configuration-panel__headline"
          >
            {header}
          </Text>
        )}

        {children}
      </div>
    )
  }

  return <RcPanel showArrow {...props} />
}

export default observer(ConfigurationPanel)
