import { observer } from 'mobx-react-lite'
import Table from './Table'
import ErrorBoundary from '../../components/ErrorBoundary'
import { toJS } from 'mobx'
import EmptyTable from '../Table/EmptyTable'
import React from 'react'
import './resourceTable.styl'

function ResourceTable(props) {
  const {
    store,
    resourceName,
    title,
    createAction,
    createActionText,
    emptyText,
    filterParams,
    loading,
    data,
    hideEmptyTextWhenNoFilters = false,
    hideEmptyComponentWhenEmpty = false,
  } = props

  const dataSource = data || toJS(store[resourceName]) || {}
  const params = toJS(store[filterParams])
  const { state } = store

  const noFilter =
    !filterParams || !Object.values(params).some((item) => !!item)

  if (
    !loading &&
    !dataSource.length &&
    state !== 'pending' &&
    noFilter &&
    !hideEmptyComponentWhenEmpty
  ) {
    return (
      <EmptyTable
        title={title}
        emptyText={emptyText}
        createAction={createAction}
        createActionText={createActionText}
        hideEmptyTextWhenNoFilters={hideEmptyTextWhenNoFilters}
      />
    )
  }

  return (
    <ErrorBoundary>
      <Table {...props} />
    </ErrorBoundary>
  )
}

export default observer(ResourceTable)
