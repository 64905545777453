import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import Modal from '../../components/Modal'
import t from '../../utils/translate'
import { ComponentStore } from '../../stores'
import Textarea from '../../components/Textarea'
import Button from '../../components/Button'

function ImportModal({ closeModal, modalVisible }) {
  const [structure, setStructure] = useState('')

  const onImport = () => {
    ComponentStore.importStructure(structure)
    closeModal()
    setStructure('')
  }

  return (
    <Modal
      visible={modalVisible}
      header={t('Import Component Structure')}
      onClose={closeModal}
      width={1000}
      centered={true}
      footer={
        <Button onClick={onImport} variant="primary" icon="file-import">
          {t('Import')}
        </Button>
      }
      mask
    >
      <Textarea
        className="component-editor__component-structure-input"
        title={t('Component Structure:')}
        value={structure}
        onChange={(value) => setStructure(value)}
        rows="auto"
        description={t(
          'Here you can paste a component structure which you copied on the component list. The current structure will be replaced.'
        )}
        defaultRows={10}
      />
    </Modal>
  )
}

export default observer(ImportModal)
