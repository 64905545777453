import { observer } from 'mobx-react-lite'
import { UserManagementStore } from '../../../stores'
import { Popover } from 'antd'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

import HeadingMain from '../../../components/Headings/HeadingMain'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import { createRedirectPath, t } from '../../../utils'
import { routes } from '../../../routing'

function DeleteUser({ user }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const hide = () => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen)
  }

  const handleRemoveUser = async () => {
    setLoading(true)
    await UserManagementStore.delete([user.user_id])
    setLoading(false)
    hide()
    history.push(createRedirectPath(routes.userList.path))
  }

  if (!user?.user_id) return null
  return (
    <div className="delete-user-section">
      <HeadingMain>{t('Remove access')}</HeadingMain>
      <Text>
        {t('The user will no longer be able to access this account.')}
      </Text>
      <Popover
        onVisibleChange={handleOpenChange}
        visible={open}
        trigger="click"
        placement="top"
        content={
          <div className="user-management__delete-confirm">
            <Text size="charlie">{t('Sure to delete?')}</Text>
            <div>
              <Button onClick={hide}>{t('Cancel')}</Button>
              <Button
                onClick={handleRemoveUser}
                variant="secondary-blocker"
                loading={loading}
              >
                {t('Ok')}
              </Button>
            </div>
          </div>
        }
      >
        <Button variant="secondary-blocker" icon="trash" iconPosition="left">
          {t('Remove access')}
        </Button>
      </Popover>
    </div>
  )
}

export default observer(DeleteUser)
