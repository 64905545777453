import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import { delay } from '../../utils'

class PageStore {
  pages = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = {}
  state = 'pending' // "pending", "done" or "error"
  fields = []

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.filter = {}
    this.sorter = {
      field: 'changed',
      order: 'descend',
    }
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchPages()
  }

  setSorter(sorter) {
    this.pagination = {
      ...this.pagination,
      current: 1,
      total: 0,
    }
    this.sorter = sorter
    this.fetchPages()
  }

  setFilter(filter) {
    this.pagination = {
      ...this.pagination,
      current: 1,
      total: 0,
    }
    this.filter = filter
    this.fetchPages()
  }

  getById(id) {
    return this.pages.find((s) => s.id == id)
  }

  *fetchPages() {
    this.state = 'pending'
    try {
      const { data, total } = yield api.pages.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.pages = data.map((item, index) => ({
        ...item,
        indexId: `${item.id}.${item.relatedId}.${index}`,
        children: item.languageVersions.map((o, i) => ({
          ...o,
          isChild: true,
          id: item.id,
          relatedId: `related-${item.relatedId}-${item.type}`,
          hasContent: item.hasContent,
          indexId: `${item.id}.${item.relatedId}.${index}.${i}`,
        })),
      }))
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the page listing.')
      this.state = 'error'
    }
  }

  *updateOrCreate(page) {
    try {
      if (page.id) {
        yield api.pages.update(page)
      } else {
        yield api.pages.create(page)
      }
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
    this.fetchPages()
  }

  *delete(id) {
    try {
      yield api.pages.delete(id)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchPages()
  }

  *bulkDelete(ids) {
    this.state = 'pending'

    try {
      yield api.pages.bulkDelete(ids)
      yield delay(1000)

      this.fetchPages()
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *fetchFields() {
    if (this.fields.length) return

    this.state = 'pending'
    try {
      const { data } = yield api.fields.getFieldsByType('datatype')

      this.fields = data
        .filter((field) => {
          return !['link', 'searchredirect'].includes(field.id)
        })
        .map((field) => ({
          value: field.id,
          label: field.title,
          singular: field.singular,
        }))

      this.fields.unshift({
        value: '',
        label: 'All types',
      })

      this.fields.push({
        value: 'snippet',
        label: 'Snippets',
        singular: 'Snippet',
      })

      this.state = 'done'
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }
}

export default new PageStore()
