/**
 * Append a valid protocol to a URL if required.
 * Will prefix a localhost URL with HTTP.
 * Everything else with HTTPS.
 *
 * @param url URL that will be prefixed, if required, with a protocol
 * @returns {string} The with a protocol prefixed URL.
 */
function appendProtocolToURL(url = '') {
  let finalURL = url

  if (url.includes('localhost')) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      finalURL = 'http://' + finalURL
    }
  } else {
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      finalURL = 'https://' + finalURL
    }
  }
  return finalURL
}

export default appendProtocolToURL
