import csx from 'classnames'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { useMemo, useRef } from 'react'
import Badge from '../../../components/Badge'
import ActiveRange from './ActiveRange'
import { getBadgeProps, SIZES, calculateLineNumbers } from '../utils'
import TimelineHeaders from './TimelineHeaders'
import { UIStore } from '../../../stores'
import { Tooltip } from 'antd'
import ABTestStore from '../../../stores/ABTestStore'

const Timeline = ({
  startDate,
  endDate,
  type = 'year',
  variant = 'overview', // overview or detail
  items = [],
  totalLine = 0,
  activeRangeStartDate,
  setActiveRangeStartDate,
  activeRangeDays,
  setActiveRangeDays,
  width = 0,
  offset = 0,
  testInFocus = '',
}) => {
  const timelineOverviewRef = useRef(null)
  const startDateMoment = moment(startDate)
  const endDateMoment = moment(endDate)
  const totalDays = endDateMoment.diff(startDateMoment, 'days')

  //calculate position of month/year in the timeline (headers of timeline)
  const headers = useMemo(() => {
    moment.locale(UIStore.currentLanguage)

    // count total month/year columns of timeline
    const totalColumns = endDateMoment
      .clone()
      .endOf(type)
      .diff(startDateMoment.clone().startOf(type), type)

    const timelineItems = []

    for (let columnIndex = 0; columnIndex <= totalColumns; columnIndex++) {
      // get moment of current column
      const columnMoment = startDateMoment.clone()
      columnMoment.add(columnIndex, type)
      columnMoment.startOf(type)
      columnMoment.add(12, 'hours')

      //calculate left base on total days
      const totalDaysFromStartDate = columnMoment.diff(startDateMoment, 'days')
      const leftPercent = totalDaysFromStartDate / totalDays

      // ignore if it's outside the view
      if (leftPercent * width + offset < -1 || leftPercent * width > width) {
        continue
      }

      let format = 'MMM'
      if (type === 'year') format = 'YYYY'
      if (type === 'day') format = 'D'

      timelineItems.push({
        title: columnMoment.format(format).toUpperCase(),
        left: leftPercent * width + offset,
        key: columnMoment.toISOString(),
        date: columnMoment.clone(),
      })
    }

    return timelineItems
    // eslint-disable-next-line
  }, [totalDays, startDate, UIStore.currentLanguage, width])

  const itemsWithPosition = useMemo(() => {
    const data = []
    let maxLine = totalLine

    const lineNumbers = calculateLineNumbers(items)

    items.forEach((test) => {
      const offsetStartDatePercent =
        moment(test.start_date?.date).diff(startDateMoment, 'days') / totalDays

      const itemEndDateMoment = test.end_date
        ? moment(test.end_date?.date)
        : moment(test.start_date?.date).add(45, 'days')
      const offsetEndDatePercent =
        itemEndDateMoment.diff(startDateMoment, 'days') / totalDays

      let itemLeft = offsetStartDatePercent * width + offset
      let itemWidth = (offsetEndDatePercent - offsetStartDatePercent) * width
      let leftFadeout = false
      let rightFadeout = false

      // Cut of the item when it is out on the left side of screen
      if (itemLeft < 0) {
        leftFadeout = true
        itemWidth += itemLeft
        itemLeft = 0
      }

      // Cut of the item when it is out on the right side of the screen
      if (itemWidth + itemLeft > width + offset) {
        rightFadeout = true
        itemWidth = width + offset - itemLeft
      }

      // If the item would only be tiny in the screen we simply skip it because all text would cut of.
      // We do it only in the detail screen because in the overview test boxes could be really short.
      if (itemWidth < 10 && variant === 'detail') return

      const newItem = {
        style: {
          left: itemLeft,
          width: itemWidth,
          height: SIZES[variant].itemHeight,
        },
        data: test,
        leftFadeout,
        rightFadeout,
        focused: !testInFocus || testInFocus === test.id,
      }

      const lineNumber = lineNumbers.find((item) => item.id === test.id).line
      if (maxLine < lineNumber) maxLine = lineNumber
      newItem.style.top =
        SIZES[variant].paddingTop +
        lineNumber * (SIZES[variant].itemHeight + SIZES[variant].paddingTop)
      data.push(newItem)
    })
    return { data, maxLine }
    // eslint-disable-next-line
  }, [items, startDate, endDate, width])

  const currentDatePosition = useMemo(() => {
    const offsetStartDatePercent =
      (moment().diff(startDateMoment, 'days') / totalDays) * 100

    return { left: offsetStartDatePercent }
    // eslint-disable-next-line
  }, [startDate, endDate, width])

  const calculateLeftOffset = (percentage) => {
    percentage = percentage / 100

    return width * percentage + offset
  }
  const getHeightOfTimeline = () => {
    return (
      SIZES[variant].paddingTop +
      (itemsWithPosition.maxLine + 1) *
        (SIZES[variant].itemHeight + SIZES[variant].paddingTop)
    )
  }

  return (
    <>
      <div
        className={`ab-testing__timeline ab-testing__timeline--${variant} ab-testing__timeline--${type}`}
      >
        <TimelineHeaders headers={headers} type={type} />
        <div
          style={{
            height: getHeightOfTimeline(),
          }}
          className="ab-testing__timeline-overview"
        >
          {itemsWithPosition.data.map((item) => (
            <Tooltip
              key={item.data.id}
              title={`${item.data.name} (${item.data.id})`}
            >
              <div
                key={item.data.id}
                className={csx(
                  'ab-testing__timeline-item',
                  `ab-testing__timeline-item--${item.data.status}`,
                  {
                    'ab-testing__timeline-item--left-fadeout': item.leftFadeout,
                    'ab-testing__timeline-item--right-fadeout':
                      item.rightFadeout,
                    'ab-testing__timeline-item--not-focused': !item.focused,
                  }
                )}
                style={item.style}
              >
                {variant === 'detail' && (
                  <Badge
                    {...getBadgeProps(item.data.status)}
                    text={`${item.data.name} (${item.data.id})`}
                  />
                )}
              </div>
            </Tooltip>
          ))}
        </div>
        {calculateLeftOffset(currentDatePosition.left) <= width + offset && (
          <div
            style={{ left: calculateLeftOffset(currentDatePosition.left) }}
            className="timeline-header__line timeline-header__line--current"
          />
        )}
        {variant === 'overview' && width !== -1 && (
          <ActiveRange
            loading={ABTestStore.state === 'pending'}
            activeRangeStartDate={activeRangeStartDate}
            setActiveRangeStartDate={setActiveRangeStartDate}
            activeRangeDays={activeRangeDays}
            setActiveRangeDays={setActiveRangeDays}
            timelineOverviewRef={timelineOverviewRef}
            startDate={startDate}
            endDate={endDate}
            totalDays={totalDays}
            totalLine={itemsWithPosition.maxLine}
            timelineWidth={width}
          />
        )}
      </div>
    </>
  )
}

export default observer(Timeline)
