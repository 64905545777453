import { Spin } from 'antd'
import classnames from 'classnames'

export default function LoadingScreen({ withPageTitle = false }) {
  return (
    <div
      className={classnames('loading-screen', {
        'loading-screen--with-page-title': withPageTitle,
      })}
    >
      <Spin size="large" />
    </div>
  )
}
