import { observer } from 'mobx-react-lite'
import React from 'react'
import Checkbox from '../../../components/Checkbox'
import Select from '../../../components/Select'
import Switch from '../../../components/Switch'
import TextInput from '../../../components/TextInput'
import { RecommendationStore } from '../../../stores'
import ABTestStore from '../../../stores/ABTestStore'
import { t } from '../../../utils'
import PresetsDropdown from './PresetsDropdown'

const BasicSection = ({ formikProps, disableEdit }) => {
  const reco = RecommendationStore.currentRecoDetail

  return (
    <div className="recommendations__edit-section">
      {!ABTestStore.showMetaconfigView && (
        <>
          <div className="recommendations__edit-row">
            <Switch
              type="horizontal"
              title={<div className="text-input__label">{t('Active')}</div>}
              checked={reco.isActive}
              onChange={(value) =>
                RecommendationStore.onChangeField(reco, 'isActive', value)
              }
            />
            <PresetsDropdown />
          </div>

          <TextInput
            label={t('Name')}
            value={formikProps.values.name}
            error={formikProps.errors.name}
            onChange={(e) => formikProps.setFieldValue('name', e.target.value)}
          />
          <TextInput
            label={t('Identifier for your front-end templates')}
            value={formikProps.values.recommendationId}
            error={formikProps.errors.recommendationId}
            onChange={(e) =>
              formikProps.setFieldValue('recommendationId', e.target.value)
            }
          />
        </>
      )}
      <div className="recommendations__type-select-wrapper">
        <Select
          flexible
          dropdownMatchSelectWidth={false}
          title={t('Recommendations based on …')}
          options={RecommendationStore.availableTypeOptions}
          value={reco.recommendationType}
          onChange={(value) =>
            RecommendationStore.onChangeField(reco, 'recommendationType', value)
          }
          disabled={disableEdit}
        />
        {!['none', 'textSimilarity'].includes(reco.recommendationType) && (
          <Checkbox
            label={t('take text similarity into account')}
            checked={reco.isPopulateTextSimilarity}
            onChange={(e) =>
              RecommendationStore.onChangeField(
                reco,
                'isPopulateTextSimilarity',
                e.target.checked
              )
            }
            disabled={disableEdit}
          />
        )}
      </div>
    </div>
  )
}

export default observer(BasicSection)
