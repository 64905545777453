import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'
import Button from '../../components/Button'
import DropdownButton from '../../components/DropdownButton'
import Select from '../../components/Select'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import { MonitoringStore, UIStore } from '../../stores'
import { createRedirectPath, formatLanguageKey, t } from '../../utils'
import { SEARCH_TERMS_TYPE_LABLES, SEARCH_TERMS_TYPE_ROUTE } from './constants'

const CheckSearchTerms = () => {
  const history = useHistory()

  const checkSearchTerms = MonitoringStore.checkSearchTerms

  const handledSearchTerm =
    !checkSearchTerms.fetching &&
    checkSearchTerms.searchTerm === checkSearchTerms.data.searchTerm &&
    checkSearchTerms.data.handled === true

  const handledSearchTermOptions = handledSearchTerm
    ? checkSearchTerms.data?.handlers?.map((item) => ({
        label: SEARCH_TERMS_TYPE_LABLES[item.type],
        value: SEARCH_TERMS_TYPE_ROUTE[item.type] + item.data.id,
      }))
    : []

  return (
    <div className="search-monitoring__check-search-term">
      <Text weight="bold" size="echo">
        {t('Check term for special handling')}
      </Text>
      <div className="search-monitoring__check-search-term-wrapper">
        <Text weight="book">
          {t('Define how the search behaves on a certain search term.')}
        </Text>
        <div className="search-monitoring__check-search-term-input-wrapper">
          <div className="search-monitoring__check-search-term-input">
            <Select
              value={MonitoringStore.checkSearchTerms.language}
              translateLabel
              options={UIStore.languages.map((language) => ({
                value: language,
                label: formatLanguageKey(language),
              }))}
              flexible
              dropdownMatchSelectWidth={false}
              onChange={(val) =>
                MonitoringStore.onChangeCheckSearchTerm(
                  MonitoringStore.checkSearchTerms,
                  'language',
                  val
                )
              }
              error={handledSearchTerm}
            />
            <TextInput
              value={MonitoringStore.checkSearchTerms.searchTerm}
              onChange={(e) =>
                MonitoringStore.onChangeCheckSearchTerm(
                  MonitoringStore.checkSearchTerms,
                  'searchTerm',
                  e.target.value
                )
              }
              rounded
              icon="magnifying-glass"
              error={handledSearchTerm}
            />
          </div>
          {handledSearchTermOptions.length === 1 && (
            <Button
              onClick={() =>
                history.push(
                  createRedirectPath(handledSearchTermOptions[0].value)
                )
              }
            >
              {t('Show')} {handledSearchTermOptions[0].label}
            </Button>
          )}
          {handledSearchTermOptions.length > 1 && (
            <DropdownButton
              className="search-monitoring__show-btn"
              text={t('Show') + '...'}
              actionIcon=""
              options={handledSearchTermOptions}
              onSelect={(value) => history.push(createRedirectPath(value.key))}
              buttonLevel={1}
              destroyTooltipOnHide
              variant="secondary"
              loading={checkSearchTerms.fetching}
            />
          )}

          {!handledSearchTerm && (
            <DropdownButton
              actionIcon="plus"
              text={t('Add as...')}
              options={[
                { label: 'Synonym', value: '/synonyms/new' },
                { label: 'Antonym', value: '/antonyms/new' },
                { label: 'Redirect', value: '/search-redirects/new' },
              ]}
              onSelect={(value) =>
                history.push(createRedirectPath(value.key), {
                  searchTerm: MonitoringStore.checkSearchTerms.searchTerm,
                  language: MonitoringStore.checkSearchTerms.language,
                })
              }
              buttonLevel={1}
              destroyTooltipOnHide
              variant="secondary"
              loading={checkSearchTerms.fetching}
              disabled={!checkSearchTerms.searchTerm || handledSearchTerm}
            />
          )}
        </div>
        {handledSearchTerm && (
          <Text weight="bold" className="search-monitoring__handled-warning">
            {t('This search term is already defined')}
          </Text>
        )}
      </div>
    </div>
  )
}

export default observer(CheckSearchTerms)
