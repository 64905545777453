import { observer } from 'mobx-react-lite'
import React, { useRef, useEffect } from 'react'
import StorefrontSettingStore from '../../../stores/StorefrontSettingStore'

const StreamRender = ({ record }) => {
  const data =
    StorefrontSettingStore.outputStreams[record.created_at + record.commit]

  const codeRef = useRef(null)

  useEffect(() => {
    if (codeRef.current) {
      codeRef.current.scrollTop = 100000
    }
  }, [data])

  return (
    <code className="makaira-scrollbar" ref={codeRef}>
      {data}
    </code>
  )
}

export default observer(StreamRender)
