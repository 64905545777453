import Drawer from 'rc-drawer'
import { observer } from 'mobx-react-lite'
import { ActionLayerStore, UIStore } from '../../stores'
import Button from '../Button'
import Text from '../Text'
import t from '../../utils/translate'
import Collapse, { Panel } from '../Collapse'

import 'rc-drawer/assets/index.css'
import './style.styl'

/**
 * @param debugError Only used for Storybook/Pali to be able to display an ActionLayer with an error.
 * @returns {JSX.Element}
 * @constructor
 */
const ActionLayer = ({ debugError = false }) => {
  const {
    open,
    render,
    className,
    handleSave,
    handleClose,
    handleSaveWithContinue,
    saveTitle,
    closeTitle,
    saveWithContinue,
    onSave,
    onClose,
    onSaveWithContinue,
    state,
    header,
    content,
    error,
    setHeight,
  } = ActionLayerStore

  const renderContent = () => {
    if (render) return render({ handleClose, handleSave })

    const showError = state === 'error' || error || debugError

    return (
      <>
        {showError && (
          <Collapse
            className="action-layer__error-collapse"
            activeKey="error"
            level={1}
          >
            <Panel key="error" collapsible="disabled">
              <Text
                element="p"
                size="echo"
                weight="heavy"
                className="action-layer__header"
              >
                {t('The sky comes tumbling down …')}
              </Text>
              <Text
                element="p"
                size="charlie"
                className="action-layer__content"
              >
                {t(
                  error ??
                    'It looks like not everything runs smoothly currently, but thats not the end of the world. We registered the error. Unfortunately we could not save your changes.'
                )}
              </Text>
            </Panel>
          </Collapse>
        )}

        {header && (
          <Text
            element="p"
            size="echo"
            weight="heavy"
            className="action-layer__header"
          >
            {header}
          </Text>
        )}

        {content && (
          <Text element="p" size="charlie" className="action-layer__content">
            {content}
          </Text>
        )}

        <div className="action-layer__footer">
          {onClose && (
            <Button
              className="action-layer__footer-button"
              variant="secondary"
              level={1}
              icon="xmark"
              onClick={handleClose}
            >
              {closeTitle || t('Cancel')}
            </Button>
          )}
          {onSaveWithContinue && (
            <Button
              className="action-layer__footer-button"
              variant="secondary-blocker"
              level={1}
              icon="chevron-right"
              onClick={handleSaveWithContinue}
              data-cy="action-layer-submit-continue"
            >
              {saveWithContinue || t('Save & continue')}
            </Button>
          )}
          {onSave && (
            <Button
              className="action-layer__footer-button"
              variant="secondary-blocker"
              level={1}
              icon="chevron-right"
              onClick={handleSave}
              loading={state === 'pending'}
              data-cy="action-layer-submit"
            >
              {saveTitle || t('Save')}
            </Button>
          )}
        </div>
      </>
    )
  }

  const afterVisibleChange = (isVisible) => {
    if (isVisible) {
      const layerWrapper = document.getElementById('action-layer').children[0]
      setHeight(layerWrapper.scrollHeight)
    } else {
      setHeight(0)
    }
  }

  if (UIStore.isStorefrontPreview) return null

  return (
    <Drawer
      id="action-layer"
      className={`action-layer ${className}`}
      placement="top"
      levelMove="0px"
      showMask={false}
      handler={false}
      open={open}
      autoFocus={false}
      afterVisibleChange={afterVisibleChange}
    >
      {renderContent()}
    </Drawer>
  )
}

export default observer(ActionLayer)
