import { observer } from 'mobx-react-lite'
import Sortable from '../Sortable'
import DocumentPreviewRow, { DocumentPreviewHeader } from './DocumentPreviewRow'

function PromotedDocuments(props) {
  const { items, onRemove, onChangePosition, hideHeader, onChangeTag } = props
  if (items.length == 0) return null
  const itemsWithProps = items.map((item, index) => ({
    ...item,
    props: {
      document: item,
      position: index + 1,
      key: item.id,
      onRemove: () => onRemove(item.es_id),
      hideImage: item.type !== 'product',
      hidePosition: item.type !== 'product',
      hideTags: item.type !== 'product',
      onChangeTag: onChangeTag,
    },
  }))

  return (
    <div className="search-preview__group">
      {!hideHeader && <DocumentPreviewHeader />}
      <div>
        <Sortable
          id="sortable"
          items={itemsWithProps}
          itemComponent={DocumentPreviewRow}
          onChange={(val) => {
            const data = val.map(({ props: propsData, ...rest }, index) => ({
              ...rest,
              position: index + 1,
            }))
            onChangePosition(data)
          }}
        />
      </div>
    </div>
  )
}

export default observer(PromotedDocuments)
