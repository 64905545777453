import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ResourceTable from '../../components/ResourceTable'
import { ArrowAction, Column } from '../../components/Table'
import Text from '../../components/Text'
import { SearchRedirectStore } from '../../stores'
import { createRedirectPath, t } from '../../utils'
import SearchPageTitle from '../SearchConfigurationV3/ImporterPageTitle'
import ActionLayerStore from '../../stores/ActionLayerStore'

const List = () => {
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    SearchRedirectStore.fetchSearchRedirects(true)
    SearchRedirectStore.fetchSearchTypes()
    // eslint-disable-next-line
  }, [])

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    await SearchRedirectStore.delete(selectedRows)

    if (SearchRedirectStore.state !== 'error') {
      setSelectedRows([])
      ActionLayerStore.closeActionLayer()
    }
  }

  return (
    <>
      <SearchPageTitle />
      <div className="content-wrapper search-redirects-list">
        <ResourceTable
          title={t('Redirects')}
          store={SearchRedirectStore}
          resourceName={'searchRedirects'}
          filterParams="filter"
          filters={[
            {
              type: 'single-select',
              title: t('Type'),
              placeholder: t('Select type'),
              name: 'type',
              onChange: SearchRedirectStore.setFilter,
              defaultValue: SearchRedirectStore.filter?.type || '',
              options: [
                {
                  label: t('Show all'),
                  value: '',
                },
                ...SearchRedirectStore.searchTypes.map((item) => ({
                  value: item.id,
                  label: t(item.title),
                })),
              ],
            },
            {
              type: 'search',
              title: t('Search'),
              name: 'q',
              onChange: SearchRedirectStore.setFilter,
              defaultValue: SearchRedirectStore.filter.q,
            },
          ]}
          headerAction={{
            type: 'create',
            action: () =>
              history.push(createRedirectPath(`/search-redirects/new`)),
          }}
          loading={SearchRedirectStore.state === 'pending'}
          bulkActions={[
            {
              title: t('Delete'),
              onExecute: () => showDeleteWarning(),
            },
          ]}
          rowSelection={{
            selectedRows,
            onSelectionChange: (newRows) => setSelectedRows(newRows),
          }}
          onRowClick={(record) =>
            history.push(createRedirectPath(`/search-redirects/${record.id}`))
          }
          emptyText={t('No redirects found.')}
          createAction={() =>
            history.push(createRedirectPath(`/search-redirects/new`))
          }
          createActionText={t('Create Redirect')}
          hideEmptyComponentWhenEmpty={SearchRedirectStore.hideEmptyComponent}
        >
          <Column title={t('ID')} dataIndex="id" width={60} sorter={true} />
          <Column
            title={t('Type')}
            className="mk-table-cell--bold"
            dataIndex="type"
            width={60}
            sorter={true}
            render={(type) => {
              const typeMapped = SearchRedirectStore.searchTypes.find(
                (item) => item.id === type
              )?.title
              return (
                <Text weight="bold" element="div">
                  {t(typeMapped)}
                </Text>
              )
            }}
          />
          <Column
            title={
              <div className="word-column">
                <div className="column-language">{t('Language')}</div>
                <div>{t('Words')}</div>
              </div>
            }
            className="mk-table-cell--bold"
            dataIndex="words"
            render={(words) => {
              return (
                <div className="block">
                  {Object.keys(words).map((lang) => (
                    <div key={lang} className="word-column">
                      <Text
                        weight="book"
                        element="div"
                        className="column-language"
                      >
                        {lang}
                      </Text>
                      <Text weight="bold" element="div">
                        {Array.isArray(words[lang])
                          ? words[lang].join(', ')
                          : words[lang]}
                      </Text>
                    </div>
                  ))}
                </div>
              )
            }}
          />
          <Column
            className="cell--align-middle"
            width={50}
            render={() => <ArrowAction />}
          />
        </ResourceTable>
      </div>
    </>
  )
}

export default observer(List)
