import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import LoadingScreen from '../../components/LoadingScreen'
import PageTitle from '../../components/PageTitle'
import { ActionLayerStore, FeedStore } from '../../stores'
import { createRedirectPath, t, useLeaveConfirm } from '../../utils'
import Actions, { calculateArrowPosition } from '../SmartBundles/Actions'
import SettingPanel from './components/SettingPanel'
import FeedStructure from './components/FeedStructure'
import FeedPreview from './components/FeedPreview'
import './feeds.styl'

const Edit = () => {
  const { id } = useParams()
  const settingFormikRef = useRef()
  const fieldsFormikRef = useRef()
  const feed = FeedStore.feedDetail
  const { isDirty } = FeedStore
  const history = useHistory()
  const { state = {} } = useLocation()

  useLeaveConfirm(isDirty)

  // recaculate the arrow position when title changed
  useEffect(() => {
    setTimeout(calculateArrowPosition, 50)
    // eslint-disable-next-line
  }, [feed.name])

  useEffect(() => {
    FeedStore.fetchFeedDetail(id)

    return () => {
      FeedStore.setFeed({})
    }
  }, [id, state.refetch])

  function handleClose() {
    FeedStore.setDirty(false)
    history.push(createRedirectPath('/feeds'))
  }

  async function submitAndValidateFormik() {
    await Promise.all([
      settingFormikRef.current?.submitForm(),
      fieldsFormikRef.current?.submitForm(),
    ])

    if (
      settingFormikRef.current !== undefined &&
      !settingFormikRef.current?.isValid
    ) {
      return false
    }

    if (
      fieldsFormikRef.current !== undefined &&
      !fieldsFormikRef.current?.isValid
    ) {
      return false
    }

    return true
  }

  async function handleSaveAndStay() {
    const isValid = await submitAndValidateFormik()

    if (!isValid) return false

    const newId = await FeedStore.updateOrCreate(FeedStore.feedDetail)

    // We need to redirect to the correct URL if the page was created
    if (newId) {
      history.push(createRedirectPath(`/feeds/${newId}`), { refetch: uuidv4() })
    }
  }

  async function handleSave() {
    const isValid = await submitAndValidateFormik()

    if (!isValid) return false

    await FeedStore.updateOrCreate(FeedStore.feedDetail)
    FeedStore.reset()
    history.push(createRedirectPath('/feeds'))
  }

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        saveTitle: t('Save & back to list'),
        closeTitle: t('Abort & back to list'),
        onSaveWithContinue: handleSaveAndStay,
        onSave: handleSave,
        onClose: handleClose,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  if (FeedStore.state === 'pending') return <LoadingScreen />

  return (
    <>
      <PageTitle
        prefix={feed.id ? t('You are editing') : t('You are creating')}
        metaInfo={
          feed.id && {
            timestamp: feed.changed?.date,
            timezone: feed.changed?.timezone,
            user: feed.user,
            id: feed.id,
          }
        }
        hiddenFields={['internal-title']}
        additionalActions={
          <Actions
            onClickApply={async () => {
              await settingFormikRef.current?.submitForm()
              if (settingFormikRef.current?.isValid) {
                FeedStore.setIsConfigurationPanelShown(false)
              }
            }}
            onClickSetting={() => FeedStore.setIsConfigurationPanelShown(true)}
            isShowConfiguration={FeedStore.configurationPanelShown}
          />
        }
      >
        {feed.name || t('New Feed…')}
      </PageTitle>
      <SettingPanel
        shown={FeedStore.configurationPanelShown}
        formikRef={settingFormikRef}
      />
      <div className="content-wrapper feeds">
        <div className="feeds__detail">
          <FeedStructure formikRef={fieldsFormikRef} />
          <FeedPreview />
        </div>
      </div>
    </>
  )
}

export default observer(Edit)
