import { observer } from 'mobx-react-lite'
import { Tooltip } from 'antd'
import moment from 'moment-timezone'

import { UIStore } from '../../../stores'
import Text from '../../../components/Text'

//TODO: reusing DateColumn component instead of create new one
const DateColumn = ({ date }) => {
  if (!date) return null

  moment.locale(UIStore.currentLanguage)

  const formattedDate = moment.tz(date.date, date.timezone).local().fromNow()
  const tooltip = moment
    .tz(date.date, date.timezone)
    .local()
    .format('DD.MM.YYYY, HH:mm:ss')

  return (
    <Tooltip title={<span>{tooltip}</span>}>
      <Text size="charlie" variant="unset">
        {formattedDate}
      </Text>
    </Tooltip>
  )
}

export default observer(DateColumn)
