import * as Yup from 'yup'
import { t } from '../../utils'

export const getSearchRedirectsFormSchemaV3 = (languages = []) => {
  return Yup.object().shape({
    type: Yup.string().required(t('This field is required.')),
    documentId: Yup.string().when('type', {
      is: (v) => v !== 'url',
      then: (schema) => schema.required(t('This field is required.')),
      otherwise: (schema) => schema.nullable(),
    }),
    targetUrl: Yup.object().when('type', {
      is: (v) => v === 'url',
      then: (schema) =>
        schema.shape(
          languages.reduce((current, lang) => {
            return {
              ...current,
              [lang]: Yup.string().required(t('This field is required.')),
            }
          }, {})
        ),
      otherwise: (schema) => schema.nullable(),
    }),
    words: Yup.object().shape(
      languages.reduce((current, lang) => {
        return {
          ...current,
          [lang]: Yup.array(),
        }
      }, {})
    ),
  })
}
