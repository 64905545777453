import { observer } from 'mobx-react-lite'

import PageTitle from '../../components/PageTitle'
import t from '../../utils/translate'
import { routes } from '../../routing'

const ImporterPageTitle = () => {
  return (
    <PageTitle
      tabData={[
        {
          route: routes.indexOverview,
          title: t('Overview'),
        },
        {
          route: routes.importerSchedule,
          title: t('Importer'),
        },
        {
          route: routes.importerLogs,
          title: t('Logs'),
        },
      ]}
    >
      {t('Index')}
    </PageTitle>
  )
}

export default observer(ImporterPageTitle)
