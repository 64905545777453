import { get } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import { t } from '../../utils'
import ConfigurationStore from '../ConfigurationStore'

class DashboardStore {
  selectedApp = ''
  appDetailModalVisible = false
  onboardingInitiallySectionClosed = false
  onboardingSectionClosed = false
  onboardingSectionHideChecked = false
  isRunningExperiment = false
  runningExperimentId = null
  detectedEffect = null
  statistics = {}
  noTrackingAvailable = false
  state = 'pending' // "pending", "done" or "error"
  isFetchingExperiment = false
  isFetchingStatistics = false

  // TODO: Replace with the Youtube-Video-URL that will be shown on the onboarding section
  // As long as this variabel is empty the onboarding section will not be shown.
  onboardingVideoURL = ''

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  initialize() {
    // We need this because we don't want to directly close the onboarding section when the user checks the checkbox.
    // But this would happen if we don't store the initial state because the configuration will be updated on click.
    this.onboardingInitiallySectionClosed =
      ConfigurationStore.configuration?.hideOnboarding ?? false
  }

  showAppDetailModal(name) {
    this.selectedApp = name
    this.appDetailModalVisible = true
  }

  closeDetailModal() {
    this.selectedApp = ''
    this.appDetailModalVisible = false
  }

  get isOnboardingSectionShown() {
    if (this.onboardingVideoURL.length === 0) return false
    if (this.onboardingInitiallySectionClosed) return false

    return !this.onboardingSectionClosed
  }

  closeOnboardingSection() {
    this.onboardingSectionClosed = true
  }

  *handleOnboardingHideClick() {
    yield ConfigurationStore.updateConfiguration(
      'hideOnboarding',
      !this.onboardingSectionHideChecked
    )

    this.onboardingSectionHideChecked = !this.onboardingSectionHideChecked
  }

  *fetchRunningExperiment() {
    try {
      this.isFetchingExperiment = true
      // Get running experiment
      const running = yield api.experiments.running()
      const runningId = running.data.id
      const isDev =
        window.location.hostname === 'demo.makaira.io' ||
        window.location.hostname === 'localhost'

      if (runningId || isDev) {
        // Get Experiment Data
        const runningResult = yield api.experiments.results(runningId)
        this.runningExperimentId = runningId
        this.detectedEffect = get(
          runningResult,
          'data.table.nb_orders_revenue.1.detected_effect',
          0
        )
        this.isRunningExperiment = true
        this.isFetchingExperiment = false
      } else {
        this.isRunningExperiment = false
        this.runningExperimentId = null
        this.isFetchingExperiment = false
      }
    } catch (error) {
      this.isRunningExperiment = false
      this.runningExperimentId = null
      this.isFetchingExperiment = false
      console.error(error)
      toast.error('Something went wrong...')
    }
  }

  *fetchStatistics() {
    try {
      this.isFetchingStatistics = true
      const { data } = yield api.common.getStatistics()
      this.noTrackingAvailable = !data.tracking_available
      if (!this.noTrackingAvailable) {
        this.statistics = data
      }
      this.isFetchingStatistics = false
    } catch (error) {
      this.noTrackingAvailable = true
      this.isFetchingStatistics = false
      console.error(error)
      toast.error(t('Fetching of KPI went wrong.'))
    }
  }
}

export default new DashboardStore()
