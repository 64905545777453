import { get, makeAutoObservable, set } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import slugify from 'slugify'

const INIT_BANNER_DEVICE_TYPE = [{ name: '' }, { name: '' }, { name: '' }]

const INIT_BANNER_TYPE = [
  { label: '', slug: '', deviceTypeIds: [], deviceTypeIndexs: [] },
  { label: '', slug: '', deviceTypeIds: [], deviceTypeIndexs: [] },
  { label: '', slug: '', deviceTypeIds: [], deviceTypeIndexs: [] },
  { label: '', slug: '', deviceTypeIds: [], deviceTypeIndexs: [] },
  { label: '', slug: '', deviceTypeIds: [], deviceTypeIndexs: [] },
]

class GeneralSettingsBannerStore {
  state = 'pending' // "pending", "done" or "error"

  deviceTypes = []
  bannerTypes = []

  editingDeviceTypes = []
  editingBannerTypes = []
  isDirty = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetchDeviceTypes() {
    this.isDirty = false
    this.state = 'pending'
    try {
      const { data } = yield api.banners.getDeviceTypes()
      // const data = yield Promise.resolve([])

      this.deviceTypes = data
      this.prepareEditingDeviceTypesData(data)
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the category listing.')
      this.state = 'error'
    }
  }

  *updateDeviceTypes() {
    const { data } = yield api.banners.updateDeviceTypes(
      this.editingDeviceTypes
    )
    this.deviceTypes = data
    this.prepareEditingDeviceTypesData(data)
  }

  *fetchBannerType() {
    this.state = 'pending'
    try {
      const { data } = yield api.banners.getBannerTypes()
      // const data = yield Promise.resolve([])
      this.bannerTypes = data
      this.prepareEditingBannerTypesData(data)
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the banner type.')
      this.state = 'error'
    }
  }

  /**
   * Depend on updateDeviceTypes, MUST call updateDeviceTypes
   * to get latest deviceType's Id for mapping with index
   */
  *updateBannerType() {
    const updatingBannerTypes = this.editingBannerTypes
      .map((type) => {
        const { deviceTypeIndexs = [], ...rest } = type

        const deviceTypeIds = deviceTypeIndexs
          .map((index) => {
            if (this.deviceTypes[index] && this.deviceTypes[index].name)
              return this.deviceTypes[index].id
            return null
          })
          .filter((id) => !!id)

        return {
          ...rest,
          deviceTypeIds,
        }
      })
      .filter((item) => !!item)

    const { data } = yield api.banners.updateBannerTypes(updatingBannerTypes)
    this.bannerTypes = data
    this.prepareEditingBannerTypesData(data)
  }

  *saveBannerSettings() {
    try {
      this.isDirty = false
      this.state = 'pending'
      yield this.updateDeviceTypes()
      yield this.updateBannerType()
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
      console.log(error)
      this.isDirty = true
    }
  }

  prepareEditingDeviceTypesData(deviceTypes = []) {
    this.editingDeviceTypes = [
      ...deviceTypes,
      ...INIT_BANNER_DEVICE_TYPE.slice(deviceTypes.length),
    ]
  }

  /**
   * only call after already fetched DeviceTypes
   */
  prepareEditingBannerTypesData(bannerTypes = []) {
    // we have to handle index instead of id
    // due to new instance does not created device type yet =>
    // device type's id does not exist
    const mapDeviceIdToIndex = (deviceId) =>
      this.editingDeviceTypes.findIndex((item) => item.id === deviceId)

    this.editingBannerTypes = [
      ...bannerTypes.map((item) => {
        return {
          ...item,
          deviceTypeIndexs: item.deviceTypeIds
            .map(mapDeviceIdToIndex)
            .filter((index) => index >= 0),
        }
      }),
      ...INIT_BANNER_TYPE.slice(bannerTypes.length),
    ]
  }

  onChangeDeviceTypeName(value, changingIndex) {
    set(this.editingDeviceTypes[changingIndex], 'name', value)
    this.isDirty = true
  }

  onChangeBannerTypeDevice(checked, changingDeviceIndex, changingIndex) {
    let deviceTypeIndexs =
      get(this.editingBannerTypes[changingIndex], `deviceTypeIndexs`) || []
    if (!checked) {
      deviceTypeIndexs = deviceTypeIndexs.filter(
        (deviceIndex) => deviceIndex !== changingDeviceIndex
      )
    } else {
      deviceTypeIndexs.push(changingDeviceIndex)
    }
    set(
      this.editingBannerTypes[changingIndex],
      'deviceTypeIndexs',
      deviceTypeIndexs
    )
    this.isDirty = true
  }

  onChangeBannerLabel(value = '', changingIndex) {
    const slug = slugify(value.toLowerCase(), { trim: true, replacement: '-' })
    set(this.editingBannerTypes[changingIndex], 'label', value)
    set(this.editingBannerTypes[changingIndex], 'slug', slug)
    this.isDirty = true
  }

  onDiscardBannerSettings() {
    this.prepareEditingDeviceTypesData(this.deviceTypes)
    this.prepareEditingBannerTypesData(this.bannerTypes)
    this.isDirty = false
  }

  discard() {
    this.editingBannerTypes = []
    this.editingDeviceTypes = []
    this.isDirty = false
  }
}

export default new GeneralSettingsBannerStore()
