import { Switch, Route, Redirect } from 'react-router-dom'
import routes from './routes'
import createInternalPath from '../utils/createInternalPath'
import getInstance from '../utils/getInstance'
import { observer } from 'mobx-react-lite'
import RouterV3 from './RouterV3'

function AppRouter() {
  const v3Routes = Object.values(routes)
    .filter((r) => r.v3 == true)
    .map((r) => createInternalPath(r.path))

  return (
    <Switch>
      <Route path={v3Routes} component={RouterV3} />

      <Route>
        <Redirect to={'/' + getInstance() + routes.dashboard.path} />
      </Route>
    </Switch>
  )
}

export default observer(AppRouter)
