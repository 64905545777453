import { buildQueryString } from '../utils'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getDeployments() {
    return fetch('/storefront/deployments')
  },

  async getConfigVars() {
    return fetch('/storefront/config-vars')
  },

  async updateConfigVars(data) {
    return fetch('/storefront/config-vars', {
      method: 'PUT',
      body: JSON.stringify(data),
    })
  },

  async getBranches() {
    return fetch('/storefront/deployments/github/branches')
  },

  async updateAutoDeployBranch(branch) {
    return fetch('/storefront/deployments/github/deploy-branch', {
      method: 'PUT',
      body: JSON.stringify({ branch }),
    })
  },

  async triggerDeploy() {
    return fetch('/storefront/deployments/github/deploy', {
      method: 'POST',
    })
  },

  async getDomains() {
    return fetch('/storefront/domains')
  },

  async postDomain(hostname) {
    return fetch('/storefront/domains', {
      method: 'POST',
      body: JSON.stringify({ hostname }),
    })
  },

  async deleteDomain(domainIdOrHostname) {
    return fetch(`/storefront/domains/${domainIdOrHostname}`, {
      method: 'DELETE',
    })
  },

  async refreshACM() {
    return fetch('/storefront/apps/refresh-acm', {
      method: 'PATCH',
    })
  },

  async getLogs(options) {
    const queryString = buildQueryString(options)

    const target = `/storefront/log?${queryString}`

    return fetch(target)
  },
}
