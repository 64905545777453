import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory } from 'react-router'
import Button from '../../components/Button'
import DropdownButton from '../../components/DropdownButton'
import Icon from '../../components/Icon'
import ResourceTable from '../../components/ResourceTable'
import { ArrowAction, Column } from '../../components/Table'
import Text from '../../components/Text'
import { MonitoringStore } from '../../stores'
import { createRedirectPath, t } from '../../utils'
import { CHART_COLORS } from './constants'

const Tables = () => {
  const history = useHistory()

  return (
    <div className="search-monitoring__search-term">
      <div>
        <Text
          className="search-monitoring__search-term-title"
          size="delta"
          weight="bold"
        >
          {t('Top search terms')}
          <Button
            iconPosition="left"
            variant="tertiary"
            level={1}
            icon="file-csv"
            onClick={() => MonitoringStore.handleDownload()}
          >
            {t('Download results')}
          </Button>
        </Text>
        <ResourceTable
          hideEmptyComponentWhenEmpty={true}
          store={MonitoringStore}
          resourceName="monitoringPopular"
          loading={MonitoringStore.fetchingPopular}
          emptyText={t('No data')}
          pagination={MonitoringStore.paginationPopular}
          setPagination={MonitoringStore.setPaginationPopular}
        >
          <Column
            width={'auto'}
            title={t('Name')}
            dataIndex="search_phrase"
            render={(text, _, index) => (
              <div
                style={{
                  '--chart-color':
                    (MonitoringStore.paginationPopular.current === 1 &&
                      MonitoringStore.top5SearchTerms.length > 0 &&
                      CHART_COLORS[index]) ||
                    'transparent',
                }}
                className="search-monitoring__name-column"
              >
                <Icon width="18px" height="18px" symbol="circle__solid" />{' '}
                <Text weight="bold">{text}</Text>
              </div>
            )}
          />
          <Column
            width={110}
            title={t('Number of hits')}
            dataIndex="count"
            align="right"
            render={(text) => (
              <Text weight="bold">
                {new Intl.NumberFormat().format(text || 0)}
              </Text>
            )}
          />
        </ResourceTable>
      </div>
      <div>
        <Text
          className="search-monitoring__search-term-title"
          size="delta"
          weight="bold"
        >
          {t('Top failed search terms')}
          <Button
            iconPosition="left"
            variant="tertiary"
            level={1}
            icon="file-csv"
            onClick={() => MonitoringStore.handleDownload(true)}
          >
            {t('Download results')}
          </Button>
        </Text>
        <ResourceTable
          hideEmptyComponentWhenEmpty={true}
          store={MonitoringStore}
          resourceName="monitoringPopularNoResults"
          loading={MonitoringStore.fetchingPopularNoResults}
          rowKey="name"
          emptyText={t('No data')}
          pagination={MonitoringStore.paginationPopularNoResults}
          setPagination={MonitoringStore.setPaginationPopularNoResults}
        >
          <Column
            title={t('Status')}
            align="center"
            width={50}
            dataIndex="status"
            className="search-monitoring__status-column"
            render={(status) => (
              <Icon
                width="18px"
                height="18px"
                symbol={
                  !status?.handled
                    ? 'triangle-exclamation__solid'
                    : 'circle-check__solid'
                }
              />
            )}
          />
          <Column
            width={300}
            title={t('Name')}
            dataIndex="search_phrase"
            render={(text) => <Text weight="bold">{text}</Text>}
          />
          <Column
            title={t('Number of hits')}
            dataIndex="count"
            width={110}
            align="right"
            render={(text) => (
              <Text weight="bold">
                {new Intl.NumberFormat().format(text || 0)}
              </Text>
            )}
          />
          <Column
            align="right"
            width={150}
            dataIndex="status"
            render={(status, record) =>
              !status?.handled ? (
                <DropdownButton
                  actionIcon="plus"
                  text={t('Add as...')}
                  options={[
                    { label: 'Synonym', value: '/synonyms/new' },
                    { label: 'Antonym', value: '/antonyms/new' },
                    { label: 'Redirect', value: '/search-redirects/new' },
                  ]}
                  onSelect={(value) =>
                    history.push(createRedirectPath(value.key), {
                      searchTerm: record.search_phrase,
                      language: MonitoringStore.filter.language,
                    })
                  }
                  buttonLevel={1}
                  destroyTooltipOnHide
                  variant="secondary"
                />
              ) : (
                <ArrowAction />
              )
            }
          />
        </ResourceTable>
      </div>
    </div>
  )
}

export default observer(Tables)
