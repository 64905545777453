import { Popover } from 'antd'
import { AppStore, EditorStore } from '../../stores'
import Menu from '../Menu'
import Text from '../Text'
import Icon from '../Icon'

// { id, label, type } = fieldInfo
export function InputGeneratorApps({ apps = [], fieldInfo = {} }) {
  if (apps.length === 0) return null

  const availabelApps = apps
    .map((appSlug) => {
      return AppStore.externalContentModals.find(
        (modal) => modal.slug === appSlug
      )
    })
    .filter((app) => !!app)

  if (availabelApps.length === 0) return null

  const content = (
    <Menu className="dropdown-button__menu">
      {availabelApps.map((app) => {
        return (
          <Menu.MenuItem
            key={app.slug}
            onClick={() => EditorStore.showContenModalApp(app, fieldInfo)}
          >
            <Text weight="bold">{app.title['de']}</Text>
          </Menu.MenuItem>
        )
      })}
    </Menu>
  )

  return (
    <div className="input-editor__content-modal--listing">
      <Popover
        trigger="hover"
        content={content}
        placement="bottomLeft"
        overlayClassName="dropdown-button__popover"
        destroyTooltipOnHide={true}
      >
        <span className="input-editor__content-modal-app">
          <Icon symbol="lightbulb-on" width={16} height={16} />
        </span>
      </Popover>
    </div>
  )
}
