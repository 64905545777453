import { observer } from 'mobx-react-lite'
import Select from '../../../components/Select'
import Text from '../../../components/Text'
import StorefrontSettingStore from '../../../stores/StorefrontSettingStore'
import { t } from '../../../utils'

const BranchInput = () => {
  const handleUpdateBranch = (value) => {
    StorefrontSettingStore.updateBranchInput(value)
    StorefrontSettingStore.updateAutoDeployBranch()
  }

  const branches = StorefrontSettingStore.branches.branches || []

  return (
    <div className="storefront-settings__branch-input">
      <Text>{t('Set branch for auto-deploy')}</Text>
      <div className="storefront-settings__branch-input-divider"></div>
      <Select
        value={StorefrontSettingStore.branchInput}
        options={branches.map((branch) => ({
          value: branch,
          label: branch,
        }))}
        onChange={handleUpdateBranch}
      />
    </div>
  )
}

export default observer(BranchInput)
