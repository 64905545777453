import React, { Component } from 'react'

async function logError(error) {
  const href = window.location.href
  const instance = sessionStorage.getItem('instance')

  await fetch(
    'https://hooks.slack.com/services/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',
    {
      method: 'POST',
      body: JSON.stringify({
        channel: '#mec-frontend-errors',
        text: `${href} | ${instance} | ${error.message}`,
      }),
    }
  )
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    if (error) {
      return { hasError: true }
    }
  }

  componentDidCatch(error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error)
    } else {
      logError(error)
    }
  }

  render() {
    if (this.state.hasError) {
      return <span>Sorry, hier ist wohl etwas kaputt gegangen. :(</span>
    }

    return this.props.children
  }
}
