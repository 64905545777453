import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { toJS } from 'mobx'
import SortableTree from '@makaira/react-sortable-tree'

import PageTitle from '../../components/PageTitle'
import t from '../../utils/translate'
import CategoryMappingStore from '../../stores/CategoryMappingStore'
import ContentWrapper from '../../components/ContentWrapper'
import Switch from '../../components/Switch'
import MenuEditorNodeWrapper from './MenuEditorNodeWrapper'
import LoadingScreen from '../../components/LoadingScreen'
import { ActionLayerStore } from '../../stores'

import './categoryMapping.styl'

function CategoryMapping() {
  const { isDirty } = CategoryMappingStore

  useEffect(() => {
    CategoryMappingStore.fetchMapping()
  }, [])

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        onSave: CategoryMappingStore.saveMapping,
        onClose: CategoryMappingStore.reset,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  function updateNode(menu, id, value) {
    function updateById(entry) {
      if (entry.uuid === id) {
        return value
      }

      entry.children = updateNode(entry.children, id, value)

      return entry
    }

    return menu.map(updateById)
  }

  const treeHelper = {
    updateNode: (id, value, setDirty) => {
      const updated = updateNode(toJS(CategoryMappingStore.tree), id, value)

      CategoryMappingStore.updateTree(updated, setDirty)
    },
  }

  if (CategoryMappingStore.state === 'pending') return <LoadingScreen />

  return (
    <>
      <PageTitle prefix={t('You are editing')}>
        {t('Category Mapping')}
      </PageTitle>

      <ContentWrapper>
        <div className="category-mapping__id-switch">
          <Switch
            onChange={(value) => CategoryMappingStore.setShowCategoryIds(value)}
            checked={CategoryMappingStore.showCategoryIds}
          />
          <p>{t('Show Category-Ids')}</p>
        </div>

        <SortableTree
          treeData={CategoryMappingStore.tree}
          isVirtualized={false}
          theme={{
            nodeContentRenderer: (props) => (
              <MenuEditorNodeWrapper {...props} treeHelper={treeHelper} />
            ),
          }}
          onChange={(updated) =>
            CategoryMappingStore.updateTree(updated, false)
          }
        />
      </ContentWrapper>
    </>
  )
}

export default observer(CategoryMapping)
