import React from 'react'
import Icon from '../Icon'
import Text from '../Text'

import t from '../../utils/translate'

import './style.styl'

const ABPlaceholder = () => {
  return (
    <div className="ab-placeholder">
      <Icon symbol="arrow-up" width={50} height={50} />
      <Text weight="bold">
        {t('This area will be editable once your test is configured')}
      </Text>
      <Text variant="book">
        {t('Please finish the test setup above first…')}
      </Text>
    </div>
  )
}

export default ABPlaceholder
