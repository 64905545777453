import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getExternalApps() {
    return fetch('/app')
  },

  async getExternalAppsUrl(slug) {
    return fetch(`/app/${slug}`)
  },

  /**
   * Check if the received credentials via the Message-Flow from an App are valid.
   *
   * @param slug {string}
   * @param credentials {{hmac: string, makairaHmac: string, nonce: string}}
   * @returns {Promise<{ valid: boolean }>}
   */
  async checkValidApp(slug, credentials) {
    const { data } = await fetch(`/app/${slug}/validate`, {
      method: 'POST',
      body: JSON.stringify(credentials),
    })

    return data
  },
}
