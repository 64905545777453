import './autoLoadIndicator.styl'

const AutoLoadIndicator = () => {
  return (
    <div className="auto-load-indicator">
      <div className="auto-load-indicator__dots" />
    </div>
  )
}

export default AutoLoadIndicator
