import { observer } from 'mobx-react-lite'
import React from 'react'
import NumberInput from '../../NumberInput'
import Select from '../../Select'
import { t } from '../../../utils'

const InputDayField = ({ onChange, value }) => {
  const arrayValue = value || []

  return (
    <div className="filter-section__input-group">
      <Select
        flexible
        dropdownMatchSelectWidth={false}
        options={[
          {
            label: t('last'),
            value: 'last',
          },
          {
            label: t('next'),
            value: 'next',
          },
        ]}
        onChange={(data) => onChange([data, arrayValue[1]])}
        value={arrayValue[0]}
      />
      <NumberInput
        onChange={(numb) => onChange([arrayValue[0], numb])}
        value={arrayValue[1]}
      />
    </div>
  )
}

export default observer(InputDayField)
