import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'

const ActiveRange = ({
  activeRangeStartDate,
  setActiveRangeStartDate,
  activeRangeDays,
  // setActiveRangeDays, //TODO: add resize active range
  totalDays,
  totalLine,
  startDate,
  endDate,
  loading,
  timelineWidth,
}) => {
  const startDateMoment = moment(startDate)
  const endDateMoment = moment(endDate)
  const isInitOffset = useRef(false)

  const [activeRangeOffset, setActiveRangeOffset] = useState(0)
  const mouseOffset = useRef(0)

  // variable that is activeRangeDays but not larger than the difference between startDate and endDate
  const finalActiveRangeDays = useMemo(() => {
    let maxDays = endDateMoment.diff(startDateMoment, 'days')
    maxDays = maxDays - 30 > 30 ? maxDays - 30 : maxDays
    return Math.min(activeRangeDays, maxDays)
  }, [activeRangeDays, endDateMoment, startDateMoment])

  useEffect(() => {
    if (loading) return
    if (!isInitOffset.current) {
      const initStartDate = moment().subtract(60, 'days')

      const offsetDays = initStartDate.diff(startDateMoment, 'days')
      const offsetPercent = offsetDays / totalDays

      setActiveRangeOffset(offsetPercent * timelineWidth)
      isInitOffset.current = true
      return
    }

    if (
      activeRangeStartDate.isBefore(startDateMoment) ||
      activeRangeStartDate
        .clone()
        .add(finalActiveRangeDays, 'days')
        .isAfter(endDateMoment)
    ) {
      setActiveRangeOffset(0)
    }

    // eslint-disable-next-line
  }, [startDate, endDate, loading])

  useEffect(() => {
    if (!isInitOffset.current) {
      return
    }

    const offsetPercent = activeRangeOffset / timelineWidth
    const offsetDays = offsetPercent * totalDays
    const newStartDateMoment = startDateMoment
      .clone()
      .add(Math.round(offsetDays), 'days')

    setActiveRangeStartDate(newStartDateMoment)
    // eslint-disable-next-line
  }, [activeRangeOffset, startDate, endDate])

  function dragMouseDown(e) {
    document.body.style.cursor = 'grabbing'
    e = e || window.event
    e.preventDefault()
    // get the mouse cursor position at startup:
    mouseOffset.current = e.clientX
    document.onmouseup = closeDragElement
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag
  }

  function elementDrag(e) {
    e = e || window.event
    e.preventDefault()
    // calculate the new cursor position:
    setActiveRangeOffset((current) => {
      const newActiveRangeOffset = current + (e.clientX - mouseOffset.current)
      if (newActiveRangeOffset < 0) return 0
      const activeRangeWidth =
        (parseFloat(activeRangePosition.width) * timelineWidth) / 100
      if (newActiveRangeOffset + activeRangeWidth > timelineWidth)
        return timelineWidth - activeRangeWidth

      return newActiveRangeOffset
    })
    mouseOffset.current = e.clientX
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.body.style.cursor = null
    document.onmouseup = null
    document.onmousemove = null
  }

  const activeRangePosition = useMemo(() => {
    const offsetStartDate =
      (activeRangeStartDate.diff(startDateMoment, 'days') / totalDays) * 100
    const offsetEndDate =
      (activeRangeStartDate
        .clone()
        .add(finalActiveRangeDays, 'days')
        .diff(startDateMoment, 'days') /
        totalDays) *
      100

    return {
      width: offsetEndDate - offsetStartDate + '%',
    }
    // eslint-disable-next-line
  }, [activeRangeStartDate])

  return (
    <div
      onMouseDown={dragMouseDown}
      className="ab-testing__timeline-active-range"
      style={{
        left: activeRangeOffset,
        width: activeRangePosition.width,
        height: 12 + totalLine * 8,
      }}
    />
  )
}

export default ActiveRange
