import { Tooltip } from 'antd'
import Text from '../../../components/Text'

export default function ColumnWithTooltip({ text, tooltip }) {
  return (
    <Tooltip title={<span className="revision-column__text">{tooltip}</span>}>
      <Text>{text}</Text>
    </Tooltip>
  )
}
