import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import Button from '../../components/Button'
import Modal from '../../components/Modal'
import Select from '../../components/Select'
import Sortable from '../../components/Sortable'
import { FilterStore, UIStore } from '../../stores'
import { t } from '../../utils'
import SortItem from './SortItem'

const SortModal = () => {
  const { sortModalVisible, closeSortModal, sortOptions } = FilterStore

  const [sortedList, setSortedList] = useState(sortOptions)
  const [language, setLanguage] = useState(
    UIStore.enterpriseConfiguration.defaultLanguage
  )

  useEffect(() => {
    setSortedList(sortOptions)
  }, [sortOptions])

  return (
    <Modal
      visible={sortModalVisible}
      onClose={closeSortModal}
      header={t('Order of values')}
      width={700}
      mask
      maskClosable={false}
      footer={[
        <Button onClick={closeSortModal} key="cancel">
          {t('Cancel')}
        </Button>,
        <Button
          onClick={() => {
            FilterStore.onChangeFilter(
              FilterStore.filterDetail,
              'valueSorting',
              sortedList
            )
            closeSortModal()
          }}
          variant="primary"
          key="create"
        >
          {t('Confirm')}
        </Button>,
      ]}
    >
      <div className="filter-edit__sort-modal">
        <div className="filter-edit__sort-modal-search-row">
          <Select
            options={UIStore.languages.map((l) => ({
              value: l,
              label: l.toUpperCase(),
            }))}
            flexible
            value={language}
            dropdownMatchSelectWidth={false}
            onChange={setLanguage}
          />
        </div>
        <div className="filter-edit__sort-modal-list">
          <Sortable
            id="sortable"
            items={sortedList[language] || []}
            itemComponent={SortItem}
            onChange={(val) => {
              const updatedPosition = val.map((v, index) => ({
                ...v,
                position: index + 1,
              }))
              setSortedList((list) => ({
                ...list,
                [language]: updatedPosition,
              }))
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default observer(SortModal)
