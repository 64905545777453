import { cloneDeep } from 'lodash'

export const getBadgeProps = (type) => {
  switch (type) {
    case 'done':
      return {
        variant: 'primary',
        icon: 'check-circle',
      }

    case 'running':
      return {
        variant: 'success',
        spinner: true,
        icon: 'spinner-third',
      }

    case 'scheduled':
      return {
        variant: 'secondary',
        icon: 'calendar',
      }
    case 'draft':
      return {
        variant: 'secondary',
        icon: 'pencil',
      }
    default:
      return {}
  }
}

export const SIZES = {
  overview: {
    itemHeight: 4,
    paddingTop: 4,
  },
  detail: {
    itemHeight: 24,
    paddingTop: 10,
  },
}

export function calculateLineNumbers(events) {
  const lines = []

  // Add 45 days to events with no end date, and today's date to events with no start date
  const eventsWithEndDates = cloneDeep(events).map((event) => {
    if (!event.start_date) {
      event.start_date = {
        date: new Date(),
      }
    }

    if (!event.end_date) {
      event.end_date = {
        date: new Date(
          new Date(event.start_date.date).getTime() + 45 * 24 * 60 * 60 * 1000
        ),
      }
    }

    return event
  })

  // sort events by start date
  eventsWithEndDates.sort(
    (a, b) => new Date(a.start_date.date) - new Date(b.start_date.date)
  )

  // initialize an array to keep track of the end dates for each line
  const lineEndDates = []

  // loop through each event and calculate its line number
  eventsWithEndDates.forEach((event) => {
    let line = 0
    while (
      lineEndDates[line] &&
      new Date(event.start_date.date) < lineEndDates[line]
    ) {
      line++
    }

    // set the line number for this event
    lines.push({ line, id: event.id })

    // update the end date for the line that this event is on
    lineEndDates[line] = new Date(event.end_date.date)
  })

  return lines
}
