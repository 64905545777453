import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import transparentPattern from '../../assets/images/transparent-pattern.gif'
import Button from '../Button'
import Spot from './Spot'
import { prepareImageUrl } from '../../utils'
import { UIStore } from '../../stores'

function ImageWithSpots(props) {
  const [dragIndex, setDragIndex] = useState(null)
  const {
    preview,
    onRemovePreview,
    updateOrAddSpot,
    spots = [],
    readOnly,
  } = props
  const imageRef = useRef()

  const {
    enterpriseConfiguration: { storageType, assetUrl },
  } = UIStore

  const { url: imageUrl } = prepareImageUrl({
    source: preview,
    storageType,
    assetUrl,
  })

  const calculatorPosition = (event) => {
    const { target } = event
    const absolutePositionInPixel = {
      x: event.clientX - target.getBoundingClientRect().left,
      y: event.clientY - target.getBoundingClientRect().top,
    }

    const image = imageRef.current
    return {
      left: Math.round((absolutePositionInPixel.x / image.clientWidth) * 100),
      top: Math.round((absolutePositionInPixel.y / image.clientHeight) * 100),
    }
  }

  const handleMouseUp = (event) => {
    event.preventDefault()

    const spot = calculatorPosition(event)

    updateOrAddSpot({ spot, dragIndex })
    setDragIndex(null)
  }

  const handleMouseDown = (index) => setDragIndex(index)

  return (
    <div
      className="hotspot__preview"
      style={{ background: `url(${transparentPattern})` }}
    >
      <div className="hotspot__preview--image">
        <img
          ref={imageRef}
          src={imageUrl}
          onMouseUp={handleMouseUp}
          onDragOver={(e) => e.preventDefault()} // allow draggable
          onDrop={handleMouseUp}
        />

        {spots.length > 0 &&
          spots.map((spot, index) => (
            <Spot
              key={index}
              index={index + 1}
              onMouseDown={() => handleMouseDown(index)}
              {...spot}
            />
          ))}
      </div>
      {!readOnly && (
        <Button
          icon="trash"
          variant="tertiary"
          className="hotspot__preview--remove"
          onClick={onRemovePreview}
        />
      )}
    </div>
  )
}

export default observer(ImageWithSpots)
