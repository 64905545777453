import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import TextInput from '../../../components/TextInput'
import StorefrontSettingStore from '../../../stores/StorefrontSettingStore'
import { t } from '../../../utils'

const Domains = () => {
  const { domains, newDomains, getDomainByHostname } = StorefrontSettingStore

  const defaultDomain =
    domains.find((domain) => domain.kind === 'makaira') || {}

  const handleDomainStatus = (domain) => {
    if (domain.acm_status === 'cert issued')
      return (
        <Text weight="bold">
          <Icon width={15} height={15} symbol="check__regular" />
          {t('Certificate issued')}&nbsp;
          {domain.kind === 'makaira' && (
            <Text>{t('Default Domain, cannot be deleted')}</Text>
          )}
        </Text>
      )

    if (domain.acm_status === 'failing' || domain.acm_status === 'failed')
      return (
        <Text weight="bold" className="storefront-settings__domain-error">
          <Icon width={15} height={15} symbol="triangle-exclamation" />
          {t('Error')}&nbsp;
          <Text>{domain.acm_status_reason}</Text>&nbsp;
          <Button
            loading={StorefrontSettingStore.isRefreshingACM}
            onClick={StorefrontSettingStore.refreshACM}
            iconPosition="left"
            icon="sync"
            variant="tertiary"
          >
            {t('Try again')}
          </Button>
        </Text>
      )

    return domain.acm_status
  }

  return (
    <>
      <div className="storefront-settings__sub-title">
        <Text size="echo" weight="bold">
          {t('Domains')}
        </Text>
      </div>
      <div className="storefront-settings__section-wrapper">
        <Text size="delta" weight="bold">
          {t('Active domains')}
        </Text>
        <div className="storefront-settings__domain-grid">
          <Text>{t('Domain name')}</Text>
          <Text>{t('Certificate status')}</Text>
          <div></div>
          <div className="line"></div>

          {domains
            .slice()
            .reverse()
            .map((domain, index) => (
              <Fragment key={domain.id}>
                {index !== 0 && <div className="line"></div>}
                <Text weight="bold">{domain.hostname}</Text>
                {handleDomainStatus(domain)}
                {domain.kind !== 'makaira' ? (
                  <Button
                    loading={domain.loading}
                    onClick={() => StorefrontSettingStore.deleteDomain(domain)}
                    level={2}
                    variant="tertiary"
                    icon="trash"
                  />
                ) : (
                  <div></div>
                )}
              </Fragment>
            ))}
        </div>
      </div>
      {newDomains.map((domain) => {
        const domainInfo = getDomainByHostname(domain.hostname)

        return (
          <div
            key={domain.id}
            className="storefront-settings__section-wrapper storefront-settings__section-wrapper--light"
          >
            <Text size="delta" weight="bold">
              {t('Add a domain')}
            </Text>
            <div className="storefront-settings__domain-input-grid">
              <div
                className={classNames('storefront-settings__step', {
                  'storefront-settings__step--success': domain.step > 1,
                })}
              >
                1
              </div>
              <TextInput
                label={t('Domain')}
                value={domain.hostname}
                error={domain.error}
                disabled={domain.step !== 1}
                onChange={(e) =>
                  StorefrontSettingStore.updateDomain(
                    domain,
                    'hostname',
                    e.target.value
                  )
                }
              />
              <div className="storefront-settings__step-actions">
                {domain.step === 1 && (
                  <Button
                    disabled={!domain.hostname || domain.error}
                    icon="chevron-right"
                    variant="primary"
                    onClick={() =>
                      StorefrontSettingStore.updateDomain(domain, 'step', 2)
                    }
                  >
                    {t('Proceed to step 2')}
                  </Button>
                )}
              </div>

              {domain.step >= 2 && (
                <>
                  <div
                    className={classNames('storefront-settings__step', {
                      'storefront-settings__step--success': domain.step > 2,
                    })}
                  >
                    2
                  </div>
                  <TextInput
                    value={defaultDomain.hostname}
                    label={t(
                      'Snippet for DNS configuration. Add as a CNAME entry'
                    )}
                    disabled
                  />
                  <div className="storefront-settings__step-actions">
                    <CopyToClipboard
                      text={defaultDomain.hostname}
                      onCopy={() => toast.success(t('Copied') + '!')}
                    >
                      <Button icon="copy" variant="reduced" />
                    </CopyToClipboard>
                    {domain.step === 2 && (
                      <Button
                        icon="check"
                        variant="primary"
                        onClick={() => {
                          StorefrontSettingStore.updateDomain(domain, 'step', 3)
                          StorefrontSettingStore.postDomain(domain)
                        }}
                      >
                        {t('Done adding DNS entry')}
                      </Button>
                    )}
                  </div>
                </>
              )}
              {domain.step >= 3 && (
                <>
                  <div
                    className={classNames(
                      'storefront-settings__step storefront-settings__step--last',
                      {
                        'storefront-settings__step--waiting': domain.step === 3,
                        'storefront-settings__step--error':
                          domain.step === 4 &&
                          domainInfo.acm_status !== 'cert issued',
                      }
                    )}
                  >
                    3
                  </div>
                  {domain.step === 3 && (
                    <Text className="storefront-settings__waiting-dns">
                      <Icon symbol="hourglass-half" width={15} height={15} />{' '}
                      {t(
                        'We check now for your DNS entry, this can take up to 2 minutes'
                      )}
                    </Text>
                  )}

                  {domain.step === 4 &&
                    domainInfo.acm_status === 'cert issued' && (
                      <Text className="storefront-settings__waiting-dns">
                        <Icon symbol="check" width={15} height={15} />{' '}
                        {t('The domain was successfully added')}
                      </Text>
                    )}

                  {domain.step === 4 &&
                    domainInfo.acm_status !== 'cert issued' && (
                      <>
                        <Text className="storefront-settings__waiting-dns">
                          <Icon
                            symbol="triangle-exclamation"
                            width={15}
                            height={15}
                          />{' '}
                          {domainInfo.acm_status_reason}
                        </Text>
                        <div>
                          <Button
                            loading={StorefrontSettingStore.isRefreshingACM}
                            onClick={StorefrontSettingStore.refreshACM}
                            iconPosition="left"
                            icon="sync"
                          >
                            {t('Try again')}
                          </Button>
                        </div>
                      </>
                    )}
                </>
              )}
            </div>
          </div>
        )
      })}

      {newDomains.length === 0 && (
        <Button
          level={1}
          onClick={StorefrontSettingStore.addNewDomain}
          iconPosition="left"
          variant="tertiary"
          icon="plus"
        >
          {t('Add a domain')}
        </Button>
      )}
    </>
  )
}

export default observer(Domains)
