import { useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'
import Text from '../../../../components/Text'
import TextInput from '../../../../components/TextInput'
import { FeedStore } from '../../../../stores'
import { generateRandomString, getInstance, t } from '../../../../utils'

const FeedUrl = () => {
  const formikProps = useFormikContext()
  const feed = FeedStore.feedDetail

  return (
    <div className="feed-setting__feed-url">
      <div className="feed-setting__feed-url-row">
        <TextInput
          width={300}
          label="Name"
          value={formikProps.values.name}
          error={formikProps.errors.name}
          onChange={(e) => formikProps.setFieldValue('name', e.target.value)}
        />
        <TextInput
          value={feed.token}
          width={300}
          label="Token"
          onChange={(e) => FeedStore.updateFeedData('token', e.target.value)}
          icon={{
            symbol: 'dice',
            onClick: () =>
              FeedStore.updateFeedData('token', generateRandomString(12)),
            tooltip: t('Click to generate a token'),
          }}
        />
      </div>
      <div className="feed-setting__feed-url-preview">
        <Text variant="book">URL</Text>
        <p>
          {`${window.location.origin}/feeds/{lang}/`}
          <span>{formikProps.values.name}</span>
          {`/?instance=${getInstance()}`}
          {feed.token && (
            <>
              &token=<span>{feed.token}</span>
            </>
          )}
        </p>
      </div>
    </div>
  )
}

export default observer(FeedUrl)
