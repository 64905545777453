import { observer } from 'mobx-react-lite'
import get from 'lodash/get'
import Text from '../../components/Text'
import { FailureIcon, SuccessIcon } from './Icons'
import Button from '../../components/Button'

import { CHECKS } from './constants'
import PersonalizationStore from '../../stores/PersonalizationStore'
import t from '../../utils/translate'

const PersonalizationCheck = observer(
  ({ success, number, title, message, additionalApiError }) => {
    return (
      <div className="personalization__check">
        <div className="personalization__check-status">
          {success ? <SuccessIcon /> : <FailureIcon />}
        </div>

        <div>
          <Text element="p" size="delta">
            {number}. {t(title)}
          </Text>
          <Text>{t(message)}</Text>
          {additionalApiError && (
            <Text element="p">
              {t('Detailed Reason')}: {additionalApiError}
            </Text>
          )}
        </div>
      </div>
    )
  }
)

const Checklist = () => {
  if (
    PersonalizationStore.status &&
    (!PersonalizationStore.isPersonalizationPossible ||
      PersonalizationStore.status?.allPassed?.result === true)
  )
    return null

  return (
    <div className="personalization__check-wrapper">
      {CHECKS.map((check, index) => {
        const successMessage = 'Great, this seems to work. Well done!'
        const checkResponse = get(
          PersonalizationStore,
          ['status', check.apiKey],
          {}
        )

        const success = checkResponse.result || false

        return (
          <PersonalizationCheck
            success={success}
            key={check.title}
            number={index + 1}
            title={check.title}
            message={success ? successMessage : check.errorMessage}
            additionalApiError={checkResponse.message}
          />
        )
      })}

      <Button
        icon="arrow-rotate-right"
        variant="tertiary"
        loading={PersonalizationStore.state === 'pending-manual'}
        onClick={() => {
          PersonalizationStore.fetchPersonalizationStatus(false)
        }}
      >
        {t('Run checks again')}
      </Button>
    </div>
  )
}

export default observer(Checklist)
