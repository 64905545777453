import { observer } from 'mobx-react-lite'
import { Switch, Route, Redirect } from 'react-router-dom'
import { intersection } from 'lodash'

import createInternalPath from '../utils/createInternalPath'
import LayoutV3 from '../layout/v3'
import routes from './routes'
import getInstance from '../utils/getInstance'
import UIStore from '../stores/UIStore'
import DisabledFeature from '../components/DisabledFeature'
import AuthStore from '../stores/AuthStore'
import ABTestStore from '../stores/ABTestStore'

function UIVersion3(props) {
  let v3Routes = Object.values(routes).filter((r) => r.v3 == true)
  if (!AuthStore.isSuperAdmin) {
    v3Routes = v3Routes.filter(
      (route) =>
        route.permission === undefined ||
        intersection(route.permission, AuthStore.permissions).length !== 0
    )
  }

  return (
    <LayoutV3 {...props}>
      <Switch>
        {v3Routes.map((route) => {
          const {
            path,
            Component,
            requireFeature,
            title,
            exact,
            routeUnbookedAccessible = false,
          } = route
          const internalPath = createInternalPath(route.path)
          const { features } = UIStore
          const isDisabledFeature =
            requireFeature && !features.includes(requireFeature)

          let CustomComponent = Component

          const isResultABTestingRankingMix =
            ABTestStore.testDetail &&
            ABTestStore.testDetail.case === 'ranking_mix' &&
            ABTestStore.testDetail.finished &&
            route.path === routes.rankingMix.path

          const isResultABTestingPersonalization =
            ABTestStore.testDetail &&
            ABTestStore.testDetail.case === 'personalization' &&
            ABTestStore.testDetail.finished &&
            route.path === routes.personalization.path

          if (
            isDisabledFeature &&
            !AuthStore.isSuperAdmin &&
            !routeUnbookedAccessible &&
            !isResultABTestingRankingMix &&
            !isResultABTestingPersonalization
          ) {
            CustomComponent = DisabledFeature
          }

          return (
            <Route
              key={path}
              path={internalPath}
              exact={exact}
              render={(props) => (
                <CustomComponent
                  {...props}
                  title={title}
                  requireFeature={requireFeature}
                />
              )}
            />
          )
        })}

        <Route>
          <Redirect to={'/' + getInstance() + routes.dashboard.path} />
        </Route>
      </Switch>
    </LayoutV3>
  )
}

export default observer(UIVersion3)
