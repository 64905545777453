import { observer } from 'mobx-react-lite'
import t from '../../utils/translate'
import SlotConfiguration from './SlotConfiguration'
import HeadingMain from '../../components/Headings/HeadingMain'
import ShadowScroll from '../../components/ShadowScroll'

function Slots(props) {
  const { slots = [] } = props

  return (
    <>
      <HeadingMain className="bundle__title">
        {t('The Bundle consists of ...')}
      </HeadingMain>
      {/*
      <Text size="echo" weight="bold" className="bundle__title" element="h1">
        {t('The Bundle consists of ...')}
      </Text>
      */}
      <ShadowScroll className="bundle__slot-section" direction="horizontal">
        {slots.map((slot, index) => (
          <SlotConfiguration key={slot.id} position={index + 1} slot={slot} />
        ))}

        {/* Potential new slot is always visible */}
        <SlotConfiguration position={slots.length + 1} />
      </ShadowScroll>
    </>
  )
}

export default observer(Slots)
