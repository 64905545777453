import { observer } from 'mobx-react-lite'
import Icon from '../../../components/Icon'
import ResourceTable from '../../../components/ResourceTable'
import Switch from '../../../components/Switch'
import { Column } from '../../../components/Table'
import Text from '../../../components/Text'
import {
  AuthStore,
  RoleManagementStore,
  UserManagementStore,
} from '../../../stores'
import { t } from '../../../utils'
import RoleSelectTag from './RoleSelectTag'
import { toJS } from 'mobx'

const CONTENTCREATOR = 'Makaira.CONTENTCREATOR'

function UserInstancePermission() {
  const selectInstanceOptions = RoleManagementStore.roles.map((item) => {
    return {
      label: item.name
        ?.replace('Makaira.', '')
        .replace(`${toJS(RoleManagementStore.rolePrefix)}.`, ''),
      value: item.id,
    }
  })

  const isOwnUser =
    AuthStore.user.sub === UserManagementStore.userDetail.user_id

  const contentCreator =
    RoleManagementStore.roles.find((item) => item.name === CONTENTCREATOR) || {}

  return (
    <ResourceTable
      rowKey="instance"
      title={t('Instances and roles')}
      store={UserManagementStore}
      resourceName="instancesTableResource"
      loading={UserManagementStore.state === 'pending'}
      // onRowClick={handleRowClick}
      noResultsText={t('No instance could be found.')}
      emptyText={t('User permissions')}
      customFooterRender={<></>}
    >
      <Column
        width={250}
        title={t('Instances')}
        dataIndex="instance"
        render={(colValue, rowValue) => {
          return (
            <div className="table-column__instance-switch">
              <Switch
                checked={rowValue.enabled}
                onChange={(value) => {
                  console.log(UserManagementStore.instancesTableResource)
                  const roles = UserManagementStore.instancesTableResource.find(
                    (data) =>
                      data.instance === colValue &&
                      data.environment === rowValue.environment
                  )?.roles
                  UserManagementStore.onChangeUserInstanceAccess(
                    colValue,
                    rowValue.environment,
                    {
                      enabled: value,
                      roles: roles?.length === 0 ? [contentCreator.id] : roles,
                    }
                  )
                }}
                size="small"
              />
              <Text>{colValue === '*' ? 'All instances' : colValue}</Text>
            </div>
          )
        }}
      />
      <Column
        width={250}
        title={t('Environment')}
        dataIndex="environment"
        render={(value) => {
          let env = ''
          switch (value) {
            case 'live':
              env = t('Live / production')
              break
            case 'stage':
              env = t('Stage / testing')
              break
            default:
              break
          }
          return <Text>{env}</Text>
        }}
      />
      <Column
        title={t('Which role should be assigned to this user?')}
        dataIndex="roles"
        render={(roles, row) => {
          return (
            <ul className="instance-role-tags">
              {roles.map((roleId) => {
                const role =
                  RoleManagementStore.roles.find(
                    (item) => item.id === roleId
                  ) || {}
                // User unable to remove them self Onwer role
                const showRemoveIcon = !(isOwnUser && role.name === 'owner')
                let roleName = role.name || roleId
                roleName = roleName
                  .replace('Makaira.', '')
                  .replace(`${toJS(RoleManagementStore.rolePrefix)}.`, '')
                return (
                  <Text size="bravo" className="instance-role-tag" key={roleId}>
                    {roleName}
                    {showRemoveIcon && (
                      <Icon
                        height={18}
                        width={18}
                        symbol="times"
                        onClick={() => {
                          UserManagementStore.onChangeUserInstanceAccess(
                            row.instance,
                            row.environment,
                            { roles: roles.filter((item) => item !== roleId) }
                          )
                        }}
                      />
                    )}
                  </Text>
                )
              })}
              <RoleSelectTag
                value={roles || []}
                options={selectInstanceOptions}
                onChange={(value) => {
                  UserManagementStore.onChangeUserInstanceAccess(
                    row.instance,
                    row.environment,
                    { roles: [...roles, value] }
                  )
                }}
              />
            </ul>
          )
        }}
      />
    </ResourceTable>
  )
}

export default observer(UserInstancePermission)
