import classNames from 'classnames'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import Text from '../Text'
import ResourceTableFilter from '../ResourceTable/ResourceTableFilter'

import t from '../../utils/translate'
import mergeFiltersWithDefaultFilters from './mergeFiltersWithDefaultFilters'

import './logView.styl'
import Spinner from '../Spinner'
import AutoLoadIndicator from './AutoLoadIndicator'

const AUTO_FETCH_STATUS = {
  DISABLED: 'DISABLED',
  SCROLL: 'SCROLL',
  FILTER_CHANGE: 'FILTER_CHANGE',
  PAGE_LOAD: 'PAGE_LOAD',
}

const LogView = ({
  store,
  filters = [],
  columns = [],
  generateRowKey = () => {},
  type = '',
}) => {
  const { logs } = store
  const messages = logs?.messages ?? []

  const scrollDivRef = useRef()

  const [filterUpdated, setFilterUpdated] = useState(false)
  const [autoFetch, setAutoFetch] = useState(AUTO_FETCH_STATUS.DISABLED)

  /* Effect that is triggered on component mount.
   * Fetches the initial log entries and activates the auto fetch.
   */
  useEffect(() => {
    store.fetchLogs(true).then(() => {
      setAutoFetch('PAGE_LOAD')
    })
  }, [store])

  /* Effect that is triggered when the value of autoFetch changes.
   * Takes care of creating and removing an interval for automatically fetching new log entries.
   */
  useEffect(() => {
    let fetchInterval
    if (autoFetch !== AUTO_FETCH_STATUS.DISABLED) {
      if (autoFetch === AUTO_FETCH_STATUS.SCROLL) {
        store.setLogParams({ offset: 0 })
      }
      fetchInterval = setInterval(() => {
        store.fetchLogs()
      }, 5000)
    }

    return () => {
      if (fetchInterval) {
        clearInterval(fetchInterval)
      }
    }
  }, [store, autoFetch])

  const handleFilterChange = async (updatedFilters) => {
    await store.setLogParams({
      ...updatedFilters,
    })

    if (scrollDivRef.current) {
      setFilterUpdated(true)
      /* We have to add here a timeout because if we switch from filtering with no results (the log box
       * will have a height of 0) to a filtering with many results (log box will have a scroll bar), we would do
       * the jump before the component had time to rerender. At the moment I couldn't find a better solution than
       * adding a timeout.
       */
      setTimeout(() => {
        scrollDivRef.current.scrollTo(0, 0)
      }, 400)
    }

    if (updatedFilters.before) {
      setAutoFetch(AUTO_FETCH_STATUS.DISABLED)
      return
    }

    setAutoFetch(AUTO_FETCH_STATUS.FILTER_CHANGE)
  }

  const handleScroll = (event) => {
    /* When we change a filter we reset the scroll position to 0.
     * This will trigger a scroll event and will update the autoFetch variable.
     * We don't want to fetch at this point logs / update the autoFetch variable
     * because we already fetch the logs when the filters where changed.
     */
    if (filterUpdated) {
      setFilterUpdated(false)
      return
    }

    if (store.logFilters.before) {
      setAutoFetch(AUTO_FETCH_STATUS.DISABLED)
      return
    }

    setAutoFetch(
      event.target.scrollTop === 0
        ? AUTO_FETCH_STATUS.SCROLL
        : AUTO_FETCH_STATUS.DISABLED
    )
  }

  const fetchMoreLogs = () => {
    store.fetchMoreLogs()
  }

  return (
    <div className="log-view">
      <ResourceTableFilter
        filters={mergeFiltersWithDefaultFilters(filters, handleFilterChange)}
        store={store}
        filterParams="logFilters"
      />
      <div
        className={classNames('log-view__table-head', {
          [`log-view__table-head--${type}`]: type,
        })}
      >
        {columns.map((column) => (
          <Text key={column.title} variant="book">
            {t(column.title)}
          </Text>
        ))}
      </div>
      <div id="scrollableDiv" className="log-view__list" ref={scrollDivRef}>
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMoreLogs}
          style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
          inverse={true}
          hasMore={logs?.total > 0}
          loader={
            <div className="log-view__loader">
              <Spinner />
            </div>
          }
          scrollableTarget="scrollableDiv"
          onScroll={handleScroll}
        >
          {autoFetch !== AUTO_FETCH_STATUS.DISABLED && <AutoLoadIndicator />}
          {autoFetch !== AUTO_FETCH_STATUS.DISABLED &&
            messages.length === 0 && (
              <Text className="log-view__item log-view__item--no-data">
                {t(
                  'There are no logs available yet. As soon as there are logs available they are shown here.'
                )}
              </Text>
            )}

          {messages.map((log) => (
            <div
              key={generateRowKey(log)}
              className={classNames('log-view__item', {
                [`log-view__item--${type}`]: type,
              })}
            >
              {columns.map((column) => (
                <Text key={column.title} size={column.size}>
                  {typeof column.format === 'function'
                    ? column.format(log[column.key])
                    : log[column.key]}
                </Text>
              ))}
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}

LogView.propTypes = {
  /**
   * The MobX store that implements at least the given fields and functions.
   */
  store: {
    logs: {
      messages: PropTypes.array,
      total: PropTypes.number,
    },
    fetchLogs: PropTypes.func,
    fetchMoreLogs: PropTypes.func,
    setLogParams: PropTypes.func,
  },
  /**
   * Additional filters (structured like the ResourceTable filters).
   * Before and after filter are set by default.
   *
   * Use the position key to place the filters correctly.
   * The default filters have position 9 and 10,
   * so you can easily place before and after them.
   */
  filters: PropTypes.arrayOf(PropTypes.object),
  /**
   * Columns that will be displayed in the list.
   * Title will be wrapped with translate function.
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      format: PropTypes.func,
      size: PropTypes.string,
    })
  ),
  /**
   * Function to generate the required key for each log entry row.
   */
  generateRowKey: PropTypes.func,
  /**
   * Define how the columns should be aligned.
   */
  type: PropTypes.oneOf(['storefront', 'importer']),
}

export default observer(LogView)
