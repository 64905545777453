export default {
  boolean: [
    { value: 'isTrue', label: 'is true' },
    { value: 'isFalse', label: 'is false' },
  ],
  string: [
    { value: 'eq', label: 'equals', showInput: true },
    { value: 'like', label: 'like', showInput: true },
    {
      value: 'notLike',
      label: 'not like',
      showInput: true,
    },
    {
      value: 'inList',
      label: 'in list',
      showInput: true,
    },
    {
      value: 'notInList',
      label: 'not in list',
      showInput: true,
    },
    { value: 'empty', label: 'empty' },
    { value: 'notEmpty', label: 'not empty' },
  ],
  text: [
    { value: 'eq', label: 'equals', showInput: true },
    { value: 'like', label: 'like', showInput: true },
    {
      value: 'notLike',
      label: 'not like',
      showInput: true,
    },
    {
      value: 'inList',
      label: 'in list',
      showInput: true,
    },
    {
      value: 'notInList',
      label: 'not in list',
      showInput: true,
    },
    { value: 'empty', label: 'empty' },
    { value: 'notEmpty', label: 'not empty' },
  ],
  keyword: [
    { value: 'eq', label: 'equals', showInput: true },
    { value: 'like', label: 'like', showInput: true },
    {
      value: 'notLike',
      label: 'not like',
      showInput: true,
    },
    {
      value: 'inList',
      label: 'in list',
      showInput: true,
    },
    {
      value: 'notInList',
      label: 'not in list',
      showInput: true,
    },
    { value: 'empty', label: 'empty' },
    { value: 'notEmpty', label: 'not empty' },
  ],
  nested: [
    { value: 'eq', label: 'equals' },
    { value: 'like', label: 'like', showInput: true },
    {
      value: 'notLike',
      label: 'not like',
      showInput: true,
    },
    {
      value: 'inList',
      label: 'in list',
      showInput: true,
    },
    {
      value: 'notInList',
      label: 'not in list',
      showInput: true,
    },
    { value: 'empty', label: 'empty' },
    { value: 'notEmpty', label: 'not empty' },
  ],
  long: [
    { value: 'eq', label: 'equals number' },
    { value: 'gt', label: 'greater' },
    { value: 'gte', label: 'greater or equal' },
    { value: 'lt', label: 'less' },
    { value: 'lte', label: 'less or equal' },
    {
      value: 'between',
      label: 'between',
      showMultiple: true,
    },
  ],
  integer: [
    { value: 'eq', label: 'equals number' },
    { value: 'gt', label: 'greater' },
    { value: 'gte', label: 'greater or equal' },
    { value: 'lt', label: 'less' },
    { value: 'lte', label: 'less or equal' },
    {
      value: 'between',
      label: 'between',
      showMultiple: true,
    },
  ],
  double: [
    { value: 'eq', label: 'equals number' },
    { value: 'gt', label: 'greater' },
    { value: 'gte', label: 'greater or equal' },
    { value: 'lt', label: 'less' },
    { value: 'lte', label: 'less or equal' },
    {
      value: 'between',
      label: 'between',
      showMultiple: true,
    },
  ],
  float: [
    { value: 'eq', label: 'equals number' },
    { value: 'gt', label: 'greater' },
    { value: 'gte', label: 'greater or equal' },
    { value: 'lt', label: 'less' },
    { value: 'lte', label: 'less or equal' },
    {
      value: 'between',
      label: 'between',
      showMultiple: true,
    },
  ],
  date: [
    { value: 'eq', label: 'equals', showDate: true },
    { value: 'since', label: 'since', showDate: true },
    { value: 'until', label: 'until', showDate: true },
    {
      value: 'within',
      label: 'within',
      showDays: true,
    },
    {
      value: 'between',
      label: 'between dates',
      showRange: true,
    },
  ],
}
