import t from '../../utils/translate'

function Tab({ type, count, allTypes, isSelected, onSelectType }) {
  const text = allTypes.find((t) => t.id == type || t.id + 's' == type)?.title

  function onClickTab() {
    onSelectType(type)
  }

  if (!text || count === 0) {
    return null
  }

  return (
    <button onClick={onClickTab} className={isSelected ? 'active' : undefined}>
      <h3 key={type}>
        {t(text)} ({count})
      </h3>
    </button>
  )
}

export default function Heading(props) {
  const {
    allTypes = [],
    searchResultTypes = [],
    selectedType,
    onSelectType,
  } = props

  return (
    <div className="data-inspector__tab-group">
      {searchResultTypes.map(({ type, count }) => (
        <Tab
          key={type}
          type={type}
          count={count}
          allTypes={allTypes}
          onSelectType={onSelectType}
          isSelected={selectedType === type}
        />
      ))}
    </div>
  )
}
