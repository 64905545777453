import React, { Fragment } from 'react'
import RcCollapse, { Panel as RcPanel } from 'rc-collapse'
import classes from 'classnames'
import motionUtil from './motionUtil'
import './collapse.styl'
import Radio, { Group as RadioGroup } from '../Radio'
import Icon from '../Icon'

export default function Collapse(props) {
  const {
    title,
    children,
    className,
    onChange,
    type, // arrow | radio | undefined
    value,
    level = 0,
    noPadding,
    ...rest
  } = props

  const Wrapper = type === 'radio' ? RadioGroup : Fragment
  const wrapperProps = {}
  if (type === 'radio') {
    wrapperProps.onChange = onChange
    wrapperProps.value = value
  }

  const expandIcon = ({ isActive }) => (
    <Icon
      width="18px"
      height="18px"
      className="collapse__expand-icon"
      symbol={isActive ? 'angle-up' : 'angle-down'}
    />
  )

  const collapseProps = {
    accordion: true,
    openMotion: motionUtil,
    className: classes(
      'collapse',
      `collapse--level-${level}`,
      `collapse--${type}`,
      className,
      { 'collapse--no-padding': noPadding }
    ),
    ...rest,
  }

  if (type !== 'radio') {
    collapseProps.onChange = onChange
  }

  if (type === 'arrow') {
    collapseProps.expandIcon = expandIcon
  }

  return (
    <div>
      {title && <h1 className="collapse__title">{title}</h1>}
      <Wrapper {...wrapperProps}>
        <RcCollapse {...collapseProps}>
          {children &&
            React.Children.map(children, (child, index) => (
              <Fragment key={child?.key || index}>
                {React.isValidElement(child) &&
                  React.cloneElement(child, { type })}
              </Fragment>
            ))}
        </RcCollapse>
      </Wrapper>
    </div>
  )
}

function Panel(props) {
  const { showArrow = false, className, type, value, header, ...rest } = props

  return (
    <RcPanel
      header={
        <div className="collapse__header">
          {type === 'radio' ? (
            <Radio size="large" label={header} value={value} />
          ) : (
            header
          )}
        </div>
      }
      showArrow={type === 'arrow' || showArrow}
      className={className}
      {...rest}
    />
  )
}

export { Panel }
