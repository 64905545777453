import { observer } from 'mobx-react-lite'

import PageTitle from '../../components/PageTitle'
import t from '../../utils/translate'
import { routes } from '../../routing'

const ABTestingPageTitle = () => {
  return (
    <PageTitle
      tabData={[
        {
          route: routes.abTestingUpcomingList,
          title: t('Upcoming'),
        },
        {
          route: routes.abTestingArchiveList,
          title: t('Archive'),
        },
        {
          route: routes.abTestingTimelineView,
          title: t('Timeline'),
        },
      ]}
    >
      {t('A/B-Tests')}
    </PageTitle>
  )
}

export default observer(ABTestingPageTitle)
