import { flattenDeep, get, isString } from 'lodash'
import getContentFromComponent from '../getContentFromComponent'
import stripHTML from './stripHTML'
import { ComponentStore, EditorStore } from '../../stores'

/**
 * Gets a string of content from the given field.
 *
 * @param field
 * @returns {*|string|null}
 */
const getFieldContent = (field) => {
  const component = ComponentStore.getByIdentifier(field.component) || {}
  const content = get(
    field,
    ['properties', EditorStore.contentLanguage, 'content'],
    {}
  )
  const fields = (component.sections || []).reduce((allFields, section) => {
    return [...allFields, ...section.fields]
  }, [])

  const contents = flattenDeep(
    fields.map((_field) =>
      getContentFromComponent(_field, content, ['text', 'richtext'])
    )
  )

  const contentOnlyText = contents.filter((_field) => isString(_field))

  const noContent =
    contentOnlyText.length > 0 && contentOnlyText.every((_field) => !_field)

  if (noContent) {
    return null
  }

  return (
    stripHTML(
      contentOnlyText
        .filter(
          (_content) =>
            !!_content && !/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(_content)
        )
        .join(' ')
    ) || ' '
  )
}

export default getFieldContent
