import { Tooltip } from 'antd'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'

import PageEditorPreviewStore from '../../../stores/PageEditor/PageEditorPreviewStore'

import t from '../../../utils/translate'

const ToggleVisibilityButton = () => {
  const { previewShown, hidePreview, showPreview } = PageEditorPreviewStore

  return (
    <Tooltip
      placement="topRight"
      title={t(previewShown ? 'Hide Preview' : 'Show Preview again')}
    >
      <button
        className={classnames('editor-preview__size')}
        onClick={previewShown ? hidePreview : showPreview}
      >
        {t(previewShown ? 'hide' : 'show preview')}
      </button>
    </Tooltip>
  )
}

export default observer(ToggleVisibilityButton)
