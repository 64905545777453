import React from 'react'
import { observer } from 'mobx-react-lite'
import ChangedField from '../../../components/ChangedField'
import Icon from '../../../components/Icon'
import Table, { Column } from '../../../components/Table'
import Text from '../../../components/Text'
import { t } from '../../../utils'
import CustomExpand from './CustomExpand'
import Spinner from '../../../components/Spinner'
import StreamRender from './StreamRender'

const BuildTable = ({ data }) => {
  const renderIcon = (type) => {
    if (type === 'succeeded')
      return (
        <Icon
          className="storefront-settings__status-icon--green"
          symbol="circle-check__solid"
          width={16}
          height={16}
        />
      )
    if (type === 'pending')
      return <Spinner size="small" symbol="rotate-solid" />
    return (
      <Icon
        symbol="triangle-exclamation__solid"
        className="storefront-settings__status-icon--red"
        width={16}
        height={16}
      />
    )
  }

  return (
    <Table
      expandable={{
        rowExpandable: (record) => record.output_stream_url,
        expandedRowRender: (record) => <StreamRender record={record} />,
        expandIcon: (props) => <CustomExpand {...props} />,
        expandIconColumnIndex: 4,
      }}
      rowKey="created_at"
      data={data}
      emptyText={t('No data')}
    >
      <Column
        align="center"
        width={50}
        dataIndex="build_state"
        render={(value) => <Text weight="bold">{renderIcon(value)}</Text>}
      />
      <Column
        title={t('Status')}
        dataIndex="build_state"
        render={(value) => <Text weight="bold">{t(value)}</Text>}
      />
      <Column
        title={t('When')}
        dataIndex="created_at"
        render={(_, record) => (
          <ChangedField
            changed={{ date: record.created_at, timezone: 'UTC' }}
          />
        )}
      />
      <Column
        title={t('Commit ID')}
        dataIndex="commit"
        render={(value) => <Text weight="bold">{value}</Text>}
      />
    </Table>
  )
}

export default observer(BuildTable)
