import { observer } from 'mobx-react-lite'
import { routes } from '../../../routing'
import { UIStore } from '../../../stores'
import ABTestStore from '../../../stores/ABTestStore'
import { t } from '../../../utils'
import Button from '../../Button'
import Select from '../../Select'
import Text from '../../Text'
import TextInput from '../../TextInput'

const CreateHeader = () => {
  const { editingExperiment } = ABTestStore
  const isValid =
    editingExperiment.case &&
    editingExperiment.segments &&
    editingExperiment.title

  const versionOptions = [
    { label: t('2 versions: A-B test'), value: '2' },
    { label: t('3 versions: A-B-C test'), value: '3' },
  ]

  return (
    <>
      <div className="ab-metaconfig__header">
        <div>
          <Text size="echo">{t('A/B-Test')} › </Text>
          <Text size="echo" weight="bold">
            {t('Create a test')}
          </Text>
        </div>
        <div className="ab-metaconfig__create-inputs">
          <Select
            title={t('Type of test')}
            options={[
              {
                label: 'Personalization',
                value: 'personalization',
                disabled: !UIStore.features.includes(
                  routes.personalization.requireFeature
                ),
              },
              {
                label: 'Ranking Mix',
                value: 'ranking_mix',
                disabled: !UIStore.features.includes(
                  routes.rankingMix.requireFeature
                ),
              },
              { label: t('Frontend'), value: 'frontend' },
              {
                label: 'Recommendation',
                value: 'recommendation',
                disabled: !UIStore.features.includes(
                  routes.recommendations.requireFeature
                ),
              },
            ]}
            onChange={(value) => {
              ABTestStore.updateEditingExperiment('case', value)

              if (value === 'ml') {
                ABTestStore.updateEditingExperiment('segments', '2')
              }
            }}
            fullWidth
            value={editingExperiment.case}
          />
          <Select
            title={t('Number of versions to test')}
            options={versionOptions}
            onChange={(value) =>
              ABTestStore.updateEditingExperiment('segments', value)
            }
            fullWidth
            value={editingExperiment.segments}
            disabled={versionOptions.length === 1}
          />
          <TextInput
            onChange={(e) => {
              ABTestStore.updateEditingExperiment('title', e.target.value)
              ABTestStore.updateEditingExperiment('description', e.target.value)
            }}
            value={editingExperiment.title}
            label={t('Name your test')}
          />
        </div>
      </div>
      <div className="ab-metaconfig__actions">
        <Button
          onClick={() => history.back()}
          variant="primary-blocker"
          icon="xmark"
        >
          {t('Cancel')}
        </Button>
        <Button
          disabled={!isValid}
          variant="secondary-blocker"
          icon="chevron-right"
          onClick={() => ABTestStore.updateCreateStep(2)}
        >
          {t('Proceed to test configuration')}
        </Button>
      </div>
    </>
  )
}

export default observer(CreateHeader)
