import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import Select from '../Select'
import NumberInput from '../NumberInput'
import StreamSelect from '../StreamSelect'

import SortFieldAutocomplete from './searchFieldAutocomplete'

import t from '../../utils/translate'

import './productStreamInput.styl'

/**
 * <h3>Component to select (and configure) a product stream.</h3>
 * Component is stateful, so it keeps track of the self and doesn't listen on updates of the <em>value</em>-prop. <br />
 * <em>value</em>-prop is used for initial state. <br />
 * Component will fetch <b>streams</b> and <b>fields</b> on its own.
 */
const ProductStreamInput = (props) => {
  const [value, setValue] = useState(props.value)
  const SORT_OPTIONS = [
    {
      label: t('Descending'),
      value: 'desc',
    },
    {
      label: t('Ascending'),
      value: 'asc',
    },
  ]

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const handleChangeField = (field, val) => {
    const _value = { ...value }
    _value[field] = val
    setValue(_value)
    props.onChange(_value)
  }

  return (
    <div className="product-stream-input">
      <StreamSelect
        title={t('Stream')}
        value={props?.value?.streamId ? String(props?.value?.streamId) : ''}
        onChange={(val) => handleChangeField('streamId', +val)}
        fullWidth
      />

      {props?.value?.streamId && (
        <>
          <SortFieldAutocomplete
            value={props?.value?.sort}
            onChange={(val) => handleChangeField('sort', val)}
            selectedStream={props.value.streamId}
            fullWidth
          />

          <Select
            title={t('Sort order')}
            options={SORT_OPTIONS}
            value={props?.value?.order}
            className="product-stream-input__sort"
            onChange={(val) => handleChangeField('order', val)}
          />

          <NumberInput
            label={t('Max. Results')}
            value={props?.value?.maxDocumentCount}
            onChange={(val) => handleChangeField('maxDocumentCount', val)}
          />
        </>
      )}
    </div>
  )
}

export default observer(ProductStreamInput)

//  We need to export it without the observer so it can be displayed in Storybook.
export { ProductStreamInput as ProductStreamInputWithoutObserver }
