import React, { useEffect, useState } from 'react'
import './numberInput.styl'
import Icon from '../Icon'
import Text from '../Text'
import classNames from 'classnames'

const NumberInput = (props) => {
  const parseData = props.isFloat ? parseFloat : parseInt
  const { description = '', label = '', onChange = () => {}, max, min } = props
  const [value, setValue] = useState(parseData(props.value, 10) || 0)

  useEffect(() => {
    setValue(parseData(props.value, 10) || 0)
    // eslint-disable-next-line
  }, [props.value])

  function handleChangeUp() {
    if (props.disabled) return
    const step = isNaN(props.step) ? 1 : props.step

    let newValue = value + step
    if (max !== undefined && newValue > max) return

    if (props.isFloat) {
      const fractionDigits = `${value}`.split('.')[1]?.length || 1
      newValue = parseFloat(newValue).toFixed(fractionDigits)
    }

    setValue(newValue)
    onChange(newValue)
  }

  function handleChangeDown() {
    if (props.disabled) return
    const step = isNaN(props.step) ? 1 : props.step

    let newValue = value - step
    if (min !== undefined && newValue < min) return

    if (props.isFloat) {
      const fractionDigits = `${value}`.split('.')[1]?.length || 1
      newValue = parseFloat(newValue).toFixed(fractionDigits)
    }

    setValue(newValue)
    onChange(newValue)
  }

  const handleInputChange = (e) => {
    let newValue = parseData(e.target.value, 10)
    if (max !== undefined && newValue > max) {
      newValue = max
    }
    if (min !== undefined && newValue < min) {
      newValue = min
    }
    setValue(newValue)
    onChange(newValue)
  }
  const className = classNames('number-input', {
    'number-input--borderless': props.borderless,
  })

  return (
    <div className={className}>
      {label && (
        <Text element="div" className="number-input__label">
          {label}
        </Text>
      )}
      <div className="number-input__input-panel">
        <input
          className="number-input__input"
          id="number-input"
          type="number"
          max={max}
          min={min}
          value={value}
          onChange={handleInputChange}
          disabled={props.disabled}
        />
        {!props.borderless && (
          <div
            className={classNames('number-input__control-panel', {
              'number-input__control-panel--disabled': props.disabled,
            })}
          >
            <button className="number-input__button" onClick={handleChangeUp}>
              <Icon className="number-input__button-icon" symbol="angle-up" />
            </button>
            <button className="number-input__button" onClick={handleChangeDown}>
              <Icon className="number-input__button-icon" symbol="angle-down" />
            </button>
          </div>
        )}
      </div>
      {description && (
        <div className="number-input__message">{description}</div>
      )}
    </div>
  )
}

export default NumberInput
