import faker from 'faker'

/**
 * The second parameter 'seed' is optional
 */
export default function getPreviewValue(componentConfig, seed) {
  const { type } = componentConfig

  if (seed) {
    faker.seed(seed)
  }

  switch (type) {
    case 'text':
      return getTextPreviewValue(componentConfig)
    case 'richtext':
      return getRichTextPreviewValue(componentConfig)
    case 'number':
      return getNumberPreviewValue(componentConfig)
    case 'file':
      return getFilePreviewValue(componentConfig)
    case 'checkbox':
      return getCheckboxPreviewValue(componentConfig)
    case 'select':
      return getSelectPreviewValue(componentConfig)
    case 'multiselect':
      return getMultiselectPreviewValue(componentConfig)
    case 'object':
      return getObjectPreviewValue(componentConfig)
    case 'array':
      return getArrayPreviewValue(componentConfig)
    case 'documents':
      return getDocumentsPreviewValue(componentConfig)
    case 'stream':
      return getDocumentsPreviewValue(componentConfig)
    case 'hotspot':
      return getHotspotImagePreviewValue(componentConfig)
    case 'colorpicker':
      return getColorPickerPreviewValue(componentConfig)
    default:
      return null
  }
}

function getTextPreviewValue(config) {
  const { id } = config

  return {
    [id]: faker.lorem.sentence(),
  }
}

function getRichTextPreviewValue(config) {
  const { id } = config

  return {
    [id]: `
          <p>
            <strong>${faker.lorem.sentence()}</strong>
          </p>
          <p>
            ${faker.lorem.sentences(5)}
          </p>
          <p>
            <img src="${faker.image.nature(1024)}"/>
          </p>
          <p>
            ${faker.lorem.sentences(5)}
          </p>
        `,
  }
}

function getNumberPreviewValue(config) {
  const { id } = config

  return {
    [id]: faker.random.number(),
  }
}

function getFilePreviewValue(config) {
  const { id } = config

  return {
    [id]: faker.image.image(),
  }
}

function getCheckboxPreviewValue(config) {
  const { id } = config

  return {
    [id]: faker.random.boolean(),
  }
}

function getSelectPreviewValue(config) {
  const { id, options = [] } = config

  // Filter empty values
  const validOptions = options.filter((o) => Boolean(o.value))

  if (validOptions.length > 0) {
    const index = faker.random.number({ max: validOptions.length - 1 })

    return {
      [id]: validOptions[index]['value'],
    }
  }
}

function getMultiselectPreviewValue(config) {
  const { id, options = [] } = config

  // trying to achieve consistent results for our tests by using the modulo-operator
  const result = options
    .map((o, index) => {
      if (index % 2 === 0) {
        return o.value
      } else {
        return null
      }
    })
    .filter(Boolean)

  return {
    [id]: result,
  }
}

function getObjectPreviewValue(config) {
  const { id, properties = [] } = config

  const nestedPreviewValues = properties.reduce((acc, currentProperty) => {
    return {
      ...acc,
      ...getPreviewValue(currentProperty),
    }
  }, {})

  return {
    [id]: {
      ...nestedPreviewValues,
    },
  }
}

function getArrayPreviewValue(config) {
  const { id, childConfig } = config

  if (!childConfig) return { [id]: [] }

  const { id: childId } = childConfig

  let result = []
  for (let i = 0; i < 3; i++) {
    const previewValueAsObject = getPreviewValue(childConfig)
    const plainPreviewValue = previewValueAsObject[childId] // reuse the id from above here for access

    result.push(plainPreviewValue)
  }

  return {
    [id]: result,
  }
}

function getDocumentsPreviewValue(config) {
  const { id } = config

  let result = []
  for (let i = 0; i < 3; i++) {
    let data = {}
    data['id'] = faker.random.uuid()
    data['title'] = faker.commerce.productName()
    data['image'] = faker.image.abstract()

    result.push(data)
  }

  return {
    [id]: result,
  }
}

function getHotspotImagePreviewValue(config) {
  const { id, properties = [] } = config

  const spots = []
  for (let i = 0; i < 3; i++) {
    let previewObject = properties.reduce((acc, currentProperty) => {
      const previewValueForPoperty = getPreviewValue(currentProperty)

      return { ...acc, ...previewValueForPoperty }
    }, {})

    previewObject['left'] = 63
    previewObject['right'] = 45

    spots.push(previewObject)
  }

  return {
    [id]: {
      image: faker.image.image(),
      spots,
    },
  }
}

function getColorPickerPreviewValue(config) {
  const { id } = config

  const h = faker.random.number(360)
  const s = faker.random.number(100)
  const l = faker.random.number(100)

  const r = faker.random.number(255)
  const g = faker.random.number(255)
  const b = faker.random.number(255)

  const a = faker.finance.amount(0, 1, 1)

  const hex = faker.internet.color()

  return {
    [id]: {
      hsla: `hsla(${h}, ${s}%, ${l}%, ${a})`,
      rgba: `rgba(${r}, ${g}, ${b}, ${a})`,
      hex: `${hex}`,
    },
  }
}
