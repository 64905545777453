import isEmpty from 'lodash/isEmpty'
import { observer } from 'mobx-react-lite'

import Text from '../../Text'
import Checkbox from '../../Checkbox'
import FileUpload from '../../FileUpload'
import HotSpotImage from '../../HotSpotImage'

import ColorPickerPreview from './ColorPickerPreview'
import MultiSelectPreview from './MultiSelectPreview'

import t from '../../../utils/translate'

import EditorStore from '../../../stores/PageEditor/EditorStore'
import StreamStore from '../../../stores/StreamStore'

export const isNoContent = (type, content) => {
  return (
    type !== 'checkbox' &&
    (content === undefined ||
      content === null ||
      isEmpty(content) ||
      content === '' ||
      (type === 'documents' && content.items.length === 0))
  )
}

function Preview(props) {
  const { selectLanguage, type, options, label, properties, id } = props

  const languageValue = EditorStore.getValueForField(id, selectLanguage)

  let content = EditorStore.getValueForField(id, selectLanguage)

  if (isNoContent(type, languageValue)) {
    content = (
      <span className="input-editor__no-content">{t('No content yet')}</span>
    )

    if (type === 'multiselect' || type === 'select') {
      content = (
        <span className="input-editor__no-content">{t('No selection')}</span>
      )
    }

    return <div className="input-editor__render-content">{content}</div>
  }

  if (type === 'multiselect' && Array.isArray(content)) {
    content = (
      <p>
        {t('Selection')}:{' '}
        <MultiSelectPreview content={content} options={options} />
      </p>
    )
  }

  if (type === 'colorpicker') {
    return <ColorPickerPreview value={content} />
  }

  switch (type) {
    case 'hotspot':
      content = (
        <HotSpotImage
          properties={properties}
          value={content}
          id={id}
          selectLanguage={selectLanguage}
          readOnly
        />
      )
      break
    case 'richtext':
      content = <div dangerouslySetInnerHTML={{ __html: content }} />
      break
    case 'file':
      content = <FileUpload value={content} level={1} onRemove={null} />
      break
    case 'object':
    case 'array': {
      content = t('(No Preview for Objects and Arrays)')
      break
    }
    case 'select':
      content = (
        <p>
          {t('Selection')}:{' '}
          {options.find((option) => option.value === content).label}
        </p>
      )
      break
    case 'checkbox':
      content = (
        <Checkbox disabled={true} checked={languageValue} label={label} />
      )
      break
    case 'documents': {
      const renderContent = (items) => (
        <ol>
          {items.map((item) => (
            <li key={item.id}>
              <Text variant="book">{t('Product')}</Text>: {item.title}{' '}
              {item.subtitle}
            </li>
          ))}
        </ol>
      )
      content = renderContent(content.items)
      break
    }
    case 'stream':
      return (
        <div className="input-editor__stream-preview input-editor__render-content">
          <div>
            <Text>{t('Product stream')}: </Text>
            <Text weight="book">
              {StreamStore.getById(content.streamId)?.title ??
                t('No product stream selected')}
            </Text>
          </div>
          <div>
            <Text>{t('Sort by')}: </Text>
            <Text weight="book">{content.sort}</Text>
          </div>
          <div>
            <Text>{t('Sort order')}: </Text>
            <Text weight="book">
              {content.order === 'desc' ? t('Descending') : 'Ascending'}
            </Text>
          </div>
          <div>
            <Text>{t('Max. results')}: </Text>
            <Text weight="book">{content.maxDocumentCount}</Text>
          </div>
        </div>
      )
  }

  return <div className="input-editor__render-content">{content}</div>
}

export default observer(Preview)
