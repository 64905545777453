import UIStore from '../../stores/UIStore'
import translate from '../translate'

/**
 * Formats a language key e.g "en" to correct language name e.g "english"
 * At first checks our local translation files for the language key and if
 * it's not found, it will use Intl.DisplayNames to find the correct name.
 *
 * @param languageKey Key that should be formatted.
 * @returns {string|*} Name of the language key.
 */
function formatLanguageKey(languageKey = '') {
  const uiLanguage = UIStore.currentLanguage

  // We check at first, if we have a translation for the key in our language files.
  // By doing this we have the option to change translations without being depended on Intl.DisplayNames
  const manuelTranslated = translate(languageKey)

  if (manuelTranslated !== languageKey) return translate(languageKey)

  // If no translations has been found, we use the browser itself for translation
  const languageNames = new Intl.DisplayNames([uiLanguage], {
    type: 'language',
  })

  return languageNames.of(languageKey) || languageKey
}

export default formatLanguageKey
