import UIStore from '../../stores/UIStore'

export default async function createGallery() {
  if (window && window.cloudinary) {
    const { enterpriseConfiguration } = UIStore
    const storage = enterpriseConfiguration

    window.ml = window.cloudinary.createMediaLibrary(
      {
        cloud_name: storage.cloudinaryCloudName,
        api_key: storage.cloudinaryApiKey,
        timestamp: storage.cloudinaryTimestamp,
        signature: storage.cloudinaryLoginSignature,
        username: storage.cloudinaryUserName,
        multiple: false,
      },
      {
        insertHandler: (data) => {
          if (data.assets.length) {
            let fileName = data.assets[0].public_id
            if (data.assets[0].format) {
              fileName = fileName + '.' + data.assets[0].format
            }
            const imageInfo = {
              fileName: fileName,
              cloudName: storage.cloudinaryCloudName,
              resourceType: data.assets[0].resource_type,
              version: data.assets[0].version,
            }
            const event = new CustomEvent('cloudinary-inserted', {
              detail: JSON.stringify(imageInfo),
            })
            document.dispatchEvent(event)
          }
        },
      }
    )

    window.ml.on('hide', () => {
      document.dispatchEvent(new CustomEvent('cloudinary-hided'))
    })
  }
}
