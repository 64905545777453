import { useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'

const FormikOnChange = ({ onChange }) => {
  const isMouted = useRef(false)
  const formik = useFormikContext()

  useEffect(() => {
    if (isMouted.current) {
      onChange(formik.values)
    } else {
      isMouted.current = true
    }
    // eslint-disable-next-line
  }, [formik.values])

  return null
}

export default FormikOnChange
