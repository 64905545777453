import * as Yup from 'yup'
import debounce from 'lodash/debounce'
import { observer } from 'mobx-react-lite'
import { useEffect, useState, useCallback } from 'react'
import { difference } from 'lodash'
import { notification } from 'antd'

import Text from '../../../components/Text'
import Radio from '../../../components/Radio'
import Select from '../../../components/Select'
import Checkbox from '../../../components/Checkbox'

import { ImporterStore } from '../../../stores'
import { PERSISTENCE_LAYER_STATUS } from '../../../stores/ImporterStore'

import t from '../../../utils/translate'

import api from '../../../api'
import { SOURCES_TYPE } from '../constants'

const LimitLanguage = ({ formikProps, languageList }) => {
  const [limitLanguage, setLimitLanguage] = useState(
    formikProps.values.languages.length === 0 ? '0' : '1'
  )
  const [shopLanguages, setShopLanguages] = useState([])
  const [fetchLoading, setFetchLoading] = useState(false)
  const debounceFetching = useCallback(debounce(fetchShopLanguages, 500), []) // eslint-disable-line

  const type = formikProps.values.sourceType

  const needShopLanguages =
    formikProps.values.sourceType !== SOURCES_TYPE.NDJSON

  const onChangeLimitLanguage = (e) => {
    const noLimitLanguage = e === '0'

    if (noLimitLanguage) {
      formikProps.setFieldValue('languages', [])
    }

    setLimitLanguage(e)
  }

  const handleCheckLanguage = (language) => (e) => {
    let languages = formikProps.values.languages

    if (e.target.checked) {
      if (!languages.includes(language)) {
        languages = [...languages, language]
      }
    } else {
      if (languages.includes(language)) {
        languages = languages.filter((item) => item !== language)
      }
    }

    const notSupportedLanguage = difference(languages, languageList)
    if (notSupportedLanguage.length > 0) {
      setTimeout(
        () =>
          notification.warning({
            key: 'import_unsupport_language_warning',
            message: t('Warning'),
            description: t('import_unsupport_language_warning')(
              notSupportedLanguage
            ),
            duration: false,
            top: 100,
          }),
        100
      )
    }

    formikProps.setFieldValue('languages', languages)
  }

  useEffect(
    () => () => notification.destroy('import_unsupport_language_warning'),
    []
  )

  useEffect(() => {
    if (formikProps.values.sourceType === SOURCES_TYPE.PERSISTENCE_LAYER) {
      setShopLanguages(ImporterStore.persistenceLayerLanguages)
    }
    // eslint-disable-next-line
  }, [formikProps.values.sourceType, ImporterStore.persistenceLayerLanguages])

  useEffect(() => {
    const urlTest = Yup.string()
      .matches(/^(https?):\/\//)
      .required()
    const isValidURL = urlTest.isValidSync(formikProps.values.sourceUrl)

    // We already fetch the languages for the PL when the panel is opened or the page is initially loaded
    if (formikProps.values.sourceType === SOURCES_TYPE.PERSISTENCE_LAYER) {
      return
    }

    if (isValidURL && needShopLanguages) {
      debounceFetching({
        sourceUrl: formikProps.values.sourceUrl,
        sourceType: formikProps.values.sourceType,
        sourceSecret: formikProps.values.sourceSecret,
        sourceAuthUser: formikProps.values.sourceAuthUser,
        sourceAuthPassword: formikProps.values.sourceAuthPassword,
      })
    }
    // eslint-disable-next-line
  }, [
    needShopLanguages,
    formikProps.values.sourceUrl,
    formikProps.values.sourceSecret,
    formikProps.values.sourceAuthPassword,
    formikProps.values.sourceAuthUser,
    formikProps.values.sourceType,
  ])

  async function fetchShopLanguages(body) {
    try {
      setFetchLoading(true)
      const data = await api.importer.fetchShopLanguages(body)
      setShopLanguages(data)
    } catch (error) {
      setShopLanguages(undefined)
    } finally {
      setFetchLoading(false)
    }
  }

  // flour: use ["de"] as default
  if (type === 'flour') return null

  // ndjson: one language has to be selected
  if (type === SOURCES_TYPE.NDJSON)
    return (
      <div className="importer-config__source-config importer-config__limit-languages importer-config__limit-languages--select">
        <Text size="bravo" variant="book">
          {t('Language')}
        </Text>
        <Select
          value={formikProps.values.languages[0] || languageList[0]}
          onChange={(e) => formikProps.setFieldValue('languages', [e])}
          options={languageList.map((language) => ({
            label: language,
            value: language,
          }))}
        />
      </div>
    )

  const errorFetchShopLanguages = !(shopLanguages && shopLanguages.length >= 0)

  const loading =
    fetchLoading ||
    (formikProps.values.sourceType === SOURCES_TYPE.PERSISTENCE_LAYER &&
      ImporterStore.persistenceLayerStatus === PERSISTENCE_LAYER_STATUS.LOADING)

  return (
    <div className="importer-config__source-config importer-config__limit-languages">
      <Text size="bravo" variant="book">
        {t('Limit languages')}
      </Text>
      <Radio.Group onChange={onChangeLimitLanguage} value={limitLanguage}>
        <Radio
          label={
            <Text size="bravo">{t('Import all available languages')}</Text>
          }
          value="0"
        />
        <Radio
          label={
            <Text size="bravo">{t('Import only the following languages')}</Text>
          }
          value="1"
        />
      </Radio.Group>
      {limitLanguage === '1' && (
        <div className="importer-config__language-checkbox-group">
          {loading && needShopLanguages && (
            <div className="text-input__message">
              {t('Fetching shop languages…')}
            </div>
          )}
          {!loading && errorFetchShopLanguages && needShopLanguages && (
            <div className="text-input__message text-input__message--error">
              {t('Shop languages could not be retrieved.')}
            </div>
          )}
          {!errorFetchShopLanguages &&
            !loading &&
            (needShopLanguages ? shopLanguages : languageList).map(
              (language) => (
                <div key={language} className="importer-config__language">
                  <Text size="bravo" variant="book">
                    {language.toUpperCase()}
                  </Text>
                  <Checkbox
                    checked={formikProps.values.languages.includes(language)}
                    onChange={handleCheckLanguage(language)}
                  />
                </div>
              )
            )}
        </div>
      )}
    </div>
  )
}

export default observer(LimitLanguage)
