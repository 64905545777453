import { observer } from 'mobx-react-lite'

import Text from '../../components/Text'

import { t } from '../../utils'
import debounce from '../../utils/debounce'
import PersonalizationStore from '../../stores/PersonalizationStore'
import ProductPreview from '../../components/ProductPreview/ProductPreview'

const Preview = () => {
  const handlePreviewChange = debounce((search, changedVariable) => {
    PersonalizationStore.fetchSearchResult(search, changedVariable)
  }, 500)

  if (
    PersonalizationStore.status?.machineLearningLastSuccessfulCalc?.result ===
      false ||
    !PersonalizationStore.status
  )
    return null

  return (
    <>
      <Text element="div" weight="heavy" size="echo">
        {t('Experience the Makaira Personalization')}
      </Text>
      <div className="personalization__preview-wrapper">
        <ProductPreview
          machineLearningShown
          usersSelectShown
          weatherSelectShown
          onChange={handlePreviewChange}
          products={PersonalizationStore.products}
          loading={PersonalizationStore.isFetchingProducts}
          withoutSearchText={t(
            'Enter a search word or choose a category on the left to see a product list and active/deactivate the Personalization with the switch above to see the effect.'
          )}
          headline={t('Displaying results for…')}
          currentPage={PersonalizationStore.previewPage}
          maxPage={PersonalizationStore.maxPreviewPage}
          maxProductsPerPage={PersonalizationStore.MAX_PRODUCTS_IN_PREVIEW}
        />
      </div>
    </>
  )
}

export default observer(Preview)
