import { makeAutoObservable, toJS } from 'mobx'
import api from '../../api'
import t from '../../utils/translate'
import { ActionLayerStore } from '../index'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import isBoolean from 'lodash/isBoolean'
import toString from 'lodash/toString'
import isNumber from 'lodash/isNumber'

class AuditStore {
  auditLogs = []
  detailId = null
  auditDetail = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = {}
  diffModal = false
  diffData = {}
  state = 'pending' // "pending", "done" or "error"

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.getById(this.detailId)
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.pagination = {
      ...this.pagination,
      current: 1,
    }
    this.fetchAudit()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.pagination = {
      ...this.pagination,
      current: 1,
    }
    this.fetchAudit()
  }

  *getById(id) {
    this.state = 'pending'
    this.detailId = id
    this.auditDetail = []
    try {
      const { data, total } = yield api.audit.getById(id, {
        pagination: this.pagination,
      })
      if (data.length > 0) {
        this.auditDetail = data.map((item) => ({
          ...item,
          children: [
            { isSubTitle: true, isChild: true, id: 'subtitle ' + item.id },
            ...Object.keys(item.diff).map((o) => ({
              ...item.diff[o],
              keyChange: o,
              isChild: true,
              id: item.id + o,
            })),
          ],
        }))
      }
      this.pagination.total = total
      this.state = 'done'
      return data
    } catch (error) {
      this.state = 'error'
      console.error(error)
    }
  }

  *fetchAudit() {
    this.state = 'pending'

    try {
      const { data } = yield api.audit.getAll()

      this.auditLogs = data
      this.state = 'done'
    } catch (error) {
      ActionLayerStore.openActionLayer({
        error: t('Something went wrong loading the smart bundles.'),
        saveTitle: t('Try again'),
        onSave: () => {
          this.fetchAudit()
        },
        onClose: () => {},
      })
      this.state = 'error'
    }
  }

  openDiffModal(open, data = {}) {
    this.diffData = data
    this.diffModal = open
  }

  transformData(data) {
    if (isNumber(data)) {
      return data
    }

    if (!data || isEmpty(data)) {
      if (isBoolean(data)) return toString(data)
      return 'null'
    }

    if (isObject(data)) return JSON.stringify(data, null, 2)

    try {
      if (isString(data)) return JSON.stringify(JSON.parse(toJS(data)), null, 2)
    } catch (error) {
      return data
    }
  }
}

export default new AuditStore()
