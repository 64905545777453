import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import t from '../../utils/translate'
import Text from '../../components/Text'
import Switch from '../../components/Switch'
import TextInput from '../../components/TextInput'
import ConfigurationStore from '../../stores/ConfigurationStore'
import PersonalizationStore from '../../stores/PersonalizationStore'
import Button from '../../components/Button'
import ABTestStore from '../../stores/ABTestStore'

const Settings = () => {
  const isRunningExperiment = PersonalizationStore.isRunningExperiment

  const isDraftingTest =
    !ABTestStore.testDetail?.running && !ABTestStore.testDetail?.finished

  const isDefaultVariant =
    ABTestStore.selectedVariant === 'A' && ABTestStore.showMetaconfigView

  const variantData =
    ABTestStore.personalizationVariantsConfig.find(
      (variant) => variant.variation_name === ABTestStore.selectedVariant
    ) || {}

  let configuration = {
    enableMachineLearning:
      ConfigurationStore.configuration.enableMachineLearning === 1,
    useWeather: ConfigurationStore.configuration.useWeather,
    externalPersonalization:
      ConfigurationStore.configuration.personalization === 'custom_arp',
    personalizationUrl: ConfigurationStore.configuration.personalizationUrl,
  }

  if (ABTestStore.showMetaconfigView) {
    const settings = variantData?.settings || {}
    configuration = {
      enableMachineLearning: settings.personalization === 'enabled',
      useWeather: settings.useWeather,
      externalPersonalization: settings.personalization === 'custom_arp',
      personalizationUrl: settings.personalizationUrl,
    }
  }

  useEffect(() => {
    ConfigurationStore.fetchConfiguration()
  }, [])

  const updateConfiguration = (key, value, isSave) => {
    if (ABTestStore.showMetaconfigView) {
      if (key === 'enableMachineLearning') {
        key = 'personalization'
        value = value === 1 ? 'enabled' : 'disabled'
      }
      ABTestStore.updateVariantConfig(variantData.settings || {}, key, value)
    } else {
      ConfigurationStore.updateConfiguration(key, value, isSave)
    }
  }

  if (
    PersonalizationStore.featureInfo.state !== 'paid' ||
    !PersonalizationStore.isPersonalizationPossible ||
    PersonalizationStore.status?.machineLearningLastSuccessfulCalc?.result ===
      false
  )
    return null

  return (
    <div className="personalization__settings">
      <Text element="div" weight="heavy" size="echo">
        {t('Personalization Settings')}
      </Text>
      <div>
        <Switch
          onChange={(value) =>
            updateConfiguration('enableMachineLearning', value ? 1 : 0)
          }
          checked={configuration.enableMachineLearning}
          type="horizontal"
          title={t('Enable Makaira Personalization')}
          disabled={
            (isRunningExperiment && !ABTestStore.showMetaconfigView) ||
            (ABTestStore.showMetaconfigView &&
              (!isDraftingTest || isDefaultVariant))
          }
        />
        <Switch
          onChange={(value) => updateConfiguration('useWeather', value)}
          checked={configuration.useWeather}
          type="horizontal"
          title={t('Consider actual weather data in the region of the user')}
          disabled={
            (isRunningExperiment && !ABTestStore.showMetaconfigView) ||
            (ABTestStore.showMetaconfigView &&
              (!isDraftingTest || isDefaultVariant))
          }
        />
      </div>

      <Switch
        onChange={(value) =>
          updateConfiguration(
            'personalization',
            value ? 'custom_arp' : 'disabled'
          )
        }
        checked={configuration.externalPersonalization}
        type="horizontal"
        title={t('Enable External Personalization')}
        disabled={
          (isRunningExperiment && !ABTestStore.showMetaconfigView) ||
          (ABTestStore.showMetaconfigView &&
            (!isDraftingTest || isDefaultVariant))
        }
      />
      {configuration.externalPersonalization && (
        <div>
          <TextInput
            onChange={(e) =>
              updateConfiguration('personalizationUrl', e.target.value, false)
            }
            label={t('External Personalization Service URL')}
            value={configuration.personalizationUrl}
            description={t(
              'More information can be found in our documentation'
            )}
            disabled={
              (isRunningExperiment && !ABTestStore.showMetaconfigView) ||
              (ABTestStore.showMetaconfigView &&
                (!isDraftingTest || isDefaultVariant))
            }
          />
          {!ABTestStore.showMetaconfigView && (
            <Button
              loading={ConfigurationStore.state === 'pending'}
              variant="primary"
              onClick={() =>
                ConfigurationStore.saveConfiguration(['personalizationUrl'])
              }
              disabled={
                (isRunningExperiment && !ABTestStore.showMetaconfigView) ||
                (ABTestStore.showMetaconfigView &&
                  (!isDraftingTest || isDefaultVariant))
              }
            >
              {t('Save')}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default observer(Settings)
