import cs from 'classnames'
import Checkbox from '../Checkbox'
import './multiCheckbox.styl'

export default function MultiCheckbox(props) {
  const {
    label,
    description,
    options,
    value = [],
    onChange = () => {},
    direction = 'vertical', // vertical || horizontal
  } = props

  const handleChange = (item) => {
    const _selected = [...value]
    if (_selected.includes(item.value)) {
      _selected.splice(_selected.indexOf(item.value), 1)
    } else {
      _selected.push(item.value)
    }

    onChange(_selected)
  }

  return (
    <div
      className={cs('multi-checkbox-wrapper', {
        'multi-checkbox-wrapper__vertical': direction === 'vertical',
        'multi-checkbox-wrapper__horizontal': direction === 'horizontal',
      })}
    >
      {label && <div className="multi-checkbox__label">{label}</div>}
      {options?.map((item) => (
        <Checkbox
          key={item.uuid}
          label={item.label}
          checked={value.includes(item.value)}
          onChange={() => handleChange(item)}
        />
      ))}
      {description && (
        <div className="multi-checkbox__message">{description}</div>
      )}
    </div>
  )
}
