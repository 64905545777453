import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import React from 'react'
import DropdownButton from '../../../components/DropdownButton'
import Icon from '../../../components/Icon'
import Table, { ArrowAction, Column } from '../../../components/Table'
import Text from '../../../components/Text'
import { RecommendationStore } from '../../../stores'
import ABTestStore from '../../../stores/ABTestStore'
import { t } from '../../../utils'
import { PRESETS } from '../constants'

const PresetsDropdown = () => {
  const isDefaultVariant =
    ABTestStore.selectedVariant === 'A' && ABTestStore.showMetaconfigView

  return (
    <DropdownButton
      actionIcon="folder-gear__regular"
      text={t('Presets')}
      disabled={isDefaultVariant}
      menu={(hide) => (
        <div className="recommendations__preset-table">
          <Table
            onRow={(record) => ({
              onClick: () => {
                hide()
                RecommendationStore.applyPreset(record.id)
              },
            })}
            data={PRESETS}
          >
            <Column
              title={t('Name')}
              render={(value, row) => (
                <Text
                  className="recommendations__preset-name-column"
                  element="div"
                  weight="bold"
                >
                  {t(value)}{' '}
                  <Tooltip title={t(row.desc)}>
                    <Icon width="14px" height="14px" symbol="circle-info" />
                  </Tooltip>
                </Text>
              )}
              dataIndex="name"
            />
            <Column width={50} render={() => <ArrowAction />} />
          </Table>
        </div>
      )}
      buttonLevel={1}
      destroyTooltipOnHide
    />
  )
}

export default observer(PresetsDropdown)
