import { observer } from 'mobx-react-lite'
import NumberInput from '../../../../components/NumberInput'
import RichTextInput from '../../../../components/RichTextInput'
import Select from '../../../../components/Select'
import TextInput from '../../../../components/TextInput'
import { t } from '../../../../utils'

const FIELD_COMPONENTS = {
  text: TextInput,
  number: NumberInput,
  richtext: RichTextInput,
  checkbox: Select,
}
function FieldPrimitive({ fieldSettings, onChange }) {
  const { type, defaultValue } = fieldSettings
  const Component = FIELD_COMPONENTS[type]

  if (!Component) return null

  const onChangeDefaultValue = (event) => {
    onChange({
      defaultValue: typeof event === 'object' ? event.target.value : event,
    })
  }

  let defaultValueProps = {
    title: t('Default value (optional)'),
    label: t('Default value (optional)'),
    value: defaultValue,
    onChange: onChangeDefaultValue,
  }

  // have to convert boolean value into string for comparation
  if (type === 'checkbox') {
    defaultValueProps = {
      ...defaultValueProps,
      options: [
        { value: 'false', label: 'False' },
        { value: 'true', label: 'True' },
      ],
      onChange: (value) => {
        onChange({
          defaultValue: value === 'true',
        })
      },
      value: defaultValue === true ? 'true' : 'false',
    }
  }

  return (
    <>
      <Component {...defaultValueProps} />
    </>
  )
}

export default observer(FieldPrimitive)
