import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import URLField from './URLField'

import Text from '../../components/Text'
import Icon from '../../components/Icon'
import Button from '../../components/Button'
import { Column } from '../../components/Table'
import ActiveField from '../../components/ActiveField'
import ChangedField from '../../components/ChangedField'
import ResourceTable from '../../components/ResourceTable'
import ContentWrapper from '../../components/ContentWrapper'
import MakairaGravatar from '../../components/MakairaGravatar'

import { createRedirectPath, t } from '../../utils'

import ActionLayerStore from '../../stores/ActionLayerStore'
import { PageStore, UIStore } from '../../stores'

import './list.styl'

function TitleField(item, row) {
  const hasAdditionInfo = !row.isChild && row.id

  return (
    <div className="text-truncated">
      <Text>{item || t('no title yet')}</Text>
      {hasAdditionInfo && <Text className="title-id">{row.id}</Text>}
    </div>
  )
}

function InternalTitleField(item, row) {
  function getText() {
    if (!row.internalTitle && !row.snippetId) {
      return ''
    }

    if (row.snippetId) {
      return row.snippetId
    }

    return row.internalTitle
  }

  const hasAdditionInfo = !row.isChild && (row.snippetId || row.internalTitle)

  return (
    <div className="text-truncated">
      {hasAdditionInfo && <Text>{getText()}</Text>}
    </div>
  )
}

function ParentArrowIndicator({ row, expandedRows }) {
  if (row.isChild) return null

  if (expandedRows.includes(row.indexId)) {
    return <Icon symbol="angle-up" width={18} height={18} tabIndex={0} />
  }

  return <Icon symbol="angle-down" width={18} height={18} tabIndex={0} />
}

function LanguageField({ row, expandedRows, lang, index }) {
  if (row.isChild && row.language === lang && row.hasContent) {
    return <ActiveField active={row.active} tooltip />
  }

  if (row.hasContent && !expandedRows.includes(row.indexId) && !row.isChild) {
    const languageVersion = row.children.find((o) => o.language === lang)
    return languageVersion ? (
      <ActiveField active={languageVersion.active} tooltip />
    ) : null
  }

  if (!row.hasContent && !row.isChild && index === 0) {
    return <Text>{t('no added content yet')}</Text>
  }

  return null
}

function List() {
  const history = useHistory()
  const { languages } = UIStore
  const [expandedRows, setExpandedRows] = useState([])

  useEffect(() => {
    PageStore.fetchPages()
    PageStore.fetchFields()

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])

  function handleKeyDown(e) {
    const isInList = ['icon', 'rc-collapse-header'].some((item) =>
      Array.from(e.target.classList).includes(item)
    )
    if (isInList && e.keyCode === 32 && document.activeElement.tabIndex >= 0) {
      e.target.click()
    }
  }

  const onRowClick = (record) => {
    // when the use clicks on a not expanded row want to redirect him to first language version
    const child = record.languageVersions
      ? {
          language:
            UIStore.enterpriseConfiguration?.defaultLanguage ??
            record.languageVersions[0].language,
          relatedId: `related--${record.relatedId}--${record.type}`,
        }
      : record

    history.push(
      createRedirectPath(
        `/content/${record.id || child.relatedId}/${child.language}`
      )
    )
  }

  const handleDeletePage = (id) => (e) => {
    e.stopPropagation()
    showDeleteWarning([id])
  }

  const showDeleteWarning = (selectedRows) => {
    if (selectedRows.length) {
      ActionLayerStore.openActionLayer({
        header: t('delete_modal_title')(selectedRows.length),
        saveTitle: 'OK',
        onSave: handleDelete(selectedRows),
        onClose: () => {},
      })
    }
  }

  const handleDelete = (selectedRows) => async () => {
    if (!selectedRows.length) return

    await PageStore.bulkDelete(selectedRows)
  }

  const filters = [
    {
      type: 'search',
      title: t('Search'),
      name: 'q',
      onChange: PageStore.setFilter,
      defaultValue: PageStore.filter?.q,
    },
    {
      type: 'single-select',
      title: t('Filter by type'),
      placeholder: t('Select Type'),
      name: 'type',
      onChange: PageStore.setFilter,
      options: PageStore.fields.map((option) => ({
        ...option,
        label: t(option.label),
      })),
      value: PageStore.filter?.type,
    },
  ]

  const handleClickCreate = () => {
    const { defaultLanguage } = UIStore.enterpriseConfiguration
    return history.push(createRedirectPath(`/content/page/${defaultLanguage}`))
  }

  const handleSelectCreate = (option) => {
    const { defaultLanguage } = UIStore.enterpriseConfiguration
    return history.push(
      createRedirectPath(`/content/${option.key}/${defaultLanguage}`)
    )
  }

  const getTypeLabel = (type) => {
    if (type === 'makaira-productgroup') {
      type = 'product'
    }

    return t(
      PageStore.fields.find((item) => item.value === type)?.singular || ''
    )
  }

  const onClickExpandCell = (row) => (e) => {
    e.stopPropagation()

    if (expandedRows.includes(row.indexId)) {
      setExpandedRows(expandedRows.filter((indexId) => indexId !== row.indexId))
    } else {
      setExpandedRows([...expandedRows, row.indexId])
    }
  }

  const multiselectButton = {
    type: 'dropdown-button',
    text: t('Add Landing page'),
    action: handleClickCreate,
    onSelect: handleSelectCreate,
    options: [
      { value: 'page', label: t('Add Landing page') },
      { value: 'snippet', label: t('Add Snippet') },
    ],
  }

  return (
    <ContentWrapper className="page-editor-list">
      <ResourceTable
        title={t('Page Editor')}
        store={PageStore}
        resourceName="pages"
        loading={PageStore.state === 'pending'}
        onRowClick={onRowClick}
        rowKey="indexId"
        emptyText={t(
          'Nothing found - just create a new landing page or reset your search & filters above.'
        )}
        expandable
        expandedRows={expandedRows}
        expandedRowChange={setExpandedRows}
        filters={filters}
        filterParams="filter"
        headerAction={multiselectButton}
        createAction={multiselectButton}
        createActionText={t(
          'Create your first landingpage to tell your customers about your business!'
        )}
        hideEmptyTextWhenNoFilters
      >
        <Column
          width={50}
          title=""
          dataIndex=""
          className="page-editor-list__column-arrow-indicator"
          render={(item, row) =>
            row?.children?.length > 1 ? (
              <ParentArrowIndicator row={row} expandedRows={expandedRows} />
            ) : null
          }
          onCell={(row) => {
            if (!row.isChild && row?.children?.length > 1) {
              return {
                onClick: onClickExpandCell(row),
              }
            }
          }}
        />
        <Column
          width={350}
          title={t('Name (ID)')}
          dataIndex="title"
          className="page-editor-list__column-name"
          render={TitleField}
          sorter
        />
        <Column
          width={400}
          title={`${t('Internal title')} / Snippet ID`}
          dataIndex="title"
          className="page-editor-list__column-name"
          render={InternalTitleField}
        />
        <Column
          width={80}
          title={t('URL')}
          dataIndex="url"
          className="page-editor-list__column-url"
          render={(item, row) => <URLField item={item} row={row} />}
        />
        {languages.map((lang, index) => (
          <Column
            width={50}
            key={lang}
            title={lang.toUpperCase()}
            className="page-editor-list__column-active"
            dataIndex=""
            render={(item, row) => (
              <LanguageField
                index={index}
                row={row}
                expandedRows={expandedRows}
                lang={lang}
              />
            )}
            onCell={(row) => {
              if (!row.isChild && !row.hasContent) {
                if (index === 0) {
                  return { colSpan: languages.length + 1 }
                } else {
                  return { colSpan: 0 }
                }
              }
            }}
          />
        ))}
        <Column
          width={110}
          title=""
          dataIndex=""
          onCell={(row) => {
            if (!row.isChild && !row.hasContent) {
              return { colSpan: 0 }
            }
          }}
        />
        <Column
          width={150}
          title={t('Type')}
          dataIndex="type"
          className="page-editor-list__column-name"
          sorter
          render={(item) => (
            <div className="text-truncated">
              <Text>{getTypeLabel(item)}</Text>
            </div>
          )}
        />
        <Column
          width={80}
          title={t('Author')}
          className="page-editor-list__column-avatar"
          dataIndex="author"
          render={(item, row) =>
            item && (
              <Tooltip
                title={
                  <div>
                    <div>{item}</div>
                    <ChangedField
                      changed={{ date: row.changed }}
                      tooltipTextOnly
                    />
                  </div>
                }
              >
                <span>
                  <MakairaGravatar email={item} />
                </span>
              </Tooltip>
            )
          }
        />
        <Column title="" dataIndex="" />
        <Column
          width={220}
          className="page-editor-list__column-action page-editor-list__column-action--button"
          render={(item, row) =>
            !row.isChild &&
            row.id && (
              <Button onClick={handleDeletePage(row.id)} icon={'trash'}>
                {row.type === 'page' || row.type === 'snippet'
                  ? t('Delete')
                  : t('Delete content')}
              </Button>
            )
          }
        />
        <Column
          width={50}
          className="page-editor-list__column-action page-editor-list__column-action--arrow"
          render={(item, row) =>
            row.isChild && <Icon symbol="angle-right" width={18} height={18} />
          }
        />
      </ResourceTable>
    </ContentWrapper>
  )
}

export default observer(List)
