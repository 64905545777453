import { useState } from 'react'
import TextTruncate from 'react-text-truncate'

const JsonTruncate = (props) => {
  const { line, textTruncateChild, ...rest } = props
  const [lineTruncate, setLine] = useState(line)

  const onClickExpand = (e) => {
    e.preventDefault()
    setLine(10000)
  }

  return (
    <TextTruncate
      line={lineTruncate}
      textTruncateChild={
        <div>
          <a onClick={onClickExpand} href="#">
            {textTruncateChild}
          </a>
        </div>
      }
      {...rest}
    />
  )
}

export default JsonTruncate
