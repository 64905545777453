import { observer } from 'mobx-react-lite'
import { UIStore } from '../../stores'
import Heading from './Heading'
import ShadowScroll from '../ShadowScroll'
import Text from '../Text'
import classNames from 'classnames'

function Result(props) {
  const { type, items, showEAN, showImage, showURL } = props
  if (items.length == 0) return null

  const { documentTypes } = UIStore

  return (
    <div className="search-preview__group">
      <Heading type={type} allTypes={documentTypes} />

      <ShadowScroll maxHeight={275}>
        {items.map((item) => (
          <div
            onClick={() =>
              props.onAssignment &&
              props.onAssignment({ esId: item.es_id, type, item })
            }
            key={item.id}
            className={classNames('search-preview__item', {
              'search-preview__item--with-image': showImage,
            })}
          >
            {showImage && (
              <img width={40} height={40} src={item.picture_url_main} />
            )}
            <Text weight="bold">{item.title}</Text>
            {!showEAN && !showURL && <Text variant="book">{item.es_id}</Text>}
            {showEAN && <Text variant="book">EAN: {item.ean}</Text>}
            {showURL && <Text variant="book">{item.url}</Text>}
          </div>
        ))}
      </ShadowScroll>
    </div>
  )
}

export default observer(Result)
