export const ICON_TYPE = {
  text: 'text',
  keyword: 'key',
  string: 'text',
  nested: 'folder-tree',
  long: 'align-left',
  double: 'align-left',
  boolean: 'toggle-large-on__regular',
  date: 'calendar-days',
  integer: 'tally',
  float: 'align-left',
}

export const SUPPORTED_FIELD_TYPES = [
  'text',
  'keyword',
  'string',
  'nested',
  'long',
  'double',
  'boolean',
  'date',
  'integer',
  'float',
]

export const OPERATORS = {
  boolean: [
    { value: 'isTrue', label: 'is true' },
    { value: 'isFalse', label: 'is false' },
  ],
  string: [
    { value: 'like', label: 'like', comparison: 'text' },
    { value: 'notLike', label: 'not like', comparison: 'text' },
    { value: 'inList', label: 'equals / in list', comparison: 'text' },
    {
      value: 'notInList',
      label: 'not equals / not in list',
      comparison: 'text',
    },
    { value: 'empty', label: 'empty' },
    { value: 'notEmpty', label: 'not empty' },
    { value: 'eq', label: 'equals', comparison: 'text' },
  ],
  text: [
    { value: 'like', label: 'like', comparison: 'text' },
    { value: 'notLike', label: 'not like', comparison: 'text' },
    { value: 'inList', label: 'equals / in list', comparison: 'text' },
    {
      value: 'notInList',
      label: 'not equals / not in list',
      comparison: 'text',
    },
    { value: 'empty', label: 'empty' },
    { value: 'notEmpty', label: 'not empty' },
    { value: 'eq', label: 'equals', comparison: 'text' },
  ],
  keyword: [
    { value: 'like', label: 'like', comparison: 'text' },
    { value: 'notLike', label: 'not like', comparison: 'text' },
    { value: 'inList', label: 'equals / in list', comparison: 'text' },
    {
      value: 'notInList',
      label: 'not equals / not in list',
      comparison: 'text',
    },
    { value: 'empty', label: 'empty' },
    { value: 'notEmpty', label: 'not empty' },
    { value: 'eq', label: 'equals', comparison: 'text' },
  ],
  nested: [
    { value: 'like', label: 'like', comparison: 'text' },
    { value: 'notLike', label: 'not like', comparison: 'text' },
    { value: 'inList', label: 'equals / in list', comparison: 'text' },
    {
      value: 'notInList',
      label: 'not equals / not in list',
      comparison: 'text',
    },
    { value: 'empty', label: 'empty' },
    { value: 'notEmpty', label: 'not empty' },
    { value: 'eq', label: 'equals', comparison: 'text' },
  ],
  long: [
    { value: 'useValue', label: 'use value' },
    { value: 'gt', label: 'greater', comparison: 'number' },
    { value: 'gte', label: 'greater or equal', comparison: 'number' },
    { value: 'lt', label: 'less', comparison: 'number' },
    { value: 'lte', label: 'less or equal', comparison: 'number' },
    { value: 'between', label: 'between', comparison: 'rangeNumber' },
    { value: 'eq', label: 'equals', comparison: 'number' },
  ],
  integer: [
    { value: 'useValue', label: 'use value' },
    { value: 'gt', label: 'greater', comparison: 'number' },
    { value: 'gte', label: 'greater or equal', comparison: 'number' },
    { value: 'lt', label: 'less', comparison: 'number' },
    { value: 'lte', label: 'less or equal', comparison: 'number' },
    { value: 'between', label: 'between', comparison: 'rangeNumber' },
    { value: 'eq', label: 'equals', comparison: 'number' },
  ],
  double: [
    { value: 'useValue', label: 'use value' },
    { value: 'gt', label: 'greater', comparison: 'number' },
    { value: 'gte', label: 'greater or equal', comparison: 'number' },
    { value: 'lt', label: 'less', comparison: 'number' },
    { value: 'lte', label: 'less or equal', comparison: 'number' },
    { value: 'between', label: 'between', comparison: 'rangeNumber' },
    {
      value: 'lta',
      label: 'less than 30% of the amount',
      comparison: 'number',
    },
    { value: 'eq', label: 'equals', comparison: 'number' },
  ],
  float: [
    { value: 'useValue', label: 'use value' },
    { value: 'gt', label: 'greater', comparison: 'number' },
    { value: 'gte', label: 'greater or equal', comparison: 'number' },
    { value: 'lt', label: 'less', comparison: 'number' },
    { value: 'lte', label: 'less or equal', comparison: 'number' },
    { value: 'between', label: 'between', comparison: 'rangeNumber' },
    { value: 'eq', label: 'equals', comparison: 'number' },
  ],
  date: [
    { value: 'eq', label: 'equals', comparison: 'datepicker' },
    { value: 'since', label: 'since', comparison: 'datepicker' },
    { value: 'until', label: 'until', comparison: 'datepicker' },
    { value: 'within', label: 'within (days)', comparison: 'withDays' },
    {
      value: 'between',
      label: 'between (date)',
      comparison: 'dateRangePicker',
    },
    {
      value: 'sameYear',
      label: 'equals same year',
      comparison: 'dateRangePicker',
    },
  ],
}

export const CATEGORY_CATID = {
  id: 'category.catid',
  title: 'Category (category.catid)',
  type: 'keyword',
}

export const PRESETS = [
  {
    name: 'Interesting products in the same year',
    desc: 'The recommendation is based on products that are bought together based on the purchase date.',
    id: 'sameyear',
  },
  {
    name: 'Accessories',
    desc: 'The recommendation is based on products that are bought together and the recommended products are in a lower price range.',
    id: 'accessories',
  },
  {
    name: 'New products',
    desc: 'The recommendation is based on products that are bought together and the number of sold items.',
    id: 'justin',
  },
  {
    name: 'Other users also bought',
    desc: 'Shows products that were added to the cart by other users.',
    id: 'alsobought',
  },
]

export const NONE_COMPARISON_OPERATORS = [
  'isTrue',
  'isFalse',
  'empty',
  'notEmpty',
  'useValue',
]
