import { t } from '../../../utils'
import PreviewBundle from './PreviewBundle'
import ContentEditor from './ContentEditor'
import HeadingMain from '../../../components/Headings/HeadingMain'
import ShadowScroll from '../../../components/ShadowScroll'

const Content = () => {
  return (
    <div className="bundle-content">
      <HeadingMain className="bundle__title">
        {t('and will be displayed like …')}
      </HeadingMain>
      {/*
      <Text size="echo" weight="bold" className="bundle__title" element="h1">
        {t('and will be displayed like …')}
      </Text>
      */}
      <ShadowScroll
        maxWidth="100rem"
        className="bundle-content__wrapper"
        direction="horizontal"
      >
        <PreviewBundle />

        <ContentEditor />
      </ShadowScroll>
    </div>
  )
}

export default Content
