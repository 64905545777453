import { useEffect, useState } from 'react'
import StreamStore from '../../stores/StreamStore'
import { observer } from 'mobx-react-lite'
import AutoComplete from '../AutoComplete'

function StreamSelect(props) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchStreams() {
      await StreamStore.fetchAllStreams()

      setLoading(false)
    }

    fetchStreams()
    // eslint-disable-next-line
  }, [])

  function formatOptions() {
    const options = StreamStore.streams

    return options.map((stream) => ({
      value: String(stream.id),
      label: stream.title,
    }))
  }

  function onSelect(selectedValue) {
    props.onChange(selectedValue)
  }

  return (
    <AutoComplete
      {...props}
      placeholder={props.placeholder}
      onSelect={onSelect}
      value={props.value}
      defaultOptions={formatOptions()}
      loading={loading}
    />
  )
}

export default observer(StreamSelect)
