import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { UIStore, MonitoringStore } from '../../stores'
import ImporterPageTitle from '../SearchConfigurationV3/ImporterPageTitle'
import Chart from './Chart'
import CheckSearchTerms from './CheckSearchTerms'
import Tables from './Tables'
import Filter from './Filter'

import './style.styl'

const SearchMonitoring = () => {
  useEffect(() => {
    MonitoringStore.resetCheckSearchTerms(
      UIStore.enterpriseConfiguration.defaultLanguage
    )
    MonitoringStore.fetchSearchTerm()
    MonitoringStore.fetchSearchTerm(true)
  }, [])

  return (
    <>
      <ImporterPageTitle />
      <div className="content-wrapper search-monitoring">
        <div>
          <Filter />
          <Tables />
          <div className="search-monitoring__search-term">
            <Chart />
            <CheckSearchTerms />
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(SearchMonitoring)
