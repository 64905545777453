import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import LoadingScreen from '../../components/LoadingScreen'
import PageTitle from '../../components/PageTitle'
import Switch from '../../components/Switch'
import { ActionLayerStore, FilterStore, UIStore } from '../../stores'
import { createRedirectPath, t } from '../../utils'
import { validateFilterFormV3 } from './validate'

import api from '../../api'
import SortModal from './SortModal'
import TitleSection from './TitleSection'
import ConfigSection from './ConfigSection'

import './style.styl'
import DisplaySection from './DisplaySection'

const Edit = () => {
  const { id } = useParams()
  const filter = FilterStore.filterDetail
  const isDirty = FilterStore.isDirty
  const errors = FilterStore.errors
  const history = useHistory()
  const [dependsOnDetail, setDependsOnDetail] = useState({})
  const [dependList, setDependList] = useState([])
  const [fetchingDependList, setFetchingDependList] = useState(false)

  useEffect(() => {
    const initial = async () => {
      FilterStore.fetchFields()
      handleSearchDependList()
      const filterDetail = await FilterStore.fetchFilterDetails(id)
      if (filterDetail && filterDetail.dependsOn) {
        try {
          const { data } = await api.filters.get(filterDetail.dependsOn)
          setDependsOnDetail(data)
        } catch (error) {
          console.error(error)
        }
      }
    }
    initial()
    // eslint-disable-next-line
  }, [])

  const onValidateForm = () => {
    try {
      const validData = validateFilterFormV3(
        UIStore.enterpriseConfiguration.defaultLanguage
      ).validateSync(JSON.parse(JSON.stringify(filter)), { abortEarly: false })
      FilterStore.setErrors({})
      return validData
    } catch (err) {
      if (err.errors && Array.isArray(err.errors) && err.errors.length > 0) {
        const newErrors = {}
        for (const innerError of err.inner) {
          newErrors[innerError.path] = innerError.message
        }
        FilterStore.setErrors(newErrors)
      }
      return false
    }
  }

  const onSaveFilter = async (isStay) => {
    const validData = onValidateForm()

    if (!validData) return false

    const newId = await FilterStore.updateOrCreate(filter)

    // We need to redirect to the correct URL if the page was created
    if (newId && isStay) {
      history.push(createRedirectPath(`/filter/${newId}`))
    }
    if (!isStay) {
      history.push(createRedirectPath(`/filter`))
    }
  }

  const onClose = () => {
    FilterStore.resetFilter()
    history.push(createRedirectPath('/filter'))
  }

  const handleSearchDependList = async () => {
    setFetchingDependList(true)

    const { data } = await api.filters.getAll({
      pagination: { pageSize: 99999 },
    })

    setFetchingDependList(false)
    setDependList(data)
  }

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        onSave: onSaveFilter,
        onClose: onClose,
        saveTitle: t('Save & back to list'),
        closeTitle: t('Abort & back to list'),
        onSaveWithContinue: async () => onSaveFilter(true),
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty, errors])

  if (FilterStore.isFetchingDetail) return <LoadingScreen />

  return (
    <>
      <PageTitle
        prefix={filter.id ? t('You are editing') : t('You are creating')}
        metaInfo={
          filter.id && {
            timestamp: filter.changed?.date,
            timezone: filter.changed?.timezone,
            user: filter.user,
            id: filter.id,
          }
        }
        hiddenFields={['internal-title']}
      >
        {filter.id
          ? get(
              filter,
              ['title', UIStore.enterpriseConfiguration.defaultLanguage],
              t('no title yet')
            )
          : t('New filter')}
      </PageTitle>
      <div className="content-wrapper filter-edit">
        <Switch
          checked={filter.active}
          type="horizontal"
          title={t('Active')}
          onChange={(checked) =>
            FilterStore.onChangeFilter(filter, 'active', checked)
          }
        />
        <TitleSection />
        <ConfigSection
          dependList={dependList}
          dependsOnDetail={dependsOnDetail}
          setDependsOnDetail={setDependsOnDetail}
          fetchingDependList={fetchingDependList}
        />
        <DisplaySection />
      </div>
      <SortModal />
    </>
  )
}

export default observer(Edit)
