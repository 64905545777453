import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/banner?${queryString}`

    return fetch(target)
  },

  async getById(id) {
    const target = `/banner/${id}`
    return fetch(target)
  },

  async create(banner) {
    return fetch('/banner', { method: 'POST', body: JSON.stringify(banner) })
  },

  async update(banner) {
    return fetch(`/banner/${banner.id}`, {
      method: 'PUT',
      body: JSON.stringify(banner),
    })
  },

  async delete(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))

    await fetch(`/banner?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },

  async bannerType() {
    return fetch('/banner-type')
  },

  async deviceType() {
    return fetch('/device-type')
  },

  async getDeviceTypes() {
    return fetch('/device-type')
  },

  async updateDeviceTypes(deviceTypes = []) {
    return fetch(`/device-type/bulk`, {
      method: 'PUT',
      body: JSON.stringify(deviceTypes),
    })
  },

  async getBannerTypes() {
    return fetch('/banner-type')
  },

  async updateBannerTypes(bannerTypes = []) {
    return fetch(`/banner-type/bulk`, {
      method: 'PUT',
      body: JSON.stringify(bannerTypes),
    })
  },
}
