import ReactApexChart from 'react-apexcharts'
import { range } from 'lodash'
import { predefinedColors } from './constants'

const LineChart = ({ data, categoryLimit }) => {
  const dataTransformed = Object.keys(data).reduce((acc, date, idx) => {
    // Iterate over the keys of the inner object (category) for each date
    Object.keys(data[date]).forEach((category) => {
      // Check if there's already an object in newData with the same category name
      const existingCategory = acc.find((item) => item.name === category)
      // Extract the value for the current category on the current date
      const value = data[date][category]

      if (existingCategory) {
        // If the category already exists in newData, add the new date-value pair to its data array
        let lastIdxHaveValue = idx - 1
        while (
          idx > 0 &&
          lastIdxHaveValue >= 0 &&
          !existingCategory.data[lastIdxHaveValue]
        ) {
          lastIdxHaveValue -= 1
        }
        existingCategory.data.push({
          x: date,
          y:
            value +
            (existingCategory.data[lastIdxHaveValue]
              ? existingCategory.data[lastIdxHaveValue].y
              : 0),
        })
        acc
          .find((item) => item.name === category + ' limit')
          .data.push({
            x: date,
            y: categoryLimit.find((limit) => limit.name === category)
              ?.requestLimit,
          })
      } else {
        // If the category doesn't exist in newData, create a new object with the category name
        // and initialize its data array with the current date-value pair
        acc.push(
          {
            name: category,
            data: [{ x: date, y: value }],
          },
          {
            name: category + ' limit',
            data: [
              {
                x: date,
                y: categoryLimit.find((limit) => limit.name === category)
                  ?.requestLimit,
              },
            ],
          }
        )
      }
    })

    return acc
  }, [])

  return (
    <ReactApexChart
      options={{
        colors: predefinedColors,
        chart: {
          id: 'statistic-line',
          background: '#522941',
          toolbar: {
            show: false,
          },
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          labels: {
            colors: 'white',
          },
        },
        stroke: {
          width: range(-1, categoryLimit.length * 2 - 1).map((_, index) =>
            index % 2 === 0 ? 5 : 2
          ),
          curve: 'smooth',
          dashArray: range(-1, categoryLimit.length * 2 - 1).map((_, index) =>
            index % 2 === 0 ? 0 : 5
          ),
        },
        grid: {
          row: {
            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.1,
          },
          column: {
            colors: ['transparent', 'transparent'],
            opacity: 0.1,
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: 'white',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: 'white',
            },
            formatter: (val) =>
              val?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          },
        },
      }}
      series={dataTransformed}
      height={350}
      type="line"
    />
  )
}

export default LineChart
