import { useEffect } from 'react'
import { InputNumber } from 'antd'

export default function RangeOperator(props) {
  const { isVisible, config, name, value, formikProps } = props
  const { setFieldValue } = formikProps

  useEffect(() => {
    if (!Array.isArray(value)) {
      // Set or sanitize initial values if necessary.
      setFieldValue(name, [0, 0])
    }
  }, [name, value, setFieldValue])

  function handleChange({ index, v }) {
    let updated = [...value]

    updated[index] = v

    setFieldValue(name, updated)
  }

  if (!isVisible) return null

  return (
    <>
      <InputNumber
        onChange={(v) => handleChange({ index: 0, v })}
        value={value[0]}
      />

      {config.showMultiple && (
        <InputNumber
          onChange={(v) => handleChange({ index: 1, v })}
          value={value[1]}
        />
      )}
    </>
  )
}
