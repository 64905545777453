import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async fetchEnterprisePage({
    searchPhrase,
    language = 'de',
    shopId,
    count = 20,
    page = 1,
    useStock = false,
    url,
  }) {
    let constraints = {
      'query.language': language,
      'query.shop_id': shopId,
      'query.use_stock': useStock,
      'oi.user.timezone': '+0200',
    }

    const body = {
      searchPhrase: searchPhrase,
      isSearch: !!searchPhrase,
      enableAggregations: false,
      count,
      offset: (page - 1) * count,
      constraints,
      url,
    }

    const host = searchPhrase ? '/search/public' : '/enterprise/page'

    const { data } = await fetch(host, {
      method: 'POST',
      body: JSON.stringify(body),
    })

    return data
  },
}
