import Button from '../Button'
import Properties from './Properties'

export default function SpotList(props) {
  const {
    spots = [],
    onRemoveSpot,
    properties,
    readOnly,
    parentId,
    selectLanguage,
  } = props

  if (!spots.length) {
    return null
  }

  return (
    <div className="hotspot__spot-list">
      {spots.map((spot, index) => (
        <div className="hotspot__spot-list-item" key={index}>
          <span className="hotspot__spot-list-item--index">{index + 1}</span>
          {!readOnly && (
            <Button
              onClick={() => onRemoveSpot(index)}
              icon="trash"
              level={1}
              variant="tertiary"
              className="hotspot__spot-list-item--remove"
            />
          )}

          <div className="hotspot__spot-list-item--content">
            <Properties
              properties={properties.map((property) => ({
                ...property,
                id: `${parentId}.spots.${index}.${property.id}`,
              }))}
              spot={spot}
              readOnly={readOnly}
              selectLanguage={selectLanguage}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
