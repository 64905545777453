import classNames from 'classnames'
import Checkbox from '../../../components/Checkbox'

export function FeaturerPermission({
  feature,
  addOrRemovePermission,
  isBaseRole,
}) {
  return (
    <>
      <div className="permission-line">
        <Checkbox
          disabled={
            feature.write || isBaseRole || !feature.existingReadPermission
          }
          checked={feature.read}
          onChange={() => addOrRemovePermission(feature, 'read', !feature.read)}
          label={'Read'}
          className={classNames({
            'feature-booked': isBaseRole || feature.existingReadPermission,
          })}
        />
      </div>
      {feature.existingWitePermission && (
        <div className="permission-line">
          <Checkbox
            checked={feature.write}
            disabled={isBaseRole || !feature.existingWitePermission}
            onChange={() => {
              if (feature.write) {
                addOrRemovePermission(feature, 'write', false)
              } else {
                addOrRemovePermission(feature, 'write', true)
                addOrRemovePermission(feature, 'read', true)
              }
            }}
            label={'Write'}
            className={classNames({
              'feature-booked': isBaseRole || feature.existingWitePermission,
            })}
          />
        </div>
      )}
    </>
  )
}
