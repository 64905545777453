import { observer } from 'mobx-react-lite'
import { useRef, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { Formik } from 'formik'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { ActionLayerStore, SecretStore } from '../../stores'

import PageTitle from '../../components/PageTitle'
import ContentWrapper from '../../components/ContentWrapper'
import Switch from '../../components/Switch'
import TextInput from '../../components/TextInput'
import Textarea from '../../components/Textarea'
import AccessPermission from './components/AccessPermission'
import ExpireTime from './components/ExpireTime'
import FormikOnChange from '../../components/FormikOnChange'
import LoadingScreen from '../../components/LoadingScreen'
import Button from '../../components/Button'

import { createRedirectPath, t } from '../../utils'

import './style.styl'

const schema = Yup.object().shape({
  title: Yup.string().required(t('Name is required!')),
})

function SecretKeyDetail() {
  const formikRef = useRef()
  const { id } = useParams()
  const history = useHistory()
  const [showSecretKey, toggleSecretKey] = useState(false)
  const { secretDetail, isDirty, fetchSecretDetail, setSecretDetail } =
    SecretStore

  useEffect(() => {
    fetchSecretDetail(id)
    // eslint-disable-next-line
  }, [id])

  function handleClose() {
    SecretStore.setDirty(false)
    history.push(createRedirectPath('/secret'))
  }

  async function handleSaveAndStay() {
    await formikRef.current?.submitForm()

    if (formikRef.current !== undefined && !formikRef.current?.isValid) {
      return false
    }

    const newId = await SecretStore.updateOrCreate()

    // We need to redirect to the correct URL if the page was created
    if (newId) {
      history.push(createRedirectPath(`/secret/${newId}`))
    }
  }

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        onSave: handleSaveAndStay,
        onClose: handleClose,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  const handleSubmit = (values) => {
    setSecretDetail({ ...secretDetail, ...values })
  }

  if (SecretStore.isFetchingDetail) return <LoadingScreen />

  const initialValues = {
    title: secretDetail.title,
    description: secretDetail.description,
    expiry: secretDetail.expiry,
    isActive: secretDetail.isActive,
  }

  return (
    <>
      <PageTitle
        prefix={secretDetail.id ? t('You are editing') : t('You are creating')}
      >
        {secretDetail.id ? secretDetail.title : t('A new secret key')}
      </PageTitle>
      <ContentWrapper className="secret-detail">
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {(formikProps) => (
            <div className="secret-detail__container">
              <div className="detail-column">
                <FormikOnChange onChange={handleSubmit} />
                <Switch
                  checked={formikProps.values.isActive}
                  onChange={(value) =>
                    formikProps.setFieldValue('isActive', value)
                  }
                  title="Active"
                  type="horizontal"
                />
                <TextInput
                  label={t('Name')}
                  value={formikProps.values.title}
                  onChange={(e) =>
                    formikProps.setFieldValue('title', e.target.value)
                  }
                  error={formikProps.errors.title}
                />

                <ExpireTime
                  value={formikProps.values.expiry}
                  onChange={(value) =>
                    formikProps.setFieldValue('expiry', value)
                  }
                />
                <AccessPermission />
              </div>
              <div className="detail-column">
                {id !== 'new' && (
                  <div className="secret-key__row">
                    <TextInput
                      autoFocus
                      label={t('Key')}
                      value={
                        showSecretKey
                          ? secretDetail.plainSecret
                          : secretDetail.maskedSecret
                      }
                      icon={{
                        symbol: showSecretKey ? 'eye' : 'eye-slash',
                        onClick: () =>
                          secretDetail.plainSecret
                            ? toggleSecretKey(!showSecretKey)
                            : toast.error(t('Not enough permissions.')),
                      }}
                      disabled
                    />
                    {secretDetail.plainSecret && (
                      <CopyToClipboard
                        text={secretDetail.plainSecret}
                        onCopy={() => toast.success(t('Secret copied!'))}
                      >
                        <Button icon="copy" />
                      </CopyToClipboard>
                    )}
                  </div>
                )}
                <Textarea
                  title={t('Notes')}
                  value={formikProps.values.description}
                  onChange={(value) =>
                    formikProps.setFieldValue('description', value)
                  }
                />
              </div>
            </div>
          )}
        </Formik>
      </ContentWrapper>
    </>
  )
}

export default observer(SecretKeyDetail)
