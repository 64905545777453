import { makeAutoObservable, toJS } from 'mobx'
import { debounce, get } from 'lodash'
import { toast } from 'react-toastify'

import t from '../../utils/translate'
import UIStore from '../UIStore'
import api from '../../api'
import ABTestStore from '../ABTestStore'

class RankingMixStore {
  originConfig = {}
  config = {}
  fieldsValidation = {}
  isFetchingConfig = true
  isDirty = false

  previewProducts = []
  previewSettings = null
  previewLoading = false
  previewTotalCount = 0
  previewPage = 0

  MAX_PRODUCTS_IN_PREVIEW = 10

  debounceFetchProductPreview = debounce(() => {
    return this.fetchProductPreview()
  }, 300)

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetchConfig() {
    this.isFetchingConfig = true
    yield this.fetchFieldsValidation()
    try {
      const { data } = yield api.rankingMix.get()

      this.config = data
      this.originConfig = data
      this.isFetchingConfig = false
    } catch (error) {
      this.isFetchingConfig = false
      toast.error('Something went wrong fetching ranking mix config.')
      this.state = 'error'
    }
  }

  *postConfig() {
    try {
      yield api.rankingMix.update(this.config)
      this.isDirty = false
      return true
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
      return false
    }
  }

  *fetchFieldsValidation() {
    try {
      const { data } = yield api.rankingMix.getFieldsValidation()
      this.fieldsValidation = data
    } catch (error) {
      toast.error('Something went wrong fetching ranking mix field validation.')
      this.state = 'error'
    }
  }

  updateConfigData(config, key, value) {
    config[key] = value
    this.isDirty = true

    this.debounceFetchProductPreview()
  }

  reset() {
    this.isDirty = false
    this.config = toJS(this.originConfig)

    this.fetchProductPreview()
  }

  *fetchProductPreview(settings, changedVariable) {
    this.previewLoading = true

    // If no search has been made yet, we skip the fetching of the preview.
    if (!this.previewSettings && !settings) {
      this.previewLoading = false
      return
    }

    if (settings) {
      this.previewSettings = settings
    }

    let updatedPageIndex = this.previewSettings.page

    // We need to reset the current page if the user changes the search query itself.
    // It should not be reset if changes were done to the ranking mix config itself so the user
    // directly see how his changes effect different pages.
    if (
      changedVariable === 'previewType' ||
      changedVariable === 'searchValue' ||
      changedVariable === 'categoryId'
    ) {
      updatedPageIndex = 0
    }

    // If no categoryId and no searchValue is set then the search query is incomplete and nothing
    // has to be fetched.
    if (!this.previewSettings.categoryId && !this.previewSettings.searchValue) {
      this.previewLoading = false
      this.previewProducts = []
      this.previewPage = updatedPageIndex
      return
    }

    const previewRankingType =
      this.previewSettings.previewType === 'product' ? 'search' : 'category'
    let config = this.config

    if (ABTestStore.showMetaconfigView) {
      const variant = ABTestStore.rankingMixVariantsConfig.find(
        (v) => v.variation_name === ABTestStore.selectedVariant
      )

      config.search = get(variant, 'settings.0', {})
      config.category = get(variant, 'settings.1', {})
    }

    const previewRankingMix = config[previewRankingType]

    try {
      const response = yield api.common.fetchSearchPreviewResult({
        searchPhrase: this.previewSettings.searchValue,
        categoryId: this.previewSettings.categoryId,
        type: this.previewSettings.previewType,
        count: this.MAX_PRODUCTS_IN_PREVIEW,
        language: UIStore.languages[0],
        rankingMix: previewRankingMix,
        shopId: UIStore.shopId,
        page: updatedPageIndex ? updatedPageIndex - 1 : updatedPageIndex,
        group: this.previewSettings.group,
      })

      this.previewPage = updatedPageIndex
      this.previewTotalCount = response.product?.total ?? 0
      this.previewProducts = response?.product?.items ?? []
    } catch (e) {
      console.error(e)

      yield toast.error(t('Rankingmix preview could not be loaded.'))
    } finally {
      this.previewLoading = false
    }
  }

  get maxPreviewPage() {
    return Math.ceil(this.previewTotalCount / this.MAX_PRODUCTS_IN_PREVIEW)
  }
}

export default new RankingMixStore()
