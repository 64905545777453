import classNames from 'classnames'

const Tab = ({ items, onChange, activeTab }) => {
  return (
    <div className="tabs">
      {items.map((tab, index) => (
        <div
          key={`${index}-item`}
          className={classNames('tabs__item', {
            'tabs__item--active': activeTab === tab.id,
            'tabs__item--disabled': tab.disabled,
          })}
          onClick={() => !tab.disabled && onChange(tab.id)}
        >
          {tab.title}
        </div>
      ))}
    </div>
  )
}

export default Tab
