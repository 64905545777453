import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { routes } from '../../../routing'
import ABTestStore from '../../../stores/ABTestStore'
import { createRedirectPath } from '../../../utils'
import t from '../../../utils/translate'
import Button from '../../Button'
import Text from '../../Text'

const ConfigTitle = ({ showPanel, setShowPanel }) => {
  const history = useHistory()
  const location = useLocation()

  const { editingExperiment } = ABTestStore

  const isCreateView = location.pathname.endsWith('/ab-testing/new')

  const handleBackClick = () => {
    if (isCreateView) {
      ABTestStore.updateCreateStep(1)
    } else history.push(createRedirectPath(routes.abTestingUpcomingList.path))
  }

  return (
    <div className="ab-metaconfig__title">
      <Button
        className="ab-metaconfig__back-btn"
        variant="secondary"
        icon="chevron-left"
        onClick={handleBackClick}
      />
      <Text size="echo">A/B-Test › </Text>
      <Text size="echo" weight="bold">
        {editingExperiment.title}
      </Text>
      <Button
        className={classNames('ab-metaconfig__config-btn', {
          'ab-metaconfig__config-btn--open': showPanel,
        })}
        onClick={() => setShowPanel((value) => !value)}
        variant="secondary"
        icon="sliders-up"
      />
      {editingExperiment.id && editingExperiment.running && (
        <Button
          className="ab-metaconfig__start-btn"
          variant="secondary-blocker"
          icon="rocket"
          iconPosition="left"
          onClick={() => ABTestStore.stop(editingExperiment.id)}
        >
          {t('Stop test')}
        </Button>
      )}
      {editingExperiment.id &&
        !(editingExperiment.running || editingExperiment.finished) && (
          <Button
            className="ab-metaconfig__start-btn"
            variant="primary"
            icon="rocket"
            iconPosition="left"
            onClick={() => ABTestStore.start(editingExperiment.id)}
            disabled={ABTestStore.isDisableStartTest}
          >
            {t('Start test')}
          </Button>
        )}
    </div>
  )
}

export default observer(ConfigTitle)
