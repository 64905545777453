import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import AutoComplete from '../AutoComplete'

import { FieldStore, StreamStore } from '../../stores'

import t from '../../utils/translate'

const SUPPORTED_FIELD_TYPES = [
  'text',
  'keyword',
  'string',
  'long',
  'double',
  'date',
  'integer',
  'float',
]

const SortFieldAutocomplete = ({
  onChange,
  value,
  selectedStream,
  fullWidth = false,
}) => {
  const { fields } = FieldStore

  useEffect(() => {
    const stream = StreamStore.getById(selectedStream)

    if (stream) {
      FieldStore.fetchFields(stream.datatype)
    }
  }, [selectedStream])

  const filterAndFormatFields = (allFields, searchString = '') => {
    return allFields
      .filter((field) => SUPPORTED_FIELD_TYPES.includes(field.type))
      .filter((field) =>
        field.title.toLowerCase().includes(searchString.toLowerCase())
      )
      .map((field) => ({ label: field.title, value: field.id }))
  }

  const handleSearch = (searchString) => {
    return filterAndFormatFields(FieldStore.fields, searchString)
  }

  const handleSelect = (selectedValue) => {
    onChange(selectedValue)
  }

  return (
    <AutoComplete
      title={t('Sort by')}
      onSearch={handleSearch}
      defaultOptions={filterAndFormatFields(fields)}
      onSelect={handleSelect}
      value={value}
      fullWidth={fullWidth}
    />
  )
}
export default observer(SortFieldAutocomplete)
