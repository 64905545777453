import Text from '../../components/Text'
import Icon from '../../components/Icon'
import t from '../../utils/translate'
import { observer } from 'mobx-react-lite'

const TrialDescription = () => {
  return (
    <div className="personalization__trial-description-section">
      <Text element="div" size="charlie">
        {t("What's next?")}
      </Text>
      <Text element="div" weight="heavy" size="echo">
        {t('Try Machine Learning for free for 30 days')}
      </Text>
      <div className="personalization__trial-description-row">
        <div className="personalization__trial-description-col">
          <Text element="p" size="charlie">
            {t('ml_trial_description_1')}
          </Text>
          <Text element="p" size="charlie">
            {t(
              'The best way to see the effects of the machine learning is to run an A/B test with versus without the machine learning enabled.'
            )}
          </Text>
          <Text element="p" size="charlie">
            {t(
              'We will set up a test for you so the effects can be measured and seen. We recommend splitting the traffic in half for the experiment.'
            )}
          </Text>
        </div>
        <div className="personalization__trial-pros-col">
          <Text weight="bold" size="delta">
            {t('Testing is easy and smart')}
          </Text>
          <div className="personalization__trial-pros">
            <Icon symbol="check-circle-regular" />
            <Text size="charlie">{t('Instant status insights')}</Text>
          </div>
          <div className="personalization__trial-pros">
            <Icon symbol="check-circle-regular" />
            <Text size="charlie">{t('Results within a few days')}</Text>
          </div>
          <div className="personalization__trial-pros">
            <Icon symbol="check-circle-regular" />
            <Text size="charlie">{t('Cancel at any time')}</Text>
          </div>
          <div className="personalization__trial-pros">
            <Icon symbol="check-circle-regular" />
            <Text size="charlie">{t('No automatic subscription')}</Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(TrialDescription)
