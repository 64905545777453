import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import ABTestStore from '../../stores/ABTestStore'
import { t } from '../../utils'
import Button from '../Button'
import Icon from '../Icon'
import Text from '../Text'
import CodeModal from './CodeModal'

import './style.styl'

const ABFrontend = () => {
  const [modalVisible, setModalVisible] = useState(false)

  const params = useParams()

  let tableData = [
    {
      name: 'A',
      state: t('OFF'),
    },
    {
      name: 'B',
      state: t('ON'),
    },
  ]

  if (params.case === 'frontend') {
    tableData = ABTestStore.editingExperiment.variations || []
  }

  const varianNames = tableData.map((data) => data.name)

  return (
    <div className="ab-frontend">
      <Icon symbol="book" width={50} height={50} />
      <Text weight="bold">{t('ab_frontend_description')}</Text>
      <div className="ab-frontend__available-versions">
        <Text size="charlie">
          {t('Available versions for this test') + ': '}
        </Text>
        {varianNames.map((data) => (
          <Text
            key={data}
            className={`ab-frontend__variant ab-frontend__variant--${data}`}
          >
            {data}
          </Text>
        ))}
      </div>
      <Button
        iconPosition="left"
        icon="code"
        level={1}
        onClick={() => setModalVisible(true)}
        variant="tertiary"
      >
        {t('Show code snippet')}
      </Button>
      <CodeModal
        id={ABTestStore.testDetail?.id}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </div>
  )
}

export default observer(ABFrontend)
