import { fetchUtil as fetch } from '../utils/fetchUtil'
import { buildQueryString } from '../utils'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/experiment?${queryString}`

    return fetch(target)
  },
  async getTimelineTests(options) {
    const queryString = buildQueryString(options)
    const target = `/experiment/timeline?${queryString}`
    return fetch(target)
  },
  async getSupportedMetrics() {
    return fetch('/experiment/supported-metrics')
  },
  async get(id) {
    const target = `/experiment/${id}`

    return fetch(target)
  },
  async getVariationConfig(id) {
    const target = `/experiment/variation-configs/${id}`

    return fetch(target)
  },
  async putVariationConfig(config, id) {
    const target = `/experiment/variation-configs/${id}`

    return fetch(target, {
      method: 'PUT',
      body: JSON.stringify({
        configs: config?.configs || config,
        recommendation_id: config?.recommendation_id,
      }),
    })
  },
}
