import moment from 'moment'
import 'moment/locale/de'

import { UIStore } from '../../stores'

export default function formatDateNow(date, timezone = 'UTC') {
  let formattedTime
  if (timezone === 'UTC') {
    formattedTime = moment.utc(date).local()
  } else {
    formattedTime = moment(date).local()
  }

  formattedTime.locale(UIStore.currentLanguage)

  return formattedTime.fromNow()
}
