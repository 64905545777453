import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import t from '../../utils/translate'

class IndexStore {
  indices = []
  activeIndices = []
  indexOverview = []
  state = 'pending' // "pending", "done" or "error"

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  getIndicesByLanguage(lang) {
    return this.indices.filter((item) => item.language === lang)
  }

  *fetchIndices() {
    try {
      const { data } = yield api.indices.get()
      this.indices = data
      this.activeIndices = data.filter((item) => item.state === 'active')
      this.indexOverview = this.activeIndices.map((activeIndex) => {
        let passiveIndex = data.find(
          (index) =>
            index.state === 'passive' && index.language === activeIndex.language
        )
        if (passiveIndex && passiveIndex.status === 'yellow') {
          activeIndex.status = 'yellow'
          activeIndex.indexActivation = passiveIndex.indexActivation
        }

        return activeIndex
      })
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong loading the search configuration.')
    }
  }

  *activateIndex(name) {
    this.state = 'pending'

    try {
      const { data } = yield api.indices.activate(name)
      this.indices = data
      this.state = 'done'

      toast.success(t('Index has been activated!'))

      this.fetchIndices()
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong activating the index.')
    }
  }

  *deleteIndex(name) {
    this.state = 'pending'

    try {
      yield api.indices.delete(name)

      toast.success(t('Index has been deleted!'))

      this.fetchIndices()
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong deleting the index.')
    }
  }
}

export default new IndexStore()
