import { observer } from 'mobx-react-lite'
import { t } from '../../../utils'
import Modal from '../../../components/Modal'
import { EditorStore } from '../../../stores'
import PublishConfig from './PublishConfig'
import './settingModal.styl'
import useHoverModal from './useHoverModal'

function SettingModal() {
  const {
    closeSettings,
    isSettingsVisible,
    publishConfig,
    getValueForPublishConfig,
    getDefaultPublishConfig,
  } = EditorStore

  useHoverModal('.page-editor-setting-modal', isSettingsVisible)

  if (!isSettingsVisible) return null

  const defaultConfig = getDefaultPublishConfig()
  const defaultConfigValue = getValueForPublishConfig(defaultConfig.identifier)

  const isDisable = (type) =>
    defaultConfigValue.active && type !== defaultConfig.identifier

  return (
    <Modal
      className="page-editor-setting-modal"
      closeIconText={t('Done')}
      visible={isSettingsVisible}
      onClose={closeSettings}
      header={t('Playout')}
      mask
      align="left"
      maskClosable
      customDialogClassName="page-editor-setting-dialog"
    >
      <PublishConfig
        key={defaultConfig.identifier}
        config={defaultConfig}
        disabled={false}
      />
      {publishConfig.map((personas) => {
        return (
          <PublishConfig
            key={personas.identifier}
            config={personas}
            disabled={isDisable(personas.identifier)}
          />
        )
      })}
    </Modal>
  )
}

export default observer(SettingModal)
