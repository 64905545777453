import { intersection } from 'lodash'
import { observer } from 'mobx-react-lite'

import AuthStore from '../../stores/AuthStore'
import UIStore from '../../stores/UIStore'

const PrivateElement = ({ feature, children, permission }) => {
  if (AuthStore.isSuperAdmin) return <>{children}</>

  if (feature && !UIStore.features.includes(feature)) return null

  if (
    permission &&
    intersection(permission, AuthStore.permissions).length === 0
  )
    return null

  return <>{children}</>
}

export default observer(PrivateElement)
