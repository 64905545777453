export default function Feed() {
  return {
    childrenData: false,
    content: '',
    contentType: 'none',
    innerStream: null,
    name: '',
    parents: false,
    parentsSingleOnly: false,
    renderHeader: true,
    separator: 'none',
    stream: null,
    token: '',
    wrapping: 'none',
    header: '',
    footer: '',
    lineSeparator: '',
    fields: [],
  }
}
