import classNames from 'classnames'
import RcSwitch from 'rc-switch'
import './switch.styl'

export default function Switch({
  title,
  defaultChecked,
  checked,
  disabled,
  onChange,
  type = 'vertical', //vertical or horizontal
  className,
  size = 'normal',
}) {
  return (
    <div
      className={classNames(
        'switch-input',
        `switch-input--${type}`,
        `switch-input--${size}`,
        className
      )}
    >
      {title && <div className="switch-input__label">{title}</div>}
      <RcSwitch
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
}
