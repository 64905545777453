import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ActionLayerStore, PersonasStore, UIStore } from '../../stores'
import Text from '../../components/Text'
import { Column } from '../../components/Table'
import { createRedirectPath, t } from '../../utils'
import ResourceTable from '../../components/ResourceTable'
import EditPersonasModal from './EditPersonasModal'
import { useHistory, useLocation } from 'react-router'

const Personas = () => {
  const [loading, setLoading] = useState(true)
  const [editVisible, setEditVisible] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const history = useHistory()
  const location = useLocation()
  const id = location.search.split('=')[1]
  useEffect(() => {
    const init = async () => {
      UIStore.fetchAvailableLanguages()
      await PersonasStore.fetchPersonas()

      setLoading(false)
    }
    init()
  }, [])

  useEffect(() => {
    if (id && id !== PersonasStore.detail.id) {
      setEditVisible(true)
    }
  }, [id])

  const closeModal = () => {
    setEditVisible(false)
    history.replace(createRedirectPath(`/settings/personas`))
  }

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    if (!selectedRows.length) return

    await PersonasStore.delete(selectedRows)

    if (PersonasStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const filters = [
    {
      type: 'search',
      title: t('Search'),
      name: 'q',
      onChange: PersonasStore.setFilter,
      defaultValue: PersonasStore.filter?.q,
    },
  ]

  return (
    <div>
      <ResourceTable
        store={PersonasStore}
        resourceName="personas"
        rowKey="id"
        loading={loading}
        filters={filters}
        title={t('Personas')}
        headerAction={{
          type: 'create',
          children: t('Add'),
          action: () => {
            PersonasStore.getById('new')
            setEditVisible(true)
          },
        }}
        onRowClick={(record) =>
          history.push(createRedirectPath(`/settings/personas?id=${record.id}`))
        }
        bulkActions={[
          {
            title: t('Delete'),
            onExecute: () => showDeleteWarning(),
          },
        ]}
        rowSelection={{
          selectedRows,
          onSelectionChange: (newRows) => setSelectedRows(newRows),
        }}
      >
        <Column
          title={t('ID')}
          render={(instance) => <Text>{instance}</Text>}
          dataIndex="identifier"
        />
        <Column
          title={t('Name')}
          render={(name) => <Text>{name[UIStore.currentLanguage]}</Text>}
          dataIndex="name"
        />
        <Column
          title={t('Description')}
          render={(description) => (
            <Text>{description[UIStore.currentLanguage]}</Text>
          )}
          dataIndex="description"
        />
      </ResourceTable>
      <EditPersonasModal
        id={id}
        visible={editVisible}
        closeModal={closeModal}
      />
    </div>
  )
}

export default observer(Personas)
