import classnames from 'classnames'
import { useMemo } from 'react'
import Button from '../Button'
import DropdownButton from '../DropdownButton'

import ResourceTableTitle from '../ResourceTable/ResourceTableTitle'
import t from '../../utils/translate'
import Text from '../Text'
import AnimationButton from '../AnimationButton'

import './table.styl'

const EmptyTable = ({
  title,
  createAction,
  createActionText,
  emptyText,
  emptyContainerSize,
  hideEmptyTextWhenNoFilters,
  disabledCreateButton,
}) => {
  const actions = useMemo(
    () => ({
      create: ({ action, ...props }) => (
        <Button variant="tertiary" icon="plus" onClick={action} {...props} />
      ),
      'mark-all-as-read': ({ action, ...props }) => (
        <Button icon="check" iconPosition="left" onClick={action} {...props}>
          {t('Mark all as read')}
        </Button>
      ),
      'dropdown-button': DropdownButton,
    }),
    []
  ) // eslint-disable-line

  let Component = null
  if (typeof createAction === 'object') {
    Component = actions[createAction.type]
  }

  return (
    <div
      className={classnames(
        'empty-table',
        `empty-table--${emptyContainerSize}`
      )}
    >
      <ResourceTableTitle title={title} />

      <div className="empty-table__container">
        {typeof createAction === 'function' && (
          <AnimationButton
            animationDuration={7000}
            animationVariant="secondary"
            icon="plus"
            onClick={createAction}
            variant="secondary"
            level={-1}
            disabled={disabledCreateButton}
          >
            {t(createActionText ?? 'Create')}
          </AnimationButton>
        )}

        {typeof createAction === 'object' && (
          <>
            <p style={{ marginBottom: '1.5rem' }}>
              <Text>{createActionText}</Text>
            </p>
            <Component {...createAction} />
          </>
        )}

        {!hideEmptyTextWhenNoFilters && (
          <Text size="bravo" weight="medium">
            {emptyText}
          </Text>
        )}
      </div>
    </div>
  )
}

export default EmptyTable
