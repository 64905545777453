import { fetchUtil as fetch } from '../utils/fetchUtil'
import { buildQueryString } from '../utils'

export default {
  async getAll() {
    return fetch(`/importer/configs`)
  },

  async getSchedulerStatus() {
    return fetch('/importer/scheduler/status')
  },

  async updateIndexStatus() {
    return fetch('/importer/status')
  },

  async buildNewIndex(id) {
    return fetch(`/importer/scheduler/${id}/start`, {
      method: 'POST',
    })
  },

  async stopBuildIndex(id) {
    return fetch(`/importer/scheduler/${id}/stop`, {
      method: 'POST',
    })
  },

  async fetchLogs(params) {
    const queryString = buildQueryString(params)

    return fetch(`/importer/scheduler/logs?${queryString}`)
  },

  async create(importer) {
    const { data } = await fetch('/importer/config', {
      method: 'POST',
      body: JSON.stringify(importer),
    })
    return data
  },

  async update(importer) {
    await fetch(`/importer/config/${importer.id}`, {
      method: 'PUT',
      body: JSON.stringify(importer),
    })
  },

  async fetchShopLanguages(body) {
    const { data } = await fetch('/shop/available-languages', {
      method: 'POST',
      body: JSON.stringify(body),
    })
    return data
  },

  async deleteImporters(ids) {
    await fetch('/importer/config', {
      method: 'DELETE',
      body: JSON.stringify({ ids }),
    })
  },
}
