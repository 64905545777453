import { observer } from 'mobx-react-lite'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { routes } from '../../../routing'
import {
  ComponentStore,
  EditorStore,
  ActionLayerStore,
  UIStore,
  PageStore,
  RevisionStore,
  StreamStore,
} from '../../../stores'
import PageTitle from '../../../components/PageTitle'
import { t } from '../../../utils'
import ContentWrapper from '../../../components/ContentWrapper'
import './structure.styl'
import createRedirectPath from '../../../utils/createRedirectPath'
import useLeaveConfirm from '../../../utils/useLeaveConfirm'
import EditorModal from '../EditorModal'
import LoadingScreen from '../../../components/LoadingScreen'
import AddElementModal from './AddElementModal'
import Preview from '../Preview'
import Actions, { calculateArrowPosition } from '../../SmartBundles/Actions'
import PageConfiguration from '../PageConfiguration'
import ConfigurationStore from '../../../stores/ConfigurationStore'
import StructureCollapse from './StructureCollapse'
import WarningMappingModal from './WarningMappingModal'
import LockingPage from '../../../components/LockingPage'
import { toast } from 'react-toastify'

function Structure() {
  const formikRef = useRef()
  const history = useHistory()
  const { id, lang } = useParams()
  const isCreate = ['page', 'snippet', 'new'].includes(id)
  const [isConfigurationPanelShown, setIsConfigurationPanelShown] =
    useState(isCreate)
  const {
    state,
    pageToEdit,
    savePage,
    savePageAndStay,
    isDirty,
    setDirty,
    closeAddElementModal,
    closeEditElementModal,
    closeSettings,
  } = EditorStore

  const prompt = useLeaveConfirm(isDirty)

  useEffect(() => {
    // We need all components available here, so we have to skip the pagination
    async function init() {
      // EditorStore-State is here set to pending because after the first load the value would be 'done'
      // When going back to the list and into a new page the state would stay 'done' it will only be set
      // to pending when EditorStore.getById(id) is called. But because this functions requires
      // ComponentStore.fetchComponent to be finished there is a period where the page is not fully loaded
      // but EditorStore.state is done which leads to multiple re-renders of the editor.
      // To fix this we explicitly set the state here to pending again.
      EditorStore.state = 'pending'

      EditorStore.setContentLanguage(lang)
      await EditorStore.fetchPublishConfig()
      await ComponentStore.fetchComponents(true)
      await EditorStore.getById(id)
      ConfigurationStore.fetchConfiguration()

      if (
        !['page', 'snippet', 'new'].includes(id) &&
        !isNaN(parseInt(id, 10))
      ) {
        RevisionStore.fetchRevisions()
      }
    }
    init()

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // Make sure we always have all streams available for interactions
    // in the <ProductStreamInput /> component.
    StreamStore.fetchStreams()

    return () => {
      setDirty(false)
      closeAddElementModal()
      closeSettings()
      closeEditElementModal()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        saveTitle: t('Save & back to list'),
        closeTitle: t('Abort & back to list'),
        onSaveWithContinue: handleSaveAndStay,
        onSave: handleSave,
        onClose: handleClose,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  // recaculate the arrow position when title changed
  useEffect(() => {
    setTimeout(calculateArrowPosition, 50)
    // eslint-disable-next-line
  }, [EditorStore.pageTitle])

  const handleCheckValidFields = () => {
    EditorStore.checkValidateRequireFields()

    return !(
      Object.keys(EditorStore.invalidData.component).length > 0 ||
      Object.keys(EditorStore.invalidData.field).length > 0
    )
  }

  async function handleSaveAndStay() {
    await formikRef.current?.submitForm()

    if (formikRef.current !== undefined && !formikRef.current?.isValid) {
      return false
    }

    const isValidContentData = handleCheckValidFields()

    if (!isValidContentData) {
      EditorStore.closeAddElementModal()
      EditorStore.closeEditElementModal()
      EditorStore.closeSettings()
      toast.error(
        t('Please check all red border components and fill required fields!'),
        { position: 'top-left' }
      )

      return false
    }

    const newId = await savePageAndStay()

    // We need to redirect to the correct URL if the page was created
    if (newId) {
      history.push(
        createRedirectPath(`/content/${newId}/${EditorStore.contentLanguage}`)
      )
    }
  }

  function handleKeyDown(e) {
    const isInList = ['rc-collapse-header'].some((item) =>
      Array.from(e.target.classList).includes(item)
    )
    if (isInList && e.keyCode === 32 && document.activeElement.tabIndex >= 0) {
      e.target.click()
    }
  }

  function handleClose() {
    setDirty(false)
    closeAddElementModal()
    closeSettings()
    closeEditElementModal()
    history.push(createRedirectPath(routes.pageEditor.path))
  }

  async function handleSave() {
    await formikRef.current?.submitForm()
    if (formikRef.current !== undefined && !formikRef.current?.isValid) {
      return false
    }

    const isValidContentData = handleCheckValidFields()

    if (!isValidContentData) {
      EditorStore.closeAddElementModal()
      EditorStore.closeEditElementModal()
      EditorStore.closeSettings()
      toast.error(
        t('Please check all red border components and fill required fields!'),
        { position: 'top-left' }
      )

      return false
    }
    await savePage()
    PageStore.reset()
    history.push(createRedirectPath(routes.pageEditor.path))
  }

  if (state === 'pending') return <LoadingScreen />

  const handleSettingsClick = () => {
    setIsConfigurationPanelShown(true)
  }

  const createDraftRevision = () => {
    RevisionStore.createRevision(pageToEdit)
  }

  if (UIStore.isStorefrontPreview) {
    return (
      <Preview key={id} isConfigurationPanelShown={isConfigurationPanelShown} />
    )
  }

  return (
    <>
      {!isCreate && (
        <LockingPage
          type="page"
          targetId={id}
          redirectPath={createRedirectPath(routes.pageEditor.path)}
          onTakenOver={createDraftRevision}
        />
      )}
      <PageTitle
        prefix={pageToEdit.id ? t('You are editing') : t('You are creating')}
        metaInfo={
          pageToEdit.id && {
            timestamp: pageToEdit.changed?.date,
            timezone: pageToEdit.changed?.timezone,
            user: pageToEdit.user,
            id: pageToEdit.id,
            internalTitle: pageToEdit.internalTitle,
          }
        }
        additionalActions={
          <Actions
            onClickApply={async () => {
              await formikRef.current?.submitForm()
              if (formikRef.current?.isValid) {
                setIsConfigurationPanelShown(false)
              }
            }}
            onClickSetting={handleSettingsClick}
            isShowConfiguration={isConfigurationPanelShown}
          />
        }
      >
        {EditorStore.pageTitle}
      </PageTitle>
      <PageConfiguration
        shown={isConfigurationPanelShown}
        pageToEdit={pageToEdit}
        formikRef={formikRef}
      />
      <ContentWrapper>
        <div className="page-editor__wrapper">
          <div className="page-editor__elements">
            <StructureCollapse />
          </div>

          <Preview
            key={id}
            isConfigurationPanelShown={isConfigurationPanelShown}
          />
        </div>
      </ContentWrapper>

      <EditorModal />

      <AddElementModal />

      <WarningMappingModal />

      {prompt}
    </>
  )
}

export default observer(Structure)
