import { observer } from 'mobx-react-lite'
import Icon from '../../components/Icon'
import NumberInput from '../../components/NumberInput'
import Text from '../../components/Text'
import { FilterStore } from '../../stores'

const ValueItem = (
  { option, listeners, attributes, hidden, isOverlay, field, ...props },
  ref
) => {
  const { updatePositionValue } = FilterStore

  return (
    <div {...props} ref={ref} className="filter-edit__sort-modal-item">
      <button {...listeners}>
        <Icon symbol="bars" width={15} height={15} />
      </button>
      <NumberInput
        onChange={(value) => updatePositionValue(field, value)}
        min={1}
        max={9999}
        disabled
        value={field.position}
        borderless
      />
      <Text weight="bold">{field.value}</Text>
    </div>
  )
}

export default observer(ValueItem, { forwardRef: true })
