import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import AutoComplete from '../../components/AutoComplete'
import Button from '../../components/Button'
import NumberInput from '../../components/NumberInput'
import Select from '../../components/Select'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import { FieldStore, FilterStore, UIStore } from '../../stores'
import { t } from '../../utils'
import Choices from './Choices'

const supportedFieldTypes = [
  'script',
  'category',
  'nested',
  'double',
  'long',
  'date',
  'text',
  'boolean',
]

const ConfigSection = ({
  dependList,
  setDependsOnDetail,
  dependsOnDetail,
  fetchingDependList,
}) => {
  const filter = FilterStore.filterDetail
  const { fields } = FilterStore
  const filtersFields = fields.filter((field) =>
    supportedFieldTypes.includes(field.type)
  )
  const textFields = FieldStore.suggestFields.map((field) => field.id)

  const onChangeSortType = (value) => {
    FilterStore.onChangeFilter(filter, 'sorting', value)
  }

  const onChangeField = (value) => {
    FilterStore.onChangeFilter(filter, 'field', value)
    FilterStore.onChangeFilter(filter, 'type', null)
    FilterStore.onChangeFilter(filter, 'valueSorting', [])
    FilterStore.fetchValues()
    if (
      !textFields.includes(value) &&
      !value?.includes('attributeStr') &&
      filter.sorting === 'manual'
    ) {
      onChangeSortType('count')
    }
  }

  const type = (filtersFields.find((field) => field.id == filter.field) || {})
    .type

  const choosenTypeName = (
    type && filter.type ? Choices[type].find((c) => c.id == filter.type) : {}
  ).name

  return (
    <div className="filter-edit__section">
      <Text size="echo" weight="bold">
        {t('Configuration')}
      </Text>
      <div className="filter-edit__section-row">
        <NumberInput
          value={filter.position}
          label={t('Position')}
          min={0}
          max={999}
          onChange={(numb) =>
            FilterStore.onChangeFilter(filter, 'position', numb)
          }
        />
        <AutoComplete
          title={t('Field')}
          style={{ width: '265px' }}
          fullWidth
          itemKey="label"
          defaultOptions={filtersFields.map((field) => ({
            label: field.title,
            value: field.id,
          }))}
          value={filter.field}
          onSelect={onChangeField}
          error={FilterStore.errors['field']}
        />
        <TextInput
          onChange={(e) =>
            FilterStore.onChangeFilter(filter, 'key', e.target.value)
          }
          value={filter.key}
          label={t('Frontend-ID')}
          error={FilterStore.errors.key}
        />
      </div>
      {type && (
        <Select
          dropdownMatchSelectWidth={false}
          flexible
          value={filter.type}
          options={Choices[type].map((c) => ({
            label: c.name,
            value: c.id,
          }))}
          title={t('Filter type')}
          onChange={(value) =>
            FilterStore.onChangeFilter(filter, 'type', value)
          }
          error={FilterStore.errors['type']}
        />
      )}
      {filter.type &&
        [
          'list_multiselect',
          'list_multiselect_custom_1',
          'list_multiselect_custom_2',
        ].includes(filter.type) &&
        filter.type !== 'script' && (
          <Select
            value={filter.conditionConnector}
            options={[
              { label: 'OR', value: 'OR' },
              { label: 'AND', value: 'AND' },
            ]}
            title={t('Operator for ') + choosenTypeName}
            onChange={(value) =>
              FilterStore.onChangeFilter(filter, 'conditionConnector', value)
            }
            error={FilterStore.errors['conditionConnector']}
          />
        )}

      {filter.type === 'script' && (
        <TextInput
          onChange={(e) =>
            FilterStore.onChangeFilter(filter, 'script', e.target.value)
          }
          value={filter.script}
          label={t('Script')}
          error={FilterStore.errors['script']}
        />
      )}

      <AutoComplete
        flexible
        allowClear
        title={t('Depends upon')}
        style={{ width: '265px' }}
        fullWidth
        itemKey="label"
        defaultOptions={dependList.map((field) => ({
          label: get(
            field,
            ['title', UIStore.enterpriseConfiguration.defaultLanguage],
            t('no title yet')
          ),
          value: field.id,
        }))}
        value={
          get(dependsOnDetail, [
            'title',
            UIStore.enterpriseConfiguration.defaultLanguage,
          ]) || filter.dependsOn
        }
        onSelect={(value) => {
          FilterStore.onChangeFilter(filter, 'dependsOn', value)
          setDependsOnDetail(dependList.find((item) => item.id === value))
        }}
        loading={fetchingDependList}
      />
      <Select
        translateLabel
        dropdownMatchSelectWidth={false}
        flexible
        value={filter.sorting}
        options={[
          { label: 'Frequency', value: 'count' },
          { label: 'Alphabetically ascending', value: 'term' },
          { label: 'Alphabetically descending', value: 'reverse_term' },
          ...(textFields.includes(filter.field) ||
          filter.field?.includes('attributeStr')
            ? [{ label: 'Manual sorting', value: 'manual' }]
            : []),
        ]}
        title={t('Sort options by')}
        onChange={onChangeSortType}
      />
      {filter.sorting === 'manual' && (
        <div>
          <Button
            onClick={FilterStore.openSortModal}
            iconPosition="left"
            icon="sort-amount-down__regular"
          >
            {t('Manual sorting of the values')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default observer(ConfigSection)
