import { observer } from 'mobx-react-lite'
import classes from 'classnames'

import Switch from '../../../components/Switch'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import { t } from '../../../utils'

import { EditorStore } from '../../../stores'

function Header() {
  const { elementToEdit, component, active, setActive } = EditorStore

  const deprecatedComponent = component.deprecated

  return (
    <div
      className={classes('editor-modal-header', {
        'editor-modal-header__active': active,
      })}
    >
      <Switch checked={active} onChange={setActive} />
      {component && (
        <Icon
          symbol={component.icon || '001-pict-heading-text-cta-L'}
          height={34}
          width={76}
          makairaIcon
        />
      )}
      <div className="editor-modal-header__text">
        {elementToEdit.name}
        {deprecatedComponent && (
          <Text
            element="div"
            size="alpha"
            className="editor-modal-header__text-deprecated"
          >
            <span>{t('Deprecated')}</span>
          </Text>
        )}
      </div>
    </div>
  )
}

export default observer(Header)
