import { useLocation } from 'react-router'

const usePathName = () => {
  const location = useLocation()
  const { pathname } = location
  const pathWithoutInstance = '/' + pathname.split('/').slice(2).join('/')

  return pathWithoutInstance
}

export default usePathName
