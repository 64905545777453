import { Tooltip } from 'antd'
import Icon from '../../components/Icon'

import { buildClientURL } from '../../utils'

export default function URLField({ item, row }) {
  if (!item) return null

  if (row.isChild) {
    return (
      <Tooltip
        title={
          <a
            href={buildClientURL(item)}
            target="_blank"
            rel="noreferrer"
            className={'page-editor-list__url-tooltip-field'}
          >
            {row.language.toUpperCase()}: {item}
          </a>
        }
      >
        <span>
          <Icon symbol="globe" width={15} height={15} />
        </span>
      </Tooltip>
    )
  }

  const clickHandler = (e) => {
    e.stopPropagation()
  }

  const title = (
    <div className={'page-editor-list__url-tooltip'} onClick={clickHandler}>
      {row.languageVersions &&
        row.languageVersions.map((languageVersion, index) => (
          <a
            href={buildClientURL(languageVersion.url)}
            target="_blank"
            rel="noreferrer"
            key={index}
            className={'page-editor-list__url-tooltip-field'}
          >
            {languageVersion.language.toUpperCase()}: {languageVersion.url}
          </a>
        ))}
    </div>
  )

  return (
    <Tooltip
      title={title}
      overlayInnerStyle={{ padding: 0 }}
      overlayStyle={{ maxWidth: 'unset' }}
    >
      <span>
        <Icon symbol="globe" width={15} height={15} />
      </span>
    </Tooltip>
  )
}
