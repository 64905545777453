import { useEffect, useState } from 'react'
import { random } from 'lodash'

import SelectTags from '../SelectTags'
import { UIStore } from '../../stores'

import { t } from '../../utils'
import api from '../../api'
import { toast } from 'react-toastify'
import Select from '../Select'

export const DisplayDataType = ({
  onChange = () => {},
  type,
  searchType,
  identifier,
  multiSelectDocs = true,
  setPreviewPageUrl = () => {},
  language,
}) => {
  const { shopId, enterpriseConfiguration } = UIStore

  const [searchOptions, setSearchOptions] = useState([])
  const [searchedDocuments, setSearchedDocuments] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [loadingInitKey, setLoadingInitKey] = useState(0)

  useEffect(() => {
    if (type !== 'search' && identifier && identifier.length > 0)
      onFetchInitListType()
    // eslint-disable-next-line
  }, [identifier])

  // reset search option when change listType
  useEffect(() => {
    setSearchOptions([])
  }, [type])

  const mapSearchOption = (item) => {
    const label = `${
      item.title || item.category_title || item.manufacturer_title
    } (${item.url})`
    const value = item.id
    return { ...item, label, value }
  }

  const onFetchInitListType = async () => {
    const result = await api.common.getDocumentsById({
      type,
      ids: typeof identifier === 'string' ? [identifier] : identifier,
      language: language || enterpriseConfiguration.defaultLanguage,
    })
    const searched = {}
    let mapped = []
    for (const item of result) {
      const itemMapped = mapSearchOption(item)
      searched[item.id] = itemMapped
      mapped.push(itemMapped)
    }
    setSearchOptions(mapped)
    setPreviewPageUrl(result?.[0].url)
    setSearchedDocuments(searched)
    setLoadingInitKey(random(1, 1000)) // Make force Select re-render again
  }
  // datatype, searchPhrase, language, shopId, fields
  const onSearch = async (searchPhrase) => {
    if (searchPhrase.trim() === '') {
      return []
    }
    let searchResults = []
    try {
      setLoading(true)
      const data = await api.common.fetchSearchResultPreview({
        searchPhrase,
        shopId: shopId,
        datatypes: [type],
        fields: [
          'id',
          'ean',
          'title',
          'datatype',
          'picture_url_main',
          'url',
          'title',
          'id',
        ],
        state: 'active',
        showEverything: false,
        language: language || enterpriseConfiguration.defaultLanguage,
      })
      if (data[type].total > 0) {
        searchResults = data[type].items.map((item) => item.fields)
      }
    } catch (error) {
      toast.error('Could not fetch search result.')
    } finally {
      setLoading(false)
      const { searched, mapped } = searchResults.reduce(
        (prev, curr) => {
          const item = mapSearchOption(curr)
          prev.mapped.push(item)
          prev.searched[curr.id] = item
          return prev
        },
        { mapped: [], searched: {} }
      )
      setSearchOptions(mapped)
      setSearchedDocuments({ ...searched, ...searchedDocuments })
    }
  }

  const getSelectedById = (id) => {
    return searchedDocuments[id]
  }

  if (type === 'search') return null

  const titles = {
    category: t('Which categories?'),
    manufacturer: t('Which manufacturer?'),
  }

  const onChangeTagsInput = (value) => {
    // make sure that the Select not remove value when unable to map value
    if (Object.keys(searchedDocuments).length > 0) {
      onChange({
        type,
        searchType,
        identifier: value,
      })
      if (type !== 'search' && Array.isArray(value) && value.length > 0) {
        setPreviewPageUrl(searchedDocuments?.[value[0]]?.url)
      }
    }
  }

  if (multiSelectDocs) {
    return (
      <SelectTags
        label={titles[type]}
        showSearch
        onSearch={onSearch}
        value={identifier}
        getSelectedById={getSelectedById}
        options={searchOptions}
        onChange={onChangeTagsInput}
        className={'display-datatype-selected'}
        key={loadingInitKey}
        additionInfoField="url"
        placeholder={t('Search for ...')}
      />
    )
  } else {
    return (
      <Select
        title={titles[type]}
        loading={isLoading}
        label={titles[type]}
        showSearch
        onSearch={onSearch}
        value={identifier}
        options={searchOptions}
        onChange={onChangeTagsInput}
        className={'display-datatype-selected'}
        placeholder={t('Search for ...')}
        filterOption={false}
        allowClear
        fullWidth
      />
    )
  }
}
