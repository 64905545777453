import classNames from 'classnames'
import InputEditor from '../InputEditor'
import './inputEditorGroup.styl'

const InputEditorGroup = (props) => {
  const parentId = props.id

  return (
    <div
      className={classNames('input-group', {
        'input-group--sub': props.isChildComponent,
      })}
    >
      {props.properties.map((property) => (
        <InputEditor
          key={property.uuid}
          {...property}
          // we create a combined id to be able to access the nested field
          id={`${parentId}.${property.id}`}
          isChildComponent={true}
          showTranslations
          setConditionalValues={props.setConditionalValues}
          conditionalValues={props.conditionalValues}
        />
      ))}
    </div>
  )
}

export default InputEditorGroup
