import HeadingMain from '../../components/Headings/HeadingMain'
import t from '../../utils/translate'
import { observer } from 'mobx-react-lite'
import { FailureIcon, SuccessIcon } from './Icons'
import PersonalizationStore from '../../stores/PersonalizationStore'
import isNumber from 'lodash/isNumber'

const PersonalizationHeadline = () => {
  const getContent = () => {
    if (!PersonalizationStore.status)
      return (
        <>
          <div>
            <FailureIcon large />
          </div>
          <HeadingMain>
            {t('Personalization Checks could not be executed')}
          </HeadingMain>
        </>
      )

    if (!PersonalizationStore.isPersonalizationPossible) {
      return (
        <>
          <div>
            <FailureIcon large />
          </div>
          <HeadingMain>
            {t(
              'Makaira Personalization is not available on stage systems.\nPlease book a live instance to see the setup procedure on this screen.'
            )}
          </HeadingMain>
        </>
      )
    }

    if (
      isNumber(PersonalizationStore.trialDaysLeft) &&
      PersonalizationStore.trialDaysLeft < 0
    ) {
      return (
        <>
          <div>
            <FailureIcon large />
          </div>
          <HeadingMain>{t('Trial period over')}</HeadingMain>
        </>
      )
    }

    if (PersonalizationStore.hasRunSuccessfulBefore) {
      return (
        <>
          <div>
            <FailureIcon large />
          </div>
          <HeadingMain>
            {t(
              'Your Makaira Personalization works, but could perform better! Please check the following points:'
            )}
          </HeadingMain>
        </>
      )
    }

    if (PersonalizationStore.status?.allPassed?.result) {
      return (
        <>
          <div>
            <SuccessIcon large />
          </div>
          <HeadingMain>
            {t(
              'The Machine Learning feature is properly configured. See it in action below…'
            )}
          </HeadingMain>
        </>
      )
    }

    return (
      <HeadingMain>
        {t(
          'In order for the Personalization feature to work make sure that the following checks are fulfilled'
        )}
      </HeadingMain>
    )
  }

  return <div className="personalization__headline">{getContent()}</div>
}

export default observer(PersonalizationHeadline)
