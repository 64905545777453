import { useEffect } from 'react'
import { DatePicker, InputNumber, Select } from 'antd'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker

const dateFormatInternal = 'YYYY-MM-DD'
const dateFormatDisplay = 'YYYY-MM-DD'

export default function DateOperator(props) {
  const { isVisible, config } = props

  if (!isVisible) return null

  if (config.showDays) {
    return <Days {...props} />
  } else {
    return <Dates {...props} />
  }
}

function Days(props) {
  const { name, value, formikProps } = props
  const { setFieldValue } = formikProps

  useEffect(() => {
    // Set or sanitize initial values if necessary.
    const isArray = Array.isArray(value)
    const isDate = moment(new Date(value[0])).isValid()

    if (!isArray || isDate) {
      setFieldValue(name, ['last', 3])
    }
  }, [name, value, setFieldValue])

  function handleChange({ index, v }) {
    let updated = [...value]

    updated[index] = v

    setFieldValue(name, updated)
  }

  return (
    <>
      <Select
        style={{ width: 200 }}
        onChange={(v) => handleChange({ index: 0, v })}
        value={value[0]}
      >
        <Option value="last">last</Option>
        <Option value="next">next</Option>
      </Select>

      <InputNumber
        onChange={(v) => handleChange({ index: 1, v })}
        value={value[1]}
      />
    </>
  )
}

function Dates(props) {
  const { config, name, value, formikProps } = props
  const { setFieldValue } = formikProps

  useEffect(() => {
    // Set or sanitize initial values if necessary.
    const isArray = Array.isArray(value)
    const isDate = value !== undefined && moment(value[0]).isValid()

    if (!isArray || !isDate) {
      const initial = moment(new Date()).format(dateFormatInternal)

      setFieldValue(name, [initial, initial])
    }
  }, [name, value, setFieldValue])

  function handleRangeChange(_, dates) {
    setFieldValue(name, dates)
  }

  function handleDateChange(_, date) {
    let updated = [...value]

    updated[0] = date

    setFieldValue(name, updated)
  }

  if (!value) return null

  if (config.showRange && value) {
    return (
      <RangePicker
        onChange={handleRangeChange}
        value={[moment(value[0]), moment(value[1])]}
      />
    )
  } else {
    return (
      <DatePicker
        onChange={handleDateChange}
        value={moment(value[0])}
        format={dateFormatDisplay}
      />
    )
  }
}
