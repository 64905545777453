import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import ConnectionButton from './ConnectionButton'

import { t } from '../../utils'

import SmartBundleStore from '../../stores/SmartBundleStore'
import AnimationButton from '../../components/AnimationButton'

function ActiveConnections(props) {
  const { id } = props

  const connections = SmartBundleStore.bundleToEdit.slot_connections ?? []
  const selectedSlotsToConnect = SmartBundleStore.selectedSlotsToConnect

  const isCurrentSlotSelected =
    selectedSlotsToConnect.length === 1 &&
    selectedSlotsToConnect[0] === id &&
    SmartBundleStore.connectionButtonsClicked

  const shouldShowPulseAnimation =
    !isCurrentSlotSelected && selectedSlotsToConnect.length === 1

  const isMatchedConnection = (connection) => {
    return connection.first_slot.id === id || connection.second_slot.id === id
  }

  const handleSelectSlot = () => {
    if (isCurrentSlotSelected) {
      SmartBundleStore.removeSlotSelected(id)

      return
    }

    SmartBundleStore.selectSlotToConnect(id)
  }

  const connectionsWithSlot = SmartBundleStore.getUniqueConnections(connections)

  const getButtonVariant = () => {
    if (isCurrentSlotSelected) return 'secondary'

    if (selectedSlotsToConnect.length === 1) {
      return 'primary'
    }

    return 'tertiary'
  }

  const getButtonText = () => {
    if (isCurrentSlotSelected) {
      return t('Connect Slot X with …')(SmartBundleStore.getSlotPosition(props))
    }

    if (!SmartBundleStore.connectionButtonsClicked) {
      return t('Add Condition')
    }
  }

  const buttonClasses = classNames('bundle__add-connection-button', {
    'bundle__add-connection-button--active': isCurrentSlotSelected,
  })

  const position = SmartBundleStore.getSlotPosition(props)

  return (
    <div className="bundle__active-connections">
      {connectionsWithSlot?.map((connection, index) =>
        isMatchedConnection(connection) ? (
          <ConnectionButton
            key={connection.id || index}
            connection={connection}
            id={id}
          />
        ) : (
          <div
            key={connection.id || index}
            className="bundle__active-connection bundle__active-connection--placeholder"
          />
        )
      )}
      <AnimationButton
        level={-1}
        variant={getButtonVariant()}
        className={buttonClasses}
        onClick={handleSelectSlot}
        icon={!SmartBundleStore.connectionButtonsClicked && 'plus'}
        iconPosition="left"
        animationVariant="primary"
        animationChildren={`… ${t('Slot')} ${position}`}
        animationRunningOverride={shouldShowPulseAnimation}
      >
        {getButtonText()}
      </AnimationButton>
    </div>
  )
}

export default observer(ActiveConnections)
