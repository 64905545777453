import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import AutoComplete from '../AutoComplete'

import api from '../../api'

import UIStore from '../../stores/UIStore'

function DocumentSelect(props) {
  const [options, setOptions] = useState([])

  const language = props.language || UIStore.languages[0]

  useEffect(() => {
    async function fetchInitialValues() {
      const { data } = await api.common.getDocumentsByType(props.type)
      setOptions(
        data.map((item) => ({
          ...item,
          id: item.id,
          title: item.title,
        }))
      )
    }
    fetchInitialValues()
  }, [props.type])

  async function handleSearch(query) {
    const { data } = await api.common.getDocumentsByQuery({
      type: props.type,
      query,
      language,
    })
    return formatOptions(data)
  }

  function formatOptions(toBeFormatted) {
    return toBeFormatted.map((document) => ({
      value: String(document.id),
      label: document.title,
    }))
  }

  function onSelect(selectedValue) {
    const selectedDocument = options.find((item) => item.id === selectedValue)
    props.onChange(selectedValue, selectedDocument)
  }

  return (
    <AutoComplete
      {...props}
      placeholder={props.placeholder}
      onSearch={handleSearch}
      onSelect={onSelect}
      defaultOptions={formatOptions(options)}
    />
  )
}

export default observer(DocumentSelect)
