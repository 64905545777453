import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll() {
    return fetch(`/aggregation/fields`)
  },

  async getAggregationByType(datatype = 'product') {
    return fetch(`/stream/fields`, {
      method: 'POST',
      body: JSON.stringify({
        datatype,
      }),
    })
  },

  async getFieldsByType(type) {
    return fetch(`/aggregation/fields?type=${type}`)
  },

  async getWithoutExcluded(excluded = '', noPublicFields = false) {
    return fetch(
      `/aggregation/fields?exclude_type=${excluded}&no_public_field=${
        noPublicFields ? 1 : 0
      }`
    )
  },
}
