import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'

import api from '../../api'
import t from '../../utils/translate'

import AuthStore from '../AuthStore'

const byType = (type) => {
  return (app) => app.type === type
}

class AppStore {
  appInfo = {}
  contentWidgetInfo = {}
  // displayed on the menu and dashboard (type=app)
  externalApps = []
  // only displayed on the page editor (type=content-widget)
  externalContentWidgets = []
  // only displayed on InputEditor
  externalContentModals = []

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetchExternalApps() {
    try {
      const { data } = yield api.apps.getExternalApps()
      this.externalApps = data.filter(byType('app')) ?? []
      this.externalContentWidgets = data.filter(byType('content-widget')) ?? []
      // TODO: update get content-modal apps
      // temporary use 'content-widget' for testing purpose
      this.externalContentModals = data.filter(byType('content-modal')) ?? []
    } catch (error) {
      toast.error('Something went wrong...')
    }
  }

  *fetchExternalAppsUrl(slug) {
    this.appInfo = {}
    try {
      const { data } = yield api.apps.getExternalAppsUrl(slug)
      this.appInfo = data
      return data
    } catch (error) {
      toast.error(t('No application with the given slug exists.'))
      return null
    }
  }

  /**
   *
   * @param slug {string}
   * @param credentials {{hmac: string, makairaHmac: string, nonce: string}}
   * @returns {Promise<boolean> | boolean}
   */
  *validateCredentialsRequest(slug, credentials) {
    try {
      const { valid } = yield api.apps.checkValidApp(slug, credentials)
      return valid
    } catch (error) {
      toast.error(t('An error occurred whilst validating app credentials.'))
      return false
    }
  }

  /**
   *
   * @param isValid {boolean}
   * @param event {MessageEvent}
   * @param isContentWidget {boolean}
   */
  sendValidationAnswerMessage(isValid = false, event, isContentWidget = false) {
    const source = isContentWidget
      ? 'makaira-content-widget-bridge'
      : 'makaira-app-bridge'

    if (isValid) {
      event.source.postMessage(
        {
          source,
          action: 'responseUserRequest',
          data: {
            token: AuthStore.token,
          },
        },
        event.origin
      )
    } else {
      event.source.postMessage(
        {
          source,
          action: 'responseUserRequestError',
          message: 'Invalid credentials',
        },
        event.origin
      )
    }
  }
}

export default new AppStore()
