import classNames from 'classnames'
import { useState, useEffect, useRef } from 'react'
import './shadowScroll.styl'

const DIRECTIONS = {
  vertical: {
    scrollSize: 'scrollHeight',
    clientSize: 'clientHeight',
    scrollTop: 'scrollTop',
    offsetSize: 'offsetHeight',
  },
  horizontal: {
    scrollSize: 'scrollWidth',
    clientSize: 'clientWidth',
    scrollTop: 'scrollLeft',
    offsetSize: 'offsetWidth',
  },
}

const ShadowScroll = ({
  children,
  className,
  direction = 'vertical',
  maxWidth,
  maxHeight,
  ...rest
}) => {
  const contentRef = useRef()
  const dir = DIRECTIONS[direction]

  const [contentHasScroll, setContentHasScroll] = useState(false)
  const [contentScrollPosition, setContentScrollPosition] = useState('top') // top, middle, bottom

  const handleScroll = (e, element) => {
    if (!element) element = e.target

    const hasScrollbar =
      element && element[dir.scrollSize] > element[dir.clientSize]

    setContentHasScroll(hasScrollbar)

    if (element[dir.scrollTop] === 0) {
      setContentScrollPosition('top')

      return
    }

    const bottomReached =
      element[dir.scrollTop] + 5 >=
      element[dir.scrollSize] - element[dir.offsetSize]

    if (bottomReached) {
      setContentScrollPosition('bottom')

      return
    }

    setContentScrollPosition('middle')
  }

  useEffect(() => {
    let content

    content = contentRef.current

    if (!content) return

    handleScroll(null, content)

    content.addEventListener('scroll', handleScroll)

    return () => {
      content && content.removeEventListener('scroll', handleScroll)
      setContentHasScroll(false)
      setContentScrollPosition('top')
    }
    // eslint-disable-next-line
  }, [children])

  const classes = {
    'shadow-scroll--has-scroll': contentHasScroll,
    'shadow-scroll--top': contentHasScroll && contentScrollPosition === 'top',
    'shadow-scroll--bottom':
      contentHasScroll && contentScrollPosition === 'bottom',
  }

  return (
    <div
      style={{ maxWidth, maxHeight }}
      className={classNames(
        'shadow-scroll',
        `shadow-scroll--${direction}`,
        classes
      )}
      {...rest}
    >
      <div className="shadow-scroll__top" />
      <div
        className={classNames(className, 'shadow-scroll__body')}
        ref={contentRef}
      >
        {children}
      </div>
      <div className="shadow-scroll__bottom" />
    </div>
  )
}

export default ShadowScroll
