import { routes } from '../../routing'

export const getRedirectPath = (type, recommendationId) => {
  switch (type) {
    case 'personalization':
      return routes.personalization.path
    case 'frontend':
      return routes.abFrontend.path.replace(':case', 'frontend')
    case 'ranking_mix':
      return routes.rankingMix.path
    case 'recommendation':
      return recommendationId
        ? routes.recommendationDetail.path.replace(':id', recommendationId)
        : routes.recommendations.path
    default:
      return ''
  }
}
