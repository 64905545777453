import { makeAutoObservable } from 'mobx'

class ActionLayerStore {
  open = false
  render = null
  header = null
  content = null
  isLoading = false
  error = null
  saveTitle = ''
  closeTitle = ''
  saveWithContinue = ''
  state = '' // "pending", "done" or "error"
  height = null
  onSave = () => {}
  onClose = () => {}
  onSaveWithContinue = () => {}

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  openActionLayer = ({
    render,
    header,
    content,
    onSave,
    onClose,
    closeTitle,
    saveTitle,
    saveWithContinue,
    error,
    onSaveWithContinue,
  }) => {
    this.open = true
    this.render = render
    this.header = header
    this.content = content
    this.saveTitle = saveTitle
    this.closeTitle = closeTitle
    this.saveWithContinue = saveWithContinue
    this.onSave = onSave
    this.onClose = onClose
    this.onSaveWithContinue = onSaveWithContinue
    this.error = error
    this.state = ''
  };

  *handleSave() {
    try {
      this.setState('pending')
      let closeActionLayer = true

      if (this.onSave) {
        closeActionLayer = yield this.onSave()
      }
      this.setState('done')

      if (closeActionLayer !== false) {
        this.closeActionLayer()
      }
    } catch (error) {
      this.setState('error')
      console.error(error)
    }
  }

  *handleSaveWithContinue() {
    try {
      this.setState('pending')
      let closeActionLayer = true

      if (this.onSaveWithContinue) {
        closeActionLayer = yield this.onSaveWithContinue()
      }
      this.setState('done')

      if (closeActionLayer !== false) {
        this.closeActionLayer()
      }
    } catch (error) {
      this.setState('error')
      console.error(error)
    }
  }

  *handleClose() {
    try {
      this.onClose && (yield this.onClose())
      this.closeActionLayer()
    } catch (error) {
      console.error(error)
    }
  }

  setHeight(height) {
    this.height = height
  }

  setState(value) {
    this.state = value
  }

  closeActionLayer() {
    this.open = false
  }

  sendExternalAppMessage(source, event, message) {
    event.source.postMessage(
      {
        source,
        action: 'responseActionLayer',
        data: {
          message,
        },
      },
      event.origin
    )
  }
}

export default new ActionLayerStore()
