import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'

import ResourceTable from '../../components/ResourceTable'
import { Column } from '../../components/Table'

import AuditStore from '../../stores/AuditStore'

import t from '../../utils/translate'
import createRedirectPath from '../../utils/createRedirectPath'

function AuditLogList() {
  const history = useHistory()

  useEffect(() => {
    AuditStore.fetchAudit()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="content-wrapper">
      <ResourceTable
        title={t('Audit Logs')}
        store={AuditStore}
        resourceName="auditLogs"
        loading={AuditStore.state === 'pending'}
        onRowClick={(record) =>
          history.push(createRedirectPath(`/audit/${record.id}`))
        }
        pagination={null}
      >
        <Column
          title={t('Title')}
          dataIndex="title"
          className="mk-table-cell--bold"
        />
        <Column
          title={t('Logs Count')}
          dataIndex="count"
          className="mk-table-cell--bold"
        />
      </ResourceTable>
    </div>
  )
}

export default observer(AuditLogList)
