import cs from 'classnames'
import React from 'react'
import { observer } from 'mobx-react-lite'
import Icon from '../../../components/Icon'
import { ImporterStore } from '../../../stores'

const StatusColumn = ({ status, state, language }) => {
  const { getScheduleByLanguage, getRunningStatus } = ImporterStore
  const isActive = state === 'active'
  const isPassive = state === 'passive'

  const continuously = getScheduleByLanguage({
    lang: language,
    type: 'continuously',
  })

  const runningStatus = getRunningStatus(language)
  const isError =
    continuously?.current?.status === 'Running' &&
    continuously?.current?.containerStatus === 'CrashLoopBackOff'

  if (isError) {
    return (
      <div className="mk-table-cell__icon mk-table-cell__icon--pink">
        <Icon height={12} symbol="bolt-lightning" />
      </div>
    )
  }

  if (isActive && runningStatus?.current?.status === 'Running') {
    return (
      <div className="mk-table-cell__icon mk-table-cell__icon--green">
        <Icon height={12} width={12} symbol="sync" />
      </div>
    )
  }

  if (isPassive && runningStatus?.current?.status === 'Running') {
    return (
      <div className="mk-table-cell__icon mk-table-cell__icon--red">
        <Icon height={12} width={12} symbol="person-digging" />
      </div>
    )
  }

  if (
    isPassive &&
    (runningStatus?.current?.status === 'Pending' ||
      runningStatus?.current?.status === 'Terminating' ||
      status === 'yellow')
  ) {
    return (
      <div className="mk-table-cell__icon mk-table-cell__icon--yellow">
        <Icon height={12} width={12} symbol="sync" />
      </div>
    )
  }

  if (isActive && continuously?.current?.status === 'Running') {
    return (
      <div className="mk-table-cell__icon mk-table-cell__icon--green">
        <Icon height={12} width={12} symbol="sync" />
      </div>
    )
  }

  if (isPassive) {
    return (
      <div className="mk-table-cell__icon">
        <Icon height={12} width={12} symbol="box-archive" />
      </div>
    )
  }

  return (
    <span
      className={cs({
        'mk-table-cell__icon': true,
        'mk-table-cell__icon--green': status === 'green',
        'mk-table-cell__icon--yellow': status === 'yellow',
      })}
    />
  )
}

export default observer(StatusColumn)
