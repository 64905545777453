export default function openGallery(onChange, setGalleryLoading) {
  document.addEventListener('cloudinary-inserted', (e) => onChange(e.detail), {
    once: true,
  })

  document.addEventListener(
    'cloudinary-hided',
    () => setGalleryLoading(false),
    {
      once: true,
    }
  )

  window.ml.show()
}
