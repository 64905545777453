import { observer } from 'mobx-react-lite'
import Switch from '../../../../components/Switch'
import { ComponentStore } from '../../../../stores'
import FormSection from './FormSection'
import { t } from '../../../../utils'
import Select from '../../../../components/Select'
import NumberInput from '../../../../components/NumberInput'
import Icon from '../../../../components/Icon'

function ConditionalSectionSettings({ settings = {} }) {
  const { type, uuid, conditionField, conditionValue, showFields } = settings

  const targetField = ComponentStore.getFieldByUUID(conditionField)

  const conditionalFields = ComponentStore.flattenFields.filter(
    (field) =>
      field.uuid !== uuid &&
      ['checkbox', 'select', 'number'].includes(field.type) &&
      field.parentId !== uuid
  )

  const onChangeProperties = (properties) => {
    for (let key of Object.keys(properties)) {
      ComponentStore.updateField(
        ComponentStore.activeFieldId,
        key,
        properties[key]
      )
    }
  }

  const getDefaultValue = (elementType) => {
    if (elementType === 'checkbox') {
      return false
    }

    if (elementType === 'number') {
      return 0
    }

    return undefined
  }

  if (type !== 'conditional-section') return null

  return (
    <>
      <FormSection title={t('If')}>
        <div className="select-input__label">
          {t(
            "Select a 'Select', 'Checkbox or 'Number' element to be the condition field"
          )}
        </div>
        <div className="component-editor__row component-editor__row--middle">
          <Icon
            style={{ color: 'var(--neutral-70)' }}
            symbol="circle__solid"
            width="20px"
            height="20px"
          />
          <Select
            options={conditionalFields.map((option) => ({
              value: option.uuid,
              label: option.label,
            }))}
            onChange={(value) =>
              onChangeProperties({
                conditionField: value,
                conditionValue: getDefaultValue(
                  conditionalFields.find((f) => f.uuid === value)?.type
                ),
              })
            }
            value={conditionField}
          />
        </div>
      </FormSection>
      <FormSection title={t('Condition')}>
        <div className="component-editor__row">
          <Select
            options={[
              {
                value: 'equal',
                label: 'equal',
              },
            ]}
            flexible
            value={'equal'}
          />
          {targetField?.type === 'number' && (
            <NumberInput
              value={conditionValue}
              onChange={(value) =>
                onChangeProperties({ conditionValue: value })
              }
            />
          )}
          {targetField?.type === 'select' && (
            <Select
              value={conditionValue}
              options={targetField?.options || []}
              flexible
              onChange={(value) =>
                onChangeProperties({ conditionValue: value })
              }
            />
          )}
          {targetField?.type === 'checkbox' && (
            <Select
              value={conditionValue ? '1' : '0'}
              flexible
              onChange={(value) =>
                onChangeProperties({ conditionValue: value === '1' })
              }
              options={[
                {
                  value: '1',
                  label: 'checked',
                },
                {
                  value: '0',
                  label: 'unchecked',
                },
              ]}
            />
          )}
        </div>
      </FormSection>
      <FormSection title={t('Then')}>
        <Switch
          title={t('Show this section')}
          type="horizontal"
          onChange={(value) => onChangeProperties({ showFields: value })}
          checked={showFields}
        />
      </FormSection>
    </>
  )
}

export default observer(ConditionalSectionSettings)
