import { observer } from 'mobx-react-lite'
import Icon from '../../Icon'
import Select from '../../Select'
import { FilterSectionStore } from '../../../stores'
import { t } from '../../../utils'
import { getOperator } from '../utils'

function ComparisonWithField() {
  const { editingFilter, setEditingFilter } = FilterSectionStore

  const OPTIONS = [
    { value: 'staticValue', label: t('Static Value') },
    { value: 'inputProduct', label: t('Input product') },
  ]

  const { comparison } = getOperator({
    type: editingFilter.type,
    operator: editingFilter.operator,
  })

  const invalidField = ['category.catid', 'manufacturerid'].includes(
    editingFilter.field
  )

  if (
    !editingFilter.field ||
    !editingFilter.type ||
    !editingFilter.operator ||
    !comparison ||
    invalidField
  ) {
    return null
  }

  const filteredOptions = OPTIONS.filter(
    (item) =>
      !(
        item.value === 'inputProduct' && editingFilter.operator === 'between'
      ) &&
      !(
        item.value === 'staticValue' && editingFilter.operator === 'sameYear'
      ) &&
      !(
        item.value === 'inputProduct' &&
        editingFilter.type === 'date' &&
        editingFilter.operator === 'within'
      )
  )

  const onChangeCompareWithOptions = (compareWith) => {
    let value = null

    const operator = getOperator({
      type: editingFilter.type,
      operator: editingFilter.operator,
    })

    if (compareWith === 'staticValue' && operator.comparison === 'number') {
      value = 0
    }
    if (
      compareWith === 'staticValue' &&
      operator.comparison === 'rangeNumber'
    ) {
      value = [0, 0]
    }

    setEditingFilter({
      ...editingFilter,
      compareWith,
      value,
    })
  }

  return (
    <>
      <Icon width="20px" height="20px" symbol="arrow-down" />
      <Select
        style={{ minWidth: '11.5rem' }}
        flexible
        dropdownMatchSelectWidth={false}
        options={filteredOptions}
        value={editingFilter.compareWith}
        onChange={onChangeCompareWithOptions}
      />
    </>
  )
}

export default observer(ComparisonWithField)
