import { Tooltip } from 'antd'
import classNames from 'classnames'
import { Fragment } from 'react'

import Icon from '../Icon'
import Spinner from '../Spinner'

import './button.styl'

export default function Button(props) {
  const {
    variant = 'secondary',
    className = '',
    icon = '',
    iconPosition = 'right',
    loading,
    children,
    disabled,
    level = 0,
    onClick = () => {},
    tooltip = '',
    ...rest
  } = props

  const classes = classNames(className, 'button', `button--level-${level}`, {
    [`button--${variant}`]: variant,
    [`button--icon`]: icon,
    [`button--icon-${iconPosition}`]: icon && iconPosition && children,
    [`button--loading`]: loading,
    [`button--icon-only`]: !children && icon,
  })

  // Separate check to properly render disabled link-buttons
  if (disabled) {
    return (
      <button className={classes} type="button" disabled={disabled || loading}>
        {loading && <Spinner size="small" />}

        <span className="button__text">{children}</span>

        {icon && <Icon symbol={icon} />}
      </button>
    )
  }

  let Wrapper = Fragment
  const WrapperProps = {}

  if (tooltip) {
    Wrapper = Tooltip
    WrapperProps.title = tooltip
  }

  return (
    <Wrapper {...WrapperProps}>
      <button
        className={classes}
        type="button"
        disabled={disabled || loading}
        onClick={onClick}
        {...rest}
      >
        {loading && <Spinner size="small" />}

        {children && <span className="button__text">{children}</span>}

        {icon && <Icon symbol={icon} />}
      </button>
    </Wrapper>
  )
}
