import { useRef } from 'react'

export default function Spot(props) {
  const { index, top, left, onMouseDown } = props
  const spotRef = useRef()

  return (
    <span
      ref={spotRef}
      className="hotspot__preview--spot"
      style={{
        top: `${top}%`,
        left: `${left}%`,
      }}
      onMouseDown={onMouseDown}
      draggable
    >
      {index}
    </span>
  )
}
