import { useState } from 'react'
import ReactJson from 'react-json-view'
import t from '../../utils/translate'
import Modal from '../../components/Modal'
import Button from '../../components/Button'

export default function ItemPreview(props) {
  const { isVisible, item } = props
  const [collapsed, setCollapsed] = useState(1)

  function toggleCollapsed() {
    if (collapsed == 1) {
      setCollapsed(false)
    } else {
      setCollapsed(1)
    }
  }

  return (
    <Modal
      visible={isVisible}
      title={`Document ${item.id}`}
      footer={[
        <Button key="expand" onClick={toggleCollapsed}>
          {collapsed == 1 ? t('Expand all') : t('Collapse all')}
        </Button>,
        <Button key="close" onClick={() => props.setVisible(false)}>
          {t('Close')}
        </Button>,
      ]}
      onClose={() => props.setVisible(false)}
      width={750}
      centered={true}
      bodyStyle={{ maxHeight: 'calc(100vh - 200px)' }}
    >
      <ReactJson
        src={item}
        name={null}
        theme={'monokai'}
        iconStyle={'circle'}
        sortKeys={true}
        collectionLimit={200}
        collapsed={collapsed}
        collapseStringsAfterLength={125}
        indentWidth={4}
        onEdit={false}
        onDelete={false}
        onAdd={false}
      />
    </Modal>
  )
}
