import { forwardRef } from 'react'
import classnames from 'classnames'
import { Tooltip } from 'antd'

import Icon from '../Icon'
import Spinner from '../Spinner'

import './textInput.styl'
import Text from '../Text'
import isObject from 'lodash/isObject'

const TextInput = forwardRef(
  (
    {
      name,
      label,
      value,
      defaultValue,
      disabled,
      description,
      rounded,
      icon,
      error,
      placeholder,
      style,
      className,
      normalWeight = false,
      loading,
      onChange = () => {},
      onWhiteGround = false,
      onBlur = () => {},
      autoFocus = false,
      type = 'text',
      prefixText = '',
      width,
      autoAdjustWidth,
      actionBtn,
    },
    ref
  ) => {
    return (
      <div
        style={{
          ...style,
          '--value-length': value?.length ? value.length + 'ch' : undefined,
        }}
        className={classnames('text-input', className, {
          'text-input--auto-width': autoAdjustWidth,
          'text-input--with-action': actionBtn,
        })}
      >
        {label && (
          <label
            className={classnames('text-input__label', {
              'text-input__label--error': error,
              'text-input__label--normal-weight': normalWeight,
              'text-input__label--white-ground': onWhiteGround,
            })}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        <div
          className={classnames('text-input__input-wrapper', {
            'text-input__input-wrapper--disabled': disabled,
            'text-input__input-wrapper--error': error,
            'text-input__input-wrapper--rounded': rounded,
            'text-input__input-wrapper--icon': icon && !loading,
            'text-input__input-wrapper--loading-possible':
              loading !== undefined,
          })}
        >
          {prefixText !== '' && (
            <Text className="text-input__prefix-text">{prefixText}</Text>
          )}
          <input
            style={{ width }}
            className="text-input__input"
            type={type}
            name={name}
            id={name}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            onBlur={onBlur}
            autoFocus={autoFocus}
            data-cy={`input-${name}`}
            ref={ref}
          />
          {loading && <Spinner size="small" />}
          {icon && !isObject(icon) && !loading && <Icon symbol={icon} />}
          {icon && isObject(icon) && !loading && (
            <Tooltip title={icon.tooltip} align={{ offset: [0, 8] }}>
              <Icon
                className={classnames({ 'icon--clickable': !!icon.onClick })}
                symbol={icon.symbol}
                onClick={icon.onClick}
              />
            </Tooltip>
          )}
          {actionBtn}
        </div>
        {description && (
          <div className="text-input__message">{description}</div>
        )}
        {error && (
          <div className="text-input__message text-input__message--error">
            {error}
          </div>
        )}
      </div>
    )
  }
)

export default TextInput
