import { observer } from 'mobx-react-lite'
import React from 'react'
import Switch from '../../../components/Switch'
import Text from '../../../components/Text'
import RankingMixStore from '../../../stores/RankingMixStore'
import { t } from '../../../utils'
import RankingMixField from './RankingMixField'

const ACCEPTABLE_FIELDS = [
  'basket',
  'new',
  'profit_margin',
  'rating',
  'revenue',
  'sold',
  'view',
]

const ConfigSection = ({ config = {}, disabled }) => {
  const { type, id, out_stock_at_the_end, active, ...rest } = config

  return (
    <div className="ranking-mix__config-section-item">
      <Switch
        onChange={(checked) =>
          RankingMixStore.updateConfigData(config, 'active', checked)
        }
        checked={active}
        type="horizontal"
        title={
          <Text weight="bold">
            {t(
              `Use Rankingmix for ${
                type === 'search' ? 'search results' : 'categories'
              }`
            )}
          </Text>
        }
        disabled={disabled}
      />

      {Object.keys(rest).map(
        (title) =>
          ACCEPTABLE_FIELDS.includes(title) && (
            <RankingMixField
              key={title}
              title={title}
              value={rest[title]}
              config={config}
              type={type}
              disabled={disabled}
            />
          )
      )}

      <Switch
        disabled={disabled}
        onChange={(checked) =>
          RankingMixStore.updateConfigData(
            config,
            'out_stock_at_the_end',
            checked
          )
        }
        checked={out_stock_at_the_end}
        type="horizontal"
        title={
          <Text weight="bold">
            {t('Show out of stock items at the end of the list')}
          </Text>
        }
      />
    </div>
  )
}

export default observer(ConfigSection)
