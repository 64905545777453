import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import cx from 'classnames'
import { useHistory, useParams } from 'react-router'
import { toJS } from 'mobx'
import { toast } from 'react-toastify'

import PageTitle from '../../components/PageTitle'
import LoadingScreen from '../../components/LoadingScreen'
import Switch from '../../components/Switch'
import Text from '../../components/Text'
import TimeControlModal from '../../components/Modal/TimeControlModal'
import TextInput from '../../components/TextInput'
import Icon from '../../components/Icon'
import TrackingSection from '../../components/TrackingDetail'
import DisplaySection from '../../components/DisplaySection'
import PromoteDocuments from '../../components/PromoteDocuments'

import { createRedirectPath, t, useLeaveConfirm } from '../../utils'
import { formatDateWithoutSeconds } from '../../utils/formatDate'

import {
  ActionLayerStore,
  EditorStore,
  PlacementStore,
  UIStore,
} from '../../stores'
import { routes } from '../../routing'
import api from '../../api'

function AdvertisingSpacesForm() {
  const { id } = useParams()
  const { currentLanguage } = UIStore
  const {
    state,
    detail,
    isDirty,
    setDirty,
    onChangeDetail,
    setPreviewPageUrl,
    updateOrCreate,
  } = PlacementStore
  const history = useHistory()
  const [showTimeControlModal, setShowTimeControlModal] = useState(false)

  useEffect(() => {
    const init = async () => {
      await EditorStore.fetchPublishConfig()
      await PlacementStore.getById(id)
      if (PlacementStore.detail?.items?.length > 0) {
        const items = toJS(PlacementStore.detail.items)
        items.forEach(async (item, index) => {
          if (item.documentType !== 'product') return
          try {
            const data = await api.common.fetchDocumentPreview({
              esId: item.documentId,
              state: 'active',
              language: UIStore.enterpriseConfiguration.defaultLanguage,
            })
            items[index] = { ...item, picture_url_main: data.picture_url_main }
            onChangeDetail(
              {
                items,
              },
              false
            )
          } catch (error) {
            toast.error('Something went wrong fetching documents')
          }
        })
      }
    }

    init()
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        saveTitle: t('Save & back to list'),
        closeTitle: t('Abort & back to list'),
        onSaveWithContinue: handleSaveAndStay,
        onSave: handleSave,
        onClose: handleClose,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  const prompt = useLeaveConfirm(isDirty)

  if (state === 'pending') return <LoadingScreen />

  async function handleSaveAndStay() {
    const result = await updateOrCreate(detail)
    if (result && result.id) {
      history.replace(createRedirectPath(`/advertising-space/${result.id}/`))
    }
  }

  function handleClose() {
    setDirty(false)
    setShowTimeControlModal(false)
    history.push(createRedirectPath(routes.advertisingSpaces.path))
  }

  async function handleSave() {
    setDirty(false)
    const result = await updateOrCreate(detail)
    if (result) {
      history.push(createRedirectPath(routes.advertisingSpaces.path))
    }
  }

  const getText = () => {
    if (
      !detail.activeTiming &&
      (!detail.personaIds || detail.personaIds?.length === 0) &&
      !detail.allPersona
    )
      return t('time-based or persona based visibility can be changed')
    const formattedFrom = detail.activeFrom
      ? `${t('from')} ${formatDateWithoutSeconds({
          dateString: detail.activeFrom,
        })}`
      : ''

    const formattedTo = detail.activeTo
      ? `${t('to')} ${formatDateWithoutSeconds({
          dateString: detail.activeTo,
        })}`
      : ''

    return (
      <div>
        {detail.allPersona && <div>{t('Visible for All Personas')}</div>}
        {detail.personaIds?.length > 0 &&
          detail.personaIds.map((id) => {
            const group =
              EditorStore.publishConfig.find((persona) => persona.id === id) ||
              {}
            return (
              <div key={group.identifier}>
                {t('Visible for')}{' '}
                {group.name?.[UIStore.currentLanguage] || group.name}
              </div>
            )
          })}
        {detail.activeTiming && (
          <span>{`${t('Scheduled')} ${formattedFrom} ${formattedTo}`}</span>
        )}
      </div>
    )
  }

  return (
    <>
      <PageTitle
        prefix={toJS(detail).id ? t('You are editing') : t('You are creating')}
        metaInfo={
          detail.id && {
            timestamp: detail.changed?.date,
            timezone: detail.changed?.timezone,
            user: detail.user,
            id: detail.id,
          }
        }
        hiddenFields={['internal-title']}
      >
        {detail.id ? detail.name : t('New Placement')}
      </PageTitle>
      <div className="content-wrapper banner-edit">
        <div className="banner-content">
          <div className="banner-edit__section">
            <div className="section__row">
              <Switch
                checked={detail.active || false}
                type="horizontal"
                title={t('Active')}
                onChange={(value) => onChangeDetail({ active: value })}
              />
              <Text
                className={cx('banner-timecontrol--cta', {
                  active: detail.activeTiming,
                })}
                variant="book"
                element="p"
                onClick={() => setShowTimeControlModal(!showTimeControlModal)}
              >
                <Icon symbol="clock" />
                {getText()}
              </Text>
              <TimeControlModal
                visible={showTimeControlModal}
                from={detail.activeFrom}
                to={detail.activeTo}
                onClose={() => setShowTimeControlModal(false)}
                onChange={({ active, from, to, allPersona, personaIds }) => {
                  onChangeDetail({
                    activeFrom: from,
                    activeTo: to,
                    activeTiming: active,
                    allPersona,
                    personaIds,
                  })
                }}
                language={currentLanguage}
                allPersona={detail.allPersona}
                personaIds={detail.personaIds || []}
              />
            </div>
            <TextInput
              label={t('Name')}
              value={detail.name}
              onChange={(e) => onChangeDetail({ name: e.target.value })}
            />
          </div>
          <div className="devider" />
          <DisplaySection
            type={detail.listType}
            identifier={detail.identifier}
            searchType={detail.searchAutosuggestBoth}
            hideSearchType
            setPreviewPageUrl={setPreviewPageUrl}
            onChange={({ type, identifier }) => {
              onChangeDetail({
                listType: type,
                identifier: identifier,
              })
            }}
          />
          <div className="devider" />
          <PromoteDocuments
            sortType={detail.enableSorting ? 'auto' : 'manual'}
            activeType={
              detail.listType === 'search'
                ? ['product', 'category', 'manufacturer']
                : ['product']
            }
            items={toJS(detail?.items)}
            onChange={(items, sortType) => {
              onChangeDetail({
                enableSorting: sortType === 'auto',
                items,
              })
            }}
          />
          <TrackingSection
            clickTrackingId={detail.clickTrackingId}
            viewTrackingId={detail.viewTrackingId}
          />
        </div>
      </div>
      {prompt}
    </>
  )
}

export default observer(AdvertisingSpacesForm)
