import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async get() {
    return fetch('/menu')
  },

  async update(menu) {
    return fetch('/menu', {
      method: 'PUT',
      body: JSON.stringify(menu),
    })
  },

  async getMetadata({ esId, type }) {
    const url = `/menu/metadata/${esId}`
    const body = { type }

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })
  },
}
