import { makeAutoObservable, toJS } from 'mobx'

import api from '../../api'
import { UIStore, EditorStore } from '..'
import appendProtocolToURL from './utils/appendProtocolToURL'
import { cloneDeep, get, set } from 'lodash'

const LOCALSTORAGE_KEY = 'page_editor_preview'
const LOCALSTORAGE_URL_KEY = 'page_editor_preview_url'

const DEFAULT_SIZE = 414

class PageEditorPreviewStore {
  previewShown = true
  selectedSize = DEFAULT_SIZE
  previousSize = DEFAULT_SIZE
  previewLoading = false
  customPreviewTargetURL = null
  reloadIframe = false
  previewPersona = 'All'
  previewTime = ''

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })

    this.loadConfigurationFromLocal()
  }

  hidePreview() {
    this.previewShown = false
    this.previousSize = this.selectedSize
    this.selectedSize = 0

    this.saveConfigurationToLocal()
  }

  showPreview() {
    this.loadConfigurationFromLocal()

    this.previewShown = true
    this.selectedSize = this.previousSize

    this.setReloadIframe(true)
  }

  setSelectedSize(size) {
    this.selectedSize = size

    this.saveConfigurationToLocal()
  }

  saveConfigurationToLocal() {
    const locallyStoredConfig = JSON.stringify({
      shown: this.previewShown,
      size: this.selectedSize,
      previousSize: this.previousSize,
    })

    localStorage.setItem(LOCALSTORAGE_KEY, locallyStoredConfig)
  }

  loadConfigurationFromLocal() {
    let locallyStoredConfig = localStorage.getItem(LOCALSTORAGE_KEY)
    locallyStoredConfig = JSON.parse(locallyStoredConfig) ?? {}

    this.previousSize = locallyStoredConfig.previousSize ?? DEFAULT_SIZE
    this.selectedSize = locallyStoredConfig.size ?? DEFAULT_SIZE
    this.previewShown = locallyStoredConfig.shown ?? true

    this.customPreviewTargetURL =
      localStorage.getItem(LOCALSTORAGE_URL_KEY) ?? null
  }

  checkIsTimedValid(element) {
    const persona = get(
      element,
      [
        'properties',
        EditorStore.contentLanguage,
        'personas',
        this.previewPersona,
      ],
      {}
    )

    if (persona.active && persona.isTimed) {
      let activeFrom = get(persona, 'activeFrom')
      let activeTo = get(persona, 'activeTo')
      const validPreviewTime = this.previewTime?.isBetween(activeFrom, activeTo)
      persona.active = validPreviewTime
      persona.isTimed = !validPreviewTime
    }

    return element
  }

  updateElementsByPreviewTime(config) {
    if (!this.previewTime) return config

    config = cloneDeep(toJS(config))

    const topElements = get(config, 'config.top.elements', []).map(
      this.checkIsTimedValid
    )
    const bottomElements = get(config, 'config.bottom.elements', []).map(
      this.checkIsTimedValid
    )

    set(config, 'config.top.elements', topElements)
    set(config, 'config.bottom.elements', bottomElements)
    return config
  }

  /**
   * Will reload the preview. Fetches the page data from the API and
   * sends the page payload to the connected storefront.
   */
  *updatePreview() {
    if (this.previewLoading) return

    // popup only get data from opener not itself
    if (UIStore.isStorefrontPreview) return

    this.previewLoading = true

    try {
      let data = yield api.pages.getPreview(
        this.updateElementsByPreviewTime(EditorStore.pageToEdit),
        EditorStore.contentLanguage,
        this.previewPersona
      )

      window.postMessage(
        {
          source: 'makaira-bridge',
          action: 'update',
          // see: https://stackoverflow.com/questions/42376464/uncaught-domexception-failed-to-execute-postmessage-on-window-an-object-co
          config: JSON.parse(JSON.stringify(data)),
        },
        '*'
      )
    } catch (error) {
      console.log(error)
    }

    this.previewLoading = false
  }

  setCustomPreviewTargetURL(url) {
    this.customPreviewTargetURL = url
    if (url) {
      localStorage.setItem(LOCALSTORAGE_URL_KEY, url)
    } else {
      localStorage.removeItem(LOCALSTORAGE_URL_KEY)
    }
  }

  handleURLPreview(url = '') {
    const urlWithProtocol = appendProtocolToURL(url)

    try {
      new URL(urlWithProtocol)

      return urlWithProtocol
    } catch (e) {
      return ''
    }
  }

  setReloadIframe(isReload) {
    this.reloadIframe = isReload
  }

  setPreviewLoading(isLoading) {
    this.previewLoading = isLoading
  }

  setPreviewPersona(personaIdentifier) {
    this.previewPersona = personaIdentifier

    this.updatePreview()
  }

  setPreviewTime(time) {
    this.previewTime = time

    this.updatePreview()
  }
}

export default new PageEditorPreviewStore()
