import { useState } from 'react'
import { observer } from 'mobx-react-lite'

import ContentSlotEdit from './ContentSlotEdit'

import Text from '../../../components/Text'
import Button from '../../../components/Button'
import Collapse, { Panel } from '../../../components/Collapse'

import t from '../../../utils/translate'

import UIStore from '../../../stores/UIStore'

import './contentEditor.styl'
import SmartBundleStore from '../../../stores/SmartBundleStore'

const ContentEditor = () => {
  const [activeKey, setActiveKey] = useState(
    UIStore.languages?.length > 0 ? [UIStore.languages[0]] : []
  )

  const slotData = SmartBundleStore.getSelectedSlot()
  const slotPosition = SmartBundleStore.getSlotPosition(slotData)
  const areAllPanelsExpanded = activeKey.length === UIStore.languages.length

  const handleAllButtonClick = () => {
    if (areAllPanelsExpanded) {
      setActiveKey([])
    } else {
      setActiveKey(UIStore.languages)
    }
  }

  return (
    <div className="content-edit">
      <div className="content-edit__header">
        <Text size="delta" element="span" weight="bold">
          {t('Settings for Slot')} {slotPosition}
        </Text>

        {UIStore.languages?.length > 1 && (
          <Button
            onClick={handleAllButtonClick}
            className="content-edit__expand-button"
            variant="tertiary"
            level={-1}
            icon={areAllPanelsExpanded ? 'chevron-up' : 'chevron-down'}
          >
            {t(areAllPanelsExpanded ? 'Collapse all' : 'Expand all')}
          </Button>
        )}
      </div>
      <Collapse
        type="arrow"
        accordion={false}
        onChange={(key) => setActiveKey(key)}
        activeKey={activeKey}
        level={-1}
        noPadding
      >
        {UIStore.languages.map((language) => (
          <Panel key={language} header={t(language)} showArrow>
            <ContentSlotEdit language={language} />
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

export default observer(ContentEditor)
