import InputEditor from '../InputEditor'
import UIStore from '../../stores/UIStore'

export default function Properties(props) {
  const { spot, properties = [], readOnly = false, selectLanguage } = props

  if (!properties.length) {
    return null
  }

  const formattedProperties = properties.map((item) => ({
    ...item,
    values: {
      [UIStore.currentLanguage]: spot[item.id],
    },
  }))

  return formattedProperties.map((property) => (
    <InputEditor
      isChildComponent
      key={property.uuid}
      disabled={readOnly}
      {...property}
      selectLanguage={selectLanguage}
      showTranslations={!readOnly}
      setConditionalValues={props.setConditionalValues}
      conditionalValues={props.conditionalValues}
    />
  ))
}
