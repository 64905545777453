import Icon from '../Icon'
import Radio from '../Radio'
import TagsInput from '../TagsInput'

import { t } from '../../utils'
import { searchTypes } from './constant'

export const DisplaySearch = ({
  onChange = () => {},
  type,
  searchType,
  identifier,
  previewUrl,
  hideSearchType,
}) => {
  if (type !== 'search') return null

  return (
    <>
      {!hideSearchType && (
        <>
          <div className="display-column">
            <div className="radio-group">
              {type === 'search' && (
                <Radio.Group
                  value={searchType}
                  onChange={(value) => {
                    onChange({
                      type,
                      searchType: value,
                      identifier: [],
                      previewUrl: previewUrl,
                    })
                  }}
                  key="searchType"
                >
                  <label className="radio-group--label">{t('within')}</label>
                  {searchTypes.map((searchType) => {
                    return (
                      <Radio
                        label={t(searchType.name)}
                        value={searchType.id}
                        key={searchType.name}
                      />
                    )
                  })}
                </Radio.Group>
              )}
            </div>
          </div>
          <div className="display-column column--center">
            <Icon symbol="arrow-right" className="display-icon-moving" />
          </div>
        </>
      )}

      {(searchType || hideSearchType) && (
        <div className="display-column column--grow">
          <TagsInput
            title={t(
              'for these search terms (terminate expressions by "," or ";")'
            )}
            tokenSeparators={[',', ';']}
            value={identifier || ''}
            onChange={(value) => {
              onChange({
                type,
                searchType,
                identifier: value,
                previewUrl: previewUrl,
              })
            }}
          />
        </div>
      )}
    </>
  )
}
