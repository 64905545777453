import { observer } from 'mobx-react-lite'
import ABMetaInformation from '../../components/ABMetaInformation'
import PageHeader from '../../components/PageHeader'
import { UIStore } from '../../stores'

function LayoutV3(props) {
  return (
    <ABMetaInformation>
      {!UIStore.isStorefrontPreview && <PageHeader {...props} />}
      {props.children}
    </ABMetaInformation>
  )
}

export default observer(LayoutV3)
