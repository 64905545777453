import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'

import PageTitle from '../../components/PageTitle'
import LoadingScreen from '../../components/LoadingScreen'

import t from '../../utils/translate'

import PersonalizationStore from '../../stores/PersonalizationStore'
import CategoryStore from '../../stores/CategoryStore'

import PersonalizationHeadline from './PersonalizationHeadline'
import Checklist from './Checklist'
import Preview from './Preview'
import TrialSection from './TrialSection'

import './personalization.styl'
import ResultsChart from './ResultsChart'
import TrialDescription from './TrialDescription'
import Settings from './Settings'
import ABTestStore from '../../stores/ABTestStore'
import { createRedirectPath } from '../../utils'

export const Title = observer(() => (
  <PageTitle prefix={t('You are editing')}>{t('Personalization')}</PageTitle>
))

const Personalization = () => {
  const { running, showMetaconfigView, isFetchingTest } = ABTestStore

  const history = useHistory()

  const runningTest =
    (running && running.find((test) => test.case === 'personalization')) || null
  const haveRunningTest = runningTest !== null

  useEffect(() => {
    async function fetchData() {
      await PersonalizationStore.fetchPersonalizationStatus()
      await PersonalizationStore.fetchPersonalizationExperiment()
      CategoryStore.fetchCategories()
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (haveRunningTest && !isFetchingTest && !showMetaconfigView) {
      const newURL = createRedirectPath(
        `/personalization?ab-test=${runningTest.id}&ab-variant=A`
      )
      history.replace(newURL)
    }

    // eslint-disable-next-line
  }, [isFetchingTest, haveRunningTest])

  if (PersonalizationStore.state === 'pending') {
    return (
      <>
        <Title />
        <LoadingScreen withPageTitle />
      </>
    )
  }

  return (
    <>
      <Title />

      <div className="content-wrapper personalization">
        <PersonalizationHeadline />

        {PersonalizationStore.status && (
          <>
            <ResultsChart />
            <Checklist />
            <TrialSection />
            <Settings />
            <Preview />

            {!PersonalizationStore.isPersonalizationPossible &&
              PersonalizationStore.status && <TrialDescription />}
          </>
        )}
      </div>
    </>
  )
}

export default observer(Personalization)
