import React from 'react'
import classnames from 'classnames'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import Button from '../../components/Button'
import Text from '../../components/Text'
import { CategoryMappingStore, UIStore } from '../../stores'
import { googleCategories } from './googleCategories'
import { googleCategoriesEnglish } from './googleCategoriesEnglish'
import { t } from '../../utils'
import AutoComplete from '../../components/AutoComplete'
import DropdownButton from '../../components/DropdownButton'

import '../../views/MenuEditor/theme/MenuEditorNodeWrapper.styl'

/**
 * Theme-Wrapper for a Tree Node of the react-sortable-tree package.
 * This is adaptation of the original node theme of react-sortable-tree.
 *
 * You can find the original file in the package-repo:
 * https://github.com/frontend-collective/react-sortable-tree/blob/master/src/node-renderer-default.js
 */

const MenuEditorNodeWrapper = (props) => {
  const {
    scaffoldBlockPxWidth,
    toggleChildrenVisibility,
    connectDragPreview,
    connectDragSource,
    isDragging,
    canDrop,
    canDrag,
    node,
    title,
    subtitle,
    draggedNode,
    path,
    treeIndex,
    isSearchMatch,
    isSearchFocus,
    className,
    didDrop,
    treeHelper,
    parentNode,
    rowDirection,
    treeId,
    isOver,
    showCategoryIds,
    ...otherProps
  } = props

  const isLandingPadActive = !didDrop && isDragging

  const findChild = (tree, position) => {
    let currentLevel = tree

    for (let i = 0; i < position.length; i++) {
      if (i === 0) {
        currentLevel = currentLevel[position[i]]
      } else {
        currentLevel = currentLevel.subtree[position[i]]
      }
    }

    return currentLevel
  }

  const changeValuesOfSubtree = (tree, value) => {
    if (Array.isArray(tree.subtree) && tree.subtree.length === 0) return

    Object.values(tree.subtree).forEach((node) => {
      node.google = value
      changeValuesOfSubtree(node, value)
    })
  }

  const applyMappingToChildren = (value, position) => {
    const mapping = { ...toJS(CategoryMappingStore.mapping) }
    const child = findChild(mapping, position)
    changeValuesOfSubtree(child, value)
    CategoryMappingStore.updateMapping(mapping)
  }

  const setMapping = (value = '', position) => {
    const mapping = { ...toJS(CategoryMappingStore.mapping) }
    const child = findChild(mapping, position)

    child.google = value
    CategoryMappingStore.updateMapping(mapping)
  }

  const googleCategoriesItem =
    UIStore.currentLanguage === 'de'
      ? googleCategories
      : googleCategoriesEnglish

  return (
    <div style={{ height: '100%' }} {...otherProps}>
      {toggleChildrenVisibility &&
        node.children &&
        (node.children.length > 0 || typeof node.children === 'function') && (
          <div>
            {node.expanded && !isDragging && (
              <div
                style={{ width: scaffoldBlockPxWidth }}
                className={classnames('menu-editor-node__line-children')}
              />
            )}
          </div>
        )}

      <div className={classnames('menu-editor-node__row-wrapper')}>
        {/* Set the row preview to be used during drag and drop */}
        {connectDragPreview(
          <div
            className={classnames(
              'menu-editor-node__row',
              isLandingPadActive && 'menu-editor-node__row-landing-pad',
              className
            )}
          >
            {node.children && node.children.length > 0 && (
              <Button
                level={1}
                variant="reduced"
                icon={node.expanded ? 'angles-up' : 'angles-down'}
                aria-label={node.expanded ? 'Collapse' : 'Expand'}
                className="menu-editor-node__collapse-button"
                onClick={() =>
                  toggleChildrenVisibility({
                    node,
                    path,
                    treeIndex,
                  })
                }
              />
            )}

            <div className={classnames('menu-editor-node__row-content')}>
              <div
                className={classnames('menu-editor-node__title-wrapper', {
                  'menu-editor-node__title-wrapper--open':
                    CategoryMappingStore.showCategoryIds,
                })}
              >
                <Text
                  title={node.title}
                  className="menu-editor-node__category-title"
                >
                  {node.title}
                </Text>
                {CategoryMappingStore.showCategoryIds && (
                  <Text className="menu-editor-node__sub-title" variant="book">
                    ({node.key})
                  </Text>
                )}
              </div>
              <AutoComplete
                allowClear
                value={node.google}
                fullWidth
                itemKey="label"
                defaultOptions={googleCategoriesItem.map((option) => ({
                  ...option,
                  label: `${option.label} (ID: ${option.value})`,
                }))}
                onSelect={(value) => setMapping(value, node.position)}
                placeholder={t('Search for Google category')}
              />
              {node.children &&
                (node.children.length > 0 ||
                  typeof node.children === 'function') && (
                  <div style={{ marginLeft: 10 }}>
                    <DropdownButton
                      actionIcon="ellipsis-vertical__solid"
                      iconOnly
                      options={[
                        {
                          label: 'Apply selection to all subcategories',
                          value: 'apply',
                        },
                        { label: 'Reset all subcategories', value: 'reset' },
                      ]}
                      onSelect={(value) => {
                        applyMappingToChildren(
                          value.key === 'apply' ? node.google : '',
                          node.position
                        )
                      }}
                      variant="tertiary"
                      buttonLevel={1}
                      destroyTooltipOnHide
                    />
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(MenuEditorNodeWrapper)
