import TextInput from '../TextInput'
import RichTextInput from '../RichTextInput'
import ColorPicker from '../ColorPicker'
import Checkbox from '../Checkbox'
import FileUpload from '../FileUpload'
import MultiCheckbox from '../MultiCheckbox'
import Select from '../Select'
import HotSpotImage from '../HotSpotImage'
import Repeatable from '../Repeatable'
import InputEditorGroup from '../InputEditorGroup'
import ProductStreamInput from '../ProductStreamInput'
import NumberInput from '../NumberInput'
import DocumentInput from '../DocumentInput'
import DatePicker from '../DatePicker'

export default {
  text: TextInput,
  richtext: RichTextInput,
  colorpicker: ColorPicker,
  checkbox: Checkbox,
  file: FileUpload,
  multiselect: MultiCheckbox,
  select: Select,
  hotspot: HotSpotImage,
  array: Repeatable,
  object: InputEditorGroup,
  number: NumberInput,
  documents: DocumentInput,
  stream: ProductStreamInput,
  date: DatePicker,
}

/**
 * Some inputs are really large, so the description is better shown directly
 * below the title and above the input itself.
 *
 * @type {string[]}
 */
const INPUTS_WITH_ABOVE_DESCRIPTION = ['documents', 'object', 'hotspot']

export { INPUTS_WITH_ABOVE_DESCRIPTION }
