import React from 'react'
import csx from 'classnames'
import { observer } from 'mobx-react-lite'

import Text from '../../../Text'
import Select from '../../../Select'

import ResultTable from './ResultTable'
import ResultsChart from './ResultsChart'
import DisplayedVariantSelection from './DisplayedVariantSelection'

import ABTestStore from '../../../../stores/ABTestStore'

import t from '../../../../utils/translate'

import './resultsPanel.styl'

const ResultPanel = () => {
  if (ABTestStore.resultsState === 'error') {
    return (
      <div className="results-panel results-panel--error">
        <Text
          className="results-panel__error-message"
          size="charlie"
          element="p"
        >
          {t('Fetching of the experiment results failed.')}
        </Text>
        {ABTestStore.resultsError && (
          <Text
            className="results-panel__error-message--small"
            size="alpha"
            element="p"
          >
            Error-Code: {ABTestStore.resultsError}
          </Text>
        )}
      </div>
    )
  }

  if (ABTestStore.hasNotEnoughDataForGraph) {
    return (
      <div className="results-panel results-panel--error">
        <Text
          className="results-panel__error-message"
          size="charlie"
          element="p"
        >
          {t('Not enough data has been collected yet.')}
        </Text>
        <Text element="p" className="results-panel__error-message--small">
          {t('When enough data is available, the results will be shown here.')}
        </Text>
      </div>
    )
  }

  if (
    !ABTestStore.graph ||
    ABTestStore.isFetchingTest ||
    ABTestStore.isFetchingSupportedMetrics
  ) {
    return null
  }

  return (
    <div
      className={csx('results-panel', {
        'results-panel--three-segments':
          ABTestStore.editingExperiment.segments === 3,
      })}
    >
      <div className="results-panel__chart">
        <ResultsChart />

        <DisplayedVariantSelection />
      </div>
      <div className="results-panel__table">
        <div className="results-panel__select-metric">
          <Text>{t('Display data for')} </Text>
          <Select
            options={ABTestStore.supportedMetrics}
            value={ABTestStore.selectedTableMetric}
            onChange={(value) => {
              ABTestStore.selectTableMetric(value)
            }}
          />
        </div>

        <ResultTable />
      </div>
    </div>
  )
}

export default observer(ResultPanel)
