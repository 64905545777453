import { Tooltip } from 'antd'
import { t } from '../../utils'
import Icon from '../Icon'
import classnames from 'classnames'

import './activeField.styl'

const ActiveField = ({ active = false, tooltip = false }) => {
  const icon = (
    <Icon
      symbol="circle__solid"
      className={classnames('active-indicator', {
        ['active-indicator--active']: active,
        ['active-indicator--inactive']: !active,
      })}
      width="1.6rem"
      height="1.6rem"
    />
  )

  if (tooltip) {
    return (
      <Tooltip title={t(active ? 'Active' : 'Inactive')}>
        <span>{icon}</span>
      </Tooltip>
    )
  }

  return icon
}

export default ActiveField
