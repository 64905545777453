import Dialog from 'rc-dialog'
import { useEffect } from 'react'
import Icon from '../Icon'
import classNames from 'classnames'

import 'rc-dialog/assets/index.css'
import './style.styl'
import ShadowScroll from '../ShadowScroll'
import { observer } from 'mobx-react-lite'
import { ActionLayerStore } from '../../stores'
import Text from '../Text'

const Modal = ({
  header,
  children,
  footer,
  onClose,
  maxWidth,
  // add maxHeight to prevent scroll body, we just need scroll modal content
  maxHeight = 'calc(100vh - 6rem)',
  className,
  mask = false,
  align = 'center',
  closeIconText = '',
  customDialogClassName = '',
  ...rest
}) => {
  useEffect(() => {
    // fix conflict style with DnD library
    const dialogs = document.getElementsByClassName('rc-dialog-root')
    if (dialogs.length) {
      Array.from(dialogs).forEach((dialog) => {
        if (!dialog.parentNode.className.includes('makaira-dialog')) {
          dialog.parentNode.className = `makaira-dialog ${customDialogClassName}`
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  function getCloseIcon() {
    if (closeIconText === '' || !closeIconText) {
      return <Icon className="modal__close-icon" symbol="xmark" />
    }

    return (
      <div className="modal__close-with-text">
        <Text size="charlie">{closeIconText}</Text>
        <Icon className="modal__close-icon" symbol="check" />
      </div>
    )
  }

  const { height } = ActionLayerStore

  return (
    <Dialog
      style={{
        ...rest?.style,
        maxWidth,
        '--modal-max-height': maxHeight,
        '--offset-top': (height || 0) + 'px',
      }}
      closeIcon={getCloseIcon()}
      className={classNames(`modal modal--align-${align} ${className}`, {
        'empty-body': !children,
        'modal--with-text-close': closeIconText !== '',
      })}
      title={header}
      onClose={onClose}
      mask={mask}
      animation="fade"
      maskAnimation="fade"
      footer={footer && <div className="modal__footer">{footer}</div>}
      destroyOnClose
      {...rest}
    >
      {children && (
        <ShadowScroll className="modal__content">{children}</ShadowScroll>
      )}
    </Dialog>
  )
}

export default observer(Modal)
