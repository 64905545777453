import { toast } from 'react-toastify'
import { makeAutoObservable } from 'mobx'

import api from '../../api'
import t from '../../utils/translate'

import AppStore from './AppStore'
import EditorStore from '../PageEditor/EditorStore'

class ContentModalStore {
  contentModalInfo = {}

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetchContentModalUrl(slug) {
    try {
      const { data } = yield api.apps.getExternalAppsUrl(slug)
      this.contentModalInfo[slug] = data
      return data
    } catch (error) {
      toast.error(t('No content-modal with the given slug exists.'))
      return null
    }
  }

  getMetaInformation(slug) {
    const information = { ...this.contentModalInfo[slug] } ?? {}
    information.finalURL = information.iframeURL
    return information
  }

  /**
   *
   * @param event {MessageEvent}
   * @param slug {string}
   */
  handleReceiveMessageEvent(event, slug) {
    const { source, action, data } = event.data

    if (source !== `makaira-content-modal-${slug}`) {
      console.debug(
        `[Makaira-Content-Modal] We have received a message event from a source 
        that doesn't match the slug "makaira-content-modal-${slug}". Instead "${source}" was given.`,
        event
      )
      return
    }

    switch (action) {
      case 'requestUser':
        this.handleUserRequest(event, slug)
        break
      case 'updateFieldValue':
        this.handleFieldUpdate(data, slug)
    }
  }

  *handleUserRequest(event, slug) {
    const { hmac, nonce, makairaHmac } = event.data

    console.debug(
      `[Makaira-Content-Modal] Received request for auth token.`,
      event
    )

    const credentialsValid = yield AppStore.validateCredentialsRequest(slug, {
      hmac,
      nonce,
      makairaHmac,
    })

    AppStore.sendValidationAnswerMessage(credentialsValid, event, false)
  }

  handleFieldUpdate(data, slug) {
    if (!this.contentModalInfo[slug]) {
      console.error(
        '[Makaira-Content-Modal] No information for content modal ' +
          slug +
          ' could be found'
      )
      return
    }

    console.debug(
      `[Makaira-Content-Modal] Received request to update field data in page editor.`,
      data
    )
    if (EditorStore.editingFieldInfo?.id && data) {
      // we have to replace new line key from chatGPT with html tag for show correctly
      // paragraph in our richtext editor
      let prepareData = data
      if (typeof prepareData === 'string') {
        prepareData = data
          .split('\n')
          .map((paragraph) => {
            if (paragraph) {
              return `<p>${paragraph}</p>`
            }
            return paragraph
          })
          .join('')
      }
      EditorStore.setValueForField(EditorStore.editingFieldInfo.id, prepareData)
      EditorStore.closeContenModalApp()
    }
  }

  sendPageInformation(iframe, slug) {
    if (!iframe) {
      console.error(
        '[Makaira-Content-Modal] Iframe for content modal ' +
          slug +
          ' could not be found.'
      )
      return
    }

    if (!this.contentModalInfo[slug]) {
      console.error(
        '[Makaira-Content-Modal] No information for content modal ' +
          slug +
          ' could be found'
      )
      return
    }

    const targetURL = this.contentModalInfo[slug].iframeURL
    iframe.contentWindow.postMessage(
      {
        source: 'makaira-content-modal-bridge',
        // Actions are always named from the perspective of the External-App/Content-Modal.
        action: 'receiveFieldInformation',
        data: {
          selectedLanguage: EditorStore.contentLanguage,
          fieldInformation: JSON.parse(
            JSON.stringify(EditorStore.editingFieldInfo)
          ),
        },
      },
      targetURL
    )
  }
}

export default new ContentModalStore()
