import { observer } from 'mobx-react-lite'
import React from 'react'
import NumberInput from '../../NumberInput'

const RangeNumberField = ({ onChange, value }) => {
  const arrayValue = value || []

  return (
    <div className="filter-section__input-group">
      <NumberInput
        onChange={(numb) => onChange([numb, arrayValue[1] || 0])}
        value={arrayValue[0]}
      />
      <NumberInput
        onChange={(numb) => onChange([arrayValue[0] || 0, numb])}
        value={arrayValue[1]}
      />
    </div>
  )
}

export default observer(RangeNumberField)
