import '../../ColorPicker/colorPicker.styl'

function ColorPickerPreview(props) {
  const rgbLabel = ['R', 'G', 'B', 'A']
  const color = props.value
  const rgbaValue = getRGBA(color.rgba)

  function getRGBA(rgba) {
    return rgba
      .substring(5, rgba.length - 1)
      .replace(/ /g, '')
      .split(',')
  }

  return (
    <div className="color-picker__wrapper color-picker__wrapper--preview">
      <div className="color-picker__component">
        <div className="color-picker__component-preview">
          <div
            className="color-picker__component-preview-overlay"
            style={{ backgroundColor: color.rgba }}
          />
        </div>

        <div className="color-picker__component-label">
          {rgbLabel.map((el, index) => {
            return (
              <div key={index} className="color-picker__component-label-value">
                <label>{el}</label>
                <span>{rgbaValue[index]}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ColorPickerPreview
