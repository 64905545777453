import { observer } from 'mobx-react-lite'
import Modal from '../Modal'
import Editor from 'react-simple-code-editor'
import Prism from 'prismjs'
import { t } from '../../utils'

function CodeModal({ visible, onClose, id }) {
  if (!visible) return null

  return (
    <Modal
      header={t('Frontend code:')}
      visible={visible}
      onClose={onClose}
      mask
      maskClosable
    >
      <div className="ab-frontend__code-editor">
        <Editor
          style={{ background: '#ecf2f3' }}
          value={`// This code will catch all possible variants, copy the snippet and modify it regarding to your needs.

function ExperimentTestComponent() {
  const { isInExperiment } = useAbTesting()
  
  if (isInExperiment({ id: ${id}, variation: 'Original' })) {
    return <span>Original implementation</span>
  } else if (isInExperiment({ id: ${id}, variation: 'B' })) {{
    return <span>Implementation of testcase B</span>
  } else if (isInExperiment({ id: ${id}, variation: 'C' })) {{
    return <span>Implementation of testcase C</span>
  }
}`}
          highlight={(code) =>
            Prism.highlight(code, Prism.languages.jsx, 'jsx')
          }
          onValueChange={() => {}}
          padding={10}
        />
      </div>
    </Modal>
  )
}

export default observer(CodeModal)
