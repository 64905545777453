import { Formik } from 'formik'
import * as Yup from 'yup'
import { observer } from 'mobx-react-lite'
import ConfigurationPanel, {
  Panel,
} from '../../../../components/ConfigurationPanel'
import { FeedStore } from '../../../../stores'
import t from '../../../../utils/translate'
import UpdateStoreDirtyState from '../../../../components/UpdateStoreDirtyState'
import FeedUrl from './FeedUrl'
import FeedContent from './FeedContent'
import FeedFormat from './FeedFormat'
import FormikOnChange from '../../../../components/FormikOnChange'

import './settingPanel.styl'

const SettingPanel = ({ shown = false, formikRef }) => {
  const feed = FeedStore.feedDetail

  const schema = Yup.object().shape({
    name: Yup.string().required(t('Name is required!')),
    stream: Yup.number().nullable().required(t('Stream is required!')),
  })

  const handleSubmit = (values) => FeedStore.setFeed(values, true)

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        name: feed.name,
        stream: feed.stream,
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <>
        <UpdateStoreDirtyState store={FeedStore} />
        <FormikOnChange onChange={handleSubmit} />
        <ConfigurationPanel
          className="feed-setting"
          accordion={false}
          active={shown}
          defaultActive={['Feed URL', 'Feed Content', 'Feed Format']}
        >
          <Panel header={t('Feed URL')} key="Feed URL">
            <FeedUrl />
          </Panel>
          <Panel header={t('Feed Content')} key="Feed Content">
            <FeedContent />
          </Panel>
          <Panel header={t('Feed Format')} key="Feed Format">
            <FeedFormat />
          </Panel>
        </ConfigurationPanel>
      </>
    </Formik>
  )
}

export default observer(SettingPanel)
