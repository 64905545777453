import { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams, useHistory } from 'react-router-dom'
import classNames from 'classnames'

import PageTitle from '../../components/PageTitle'
import LoadingScreen from '../../components/LoadingScreen'
import ContentWrapper from '../../components/ContentWrapper'
import ConfigurationPanel, { Panel } from '../../components/ConfigurationPanel'

import Slots from './Slots'
import Actions, { calculateArrowPosition } from './Actions'
import EditModal from './EditModal'
import Content from './Content/Content'
import ConnectionModal from './ConnectionModal/ConnectionModal'
import Revision from './Revision'
import MetaConfiguration from './MetaConfiguration'

import t from '../../utils/translate'
import { routes } from '../../routing'
import useLeaveConfirm from '../../utils/useLeaveConfirm'
import createRedirectPath from '../../utils/createRedirectPath'

import UIStore from '../../stores/UIStore'
import ActionLayerStore from '../../stores/ActionLayerStore'
import SmartBundleStore from '../../stores/SmartBundleStore'

import './bundle.styl'

function BundleDetail() {
  const formikRef = useRef()
  const { id } = useParams()
  const history = useHistory()

  const [isLoading, setLoading] = useState(true)
  const { setRequireImport } = SmartBundleStore

  const { languages } = UIStore
  const {
    isDirty,
    isConnectionModalVisible,
    isEditModalVisible,
    isConfirmRestoreModalVisible,
  } = SmartBundleStore

  useLeaveConfirm(isDirty)

  useEffect(() => {
    async function fetchBundle() {
      const bundle = await SmartBundleStore.getById(id)
      SmartBundleStore.editBundle(bundle)

      if (id) {
        SmartBundleStore.fetchRevisions()
      } else {
        SmartBundleStore.setShowConfiguration(true)
      }
      setLoading(false)
    }

    fetchBundle()

    return () => {
      SmartBundleStore.setShowConfiguration(false)
    }
  }, [id])

  useEffect(() => {
    if (
      isConnectionModalVisible ||
      isEditModalVisible ||
      isConfirmRestoreModalVisible
    ) {
      ActionLayerStore.closeActionLayer()
      return
    }

    if (isDirty) {
      ActionLayerStore.openActionLayer({
        saveTitle: t(id ? 'Save' : 'Continue'),
        closeTitle: t('Abort and back to listing'),
        onSave: handleActionLayerSave,
        onClose: handleActionLayerClose,
      })
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDirty,
    isConnectionModalVisible,
    isEditModalVisible,
    history,
    id,
    setRequireImport,
    isConfirmRestoreModalVisible,
  ])

  // recaculate the arrow position when title changed
  useEffect(() => {
    setTimeout(calculateArrowPosition, 50)
    // eslint-disable-next-line
  }, [SmartBundleStore.bundleToEdit.internal_title])

  const handleActionLayerSave = async () => {
    const formValidation = await checkFormValidation()

    if (!formValidation) {
      return false
    }

    if (SmartBundleStore.showConfiguration) {
      await formikRef.current?.submitForm()
    }

    const bundle = await SmartBundleStore.updateOrCreate()

    if (SmartBundleStore.state === 'error') {
      throw new Error()
    }

    // back to list when submited update bundle, go to bundle detail page if submited create new bundle
    if (id) {
      history.push(createRedirectPath(routes.bundleList.path))
    } else {
      history.push(createRedirectPath(`/bundle/${bundle.id}`))
    }
  }

  const handleActionLayerClose = () => {
    setRequireImport(false)
    history.push(createRedirectPath(routes.bundleList.path))
  }

  const checkFormValidation = async () => {
    if (formikRef.current && SmartBundleStore.showConfiguration) {
      const formValidation = await formikRef.current?.validateForm()

      if (Object.keys(formValidation).length !== 0) {
        // we need to set all url input fields as touched, so the error messages will be displayed
        const urlTouched = {}
        languages.forEach((lang) => (urlTouched[lang] = true))
        formikRef.current?.setTouched(
          {
            internal_title: true,
            url: urlTouched,
          },
          false
        )

        return false
      }
    }

    return true
  }

  if (isLoading || Object.keys(SmartBundleStore.bundleToEdit).length === 0)
    return <LoadingScreen />

  const bundle = SmartBundleStore.bundleToEdit

  let title = bundle.internal_title
  if (title === '' && !bundle.id) {
    title = t('New Bundle…')
  }

  return (
    <>
      <PageTitle
        prefix={t(bundle.id ? 'You are editing' : 'You are creating')}
        additionalActions={
          <Actions
            onClickApply={async () => {
              const formValidation = await checkFormValidation()

              if (formValidation) {
                await formikRef.current?.submitForm()
                SmartBundleStore.setShowConfiguration(false)
              }
            }}
            onClickSetting={() => SmartBundleStore.setShowConfiguration(true)}
            isShowConfiguration={SmartBundleStore.showConfiguration}
          />
        }
        metaInfo={
          id && {
            timestamp: bundle.changed?.date,
            timezone: bundle.changed?.timezone,
            user: bundle.user,
            id: bundle.id,
            internalTitle: bundle.internal_title,
          }
        }
      >
        {title}
      </PageTitle>

      <ConfigurationPanel
        active={SmartBundleStore.showConfiguration}
        defaultActive="configuration"
        className={classNames({ 'bundle__configuration--new': !id })}
      >
        <Panel
          header={t('Configuration')}
          key="configuration"
          withoutCollapse={!bundle.id}
        >
          <MetaConfiguration formikRef={formikRef} />
        </Panel>
        {bundle.id && (
          <Panel header={t('Revision')} key="revision">
            <Revision />
          </Panel>
        )}
      </ConfigurationPanel>
      <ContentWrapper>
        <Slots slots={bundle.slots} />

        <Content />

        <EditModal />

        <ConnectionModal />
      </ContentWrapper>
    </>
  )
}

export default observer(BundleDetail)
