import { useEffect, useState, useRef } from 'react'
import { Tooltip } from 'antd'
import cs from 'classnames'
import cronstrue from 'cronstrue/i18n'
import { useHistory } from 'react-router-dom'
import ImporterPageTitle from './ImporterPageTitle'
import { createRedirectPath, t } from '../../utils'
import ImporterStore from '../../stores/ImporterStore'
import ResourceTable from '../../components/ResourceTable'
import { Column } from '../../components/Table'
import { observer } from 'mobx-react-lite'
import UIStore from '../../stores/UIStore'
import Icon from '../../components/Icon'
import ActionLayerStore from '../../stores/ActionLayerStore'
import BuildButton from './components/BuildButton'
import Text from '../../components/Text'
import { TARGET_TYPE } from './constants'

import './schedule.styl'

const ScheduleIcon = ({ status, kind, isError }) => {
  const className = cs('schedule-icon', {
    'schedule-icon__red': isError,
    'schedule-icon__green': !isError && status === 'Running',
    'schedule-icon__yellow':
      !isError && ['Terminating', 'Pending', 'Waiting'].includes(status),
  })

  const getSymbol = () => {
    if (isError) return 'bolt-lightning'

    switch (kind) {
      case 'continuously':
        return 'sync'
      case 'one-time':
        return 'power-off'
      case 'scheduled':
        return 'calendar'
      default:
        return ''
    }
  }

  return (
    <div className={className}>
      <Icon minWidth={14} height={14} symbol={getSymbol()} />
    </div>
  )
}

const Schedule = () => {
  const [selectedRows, setSelectedRows] = useState([])
  const history = useHistory()
  const {
    buildNewIndex,
    stopBuildIndex,
    fetchScheduleStatus,
    fetchAll,
    pagination,
    ...storeWithoutPagination
  } = ImporterStore

  const timeout = useRef(null)

  useEffect(() => {
    ImporterStore.fetchAll()
    ImporterStore.fetchScheduleStatus()

    // update status after 10s
    timeout.current = setTimeout(ImporterStore.fetchScheduleStatus, 10 * 1000)

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  const goToEdit = (data) => {
    if (data.schedules) {
      history.push(createRedirectPath(`/importer/edit/${data.id}`))
    }
  }

  const updatePage = async () => {
    await fetchScheduleStatus()
    await fetchAll()

    // update status after 10s
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    timeout.current = setTimeout(ImporterStore.fetchScheduleStatus, 10 * 1000)
  }

  const handleBuildNewIndex = async (id) => {
    await buildNewIndex(id)
    await updatePage()
  }

  const handleStopBuildIndex = async (id) => {
    await stopBuildIndex(id)
    await updatePage()
  }

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    await ImporterStore.delete(selectedRows)

    if (ImporterStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const bulkActions = [
    {
      title: t('Delete'),
      onExecute: () => showDeleteWarning(),
    },
  ]

  return (
    <>
      <ImporterPageTitle />
      <div className="content-wrapper schedule-table">
        <ResourceTable
          title={t('Importer Configuration')}
          store={storeWithoutPagination}
          loading={ImporterStore.state === 'pending'}
          resourceName="importers"
          emptyText={t('importerDescription')}
          onRowClick={goToEdit}
          headerAction={{
            type: 'create',
            action: () => history.push(createRedirectPath('/importer/new')),
          }}
          createAction={() => history.push(createRedirectPath('/importer/new'))}
          createActionText={t('Create Importer Configuration')}
          bulkActions={bulkActions}
          rowSelection={{
            selectedRows,
            onSelectionChange: (newRows) => setSelectedRows(newRows),
            checkShow: (record) => !record.kind,
          }}
        >
          <Column
            minWidth={100}
            title={t('Name')}
            dataIndex="internalTitle"
            render={(name) => <Text element="p">{name}</Text>}
          />

          <Column
            minWidth={20}
            title={t('Status')}
            render={(_, record) => {
              if (!record.kind) {
                return null
              }
              const scheduleStatus = ImporterStore.getScheduleById(record.id)
              const status = scheduleStatus?.current?.status
              const isError =
                scheduleStatus?.current?.status === 'Running' &&
                scheduleStatus?.current?.containerStatus === 'CrashLoopBackOff'
              return (
                <div className="schedule-table__status">
                  <Tooltip
                    align={{ offset: [-4, 3] }}
                    title={isError ? t('Error') : t(status) || t('Not running')}
                  >
                    <div>
                      <ScheduleIcon
                        isError={isError}
                        status={status}
                        kind={record.kind}
                      />
                    </div>
                  </Tooltip>
                </div>
              )
            }}
          />

          <Column
            minWidth={200}
            title={t('Type')}
            dataIndex="kind"
            render={(kind) => <Text element="p">{t(kind)}</Text>}
          />
          <Column
            minWidth={200}
            title={t('Source Type')}
            dataIndex="sourceType"
            render={(sourceType) => <Text element="p">{sourceType}</Text>}
          />
          <Column
            minWidth={200}
            title={t('Target Type')}
            dataIndex="targetType"
            render={(data) => <Text element="p">{t(TARGET_TYPE[data])}</Text>}
          />
          <Column
            minWidth={50}
            title={t('Languages')}
            render={(_, record) => (
              <Text element="p">
                {record.languages
                  ? record.languages.length > 0
                    ? record.languages.join(', ')
                    : t('all')
                  : ''}
              </Text>
            )}
          />
          <Column
            minWidth={300}
            title={t('Next run')}
            className="mk-table-cell__run-column"
            render={(_, record) => {
              if (record.kind === 'one-time') {
                const currentImporter = ImporterStore.getImporterById(
                  record.importerId
                )
                const oneTimeSchedule = ImporterStore.getScheduleById(record.id)
                return (
                  <div>
                    <BuildButton
                      importer={currentImporter}
                      schedule={oneTimeSchedule}
                      onBuildNewIndex={handleBuildNewIndex}
                      onStopBuildIndex={handleStopBuildIndex}
                    />
                  </div>
                )
              }

              if (record.cronExpression) {
                return (
                  <Text element="p">
                    {cronstrue.toString(record.cronExpression, {
                      locale: UIStore.currentLanguage,
                    })}
                  </Text>
                )
              }
            }}
          />
          <Column
            minWidth={50}
            align="right"
            render={(_, record) =>
              !record.kind && (
                <Icon
                  symbol="angle-right"
                  width={18}
                  height={18}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: 0,
                  }}
                />
              )
            }
          />
        </ResourceTable>
      </div>
    </>
  )
}

export default observer(Schedule)
