import { observer } from 'mobx-react-lite'

import Modal from '../../components/Modal'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import { t } from '../../utils'
import Button from '../../components/Button'
import Sortable from '../../components/Sortable'
import { CategoryStore, StreamStore, UIStore } from '../../stores'
import Hierarchy from './Hierarchy'
import Switch from '../../components/Switch'
import AutoComplete from '../../components/AutoComplete'
import Radio from '../../components/Radio'
import TagsProductInput from '../../components/TagsProductInput'
import { useEffect, useState } from 'react'
import Select from '../../components/Select'
import { toJS } from 'mobx'
import { get } from 'lodash'
import SortItem from './SortItem'

import './filter.styl'

function DetailModal(props) {
  const { close, isOpen, detailId } = props

  const [searchFilter, setSearchFilter] = useState('')
  const [language, setLanguage] = useState(
    UIStore.enterpriseConfiguration.defaultLanguage
  )
  const [customStream, setCustomStream] = useState(false)

  const alignToLatestAggregations = (modalDetailData) => {
    // Case default filters, custom sorting
    if (
      modalDetailData.overrideAggregationSorting &&
      !modalDetailData.overrideAggregations
    ) {
      CategoryStore.alignSortingToLatestAggregations()
    }

    // Case custom filters, default sorting
    if (
      modalDetailData.overrideAggregations &&
      !modalDetailData.overrideAggregationSorting
    ) {
      CategoryStore.alignFiltersToLatestAggregations()
    }
  }

  useEffect(() => {
    if (isOpen) {
      const data = CategoryStore.categoriesById[detailId] || {}
      CategoryStore.setModalDetail(data)
      setCustomStream(data.stream !== null)

      alignToLatestAggregations(data)
    }
  }, [isOpen, detailId])

  const onUpdate = async () => {
    await CategoryStore.updateOrCreate(CategoryStore.modalDetail)
    close()
  }

  if (!isOpen) return null

  const aggregationSorting = toJS(CategoryStore.modalDetail.aggregationSorting)

  const sortableArray = Object.entries(aggregationSorting || {})
    .reduce((d, [key, value]) => {
      const aggregation = CategoryStore.aggregations.find(
        (agg) => agg.key === key
      )
      if (aggregation) {
        return [
          ...d,
          {
            id: key,
            key,
            position: value,
            title: aggregation.title[language] || key,
          },
        ]
      }
      return d
    }, [])
    .sort((a, b) => (a.position - b.position > 0 ? 1 : -1))

  return (
    <Modal
      width={1000}
      visible={isOpen}
      onClose={close}
      header={
        <div className="category-list__modal-title">
          <Text weight="bold" size="delta">
            {CategoryStore.modalDetail.name}
          </Text>{' '}
          <Text size="charlie">({CategoryStore.modalDetail.id})</Text>
        </div>
      }
      mask
      maskClosable
      footer={[
        <Button onClick={close} key="cancel" icon="xmark">
          {t('Cancel')}
        </Button>,
        <Button
          loading={CategoryStore.updating}
          onClick={onUpdate}
          key="update"
          variant="primary"
          icon="check"
        >
          {t('Update')}
        </Button>,
      ]}
    >
      <div className="category-list__modal">
        <div className="category-list__modal-section">
          <Text>{t('Hierarchie')}</Text>
          <Hierarchy text={CategoryStore.modalDetail.hierarchy} />
        </div>
        <div className="category-list__modal-section">
          <Text>{t('Stream overwrite')}</Text>
          <Switch
            checked={customStream}
            onChange={(value) => {
              setCustomStream(value)
              if (!value) {
                CategoryStore.setModalDetail(null, 'stream')
              }
            }}
            type="horizontal"
            title={t('Use a custom stream')}
          />
          {customStream && (
            <AutoComplete
              value={CategoryStore.modalDetail.stream}
              fullWidth
              itemKey="label"
              defaultOptions={StreamStore.streamOptions}
              onSelect={(value) =>
                CategoryStore.setModalDetail(value, 'stream')
              }
            />
          )}
        </div>
        <div className="category-list__modal-row">
          <div className="category-list__modal-section category-list__filter-radios">
            <Text>{t('Filters')}</Text>
            <Radio.Group
              value={CategoryStore.modalDetail.overrideAggregations ? '1' : '0'}
              onChange={(value) =>
                CategoryStore.setModalDetail(
                  value === '1' ? true : false,
                  'overrideAggregations'
                )
              }
            >
              <Radio label={t('Default filters')} value={'0'} />
              <Radio label={t('Custom filters')} value={'1'} />
            </Radio.Group>
          </div>
          {CategoryStore.modalDetail.overrideAggregations && (
            <>
              <Icon width="25px" height="25px" symbol="arrow-right" />
              <div className="category-list__modal-section category-list__filter-input">
                <Text>{t('Which filters do you want to use?')}</Text>
                <TagsProductInput
                  key={CategoryStore.modalDetail.aggregations?.join('_')}
                  className="category-list__product-tags"
                  keyPathTitle={`title.${language}`}
                  fallbackKeyPathTitle="key"
                  searchedProducts={CategoryStore.aggregations.filter(
                    (item) =>
                      !searchFilter ||
                      get(item, `title.${language}`, get(item, 'title'))
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                  )}
                  languageSelect={
                    <Select
                      options={UIStore.languages.map((l) => ({
                        value: l,
                        label: l.toUpperCase(),
                      }))}
                      flexible
                      value={language}
                      dropdownMatchSelectWidth={false}
                      onChange={setLanguage}
                    />
                  }
                  value={CategoryStore.modalDetail.aggregations}
                  onChange={(newIDs) => {
                    if (CategoryStore.modalDetail.overrideAggregationSorting) {
                      const aggregations = toJS(
                        CategoryStore.modalDetail.aggregations
                      )
                      const addingId = newIDs.find(
                        (v) => !aggregations.includes(v)
                      )
                      const removingId = aggregations.find(
                        (v) => !newIDs.includes(v)
                      )

                      if (addingId) {
                        const addingFilter = toJS(
                          CategoryStore.aggregationsById[addingId]
                        )
                        const isExist = Object.keys(aggregationSorting).some(
                          (key) => key === addingFilter.key
                        )
                        if (!isExist) {
                          const maxPosition =
                            sortableArray[sortableArray.length - 1]?.position ||
                            0

                          CategoryStore.setModalDetail(
                            {
                              ...aggregationSorting,
                              [addingFilter.key]: maxPosition + 1,
                            },
                            'aggregationSorting'
                          )
                        }
                      }

                      if (removingId) {
                        const removingFilter = toJS(
                          CategoryStore.aggregationsById[removingId]
                        )
                        const isExist = Object.keys(aggregationSorting).some(
                          (key) => key === removingFilter.key
                        )
                        if (isExist) {
                          const newSorting = {}
                          Object.keys(aggregationSorting).forEach((key) => {
                            if (key !== removingFilter.key) {
                              newSorting[key] = aggregationSorting[key]
                            }
                          })
                          CategoryStore.setModalDetail(
                            newSorting,
                            'aggregationSorting'
                          )
                        }
                      }
                    }
                    CategoryStore.setModalDetail(newIDs, 'aggregations')
                  }}
                  allProductByIds={CategoryStore.aggregationsById}
                  onSearch={setSearchFilter}
                />
              </div>
            </>
          )}
        </div>
        <div className="category-list__modal-row">
          <div className="category-list__modal-section category-list__filter-radios">
            <Text>{t('Sorting filters')}</Text>
            <Radio.Group
              value={
                CategoryStore.modalDetail.overrideAggregationSorting ? '1' : '0'
              }
              onChange={(value) =>
                CategoryStore.setModalDetail(
                  value === '1' ? true : false,
                  'overrideAggregationSorting'
                )
              }
            >
              <Radio label={t('Default sorting')} value={'0'} />
              <Radio label={t('Custom sorting')} value={'1'} />
            </Radio.Group>
          </div>
          {Object.keys(sortableArray || []).length > 0 &&
            CategoryStore.modalDetail.overrideAggregationSorting && (
              <>
                <Icon width="25px" height="25px" symbol="arrow-right" />
                <div className="category-list__modal-section category-list__filter-input">
                  <Text>{t('Drag and Drop to sort the filters')}</Text>
                  <div className="category-list__sort-modal-list">
                    <Sortable
                      id="category_filters_sortable"
                      items={sortableArray}
                      itemComponent={SortItem}
                      onChange={(newArray) => {
                        const newAggregationSorting = newArray.reduce(
                          (d, current, index) => {
                            return {
                              ...d,
                              [current.key]: index + 1,
                            }
                          },
                          {}
                        )

                        CategoryStore.setModalDetail(
                          newAggregationSorting,
                          'aggregationSorting'
                        )
                      }}
                    />
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
    </Modal>
  )
}

export default observer(DetailModal)
