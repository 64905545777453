import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import cloneDeep from 'lodash/cloneDeep'
import t from '../../utils/translate'
import { EditorStore, UIStore } from '../../stores'
import Collapse, { Panel } from '../Collapse'
import Icon from '../Icon'
import Text from '../Text'
import Select from '../Select'
import Button from '../Button'
import Preview from './TranslationHelper/Preview'
import './inputEditor.styl'
import components, { INPUTS_WITH_ABOVE_DESCRIPTION } from './components'
import formatLanguageKey from '../../utils/formatLanguageKey'
import { InputGeneratorApps } from './InputGeneratorApps'

const InputEditor = (props) => {
  const [selectLanguage, setSelectLanguage] = useState()
  const [availableLanguages, setAvailableLanguage] = useState([])
  const [activeKey, setActiveKey] = useState('')

  const {
    label,
    showTranslations,
    description,
    type,
    apps = [],
    conditionField,
    conditionValue,
    conditionalValues = {},
    setConditionalValues = () => {},
    uuid,
    required,
  } = props

  const descriptionAbove = INPUTS_WITH_ABOVE_DESCRIPTION.includes(type)

  useEffect(() => {
    const languages = props.pali ? ['de', 'en', 'fr'] : UIStore.sortedLanguages
    const _availableLanguages = languages.filter(
      (language) => language !== EditorStore.contentLanguage
    )
    setAvailableLanguage(_availableLanguages)
    setSelectLanguage(_availableLanguages[0])
    // eslint-disable-next-line
  }, [])

  const handleToggleCollapse = () => {
    if (activeKey === 'open') {
      setActiveKey('')
    } else setActiveKey('open')
  }

  const InputComponent = components[type]

  const inputComponentProps = EditorStore.handleInputProps(props)

  useEffect(() => {
    if (Object.keys(conditionalValues).includes(uuid)) {
      if (
        inputComponentProps.value !== undefined &&
        conditionalValues[uuid] !== inputComponentProps.value
      ) {
        setConditionalValues((values) => ({
          ...values,
          [uuid]: inputComponentProps.value,
        }))
      }
    }
    // eslint-disable-next-line
  }, [inputComponentProps.value, conditionalValues])

  if (!InputComponent) {
    return null
  }

  const onCopy = () => {
    const selectedValue = EditorStore.getValueForField(props.id, selectLanguage)

    EditorStore.setValueForField(props.id, cloneDeep(selectedValue))
  }

  const onHoverInput = (e) => {
    e.stopPropagation()
    EditorStore.setHoveringInputId(props.uuid)
  }

  if (conditionField && conditionalValues[conditionField] !== conditionValue)
    return null

  return (
    <div
      className="input-editor"
      onMouseMove={onHoverInput}
      onMouseLeave={() => EditorStore.setHoveringInputId(null)}
    >
      <div className="input-editor__header">
        {label && type !== 'checkbox' && (
          <Text
            className={classNames('input-editor__title', {
              'input-editor__title--section-heading':
                type === 'object' && props.isChildComponent,
              'input-editor__title--required': required,
              'input-editor__title--invalid-field':
                EditorStore.invalidData.field[props.id],
            })}
            size="bravo"
          >
            {label}
          </Text>
        )}
        {availableLanguages.length > 0 && showTranslations && (
          <div
            onClick={handleToggleCollapse}
            className={classNames('input-editor__translate-helper', {
              'input-editor__translate-helper--open':
                activeKey === 'open' ||
                props.uuid === EditorStore.hoveringInputId,
            })}
          >
            <Text variant="book">{t('Copy from language')}</Text>
            <div className="input-editor__language-icon">
              <Icon
                symbol={activeKey === 'open' ? 'times' : 'language__solid'}
              />
            </div>
          </div>
        )}
        <InputGeneratorApps
          apps={apps}
          fieldInfo={{ id: props.id, label: props.label, type: props.type }}
        />
      </div>
      <Collapse
        level={1}
        className="input-editor__collapse"
        activeKey={activeKey}
      >
        <Panel key="open">
          <div className="input-editor__panel">
            <div className="input-editor__panel-header">
              {availableLanguages.length === 1 && (
                <Text size="bravo">{formatLanguageKey(selectLanguage)}</Text>
              )}
              {availableLanguages.length > 1 && (
                <Select
                  value={selectLanguage}
                  options={availableLanguages.map((language) => ({
                    value: language,
                    label: formatLanguageKey(language),
                  }))}
                />
              )}

              <div className="input-editor__copy-content">
                <Text size="bravo" variant="book">
                  {t('Copy to the input below.')}
                </Text>
                <Button
                  variant="secondary"
                  icon="arrow-down"
                  onClick={onCopy}
                />
              </div>
            </div>

            <Preview {...props} selectLanguage={selectLanguage} />
          </div>
        </Panel>
      </Collapse>
      {description && descriptionAbove && (
        <Text
          className="input-editor__description"
          size="bravo"
          variant="book"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      <div
        className={classNames('input-editor__component-wrapper', {
          'input-editor__component-wrapper--no-header':
            !label && !(availableLanguages.length > 0 && showTranslations),
        })}
      >
        <InputComponent
          {...inputComponentProps}
          setConditionalValues={setConditionalValues}
          conditionalValues={conditionalValues}
        />
      </div>
      {description && !descriptionAbove && (
        <Text
          className="input-editor__description input-editor__description--below"
          size="bravo"
          variant="book"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  )
}

export default observer(InputEditor)
