import { useEffect, useState } from 'react'
import { getIn } from 'formik'
import { observer } from 'mobx-react-lite'
import AutoComplete from '../../../components/AutoComplete'
import t from '../../../utils/translate'
import SmartBundleStore from '../../../stores/SmartBundleStore'
import get from 'lodash/get'

function SecondSlotSelect(props) {
  const { fields, formikProps, index } = props
  const { setFieldValue, values, errors } = formikProps
  const connection = values.connections[index] || {}
  const error = get(errors, `connections.${index}.second_slot.field_name`)
  const [value, setValue] = useState(getValueFromId())

  useEffect(() => {
    const propValue = getValueFromId()

    if (propValue !== value) {
      setValue(propValue)
    }
    // eslint-disable-next-line
  }, [connection])

  /**
   * Return a field in Value-Label-Object style for use in
   * a array map aggregation for a Ant-Design AutoComplete options array.
   *
   * @param field
   * @returns {{label, value}}
   */
  function forOptions(field) {
    return {
      value: field.id,
      label: field.title,
    }
  }

  function getField(id) {
    return fields.find((field) => field.id === id) || {}
  }

  function getValueFromId() {
    return (
      getField(getIn(connection, 'second_slot.field_name') ?? '').title ??
      undefined
    )
  }

  function handleChange(selected) {
    const selectedField = getField(selected)

    setValue(selectedField.title)
    setFieldValue(
      `connections.${index}.second_slot.field_name`,
      selectedField.id,
      // trigger validation if the field already has an error
      !!error
    )
  }

  return (
    <AutoComplete
      defaultOptions={fields.map(forOptions)}
      style={{ width: 240 }}
      onSelect={handleChange}
      placeholder={t('Choose field for Slot')(
        SmartBundleStore.getSlotPosition(connection.second_slot)
      )}
      value={value}
      fullWidth
      title={
        index === 0 &&
        t('Field for Slot')(
          SmartBundleStore.getSlotPosition(connection.second_slot)
        )
      }
      error={error}
    />
  )
}

export default observer(SecondSlotSelect)
