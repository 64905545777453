import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import { t } from '../../utils'

class ConfigurationStore {
  configuration = {}
  state = 'pending' // "pending", "done" or "error"
  setup = {}
  savingConfig = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetchConfiguration() {
    this.state = 'pending'
    try {
      const { data } = yield api.configuration.get()

      this.configuration = data
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong loading the search configuration.')
    }
  }

  *fetchPersonalization() {
    try {
      const { data } = yield api.configuration.get()

      this.configuration = data.personalization
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
      toast.error(
        'Something went wrong loading the personalization configuration.'
      )
    }
    return this.configuration
  }

  /**
   * Write the configuration store to the API.
   * Save-API-Call is a PUT request, so you can provide array of keys, which
   * will be sent to the API. Only the keys on the first level will be checked.
   *
   * @param updatedKeys List of keys that will be sent to the API.
   *                    If empty or null all keys will be sent.
   * @returns {Generator<Promise<{total: string, data: any}>, void, *>}
   */
  *saveConfiguration(updatedKeys) {
    updatedKeys = updatedKeys || []
    this.savingConfig = true
    try {
      this.state = 'pending'

      let putConfiguration = {}

      Object.keys(this.configuration).forEach((key) => {
        if (updatedKeys.includes(key) || updatedKeys.length === 0) {
          putConfiguration[key] = this.configuration[key]
        }
        if (
          key === 'suggestField' &&
          updatedKeys.includes(key) &&
          !putConfiguration[key]
        ) {
          putConfiguration[key] = ''
        }
      })

      const { data } = yield api.configuration.update(putConfiguration)

      this.configuration = data
      this.state = 'done'
      this.savingConfig = false
    } catch (error) {
      this.savingConfig = false
      this.state = 'error'
      toast.error('Something went wrong saving the search configuration.')
    }
  }

  get normalizedBoostFields() {
    const { boostFields = {} } = this.configuration

    return Array.from(Object.entries(boostFields), ([name, value]) => {
      return { name, value }
    })
  }

  get searchDocumentTypes() {
    const { searchDocumentTypes = {} } = this.configuration

    return Array.from(Object.entries(searchDocumentTypes), ([name, value]) => {
      return { name, value }
    })
  }

  updateBoostField({ name, value }) {
    this.configuration.boostFields[name] = value

    this.saveConfiguration()
  }

  updateSearchDocumentTypes({ name, value }) {
    if (Array.isArray(this.configuration.searchDocumentTypes)) {
      this.configuration.searchDocumentTypes = {}
    }

    this.configuration.searchDocumentTypes[name] = value

    this.saveConfiguration()
  }

  updateSuggestField(value) {
    this.configuration.suggestField = value

    this.saveConfiguration()
  }

  updatePreviewURL(previewUrl = '') {
    this.configuration.previewUrl = previewUrl

    this.saveConfiguration(['previewUrl'])
  }

  updateConfiguration(key, value, isSave = true) {
    this.configuration[key] = value

    if (isSave) {
      this.saveConfiguration([key])
    }
  }

  *fetchSetup() {
    this.state = 'pending'
    try {
      const { data } = yield api.installation.getSetup()

      this.setup = data
    } catch (error) {
      this.state = 'error'
      toast.error(t('Something went wrong fetching the setup information.'))
    }
  }
}

export default new ConfigurationStore()
