import { observer } from 'mobx-react-lite'
import moment from 'moment'

import HeadingMain from '../../../components/Headings/HeadingMain'
import Radio from '../../../components/Radio'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import DatePicker from '../../../components/DatePicker'
import { t } from '../../../utils'
import { UIStore } from '../../../stores'

function ExpireTime({ value, onChange, error }) {
  const { currentLanguage } = UIStore

  const timeFormat = currentLanguage === 'de' ? 'DD.MM.YYYY' : 'YYYY-MM-DD'

  let timeValue = undefined
  if (value && typeof value === 'string') {
    timeValue = moment(value)
  } else if (value && typeof value === 'object') {
    moment.tz(value?.date, value.timezone)
  }

  const onAddDays = (days) => {
    let updatingTime = moment().add(days, 'days')
    onChange(fortmatTime(updatingTime))
  }

  const fortmatTime = (time) => time.utc().format()

  return (
    <div className="expire-time">
      <HeadingMain>{t('Expiration')}</HeadingMain>

      <Radio.Group
        onChange={(value) => {
          if (value === 'false') {
            onChange(null)
          } else if (value === 'true') {
            onChange(fortmatTime(moment().add(1, 'days')))
          }
        }}
        value={value ? 'true' : 'false'}
      >
        <Radio
          size="large"
          label={<Text size="charlie">{t('Never expire')}</Text>}
          value="false"
        />
        <div className="expire-time__row">
          <Radio
            size="large"
            label={<Text size="charlie">{t('Expire at')}</Text>}
            value="true"
          />
          <div className="expire-time__expire-at">
            <DatePicker
              disabled={!value}
              error={error}
              value={timeValue}
              showTime={false}
              allowClear={false}
              onChange={(momentTime) => {
                onChange(fortmatTime(momentTime))
              }}
              mode="date"
              format={timeFormat}
              disabledDate={(current) => current.isBefore(moment())}
            />
            <Icon symbol="arrow-left" width={18} height={18} />
            <Button disabled={!value} onClick={() => onAddDays(7)}>
              7 Days
            </Button>
            <Button disabled={!value} onClick={() => onAddDays(30)}>
              30 Days
            </Button>
            <Button disabled={!value} onClick={() => onAddDays(60)}>
              60 Days
            </Button>
            <Button disabled={!value} onClick={() => onAddDays(90)}>
              90 Days
            </Button>
            <Button disabled={!value} onClick={() => onAddDays(180)}>
              180 Days
            </Button>
          </div>
        </div>
      </Radio.Group>
    </div>
  )
}

export default observer(ExpireTime)
