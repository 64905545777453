import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import Modal from '../../components/Modal'
import Table from '../../components/ResourceTable/Table'
import { Column } from '../../components/Table'
import Text from '../../components/Text'
import t from '../../utils/translate'
import { ComponentStore } from '../../stores'

function UsageModal({ closeModal, modalVisible }) {
  useEffect(() => {
    if (modalVisible) {
      ComponentStore.fetchUsage()
    }
  }, [modalVisible])

  return (
    <Modal
      visible={modalVisible}
      header={t('Usage of') + ' ' + ComponentStore.usage.componentData?.name}
      onClose={closeModal}
      width={800}
      centered={true}
      footer={null}
      mask
      className="component-editor__usage-modal"
    >
      <Table
        store={ComponentStore.usage}
        resourceName="items"
        loading={ComponentStore.usage.state === 'pending'}
        emptyText={t('No data')}
      >
        <Column
          title={t('Name (ID)')}
          width={400}
          render={(_, record) => {
            const title =
              record.languageVersions?.find((l) => l.title)?.title ||
              t('no title yet')
            return (
              <Text weight="bold" element="div">
                {title} <span className="bundle__list-id">({record.id})</span>
              </Text>
            )
          }}
        />
        <Column
          title={t('Type')}
          dataIndex="type"
          render={(type) => (
            <div className="cell-title">
              <Text weight="bold" element="span">
                {type}
              </Text>
            </div>
          )}
        />
      </Table>
    </Modal>
  )
}

export default observer(UsageModal)
