import { observer } from 'mobx-react-lite'
import Modal from '../../../components/Modal'
import Table, { Column } from '../../../components/Table'
import Text from '../../../components/Text'
import t from '../../../utils/translate'
import { RoleManagementStore, UserManagementStore } from '../../../stores'
import { toJS } from 'mobx'

function InstancesModal({ closeModal, modalVisible, email }) {
  const user = UserManagementStore.users.find((u) => u.email === email) || {}
  const mappingRole = (roles) =>
    roles
      .map((roleId) => {
        const role = RoleManagementStore.roles.find(
          (item) => item.id === roleId
        )
        return role ? role.name : ''
      })
      .filter((roleName) => roleName !== '')
      .map((roleName) => {
        return roleName
          .replace('Makaira.', '')
          .replace(`${toJS(RoleManagementStore.rolePrefix)}.`, '')
      })
  const data = Object.keys(user.instances || {}).map((key) => ({
    instance: key === '*' ? t('All instances') : key,
    roles: mappingRole(user.instances[key]),
  }))

  return (
    <Modal
      visible={modalVisible}
      header={t('Instances of') + ' ' + email}
      onClose={closeModal}
      width={800}
      centered={true}
      footer={null}
      mask
    >
      <Table data={data} emptyText={t('No data')}>
        <Column title={t('Instance')} dataIndex="instance" width={400} />
        <Column
          title={t('Roles')}
          dataIndex="roles"
          render={(roles) => (
            <div>
              <Text element="span">{roles.join(', ')}</Text>
            </div>
          )}
        />
      </Table>
    </Modal>
  )
}

export default observer(InstancesModal)
