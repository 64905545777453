import { observer } from 'mobx-react-lite'
import ReactMarkdown from 'react-markdown'

import '../markdown.css'

const MessageColumn = ({ language, message = {}, title = {} }) => {
  let markdown = ''

  if (title[language]) {
    markdown += `### ${title[language]}`
  }

  if (message[language]) {
    markdown += `\n${message[language]}`
  }
  return (
    <ReactMarkdown className="mk-table-cell__markdown markdown-body">
      {markdown}
    </ReactMarkdown>
  )
}

export default observer(MessageColumn)
