import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Switch from '../../components/Switch'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import { FilterStore, UIStore } from '../../stores'
import { formatLanguageKey, t } from '../../utils'

const TitleSection = () => {
  const filter = FilterStore.filterDetail

  return (
    <div className="filter-edit__section">
      <Text size="echo" weight="bold">
        {t('Title')}
      </Text>
      <Switch
        checked={filter.useAttributeTitle}
        type="horizontal"
        title={t(
          'Use language specific titles from imported product attributes'
        )}
        onChange={(checked) =>
          FilterStore.onChangeFilter(filter, 'useAttributeTitle', checked)
        }
      />
      <div className="filter-edit__language-grid">
        {UIStore.languages.map((language) => (
          <div key={language} className="filter-edit__language-grid-item">
            <Text>{formatLanguageKey(language)}</Text>
            <TextInput
              onChange={(e) =>
                FilterStore.onChangeFilter(
                  filter.title,
                  language,
                  e.target.value
                )
              }
              value={get(filter, ['title', language])}
              error={FilterStore.errors[`title.${language}`]}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(TitleSection)
