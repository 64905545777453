import { range } from 'lodash'
import { observer } from 'mobx-react-lite'
import AutoComplete from '../../components/AutoComplete'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Icon from '../../components/Icon'
import NumberInput from '../../components/NumberInput'
// import Select from '../../components/Select'
import SliderInput from '../../components/SliderInput'
import Text from '../../components/Text'
import { t } from '../../utils'
import SearchPageTitle from './ImporterPageTitle'
import { ConfigurationStore, SearchFieldStore, UIStore } from '../../stores'

import './searchConfiguration.styl'
import { useEffect, useState } from 'react'
import api from '../../api'
import Switch from '../../components/Switch'

const MAX_RANGE_CONFIG = 10

const SearchConfigurationV3 = () => {
  const [fields, setFields] = useState([])
  const searchDocumentTypes =
    ConfigurationStore.configuration.searchDocumentTypes || {}
  const suggestField = ConfigurationStore.configuration.suggestField
  const searchFields = SearchFieldStore.searchFields
  const documentTypes = UIStore.documentTypes

  useEffect(() => {
    const fetchField = async () => {
      try {
        const { data } = await api.fields.getFieldsByType('string')
        setFields(data)
      } catch (error) {
        console.log(error)
      }
    }

    fetchField()
    SearchFieldStore.fetchSearchFields()
    UIStore.fetchDocumentTypes()
  }, [])

  const typesInPreview = [
    'product-156',
    'category-156',
    'manufacturer-156',
    'page-196-156',
    'link-156',
    'searchredirect-156',
    'bundle-222',
  ]
  const additionalTypesInPreview = documentTypes
    .filter(
      (type) =>
        typesInPreview.findIndex((typeInpreview) =>
          typeInpreview.includes(type.id)
        ) === -1
    )
    .map((additionalTypeInPreview) => additionalTypeInPreview.id + '-156')

  return (
    <>
      <SearchPageTitle />
      <div className="content-wrapper search-configuration">
        <div>
          <Text weight="bold" size="echo">
            {t('What to include in your Search Results / Auto-Suggest')}
          </Text>
          <div className="search-configuration__row">
            <div className="search-configuration__preview-section">
              <div className="search-configuration__preview-header">
                <Text weight="bold" size="delta">
                  {t('Preview')}
                </Text>
                {/* <Select
                  translateLabel
                  options={UIStore.languages.map((language) => ({
                    value: language,
                    label: formatLanguageKey(language),
                  }))}
                  flexible
                  dropdownMatchSelectWidth={false}
                /> */}
              </div>
              <div className="search-configuration__preview-body">
                <div className="search-configuration__preview-search-input">
                  <Icon symbol="search__regular" width="15px" height="15px" />
                  <Text weight="bold">Abc</Text>
                </div>
                <div className="search-configuration__preview-components">
                  <div className="search-configuration__preview-products">
                    {range(5).map((index) => (
                      <Icon
                        height="40px"
                        width="300px"
                        key={index}
                        symbol="module-skeleton"
                        makairaIcon
                      />
                    ))}
                  </div>
                  <div className="search-configuration__preview-results">
                    {[...typesInPreview, ...additionalTypesInPreview].map(
                      (title) => {
                        const id = title.split('-')[0]
                        const docTitle = documentTypes.find(
                          (type) => type.id === id
                        )?.title
                        if (!searchDocumentTypes[id]) return null

                        return (
                          <div
                            key={title}
                            className="search-configuration__preview-result-item"
                          >
                            <Text size="charlie" weight="bold">
                              {t(docTitle)}
                            </Text>
                            <span
                              style={{ width: title.split('-')[1] + 'px' }}
                              className="search-configuration__rectangle"
                            />
                            {title.split('-')[2] && (
                              <span
                                style={{
                                  width: (title.split('-')[2] || 0) + 'px',
                                }}
                                className="search-configuration__rectangle"
                              />
                            )}
                          </div>
                        )
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="search-configuration__settings">
              <div className="search-configuration__preview-header">
                <Text weight="bold" size="delta">
                  {t('Settings')}
                </Text>
              </div>
              <div className="search-configuration__settings-wrapper">
                <div className="search-configuration__preview-body">
                  <Text variant="book">
                    {t(
                      'Which additional data should be displayed in the search result?'
                    )}
                  </Text>
                  {documentTypes.map((type) => (
                    <Checkbox
                      disabled={
                        ConfigurationStore.savingConfig || type.id === 'product'
                      }
                      checked={
                        searchDocumentTypes[type.id] || type.id === 'product'
                      }
                      key={type.id}
                      label={t(type.title)}
                      onChange={(value) =>
                        ConfigurationStore.updateConfiguration(
                          'searchDocumentTypes',
                          {
                            ...searchDocumentTypes,
                            [type.id]: value.target.checked,
                          }
                        )
                      }
                    />
                  ))}
                </div>
                <div className="search-configuration__preview-body">
                  <Text variant="book">
                    {t(
                      'From which field should the term suggestions be loaded while entering a search expression?'
                    )}
                  </Text>
                  <AutoComplete
                    allowClear
                    value={suggestField}
                    defaultOptions={fields.map((field) => ({
                      label: field.title,
                      value: field.id,
                    }))}
                    onSelect={(value) =>
                      ConfigurationStore.updateConfiguration(
                        'suggestField',
                        value
                      )
                    }
                    onChange={() => {}}
                  />
                  <Icon
                    className="search-configuration__select-skeleton"
                    symbol="module-skeleton-2"
                    makairaIcon
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search-configuration__field-section">
          <Text weight="bold" size="echo">
            {t('Where do you want to search?')}
          </Text>
          <div className="search-configuration__fields">
            <div className="search-configuration__field-header">
              <Text variant="book">{t('Active')}</Text>
              <Text variant="book">{t('Field')}</Text>
              <Text variant="book">{t('Boosting')}</Text>
            </div>
            {searchFields.map((field) => (
              <div key={field.id} className="search-configuration__field-item">
                <Switch
                  onChange={(value) =>
                    SearchFieldStore.updateField(field, 'active', value)
                  }
                  checked={field.active}
                />
                {field.id && <Text weight="bold">{field.field}</Text>}
                {!field.id && (
                  <AutoComplete
                    value={field.field}
                    defaultOptions={fields.map((f) => ({
                      label: f.title,
                      value: f.id,
                    }))}
                    onSelect={(value) =>
                      SearchFieldStore.updateField(field, 'field', value, true)
                    }
                  />
                )}
                <div className="search-configuration__field-configuration">
                  <NumberInput
                    onChange={(value) =>
                      SearchFieldStore.updateField(field, 'boostFactor', value)
                    }
                    max={MAX_RANGE_CONFIG}
                    min={0}
                    value={field.boostFactor}
                  />
                  <SliderInput
                    onChange={(value) =>
                      SearchFieldStore.updateField(field, 'boostFactor', value)
                    }
                    max={MAX_RANGE_CONFIG}
                    min={0}
                    value={field.boostFactor}
                  />
                  <Button
                    onClick={() => SearchFieldStore.delete(field.id)}
                    icon="trash"
                    variant="reduced"
                  />
                </div>
              </div>
            ))}
            <div>
              <Button
                iconPosition="left"
                className="search-configuration__add-field-btn"
                variant="tertiary"
                level={1}
                icon="plus"
                onClick={SearchFieldStore.addNewField}
              >
                {t('Add field')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(SearchConfigurationV3)
