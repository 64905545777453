import { observer } from 'mobx-react-lite'
import { without } from 'lodash'
import { Popover } from 'antd'

import Icon from '../../../../components/Icon'
import Text from '../../../../components/Text'
import ShadowScroll from '../../../../components/ShadowScroll'

import UIStore from '../../../../stores/UIStore'
import EditorStore from '../../../../stores/PageEditor/EditorStore'

import t from '../../../../utils/translate'
import formatLanguageKey from '../../../../utils/formatLanguageKey'

// Maximum number of languages (excluding the currently selected one) before a second context menu
// will be shown instead of showing all available languages in the main context menu.
const MAX_MENU_ENTRIES = 4

const ContentLanguageExtraMenu = ({ onMenuHide, languages = [], onSelect }) => {
  return (
    <div
      onClick={onMenuHide}
      className="page-editor__field-menu-action page-editor__content-language-extra-menu"
    >
      <ShadowScroll maxHeight="300px">
        {languages.map((language) => (
          <button
            key={language}
            onClick={() => {
              onMenuHide()
              onSelect(language)
            }}
          >
            <Text size="charlie">{formatLanguageKey(language)}</Text>
          </button>
        ))}
      </ShadowScroll>
    </div>
  )
}

const ContentLanguageCopySection = ({
  onMenuHide,
  visible,
  element,
  type = 'page',
}) => {
  const { languages } = UIStore
  const currentlySelectedLanguage = EditorStore.contentLanguage
  const availableLanguages = without(languages, currentlySelectedLanguage)

  const isShownInExtraMenu = availableLanguages.length > MAX_MENU_ENTRIES

  /**
   * Copy the content from the select language to the currently selected language.
   *
   * @param language {string} code of the language to copy from
   */
  const handleCopyContent = (language) => {
    if (type === 'page') {
      EditorStore.copyContentFromLanguageForPage(language)
    } else {
      EditorStore.copyContentFromLanguageForElement(language, element)
    }
  }

  if (isShownInExtraMenu) {
    return (
      <Popover
        visible={visible === false ? false : undefined}
        content={
          <ContentLanguageExtraMenu
            languages={availableLanguages}
            onMenuHide={onMenuHide}
            onSelect={handleCopyContent}
          />
        }
        placement="rightTop"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="page-editor__field-menu-item"
        >
          <Icon width="20px" height="15px" symbol="paste" />
          <span>{t('Insert content from…')}</span>
        </div>
      </Popover>
    )
  }

  return availableLanguages.map((language) => (
    <div
      key={language}
      onClick={() => handleCopyContent(language)}
      className="page-editor__field-menu-item"
    >
      <Icon width="20px" height="15px" symbol="language__solid" />
      <span>
        {t('Insert content from')} {formatLanguageKey(language)}
      </span>
    </div>
  ))
}

export default observer(ContentLanguageCopySection)
