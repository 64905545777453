import { getIn } from 'formik'
import TextInput from './index'

const FormikTextInput = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  ...rest
}) => {
  return (
    <TextInput
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={getIn(touched, name) && getIn(errors, name)}
      {...rest}
    />
  )
}

export default FormikTextInput
