import { forwardRef } from 'react'
import Text from '../Text'

const ProductRow = forwardRef(
  ({ position, product = {}, scoreShown = false }, ref) => {
    const score = product.fields ? product.fields['_score'] : undefined

    const title =
      product.fields?.title ??
      product.fields?.manufacturer_title ??
      product.fields?.category_title
    const additionelInfo = product.fields?.ean ?? product.fields?.id

    return (
      <div className="product-preview__list-row" ref={ref}>
        <div className="product-preview__list-column product-preview__position">
          <Text size="foxtrot" weight="bold">
            {position < 10 && '0'}
            {position}
          </Text>
        </div>
        <div className="product-preview__list-column product-preview__product">
          {product.fields?.picture_url_main && (
            <img src={product.fields?.picture_url_main} />
          )}

          <div className="product-preview__list-meta">
            <Text className="product-preview__product-title" weight="bold">
              {title}
            </Text>
            <div>
              <Text className="product-preview__product-info" variant="book">
                {additionelInfo}
              </Text>

              {score !== undefined && scoreShown && (
                <Text className="product-preview__product-score" variant="book">
                  {score}
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default ProductRow
