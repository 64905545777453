import classNames from 'classnames'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { toJS } from 'mobx'

import Button from '../../../components/Button'
import FormikOnChange from '../../../components/FormikOnChange'
import Sortable from '../../../components/Sortable'
import Text from '../../../components/Text'
import { FeedStore } from '../../../stores'
import { t } from '../../../utils'
import FeedField from './FeedField'

const FeedStructure = ({ formikRef }) => {
  const feed = FeedStore.feedDetail

  const feedFieldsWithUniqueId = (data) =>
    data.map((field) => ({
      ...field,
      originalId: field.id,
      id: field.uniqueId,
    }))

  const prepareSchema = () => {
    const fields = {}

    fields.id = Yup.string().required(t('This field is required.'))
    if (feed.contentType === 'csv') {
      fields.column = Yup.string().required(t('This field is required.'))
    }

    return Yup.object().shape({
      fields: Yup.array().of(Yup.object(fields)),
    })
  }
  const schema = prepareSchema()

  const handleSubmit = ({ fields }) => {
    FeedStore.setFeed({ ...FeedStore.feedDetail, fields })
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        fields: toJS(feed.fields),
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <>
          <FormikOnChange onChange={handleSubmit} />
          <div className="feeds__structure">
            <Text weight="bold" size="echo">
              {t('Feed Structure')}
            </Text>
            <div className="feeds__structure-inner">
              {feed.fields && feed.fields.length > 0 && (
                <>
                  <div
                    className={classNames(
                      'feeds__structure-row feeds__structure-row--header',
                      {
                        'feeds__structure-row--csv': feed.contentType === 'csv',
                      }
                    )}
                  >
                    <div></div>
                    {feed.contentType === 'csv' && (
                      <Text>{t('CSV-Header')}</Text>
                    )}
                    <Text>{t('Product/Variant field')}</Text>
                    <Text>{t('Your code')}</Text>
                    <div></div>
                  </div>
                  <Sortable
                    items={feedFieldsWithUniqueId(formikProps.values.fields)}
                    onChange={(fields) => {
                      formikProps.setFieldValue(
                        'fields',
                        // get correct id for field
                        fields.map(({ originalId, ...rest }) => ({
                          ...rest,
                          id: originalId,
                        }))
                      )
                    }}
                    itemComponent={FeedField}
                  />
                </>
              )}
              <div className="feeds__structure-footer">
                <Button
                  onClick={() =>
                    formikProps.setFieldValue('fields', [
                      ...formikProps.values.fields,
                      {
                        column: '',
                        uniqueId: uuidv4(),
                        title: undefined,
                        template: '{{ item }}',
                        id: undefined,
                      },
                    ])
                  }
                  iconPosition="left"
                  icon="plus"
                  level={1}
                >
                  {t('Add field')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  )
}

export default observer(FeedStructure)
