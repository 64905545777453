import { observer } from 'mobx-react-lite'
import UIStore from '../../stores/UIStore'
import SmartBundleStore from '../../stores/SmartBundleStore'
import Text from '../../components/Text'
import Button from '../../components/Button'
import t from '../../utils/translate'
import SlotRequired from './SlotRequired'
import SlotConnection from './SlotConnection'
import classNames from 'classnames'
import Container from '../../components/Container'

function AddButton(props) {
  const { id } = props

  function handleClick() {
    if (id) {
      SmartBundleStore.editSlot(props)
    } else {
      SmartBundleStore.addSlot()
    }
  }

  return (
    <div className="bundle__add-stream">
      <Button variant="tertiary" icon="plus" onClick={handleClick} />

      {t('Add Slot')}
    </div>
  )
}

function EditButton(props) {
  const lang = UIStore.currentLanguage
  // It is possible that the store languages do not contain the UI language.
  // In that case we display the first language as a fallback.
  const isUILanguageAvailable = UIStore.languages.includes(lang)
  const { title = {} } = props

  return (
    <div
      className="bundle__edit-stream"
      onClick={() => SmartBundleStore.editSlot(props)}
    >
      <Text element="p" className="bundle__slot-label">
        {t('Products')}
      </Text>
      <Text element="p" weight="bold" className="bundle__slot-title">
        {isUILanguageAvailable ? title[lang] : title[Object.keys(title)[0]]}
      </Text>

      <Button
        className="bundle__slot-edit-button"
        variant="tertiary"
        icon="sliders-up"
      />
    </div>
  )
}

function SlotConfiguration(props) {
  const { slot = {} } = props
  const { id } = slot

  const slotPosition = SmartBundleStore.getSlotPosition(slot)

  // A new slot does not have any title configured yet
  const isEmptySlot = !id
  const isFirstSlot = slotPosition === 1

  return (
    <div className="bundle__slot-wrapper">
      {!isFirstSlot && <SlotRequired {...slot} />}

      <div
        className={classNames('bundle__slot-data', {
          'bundle__slot-data--empty': isEmptySlot,
        })}
      >
        <Text
          className="bundle__slot-index"
          size="charlie"
          weight="light"
          variant="book"
        >
          {(!isEmptySlot || isFirstSlot) && `${t('Slot')} ${slotPosition}`}
        </Text>

        <Container
          className={classNames('bundle__slot', {
            'bundle__slot--empty': isEmptySlot && !isFirstSlot,
          })}
          level={-1}
          padding="l"
          paddingBottom="s"
        >
          {isEmptySlot ? (
            <AddButton {...slot} />
          ) : (
            <EditButton slotPosition={slotPosition} {...slot} />
          )}

          {!isEmptySlot && <SlotConnection {...slot} />}
        </Container>
      </div>
    </div>
  )
}

export default observer(SlotConfiguration)
