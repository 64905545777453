import t from '../../../../utils/translate'

export const SEPARATOR_OPTIONS = [
  {
    label: t('No value'),
    value: 'none',
  },
  {
    label: t('Comma (,)'),
    value: 'comma',
  },
  {
    label: 'Semicolon (;)',
    value: 'semi',
  },
  {
    label: 'Pipe (|)',
    value: 'pipe',
  },
  {
    label: 'Tabstop (\\t)',
    value: 'tab',
  },
]

export const SEPARATOR_VALUE = {
  none: ' ',
  comma: ',',
  semi: ';',
  pipe: '|',
  tab: '\\t',
}

export const WRAPPER_OPTIONS = [
  {
    label: t('No value'),
    value: 'none',
  },
  {
    label: t('Single quotes'),
    value: 'singleQuotes',
  },
  {
    label: t('Double quotes'),
    value: 'doubleQuotes',
  },
]

export const LINE_END_OPTIONS = [
  {
    label: t('No value'),
    value: '',
  },
  {
    label: 'Windows CR+LF',
    value: '\\r\\n',
  },
  {
    label: 'Unix LF',
    value: '\\n',
  },
  {
    label: 'Macintosh CR',
    value: '\\r',
  },
]
