import { Tooltip } from 'antd'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'

import Icon from '../../../components/Icon'
import ToggleVisibilityButton from './ToggleVisibilityButton'

import EditorStore from '../../../stores/PageEditor/EditorStore'
import PageEditorPreviewStore from '../../../stores/PageEditor/PageEditorPreviewStore'

import { buildClientURL } from '../../../utils'
import t from '../../../utils/translate'
import Select from '../../../components/Select'
import { UIStore } from '../../../stores'
import DatePicker from '../../../components/DatePicker'

const AVAILABLE_SIZES = [320, 414, 600, 768, 1024, 1280, 1440]

const PreviewActions = () => {
  const {
    pageToEdit,
    publishedURLPath,
    publishConfig,
    getDefaultPublishConfig,
  } = EditorStore
  const {
    selectedSize,
    setSelectedSize,
    previewShown,
    previewPersona,
    setPreviewPersona,
    setPreviewTime,
    previewTime,
  } = PageEditorPreviewStore
  const { currentLanguage } = UIStore

  const openPreviewPopup = (e) => {
    e.preventDefault()
    if (!EditorStore.previewPopup || EditorStore.previewPopup.closed) {
      const popup = window.open(
        window.location.href + '?popup=true',
        'popup',
        'width=600,height=600'
      )
      EditorStore.setPreviewPopup(popup)
    } else {
      EditorStore.previewPopup.focus()
    }
  }

  const storefrontLink = buildClientURL(publishedURLPath)
  const defaultPublicConfig = getDefaultPublishConfig()
  const publicOptions = [
    {
      value: defaultPublicConfig.identifier,
      label: defaultPublicConfig.name,
    },
    ...publishConfig.map((config) => ({
      value: config.identifier,
      label: config.name[currentLanguage],
    })),
  ]

  return (
    <>
      <div className="editor-preview__sizes">
        <ToggleVisibilityButton />
        {previewShown && (
          <>
            {AVAILABLE_SIZES.map((size) => (
              <button
                className={classnames('editor-preview__size', {
                  'editor-preview__size--active': size === selectedSize,
                })}
                onClick={() => setSelectedSize(size)}
                key={size}
              >
                {size}
              </button>
            ))}
          </>
        )}
        <Tooltip placement="topRight" title={t('Open Preview in new window')}>
          <a
            onClick={openPreviewPopup}
            href={window.location.href + '?popup=true'}
            rel="noreferrer"
            target="popup"
            className="editor-preview__size"
          >
            <Icon
              width="15px"
              height="13px"
              symbol="arrow-up-right-from-square__solid"
            />
          </a>
        </Tooltip>
        {pageToEdit.type !== 'snippet' && publishedURLPath && (
          <Tooltip placement="topRight" title={t('Open published version')}>
            <a
              href={storefrontLink}
              rel="noreferrer"
              target="_blank"
              className="editor-preview__size"
            >
              <Icon width="15px" height="13px" symbol="globe" />
            </a>
          </Tooltip>
        )}
      </div>
      {previewShown && (
        <div className="editor-preview__settings">
          <Select
            value={previewPersona}
            onChange={setPreviewPersona}
            options={publicOptions}
          />
          <DatePicker showTime onChange={setPreviewTime} value={previewTime} />
        </div>
      )}
    </>
  )
}

export default observer(PreviewActions)
