import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import ContentWrapper from '../../components/ContentWrapper'
import FormikOnChange from '../../components/FormikOnChange'
import LoadingScreen from '../../components/LoadingScreen'
import PageTitle from '../../components/PageTitle'
import FilterSection from '../../components/FilterSection'
import UpdateStoreDirtyState from '../../components/UpdateStoreDirtyState'
import {
  ActionLayerStore,
  CategoryStore,
  FieldStore,
  RecommendationStore,
  UIStore,
  FilterSectionStore,
} from '../../stores'
import ABTestStore from '../../stores/ABTestStore'
import { createRedirectPath, t } from '../../utils'
import BasicSection from './components/BasicSection'
import DisplaySection from './components/DisplaySection'
import PreviewSection from './components/PreviewSection'
import SortSection from './components/SortSection'

import './style.styl'

const EditRecommendation = () => {
  const formikRef = useRef()
  const { id } = useParams()
  const isEditView = id !== 'new'
  const history = useHistory()
  const { isDirty, onChangeField } = RecommendationStore
  const schema = Yup.object().shape({
    name: Yup.string().required(),
    recommendationId: Yup.string().required(),
  })
  const reco = RecommendationStore.currentRecoDetail
  const defaultLanguage = UIStore.enterpriseConfiguration.defaultLanguage
  const isRunningTest =
    ABTestStore.running &&
    ABTestStore.running.find((test) => test.recommendationId === parseInt(id))
  const { selectedVariant, showMetaconfigView, testDetail } = ABTestStore
  const isDefaultVariant = selectedVariant === 'A' && showMetaconfigView
  const isDraftingTest = !testDetail?.running && !testDetail?.finished
  const disableEdit =
    ABTestStore.showMetaconfigView && (isDefaultVariant || !isDraftingTest)

  useEffect(() => {
    if (isRunningTest && !ABTestStore.showMetaconfigView) {
      const newURL = createRedirectPath(
        `/recommendations/${id}?ab-test=${isRunningTest.id}&ab-variant=A`
      )
      history.replace(newURL)
    }
    // eslint-disable-next-line
  }, [isRunningTest])

  useEffect(() => {
    RecommendationStore.fetchRecoDetail(id)
  }, [id])

  useEffect(() => {
    RecommendationStore.onChangePreviewLanguage(defaultLanguage, false)
    UIStore.fetchProductGroups(defaultLanguage)
  }, [defaultLanguage])

  useEffect(() => {
    FieldStore.fetchFields()
    CategoryStore.setPagination({ pageSize: 1000 })
    FilterSectionStore.fetchManufacturers()
  }, [])

  function handleClose() {
    RecommendationStore.setDirty(false)
    history.push(createRedirectPath('/recommendations'))
  }

  async function handleSaveAndStay() {
    await formikRef.current?.submitForm()

    if (formikRef.current !== undefined && !formikRef.current?.isValid) {
      return false
    }

    const newId = await RecommendationStore.updateOrCreate()

    // We need to redirect to the correct URL if the page was created
    if (newId) {
      history.push(createRedirectPath(`/recommendations/${newId}`))
    }
  }

  useEffect(() => {
    if (isDirty && !ABTestStore.showMetaconfigView) {
      ActionLayerStore.openActionLayer({
        onSave: handleSaveAndStay,
        onClose: handleClose,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  const handleSubmit = (values) =>
    RecommendationStore.setRecoDetail(reco, values)

  if (
    RecommendationStore.isFetchingReco ||
    (ABTestStore.showMetaconfigView && ABTestStore.isFetchingVariantionConfig)
  )
    return <LoadingScreen />

  return (
    <>
      <PageTitle
        prefix={isEditView ? t('You are editing') : t('You are creating')}
        metaInfo={
          isEditView && {
            timestamp: reco.changed?.date,
            timezone: reco.changed?.timezone,
            user: reco.user,
            id: reco.id,
          }
        }
        hiddenFields={['internal-title']}
      >
        {isEditView ? reco.name : t('A new recommendation')}
      </PageTitle>
      <ContentWrapper className="recommendations">
        <div className="recommendations__edit-wrapper">
          <Formik
            key={ABTestStore.selectedVariant}
            innerRef={formikRef}
            initialValues={{
              recommendationId: reco.recommendationId,
              name: reco.name,
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {(formikProps) => (
              <>
                <UpdateStoreDirtyState store={RecommendationStore} />
                <FormikOnChange onChange={handleSubmit} />
                <div className="recommendations__edit">
                  <BasicSection
                    formikProps={formikProps}
                    disableEdit={disableEdit}
                  />
                  <FilterSection
                    type="filter"
                    title={t('Filter products')}
                    buttonText={t('Add filter')}
                    hasInputProduct={true}
                    defaultValue={reco?.filter}
                    onChange={(filters) =>
                      onChangeField(reco, 'filter', filters)
                    }
                    disableEdit={disableEdit}
                  />

                  <SortSection disableEdit={disableEdit} />
                  <DisplaySection disableEdit={disableEdit} />
                </div>
              </>
            )}
          </Formik>
          <PreviewSection />
        </div>
      </ContentWrapper>
    </>
  )
}

export default observer(EditRecommendation)
