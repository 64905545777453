import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import DisabledFeature from '../../components/DisabledFeature'
import LoadingScreen from '../../components/LoadingScreen'
import { ActionLayerStore } from '../../stores'
import StorefrontSettingStore from '../../stores/StorefrontSettingStore'
import RemoveAuthenticationModal from './components/RemoveAuthenticationModal'
import Authentication from './settingSections/Authentication'
import Builds from './settingSections/Builds'
import Domains from './settingSections/Domains'
import Variables from './settingSections/Variables'
import StoreFrontPageTitle from './components/StoreFrontPageTitle'

import './storefrontSettings.styl'
import { t } from '../../utils'

function Settings() {
  const { isDirty, isValidConfigVars } = StorefrontSettingStore

  useEffect(() => {
    // dont fetch all api if it is externalStorefront or no storefront
    async function init() {
      const data = await StorefrontSettingStore.fetchDeployments()
      if (data.status !== 404 && !data.externalStorefront) {
        StorefrontSettingStore.fetchConfigVars()
        StorefrontSettingStore.fetchBranches()
        StorefrontSettingStore.fetchDomains()
      }
    }

    init()
  }, [])

  useEffect(() => {
    if (isDirty && isValidConfigVars) {
      ActionLayerStore.openActionLayer({
        onSave: StorefrontSettingStore.updateConfigVars,
        onClose: StorefrontSettingStore.resetConfigVars,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
  }, [isDirty, isValidConfigVars])

  if (StorefrontSettingStore.state === 'pending')
    return (
      <>
        <StoreFrontPageTitle />
        <LoadingScreen withPageTitle />
      </>
    )

  if (StorefrontSettingStore.deployments.externalStorefront) {
    return (
      <>
        <StoreFrontPageTitle />
        <DisabledFeature
          requireFeature="externalStorefront"
          showContactInfo={false}
        />
      </>
    )
  }

  if (StorefrontSettingStore.error.status) {
    return (
      <>
        <StoreFrontPageTitle />
        <DisabledFeature
          requireFeature="noStorefront"
          content={
            StorefrontSettingStore.error.status !== 404
              ? t(`errorStorefrontDescription`)(
                  StorefrontSettingStore.error.errorId
                )
              : undefined
          }
          showContactInfo={false}
        />
      </>
    )
  }

  return (
    <>
      <StoreFrontPageTitle />
      <div className="content-wrapper storefront-settings">
        <Builds />
        <Variables />
        <Authentication />
        <Domains />
      </div>
      <RemoveAuthenticationModal />
    </>
  )
}

export default observer(Settings)
