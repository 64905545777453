import { Tooltip } from 'antd'
import Icon from '../../components/Icon'

import Text from '../../components/Text'
import { t } from '../../utils'

const LanguageContent = ({
  text,
  link,
  language,
  linked,
  setOpenEdit = () => {},
}) => {
  return (
    <div
      className="menu-editor-node__content"
      onClick={() => setOpenEdit(true)}
    >
      <Text className="menu-editor-node__language-symbol" variant="book">
        {language.toUpperCase()}
      </Text>
      <Text title={text || ''} className="menu-editor-node__title">
        {linked && (
          <Tooltip title={t('Prefilled menu entry')} align={{ offset: [0, 3] }}>
            <Icon symbol="link__solid" width={20} height={14} />
          </Tooltip>
        )}
        {text || t('No title yet')}
      </Text>
      <div className="menu-editor-node__link">
        <Icon symbol="globe" width={20} height={16} />
        <Text title={link || ''} variant="book">
          {link || t('No link yet')}
        </Text>
      </div>
    </div>
  )
}

export default LanguageContent
