import { Tooltip } from 'antd'
import classNames from 'classnames'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import Spinner from '../../components/Spinner'
import Text from '../../components/Text'
import DashboardStore from '../../stores/DashboardStore'
import { createRedirectPath, currencySymbol, t } from '../../utils'

const KPIStats = ({
  value,
  state,
  name,
  isAbtesting,
  noTrackingAvailable,
  isRunningExperiment,
  runningExperimentId,
  dateRange,
  isFetching,
}) => {
  const experimentUrl =
    (process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : window.location.origin) +
    '/#/' +
    `experiment/${runningExperimentId}/detail`

  const onClick = () => {
    if (isAbtesting) {
      window.open(experimentUrl, '_self')
    }
  }

  if (isFetching)
    return (
      <div className="dashboard__kpi-stats">
        <Spinner />
      </div>
    )

  return (
    <div
      onClick={onClick}
      className={classNames(
        'dashboard__kpi-stats',
        `dashboard__kpi-stats--${isAbtesting ? 'ab-' : ''}${state}`
      )}
    >
      <div className="dashboard__kpi-stats-number">
        <Text size="echo" weight="medium">
          {value}
        </Text>
        {state === 'positive' && (
          <Icon symbol="caret-up-solid" width={15} height={15} />
        )}
        {state === 'negative' && (
          <Icon symbol="caret-down-solid" width={15} height={15} />
        )}
      </div>
      <Text
        className="dashboard__kpi-stats-title"
        size="charlie"
        weight="medium"
      >
        {name}
      </Text>
      {noTrackingAvailable && (
        <Text weight="book">{t('no Tracking available')}</Text>
      )}
      {!isAbtesting && !noTrackingAvailable && (
        <Tooltip title={dateRange}>
          <Text>{t('in the last 7 days')}</Text>
        </Tooltip>
      )}
      {isAbtesting && isRunningExperiment && (
        <Text weight="book">
          {state === 'positive' && t('more revenue')}
          {state === 'negative' && t('less revenue')}
        </Text>
      )}
      {isAbtesting && !isRunningExperiment && (
        <Text>{t('no test running')}</Text>
      )}
    </div>
  )
}

const KPISection = () => {
  const history = useHistory()
  const statistics = DashboardStore.statistics

  useEffect(() => {
    DashboardStore.fetchRunningExperiment()
    DashboardStore.fetchStatistics()
  }, [])

  const dateRangeFormat = () => {
    const startDate = get(statistics, ['current', 'start_date', 'date'])
    const endDate = get(statistics, ['current', 'end_date', 'date'])

    if (!startDate || !endDate) return

    let formattedStartDate = moment.utc(startDate).local()
    let formattedEndDate = moment.utc(endDate).local()

    return (
      formattedStartDate.format('DD.MM.yyyy') +
      ' - ' +
      formattedEndDate.format('DD.MM.yyyy')
    )
  }

  const getState = (name) => {
    const current = get(statistics, ['current', name])
    const comparison = get(statistics, ['comparison_period', name])
    if (current > comparison) return 'positive'
    if (current < comparison) return 'negative'
  }

  const getAbtestingState = () => {
    const detectedEffect = parseFloat(DashboardStore.detectedEffect)
    if (detectedEffect > 0) return 'positive'
    if (detectedEffect < 0) return 'negative'

    return 'no-tracking'
  }

  const dateRange = dateRangeFormat()
  const currency = currencySymbol[statistics.currency] || '€'

  return (
    <div className="dashboard__kpi">
      <Text
        className="dashboard__kpi-beta-label"
        size="bravo"
        weight="book"
        element="p"
      >
        {t('kpi_beta_label')}
      </Text>

      <div className="dashboard__kpi-wrapper">
        <KPIStats
          value={new Intl.NumberFormat().format(
            statistics?.current?.orders || 0
          )}
          state={getState('orders')}
          name={t('Orders')}
          noTrackingAvailable={DashboardStore.noTrackingAvailable}
          dateRange={dateRange}
          isFetching={DashboardStore.isFetchingStatistics}
        />
        <KPIStats
          value={`${currency} ${new Intl.NumberFormat().format(
            statistics?.current?.avg_order_revenue || 0
          )}`}
          state={getState('avg_order_revenue')}
          name={`Ø ${t('Order value')}`}
          noTrackingAvailable={DashboardStore.noTrackingAvailable}
          dateRange={dateRange}
          isFetching={DashboardStore.isFetchingStatistics}
        />
        {DashboardStore.noTrackingAvailable &&
          !DashboardStore.isFetchingStatistics && (
            <div className="dashboard__kpi-stats dashboard__kpi-stats--no-tracking">
              <Text size="charlie" weight="medium">
                {t('Install our tracking to display KPI')}
              </Text>
              <Button
                onClick={() =>
                  window.open('https://docs.makaira.io/docs/tracking')
                }
                variant="primary"
                icon="chevron-right"
              >
                <Text>{t('Open documentation')}</Text>
              </Button>
              <Button
                onClick={() =>
                  history.push(createRedirectPath('/personalization'))
                }
                variant="primary"
                icon="chevron-right"
              >
                <Text>{t('Verify Tracking')}</Text>
              </Button>
            </div>
          )}
        {(!DashboardStore.noTrackingAvailable ||
          DashboardStore.isFetchingStatistics) && (
          <>
            <KPIStats
              value={`${statistics?.current?.conversion_rate || 0} %`}
              state={getState('conversion_rate')}
              name={t('Conversion rate')}
              dateRange={dateRange}
              isFetching={DashboardStore.isFetchingStatistics}
            />
            <KPIStats
              value={new Intl.NumberFormat().format(
                statistics?.current?.visits || 0
              )}
              state={getState('visits')}
              name={t('Visitors')}
              dateRange={dateRange}
              isFetching={DashboardStore.isFetchingStatistics}
            />
          </>
        )}
        <KPIStats
          value={`${
            !DashboardStore.isRunningExperiment
              ? '-'
              : DashboardStore.detectedEffect
          } %`}
          state={getAbtestingState()}
          name={t('A/B Performance')}
          isAbtesting
          isRunningExperiment={DashboardStore.isRunningExperiment}
          runningExperimentId={DashboardStore.runningExperimentId}
          isFetching={DashboardStore.isFetchingExperiment}
        />
      </div>
    </div>
  )
}

export default observer(KPISection)
