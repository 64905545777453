import { useEffect, useState } from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import { observer } from 'mobx-react-lite'
import { isArray, differenceBy } from 'lodash'

import TextInput from '../TextInput'
import ShadowScroll from '../ShadowScroll'
import Button from '../Button'
import Checkbox from '../Checkbox'
import Text from '../Text'
import Icon from '../Icon'

import SortableItem from './SortableItem'

import api from '../../api'
import t from '../../utils/translate'
import EditorStore from '../../stores/PageEditor/EditorStore'

import './documentInput.styl'

const DocumentInput = (props) => {
  const { onChange } = props

  const [searchDocuments, setSearchDocuments] = useState([])
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [outputDocuments, setOutputDocuments] = useState(
    props.value?.items || []
  )
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    onChange({
      ...props.value,
      items: outputDocuments,
      resolveDocuments: outputDocuments?.length > 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputDocuments])

  useEffect(() => {
    if (props.value && props.value.items && props.value.items.length) {
      setOutputDocuments(props.value.items)
    }
  }, [props.value])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleSearch = debounce(async (e) => {
    setSearchValue(e.target.value)
    await searchForDocuments(e.target.value)
  }, 500)

  const searchForDocuments = async (
    searchTerm,
    removedId = '',
    removedAll = false
  ) => {
    const { data } = await api.common.getDocumentsByQuery({
      type: props.documentType,
      query: searchTerm,
      language: EditorStore.contentLanguage,
    })

    const outputDocumentIds = outputDocuments
      .filter((doc) => doc.id !== removedId && !removedAll)
      .map((doc) => doc.id)

    // We need to map id to the es_id because this is the id that we need to save.
    // After that we remove all documents from the search that are already part of the output documents.
    const documentsWithoutOutput = data
      .map((doc) => ({ ...doc, id: doc.es_id }))
      .filter((doc) => !outputDocumentIds.includes(doc.id))

    setSearchDocuments(documentsWithoutOutput)
    setSelectedDocuments([])
  }

  const handleSelect = (product) => (e) => {
    e.stopPropagation()
    const isSelected =
      selectedDocuments.find((item) => item.id === product.id) !== undefined
    if (isSelected) {
      setSelectedDocuments((selectedProduct) =>
        selectedProduct.filter((item) => item.id !== product.id)
      )
    } else {
      setSelectedDocuments((selectedProduct) => [...selectedProduct, product])
    }
  }

  const addToOutputProduct = () => {
    const productsNotOnOutput = differenceBy(
      selectedDocuments,
      outputDocuments,
      (item) => item.id
    )
    setOutputDocuments((outputProduct) => [
      ...outputProduct,
      ...productsNotOnOutput,
    ])

    // clear the selection and don't show chosen documents in the search list
    const outputDocumentIds = [...outputDocuments, ...productsNotOnOutput].map(
      (doc) => doc.id
    )
    setSearchDocuments(
      searchDocuments.filter((doc) => !outputDocumentIds.includes(doc.id))
    )
    setSelectedDocuments([])
  }

  const handleDragEnd = (e) => {
    const { active, over } = e

    if (active.id !== over.id) {
      const oldIndex = outputDocuments.findIndex(
        (product) => product.id === active.id
      )
      const newIndex = outputDocuments.findIndex(
        (product) => product.id === over.id
      )
      setOutputDocuments(arrayMove(outputDocuments, oldIndex, newIndex))
    }
  }

  const handleRemoveItem = (id) => async () => {
    setOutputDocuments((products) =>
      products.filter((product) => product.id !== id)
    )
    await searchForDocuments(searchValue, id)
  }

  const handleDiscardAll = async () => {
    setOutputDocuments([])
    await searchForDocuments(searchValue, '', true)
  }

  return (
    <div className="document-input">
      <div className="document-input__search">
        <TextInput
          rounded
          icon="magnifying-glass"
          label={
            <Text size="bravo" className="document-input__label">
              {t('What would you like to put on the list?')}
            </Text>
          }
          onChange={handleSearch}
        />
      </div>
      <div className="document-input__selection">
        <div className="document-input__product-list">
          <Text size="bravo" className="document-input__label">
            {t(`${props.documentType}_plural`)}
          </Text>
          <div className="document-input__product-wrapper">
            <div className="document-input__products">
              <ShadowScroll maxHeight="250px">
                {searchDocuments.map((product) => {
                  const isSelected =
                    selectedDocuments.find((item) => item.id === product.id) !==
                    undefined
                  return (
                    <div
                      key={product.id}
                      onClick={handleSelect(product)}
                      className={classNames('document-input__product-item', {
                        'document-input__product-item--selected': isSelected,
                      })}
                    >
                      <Checkbox
                        onChange={handleSelect(product)}
                        checked={isSelected}
                      />
                      <Text
                        className="document-input__product-name"
                        element="p"
                        size="bravo"
                        title={product.title}
                      >
                        {product.active === false && (
                          <Icon
                            symbol="triangle-exclamation"
                            width="15px"
                            height="15px"
                          />
                        )}
                        {product.title || t('No title yet')}
                      </Text>
                      <Text
                        variant="book"
                        size="bravo"
                        className="document-input__product-subtitle"
                      >
                        {(isArray(product.subtitle)
                          ? product.subtitle[0]
                          : product.subtitle) || product.es_id}
                      </Text>
                    </div>
                  )
                })}
              </ShadowScroll>
            </div>
            <hr />
            <div className="document-input__bottom-actions document-input__bottom-actions--search">
              <div className="document-input__bulk-select">
                {searchDocuments.length > 0 && (
                  <Checkbox
                    onChange={() =>
                      setSelectedDocuments(
                        searchDocuments.length !== selectedDocuments.length
                          ? searchDocuments
                          : []
                      )
                    }
                    checked={
                      searchDocuments.length === selectedDocuments.length
                    }
                    label={t('Select all')}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="document-input__select-btn-wrapper">
          <Button
            onClick={addToOutputProduct}
            variant="secondary"
            icon="chevron-right"
          />
        </div>
        <div className="document-input__product-list">
          <Text size="bravo" className="document-input__label">
            {`${t('Selected')} ${t(`${props.documentType}_plural`)}`}
          </Text>
          <div className="document-input__product-wrapper">
            <div className="document-input__products">
              <ShadowScroll maxHeight="250px">
                <DndContext
                  modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                  onDragEnd={handleDragEnd}
                  sensors={sensors}
                  collisionDetection={closestCenter}
                >
                  <SortableContext
                    items={outputDocuments}
                    strategy={verticalListSortingStrategy}
                  >
                    {outputDocuments.map((product) => (
                      <SortableItem
                        handleRemoveItem={handleRemoveItem(product.id)}
                        key={product.id}
                        field={product}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              </ShadowScroll>
            </div>
            <hr />
            <div className="document-input__bottom-actions">
              {outputDocuments.length > 0 && (
                <div
                  onClick={handleDiscardAll}
                  className="document-input__bulk-select"
                >
                  <div>{t('Discard all')}</div>
                  <Icon symbol="times" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(DocumentInput)
