import React from 'react'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import t from '../../../utils/translate'
import Icon from '../../../components/Icon'
import { ImporterStore } from '../../../stores'
import Button from '../../../components/Button'
import Text from '../../../components/Text'
import classNames from 'classnames'

const ActivateButton = ({ onActivate }) => {
  return (
    <Button
      variant="tertiary"
      onClick={onActivate}
      icon="check"
      iconPosition="left"
    >
      <Text variant="book">{t('Use as live index')}</Text>
    </Button>
  )
}

const DeleteButton = ({ onDeleteIndex }) => {
  return (
    <Button
      variant="tertiary"
      onClick={onDeleteIndex}
      icon="trash-alt"
      iconPosition="left"
    >
      <Text variant="book">{t('Delete')}</Text>
    </Button>
  )
}

const StatusLabelColumn = ({
  name,
  state,
  status,
  language,
  onActive,
  onDelete,
  defaultLabel,
  hideMessage,
  indexActivation,
  isDetail = true,
}) => {
  const { getRunningStatus } = ImporterStore
  const isActive = state === 'active'
  const isPassive = state === 'passive'
  const isYellow = status === 'yellow'

  const runningStatus = getRunningStatus(language)

  if (isActive) {
    return (
      <div className="mk-table-cell__cell-status">
        <Text className="mk-table-cell__label mk-table-cell__label--green">
          {t('live')}
        </Text>
      </div>
    )
  }

  if (
    (isPassive && runningStatus?.current?.status === 'Running') ||
    (!isDetail && isPassive && runningStatus?.current?.status === 'Waiting')
  ) {
    return (
      <div className="mk-table-cell__cell-status">
        <Text
          className={classNames('mk-table-cell__label', {
            'mk-table-cell__label--yellow':
              runningStatus?.current?.status === 'Waiting',
          })}
        >
          {runningStatus?.current?.status === 'Running'
            ? t('Rebuilding')
            : t('Queued & Waiting')}
        </Text>
        {runningStatus?.current?.autoSwitch && !hideMessage && (
          <Tooltip
            title={t(
              'once the rebuilding process is finished this index will be auto-activated as the live index'
            )}
          >
            <Text className="mk-table-cell__cell-status--text">
              <Icon symbol="circle-info" width={16} height={16} />
            </Text>
          </Tooltip>
        )}
      </div>
    )
  }

  if (
    isPassive &&
    (runningStatus?.current?.status === 'Pending' ||
      runningStatus?.current?.status === 'Terminating')
  ) {
    return (
      <div className="mk-table-cell__cell-status">
        <Text className="mk-table-cell__label mk-table-cell__label--yellow">
          {isDetail ? t(runningStatus?.current?.status) : t('Queued & Waiting')}
        </Text>
      </div>
    )
  }

  if (isYellow && (isPassive || indexActivation)) {
    return (
      <div className="mk-table-cell__cell-status">
        <Text className="mk-table-cell__label mk-table-cell__label--yellow">
          {t('activating')}: {indexActivation ? t(indexActivation) : ''}
        </Text>
      </div>
    )
  }

  if (isDetail && isPassive && runningStatus?.current?.status === 'Waiting') {
    return (
      <div className="mk-table-cell__cell-status">
        <Text className="mk-table-cell__label mk-table-cell__label--yellow">
          {t('Waiting')}
        </Text>
      </div>
    )
  }

  if (defaultLabel !== undefined) {
    return defaultLabel
  }

  return (
    <div className="mk-table-cell__cell-status">
      <Text>{t('archived')}</Text>
      {isPassive && (
        <div className="mk-table-cell__row-actions">
          <ActivateButton onActivate={() => onActive(name)} />
          <DeleteButton onDeleteIndex={() => onDelete(name)} />
        </div>
      )}
    </div>
  )
}

export default observer(StatusLabelColumn)
