import TextInput from '../../TextInput'

export default function TextOperator(props) {
  const { isVisible, config, name, value, formikProps } = props
  const { handleChange, handleBlur } = formikProps

  if (!isVisible) return null

  if (!config.showInput) return null

  return (
    <TextInput
      className="field-conditions__text-input"
      style={{ marginBottom: 0 }}
      type="text"
      name={name}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
    />
  )
}
