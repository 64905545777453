import { observer } from 'mobx-react-lite'
import ABTestStore from '../../../stores/ABTestStore'
import { t } from '../../../utils'
import Radio from '../../Radio'
import Select from '../../Select'
import Text from '../../Text'

const ConfigStop = ({ disabled }) => {
  const { editingExperiment } = ABTestStore

  return (
    <div className="ab-metaconfig__config-stop">
      <Text
        size="delta"
        weight="bold"
        className="ab-metaconfig__traffic-distribution-label"
      >
        {t('Stop the test…')}
      </Text>
      <div className="ab-metaconfig__radio-group">
        <Radio.Group
          onChange={(value) => {
            ABTestStore.updateEditingExperiment('manualStop', value === '1')
          }}
          value={editingExperiment.manualStop ? '1' : '0'}
        >
          <Radio
            disabled={disabled}
            size="large"
            labelClickable={false}
            label={
              <div className="ab-metaconfig__text-with-select">
                <Text size="charlie">{t('… at')} </Text>
                <Select
                  disabled={disabled}
                  options={[
                    { label: '70%', value: '70' },
                    { label: '80%', value: '80' },
                    { label: '90%', value: '90' },
                  ]}
                  placeholder="..%"
                  value={editingExperiment.stoppingThreshold?.toString()}
                  onChange={(value) => {
                    ABTestStore.updateEditingExperiment(
                      'stoppingThreshold',
                      parseInt(value)
                    )
                    ABTestStore.updateEditingExperiment('manualStop', false)
                  }}
                />
                <Text size="charlie">{t('statistical relevance on')} </Text>
                <Select
                  disabled={disabled}
                  className="ab-metaconfig__metric-select"
                  options={ABTestStore.supportedMetrics}
                  value={editingExperiment.stoppingMetric}
                  onChange={(value) => {
                    ABTestStore.updateEditingExperiment('stoppingMetric', value)
                    ABTestStore.updateEditingExperiment('manualStop', false)
                  }}
                />
              </div>
            }
            value="0"
          />
          <Radio
            size="large"
            label={<Text size="charlie">{t('… manually')} </Text>}
            value="1"
            disabled={disabled}
          />
        </Radio.Group>
      </div>
    </div>
  )
}

export default observer(ConfigStop)
