import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import ABTestStore from '../../stores/ABTestStore'
import LoadingScreen from '../LoadingScreen'
import ConfigHeader from './components/ConfigHeader'
import CreateHeader from './components/CreateHeader'

import './style.styl'

const ABMetaInformation = ({ children }) => {
  const location = useLocation()
  const [isFetching, setFetching] = useState(false)
  const isCreateView = location.pathname.endsWith('/ab-testing/new')
  const searchParams = new URLSearchParams(location.search)
  const abTestId = searchParams.get('ab-test')
  const isCanEdit =
    ABTestStore.testDetail &&
    !ABTestStore.testDetail.running &&
    !ABTestStore.testDetail.finished

  useEffect(() => {
    async function fetchData() {
      if (!abTestId) return
      setFetching(true)

      await ABTestStore.fetchTestDetail(abTestId)
      if (ABTestStore.showMetaconfigView) {
        await ABTestStore.fetchSupportedMetrics()
      }
      setFetching(false)
    }
    fetchData()
  }, [abTestId])

  useEffect(() => {
    if (isCreateView) {
      ABTestStore.setShowMetaconfigView(true)
    }

    if (!isCreateView && !abTestId) {
      ABTestStore.resetEditingExperiment()
      ABTestStore.setShowMetaconfigView(false)
      ABTestStore.resetVariantConfig()
    }
  }, [isCreateView, abTestId])

  if (isFetching) return <LoadingScreen />

  if (!ABTestStore.showMetaconfigView) {
    return children
  }

  return (
    <div className="ab-metaconfig ab-metaconfig--active">
      {isCreateView && ABTestStore.createStep === 1 && <CreateHeader />}
      {((isCreateView && ABTestStore.createStep === 2) ||
        (abTestId &&
          ABTestStore.showMetaconfigView &&
          !ABTestStore.isFetchingTest)) && (
        <ConfigHeader disabled={!isCreateView && !isCanEdit} />
      )}
      <div className="ab-metaconfig__page-wrapper">{children}</div>
    </div>
  )
}

export default observer(ABMetaInformation)
