import {
  DndContext,
  DragOverlay,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { snapCenterToCursor } from '@dnd-kit/modifiers'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import ContentWrapper from '../../components/ContentWrapper'
import PageTitle from '../../components/PageTitle'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import { ComponentStore, ActionLayerStore } from '../../stores'
import { t, createRedirectPath } from '../../utils'
import { DragableElement, Element } from './components/DragableElement'
import FormBuilder from './components/FormBuilder'
import Preview from './components/Preview'
import DataStructure from './components/DataStructure'
import FieldConfiguration from './components/configuration'
import { AVAILABLE_FIELD_TYPES, CONDITIONAL_SECTION_TYPE } from './constants'
import Settings from './components/Settings'
import { routes } from '../../routing'
import { createPortal } from 'react-dom'
import { DragableSection } from './components/DraggableSection'
import LoadingScreen from '../../components/LoadingScreen'
import { calculateArrowPosition } from '../SmartBundles/Actions'
import ImportModal from './ImportModal'

import './style.styl'

const FIELDS_WITHOUT_ARRAY = AVAILABLE_FIELD_TYPES.filter(
  (element) => !['object', 'array', 'section'].includes(element.value)
)

const ARRAY_FIELDS = AVAILABLE_FIELD_TYPES.filter((element) =>
  ['object', 'array', 'section'].includes(element.value)
)

const MIDDLE_SECTIONS = ['form-builder', 'preview', 'data-structure']
const getMiddleSectionTitle = (section) => {
  switch (section) {
    case 'form-builder':
      return 'Form Builder'
    case 'preview':
      return 'Preview'
    case 'data-structure':
      return 'Data Structure'
    default:
      return ''
  }
}

const Edit = () => {
  const { id } = useParams()
  const [search, setSearch] = useState('')
  const [activeItem, setActiveItem] = useState(null)
  const [importModalVisible, setImportModalVisible] = useState(false)
  const history = useHistory()
  // middleSection = 'form-builder' | 'preview' | 'data-structure'
  const [middleSection, setMiddleSection] = useState(MIDDLE_SECTIONS[0])
  const isEditView = id !== 'new'

  const settingsFormikRef = useRef()

  const {
    detail,
    elementUniqueIds,
    isDirty,
    setDirty,
    updateOrCreate,
    updateDetail,
    resetEditing,
  } = ComponentStore

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  )
  const [isShowConfiguration, setIsShowConfiguration] = useState(!isEditView)

  // recaculate the arrow position when title changed
  useEffect(() => {
    setTimeout(calculateArrowPosition, 50)
    // eslint-disable-next-line
  }, [detail.name])

  useEffect(() => {
    const init = async () => {
      await ComponentStore.fetchSections()
      await ComponentStore.fetchComponentDetail(id)
    }

    init()
    return () => {
      resetEditing()
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        onSave: handleSave,
        onClose: handleClose,
        saveTitle: t('Save & back to list'),
        closeTitle: t('Abort & back to list'),
        onSaveWithContinue: async () => handleSave(true),
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  const handleClose = () => {
    setDirty(false)
    history.push(createRedirectPath(routes.componentEditorList.path))
  }

  const handleSave = async (stay = false) => {
    setDirty(false)
    const result = await updateOrCreate()
    if (result && result.id) {
      if (stay) {
        history.replace(
          createRedirectPath(
            routes.componentEditorDetail.path.replace(':id', result.id)
          )
        )
      } else {
        history.push(createRedirectPath(routes.componentEditorList.path))
      }
    }
  }

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      setActiveItem(null)
      return
    }

    if (active && over && active.id !== over.id) {
      ComponentStore.handleDragElementToDropable(active, over.id)
    }
  }

  if (ComponentStore.state === 'pending') return <LoadingScreen />

  return (
    <>
      <PageTitle
        prefix={isEditView ? t('You are editing') : t('You are creating')}
        metaInfo={
          isEditView && {
            timestamp: detail.changed?.date,
            timezone: detail.changed?.timezone,
            user: detail.user,
            id: detail.id,
          }
        }
        hiddenFields={['internal-title']}
        title={detail.name}
        additionalActions={
          <div className="component-editor__settings-buttons">
            {isShowConfiguration ? (
              <Button
                id="config-button"
                onClick={async () => {
                  setIsShowConfiguration(false)
                }}
                variant="tertiary"
                icon="times"
              />
            ) : (
              <Button
                id="config-button"
                variant="tertiary"
                icon="sliders-up"
                onClick={() => {
                  setIsShowConfiguration(true)
                }}
              >
                {t('Settings')}
              </Button>
            )}
            <Button
              style={{ marginLeft: 10 }}
              variant="tertiary"
              icon="file-import"
              onClick={() => setImportModalVisible(true)}
            >
              {t('Import')}
            </Button>
          </div>
        }
      >
        {isEditView ? detail.name : t('A New Component...')}
      </PageTitle>
      <Settings
        show={isShowConfiguration}
        setFieldValue={updateDetail}
        initialValues={detail}
        formikRef={settingsFormikRef}
      />
      <ContentWrapper className="component-editor">
        <div className="component-editor__edit-inner">
          <DndContext
            sensors={sensors}
            onDragStart={({ active }) => {
              setActiveItem(active?.data?.current?.field)
            }}
            onDragCancel={() => {
              setActiveItem(null)
            }}
            onDragEnd={handleDragEnd}
            collisionDetection={pointerWithin}
            modifiers={[snapCenterToCursor]}
          >
            <div className="component-editor__left-section">
              <div className="component-editor__form-element-section">
                <Text>
                  {t(
                    'Drag form elements into the area to the right in order to create an input form for your component.'
                  )}
                </Text>
                <div className="component-editor__form-element">
                  <Text size="delta" weight="bold">
                    {t('Form Elements')}
                  </Text>
                  <TextInput
                    placeholder={t('Search')}
                    icon="magnifying-glass"
                    rounded
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="component-editor__elements-wrapper">
                    {FIELDS_WITHOUT_ARRAY.filter(
                      (element) =>
                        !search ||
                        element.label
                          .toLowerCase()
                          .includes(search.toLowerCase())
                    ).map((element) => (
                      <DragableElement
                        key={element.value}
                        id={elementUniqueIds[element.value]}
                        element={element}
                      />
                    ))}
                  </div>
                  <div className="component-editor__elements-wrapper">
                    {ARRAY_FIELDS.map((element) => (
                      <DragableElement
                        key={element.value}
                        id={elementUniqueIds[element.value]}
                        element={element}
                      />
                    ))}
                  </div>
                  <Text size="delta" weight="bold">
                    {t('Sections')}
                  </Text>
                  <div className="component-editor__elements-wrapper">
                    <DragableElement
                      key={CONDITIONAL_SECTION_TYPE.value}
                      id={elementUniqueIds[CONDITIONAL_SECTION_TYPE.value]}
                      element={CONDITIONAL_SECTION_TYPE}
                    />
                  </div>
                  <div className="component-editor__elements-wrapper">
                    {ComponentStore.sections.map((section) => (
                      <DragableSection
                        key={section.id}
                        id={section.id}
                        section={section}
                        onRemove={() =>
                          ComponentStore.removeSection(section.id)
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="component-editor__middle-section">
              <div className="tabs tabs--small">
                {MIDDLE_SECTIONS.map((section) => (
                  <div
                    key={section}
                    className={classNames('tabs__item', {
                      'tabs__item--active': middleSection === section,
                    })}
                    onClick={() => setMiddleSection(section)}
                  >
                    {t(getMiddleSectionTitle(section))}
                  </div>
                ))}
              </div>

              <div className="component-editor__middle-section-content">
                <FormBuilder middleSection={middleSection} />
                <Preview middleSection={middleSection} />
                <DataStructure middleSection={middleSection} />
              </div>
            </div>
            <div className="component-editor__right-section">
              <FieldConfiguration />
            </div>
            {createPortal(
              <DragOverlay>
                {activeItem && <Element field={activeItem} />}
              </DragOverlay>,
              document.body
            )}
          </DndContext>
        </div>
        <ImportModal
          modalVisible={importModalVisible}
          closeModal={() => setImportModalVisible(false)}
        />
      </ContentWrapper>
    </>
  )
}

export default observer(Edit)
