import AuthStore from '../../stores/AuthStore'
import ExperimentStore from '../../stores/ExperimentStore'
import getInstance from '../getInstance'

export function createFetchHeaders(options = {}) {
  let headers = new Headers({
    Authorization: `Bearer ${AuthStore.token}`,
    'X-Makaira-Instance': getInstance(),
  })

  const { id = '' } = ExperimentStore.editingExperiment

  if (id) {
    headers.set('X-Makaira-Scenario', id)
  }

  if (options.body instanceof FormData) {
    headers.set('Accept', 'multipart/form-data')
  } else {
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
  }

  if (options.headers) {
    for (let key in options.headers) {
      headers.set(key, options.headers[key])
    }
  }

  return headers
}

export async function fetchUtil(url, options = {}) {
  // enable debugging through phpstorm in dev-mode
  if (process.env.NODE_ENV === 'development') {
    document.cookie = 'XDEBUG_SESSION=PHPSTORM'
  }

  options.headers = createFetchHeaders(options)

  // for debugging purposes
  if (
    process.env.REACT_APP_PROXY_STAGE &&
    process.env.NODE_ENV === 'development'
  ) {
    url = 'https://stage.makaira.io' + url
    options.headers = {
      ...options.headers,
      Authorization: 'Basic ' + btoa(process.env.REACT_APP_PROXY_AUTH),
      'X-Makaira-Instance': 'oxid6',
    }
  }

  // By default, fetch won't send or receive any cookies from the server
  // to send cookies, the credentials init option must be set
  const response = await fetch(url, { ...options, credentials: 'include' })

  const resContentType = response.headers.get('Content-Type')

  const body = await (resContentType.startsWith('text/csv')
    ? response.blob()
    : response.json())

  const { status, headers } = response

  if (status != 200 && status != 201 && status != 202) {
    const formattedError = JSON.stringify({ ...body, status }, null, 2)

    console.error(formattedError)

    throw new Error(formattedError)
  }

  return { data: body, total: headers.get('x-total-count') }
}
