import get from 'lodash/get'
import { toast } from 'react-toastify'
import { makeAutoObservable } from 'mobx'

import api from '../../api'
import t from '../../utils/translate'

import AppStore from './AppStore'
import EditorStore from '../PageEditor/EditorStore'
import ComponentStore from '../ComponentStore'

class ContentWidgetStore {
  contentWidgetInfo = {}

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetchContentWidgetUrl(slug) {
    try {
      const { data } = yield api.apps.getExternalAppsUrl(slug)
      this.contentWidgetInfo[slug] = data
      return data
    } catch (error) {
      toast.error(t('No content-widget with the given slug exists.'))
      return null
    }
  }

  getMetaInformation(slug) {
    const information = { ...this.contentWidgetInfo[slug] } ?? {}

    information.finalURL = information.iframeURL ?? ''

    const page = EditorStore.pageToEdit

    const additionalSearchParams = new URLSearchParams()
    additionalSearchParams.append('pageId', page.id)
    additionalSearchParams.append('pageType', page.type)

    Object.keys(page.metadata).forEach((languageKey) => {
      let title = ''
      if (page.type === 'page' || page.type === 'snippet') {
        title = get(page, ['metadata', languageKey, 'title'])
      } else {
        title = EditorStore.extraLanguageInformation[languageKey]?.title ?? ''
      }

      additionalSearchParams.append(`pageTitle[${languageKey}]`, title)
    })

    information.finalURL += `&${additionalSearchParams.toString()}`

    return information
  }

  /**
   *
   * @param event {MessageEvent}
   * @param slug {string}
   */
  handleReceiveMessageEvent(event, slug) {
    const { source, action, data } = event.data

    if (source !== `makaira-content-widget-${slug}`) {
      console.debug(
        `[Makaira-Content-Widget] We have received a message event from a source 
        that doesn't match the slug "makaira-content-widget-${slug}". Instead "${source}" was given.`,
        event
      )
      return
    }

    switch (action) {
      case 'requestUser':
        this.handleUserRequest(event, slug)
        break
      case 'updatePageInformation':
        this.handlePageUpdate(data, slug)
    }
  }

  *handleUserRequest(event, slug) {
    const { hmac, nonce, makairaHmac } = event.data

    console.debug(
      `[Makaira-Content-Widget] Received request for auth token.`,
      event
    )

    const credentialsValid = yield AppStore.validateCredentialsRequest(slug, {
      hmac,
      nonce,
      makairaHmac,
    })

    AppStore.sendValidationAnswerMessage(credentialsValid, event, true)
  }

  handlePageUpdate(data, slug) {
    console.debug(
      `[Makaira-Content-Widget] Received request to update page data in page editor.`,
      data
    )

    const { key, value } = data
    const allowedKeysToUpdate = [
      'config',
      'metadata',
      'seoUrls',
      'active',
      'internalTitle',
      'searchable',
    ]

    if (!allowedKeysToUpdate.includes(key)) {
      console.error(
        `[Makaira-Content-Widget] Content widget ${slug} tried to modify key "${key}" which is not allowed`
      )
      return
    }

    EditorStore.updateValueFromContentWidget(key, value)
  }

  sendPageInformation(iframe, slug) {
    if (!iframe) {
      console.error(
        '[Makaira-Content-Widget] Iframe for content widget ' +
          slug +
          ' could not be found.'
      )
      return
    }

    if (!this.contentWidgetInfo[slug]) {
      console.error(
        '[Makaira-Content-Widget] No information for content widget ' +
          slug +
          ' could be found'
      )
      return
    }

    const targetURL = this.contentWidgetInfo[slug].iframeURL

    iframe.contentWindow.postMessage(
      {
        source: 'makaira-content-widget-bridge',
        // Actions are always named from the perspective of the External-App/Content-Widget.
        action: 'receivePageInformation',
        data: {
          page: JSON.parse(JSON.stringify(EditorStore.pageToEdit)),
          components: JSON.parse(JSON.stringify(ComponentStore.components)),
          selectedLanguage: EditorStore.contentLanguage,
        },
      },
      targetURL
    )
  }
}

export default new ContentWidgetStore()
