import { Redirect } from 'react-router-dom'
import t from '../utils/translate'
import Dashboard from '../views/Dashboard'
import MenuEditor from '../views/MenuEditor'

import PagesList from '../views/PageEditor/List'
import PageStructure from '../views/PageEditor/Structure'
import SetupInformation from '../views/SetupInformation'
import SmartBundleList from '../views/SmartBundles/List'
import SmartBundleDetail from '../views/SmartBundles/Detail'
import ImporterSchedule from '../views/Importer/Schedule'
import ImporterLogs from '../views/Importer/Logs'
import IndexList from '../views/Importer/IndexList'
import IndexDetail from '../views/Importer/IndexDetail'
import ImporterEdit from '../views/Importer/Edit'
import getInstance from '../utils/getInstance'
import NotificationList from '../views/Notifications/List'
import Personalization from '../views/Personalization'
import AuditLogList from '../views/AuditLogs/List'
import AuditLogDetail from '../views/AuditLogs/Detail'
import ExternalApps from '../views/ExternalApps/ExternalApps'
import { DataInspector, StreamInspector } from '../views/Inspector'
import Settings from '../views/StorefrontSettings/Settings'
import Logs from '../views/StorefrontSettings/Logs'
import FeedsList from '../views/Feeds/List'
import FeedsDetail from '../views/Feeds/Edit'
import UserManagementList from '../views/UserManagement/UserManagementList'
import UserDetail from '../views/UserManagement/Edit'
import RankingMix from '../views/RankingMix'
import RecommendationsList from '../views/Recommendations-v3/List'
import recommendationDetail from '../views/Recommendations-v3/Edit'
import ABPlaceholder from '../components/ABPlaceholder'
import ABFrontend from '../components/ABFrontend'
import ABTestingList from '../views/ABTesting/ABTestingList'
import ABTestingTimelineView from '../views/ABTesting/TimelineView'
import SearchConfigurationV3 from '../views/SearchConfigurationV3'
import SearchableLinkList from '../views/SearchableLinksV3/List'
import SearchableLinkDetail from '../views/SearchableLinksV3/Edit'
import AntonymsList from '../views/AntonymsAndSynonyms/List'
import AntonymsDetail from '../views/AntonymsAndSynonyms/Edit'
import FilterList from '../views/FilterV3/List'
import FilterEdit from '../views/FilterV3/Edit'
import SearchRedirectList from '../views/SearchRedirects/List'
import SearchRedirectForm from '../views/SearchRedirects/Form'
import SearchMonitoring from '../views/SearchMonitoring'
import Banner from '../views/Banners/Form'
import BannerList from '../views/Banners/List'
import AdvertisingSpacesList from '../views/AdvertisingSpaces/List'
import AdvertisingSpacesForm from '../views/AdvertisingSpaces/Form'
import CommonSettings from '../views/Settings/index'
import StreamForm from '../views/Streams/Form'
import ProductStreamList from '../views/Streams/List'
import CategoryList from '../views/Category/List'
import ComponentEditorList from '../views/ComponentEditor/List'
import ComponentEditorDetail from '../views/ComponentEditor/Edit'
import Redirects from '../views/Settings/Redirects'
import SecretKeyDetail from '../views/SecretKey/Edit'
import SecretKeyList from '../views/SecretKey/List'
import RoleManagementList from '../views/Role/List'
import RoleManagementDetail from '../views/Role/Edit'
import Statistic from '../views/Statistic'
import CategoryMapping from '../views/CategoryMapping'

export default {
  dashboard: {
    path: '/dashboard',
    Component: Dashboard,
    title: t('menu_dashboard'),
    submenu: [],
    v3: true,
  },
  pageEditor: {
    path: '/content',
    Component: PagesList,
    title: t('Page Editor'),
    submenu: [],
    requireFeature: 'page_editor',
    roles: ['owner', 'admin', 'contentcreator', 'shopmanager'],
    exact: true,
    v3: true,
    permission: ['pageeditor.read'],
  },
  pageStructure: {
    path: '/content/:id/:lang',
    Component: PageStructure,
    title: t('Page Editor'),
    submenu: [],
    requireFeature: 'page_editor',
    roles: ['owner', 'admin', 'contentcreator', 'shopmanager'],
    exact: true,
    v3: true,
    permission: ['pageeditor.read'],
  },
  menuEditor: {
    path: '/menu',
    Component: MenuEditor,
    title: t('menu_menu_editor'),
    submenu: ['content'],
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'page_editor',
    permission: ['menu.read'],
    v3: true,
  },
  personalization: {
    path: '/personalization',
    Component: Personalization,
    title: t('Personalization'),
    submenu: [],
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'machine_learning',
    permission: ['personalization.read'],
    exact: true,
    v3: true,
    routeUnbookedAccessible: true,
  },
  bundleList: {
    path: '/bundle',
    exact: true,
    Component: SmartBundleList,
    title: t('menu_bundle'),
    submenu: [],
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    requireFeature: 'bundles',
    permission: ['smartbundle.read'],
  },
  bundleNew: {
    path: '/bundle/new',
    exact: true,
    Component: SmartBundleDetail,
    title: t('menu_bundle'),
    submenu: [],
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    requireFeature: 'bundles',
    permission: ['smartbundle.read'],
  },
  bundleDetail: {
    path: '/bundle/:id',
    Component: SmartBundleDetail,
    title: t('menu_bundle'),
    submenu: [],
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    requireFeature: 'bundles',
    permission: ['smartbundle.read'],
  },
  setupInformation: {
    path: '/setup-information',
    Component: SetupInformation,
    title: t('Setup Information'),
    submenu: [],
    v3: true,
  },
  indexOverview: {
    path: '/index',
    Component: IndexList,
    title: t('Index & Importer'),
    subTitle: t('Overview'),
    roles: ['owner', 'admin'],
    v3: true,
    submenu: [],
    exact: true,
    permission: ['indices.read'],
  },
  indexOverviewDetail: {
    path: '/index/:lang',
    Component: IndexDetail,
    title: t('Index & Importer'),
    parentPath: '/index/overview',
    subTitle: t('Overview'),
    roles: ['owner', 'admin'],
    v3: true,
    submenu: [],
    exact: true,
    permission: ['indices.read'],
  },
  importerSchedule: {
    path: '/importer',
    exact: true,
    Component: ImporterSchedule,
    title: t('Index & Importer'),
    subTitle: t('Importer'),
    submenu: [],
    roles: ['owner', 'admin'],
    v3: true,
    permission: ['indices.read'],
  },
  importerLogs: {
    path: '/importer-logs',
    exact: true,
    Component: ImporterLogs,
    title: t('Index & Importer'),
    subTitle: t('Logs'),
    submenu: [],
    roles: ['owner', 'admin'],
    v3: true,
    permission: ['indices.read'],
  },
  importerCreate: {
    path: '/importer/new',
    Component: ImporterEdit,
    parentPath: '/importer',
    title: t('Index & Importer'),
    subTitle: t('Importer'),
    roles: ['owner', 'admin'],
    v3: true,
    submenu: [],
    exact: true,
    permission: ['indices.read'],
  },
  importerEdit: {
    path: '/importer/edit/:id',
    Component: ImporterEdit,
    parentPath: '/importer',
    title: t('Index & Importer'),
    subTitle: t('Importer'),
    roles: ['owner', 'admin'],
    v3: true,
    submenu: [],
    exact: true,
    permission: ['indices.read'],
  },
  notificationList: {
    path: '/notifications',
    Component: NotificationList,
    title: 'Notifications',
    submenu: [],
    v3: true,
  },
  auditList: {
    path: '/audit',
    exact: true,
    Component: AuditLogList,
    title: t('Audit Logs'),
    submenu: [],
    roles: ['owner', 'admin'],
    requireFeature: 'audit_log',
    permission: ['auditlog.read'],
    v3: true,
  },
  auditDetail: {
    path: '/audit/:id',
    exact: true,
    Component: AuditLogDetail,
    title: t('Audit Logs'),
    submenu: [],
    roles: ['owner', 'admin'],
    requireFeature: 'audit_log',
    permission: ['auditlog.read'],
    v3: true,
  },
  // This ("externalAppsFallback") is a quick fix for a bug caused in the PageHeader/index.js
  // Will fix it later.
  externalAppsFallback: {
    path: '/app',
    Component: ExternalApps,
    title: t('External App'),
    submenu: [],
    roles: ['owner', 'admin', 'apps-only', 'contentcreator'],
    v3: true,
    exact: true,
  },
  externalApps: {
    path: '/app/:slug',
    Component: ExternalApps,
    title: t('External App'),
    submenu: [],
    roles: ['owner', 'admin', 'apps-only', 'contentcreator'],
    v3: true,
    exact: true,
  },
  // dont miss when you update the dataInspector this directly affects the streamInspector
  dataInspector: {
    path: '/data-inspector',
    Component: DataInspector,
    title: t('menu_expert_data_inspector'),
    submenu: ['expert'],
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    permission: ['datainspector.read'],
  },
  // dont miss this is only an alias so the routing is working. To adjust roles
  // or requiredFeatures you have to update the dataInspector
  streamInspector: {
    path: '/stream-inspector',
    Component: StreamInspector,
    title: t('menu_expert_data_inspector'),
    submenu: ['expert'],
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    permission: ['streams.read'],
  },
  storefront: {
    path: '/storefront',
    exact: true,
    Component: () => (
      <Redirect to={'/' + getInstance() + '/storefront/settings'} />
    ),
    title: t('Storefront Settings'),
    roles: ['owner', 'admin'],
    v3: true,
    permission: ['storefront.read'],
  },
  storefrontSettings: {
    path: '/storefront/settings',
    Component: Settings,
    title: t('Storefront Settings'),
    v3: true,
    roles: ['owner', 'admin'],
    permission: ['storefront.read'],
  },
  storefrontLogs: {
    path: '/storefront/logs',
    Component: Logs,
    title: t('Storefront Logs'),
    v3: true,
    roles: ['owner', 'admin'],
    permission: ['storefront.read'],
  },
  feeds: {
    path: '/feeds',
    Component: FeedsList,
    title: t('Feeds'),
    submenu: ['expert'],
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    exact: true,
    requireFeature: 'productfeeds',
    permission: ['productfeeds.read'],
  },
  feedDetail: {
    path: '/feeds/:id',
    Component: FeedsDetail,
    title: t('feeds'),
    submenu: ['expert'],
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    exact: true,
    requireFeature: 'productfeeds',
    permission: ['productfeeds.read'],
  },
  rankingMix: {
    path: '/ranking-mix',
    Component: RankingMix,
    title: t('Ranking Mix'),
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    requireFeature: 'search',
    permission: ['rankingmix.read'],
  },
  abTesting: {
    path: '/ab-testing',
    exact: true,
    Component: () => (
      <Redirect to={'/' + getInstance() + '/ab-testing/upcoming'} />
    ),
    title: t('A/B Tests'),
    roles: ['owner', 'admin', 'shopmanager'],
    permission: ['abtesting.read'],
    v3: true,
  },
  abTestingUpcomingList: {
    path: '/ab-testing/upcoming',
    Component: ABTestingList,
    title: t('A/B Tests'),
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    exact: true,
    requireFeature: 'ab_testing',
    permission: ['abtesting.read', 'personalization.read'],
  },
  abTestingArchiveList: {
    path: '/ab-testing/done',
    Component: ABTestingList,
    title: t('A/B Tests'),
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    exact: true,
    requireFeature: 'ab_testing',
    permission: ['abtesting.read', 'personalization.read'],
  },
  abTestingTimelineView: {
    path: '/ab-testing/timeline-view',
    Component: ABTestingTimelineView,
    title: t('A/B Tests'),
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    exact: true,
    requireFeature: 'ab_testing',
    permission: ['abtesting.read', 'personalization.read'],
  },
  userList: {
    path: '/user',
    Component: UserManagementList,
    title: t('User'),
    submenu: ['expert'],
    roles: ['owner'],
    v3: true,
    exact: true,
    permission: ['user.read'],
  },
  userDetail: {
    path: '/user/:id',
    Component: UserDetail,
    title: t('User'),
    submenu: ['expert'],
    roles: ['owner'],
    v3: true,
    exact: true,
    permission: ['user.read'],
  },
  recommendations: {
    path: '/recommendations',
    Component: RecommendationsList,
    title: t('Recommendations'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'recommendations',
    v3: true,
    exact: true,
    permission: ['recommendations.read', 'abtesting.read'],
  },
  recommendationDetail: {
    path: '/recommendations/:id',
    Component: recommendationDetail,
    title: t('Recommendations'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'recommendations',
    v3: true,
    exact: true,
    permission: ['recommendations.read', 'abtesting.read'],
  },
  abTestingCreate: {
    path: '/ab-testing/new',
    Component: ABPlaceholder,
    title: t('A/B-Tests'),
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    exact: true,
    permission: ['abtesting.read'],
  },
  abFrontend: {
    path: '/ab-testing/created/:case',
    Component: ABFrontend,
    title: t('A/B-Tests'),
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    exact: true,
    permission: ['abtesting.read'],
  },
  searchMonitoring: {
    path: '/search-monitoring',
    Component: SearchMonitoring,
    parentPath: '/search-configuration',
    title: t('Search'),
    subTitle: t('Monitoring'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: ['search.read'],
  },
  searchConfiguration: {
    path: '/search-configuration',
    Component: SearchConfigurationV3,
    title: t('Search'),
    subTitle: t('Configuration'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: ['search.read'],
  },
  filterList: {
    path: '/filter',
    Component: FilterList,
    parentPath: '/search-configuration',
    title: t('Search'),
    subTitle: t('Filters'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: ['search.read', 'categories.read'],
  },
  filterEdit: {
    path: '/filter/:id',
    Component: FilterEdit,
    parentPath: '/search-configuration',
    title: t('Search'),
    subTitle: t('Filters'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: ['search.read', 'categories.read'],
  },
  antonymsList: {
    path: '/antonyms',
    Component: AntonymsList,
    parentPath: '/search-configuration',
    title: t('Search'),
    subTitle: t('Antonyms'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: ['search.read'],
  },
  antonymsDetail: {
    path: '/antonyms/:id',
    Component: AntonymsDetail,
    parentPath: '/search-configuration',
    title: t('Search'),
    subTitle: t('Antonyms'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: ['search.read'],
  },
  synonymsList: {
    path: '/synonyms',
    Component: AntonymsList,
    parentPath: '/search-configuration',
    title: t('Search'),
    subTitle: t('Synonyms'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: ['search.read'],
  },
  synonymsDetail: {
    path: '/synonyms/:id',
    Component: AntonymsDetail,
    parentPath: '/search-configuration',
    title: t('Search'),
    subTitle: t('Synonyms'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: ['search.read'],
  },
  searchRedirectList: {
    path: '/search-redirects',
    Component: SearchRedirectList,
    parentPath: '/search-configuration',
    title: t('Search'),
    subTitle: t('Redirects'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: ['search.read'],
  },
  searchRedirectDetail: {
    path: '/search-redirects/:id',
    Component: SearchRedirectForm,
    parentPath: '/search-configuration',
    title: t('Search'),
    subTitle: t('Redirects'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: ['search.read'],
  },
  searchableLinkList: {
    path: '/searchable-link',
    Component: SearchableLinkList,
    parentPath: '/search-configuration',
    title: t('Search'),
    subTitle: t('Links'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: ['search.read'],
  },
  searchableLinkDetail: {
    path: '/searchable-link/:id',
    Component: SearchableLinkDetail,
    title: t('Search Configuration'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: ['search.read'],
  },
  settings: {
    path: '/settings',
    Component: CommonSettings,
    title: t('Settings'),
    roles: ['owner', 'admin'],
    v3: true,
    permission: ['settings.read'],
  },
  redirects: {
    path: '/redirects',
    Component: Redirects,
    title: t('Redirects'),
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    requireFeature: 'page_editor',
    permission: ['redirects.read'],
  },
  bannerList: {
    path: '/banner',
    Component: BannerList,
    title: t('Banner'),
    roles: ['owner', 'admin', 'shopmanager', 'contentcreator'],
    requireFeature: 'promotion',
    v3: true,
    exact: true,
    permission: ['banner.read'],
  },
  bannerDetail: {
    path: '/banner/:id',
    Component: Banner,
    title: t('Banner'),
    roles: ['owner', 'admin', 'shopmanager', 'contentcreator'],
    requireFeature: 'promotion',
    v3: true,
    exact: true,
    permission: ['banner.read'],
  },
  advertisingSpaces: {
    path: '/advertising-space',
    Component: AdvertisingSpacesList,
    title: t('Advertising Spaces'),
    roles: ['owner', 'admin', 'shopmanager', 'contentcreator'],
    requireFeature: 'promotion',
    v3: true,
    exact: true,
    permission: ['placement.read'],
  },
  advertisingSpaceDetail: {
    path: '/advertising-space/:id',
    Component: AdvertisingSpacesForm,
    title: t('Advertising Spaces'),
    roles: ['owner', 'admin', 'shopmanager', 'contentcreator'],
    requireFeature: 'promotion',
    v3: true,
    exact: true,
    permission: ['placement.read'],
  },
  ProductStreamList: {
    path: '/stream',
    Component: ProductStreamList,
    title: t('Streams'),
    roles: ['owner', 'admin', 'shopmanager'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: [
      'pageeditor.read',
      'categories.read',
      'search.read',
      'datainspector.read',
      'feeds.read',
      'streams.read',
      'pageeditor.write',
    ],
  },
  streamDetail: {
    path: '/stream/:id',
    Component: StreamForm,
    title: t('Streams'),
    roles: ['owner', 'admin', 'shopmanager', 'contentcreator'],
    requireFeature: 'search',
    v3: true,
    exact: true,
    permission: [
      'pageeditor.read',
      'categories.read',
      'search.read',
      'streams.read',
    ],
  },
  categories: {
    path: '/categories',
    Component: CategoryList,
    title: t('Categories'),
    requireFeature: 'search',
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    exact: true,
    permission: [
      'categories.read',
      'abtesting.read',
      'personalization.read',
      'recommendations.read',
      'streams.read',
    ],
  },
  componentEditorList: {
    path: '/component-editor',
    Component: ComponentEditorList,
    title: t('Component Editor'),
    requireFeature: 'page_editor',
    permission: ['pageeditor.read', 'component.read', 'pageeditor.write'],
    roles: ['owner', 'admin'],
    v3: true,
    exact: true,
  },
  componentEditorDetail: {
    path: '/component-editor/:id',
    Component: ComponentEditorDetail,
    title: t('Component Editor'),
    requireFeature: 'page_editor',
    permission: ['pageeditor.read', 'component.read', 'pageeditor.write'],
    roles: ['owner', 'admin'],
    v3: true,
    exact: true,
  },
  secretList: {
    path: '/secret',
    Component: SecretKeyList,
    title: t('Api Keys'),
    roles: ['owner'],
    v3: true,
    exact: true,
    permission: ['secret.read'],
  },
  secretDetail: {
    path: '/secret/:id',
    Component: SecretKeyDetail,
    title: t('Api Keys'),
    roles: ['owner'],
    v3: true,
    exact: true,
    permission: ['secret.read'],
  },
  rolesList: {
    path: '/role',
    Component: RoleManagementList,
    title: t('Roles'),
    // submenu: ['expert'],
    roles: ['owner'],
    v3: true,
    exact: true,
  },
  roleDetail: {
    path: '/role/:id',
    Component: RoleManagementDetail,
    title: t('Roles'),
    roles: ['owner'],
    v3: true,
    exact: true,
  },
  statistic: {
    path: '/statistic/request',
    Component: Statistic,
    title: t('Statistic'),
    roles: ['owner', 'admin'],
    permission: ['statistics.read'],
    v3: true,
    exact: true,
  },
  categoryMapping: {
    path: '/category-mapping',
    Component: CategoryMapping,
    title: t('Category Mapping'),
    roles: ['owner', 'admin', 'shopmanager'],
    v3: true,
    exact: true,
  },
}
