import * as Yup from 'yup'
import { t } from '../../utils'

export const validateFilterFormV3 = (defaultLanguage = []) => {
  return Yup.object().shape({
    title: Yup.object()
      .shape({
        [defaultLanguage]: Yup.string().required(t('This field is required.')),
      })
      .required(t('This field is required.')),
    field: Yup.string().required(t('This field is required.')).nullable(),
    type: Yup.string().when('field', {
      is: (value) => !!value,
      then: (schema) =>
        schema.required(t('This field is required.')).nullable(),
      otherwise: (schema) => schema.nullable(),
    }),
    conditionConnector: Yup.string().when('type', {
      is: (value) =>
        [
          'list_multiselect',
          'list_multiselect_custom_1',
          'list_multiselect_custom_2',
        ].includes(value),
      then: (schema) =>
        schema.required(t('This field is required.')).nullable(),
      otherwise: (schema) => schema.nullable(),
    }),
    dependsOn: Yup.number().optional().nullable(),
    key: Yup.string().required(t('This field is required.')),
    position: Yup.number().optional().nullable(),
    script: Yup.string().optional().nullable(),
    showActiveOnTop: Yup.boolean().optional().nullable(),
    showCount: Yup.boolean().optional().nullable(),
    sorting: Yup.string().optional().nullable(),
    useAttributeTitle: Yup.boolean().optional().nullable(),
    user: Yup.string().optional(),
  })
}
