import { stripSlashes } from '../stripSlashes'

export default function updateInstance() {
  const { pathname } = window.location

  const normalizedPath = stripSlashes(pathname)
  let [, instance] = normalizedPath.split('/')

  if (!instance) {
    instance = localStorage.getItem('instance') ?? 'live'
  }

  sessionStorage.setItem('instance', instance)
  localStorage.setItem('instance', instance)
}
