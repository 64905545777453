export const predefinedColors = [
  '#008FFB',
  '#008FFB',
  '#00E396',
  '#00E396',
  '#FEB019',
  '#FEB019',
  '#FF4560',
  '#FF4560',
  '#775DD0',
  '#775DD0',
  '#3F51B5',
  '#3F51B5',
  '#546E7A',
  '#546E7A',
  '#D4526E',
  '#D4526E',
  '#8D5B4C',
  '#8D5B4C',
  '#F86624',
  '#F86624',
  '#D7263D',
  '#D7263D',
  '#1B998B',
  '#1B998B',
  '#2E294E',
  '#2E294E',
  '#F46036',
  '#F46036',
  '#E2C044',
  '#E2C044',
]
