import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import { Field } from 'formik'

import Text from '../../../components/Text'
import Switch from '../../../components/Switch'
import FormikURLInput from '../../../components/TextInput/FormikURLInput'
import FormikTextInput from '../../../components/TextInput/FormikTextInput'

import t from '../../../utils/translate'
import UIStore from '../../../stores/UIStore'
import EditorStore from '../../../stores/PageEditor/EditorStore'

export default function PageSetting({ pageToEdit, formikProps }) {
  if (pageToEdit.type !== 'page') return null

  return (
    <div className="page-configuration__general-settings">
      {UIStore.languages.map((language) => (
        <LanguageSetting
          key={language}
          language={language}
          pageToEdit={pageToEdit}
          formikProps={formikProps}
        />
      ))}
    </div>
  )
}

function LanguageSetting({ language, pageToEdit, formikProps }) {
  if (pageToEdit.type !== 'page') return null

  const { setFieldValue, handleChange, values } = formikProps

  function getBaseUrl() {
    let url = UIStore.enterpriseConfiguration?.clientBaseurl ?? ''

    if (url.endsWith('/')) {
      url = url.slice(0, url.length - 1)
    }

    return url
  }

  const handleTitleChange = (event, formikProps) => {
    const { handleChange } = formikProps
    handleChange(event)

    // Always update the title in the store so that in the PageHeader
    // the user input is displayed.
    const newPageToEdit = cloneDeep(pageToEdit)
    set(newPageToEdit, event.target.name, event.target.value)
    EditorStore.setPage(newPageToEdit)
  }

  return (
    <div className="page-configuration__language-row">
      <Switch
        checked={get(values, ['active', language])}
        title={`${t('Active')} (${language.toUpperCase()})`}
        onChange={(checked) => setFieldValue(`active.${language}`, checked)}
      />

      <Switch
        checked={get(values, ['searchable', language])}
        title={`${t('Searchable')} (${language.toUpperCase()})`}
        onChange={(checked) => setFieldValue(`searchable.${language}`, checked)}
      />

      <Field
        component={FormikTextInput}
        label={
          <Text variant="book">
            {t('Title')} ({language.toUpperCase()})
          </Text>
        }
        className="page-configuration__setting-grow"
        onChange={(event) => handleTitleChange(event, formikProps)}
        name={`metadata.${language}.title`}
      />

      <Field
        name={`seoUrls.${language}`}
        label={
          <Text variant="book">
            {t('URL')} ({language.toUpperCase()})
          </Text>
        }
        prefixText={getBaseUrl()}
        component={FormikURLInput}
        className="page-configuration__setting-grow"
        onChange={handleChange}
        type={pageToEdit.type}
        id={pageToEdit.id}
      />
    </div>
  )
}
