import moment from 'moment'
import ABTestStore from '../stores/ABTestStore'
import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'
import experimentData from './mock/experiment_data.json'

export default {
  async get(id) {
    const target = `/experiment/${id}`

    return fetch(target)
  },

  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/experiment?${queryString}`

    return fetch(target)
  },

  async create(experiment) {
    return fetch('/experiment', {
      method: 'POST',
      body: JSON.stringify(experiment),
    })
  },

  async update(experiment) {
    await fetch(`/experiment/${experiment.id}`, {
      method: 'PUT',
      body: JSON.stringify(experiment),
    })
  },

  async delete(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))

    await fetch(`/experiment?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },

  async start(id) {
    await fetch(`/experiment/${id}/start`, { method: 'PUT' })
  },

  async stop(id) {
    await fetch(`/experiment/${id}/stop`, { method: 'PUT' })
  },

  generateMockedData(metric, startDate, endDate, segments = 2) {
    const difference = endDate.diff(startDate, 'days')

    const data = {}

    let lastValueOriginal = Math.random() * 100
    let lastValueVariation = Math.random() * 100
    let lastValueVariation2 = Math.random() * 100

    for (let offset = 0; offset <= difference; offset++) {
      const newDate = startDate.clone().add(offset, 'days')
      const formattedDate = newDate.format('YYYY-MM-DD')

      const diffOriginal =
        Math.random() < 0.1 ? -0.9 * (Math.random() + 5) : Math.random() + 5
      const diffVariation =
        Math.random() < 0.2 ? -0.8 * (Math.random() + 5) : Math.random() + 5

      const diffVariation2 =
        Math.random() < 0.3 ? -0.7 * (Math.random() + 5) : Math.random() + 5

      lastValueOriginal += diffOriginal
      lastValueVariation += diffVariation
      lastValueVariation2 += diffVariation2

      data[formattedDate] = {
        Original: lastValueOriginal,
        MakairaPersonalisation: lastValueVariation,
        0: lastValueOriginal - (Math.random() / 10) * lastValueOriginal,
        1: lastValueOriginal + (Math.random() / 10) * lastValueOriginal,
        2: lastValueVariation + (Math.random() / 10) * lastValueVariation,
        3: lastValueVariation - (Math.random() / 10) * lastValueVariation,
      }

      if (segments === 3) {
        data[formattedDate]['variantC'] = lastValueVariation2
        data[formattedDate]['variantC1'] =
          lastValueVariation2 + (Math.random() / 10) * lastValueVariation2
        data[formattedDate]['variantC2'] =
          lastValueVariation2 - (Math.random() / 10) * lastValueVariation2
      }
    }

    return data
  },

  async results(id) {
    const isDev =
      window.location.hostname === 'demo.makaira.io' ||
      window.location.hostname === 'localhost'

    const isStagingMocked =
      window.location.hostname === 'stage.makaira.io' &&
      localStorage.getItem('ab_mocked')

    if (isDev || isStagingMocked) {
      const experiment = ABTestStore.editingExperiment
      const startDate = moment(experiment.startDate)
      const endDate = startDate.clone().add(40, 'days')
      const data = experimentData
      const updated_graph = {}
      const metrics = Object.keys(experimentData.graph)

      for (const metric of metrics) {
        updated_graph[metric] = this.generateMockedData(
          metric,
          startDate,
          endDate,
          experiment.segments
        )
      }

      return {
        data: {
          graph: updated_graph,
          table: data.table,
        },
      }
    }

    return fetch(`/experiment/${id}/results`, { method: 'GET' })
  },

  async running() {
    return fetch(`/experiment/running`, { method: 'GET' })
  },

  async applyVariation(experimentId, variationId) {
    return fetch(`/experiment/variation/${experimentId}/${variationId}`, {
      method: 'PUT',
    })
  },
}
