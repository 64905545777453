import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import UIStore from '../../stores/UIStore'
import StreamStore from '../../stores/StreamStore'
import { toast } from 'react-toastify'
import useDidMountEffect from '../../utils/useDidMountEffect'
import t from '../../utils/translate'
import ContentWrapper from '../../components/ContentWrapper'
import InspectorPageTitle from './InspectorPageTitle'
import Text from '../../components/Text'
import { Column } from '../../components/Table'
import ImagePreview from './ImagePreview'
import ResourceTable from '../../components/ResourceTable'
import api from '../../api'
import ItemPreview from './ItemPreview'
import ResourceTableFilter from '../../components/ResourceTable/ResourceTableFilter'

function StreamInspector() {
  const [selectedStream, setSelectedStream] = useState(null)
  const [language, setLanguage] = useState(UIStore.languages[0])
  const [indexState, setIndexState] = useState('active')
  const [streamData, setStreamData] = useState({ items: [], total: 0 })
  const [previewItem, setPreviewItem] = useState({})
  const [isModalVisible, setModalVisible] = useState(false)
  const [isFetching, setFetching] = useState(false)

  useDidMountEffect(() => {
    if (selectedStream) {
      updateStreamData()
    }
  }, [selectedStream, language, indexState])

  async function updateStreamData() {
    setFetching(true)

    try {
      const data = await StreamStore.fetchStreamInspector({
        id: selectedStream,
        language,
        state: indexState,
      })
      setStreamData(data)
    } catch (error) {
      toast.error('Could not fetch stream data.')
    }

    setFetching(false)
  }

  async function handleSelection(record) {
    try {
      const item = await api.common.fetchDocumentPreview({
        type: 'product',
        esId: record.es_id,
        state: indexState,
        language,
      })

      setPreviewItem(item)
      setModalVisible(true)
    } catch (error) {
      toast.error('Could not fetch item preview.')
    }
  }

  const filters = [
    {
      type: 'stream-select',
      title: t('Stream'),
      name: 'stream',
      onChange: ({ stream }) => setSelectedStream(stream),
      value: selectedStream,
    },
    {
      type: 'single-select',
      title: t('Language'),
      name: 'language',
      onChange: ({ language: newLanguage }) => setLanguage(newLanguage),
      value: language,
      options: UIStore.languages.map((lang) => ({
        label: lang,
        value: lang,
      })),
    },
    {
      type: 'single-select',
      title: t('Index'),
      name: 'index',
      onChange: ({ index }) => setIndexState(index),
      value: indexState,
      options: [
        { label: t('active'), value: 'active' },
        { label: t('passive'), value: 'passive' },
      ],
    },
  ]

  return (
    <>
      <InspectorPageTitle />
      <ContentWrapper>
        <div className="importer-config__section-wrapper">
          <Text className="importer-config__guide-text" size="bravo">
            {t(`dataInspectorDescription`)}
          </Text>
        </div>
        <ResourceTableFilter filters={filters} />
        <div className="data-inspector__table">
          <ResourceTable
            loading={isFetching}
            store={UIStore}
            onRowClick={handleSelection}
            emptyText={
              selectedStream
                ? t('The selected stream does not contain any product.')
                : t('Please select a stream to view the containing products.')
            }
            heading={
              <h3>
                {t('Products')} ({streamData.total})
              </h3>
            }
            data={streamData.items ?? []}
          >
            <Column
              width={150}
              title={t('Image')}
              dataIndex=""
              render={(_, record) => <ImagePreview {...record} />}
            />
            <Column width={350} title={t('ID')} dataIndex="id" />
            <Column title={t('Title')} dataIndex="title" />
          </ResourceTable>
        </div>

        <ItemPreview
          isVisible={isModalVisible}
          setVisible={setModalVisible}
          item={previewItem}
        />
      </ContentWrapper>
    </>
  )
}

export default observer(StreamInspector)
