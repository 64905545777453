import cs from 'classnames'
import RcTable, { Column as RcColumn } from 'rc-table'
import { observer } from 'mobx-react-lite'

import Spinner from '../Spinner'
import Text from '../Text'
import EmptyTable from './EmptyTable'

import './table.styl'
import Icon from '../Icon'

function Table(props) {
  const {
    loading,
    data,
    createAction,
    createActionText,
    emptyText,
    title,
    hideTitle = false,
    emptyContainerSize = 'normal',
    filter,
  } = props

  const NoData = (
    <div style={{ textAlign: 'center' }}>
      <Text>{props.emptyText}</Text>
    </div>
  )

  return (
    <div
      className={cs('mk-table__wrapper', {
        'mk-table__wrapper--loading': loading,
      })}
    >
      {loading && <Spinner size="normal" />}
      {!loading && !data.length && !filter ? (
        <EmptyTable
          emptyText={emptyText}
          title={!hideTitle && title}
          createAction={createAction}
          emptyContainerSize={emptyContainerSize}
          createActionText={createActionText}
        />
      ) : (
        <RcTable
          {...props}
          rowClassName={
            props.rowClassName + (props.noStriped ? ' no-striped' : '')
          }
          prefixCls="mk-table"
          emptyText={NoData}
        >
          {props.children}
        </RcTable>
      )}
    </div>
  )
}

export const Column = observer(RcColumn)

export const ArrowAction = () => (
  <Icon
    className="mk-table__arrow-action"
    symbol="angle-right"
    width={18}
    height={18}
  />
)

export default observer(Table)
