import { getIn } from 'formik'
import { useCallback, useEffect, useState } from 'react'

import TextInput from './index'

import t from '../../utils/translate'
import debounce from '../../utils/debounce'
import checkMakairaURL from '../../utils/checkMakairaURL'

/**
 * For formik wrapped TextInput that checks if the given input
 * is a valid and free Makaira URL.
 * Input is validated on mount (if the field is not empty) and
 * every time the value is updated. (with 500ms debounce)
 */
const FormikURLInput = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id = '',
  type = '',
  ...rest
}) => {
  // We don't use Formiks "setFieldError" here because the validation in this component is not part
  // of Formiks validation cycle and our error would be overwritten everytime Formik validates.
  const [urlError, setURLError] = useState(null)
  // we check here if the initial value is empty because we don't want to validate empty fields on mount
  const [lastValue, setLastValue] = useState(value ?? null)
  const [isValidating, setIsValidating] = useState(false)

  const checkURL = async (val = '') => {
    if (!val.startsWith('/')) {
      setURLError(t('URL should start with a slash'))
      setIsValidating(false)
    } else {
      try {
        const checkResponse = await checkMakairaURL(val, id, type)

        if (!checkResponse) {
          setURLError(t('This URL is already in use.'))
        }
      } catch (e) {
        setURLError(null)
      } finally {
        setIsValidating(false)
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTest = useCallback(debounce(checkURL, 500), [])

  useEffect(() => {
    if (lastValue !== value) {
      setIsValidating(true)
      setURLError(null)
      debouncedTest(value)
    }

    setLastValue(lastValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <TextInput
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={(getIn(touched, name) && getIn(errors, name)) || urlError}
      loading={isValidating}
      {...rest}
    />
  )
}

export default FormikURLInput
