import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import Switch from '../../../components/Switch'
import Text from '../../../components/Text'
import TextInput from '../../../components/TextInput'
import StorefrontSettingStore from '../../../stores/StorefrontSettingStore'
import { t } from '../../../utils'

const Authentication = () => {
  const {
    configVars,
    newUsers,
    checkValidUser,
    removeAuthentication,
    openAuthenticationModal,
  } = StorefrontSettingStore

  const handleSwitchAuthentication = () => {
    if (newUsers.length > 0) return removeAuthentication()
    if (configVars.users?.length > 0) return openAuthenticationModal()

    return StorefrontSettingStore.addNewUser()
  }

  const handleAuthenticationSwitchTitle = () => {
    if (configVars.users?.length > 0 || newUsers.length > 0) {
      return (
        <>
          <strong>{t('Authentication is active')}</strong>.{' '}
          {t(
            'Click to disable authentication. This makes the storefront publicly available'
          )}
        </>
      )
    }

    return (
      <>
        <strong>{t('Storefront is public')}</strong>.{' '}
        {t('Click to enable authentication')}
      </>
    )
  }

  return (
    <>
      <div className="storefront-settings__sub-title">
        <Text size="echo" weight="bold">
          {t('Authentication / BasicAuth')}
        </Text>
      </div>
      <Switch
        checked={configVars.users?.length > 0 || newUsers.length > 0}
        onChange={handleSwitchAuthentication}
        className="storefront-settings__auth-switch"
        type="horizontal"
        title={handleAuthenticationSwitchTitle()}
      />
      {configVars.users?.length > 0 && (
        <div className="storefront-settings__section-wrapper storefront-settings__section-wrapper--authentication">
          <Text size="delta" weight="bold">
            {t('Users')}
          </Text>
          <div className="storefront-settings__variable-input-grid">
            <div>{t('User')}</div>
            <div></div>
            <div>{t('Password')}</div>
            <div></div>

            {configVars.users.map((user) => (
              <Fragment key={user.username}>
                <Text weight="bold">{user.username}</Text>
                <Icon symbol="chevron-right" width={15} height={15} />
                <Text weight="bold">••••••••••••</Text>
                <Button
                  onClick={() => StorefrontSettingStore.removeUser(user)}
                  level={0}
                  variant="tertiary"
                  icon="trash"
                />
              </Fragment>
            ))}
          </div>
        </div>
      )}

      {newUsers.length > 0 && (
        <div className="storefront-settings__variable-input-grid">
          <div>{t('User')}</div>
          <div></div>
          <div>{t('Password')}</div>
          <div></div>

          {newUsers.map((user) => (
            <Fragment key={user.name}>
              <TextInput
                onBlur={() => checkValidUser(user)}
                error={user.error.username}
                onChange={(e) =>
                  StorefrontSettingStore.updateUser(
                    user,
                    'username',
                    e.target.value
                  )
                }
                value={user.username}
              />
              <Icon symbol="chevron-right" width={15} height={15} />
              <TextInput
                onBlur={() => checkValidUser(user)}
                error={user.error.password}
                onChange={(e) =>
                  StorefrontSettingStore.updateUser(
                    user,
                    'password',
                    e.target.value
                  )
                }
                value={user.password}
              />
              <Button
                onClick={() => StorefrontSettingStore.removeUser(user, true)}
                level={1}
                variant="tertiary"
                icon="trash"
              />
            </Fragment>
          ))}
        </div>
      )}

      {(configVars.users?.length > 0 || newUsers.length > 0) && (
        <Button
          level={1}
          onClick={StorefrontSettingStore.addNewUser}
          iconPosition="left"
          variant="tertiary"
          icon="plus"
        >
          {t('Add user')}
        </Button>
      )}
    </>
  )
}

export default observer(Authentication)
