import { observer } from 'mobx-react-lite'
import Icon from '../../Icon'
import Text from '../../Text'
import { FieldStore, FilterSectionStore } from '../../../stores'
import { t } from '../../../utils'
import { ICON_TYPE } from '../constants'
import BoostingField from './BoostingField'
import CategoryField from './CategoryField'
import ComparisonField from './ComparisonField'
import ComparisonWithField from './ComparisonWithField'
import ManufacturerField from './ManufacturerField'
import ValueField from './ValueField'

const ChooseRule = ({ fieldOptions, canCreate, type, mode: editMode }) => {
  const { editingFilter, mode, config } = FilterSectionStore

  const hasInputProduct = config[type]?.hasInputProduct

  const selectedfilterField = FieldStore.fieldsByIds[editingFilter.field] || {}

  return (
    <div className="filter-section__rule-wrapper">
      {mode === 'create' && (
        <Text weight="bold" size="delta">
          {t('Define the rule')}
        </Text>
      )}

      <div
        className={`filter-section__rule-section filter-section__rule-section--${editMode}`}
      >
        {editingFilter.field && (
          <div className="filter-section__selected-field-wrapper">
            <div className="filter-section__rule-title">
              <Icon width="16px" height="16px" symbol="box" />
              <Text size="bravo" weight="bold">
                {t('All Products input')}
              </Text>
            </div>
            <div className="filter-section__selected-field">
              <Icon
                width="16px"
                height="16px"
                symbol={ICON_TYPE[selectedfilterField.type] || 'question'}
              />
              <Text weight="bold">{selectedfilterField.title}</Text>
            </div>
          </div>
        )}

        <CategoryField />
        <ManufacturerField />
        <ComparisonField type={type} />
        {hasInputProduct && <ComparisonWithField />}
        <ValueField fieldOptions={fieldOptions} type={type} />
        <BoostingField type={type} />

        {canCreate && (
          <>
            <Icon width="20px" height="20px" symbol="arrow-down" />
            <div className="filter-section__selected-field-wrapper">
              <div className="filter-section__rule-title">
                <Icon width="16px" height="16px" symbol="box" />
                <Text size="bravo" weight="bold">
                  {t('Filtered Products output')}
                </Text>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default observer(ChooseRule)
