import { observer } from 'mobx-react-lite'
import React from 'react'
import ProductPreview from '../../../components/ProductPreview/ProductPreview'
import Text from '../../../components/Text'
import { RecommendationStore, UIStore } from '../../../stores'
import { t, debounce } from '../../../utils'

const PreviewSection = () => {
  const handlePreviewChange = debounce((search, changedVariable) => {
    RecommendationStore.fetchRecommendationPreviewList({
      currentLanguage:
        RecommendationStore.previewLanguage ||
        UIStore.enterpriseConfiguration.defaultLanguage,
      shopId: UIStore.shopId,
      productId: search.productId,
      recommendation: RecommendationStore.currentRecoDetail,
      changedVariable,
      page: search.page || 1,
      group: search.group,
    })
  }, 500)

  return (
    <div className="recommendations__edit-section">
      <Text size="echo" weight="bold">
        {t('Preview the recommendation for...')}
      </Text>
      <ProductPreview
        tagsInputShown={
          RecommendationStore.currentRecoDetail.recommendationType !== 'none'
        }
        type="recommendation"
        singleRow
        typeSelectShown={false}
        onChange={handlePreviewChange}
        products={RecommendationStore.recommendationPreviewList}
        loading={RecommendationStore.previewState === 'pending'}
        withoutSearchText={t('Select products to see the preview.')}
        headline=""
        currentPage={RecommendationStore.previewPage + 1}
        maxPage={RecommendationStore.maxPreviewPage}
        maxProductsPerPage={RecommendationStore.MAX_PRODUCTS_IN_PREVIEW}
        onChangePreviewGroup={() => {}}
      />
    </div>
  )
}

export default observer(PreviewSection)
