import classNames from 'classnames'
import { useState, useEffect } from 'react'

import Text from '../Text'
import Tab from './Tab'

import t from '../../utils/translate'
import formatDateNow from '../../utils/formatDateNow'
import TextInput from '../TextInput'
import Button from '../Button'

import './pageTitle.styl'

const MetaInfo = ({
  timestamp,
  user,
  timezone,
  id,
  internalTitle,
  hiddenFields,
}) => {
  // const tooltip = moment.utc(timestamp).local().format('YYYY/MM/DD, h:mm:ss A')

  return (
    <div className="last-changed">
      <div>
        <span className="last-changed__text">{t('Published')}</span>
        <span className="last-changed__value">
          {formatDateNow(timestamp, timezone)}
        </span>

        <span className="last-changed__text">{t('by')}</span>
        <span className="last-changed__value">{user}</span>
      </div>
      <div>
        {
          !hiddenFields.includes(
            'internal-title' && (
              <>
                <span className="last-changed__text">
                  {t('Internal title')}
                </span>
                <span className="last-changed__value">
                  {internalTitle || t('no title yet')}
                </span>
              </>
            )
          )
        }

        <span className="last-changed__text">{t('ID')}</span>
        <span className="last-changed__value">{id}</span>
      </div>
    </div>
  )
}

const PageTitle = (props) => {
  const {
    className,
    children,
    prefix,
    additionalActions,
    metaInfo,
    tabData, // [{route: route object, title: string}]
    hiddenFields = [],
    title,
    onChangeTitle,
    ...rest
  } = props

  const [editMode, setEditMode] = useState(false)

  const [titleState, setTitleState] = useState(title)

  useEffect(() => {
    setTitleState(title)
  }, [title])

  const onChangeEditMode = (e) => {
    e?.preventDefault()
    if (editMode) {
      onChangeTitle(titleState)
    }
    setEditMode(!editMode)
  }

  return (
    <div className={classNames('page-title', className)} {...rest}>
      <div className="page-title__prefix">
        <Text element="p" size="delta" weight="book">
          {prefix}
        </Text>
      </div>
      <div className="page-title__main">
        {!editMode && (
          <Text
            element="p"
            className="page-title__title"
            size="golf"
            weight="heavy"
            data-cy="page-title"
            title={children}
          >
            {children}
          </Text>
        )}

        {onChangeTitle && editMode && (
          <form onSubmit={onChangeEditMode}>
            <TextInput
              width={500}
              value={titleState}
              onChange={(e) => setTitleState(e.target.value)}
              autoFocus
            />
          </form>
        )}
        {onChangeTitle && (
          <Button
            onClick={onChangeEditMode}
            level={1}
            variant="tertiary"
            icon={!editMode ? 'pencil' : 'check'}
            iconPosition="left"
          >
            {t(!editMode ? 'Edit' : 'Apply')}
          </Button>
        )}

        {tabData && (
          <span className="page-title-v3__suffix page-title-v3__suffix--tab">
            <Tab tabData={tabData} />
          </span>
        )}
      </div>

      {additionalActions && (
        <div className="page-title__additional">{additionalActions}</div>
      )}
      {metaInfo && <MetaInfo {...metaInfo} hiddenFields={hiddenFields} />}
    </div>
  )
}

export default PageTitle
