import { Popover } from 'antd'
import Icon from '../../../components/Icon'
import Menu from '../../../components/Menu'
import { useState } from 'react'

import './instanceSelectTag.styl'
import TextInput from '../../../components/TextInput'
import { t } from '../../../utils'

export default function RoleSelectTag(props) {
  const { value = [], options, onChange } = props
  const [visible, setVisible] = useState(false)
  const [search, setSearch] = useState('')

  const onSelectTag = (selectedKey) => {
    onChange(selectedKey.key)
  }

  const notAddedTags = options.filter(
    (option) =>
      !value.includes(option.value) &&
      (!search ||
        (search && option.label?.toLowerCase()?.includes(search.toLowerCase())))
  )

  if (notAddedTags.length === 0 && !search) return null

  const menu = (
    <>
      <TextInput
        value={search}
        rounded
        icon="magnifying-glass"
        placeholder={t('Search a role')}
        onChange={(e) => {
          e.stopPropagation()
          setSearch(e.target.value)
        }}
      />
      <Menu multiple onSelect={onSelectTag}>
        {notAddedTags.map((option) => (
          <Menu.MenuItem key={option.value}>{option.label}</Menu.MenuItem>
        ))}
      </Menu>
    </>
  )
  return (
    <div>
      <Popover
        trigger="click"
        content={menu}
        placement="bottomLeft"
        overlayClassName="instance-select-tag"
        destroyTooltipOnHide={false}
        visible={visible}
        onVisibleChange={(isVisible) => {
          setVisible(isVisible)
          setSearch('')
        }}
      >
        <Icon symbol={'plus'} className="instance-select-tag__icon" />
      </Popover>
    </div>
  )
}
