import { cleanUpPublishConfig, getDefaultFieldState } from '../index'
import { intersection, cloneDeep, get, xor, isEmpty, set } from 'lodash'
import { DEFAULT_VALUES } from '../../models/Page/defaultPageValues'
import LanguageConfig from '../../models/Component/LanguageConfig'
import PublishSetting from '../../models/Component/PublishSetting'

/**
 * Compares the actual page data to the component definitions and reset values to be the default value when
 * actual data and definition do not match.
 *
 * Does this be iterating over all elements of a page and all fields in all languages of this element.
 *
 * Component is here the structure definition.
 * Element is here the actual instance of a Component.
 *
 * @param pageContent {object} The content of the page that is returned by the API.
 * @param components {object[]} The definitions of the components.
 * @param languages {string[]} Array of language keys that will be checked.
 * @param publishConfigs {object[]} Array of public settings config
 * @returns {{isDifference: boolean, notifyUser: boolean, pageContent}}
 */
export default function prepareDataForPageEditor({
  pageContent,
  components,
  languages,
  publishConfigs,
}) {
  const sections = ['bottom', 'top']
  const newPageContent = cloneDeep(pageContent)
  const modifiedElements = []
  let hasDifferences = false
  let notifyUser = false

  // It may be the case that a language has been added or removed after the last save / creation
  // of the page. We check this here for all multi-language fields of the page object.
  // Only the element-configuration is checked later on
  const keysToCheck = ['active', 'seoUrls', 'metadata', 'searchable']
  hasDifferences = keysToCheck.reduce((previous, key) => {
    const filteredResult = filterUnavailableLanguages(
      newPageContent,
      languages,
      key,
      DEFAULT_VALUES[key]
    )
    newPageContent[key] = filteredResult.correctedData

    return previous || filteredResult.isDifferent
  }, false)

  sections.forEach((section) => {
    const elements = get(newPageContent, ['config', section, 'elements'])

    elements?.forEach((element) => {
      const componentName = element.component
      const component = getComponentByIdentifier(components, componentName)
      let modifiedElement = false

      if (component) {
        const fields = get(component, ['sections'], []).reduce(
          (allFields, sectionData) => {
            allFields = [...allFields, ...(sectionData.fields || [])]

            return allFields
          },
          []
        )

        // It may be the case that an index has been removed after using.
        // So we only keep languages that still exist in this instance.
        element.properties = languages.reduce((correctedProperties, lang) => {
          correctedProperties[lang] = element.properties[lang]

          return correctedProperties
        }, {})

        languages.forEach((lang) => {
          const content = get(element, ['properties', lang, 'content']) ?? {}

          // If the language has been added after the component was added we will generate
          if (!element.properties[lang]) {
            element.properties[lang] = new LanguageConfig(fields, false)
          }

          const newContent = {}

          fields.forEach((field) => {
            const cleanedUpResult = cleanUpField(field, content[field.id])
            hasDifferences = hasDifferences || cleanedUpResult.valueCorrected
            modifiedElement = modifiedElement || cleanedUpResult.valueCorrected
            notifyUser = notifyUser || cleanedUpResult.notifyUser

            newContent[field.id] = cleanedUpResult.correctedValue
          })

          // This assignment is important to remove all keys
          // that are not related to any component field.
          element.properties[lang].content = newContent

          // new Feature, need to add new Object for not existing properties
          if (element.properties[lang].personas) {
            // This assignment is important to remove all publish config
            // that are not available anymore.
            element.properties[lang].personas = cleanUpPublishConfig(
              publishConfigs,
              element.properties[lang].personas
            )
          } else {
            const userGroups = element.properties[lang].userGroups
            if (
              userGroups &&
              userGroups['Alle Benutzer'] &&
              userGroups['Alle Benutzer'].isTimed
            ) {
              set(element.properties[lang], 'personas', {
                All: cloneDeep(userGroups['Alle Benutzer']),
              })
            } else {
              set(element.properties[lang], 'personas', {
                All: new PublishSetting(),
              })
            }
          }
        })
      }
      if (modifiedElement && !modifiedElements.includes(element.id)) {
        modifiedElements.push(element.id)
      }
    })
  })

  return {
    isDifference: hasDifferences,
    notifyUser: notifyUser,
    pageContent: newPageContent,
    modifiedElements,
  }
}

function filterUnavailableLanguages(data, languages, key, defaultValue) {
  const isDifferent = xor(Object.keys(data[key] ?? {}), languages).length > 0

  const correctedData = languages.reduce((correctedProperties, lang) => {
    if (!data[key]) data[key] = {}

    correctedProperties[lang] = data[key][lang] ?? defaultValue

    return correctedProperties
  }, {})

  return {
    correctedData,
    isDifferent,
  }
}

function cleanUpField(field, value) {
  const fieldKey = field.id

  /*
   * At first, we check if the there were fields added to the component definition.
   * If components have new fields than they will not have a value in the stored content object.
   *
   * We will generate the new initial/default values for this field.
   *
   * Adding new fields will not lead to valueCorrected = true because we don't want to show
   * the modal at this time. Only if existing data has been changed this value will be true.
   */
  if (value === undefined) {
    return {
      correctedValue: getDefaultFieldState(field)[fieldKey],
      notifyUser: false,
      valueCorrected: true,
    }
  }

  /*
   * For an object we will for each field in this object the cleanUpField-function and
   * clean the object so recursively.
   *
   * Important to mention here is that we take the fields that we check from the definition
   * and not from the stored page object. By doing so we make sure that every field that is defined in
   * the component also is present with a value in the page object.
   *
   * Fields that were removed/renamed from the component but are present in the page object
   * are simply ignored and so removed.
   */
  if (field.type === 'object') {
    const { properties } = field
    return cleanUpObject(properties, value)
  }

  /* First for Arrays, we check if it is really an Array. This may not be the case for example when
   * the type of any field was changed from object to array. If it is the case we simply generate the default structure.
   *
   * If it is of type array we will check every entry of this array.
   */
  if (field.type === 'array') {
    if (!Array.isArray(value)) {
      return {
        correctedValue: getDefaultFieldState(field)[fieldKey],
        valueCorrected: true,
        notifyUser: true,
      }
    }

    return cleanUpArray(field.childConfig, value)
  }

  // When this point is reached we are at the leaf-level of a page-object, in other words
  // it is not nested deeper with arrays of objects.
  // We then check if the type of the value matches the field-type of the component definition.
  // If so we keep the value otherwise we generate a new default value.
  const hasCorrectValue = checkValueType(value, field)
  const finalValue = hasCorrectValue
    ? value
    : getDefaultFieldState(field)[fieldKey]

  return {
    correctedValue: finalValue,
    valueCorrected: !hasCorrectValue,
    notifyUser: !hasCorrectValue,
  }
}

function cleanUpObject(fields, value) {
  return fields.reduce(
    (acc, current) => {
      const { id } = current
      const res = cleanUpField(current, value[id])

      if (res.valueCorrected) acc.valueCorrected = true
      if (
        res.notifyUser ||
        // also notify user if existing data has been changed
        (res.valueCorrected &&
          (isNumber(value) || (!isEmpty(value) && !isNumber(value))))
      ) {
        acc.notifyUser = true
      }

      acc.correctedValue[id] = res.correctedValue
      return acc
    },
    {
      correctedValue: {},
      valueCorrected: false,
      notifyUser: false,
    }
  )
}

function cleanUpArray(field, value) {
  return value.reduce(
    (acc, entry) => {
      const res = cleanUpField(field, entry)

      if (res.valueCorrected) acc.valueCorrected = true
      if (res.notifyUser) acc.notifyUser = true

      acc.correctedValue.push(res.correctedValue)
      return acc
    },
    {
      correctedValue: [],
      valueCorrected: false,
      notifyUser: false,
    }
  )
}

/**
 * Checks if the actual value of a component field and the definition of this field in the component matches.
 * If they don't match the value will be set to the empty value corresponding to the component definition.
 *
 * @param value The value of the field with the given key that will be checked
 * @param field The field that is check in the function
 * @returns {boolean} Whether the value has the correct type.
 */
function checkValueType(value, field) {
  let valueCorrected = false

  const valueType = typeof value
  const isObject =
    valueType === 'object' && value !== null && !Array.isArray(value)

  if (hasType(field, ['hotspot']) && !isObject) {
    valueCorrected = true
  }

  if (hasType(field, ['text', 'richtext']) && valueType !== 'string') {
    valueCorrected = true
  }

  if (hasType(field, 'checkbox') && valueType !== 'boolean') {
    valueCorrected = true
  }

  if (hasType(field, 'number') && !isNumber(value) && value !== '') {
    valueCorrected = true
  }

  if (hasType(field, 'colorpicker') && !isColorValue(value)) {
    valueCorrected = true
  }

  if (hasType(field, 'multiselect') && !Array.isArray(value)) {
    valueCorrected = true
  }

  return !valueCorrected
}

/**
 * Get a component out of list of components.
 *
 * @param components List of components
 * @param identifier The identifier of the component that should be returned.
 * @returns {Object|undefined} The component or undefined otherwise.
 */
function getComponentByIdentifier(components, identifier) {
  return components.find((component) => component.identifier === identifier)
}

/**
 * Checks if a fields is of a given type.
 *
 * @param field The field that will be checked
 * @param type {string|string[]} One or more types of the field.
 * @returns {boolean} Whether the type of the field equals one of the given types.
 */
function hasType(field, type) {
  if (!field) return false

  return Array.isArray(type) ? type.includes(field.type) : type === field.type
}

/**
 * Check if a value is a number. Ignores the "real" JS datatype.
 * So "12" and 12 will both return true but "12b" will return false.
 *
 * @param value The value to check.
 * @returns {boolean} Whether the value is a number or not.
 */
function isNumber(value) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(value)
}

/**
 * Check if a value is a color object. This is the case when the value
 * is an object with the keys "hex", "hsla" and "rgba".
 *
 * @param value The value to check.
 * @returns {boolean} Whether the value is a color object or not.
 */
function isColorValue(value) {
  return (
    typeof value === 'object' &&
    intersection(Object.keys(value), ['hex', 'hsla', 'rgba']).length === 3
  )
}
