import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import api from '../../api'

class FilterSectionStore {
  state = 'pending' // "pending", "done" or "error"
  editingFilter = {}
  listFilter = {}
  mode = 'create'
  manufacturers = []
  manufacturersById = {}
  config = {}

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset(type) {
    this.editingFilter = {}
    this.mode = 'create'
    if (type) {
      this.listFilter[type] = undefined
      this.config[type] = {}
    } else {
      this.listFilter = {}
      this.config = {}
    }
  }

  setConfig(config, type) {
    const currentConfig = this.config[type] || {}
    this.config[type] = { ...currentConfig, ...config }
  }

  setMode(mode) {
    this.mode = mode
  }

  setEditingFilter(filter) {
    this.editingFilter = filter
  }

  appendListFilter(filter, type) {
    const list = this.listFilter[type] || []

    const position = list.findIndex((item) => item.uuid === filter.uuid)

    if (position !== -1) {
      list[position] = filter
    } else {
      list.push({
        ...filter,
        uuid: uuidv4(),
        boostingStrength: filter.boostingStrength || 'low',
      })
    }
    this.listFilter[type] = list
    return list
  }

  setListFilter(filters = [], type) {
    const list = filters.map((filter) => ({ ...filter, uuid: uuidv4() }))
    this.listFilter[type] = list
  }

  removeListFilter(uuid, type) {
    this.listFilter[type] = (this.listFilter[type] || []).filter(
      (item) => item.uuid !== uuid
    )
    return this.listFilter[type]
  }

  *fetchManufacturers() {
    try {
      const { data } = yield api.manufacturer.getAll()
      this.manufacturers = data
      this.manufacturers.forEach(
        (item) => (this.manufacturersById[item.shopid] = item)
      )
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }
}

export default new FilterSectionStore()
