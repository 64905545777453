import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Column, ArrowAction } from '../../components/Table'
import PlacementStore from '../../stores/PlacementStore'
import ResourceTable from '../../components/ResourceTable'
import { useHistory } from 'react-router-dom'
import ContentWrapper from '../../components/ContentWrapper'
import Text from '../../components/Text'
import DownloadAnalyticsButton from '../../components/DownloadAnalyticsButton'
import t from '../../utils/translate'
import ActionLayerStore from '../../stores/ActionLayerStore'
import createRedirectPath from '../../utils/createRedirectPath'
import Icon from '../../components/Icon'
import { formatScheduleDate, getResourceTableFilters } from '../Banners/utils'
import ActiveField from '../../components/ActiveField'
import './list.styl'

function BannerList() {
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    PlacementStore.fetchPlacements()
  }, [])

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    await PlacementStore.delete(selectedRows)

    if (PlacementStore.state !== 'error') {
      setSelectedRows([])
      ActionLayerStore.closeActionLayer()
    }
  }

  return (
    <>
      <ContentWrapper className="advertising-space-list">
        <ResourceTable
          title={t('Advertising Space')}
          store={PlacementStore}
          resourceName="placements"
          loading={PlacementStore.state === 'pending'}
          emptyText={t(
            'Nothing found - just create a new Advertising Space or reset your search & filters above.'
          )}
          filters={getResourceTableFilters(PlacementStore)}
          filterParams="filter"
          bulkActions={[
            {
              title: t('Delete'),
              onExecute: () => showDeleteWarning(),
            },
          ]}
          rowSelection={{
            selectedRows,
            onSelectionChange: (newRows) => setSelectedRows(newRows),
          }}
          onRowClick={(record) =>
            history.push(createRedirectPath(`/advertising-space/${record.id}`))
          }
          hideEmptyTextWhenNoFilters
          headerAction={{
            type: 'create',
            action: () =>
              history.push(createRedirectPath('/advertising-space/new')),
          }}
          createAction={() =>
            history.push(createRedirectPath('/advertising-space/new'))
          }
        >
          <Column
            width={70}
            title={t('Active')}
            dataIndex="active"
            align="center"
            render={(value) => <ActiveField active={value} />}
            sorter={true}
          />
          <Column
            title={t('Name (ID)')}
            render={(_, record) => (
              <>
                <span className="text text--bravo text--medium text--regular">
                  {record.name}&nbsp;
                  <span className="advertising-space-list__text-grey">
                    {record.id}
                  </span>
                </span>
              </>
            )}
            dataIndex="name"
            sorter={true}
          />
          <Column
            className="advertising-space-list__listType"
            title={t('Displayed on')}
            dataIndex="listType"
            sorter={true}
          />
          <Column
            title={t('Schedule start')}
            render={(_, record) => {
              const { textFrom, iconFrom, classFrom } =
                formatScheduleDate(record)
              return (
                <div className="advertising-space-list__schedule-date">
                  <Icon className={classFrom} symbol={iconFrom} size="1x" />
                  <Text className={classFrom}>{textFrom}</Text>
                </div>
              )
            }}
          />
          <Column
            title={t('Schedule end')}
            render={(_, record) => {
              const { textTo, iconTo, classTo } = formatScheduleDate(record)
              return (
                <div className="advertising-space-list__schedule-date">
                  <Icon className={classTo} symbol={iconTo} size="1x" />
                  <Text className={classTo}>{textTo}</Text>
                </div>
              )
            }}
          />
          <Column
            width={50}
            onCell={() => {
              return {
                onClick: (event) => {
                  event.stopPropagation()
                },
              }
            }}
            render={(_, record) => (
              <DownloadAnalyticsButton
                record={record}
                type="advertising_space"
              />
            )}
          />
          <Column width={50} render={() => <ArrowAction />} />
        </ResourceTable>
      </ContentWrapper>
    </>
  )
}

export default observer(BannerList)
