import UIStore from '../../stores/UIStore'

export default function processCloudinaryResponse(response = {}) {
  const { enterpriseConfiguration } = UIStore
  const { cloudinaryCloudName: cloudName } = enterpriseConfiguration
  const { public_id, format, resource_type, version } = response

  let fileName = `${public_id}.${format}`

  return JSON.stringify({
    fileName,
    resourceType: resource_type,
    version,
    cloudName,
  })
}
