import { notification } from 'antd'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'

import api from '../../api'
import User from '../../models/User'
import { t } from '../../utils'

class UserManagementStore {
  users = []
  userDetail = new User()
  instances = {
    stage: [],
    live: [],
  }
  roles = []

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }

  sorter = {
    field: 'changed',
    order: 'descend',
  }

  filter = {}
  state = 'pending' // "pending", "done" or "error"
  isFetchingUserDetail = true
  hideEmptyComponent = false
  resendingUserId = null
  isDirty = false

  instancesTableResource = []
  cusomterDomain = ''

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })

    if (
      process.env.REACT_APP_PROXY_STAGE &&
      process.env.NODE_ENV === 'development'
    ) {
      this.cusomterDomain = 'stage'
    } else if (process.env.NODE_ENV === 'development') {
      this.cusomterDomain = 'local'
    } else {
      this.customerDomain = window.location.host
        .replace('.makaira.io', '')
        .replace('makaira.vm', 'local')
        .replace(/localhost:\d*/, 'local')
        .toLowerCase()
    }
  }

  *fetchUsers() {
    this.state = 'pending'

    try {
      const { data, total } = yield api.userManagement.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.users = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
    }
  }

  *setFilter(filter) {
    if (filter.q && filter.q.length < 3) return
    this.pagination = {
      ...this.pagination,
      current: 1,
      total: 0,
    }
    this.filter = filter
    yield this.fetchUsers()
    this.hideEmptyComponent = true
  }

  setSorter(sorter) {
    this.sorter = sorter
    this.fetchUsers()
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchUsers()
  }

  *fetchUserDetail(id) {
    this.isFetchingUserDetail = true
    this.state = 'pending'
    yield Promise.all([this.fetchInstances(), this.fetchRoles()])
    if (id === 'new') {
      this.userDetail = new User()
      this.isFetchingUserDetail = false

      this.state = 'done'
      this.initializeInstanceTableResource()
      return
    }
    try {
      const { data } = yield api.userManagement.get(id)
      this.userDetail = data
      this.initializeInstanceTableResource()
      this.isFetchingUserDetail = false
      this.state = 'done'
    } catch (error) {
      toast.error(t('User could not be loaded.'))
      this.isFetchingUserDetail = false
      this.state = 'error'
    }
  }

  *updateOrCreate() {
    try {
      const userDomainRoles = {}
      for (let instanceRole of this.instancesTableResource) {
        if (instanceRole.enabled) {
          userDomainRoles[instanceRole.instance] = instanceRole.roles
        }
      }
      this.userDetail.instances = {
        ...userDomainRoles,
      }
      if (this.userDetail.user_id) {
        yield api.userManagement.update(this.userDetail)
        toast.success(t('User was successfully updated.'))
      } else {
        const { data } = yield api.userManagement.create(this.userDetail)
        notification.info({
          message: t(
            data.created
              ? 'Invitation mail was sent to user. Attention: The activation link is only valid for 24 hours.'
              : 'Invitation was sent. User can directly log in.'
          ),
          duration: 7,
          placement: 'center',
        })
        return get(data, 'users.0.user_id')
      }
      this.isDirty = false
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *delete(ids) {
    try {
      yield api.userManagement.delete(ids)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchUsers()
  }

  *fetchInstances() {
    try {
      const { data } = yield api.userManagement.getCategorizedInstances()
      this.instances = data
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong fetching instances...')
    }
  }

  *fetchRoles() {
    try {
      const { data } = yield api.rolesManagement.getAll({
        filter: { withPermissions: 'true' },
      })
      this.roles = data
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong fetching roles...')
    }
  }

  *resendInvitation(user) {
    this.resendingUserId = user.user_id
    try {
      yield api.userManagement.resendInvitation(user.email)
      this.resendingUserId = null
      toast.success(t('Invitation sent successfully'))
    } catch (error) {
      this.resendingUserId = null
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  setUserDetail(data) {
    this.userDetail = data
    this.isDirty = true
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  reset() {
    this.isFetchingUserDetail = true
    this.userDetail = new User()
    this.isDirty = false
  }

  initializeInstanceTableResource() {
    const envNames = Object.keys(this.instances)
    this.instancesTableResource = []
    for (let env of envNames) {
      if (
        this.instances[env] &&
        Array.isArray(this.instances[env]) &&
        this.instances[env].length > 0
      ) {
        for (let instance of this.instances[env]) {
          const roles = this.userDetail?.instances?.[instance] || []
          this.instancesTableResource.push({
            instance,
            environment: env,
            roles,
            enabled: roles && roles.length > 0,
          })
        }
      }
    }

    if (this.instancesTableResource.length > 0) {
      const roles = this.userDetail?.instances?.['*'] || []
      this.instancesTableResource = [
        {
          instance: '*',
          environment: '',
          roles,
          enabled: roles && roles.length > 0,
        },
        ...this.instancesTableResource,
      ]
    }
  }

  onChangeUserInstanceAccess(instance, environment, data = {}) {
    this.isDirty = true
    this.instancesTableResource = this.instancesTableResource.map((item) => {
      if (item.instance === instance && item.environment === environment) {
        return { ...item, ...data }
      }
      return item
    })
  }
}

export default new UserManagementStore()
