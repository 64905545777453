import * as Yup from 'yup'
import get from 'lodash/get'
import { Field, Formik } from 'formik'
import { observer } from 'mobx-react-lite'

import Text from '../../../components/Text'
import FormikTextInput from '../../../components/TextInput/FormikTextInput'
import UpdateStoreDirtyState from '../../../components/UpdateStoreDirtyState'

import PageSetting from './PageSetting'
import ContentSetting from './ContentSetting'
import SnippetSetting from './SnippetSetting'

import t from '../../../utils/translate'

import UIStore from '../../../stores/UIStore'
import EditorStore from '../../../stores/PageEditor/EditorStore'

const GeneralSettings = ({ formikRef }) => {
  const { pageToEdit, setPage } = EditorStore
  const { type } = pageToEdit

  const schema = prepareSchema()

  function prepareSchema() {
    const fields = {}
    if (pageToEdit.type === 'page') {
      fields.seoUrls = Yup.object({
        ...UIStore.languages.reduce((result, lang) => {
          result[lang] = Yup.string().when('active', {
            is: () => get(formikRef, `current.values.active.${lang}`, false),
            then: Yup.string().required(t('Please enter a correct url.')),
          })
          return result
        }, {}),
      })

      fields.metadata = Yup.object({
        ...UIStore.languages.reduce((result, lang) => {
          result[lang] = Yup.object({
            title: Yup.string().when('active', {
              is: () => get(formikRef, `current.values.active.${lang}`, false),
              then: Yup.string().required(t('Please enter a title.')),
            }),
          })
          return result
        }, {}),
      })
    }

    return Yup.object(fields)
  }

  const handleTitleChange = (event, formikProps) => {
    const { handleChange } = formikProps
    handleChange(event)

    // Always update the title in the store so that in the PageHeader
    // the user input is displayed.
    setPage({
      ...pageToEdit,
      internalTitle: event.target.value,
    })
  }

  const handleSubmit = (values) => setPage(values, true)

  return (
    <Formik
      innerRef={formikRef}
      initialValues={pageToEdit}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {(formikProps) => (
        <>
          <UpdateStoreDirtyState store={EditorStore} />
          <SnippetSetting
            pageToEdit={pageToEdit}
            formikProps={formikProps}
            languages={UIStore.languages}
          />

          <ContentSetting
            pageToEdit={pageToEdit}
            formikProps={formikProps}
            languages={UIStore.languages}
          />

          <PageSetting pageToEdit={pageToEdit} formikProps={formikProps} />

          {type !== 'snippet' && (
            <Field
              component={FormikTextInput}
              label={<Text variant="book">{t('Internal title')}</Text>}
              className="page-configuration__setting-grow"
              name="internalTitle"
              description={t('Will be shown in Makaira admin overviews')}
              onChange={(event) => handleTitleChange(event, formikProps)}
            />
          )}
        </>
      )}
    </Formik>
  )
}

export default observer(GeneralSettings)
