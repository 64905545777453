import { makeAutoObservable, toJS } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import AuthStore from '../AuthStore'
import UIStore from '../UIStore'
import { set } from 'lodash'

class PersonasStore {
  personas = []
  state = 'pending' // "pending", "done" or "error"

  detail = {}
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = {}

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.filter = {}
    this.sorter = {
      field: 'changed',
      order: 'descend',
    }
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchPersonas()
  }

  setSorter(sorter) {
    this.pagination = {
      ...this.pagination,
      current: 1,
      total: 0,
    }
    this.sorter = sorter
    this.fetchPersonas()
  }

  setFilter(filter) {
    this.pagination = {
      ...this.pagination,
      current: 1,
      total: 0,
    }
    this.filter = filter
    this.fetchPersonas()
  }

  *getById(id) {
    this.state = 'pending'
    this.editLanguage = UIStore.languages[0]
    this.detail = {
      name: { de: '', en: '' },
      description: { de: '', en: '' },
      identifier: '',
      updatedBy: AuthStore.user?.email,
    }
    if (id === 'new') {
      this.state = 'done'
      return
    }

    const dataInList = this.personas.find((persona) => persona.id === id)

    if (dataInList) {
      this.detail = { ...toJS(dataInList) }
      this.state = 'done'
      return
    }

    try {
      const { data } = yield api.common.getPersona(id)
      this.detail = data
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the placement detail.')
      this.state = 'error'
    }
  }

  *updateOrCreate() {
    try {
      if (this.detail.id) {
        yield api.common.updatePersona(this.detail)
      } else {
        yield api.common.createPersona(this.detail)
      }
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *delete(ids) {
    try {
      yield api.common.deletePersonas(ids)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchPersonas()
  }

  onChangeDetail(key, value) {
    set(this.detail, key, value)
  }

  *fetchPersonas() {
    try {
      const { data } = yield api.common.getPersonas({
        pagination: this.pagination,
        filter: this.filter,
      })
      this.personas = data
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }
}

export default new PersonasStore()
