import { FieldArray } from 'formik'
import OperatorComponent from './OperatorComponent'
import Button from '../Button'
import FieldSelect from './FieldSelect'
import OperatorSelect from './OperatorSelect'
import Text from '../Text'

import './fieldConditions.styl'

export default function FieldConditions(props) {
  const { name, title, conditions, fields, formikProps } = props

  return (
    <div className="field-conditions" data-cy="field-conditions">
      <Text element="p" size="charlie" weight="medium">
        {title}
      </Text>

      <FieldArray name={name}>
        {(arrayHelpers) => (
          <>
            <div>
              {conditions.map((condition, index) => (
                <div
                  key={`${index}-${condition.field}`}
                  className="field-conditions__row"
                  data-testid="field-conditions__row"
                >
                  <Button
                    icon="trash"
                    variant="reduced"
                    level={1}
                    className="field-conditions__remove-button"
                    onClick={() => arrayHelpers.remove(index)}
                  />

                  <FieldSelect
                    name={`${name}.${index}`}
                    condition={condition}
                    fields={fields}
                    formikProps={formikProps}
                  />

                  <OperatorSelect
                    name={`${name}.${index}.operator`}
                    condition={condition}
                    formikProps={formikProps}
                  />

                  <OperatorComponent
                    name={`${name}.${index}.value`}
                    condition={condition}
                    formikProps={formikProps}
                  />
                </div>
              ))}
            </div>

            <Button
              variant="tertiary"
              level={1}
              icon="plus"
              className="field-conditions__add-button"
              onClick={() =>
                arrayHelpers.push({
                  field: '',
                  type: '',
                  operator: '',
                  value: '',
                })
              }
            />
          </>
        )}
      </FieldArray>
    </div>
  )
}
