import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import isEqualWith from 'lodash/isEqualWith'
import api from '../../api'
import SmartBundle, { Slot } from '../../models/SmartBundle'
import uniqWith from 'lodash/uniqWith'
import difference from 'lodash/difference'
import t from '../../utils/translate'
import moment from 'moment'
import { ActionLayerStore } from '../index'

class SmartBundleStore {
  bundles = []
  revisions = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = {}
  state = 'pending' // "pending", "done" or "error"

  isEditModalVisible = false
  isConnectionModalVisible = false
  isConfirmRestoreModalVisible = false
  bundleToEdit = {}
  isDirty = false
  requireImport = false
  slotToEdit = {}
  selectedSlotIdToEdit = null
  connectionToEdit = {}
  showConfiguration = false
  revisionIdToRestore = null
  autofilledFieldChanged = false
  selectedSlotsToConnect = [] // pairs of slot id like ['s1','s2']
  connectionButtonsClicked = false
  addNewConnection = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  resetInitialValues() {
    this.slotToEdit = {}
    this.addNewConnection = false
    this.selectedSlotIdToEdit = null
    this.connectionToEdit = {}
    this.revisionIdToRestore = null
    this.selectedSlotsToConnect = []
    this.connectionButtonsClicked = false
    this.autofilledFieldChanged = false
    this.isDirty = false
    this.isEditModalVisible = false
    this.isConnectionModalVisible = false
    this.isConfirmRestoreModalVisible = false
  }

  editBundle(bundle) {
    this.resetInitialValues()

    this.bundleToEdit = bundle

    this.correctConnectionsOrder()

    // default select first slot
    this.selectedSlotIdToEdit = this.bundleToEdit.slots[0].id
  }

  setBundle(bundle) {
    this.bundleToEdit = bundle
  }

  addSlot() {
    this.slotToEdit = new Slot({
      id: this.getFreeSlotId(),
    })

    this.isEditModalVisible = true
    this.requireImport = true
  }

  editSlot(slot) {
    this.slotToEdit = slot
    this.isEditModalVisible = true
  }

  setSelectedSlot(slotId) {
    this.selectedSlotIdToEdit = slotId
  }

  getSelectedSlot() {
    return (
      this.bundleToEdit.slots.find((s) => s.id === this.selectedSlotIdToEdit) ||
      {}
    )
  }

  updateSlot(slot) {
    const indexToUpdate = this.bundleToEdit.slots.findIndex(
      (s) => s.id === slot.id
    )

    if (indexToUpdate === -1) {
      this.bundleToEdit.slots.push(slot)

      this.requireImport = true
    } else {
      const currentSlot = this.bundleToEdit.slots[indexToUpdate]

      this.requireImport = !isEqualWith(
        slot.base_stream,
        currentSlot.base_stream
      )

      this.bundleToEdit.slots[indexToUpdate] = slot
    }

    this.isDirty = true

    this.closeEditModal()
  }

  /**
   * Find a free slot id of the current bundleToEdit.
   * Free slot id will always be the current highest id plus 1.
   *
   * E.g ids ["s1", "s3", "s10"] will generate 11
   *
   * @returns {number} free numeric id
   */
  getFreeSlotId() {
    let highestId = 0

    this.bundleToEdit.slots.forEach((slot) => {
      const numericIdPart = Number(slot.id?.substring(1) ?? 0)

      if (numericIdPart > highestId) highestId = numericIdPart
    })

    return highestId + 1
  }

  /**
   * Get the position of a slot by the id of the slot.
   * Position of the slot equals the position of the slot in the slots array.
   * If slot id is undefined the slot is not added to the array so it is at the end of the array ->
   * Position equals array size + 1.
   *
   * @param slot
   * @returns {number} Position of the slot in the slots array.
   */
  getSlotPosition(slot) {
    if (
      slot?.id === undefined ||
      this.bundleToEdit.slots.find((s) => s.id === slot.id) === undefined
    )
      return this.bundleToEdit.slots.length + 1

    return this.bundleToEdit.slots.findIndex((s) => s.id === slot.id) + 1
  }

  deleteSlot(slot) {
    const indexToUpdate = this.bundleToEdit.slots.findIndex(
      (s) => s.id === slot?.id
    )

    if (indexToUpdate === -1) {
      return
    }

    // remove all connections that have deleted slot as first or second slot
    this.bundleToEdit.slot_connections =
      this.bundleToEdit.slot_connections.filter(
        (c) => c.first_slot.id !== slot.id && c.second_slot.id !== slot.id
      )

    if (this.bundleToEdit.slots.length > 2) {
      this.bundleToEdit.slots.splice(indexToUpdate, 1)
    } else {
      // when the first slot is deleted -> move second slot to first slot so first slot is always filled
      if (indexToUpdate === 0) {
        this.bundleToEdit.slots[0] = {
          ...this.bundleToEdit.slots[1],
        }
      }

      // create new slot so that there are never less than 2 slots
      this.bundleToEdit.slots[1] = new Slot({
        id: this.getFreeSlotId(),
      })
    }

    this.isDirty = true

    this.closeEditModal()
  }

  updateSlotRequiredStatus({ id, required }) {
    if (id) {
      const slot = this.bundleToEdit.slots.find((s) => s.id === id)

      slot.required = required
    } else {
      /*
       * This branch is relevant in cases where a user attempts to change the required state
       * of a slot without actually having added the slot first using the UI.
       */
      const slot = new Slot({
        id: this.getFreeSlotId(),
        required,
      })

      this.bundleToEdit.slots.push(slot)
    }
    this.isDirty = true
  }

  closeEditModal() {
    this.isEditModalVisible = false
    this.slotToEdit = {}
  }

  addConnection(connection) {
    this.setConnectionsButtonClicked(false)
    this.bundleToEdit.slot_connections.push(connection)

    this.selectedSlotsToConnect = [
      connection.first_slot.id,
      connection.second_slot.id,
    ]

    this.isConnectionModalVisible = true
  }

  /**
   * Check if a given connection is the currently by the user selected connection.
   * (The user can select two slots when he wants to create/edit a connection between two slots.)
   *
   * @param connection
   * @returns {boolean}
   */
  isSelectedConnection(connection) {
    const slots = [connection.first_slot.id, connection.second_slot.id]

    return difference(slots, this.selectedSlotsToConnect).length === 0
  }

  /**
   * Get all connections between the two slots the user has currently selected to edit.
   * If the addNewConnection field is set to true an new empty connection will be added to the list
   * of filtered connections.
   *
   * @returns Array of connections
   */
  getConnectionsForEdit() {
    if (!this.selectedSlotsToConnect) {
      return []
    }

    const filteredConnections = this.bundleToEdit.slot_connections.filter(
      this.isSelectedConnection
    )

    if (this.addNewConnection) {
      const newConnection = {
        first_slot: { id: this.selectedSlotsToConnect[0] },
        second_slot: { id: this.selectedSlotsToConnect[1] },
      }

      return [...filteredConnections, newConnection]
    }

    return filteredConnections
  }

  updateConnection(connections) {
    this.bundleToEdit.slot_connections =
      this.bundleToEdit.slot_connections.filter(
        (connection) => !this.isSelectedConnection(connection)
      )

    this.bundleToEdit.slot_connections = [
      ...this.bundleToEdit.slot_connections,
      ...connections,
    ]

    this.isDirty = true
    this.closeConnectionModal()
  }

  /**
   * Deletes all connections between the both currently selected slots.
   */
  deleteConnection() {
    this.bundleToEdit.slot_connections =
      this.bundleToEdit.slot_connections.filter(
        (connection) => !this.isSelectedConnection(connection)
      )

    this.isDirty = true

    this.closeConnectionModal()
  }

  getUniqueConnections(connections) {
    return uniqWith(connections, (connection, other) => {
      const connectionSlots = [
        connection.first_slot.id,
        connection.second_slot.id,
      ]

      const otherConnectionSlots = [other.first_slot.id, other.second_slot.id]

      return difference(connectionSlots, otherConnectionSlots).length === 0
    })
  }

  /**
   * Add a new slot id to the list of selected slots.
   * If two slots are in this list the connection edit modal for these two slots
   * will be opened and contain a new connection.
   * We do not directly add a new connection because we only want to persist the store
   * when the user clicks on the save button in the modal.
   *
   * @param id
   */
  selectSlotToConnect(id) {
    this.setConnectionsButtonClicked(true)
    this.selectedSlotsToConnect.push(id)

    if (this.selectedSlotsToConnect.length === 2) {
      this.selectedSlotsToConnect = this.selectedSlotsToConnect.sort()
      this.connectionButtonsClicked = false
      this.isConnectionModalVisible = true
      this.addNewConnection = true
    }
  }

  removeSlotSelected(id) {
    this.selectedSlotsToConnect = this.selectedSlotsToConnect.filter(
      (slotId) => slotId !== id
    )
    this.connectionButtonsClicked = false
  }

  openConnectionModal(connection) {
    this.connectionButtonsClicked = false
    this.selectedSlotsToConnect = [
      connection.first_slot.id,
      connection.second_slot.id,
    ]
    this.isConnectionModalVisible = true
  }

  closeConnectionModal() {
    this.isConnectionModalVisible = false
    this.addNewConnection = false
    this.selectedSlotsToConnect = []
  }

  updateBundleField(name, value) {
    this.bundleToEdit[name] = value

    this.isDirty = true
  }

  updateBundleLanguageField(name, language, value) {
    this.bundleToEdit[name][language] = value
    this.isDirty = true
  }

  updateSelectedSlot(name, value, language) {
    const indexToUpdate = this.bundleToEdit.slots.findIndex(
      (s) => s.id === this.selectedSlotIdToEdit
    )

    const slot = this.bundleToEdit.slots[indexToUpdate]

    this.bundleToEdit.slots[indexToUpdate] = {
      ...slot,
      [name]: {
        ...slot[name],
        [language]: value,
      },
    }

    this.isDirty = true
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchBundles()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.pagination = {
      ...this.pagination,
      current: 1,
    }
    this.fetchBundles()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.pagination = {
      ...this.pagination,
      current: 1,
    }
    this.fetchBundles()
  }

  setRequireImport(require) {
    this.requireImport = require
  }

  *getById(id) {
    this.resetInitialValues()
    this.state = 'pending'
    if (!id) {
      this.state = 'done'
      return new SmartBundle()
    }

    // check store if already fetched
    let bundle = this.bundles.find((b) => b.id === id)

    if (!bundle) {
      bundle = yield api.bundles.getById(id)
    }

    this.state = 'done'

    // fetch from api
    return bundle
  }

  *fetchBundles() {
    this.state = 'pending'

    try {
      const { data, total } = yield api.bundles.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.bundles = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      ActionLayerStore.openActionLayer({
        error: t('Something went wrong loading the smart bundles.'),
        saveTitle: t('Try again'),
        onSave: () => {
          this.fetchBundles()
        },
        onClose: () => {},
      })
      this.state = 'error'
    }
  }

  *updateOrCreate() {
    this.state = 'pending'
    try {
      let response
      if (this.bundleToEdit.id) {
        response = yield api.bundles.update(this.bundleToEdit)
      } else {
        response = yield api.bundles.create(this.bundleToEdit)
      }
      this.state = 'done'
      return response
    } catch (error) {
      this.state = 'error'
    }
  }

  *delete(ids) {
    this.state = 'pending'

    try {
      yield api.bundles.bulkDelete(ids)
      this.state = 'done'
      toast.success(
        ids.length > 1
          ? t('Smart Bundles have been deleted.')
          : t('Smart Bundle has been deleted.')
      )

      // back to previou page if we deleted all bundles in current page
      if (ids.length === this.bundles.length && this.pagination.current > 1) {
        this.pagination = {
          ...this.pagination,
          current: this.pagination.current - 1,
        }
      }
    } catch (error) {
      this.state = 'error'
      ActionLayerStore.openActionLayer({
        error:
          ids.length > 1
            ? t('Smart Bundles could not be deleted.')
            : t('Smart Bundle could not be deleted.'),
        saveTitle: 'OK',
        onSave: () => {},
      })
      return
    }

    this.fetchBundles()
  }
  setDirty(value = true) {
    this.isDirty = value
  }

  *fetchRevisions() {
    this.state = 'pending'

    try {
      this.revisions = yield api.bundles.getRevisions(this.bundleToEdit.id)
      this.state = 'done'
    } catch (error) {
      ActionLayerStore.openActionLayer({
        error: t(
          'Something went wrong loading the revisions of the smart bundle.'
        ),
        saveTitle: t('OK'),
        onSave: () => {},
      })
      this.state = 'error'
    }
  }

  *restoreRevision(revision) {
    if (revision.id) {
      this.revisionIdToRestore = revision.id

      if (this.isDirty) {
        this.isConfirmRestoreModalVisible = true
        return
      }
    }

    this.state = 'pending'

    try {
      this.bundleToEdit = yield api.bundles.restoreRevision(
        this.bundleToEdit.id,
        this.revisionIdToRestore
      )
      this.correctConnectionsOrder()
      this.state = 'done'
      toast.success(
        t('revision_restored')(
          moment(revision.changed.date).format('DD.MM.yyyy HH:mm')
        )
      )
      this.state = 'done'
      this.isDirty = false
      this.showConfiguration = false
      this.isConfirmRestoreModalVisible = false
    } catch (error) {
      this.state = 'error'
      ActionLayerStore.openActionLayer({
        error: t(
          'Something went wrong restoring the revision of the smart bundle.'
        ),
        saveTitle: t('OK'),
        onSave: () => {},
      })
    }
  }

  /**
   * In the connection edit modal form the left input is always assigned to the slot with the lower id.
   * To achieve this the first_slot.id has to be the lower one. To be sure that this is always the
   * case we check the incoming data and swap first_slot with second_slot if the order is not correct.
   */
  correctConnectionsOrder() {
    this.bundleToEdit.slot_connections = this.bundleToEdit.slot_connections.map(
      (connection) => {
        const idFirstSlot = Number(
          connection?.first_slot?.id?.substring(1) ?? 0
        )
        const idSecondSlot = Number(
          connection?.second_slot?.id?.substring(1) ?? 0
        )

        // If the second slot has a lower id of the first slot we need to swap both slots in order to
        // always the slot with the lower id on the left side of the connection edit modal form.
        if (idSecondSlot < idFirstSlot) {
          const firstSlot = { ...connection.first_slot }
          const secondSlot = { ...connection.second_slot }

          return {
            ...connection,
            first_slot: secondSlot,
            second_slot: firstSlot,
          }
        }

        return connection
      }
    )
  }

  setShowConfiguration(isShow) {
    this.showConfiguration = isShow
  }

  closeConfirmRestoreModal() {
    this.isConfirmRestoreModalVisible = false
    this.revisionIdToRestore = null
  }

  setConnectionsButtonClicked(value) {
    this.connectionButtonsClicked = value
  }
}

export default new SmartBundleStore()
