import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import { t } from '../../utils'

import './styles.styl'

function TrackingSection({ viewTrackingId, clickTrackingId }) {
  if (!viewTrackingId || !clickTrackingId) return null

  return (
    <div className="tracking-section">
      <Text size="echo" weight="bold">
        {t('Tracking')}
      </Text>
      <div className="section__row">
        <TextInput label={'View-Tracking-ID'} value={viewTrackingId} disabled />
        <TextInput
          label={'Click-Tracking-ID'}
          value={clickTrackingId}
          disabled
        />
      </div>
    </div>
  )
}

export default TrackingSection
