import React, { useState } from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import DragOverlay from './DragOverlay'
import SortableItem from './SortableItem'

function Sortable(props) {
  const { items } = props
  const [activeIndex, setActiveIndex] = useState(null)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  function handleDragEnd(event) {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = active.data.current.sortable.index
      const newIndex = over.data.current.sortable.index
      const newArray = arrayMove(items, oldIndex, newIndex)

      if (props.onChange) {
        props.onChange(newArray)
      }
    }
  }

  const activeItem = items.find((item) => item.id === activeIndex) || {}

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={({ active }) => {
        if (!active) {
          return
        }

        setActiveIndex(active.id)
      }}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items?.map((element) => (
          <SortableItem
            key={element.id}
            field={element}
            itemComponent={props.itemComponent}
            id={element.id}
          />
        ))}
      </SortableContext>
      <DragOverlay>
        <props.itemComponent field={activeItem} />
      </DragOverlay>
    </DndContext>
  )
}

export default Sortable
export { default as SortableItem } from './SortableItem'
export { default as DragOverlay } from './DragOverlay'
