export function Slot({ id = 1, required = true } = {}) {
  return {
    id: `s${id}`,
    title: {},
    button_text: {},
    image: {},
    required,
    base_stream: {
      and: [
        {
          field: 'active',
          type: 'boolean',
          operator: 'isTrue',
        },
      ],
      or: [],
    },
  }
}

export default function SmartBundle() {
  return {
    internal_title: '',
    title: {},
    url: {},
    identifier: '',
    slots: [new Slot({ id: 1 }), new Slot({ id: 2 })],
    slot_connections: [],
    active: true,
  }
}
