import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import Icon from '../../../components/Icon'
import Text from '../../../components/Text'

import { ComponentStore, EditorStore } from '../../../stores'
import { t } from '../../../utils'
import getFieldContent from '../../../utils/pageEditor/getFieldContent'
import Button from '../../../components/Button'
import ContextMenu from './ContextMenu/ContextMenu'

export const Item = observer(
  ({ listeners, attributes, hidden, isOverlay, ...props }, ref) => {
    const { field, ...rest } = props
    const component = ComponentStore.getByIdentifier(field.component) || {}

    const unavailableComponent = isEmpty(component)
    const deprecatedComponent = component.deprecated

    const { editElement, getFieldTimeStatus, getActiveField } = EditorStore

    const { showClock, showUser } = getFieldTimeStatus(field)
    const fieldContent = getFieldContent(field)
    const isActive = getActiveField(field)

    const isGridComponent = field.id === 'grid' || field.id === 'detail-grid'

    const getContentPreview = () => {
      if (fieldContent?.length) {
        return fieldContent
      }

      const content = get(
        field,
        ['properties', EditorStore.contentLanguage, 'content'],
        {}
      )

      // we only want to show "no content yet" if the component has fields
      if (Object.keys(content).length) {
        return t('No content yet')
      }

      return ''
    }

    const getSymbol = () => {
      if (unavailableComponent) return 'unavailable-component'

      return component.icon || '001-pict-heading-text-cta-L'
    }

    const handleAddComponent = (e) => {
      e.stopPropagation()
      EditorStore.selectElementOnAdd(
        EditorStore.elements.find((element) => element.id === field.id)
      )
      EditorStore.showAddElementModal()
    }

    return (
      <>
        <div
          onClick={() =>
            !isGridComponent && !unavailableComponent && editElement(field)
          }
          {...rest}
          ref={ref}
          className={classNames('page-editor__field', {
            'page-editor__field--active': isActive,
            'page-editor__field--is-grid': isGridComponent,
            'page-editor__field--hidden': hidden,
            'page-editor__field--is-overlay': isOverlay,
            'page-editor__field--unavailable': unavailableComponent,
            'page-editor__field--deprecated': deprecatedComponent,
            'page-editor__field--modified':
              EditorStore.modifiedElements.includes(field.id),
            'page-editor__field--invalid-data':
              EditorStore.invalidData.component[field.id],
          })}
          title={`${component.name || field.name || ''}`}
        >
          {!isGridComponent && (
            <span className="page-editor__field-active"></span>
          )}
          {!isGridComponent && (
            <ContextMenu
              type="component"
              field={field}
              isComponentAvailable={!unavailableComponent}
              isComponentDeprecated={deprecatedComponent}
            />
          )}
          {EditorStore.elementsWithAnimationPlaying.includes(field.id) && (
            <div className="page-editor__field-copied">
              <Icon symbol="check" />
            </div>
          )}
          <span
            {...attributes}
            {...listeners}
            onClick={(e) => e.stopPropagation()}
            className="page-editor__field-drag"
          >
            <Icon symbol="bars" />
          </span>
          {isGridComponent ? (
            <div className="page-editor__field-info">
              <div>
                <Text
                  className="page-editor__field-grid-text"
                  element="div"
                  variant="book"
                >
                  {t(
                    field.id === 'detail-grid'
                      ? 'Product details'
                      : 'Product Grid'
                  )}
                </Text>
                <Icon
                  className="page-editor__field-icon page-editor__field-icon--no-border"
                  width="400px"
                  height={field.id === 'detail-grid' ? '250px' : '200px'}
                  symbol={
                    field.id === 'detail-grid' ? '000-detail-grid' : '000-grid'
                  }
                  makairaIcon
                />
              </div>
            </div>
          ) : (
            <>
              <div className="page-editor__field-info">
                <Icon
                  className="page-editor__field-icon"
                  width="92px"
                  height="36px"
                  symbol={getSymbol()}
                  makairaIcon
                />
                <div className="page-editor__field-content">
                  <Text className="page-editor__field-name" variant="book">
                    {unavailableComponent ? field.component : component.name}
                    {unavailableComponent && (
                      <Text className="page-editor__text-unavailable">
                        {' '}
                        · {t('Component not available')}
                      </Text>
                    )}
                    {deprecatedComponent && (
                      <Text className="page-editor__text-deprecated">
                        {' '}
                        · {t('Deprecated')}
                      </Text>
                    )}
                  </Text>
                  <Text
                    className={classNames({
                      'page-editor__no-content': !fieldContent,
                    })}
                  >
                    {getContentPreview()}
                  </Text>
                </div>
              </div>
              {(showClock || showUser) && (
                <div
                  className="page-editor__field-status"
                  onClick={() => {
                    EditorStore.editElement(field)
                    EditorStore.showSettings()
                  }}
                >
                  {showClock && (
                    <Icon symbol="clock__solid" width="18px" height="14px" />
                  )}
                  {showUser && (
                    <Icon symbol="user__solid" width="18px" height="14px" />
                  )}
                </div>
              )}
            </>
          )}
          {!isGridComponent && (
            <div className="page-editor__add-bar">
              <Button
                onClick={handleAddComponent}
                variant="icon-only"
                icon="plus"
              />
            </div>
          )}
        </div>
      </>
    )
  },
  { forwardRef: true }
)

const SortableItem = (props) => {
  const { field, hidden } = props
  const { id } = field
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      transition: {
        duration: 150, // milliseconds
        easing: 'ease',
      },
    })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Item
      ref={setNodeRef}
      style={style}
      attributes={attributes}
      listeners={listeners}
      field={field}
      hidden={hidden}
    />
  )
}

export default observer(SortableItem)
