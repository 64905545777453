import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

// TODO: getBoostStreams() method and implement relevant API endpoint in Symfony
export default {
  getAll(options) {
    const queryString = buildQueryString(options)
    const target = `/stream?${queryString}`
    return fetch(target, { stage: true })
  },

  create(stream) {
    return fetch('/stream', { method: 'POST', body: JSON.stringify(stream) })
  },

  update(stream) {
    return fetch(`/stream/${stream.id}`, {
      method: 'PUT',
      body: JSON.stringify(stream),
    })
  },

  async delete(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))

    await fetch(`/stream?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },

  get(id) {
    return fetch(`/stream/${id}`)
  },

  async getProductsById({
    id,
    language,
    fields = ['id', 'es_id', 'title', 'ean', 'picture_url_main'],
    state = 'active',
    count = 200,
  }) {
    const body = {
      count,
      fields,
      state,
    }

    const { data } = await fetch(`/stream/documents/${id}/${language}`, {
      method: 'POST',
      body: JSON.stringify(body),
    })

    return data
  },

  async getProductsPreview({
    config,
    language,
    group,
    fields = ['id', 'es_id', '*title*', 'ean', 'picture_url_main'],
    count = 20,
    offset = 0,
  }) {
    const body = {
      config,
      fields,
      count,
      offset,
      group,
    }

    const { data } = await fetch(`/stream/documents/preview/${language}`, {
      method: 'POST',
      body: JSON.stringify(body),
    })

    return data
  },

  copy(payload) {
    return fetch(`/stream/copy`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
  },

  streamUsages({ id, type, ...options }) {
    const queryString = buildQueryString(options)
    const target = `/stream/${id}/usage?type=${type}&${queryString}`
    return fetch(target)
  },
}
