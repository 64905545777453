import csx from 'classnames'

import Icon from '../Icon'

import './pagination.styl'
import { useEffect, useState } from 'react'

function Pagination(props) {
  const [currentPage, setCurrentPage] = useState(props.initialCurrentPage ?? 1)

  const { maxPage = 1, onPageSwitch = () => {} } = props

  useEffect(() => {
    if (props.currentPage !== undefined) setCurrentPage(props.currentPage)
  }, [props.currentPage])

  const finalMaxPage = maxPage < 1 ? 1 : maxPage

  const handlePageSwitch = (page) => {
    let finalPage = page

    if (page > finalMaxPage) {
      finalPage = finalMaxPage
    } else if (page < 1) {
      finalPage = 1
    }

    if (finalPage === currentPage) return

    onPageSwitch(finalPage)
    setCurrentPage(finalPage)
  }

  const pages = []

  let startPage = 2
  let endPage = finalMaxPage - 1

  if (finalMaxPage > 5) {
    if (currentPage < 3) {
      endPage = 3
    } else {
      endPage = currentPage + 1
      startPage = currentPage - 1

      if (currentPage > finalMaxPage - 4) {
        endPage = finalMaxPage - 1
      }

      if (currentPage > finalMaxPage - 2) {
        startPage = finalMaxPage - 2
      }
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <button
        className={csx('pagination__page', {
          ['pagination__page--active']: i === currentPage,
        })}
        key={i}
        onClick={() => handlePageSwitch(i)}
      >
        {i}
      </button>
    )
  }

  return (
    <div className="pagination">
      <button
        className={csx('pagination__nav-button', {
          ['pagination__nav-button--disabled']: 1 === currentPage,
        })}
        onClick={() => handlePageSwitch(currentPage - 1)}
      >
        <Icon symbol="chevron-left" />
      </button>
      <div className="pagination__pages">
        <button
          className={csx('pagination__page', {
            ['pagination__page--active']: 1 === currentPage,
          })}
          onClick={() => handlePageSwitch(1)}
        >
          1
        </button>
        {currentPage > 3 && finalMaxPage > 5 && (
          <span className="pagination__dots">…</span>
        )}
        {pages}
        {currentPage < finalMaxPage - 3 && finalMaxPage > 5 && (
          <span className="pagination__dots">…</span>
        )}
        {finalMaxPage > 1 && (
          <button
            className={csx('pagination__page', {
              ['pagination__page--active']: finalMaxPage === currentPage,
            })}
            onClick={() => handlePageSwitch(finalMaxPage)}
          >
            {finalMaxPage}
          </button>
        )}
      </div>
      <button
        className={csx('pagination__nav-button', {
          ['pagination__nav-button--disabled']: maxPage === currentPage,
        })}
        onClick={() => handlePageSwitch(currentPage + 1)}
      >
        <Icon symbol="chevron-right" />
      </button>
    </div>
  )
}

export default Pagination
