import { observer } from 'mobx-react-lite'
import Text from '../../components/Text'
import t from '../../utils/translate'
import ActiveConnections from './ActiveConnections'
import SmartBundleStore from '../../stores/SmartBundleStore'

function SlotConnection(props) {
  const { id } = props

  const connections = SmartBundleStore.bundleToEdit.slot_connections ?? []

  const isMatchedConnection = (connection) => {
    return connection.first_slot.id === id || connection.second_slot.id === id
  }

  const hasBundleConnections = connections.length > 0
  const hasCurrentSlotConnection =
    connections.filter(isMatchedConnection).length > 0

  return (
    <>
      {hasBundleConnections && (
        <Text
          size="bravo"
          element="p"
          variant="book"
          className="bundle__slot-condition"
        >
          {hasCurrentSlotConnection && t('has conditions to...')}
        </Text>
      )}

      <ActiveConnections {...props} />
    </>
  )
}

export default observer(SlotConnection)
