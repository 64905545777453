import { observer } from 'mobx-react-lite'
import { Tooltip } from 'antd'
import moment from 'moment'
import UIStore from '../../../stores/UIStore'
import Text from '../../../components/Text'

const DateColumn = ({ date }) => {
  if (!date) return null

  moment.locale(UIStore.currentLanguage)

  const formattedDate = moment.utc(date).local().fromNow()
  const tooltip = moment.utc(date).local().format('YYYY/MM/DD, h:mm:ss A')

  return (
    <Tooltip title={<span>{tooltip}</span>} className="mk-table-cell__date">
      <Text>{formattedDate}</Text>
    </Tooltip>
  )
}

export default observer(DateColumn)
