import { get, range } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import ABTestStore from '../../../../stores/ABTestStore'
import { t } from '../../../../utils'
import Button from '../../../Button'
import Icon from '../../../Icon'
import Spinner from '../../../Spinner'
import Table, { Column } from '../../../Table'
import Text from '../../../Text'

const UNITS = {
  nb_orders_revenue: '€',
}

const AVERAGE_UNITS = {
  bounce_count: '%',
  sum_visit_length: 's',
  nb_orders_revenue: '€',
}

const formatValue = (value, key) => {
  if (!value) {
    return '-'
  }

  if (key === 'detected_effect') {
    return `${value} ${value === '-' ? '' : '%'}`
  }

  if (key === ABTestStore.selectedTableMetric) {
    return `${value} ${UNITS[ABTestStore.selectedTableMetric] ?? ''}`
  }

  if (key === 'average') {
    return `${value} ${AVERAGE_UNITS[ABTestStore.selectedTableMetric] ?? ''}`
  }

  if (key === 'significance_rate') {
    return `${
      value === 'NOT_ENOUGH_VISITS' || parseFloat(value) < 50 ? '< 50' : value
    } ${value === '-' ? '' : '%'}`
  }

  return value
}

const ResultTable = () => {
  const metricData = ABTestStore.table[ABTestStore.selectedTableMetric] || []

  const KEYS_PER_METRIC = [
    'nb_visits',
    'nb_uniq_visitors',
    ABTestStore.selectedTableMetric,
    'average',
    'detected_effect',
    'significance_rate',
  ]

  const hasNoActivatedVariation =
    !ABTestStore.editingExperiment.archive ||
    ABTestStore.editingExperiment.archive?.activeVariationId === -1

  const isFrontend = ABTestStore.editingExperiment.case === 'frontend'

  const transformData = (data = []) => {
    let rowData = KEYS_PER_METRIC.map((key) => {
      const formattedData = {
        key: key,
        name: key,
        0: formatValue(get(data, ['0', key]), key),
        1: formatValue(get(data, ['1', key]), key),
      }

      if (ABTestStore.editingExperiment.segments === 3) {
        formattedData[2] = formatValue(get(data, ['2', key]), key)
      }

      return formattedData
    })

    if (ABTestStore.editingExperiment?.variations) {
      const trafficSplit = {
        key: 'status_traffic_split',
        name: t('Traffic split'),
        0: `${ABTestStore.editingExperiment?.variations[0]?.trafficDistribution}%`,
        1: `${ABTestStore.editingExperiment?.variations[1]?.trafficDistribution}%`,
      }

      if (ABTestStore.editingExperiment?.segments === 3) {
        trafficSplit[2] = `${ABTestStore.editingExperiment?.variations[2]?.trafficDistribution}%`
      }

      rowData.unshift(trafficSplit)
    }

    rowData.unshift({
      key: 'version',
      name: 'Version',
      0: 'A',
      1: 'B',
      2: 'C',
    })

    if (
      !ABTestStore.editingExperiment.readOnly &&
      !ABTestStore.editingExperiment.running &&
      hasNoActivatedVariation &&
      !isFrontend
    ) {
      const status = {
        key: 'status',
        name: 'Status',
        0: ABTestStore.editingExperiment?.variations[0]?.id,
        1: ABTestStore.editingExperiment?.variations[1]?.id,
      }

      if (ABTestStore.editingExperiment?.segments === 3) {
        status[2] = ABTestStore.editingExperiment?.variations[2]?.id
      }

      rowData.push(status)
    }

    return rowData
  }

  const handleSetLiveClick = (variationId) => {
    ABTestStore.applyVariation(variationId)
  }

  return (
    <>
      <Table showHeader={false} data={transformData(metricData)}>
        <Column
          dataIndex="name"
          render={(data) => {
            return <Text weight="book">{t(data)}</Text>
          }}
          align="right"
          width={200}
        />
        {range(ABTestStore.editingExperiment.segments).map((segment) => (
          <Column
            key={segment}
            dataIndex={segment}
            render={(data, record) => {
              if (record.key === 'version') {
                return (
                  <Text
                    className={`ab-frontend__variant ab-frontend__variant--${data} ab-frontend__variant--table`}
                  >
                    {data}
                  </Text>
                )
              }

              if (record.key === 'status') {
                if (
                  segment === 0 &&
                  (!ABTestStore.editingExperiment.archive ||
                    ABTestStore.editingExperiment.archive?.activeVariationId ===
                      -1)
                ) {
                  return (
                    <div className="results-panel__live">
                      <Spinner />
                      <Text weight="bold">LIVE</Text>
                    </div>
                  )
                }

                return (
                  <Button
                    variant="primary"
                    onClick={() => handleSetLiveClick(record[segment])}
                    disabled={ABTestStore.editingExperiment.readOnly}
                  >
                    set live
                  </Button>
                )
              }
              return <Text weight="bold">{data || '-'}</Text>
            }}
            align="right"
          />
        ))}
      </Table>
      {!hasNoActivatedVariation && !isFrontend && (
        <Text
          element="p"
          weight="book"
          className="results-panel__info-text results-panel__info-text--already-activated"
        >
          <Icon symbol="circle-info" width={16} height={16} />
          {t('Variation')}
          <Text className={`ab-frontend__variant ab-frontend__variant--B`}>
            B
          </Text>
          {t('has been set live.')}
        </Text>
      )}
      {!isFrontend &&
        ABTestStore.editingExperiment.readOnly &&
        hasNoActivatedVariation && (
          <Text element="p" weight="book" className="results-panel__info-text">
            <Icon symbol="circle-info" width={16} height={16} />
            {t(
              'There is a newer A/B-Test of this type, so no variation can be activated.'
            )}
          </Text>
        )}
    </>
  )
}

export default observer(ResultTable)
