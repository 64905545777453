import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/category?${queryString}`

    return fetch(target)
  },

  async create(category) {
    await fetch('/category', { method: 'POST', body: JSON.stringify(category) })
  },

  async update(category) {
    await fetch(`/category/${category.id}`, {
      method: 'PUT',
      body: JSON.stringify(category),
    })
  },

  async delete(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))

    await fetch(`/category?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },

  async getMapping() {
    return fetch('/category/tree/mapping', { method: 'GET' })
  },

  async updateMapping(mapping) {
    return fetch('/category/tree/mapping', {
      method: 'PUT',
      body: JSON.stringify(mapping),
    })
  },

  async sync() {
    await fetch('/category/sync', { method: 'GET' })
  },
}
