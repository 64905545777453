import { observer } from 'mobx-react-lite'

import Modal from '../../../components/Modal'
import Table from '../../../components/ResourceTable/Table'
import { Column } from '../../../components/Table'
import Text from '../../../components/Text'

import t from '../../../utils/translate'
import StreamStore from '../../../stores/StreamStore'

import './usageModal.styl'

function UsageModal() {
  const { state, id } = StreamStore.usages
  return (
    <Modal
      className="product-stream__usage-modal"
      visible={!!id}
      header={t('Stream usage')}
      onClose={() => StreamStore.closeStreamDialog()}
      width={800}
      centered={true}
      footer={null}
    >
      <Table
        store={StreamStore.usages}
        resourceName="items"
        loading={state === 'pending'}
      >
        <Column
          title="ID"
          render={(id) => (
            <div className="cell-title">
              <Text weight="bold" element="span">
                {id}
              </Text>
            </div>
          )}
          dataIndex="id"
          width={150}
        />
        <Column
          title={t('Name')}
          render={(title) => (
            <div className="cell-title">
              <Text weight="bold" element="span">
                {title}
              </Text>
            </div>
          )}
          dataIndex="title"
        />
      </Table>
    </Modal>
  )
}

export default observer(UsageModal)
