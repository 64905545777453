import Button from '../Button'
import t from '../../utils/translate'
import { basePath } from '../../utils/constants'
import AuthStore from '../../stores/AuthStore'

import logoMakaira from '../../assets/images/logoMakaira.svg'

import './accessError.styl'

const AccessError = ({ status }) => {
  const handleFixThingsClick = () => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = basePath
  }

  const handleLogoutClick = () => {
    AuthStore.logout()
  }

  let errorMessage = ''

  switch (String(status)) {
    case '401':
    case '403':
      errorMessage = t("You don't have access to this Makaira installation.")
      break
    case '404':
      errorMessage = t(
        'This Makaira Installation does not exist - please check your URL for typos.'
      )
      break
    default:
      errorMessage = t(
        'Makaira could not be booted because of an error, please contact our support at'
      )
  }

  return (
    <>
      <div className="access-error">
        <img src={`${logoMakaira}`} alt="Makaira Logo" />
        <h2>{errorMessage}</h2>
        {!['401', '403', '404'].includes(String(status)) && (
          <a href="mailto:support@makaira.io">support@makaira.io</a>
        )}

        <div className="access-error__actions">
          <Button
            variant="primary"
            icon="repeat"
            onClick={handleFixThingsClick}
          >
            Fix Things and Try Again
          </Button>
          <Button
            variant="primary"
            icon="right-from-bracket"
            onClick={handleLogoutClick}
          >
            Logout
          </Button>
        </div>
      </div>
    </>
  )
}

export default AccessError
