import { observer } from 'mobx-react-lite'
import cs from 'classnames'
import Text from '../Text'
import './checkbox.styl'

const Checkbox = ({
  label,
  onChange,
  checked,
  disabled,
  className,
  partialChecked,
}) => {
  return (
    <label
      className={cs('checkbox', className, { checkbox__disabled: disabled })}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="checkmark" />
      {!checked && partialChecked && <span className="partial-checkmark" />}
      {label && <Text>{label}</Text>}
    </label>
  )
}

export default observer(Checkbox)
