import { makeAutoObservable, toJS } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import { cloneDeep, differenceBy } from 'lodash'

class PublicFieldStore {
  publicFields = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = {}
  state = 'pending' // "pending", "done" or "error"

  addOrEditFields = []
  availableFields = []
  isDirty = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  discard() {
    this.addOrEditFields = []
    this.isDirty = false
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchPublicFields()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchPublicFields()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchPublicFields()
  }

  getById(id) {
    return this.publicFields.find((s) => s.id == id)
  }

  setAddOrEditFields(row, oldRow = null) {
    this.isDirty = true
    const foundIndex = this.addOrEditFields.findIndex(
      (f) => f.fieldId === (oldRow?.fieldId || row.fieldId)
    )
    if (foundIndex < 0) {
      this.addOrEditFields.push(row)
    } else {
      this.addOrEditFields[foundIndex] = row
    }
  }

  deleteAddOrEditFields(fieldId) {
    this.addOrEditFields = this.addOrEditFields.filter(
      (f) => f.fieldId !== fieldId
    )
    if (this.addOrEditFields.length <= 0) {
      this.isDirty = false
    }
  }

  *fetchPublicFields(isDirty) {
    this.isDirty = isDirty || this.isDirty
    try {
      const { data, total } = yield api.publicFields.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.publicFields = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the public field listing.')
      this.state = 'error'
    }
  }

  *fetchAvailableFields() {
    try {
      const { data } = yield api.fields.getWithoutExcluded(
        'script,datatype',
        true
      )
      this.availableFields = data
    } catch (error) {
      console.error(error)
      toast.error('Something went wrong while getWithoutExcluded fields')
    }
  }

  toastError(error) {
    if (!error.message) return
    const errorObject = JSON.parse(error.message)
    toast.error(errorObject.message)
  }

  *saveAddOrEditFields() {
    try {
      const requests = []
      this.addOrEditFields.forEach((field) => {
        if (field.id === 'new') {
          const { fieldId, ...rest } = field
          requests.push(
            api.publicFields
              .create({
                ...rest,
                field: fieldId,
              })
              .catch(this.toastError)
          )
        } else {
          requests.push(api.publicFields.update(field).catch(this.toastError))
        }
      })

      yield Promise.allSettled(requests)
      this.discard()
      this.fetchPublicFields(false)
      this.fetchAvailableFields()
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong while saving Public Fields')
      return false
    }
  }

  *updateOrCreate(stream) {
    try {
      if (stream.id) {
        yield api.publicFields.update(stream)
      } else {
        const { fieldName, ...rest } = stream
        yield api.publicFields.create({
          ...rest,
          field: fieldName,
        })
      }
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
    this.fetchPublicFields()
  }

  *delete(id) {
    try {
      yield api.publicFields.delete(id)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchPublicFields()
  }

  mergeFields(originFields = [], editingFields = []) {
    const clone = cloneDeep(originFields)
    editingFields.forEach((editing) => {
      const foundIndex = originFields.findIndex(
        (f) => f.fieldId === editing.fieldId
      )
      if (foundIndex < 0) {
        if (editing.id === 'new') {
          clone.push(editing)
        }
      } else {
        clone[foundIndex] = editing
      }
    })
    return clone
  }

  get fields() {
    return [
      ...this.mergeFields(toJS(this.publicFields), toJS(this.addOrEditFields)),
      { id: 'add_new' },
    ]
  }

  get availableFieldsUpdated() {
    const addOrEditFieldsById = this.addOrEditFields.map((item) => ({
      id: item.fieldId,
    }))
    return differenceBy(this.availableFields, addOrEditFieldsById, 'id')
  }
}

export default new PublicFieldStore()
