import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import ABTestStore from '../../../stores/ABTestStore'
import { t } from '../../../utils'
import DatePicker from '../../DatePicker'
import Radio from '../../Radio'
import Text from '../../Text'

const ConfigStart = ({ disabled }) => {
  const { editingExperiment } = ABTestStore

  return (
    <div className="ab-metaconfig__config-start">
      <Text
        size="delta"
        weight="bold"
        className="ab-metaconfig__traffic-distribution-label"
      >
        {t('Start test')}
      </Text>
      <Radio.Group
        onChange={(value) => {
          ABTestStore.updateEditingExperiment('manualStart', value === '0')
        }}
        value={editingExperiment.manualStart ? '0' : '1'}
      >
        <Radio
          size="large"
          label={<Text size="charlie">{t('Manually')}</Text>}
          value="0"
          disabled={disabled}
        />
        <Radio
          size="large"
          label={
            <DatePicker
              value={moment(editingExperiment.startDate)}
              showTime
              allowClear={false}
              onChange={(_, value) => {
                ABTestStore.updateEditingExperiment('startDate', value)
                ABTestStore.updateEditingExperiment('manualStart', false)
              }}
              format="YYYY-MM-DD HH:mm:ss"
              disabled={disabled}
              disabledDate={(current) => current.isBefore(moment())}
            />
          }
          disabled={disabled}
          value="1"
        />
      </Radio.Group>
    </div>
  )
}

export default observer(ConfigStart)
