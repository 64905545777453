import Button from '../../../components/Button'
import t from '../../../utils/translate'

const BuildButton = ({
  onStopBuildIndex,
  onBuildNewIndex,
  importer,
  schedule,
  showStatusText = false,
}) => {
  const oneTimeConfig =
    (importer?.schedules || []).find((item) => item.kind === 'one-time') || {}
  const status = schedule?.current?.status

  if (status === 'Running') {
    return (
      <>
        {showStatusText && (
          <div className="text-input__label text-input__label--white-ground">
            {t('Manual Rebuild is running currently')}
          </div>
        )}
        <Button
          onClick={() => onStopBuildIndex(schedule?.configId)}
          icon="times"
        >
          {t('Stop rebuilding of index')}
        </Button>
      </>
    )
  }

  return (
    <Button
      style={{ margin: 0 }}
      onClick={() => onBuildNewIndex(oneTimeConfig.id)}
      disabled={!importer || status === 'Pending' || status === 'Terminating'}
      icon="play"
    >
      {t('Build new index')}
    </Button>
  )
}

export default BuildButton
