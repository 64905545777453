import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { message } from 'antd'

import { createRedirectPath, t } from '../../../utils'
import Button from '../../Button'
import ConfigurationPanel, { Panel } from '../../ConfigurationPanel'
import ABTestStore from '../../../stores/ABTestStore'
import ResultPanel from './Results/ResultPanel'
import TrafficDistributionGroup from './TrafficDistributionGroup'
import { getRedirectPath } from '../utils'
import ConfigTitle from './ConfigTitle'
import ConfigStart from './ConfigStart'
import ConfigStop from './ConfigStop'
import ConfigVariant from './ConfigVariant'

const ConfigHeader = ({ disabled }) => {
  const { editingExperiment } = ABTestStore
  const [showPanel, setShowPanel] = useState(
    !editingExperiment.finished && !editingExperiment.running
  )
  const history = useHistory()
  const location = useLocation()
  const isCreateView = location.pathname.endsWith('/ab-testing/new')

  const handleUpdateOrCreate = async () => {
    const res = await ABTestStore.updateOrCreate(editingExperiment)
    if (res === false) return
    if (res?.data) {
      setShowPanel(false)
      const newSearchParams = new URLSearchParams({
        'ab-test': res.data.id,
      })
      if (res.data.case !== 'frontend') {
        newSearchParams.set('ab-variant', 'A')
      }
      const newURL = `${createRedirectPath(
        getRedirectPath(res.data.case)
      )}?${newSearchParams.toString()}`

      history.push(newURL)
    } else {
      message.success(t('Successfully updated'))
    }
  }

  return (
    <>
      <div className="ab-metaconfig__header">
        <ConfigTitle showPanel={showPanel} setShowPanel={setShowPanel} />
        <ConfigurationPanel active={showPanel}>
          <Panel withoutCollapse={true}>
            <div className="ab-metaconfig__config-wrapper">
              <div className="ab-metaconfig__configs">
                <TrafficDistributionGroup disabled={disabled} />
                <div className="ab-metaconfig__time-configuration">
                  <ConfigStart disabled={disabled} />
                  <ConfigStop disabled={disabled} />
                </div>
              </div>
              <div className="ab-metaconfig__config-actions">
                {!isCreateView && ABTestStore.isDirty && (
                  <Button
                    onClick={ABTestStore.resetEditData}
                    variant="primary-blocker"
                    icon="xmark"
                  >
                    {t('Discard changes')}
                  </Button>
                )}
                {(isCreateView || (!isCreateView && ABTestStore.isDirty)) && (
                  <Button
                    onClick={handleUpdateOrCreate}
                    variant="secondary-blocker"
                    icon="chevron-right"
                    loading={ABTestStore.isCreatingTest}
                  >
                    {t(isCreateView ? 'Create test' : 'Save configuration')}
                  </Button>
                )}
              </div>
            </div>
          </Panel>
        </ConfigurationPanel>
        {ABTestStore.showResults && <ResultPanel />}
      </div>
      <ConfigVariant />
    </>
  )
}

export default observer(ConfigHeader)
