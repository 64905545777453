import { observer } from 'mobx-react-lite'
import DropdownButton from '../../../components/DropdownButton'
import Text from '../../../components/Text'
import StorefrontSettingStore from '../../../stores/StorefrontSettingStore'
import { t } from '../../../utils'
import BranchInput from '../components/BranchInput'
import BuildTable from '../components/BuildTable'

const Build = () => {
  const {
    branches,
    isUpdatingAutoDeployBranch,
    isTriggeringDeploy,
    triggerDeploy,
    deployments,
  } = StorefrontSettingStore

  return (
    <>
      <div className="storefront-settings__sub-title">
        <Text size="echo" weight="bold">
          {t('Builds')}
        </Text>
        <DropdownButton
          action={triggerDeploy}
          actionIcon="rocket__regular"
          text={
            <>
              {t('Build')}&nbsp;<strong>{branches.auto_deploy_branch}</strong>
            </>
          }
          menu={<BranchInput />}
          buttonLevel={1}
          loading={isUpdatingAutoDeployBranch || isTriggeringDeploy}
          destroyTooltipOnHide
        />
      </div>
      <Text
        className="storefront-settings__build-type"
        size="bold"
        weight="medium"
      >
        {t('Deployed Build')}
      </Text>
      <BuildTable
        data={
          deployments.current_deployed_build
            ? [deployments.current_deployed_build]
            : []
        }
      />
      <Text
        className="storefront-settings__build-type"
        size="bold"
        weight="medium"
      >
        {t('Pending & Previous Builds')}
      </Text>
      <BuildTable data={deployments.latest_builds || []} />
    </>
  )
}

export default observer(Build)
