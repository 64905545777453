import { observer } from 'mobx-react-lite'
import TextInput from '../../../components/TextInput'
import t from '../../../utils/translate'
import FileUploadV3 from '../../../components/FileUpload'
import SmartBundleStore from '../../../stores/SmartBundleStore'

const ContentSlotEdit = ({ language }) => {
  const slotData = SmartBundleStore.getSelectedSlot()
  return (
    <div>
      <div className="content-edit__content-wrapper">
        <div className="content-edit__content-left">
          <FileUploadV3
            showDetail={false}
            title={
              <div className="content-edit__content-title">
                {t('Placeholder Image')}
              </div>
            }
            value={slotData.image?.[language] ?? ''}
            onChange={(value) =>
              SmartBundleStore.updateSelectedSlot('image', value, language)
            }
            onRemove={() =>
              SmartBundleStore.updateSelectedSlot('image', '', language)
            }
          />
        </div>
        <div className="content-edit__content-right">
          <TextInput
            label={t('Title')}
            placeholder={t('Enter title...')}
            onChange={(e) =>
              SmartBundleStore.updateSelectedSlot(
                'title',
                e.target.value,
                language
              )
            }
            value={slotData.title?.[language] ?? ''}
          />
          <TextInput
            label={t('Buttontext')}
            placeholder={t('Enter text...')}
            onChange={(e) =>
              SmartBundleStore.updateSelectedSlot(
                'button_text',
                e.target.value,
                language
              )
            }
            value={slotData.button_text?.[language] ?? ''}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(ContentSlotEdit)
