import getInstance from '../getInstance'
import { stripSlashes } from '../stripSlashes'

export default function createInternalHref({ route, id = '' }) {
  const instance = getInstance()
  const { path = '/' } = route
  const normalizedPath = stripSlashes(path)

  const href = '/' + instance + '/' + normalizedPath
  const withId = href.replace(':id', id)

  return withId.replace('//', '/') // return double slashes
}
