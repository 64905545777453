import { makeAutoObservable, set } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'

class PlacementStore {
  placements = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = {}
  state = 'pending' // "pending", "done" or "error"

  detail = {}
  isDirty = false
  previewPageUrl = null

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchPlacements()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchPlacements()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchPlacements()
  }

  *getById(id) {
    this.state = 'pending'

    if (id === 'new') {
      this.detail = { allPersona: true }
      this.state = 'done'
      return
    }

    try {
      const { data } = yield api.placements.getById(id)
      this.detail = data
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the placement detail.')
      this.state = 'error'
    }
  }

  *fetchPlacements() {
    try {
      this.state = 'pending'
      const { data, total } = yield api.placements.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.placements = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the placement listing.')
      this.state = 'error'
    }
  }

  *updateOrCreate(stream) {
    let detail = null
    const items = stream.items
    try {
      if (stream.id) {
        const { data } = yield api.placements.update(stream)
        detail = data
      } else {
        const { data } = yield api.placements.create(stream)
        detail = data
      }
      this.isDirty = false
      this.detail = detail
      // keep 'items' field to avoid missing image of product
      this.detail.items = items
    } catch (error) {
      this.isDirty = false
      this.state = 'error'
      toast.error('Something went wrong...')
    }
    return detail
  }

  *delete(ids) {
    try {
      yield api.placements.delete(ids)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchPlacements()
  }

  onChangeDetail(value, isDirty = true) {
    this.isDirty = isDirty
    set(this.detail, value)
  }

  setPreviewPageUrl(url) {
    this.previewPageUrl = url
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }
}

export default new PlacementStore()
