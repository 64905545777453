const CHECKS = [
  {
    title: 'Tracking of product detail pages',
    apiKey: 'haveDetailPageCalls',
    errorMessage: 'haveDetailPageCalls_error',
  },
  {
    title: 'Tracking of abandoned carts',
    apiKey: 'haveAbandonedCartCalls',
    errorMessage: 'haveAbandonedCartCalls_error',
  },
  {
    title: 'Tracking of orders',
    apiKey: 'haveOrderCompletedCalls',
    errorMessage: 'haveOrderCompletedCalls_error',
  },
  {
    title: 'Tracked EAN codes have to match your products',
    apiKey: 'checkEansOnMatomoExistsInMakaira',
    errorMessage: 'checkEansOnMatomoExistsInMakaira_error',
  },
  {
    title: '14 days of successful tracking',
    apiKey: 'checkEnoughElapsedData',
    errorMessage:
      'We still have to wait some days until your tracking data is sufficient for our algorithms.',
  },
  {
    title: 'Grouping fields fulfill requirements',
    apiKey: 'checkTermAggregationOnGroupingFields',
    errorMessage: 'checkTermAggregationOnGroupingFields_error',
  },
  {
    title: 'Machine Learning was calculated successfully',
    apiKey: 'checkMachineLearningReadinessFlag',
    errorMessage: 'Our algorithms still have to prepare your data.',
  },
]

const USERS = [
  {
    name: 'Berlin - MacOS - Safari',
    'oi.user.agent':
      'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_4) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.1 Safari/605.1.15',
    'oi.user.ip': '95.91.209.0',
  },
  {
    name: 'Oberstdorf - Windows - Chrome',
    'oi.user.agent':
      'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/66.0.3359.139 Safari/537.36',
    'oi.user.ip': '95.90.199.0',
  },
  {
    name: 'Wien - Android - Chrome',
    'oi.user.agent':
      'Mozilla/5.0 (Linux; Android 6.0.1; SM-G532G Build/MMB29T) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.83 Mobile Safari/537.36',
    'oi.user.ip': '77.119.129.0',
  },
]

export { CHECKS, USERS }
