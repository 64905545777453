import appendProtocolToURL from './appendProtocolToURL'

/**
 * Returns the hostname/domain part of the given URL.
 *
 * @param url{string} URL that should be parsed.
 * @returns {string} The domain/hostname part of the given URL.
 */
function getOnlyDomain(url = '') {
  try {
    const urlWithProtocol = appendProtocolToURL(url)

    const urlObject = new URL(urlWithProtocol)

    return urlObject.hostname
  } catch (e) {
    return url
  }
}

export default getOnlyDomain
