import { getDefaultFieldState } from '../../utils'
import PublishSetting from './PublishSetting'

export default function LanguageConfig(fields, active = false) {
  const content = fields.reduce((acc, currentField) => {
    return {
      ...acc,
      ...getDefaultFieldState(currentField),
    }
  }, {})

  return {
    active,
    personas: {
      All: new PublishSetting(),
    },
    content,
  }
}
