import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ContentWrapper from '../../components/ContentWrapper'
import LoadingScreen from '../../components/LoadingScreen'
import PageTitle from '../../components/PageTitle'
import Text from '../../components/Text'
import { ActionLayerStore, UIStore } from '../../stores'
import ABTestStore from '../../stores/ABTestStore'
import RankingMixStore from '../../stores/RankingMixStore'
import { createRedirectPath, t } from '../../utils'
import ConfigSection from './components/ConfigSection'
import PreviewSection from './components/PreviewSection'

import './style.styl'

const RankingMix = () => {
  const isDirty = RankingMixStore.isDirty
  const {
    selectedVariant,
    running,
    rankingMixVariantsConfig,
    showMetaconfigView,
    isFetchingTest,
    testDetail,
  } = ABTestStore
  const runningTest =
    (running && running.find((test) => test.case === 'ranking_mix')) || null
  const haveRunningTest = runningTest !== null
  const isDefaultVariant = selectedVariant === 'A' && showMetaconfigView
  const isDraftingTest = !testDetail?.running && !testDetail?.finished

  const { search } = useLocation()
  const history = useHistory()

  const typeFromUrl = useMemo(
    () => new URLSearchParams(search).get('type') || 'product',
    [search]
  )

  useEffect(() => {
    RankingMixStore.fetchConfig()
    ABTestStore.fetchExperimentRunning()
    UIStore.fetchProductGroups(UIStore.enterpriseConfiguration.defaultLanguage)
  }, [])

  useEffect(() => {
    if (haveRunningTest && !isFetchingTest) {
      const newSearchParams = new URLSearchParams(search)

      if (!newSearchParams.get('ab-test')) {
        newSearchParams.set('ab-variant', 'A')
        newSearchParams.set('ab-test', runningTest.id)

        const newURL = `${createRedirectPath(
          '/ranking-mix'
        )}?${newSearchParams.toString()}`

        history.replace(newURL)
      }
    }

    // eslint-disable-next-line
  }, [isFetchingTest, haveRunningTest])

  useEffect(() => {
    RankingMixStore.fetchProductPreview()
  }, [selectedVariant])

  useEffect(() => {
    if (isDirty && !showMetaconfigView) {
      ActionLayerStore.openActionLayer({
        onSave: RankingMixStore.postConfig,
        onClose: RankingMixStore.reset,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  const getTabClassNames = (type) => {
    return classNames('tabs__item', {
      'tabs__item--active': type === typeFromUrl,
    })
  }

  const handleChangeTab = (type) => (e) => {
    e.stopPropagation()
    const newSearchParams = new URLSearchParams(search)
    newSearchParams.set('type', type)
    const newURL = `${createRedirectPath(
      '/ranking-mix'
    )}?${newSearchParams.toString()}`
    history.replace(newURL)
  }

  let variantData = {}
  if (showMetaconfigView) {
    const variantRankingMixData = rankingMixVariantsConfig?.find(
      (item) => item.variation_name === ABTestStore.selectedVariant
    )
    variantData.searchConfig = variantRankingMixData?.settings[0] || {}
    variantData.categoryConfig = variantRankingMixData?.settings[1] || {}
  }

  const searchConfig = RankingMixStore.config.search || {}
  const categoryConfig = RankingMixStore.config.category || {}

  if (RankingMixStore.isFetchingConfig) return <LoadingScreen />

  return (
    <>
      <PageTitle prefix={t('You are editing')}>{t('Ranking Mix')}</PageTitle>

      <ContentWrapper className="ranking-mix">
        <div className="ranking-mix__config-section">
          {isDefaultVariant && (
            <div className="ranking-mix__variant-a-warning">
              {t('For version A the current productive settings are used.')}
            </div>
          )}
          <div className="tabs">
            <a
              className={getTabClassNames('product')}
              onClick={handleChangeTab('product')}
            >
              <Text weight="bold" size="echo">
                {t('Search results')}
              </Text>
            </a>
            <a
              className={getTabClassNames('category')}
              onClick={handleChangeTab('category')}
            >
              <Text weight="bold" size="echo">
                {t('Categories')}
              </Text>
            </a>
          </div>
          {typeFromUrl === 'product' && (
            <ConfigSection
              config={
                showMetaconfigView ? variantData.searchConfig : searchConfig
              }
              disabled={
                (!showMetaconfigView && haveRunningTest) ||
                (showMetaconfigView && (isDefaultVariant || !isDraftingTest))
              }
            />
          )}
          {typeFromUrl === 'category' && (
            <ConfigSection
              config={
                showMetaconfigView ? variantData.categoryConfig : categoryConfig
              }
              disabled={
                (!showMetaconfigView && haveRunningTest) ||
                (showMetaconfigView && (isDefaultVariant || !isDraftingTest))
              }
            />
          )}
        </div>
        <div className="ranking-mix__preview-section">
          <PreviewSection type={typeFromUrl} />
        </div>
      </ContentWrapper>
    </>
  )
}

export default observer(RankingMix)
