import RcSelect from 'rc-select'
import { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { Tooltip } from 'antd'

import Icon from '../Icon'
import { t } from '../../utils'
import Text from '../Text'
import Checkbox from '../Checkbox'

import './tags-select.styl'
import './style.styl'

const SelectTags = ({
  onChange,
  value = [],
  onSearch = () => {},
  showSearch,
  options = [],
  labelField = 'label',
  valueField = 'id',
  imageField = 'picture_url_main',
  additionInfoField,
  getSelectedById,
  label,
  className,
  placeholder,
}) => {
  const [values, setValues] = useState(value)

  useEffect(() => {
    setValues(value)
  }, [value])

  const renderOptions = useMemo(
    () =>
      options.map((item) => ({
        label: (
          <div className="option-tags__list-row">
            <Checkbox
              readOnly
              checked={values.includes(item[valueField])}
              onChange={() => {}}
            />
            <div className="option-tags__list-column option-tags__option">
              {item[imageField] && <img src={item[imageField]} />}
              <div className="option-tags__list-meta">
                <Text className="option-tags__option-title" weight="bold">
                  {item[labelField]}
                </Text>
                {additionInfoField && item[additionInfoField] && (
                  <div>
                    <Text className="option-tags__option-info" variant="book">
                      {item[additionInfoField]}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </div>
        ),
        value: item[valueField],
      })),
    [values, options, additionInfoField, imageField, labelField, valueField]
  )

  return (
    <div className={cx('select-tags', className)}>
      <Text className="text-input__label">{label}</Text>
      <RcSelect
        placeholder={placeholder}
        dropdownStyle={{ maxHeight: '400px' }}
        virtual={false}
        prefixCls="select-tags"
        value={values}
        title={t('Select products that are used for the preview')}
        optionFilterProp="label"
        mode="tags"
        onSearch={showSearch ? onSearch : undefined}
        showSearch={showSearch}
        dropdownClassName="option-tags__dropdown"
        filterOption={(_, option) => option}
        tagRender={(data) => {
          const { onClose } = data
          const item = getSelectedById
            ? getSelectedById(data.value)
            : options.find((option) => option.value === data.value)
          return (
            <Tooltip title={item?.url} align={{ offset: [0, 5] }}>
              <div className="option-tags__item">
                <Text weight="bold" size="bravo">
                  {item?.[labelField] || data?.label}
                </Text>
                <Icon
                  onClick={onClose}
                  width="16px"
                  height="16px"
                  symbol="xmark"
                />
              </div>
            </Tooltip>
          )
        }}
        onChange={(newValues) => {
          const filterValues = newValues.filter((item) => {
            return (
              options.some((option) => option[valueField] === item) ||
              (getSelectedById && getSelectedById(item))
            )
          })

          setValues(filterValues)
          onChange(filterValues)
        }}
        options={renderOptions}
      />
    </div>
  )
}

export default SelectTags
