import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import TextInput from '../../../components/TextInput'
import StorefrontSettingStore from '../../../stores/StorefrontSettingStore'
import { t } from '../../../utils'

const Variables = () => {
  const { configVars } = StorefrontSettingStore

  const readOnlyVariables = configVars.readonly_variables || {}

  const getDataToCopy = () => {
    const readOnlyVariablesArr = Object.entries(readOnlyVariables).map(
      (value) => ({
        name: value[0],
        value: value[1],
      })
    )
    const variablesNoId = StorefrontSettingStore.variables.map(
      ({ name, value }) => ({ name, value })
    )
    const resultArr = [...readOnlyVariablesArr, ...variablesNoId].map(
      ({ name, value }) => name + '=' + value
    )

    return resultArr.join('\n')
  }

  return (
    <>
      <div className="storefront-settings__sub-title">
        <Text size="echo" weight="bold">
          {t('Environment Variables')}
        </Text>
        <CopyToClipboard
          text={getDataToCopy()}
          onCopy={() => toast.success(t('Copied') + '!')}
        >
          <Button level={1} variant="tertiary" icon="copy">
            {t('Copy all variables')}
          </Button>
        </CopyToClipboard>
      </div>
      {Object.entries(readOnlyVariables).length > 0 && (
        <div className="storefront-settings__section-wrapper">
          <Text size="delta" weight="bold">
            {t('Fixed variables')}
          </Text>
          <div className="storefront-settings__variable-grid">
            <div>{t('Variable Name')}</div>
            <div></div>
            <div>{t('Variable Value')}</div>

            {Object.entries(readOnlyVariables).map((value) => (
              <Fragment key={value[0]}>
                <Text weight="bold">{value[0]}</Text>
                <Icon symbol="chevron-right" width={15} height={15} />
                <Text weight="bold">{value[1]}</Text>
              </Fragment>
            ))}
          </div>
        </div>
      )}
      {StorefrontSettingStore.variables.length > 0 && (
        <div className="storefront-settings__variable-input-grid">
          <div>{t('Variable Name')}</div>
          <div></div>
          <div>{t('Variable Value')}</div>
          <div></div>

          {StorefrontSettingStore.variables.map((variable) => (
            <Fragment key={variable.id}>
              <TextInput
                error={variable.error}
                onChange={(e) =>
                  StorefrontSettingStore.updateVariable(
                    variable,
                    'name',
                    e.target.value.toUpperCase()
                  )
                }
                value={variable.name}
              />
              <Icon symbol="chevron-right" width={15} height={15} />
              <TextInput
                onChange={(e) =>
                  StorefrontSettingStore.updateVariable(
                    variable,
                    'value',
                    e.target.value
                  )
                }
                value={variable.value}
              />
              <Button
                onClick={() =>
                  StorefrontSettingStore.removeVariable(variable.id)
                }
                level={1}
                variant="tertiary"
                icon="trash"
              />
            </Fragment>
          ))}
        </div>
      )}
      <Button
        onClick={StorefrontSettingStore.addNewVariable}
        level={1}
        iconPosition="left"
        variant="tertiary"
        icon="plus"
      >
        {t('Add environment variable')}
      </Button>
    </>
  )
}

export default observer(Variables)
