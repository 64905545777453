import Chart from 'react-apexcharts'
import { observer } from 'mobx-react-lite'

import Text from '../../components/Text'
import Button from '../../components/Button'
import t from '../../utils/translate'
import PersonalizationStore from '../../stores/PersonalizationStore'
import Spinner from '../../components/Spinner'
import { prepareDataForChart } from '../../utils'
import ABTestStore from '../../stores/ABTestStore'

const defaultOptions = {
  xaxis: {
    type: 'datetime',
  },
  stroke: {
    curve: 'smooth',
    width: 2,
    dashArray: [0, 0, 2, 2, 2, 2],
  },
  markers: {
    size: [4, 4, 0, 0, 0, 0],
  },
  legend: {
    show: false,
  },
}

function ResultsChart() {
  const isPaidExperimentRunning =
    PersonalizationStore.featureInfo.state === 'paid' &&
    PersonalizationStore.isRunningExperiment

  if (
    PersonalizationStore.featureInfo.state !== 'trial' &&
    !isPaidExperimentRunning
  )
    return null

  if (ABTestStore.showMetaconfigView) return null

  const label = t('Revenue per visit in €')
  const metricData = 'nb_orders_revenue'

  const data = PersonalizationStore.graph

  const {
    originalData,
    variationData,
    standardDevA1,
    standardDevA2,
    standardDevB1,
    standardDevB2,
    variationLegend,
  } = prepareDataForChart(metricData, PersonalizationStore.graph)

  const handleBookApp = () => {
    document.location.href =
      'mailto:sales@makaira.io?subject=' +
      encodeURIComponent(
        `Makaira Feature Booking for ${window.location.hostname}`
      ) +
      '&body=' +
      encodeURIComponent(
        `Dear Makaira,\rhereby I would like to book the feature machine_learning\r\rKind regards`
      )
  }

  return (
    <div className="personalization__trial-section">
      <div className="personalization__chart-section">
        {data[metricData] === 'There is no enough data' && (
          <div className="personalization__chart-no-data">
            <div className="personalization__spinner">
              <Spinner />
            </div>
            <Text element="div" weight="bold" size="delta">
              {t('Waiting for data from the shop, this might take a moment …')}
            </Text>
            <Text size="charlie">{t('chart_no_data_des')}</Text>
          </div>
        )}
        {data[metricData] !== 'There is no enough data' && (
          <>
            <Text element="div" weight="heavy" size="echo">
              {t('Current Trial & A/B test results')}
            </Text>
            {PersonalizationStore.effect.detected_effect &&
              PersonalizationStore.effect.detected_effect !== '-' && (
                <Text
                  className="personalization__effective"
                  size="charlie"
                  variant="book"
                  element="div"
                >
                  {t('best_variant')(
                    PersonalizationStore.effect.detected_effect,
                    PersonalizationStore.effect.significance_rate
                  )}
                </Text>
              )}
            <Chart
              options={{
                ...defaultOptions,
                yaxis: {
                  title: {
                    text: label,
                  },
                  labels: {
                    formatter: function (value) {
                      return (
                        Number(value).toLocaleString('de-DE', {
                          maximumFractionDigits: 3,
                        }) +
                        ' ' +
                        '€'
                      )
                    },
                  },
                },
              }}
              series={[
                { name: t('Original'), data: originalData, color: '#63b6c8' },
                {
                  name: variationLegend,
                  data: variationData,
                  color: '#84a400',
                },
                {
                  name: t('Upper limit ') + '(Original)',
                  data: standardDevA1,
                  color: '#63b6c8',
                },
                {
                  name: t('Lower limit ') + '(Original)',
                  data: standardDevA2,
                  color: '#63b6c8',
                },
                {
                  name: t('Upper limit ') + '(' + variationLegend + ')',
                  data: standardDevB1,
                  color: '#84a400',
                },
                {
                  name: t('Lower limit ') + '(' + variationLegend + ')',
                  data: standardDevB2,
                  color: '#84a400',
                },
              ]}
              type="line"
              height={320}
            />
          </>
        )}
      </div>
      {PersonalizationStore.featureInfo.state !== 'paid' &&
        PersonalizationStore.trialDaysLeft >= 0 && (
          <div className="personalization__start-trial-section">
            <Text
              className="personalization__start-trial-section-title"
              element="div"
              weight="heavy"
              size="echo"
            >
              {t('trial_days_left')(PersonalizationStore.trialDaysLeft)}
            </Text>
            <Text element="div" weight="bold" size="delta">
              {t(
                "… to test the machine learning feature and improve your shop's performance."
              )}
            </Text>
            <Text element="div" size="charlie">
              {t(
                'After that we will end the A/B test and return your shop to the original state. From that point on please get in touch with us to reactivate the feature.'
              )}
            </Text>
            <Button
              variant="green"
              onClick={handleBookApp}
              icon="chevron-right"
            >
              {t('Book personalization')}
            </Button>
          </div>
        )}
      {PersonalizationStore.featureInfo.state !== 'paid' &&
        PersonalizationStore.trialDaysLeft < 0 && (
          <div className="personalization__start-trial-section personalization__start-trial-section--end">
            <Text
              className="personalization__start-trial-section-title"
              element="div"
              weight="heavy"
              size="echo"
            >
              {t('Your trial period is over …')}
            </Text>
            <Text element="div" weight="bold" size="delta">
              {t(
                '… it seems your shop did well with the Machine Learning switched on.'
              )}
            </Text>
            <Text element="div" size="charlie">
              {t(
                'It would be a shame to waste the opportunity to make the most of your shop. We recommend booking the feature now to benefit in future.'
              )}
            </Text>
            <Button onClick={handleBookApp} icon="chevron-right">
              {t('Book personalization')}
            </Button>
          </div>
        )}
    </div>
  )
}

export default observer(ResultsChart)
