import { Tooltip } from 'antd'
import classes from 'classnames'
import { useEffect, useState } from 'react'
import RcSelect, { Option, OptGroup } from 'rc-select'

import Text from '../Text'
import Icon from '../Icon'

import t from '../../utils/translate'

import './select.styl'

const PLACEMENTS = {
  bottomLeft: {
    points: ['tl', 'bl'],
    offset: [0, 4],
    overflow: {
      adjustX: 0,
      adjustY: 1,
    },
  },
  topLeft: {
    points: ['bl', 'tl'],
    offset: [0, -4],
    overflow: {
      adjustX: 0,
      adjustY: 1,
    },
  },
}

export default function Select(props) {
  const {
    placeholder = t('Select…'),
    title,
    defaultValue,
    disabled,
    onChange = () => {},
    onSearch = () => {},
    borderless,
    width,
    options = [],
    groupOptions = [],
    showSearch,
    className,
    dropdownMatchSelectWidth = true,
    customDropdownPlacement,
    translateLabel = false,
    allowClear = false,
    description,
    info,
    style = {},
    error,
    size = 'normal', // normal or large (used for example at smart bundles)
    dropdownRender,
    onDropdownVisibleChange = () => {},
    fullWidth = false,
    itemKey,
    flexible = false,
    value: valueFromProps,
    ...rest
  } = props
  const [value, setValue] = useState(valueFromProps)

  useEffect(() => {
    setValue(valueFromProps || undefined)
  }, [valueFromProps])

  const handleChange = (newValue, option) => {
    setValue(newValue)
    onChange(newValue, option)
  }

  const classNames = classes('select-input', className, {
    'select-input__borderless': borderless,
    'select-input--error': error,
    'select-input--large': size === 'large',
    'select-input--full-width': fullWidth,
    'select-input--allow-clear': allowClear,
    'select-input--flexible': flexible,
  })

  const getPlacements = () => {
    const htmlRegion = 'visible'

    const sharedConfig = {
      overflow: {
        adjustX: true,
        adjustY: true,
        shiftY: true,
      },
      htmlRegion,
      dynamicInset: true,
    }

    return {
      bottomLeft: {
        ...sharedConfig,
        points: ['tl', 'bl'],
        offset: [0, 4],
      },
      bottomRight: {
        ...sharedConfig,
        points: ['tr', 'br'],
        offset: [0, 4],
      },
      topLeft: {
        ...sharedConfig,
        points: ['bl', 'tl'],
        offset: [0, -4],
      },
      topRight: {
        ...sharedConfig,
        points: ['br', 'tr'],
        offset: [0, -4],
      },
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()} className={classNames}>
      {title && <div className="select-input__label">{title}</div>}
      <div className="select-input__main">
        <RcSelect
          allowClear={allowClear}
          placeholder={placeholder}
          suffixIcon={() => <Icon symbol="angle-down" />}
          clearIcon={() => <Icon symbol="times" />}
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
          onDropdownVisibleChange={onDropdownVisibleChange}
          onChange={onChange && handleChange}
          width={width}
          showSearch={showSearch}
          dropdownClassName="select-input__drop-down"
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
          dropdownAlign={PLACEMENTS[customDropdownPlacement]}
          // fix bug the popup not follow the input when modal scroll
          dropdownStyle={{
            transform: 'translateY(calc(var(--scroll-modal) * -1))',
          }}
          style={style}
          prefixCls="makaira-select"
          dropdownRender={dropdownRender}
          onSearch={onSearch}
          builtinPlacements={getPlacements()}
          {...rest}
        >
          {groupOptions &&
            groupOptions.map((group) => (
              <OptGroup label={group.label} key={group.label}>
                {group.children.map((option) => (
                  <Option
                    className={
                      size === 'large' ? 'makaira-select-option--large' : ''
                    }
                    key={itemKey ? option[itemKey] : option.value}
                    value={option.value}
                  >
                    {translateLabel ? t(option.label) : option.label}
                  </Option>
                ))}
              </OptGroup>
            ))}

          {options &&
            options.map((option, index) => (
              <Option
                className={
                  size === 'large' ? 'makaira-select-option--large' : ''
                }
                value={option.value}
                key={`${option.label}_${option.value}_${index}`}
                disabled={option.disabled}
              >
                {translateLabel ? t(option.label) : option.label}
              </Option>
            ))}
        </RcSelect>
        {info && (
          <Tooltip title={info} className="select-input__info">
            <Icon symbol="circle-info" width={16} height={16} />
          </Tooltip>
        )}
      </div>
      {(description || error) && (
        <Text
          element="p"
          weight="book"
          size="bravo"
          className="select-input__message"
        >
          {description || error}
        </Text>
      )}
    </div>
  )
}
