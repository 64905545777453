import isEqual from 'lodash/isEqual'

export default function removeMediaData(data) {
  if (Array.isArray(data)) {
    return data.reduce((result, item) => {
      result.push(removeMediaData(item))
      return result
    }, [])
  } else if (typeof data === 'object' && data !== null) {
    // remove media for cloundinary type
    const keys = Object.keys(data)
    if (
      isEqual(['cloudName', 'fileName', 'resourceType', 'version'], keys.sort())
    ) {
      return ''
    }

    return Object.keys(data).reduce((result, key) => {
      result[key] = removeMediaData(data[key])
      return result
    }, {})
  }

  return /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(data) ? '' : data
}
