import { buildQueryString } from '../utils'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getSearchTerm(options) {
    const queryString = buildQueryString(options)
    const target = `/monitoring/search-terms?${queryString}`

    return fetch(target)
  },
  async checkSearchTerm(options) {
    const queryString = buildQueryString(options)
    const target = `/monitoring/search-term/check?${queryString}`

    return fetch(target)
  },
}
