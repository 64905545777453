import { Field } from 'formik'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import Switch from '../../../components/Switch'
import Text from '../../../components/Text'
import FormikTextInput from '../../../components/TextInput/FormikTextInput'
import UIStore from '../../../stores/UIStore'
import EditorStore from '../../../stores/PageEditor/EditorStore'
import t from '../../../utils/translate'

export default function SnippetSetting({ pageToEdit, formikProps, languages }) {
  if (pageToEdit.type !== 'snippet') return null

  const { handleChange, values, setFieldValue } = formikProps

  const handleTitleChange = (e) => {
    const newPageToEdit = cloneDeep(pageToEdit)

    UIStore.languages.forEach((lang) => {
      setFieldValue(`metadata.${lang}.title`, e.target.value)
      // Always update the title in the store so that in the PageHeader
      // the user input is displayed.
      set(newPageToEdit, `metadata.${lang}.title`, e.target.value)
    })
    EditorStore.setPage(newPageToEdit)
  }

  return (
    <div className="page-configuration__global-settings">
      <div className="page-configuration__active-switchs">
        {UIStore.languages.map((language) => (
          <Switch
            key={language}
            checked={get(values, ['active', language])}
            title={`${t('Active')} (${language.toUpperCase()})`}
            onChange={(checked) => setFieldValue(`active.${language}`, checked)}
          />
        ))}
      </div>

      <Switch
        checked={get(values, ['essentialSnippet'])}
        title={t('Essential Snippet')}
        onChange={(checked) => setFieldValue(`essentialSnippet`, checked)}
      />

      <Field
        component={FormikTextInput}
        label={<Text variant="book">{t('Title')}</Text>}
        className="page-configuration__setting-grow"
        onChange={handleTitleChange}
        name={`metadata.${languages[0]}.title`}
      />

      <Field
        name={`snippetId`}
        label={<Text variant="book">{t('Snippet ID')}</Text>}
        component={FormikTextInput}
        className="page-configuration__setting-grow"
        onChange={handleChange}
        // We validate here custom because, we want this error to be not blocking.
        // Formik won't submit a form it is invalid, but we want to allow this error.
        error={
          !values.snippetId?.match(/^\w[0-9a-zA-Z-_.]*$/) &&
          t('Please enter a correct Snippet-ID.')
        }
      />
    </div>
  )
}
