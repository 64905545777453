import { observer } from 'mobx-react-lite'
import { t } from '../../utils'
import Modal from '../../components/Modal'

import SearchPreview from '../../components/SearchPreview'

function PrefillModal(props) {
  const { prefillModalVisible, closePrefillModal, onSelect } = props

  if (!prefillModalVisible) return null

  function handleAssignment(data) {
    onSelect && onSelect(data)
    closePrefillModal()
  }

  return (
    <Modal
      visible={prefillModalVisible}
      onClose={closePrefillModal}
      header={t('Search a target')}
      mask
      maskClosable
    >
      <SearchPreview
        searchLabel={t('Where should the redirect refer to?')}
        focus
        handleAssignment={handleAssignment}
        showURL
      />
    </Modal>
  )
}

export default observer(PrefillModal)
