import { cloneDeep } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import api from '../../api'
import Importer from '../../models/Importer'
import t from '../../utils/translate'
import { ActionLayerStore } from '../index'
import { SOURCES_TYPE } from '../../views/Importer/constants'

export const PERSISTENCE_LAYER_STATUS = {
  LOADING: 'loading',
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
  ERROR: 'error',
}

class ImporterStore {
  importers = []
  scheduleStatus = []
  importerLogs = []
  importerLogsTotal = 0
  importerToEdit = {}
  importerLogsParams = {
    podName: '',
    limit: 25,
  }
  indicies = []
  replicatype = ''
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  isDirty = false
  filter = null
  state = 'pending' // 'pending', 'done' or 'error'

  persistenceLayerStatus = PERSISTENCE_LAYER_STATUS.LOADING
  persistenceLayerLanguages = []

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  getImporterById(id) {
    return this.importers.find((importer) => importer.id == id)
  }

  getScheduleById(id) {
    return this.scheduleStatus.find((s) => s.configId == id)
  }

  getScheduleByLanguage({ lang, type }) {
    const makairaImporter = this.importers.find(
      (importer) => importer.targetType === 'makaira'
    )
    let schedule = null
    if (makairaImporter && makairaImporter.schedules) {
      schedule = makairaImporter.schedules.find(
        (schedule) => schedule.kind === type
      )
    }

    return this.scheduleStatus.find((item) => {
      if (schedule && schedule.id !== item.configId) {
        return false
      }

      const languages = get(item, 'current.languages')
      const isEqualType = item.type === type
      const isSupportAllLanguages =
        isEqualType && languages && languages.length === 0
      const isSupportSingleLanguage =
        isEqualType && languages && languages.indexOf(lang) > -1

      return isSupportSingleLanguage || isSupportAllLanguages
    })
  }

  getImporterByLanguage(lang) {
    return this.importers.find((item) => {
      const isSupportAllLanguages = item.languages.length === 0
      const isSupportSingleLanguage = item.languages.indexOf(lang) > -1
      const hasOneTimeImport = item.schedules.some((s) => s.kind === 'one-time')
      return (
        hasOneTimeImport && (isSupportSingleLanguage || isSupportAllLanguages)
      )
    })
  }

  editImporter(importer) {
    const formattedImporter = cloneDeep(importer)

    // We split here all schedules into two groups: scheduled and other
    // Scheduled schedules are the ones that are displayed in the schedule section
    // and by splitting we can use FieldArray of Formik.
    const scheduledImporters = formattedImporter.schedules
      .filter((schedule) => schedule.kind === 'scheduled')
      .map((schedule) => {
        return {
          ...schedule,
          selectedType: 'cron',
          hourly: 0,
          every: 15,
          daily: [0, 0],
        }
      })

    formattedImporter.otherSchedules = formattedImporter.schedules.filter(
      (schedule) => schedule.kind !== 'scheduled'
    )

    formattedImporter.scheduledImporters = scheduledImporters

    this.importerToEdit = formattedImporter

    if (importer.sourceType === SOURCES_TYPE.PERSISTENCE_LAYER) {
      this.checkPersistenceLayerAvailability()
    }
  }

  setImporter(importer) {
    this.importerToEdit = importer
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  resetLogFilter() {
    this.importerLogsParams = {
      podName: '',
      limit: 25,
    }
  }

  setLogParams(filter) {
    this.resetLogFilter()
    this.importerLogsParams = {
      ...this.importerLogsParams,
      ...filter,
    }
    this.fetchLogs()
  }

  getRunningStatus(lang) {
    const oneTimeConfig = this.getScheduleByLanguage({ lang, type: 'one-time' })
    const scheduledConfig = this.getScheduleByLanguage({
      lang,
      type: 'scheduled',
    })
    return oneTimeConfig || scheduledConfig
  }

  *getById(id) {
    this.state = 'pending'
    if (!id) {
      this.state = 'done'
      return new Importer()
    }

    // check store if already fetched
    let importer = this.importers.find((b) => b.id === id)
    if (!importer) {
      yield this.fetchAll()
      importer = this.importers.find((b) => b.id == id)
    }

    this.state = 'done'

    // fetch from api
    return importer
  }

  *updateOrCreate(data) {
    data = data || this.importerToEdit

    try {
      this.state = 'pending'
      if (data.id) {
        yield api.importer.update(data)
      } else {
        yield api.importer.create(data)
      }
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
      toast.error(t('Something went wrong saving the importer.'))
    }
    this.fetchAll()
  }

  *fetchAll() {
    try {
      this.state = 'pending'
      const { data } = yield api.importer.getAll()
      this.importers = data.map((importer) => ({
        ...importer,
        children: importer.schedules.map((schedule) => ({
          ...schedule,
          importerId: importer.id,
        })),
      }))
      this.state = 'done'
      return data
    } catch (error) {
      toast.error(t('Something went wrong loading the importer listing.'))
      this.state = 'error'
    }
  }

  *fetchScheduleStatus() {
    try {
      this.state = 'pending'
      const { data } = yield api.importer.getSchedulerStatus()

      if (Array.isArray(data)) {
        this.scheduleStatus = data
      } else {
        toast.error(t('Something went wrong loading the importer status.'))
      }

      this.state = 'done'
      return data
    } catch (error) {
      toast.error(t('Something went wrong loading the importer status.'))
      this.state = 'error'
    }
  }

  *buildNewIndex(id) {
    this.state = 'pending'
    try {
      const data = yield api.importer.buildNewIndex(id)
      this.state = 'done'
      return data
    } catch (error) {
      toast.error(t('Something went wrong building the new index.'))
      this.state = 'error'
    }
  }

  *stopBuildIndex(id) {
    this.state = 'pending'
    try {
      const data = yield api.importer.stopBuildIndex(id)
      this.state = 'done'
      return data
    } catch (error) {
      toast.error(t('Something went wrong building the new index.'))
      this.state = 'error'
    }
  }

  *fetchLogs() {
    this.state = 'pending'
    try {
      const { isLoadMore, ...params } = this.importerLogsParams
      const { data } = yield api.importer.fetchLogs({ params })

      if (isLoadMore) {
        this.importerLogs = this.importerLogs.concat(data.messages)
      } else {
        this.importerLogs = data.messages
        this.importerLogsTotal = data.total
      }

      this.state = 'done'
    } catch (error) {
      toast.error(t('Something went wrong.'))
      this.state = 'error'
    }
  }

  *autoFetchLogs(params) {
    try {
      const { data } = yield api.importer.fetchLogs({
        params: {
          ...this.importerLogsParams,
          ...params,
        },
      })
      this.importerLogs = data.messages.concat(this.importerLogs)
      this.importerLogs = uniqBy(this.importerLogs, 'timestamp')
      return data
    } catch (error) {
      toast.error(t('Something went wrong.'))
    }
  }

  *delete(ids) {
    this.state = 'pending'

    try {
      yield api.importer.deleteImporters(ids)
      this.state = 'done'
      toast.success(
        ids.length > 1
          ? t('Importers have been deleted.')
          : t('Importer has been deleted.')
      )
    } catch (error) {
      this.state = 'error'
      ActionLayerStore.openActionLayer({
        error:
          ids.length > 1
            ? t('Importers could not be deleted.')
            : t('Importer could not be deleted.'),
        saveTitle: 'OK',
        onSave: () => {},
      })
      return
    }

    this.fetchAll()
  }

  *fetchIndexStatus() {
    try {
      const { data } = yield api.importer.updateIndexStatus()

      this.indicies = data.indices
      this.replicatype = data.replicatype
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong.')
      this.state = 'error'
    }
  }

  *checkPersistenceLayerAvailability() {
    this.persistenceLayerStatus = PERSISTENCE_LAYER_STATUS.LOADING

    try {
      const data = yield api.importer.fetchShopLanguages({
        sourceType: SOURCES_TYPE.PERSISTENCE_LAYER,
      })

      if (data.code === 400) {
        this.persistenceLayerLanguages = []
        this.persistenceLayerStatus = PERSISTENCE_LAYER_STATUS.UNAVAILABLE
        return
      }

      this.persistenceLayerLanguages = data
      this.persistenceLayerStatus = PERSISTENCE_LAYER_STATUS.AVAILABLE
    } catch (error) {
      this.persistenceLayerLanguages = []
      this.persistenceLayerStatus = PERSISTENCE_LAYER_STATUS.ERROR
    }
  }
}

export default new ImporterStore()
