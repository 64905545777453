export default [
  {
    id: '001-pict-heading-text-cta-L',
    name: '001 picture heading text cta - Left',
  },
  {
    id: '002-pict-heading-text-cta-R',
    name: '002 picture heading text cta - Right',
  },
  {
    id: '003-pict-heading-text-cta-cta-L',
    name: '003 picture heading text 2-cta - Left',
  },
  {
    id: '004-pict-heading-text-cta-cta-R',
    name: '004 picture heading text 2-cta - Right',
  },
  {
    id: '005-pict-heading-cta-L',
    name: '005 picture heading cta - Left',
  },
  {
    id: '006-pict-heading-cta-R',
    name: '006 picture heading cta - Right',
  },
  {
    id: '007-pict-heading-cta-cta-L',
    name: '007 picture heading 2-cta - Left',
  },
  {
    id: '008-pict-heading-cta-cta-R',
    name: '008 picture heading 2-cta - Right',
  },
  {
    id: '009-pict-tag-heading-text-cta-L',
    name: '009 picture tag heading text cta - Left',
  },
  {
    id: '010-pict-tag-heading-text-cta-R',
    name: '010 picture tag heading text cta - Right',
  },
  {
    id: '011-pict-tag-heading-text-cta-L',
    name: '011 picture tag heading text cta - Left',
  },
  {
    id: '012-pict-tag-heading-text-cta-R',
    name: '012 picture tag heading text cta - Right',
  },
  {
    id: '013-pict-heading-text-L',
    name: '013 picture heading text - Left',
  },
  {
    id: '014-pict-heading-text-R',
    name: '014 picture heading text - Right',
  },
  {
    id: '015-bgrpict-tag-heading-text-cta-L',
    name: '015 background-image tag heading text cta - Left',
  },
  {
    id: '016-bgrpict-tag-heading-text-cta-R',
    name: '016 background-image tag heading text cta - Right',
  },
  {
    id: '017-bgrpict-heading-text-cta-L',
    name: '017 background-image heading text cta - Left',
  },
  {
    id: '018-bgrpict-heading-text-cta-R',
    name: '018 background-image heading text cta - Right',
  },
  {
    id: '019-slider-bgrpict-heading-text-cta-L',
    name: '019 slider background-image heading text cta - Left',
  },
  {
    id: '020-slider-bgrpict-heading-text-cta-R',
    name: '020 slider background-image heading text cta - Right',
  },
  {
    id: '021-pict-caption',
    name: '021 pict caption',
  },
  {
    id: '022-video',
    name: '022 video',
  },
  {
    id: '023-slider-pict-caption',
    name: '023 slider pict caption',
  },
  {
    id: '024-2cols-heading-text-cta',
    name: '024 2-cols heading text cta',
  },
  {
    id: '025-heading-2cols-text-cta',
    name: '025 heading 2-cols text cta',
  },
  {
    id: '026-heading-2cols-text',
    name: '026 heading 2-cols text',
  },
  {
    id: '027-2cols-heading-text',
    name: '027 2-cols heading text',
  },
  {
    id: '028-heading-3cols-text',
    name: '028 heading 3-cols text',
  },
  {
    id: '029-2cols-heading-text',
    name: '029 2-cols heading text',
  },
  {
    id: '030-3cols-heading-text',
    name: '030 3-cols heading text',
  },
  {
    id: '031-4cols-heading-text',
    name: '031 4-cols heading text',
  },
  {
    id: '032-3cols-heading-text',
    name: '032 3-cols heading text',
  },
  {
    id: '033-4cols-heading-text',
    name: '033 4-cols heading text',
  },
  {
    id: '034-3cols-pict-heading-cta',
    name: '034 3-cols pict heading cta',
  },
  {
    id: '035-3cols-2rows-pict-heading-text-cta',
    name: '035 3-cols 2-rows picture heading text cta',
  },
  {
    id: '036-2cols-pict-heading-cta',
    name: '036 2-cols picture heading cta',
  },
  {
    id: '037-grid-4x-picture',
    name: '037 grid 4x-picture',
  },
  {
    id: '038-video-heading-text-cta-L',
    name: '038 video heading text cta - Left',
  },
  {
    id: '039-video-heading-text-cta-R',
    name: '039 video heading text cta - Right',
  },
  {
    id: '040-logostrip',
    name: '040 logostrip',
  },
  {
    id: '041-quote-text-cta-L',
    name: '041 quote text cta - Left',
  },
  {
    id: '042-quote-text-cta-R',
    name: '042 quote text cta - Right',
  },
  {
    id: '043-discovery-pict-3cols-product',
    name: '043 discovery pict 3-cols product',
  },
  {
    id: '044-accordion',
    name: '044 accordion',
  },
  {
    id: '045-tabs',
    name: '045 tabs',
  },
  {
    id: '046-3cols-product-heading-text-cta',
    name: '046 3-cols product heading text cta',
  },
  {
    id: '047-slider-2cols-product-heading-text-cta',
    name: '047 slider 2-cols product heading text cta',
  },
  {
    id: '048-heading-text-form-cta',
    name: '048 heading text form cta',
  },
  {
    id: '049-heading-form-cta',
    name: '049 heading form cta',
  },
  {
    id: '050-heading-form-form-cta',
    name: '050 heading 2-form cta',
  },
  {
    id: '051-3cols-masonry',
    name: '051 3-cols masonry',
  },
  {
    id: '052-4cols-masonry',
    name: '052 4-cols masonry',
  },
  {
    id: '053-5cols-masonry',
    name: '053 5-cols masonry',
  },
  {
    id: '054-5cols-masonry-jagged',
    name: '054 5-cols masonry jagged',
  },
  {
    id: '055-text',
    name: '055 text',
  },
  {
    id: '056-Image',
    name: '056 Image',
  },
  {
    id: '057-Product',
    name: '057 Product',
  },
  {
    id: '058-Technical',
    name: '058 Technical',
  },
  {
    id: '059-Other',
    name: '059 Other',
  },
]
