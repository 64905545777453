import React from 'react'
import { observer } from 'mobx-react-lite'

import Text from '../../../Text'
import Checkbox from '../../../Checkbox'

import { t } from '../../../../utils'

import ABTestStore from '../../../../stores/ABTestStore'

import './displayedVariantSelection.styl'

const VariantSelectionLabel = ({ variant, text = 'Variant' }) => (
  <div className="variant-selection__label">
    {text}{' '}
    <Text className={`ab-frontend__variant ab-frontend__variant--${variant}`}>
      {variant}
    </Text>
  </div>
)

const CheckboxWrapper = observer(({ variant, variantIndex, text }) => {
  const handleChange = () => {
    if (ABTestStore.displayedVariants.includes(variantIndex)) {
      ABTestStore.hideVariant(variantIndex)
    } else {
      ABTestStore.showVariant(variantIndex)
    }
  }

  return (
    <Checkbox
      onChange={handleChange}
      checked={ABTestStore.displayedVariants.includes(variantIndex)}
      label={<VariantSelectionLabel variant={variant} text={text} />}
    />
  )
})

const DisplayedVariantSelection = () => {
  return (
    <div className="variant-selection">
      <Text size="bravo">{t('In graph displayed variants:')}</Text>

      <div className="variant-selection__boxes">
        <CheckboxWrapper variantIndex={0} variant="A" text="Original" />
        <CheckboxWrapper variantIndex={1} variant="B" />
        {ABTestStore.editingExperiment.segments === 3 && (
          <CheckboxWrapper variantIndex={2} variant="C" />
        )}
      </div>
    </div>
  )
}

export default observer(DisplayedVariantSelection)
