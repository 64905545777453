import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/searchredirects?${queryString}`

    return fetch(target)
  },

  async create(redirect) {
    return fetch('/searchredirects', {
      method: 'POST',
      body: JSON.stringify(redirect),
    })
  },

  async update(redirect) {
    return fetch(`/searchredirects/${redirect.id}`, {
      method: 'PUT',
      body: JSON.stringify(redirect),
    })
  },

  async delete(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))
    await fetch(`/searchredirects?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },

  get(id) {
    return fetch(`/searchredirects/${id}`)
  },
}
