export default function Filter() {
  return {
    title: {},
    active: true,
    useAttributeTitle: false,
    field: null,
    type: '',
    fieldType: '',
    display: '',
    key: '',
    position: 1,
    sorting: 'count',
    script: '',
    showCount: false,
    showActiveOnTop: false,
    dependsOn: null,
  }
}
